import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { FormControl, TextField } from '@mui/material';

import IconError from '../../IconError';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * A label plus a text field plus error indicator
 *
 * @param label Label text Default =''
 * @param subLabel Label for extra sub-text - default to ''
 * @param id Used to create id and data-testid
 * @param error  This has error to display in IconError
 * @param disabled Disables control if true. Default = false
 * @param mandatory Shows Mandatory component if true. Default = false;
 * @parma rows   Number of rows. Default =1
 * @params customClass - a customized className
 */

// eslint-disable-next-line react/display-name
const LabelledTextField = forwardRef(
    (
        {
            label = '',
            subLabel = '',
            id = '',
            error,
            disabled = false,
            mandatory = false,
            rows = 1,
            customClass = '',
            labelClass = '',
            ...props
        },
        ref
    ) => {
        return (
            <div className={`${form.textFieldInputWrapper} ${form[customClass]}`}>
                {subLabel && <div>{subLabel}</div>}
                <label htmlFor={id} className={`${form.formLabel} ${form[labelClass]}`}>
                    {label} {mandatory && <sup>*</sup>}
                </label>
                <FormControl fullWidth>
                    <TextField
                        role="input"
                        multiline={rows > 1}
                        rows={rows}
                        disabled={disabled}
                        {...props}
                        size="small"
                        id={id}
                        data-testid={'textField' + id}
                        ref={ref}
                        variant="outlined"
                    />
                </FormControl>
                <IconError text={error || null} data-testid={id + 'Error'} />
            </div>
        );
    }
);

LabelledTextField.propTypes = {
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.any,
    rows: PropTypes.number,
    customClass: PropTypes.string,
    labelClass: PropTypes.string
};

export default LabelledTextField;
