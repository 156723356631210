import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

import { SEARCH_REGEX } from '../../validation/validation';

import classes from './searchStyles/dynamicSearch.module.css';

const DynamicSearch = ({
    search,
    searchTerm,
    placeholder,
    disabled = false,
    autocomplete = 'on'
}) => {
    const onSearch = (value) => search(value.replace(SEARCH_REGEX, ''));

    return (
        <div className={classes.dynamicSearch}>
            <TextField
                type="search"
                placeholder={placeholder}
                size={'small'}
                value={searchTerm}
                disabled={disabled}
                autoComplete={autocomplete}
                onChange={(e) => onSearch(e.target.value)}></TextField>
            <div className={`${classes.icons} ${disabled ? classes.disabled : ''}`}>
                {!searchTerm ? (
                    <SearchIcon />
                ) : (
                    <ClearIcon onClick={() => search('')} className={classes.clear} />
                )}
            </div>
        </div>
    );
};

export default DynamicSearch;

DynamicSearch.propTypes = {
    search: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autocomplete: PropTypes.string
};
