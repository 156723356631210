import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLicenseKey } from 'survey-core';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';

import { Checkbox } from '@mui/material';

import { loadCommunicationTemplates } from '../../store/communicationService';
import { loadCommunicationTypes, loadFormTypes } from '../../store/directusService';
import { setErrorMessage } from '../../store/formsState';
import { selectCurrentForm, selectFormsParticipant } from '../../store/participantSelectors';
import { createForm, loadForms, updateForm } from '../../store/participantService';
import { selectUserRoles } from '../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../themes/theme';
import { getNameFromId } from '../../utils/directusFunctions';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';

import { themeJson } from './theme';

import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import form from '../../commonStyles/formStyles.module.css';
import local from './formStyles/formStyle.module.css';
import classes from './formStyles/formToolbar.module.css';

// eslint-disable-next-line no-undef
const REACT_APP_SURVEYJS_BUILDER_LICENCE = process.env.REACT_APP_SURVEYJS_BUILDER_LICENCE;

const FormBuilder = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentForm = useSelector(selectCurrentForm);
    const formsParticipant = useSelector(selectFormsParticipant);
    const communicationTemplates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const { communicationTypes, formTypes } = useSelector(
        (state) => state.entities.directusService
    );

    // LOCAL STATE
    setLicenseKey(REACT_APP_SURVEYJS_BUILDER_LICENCE);

    const questionTypes = [
        'text',
        'comment',
        'checkbox',
        'radiogroup',
        'dropdown',
        'boolean',
        'rating',
        'matrix',
        'matrixdropdown',
        'matrixdynamic',
        'multipletext',
        'panel',
        'paneldynamic',
        'expression',
        'ranking',
        'buttongroup',
        'flowpanel'
    ];

    const [creator, setCreator] = useState(
        new SurveyCreator({
            showDesignerTab: true,
            showLogicTab: true,
            showThemeTab: false,
            showJSONEditorTab: true,
            isAutoSave: false,
            questionTypes: questionTypes
        })
    );
    const [inactive, setInactive] = useState(currentForm?.inactive || false);
    const [formChanged, setFormChanged] = useState(false);

    const [arrayTypes, setArrayTypes] = useState([]);
    const [isClearSelectedType, setIsClearSelectedType] = useState('0');

    const [arrayEmailTemplates, setArrayEmailTemplates] = useState([]);
    const [isClearSelectedEmailTemplate, setIsClearSelectedEmailTemplate] = useState('1');

    // EVENT HANDLERS
    const onCheck = (e) => {
        const { checked } = e.target;

        const newInactiveState = !checked;
        setInactive(newInactiveState);

        const payload = {
            ...currentForm,
            inactive: newInactiveState
        };
        dispatch(updateForm(payload));
    };

    const handleFormTypeChange = (chosenId) => {
        if (
            !currentForm ||
            (!currentForm.typeId && chosenId === '') ||
            currentForm.typeId === chosenId
        )
            return;

        const payload = {
            ...currentForm,
            typeId: chosenId
        };
        dispatch(updateForm(payload));
    };

    const handleEmailTemplateChange = (chosenId) => {
        if (
            !currentForm ||
            (!currentForm.emailTemplateId && chosenId === '') ||
            currentForm.emailTemplateId === chosenId
        )
            return;

        const payload = {
            ...currentForm,
            emailTemplateId: chosenId
        };
        dispatch(updateForm(payload));
    };

    const handleUpdateForm = () => {
        let error = false;

        const MIN_TITLE_LENGTH = 5;
        const MAX_TITLE_LENGTH = 50;
        const MIN_DESCRIPTION_LENGTH = 5;
        const MAX_DESCRIPTION_LENGTH = 250;

        if (
            creator.survey.title.length === 0 ||
            creator.survey.title.length < MIN_TITLE_LENGTH ||
            creator.survey.title.length > MAX_TITLE_LENGTH
        ) {
            dispatch(
                setErrorMessage(
                    `Form title must be between ${MIN_TITLE_LENGTH} and ${MAX_TITLE_LENGTH} characters.`
                )
            );
            error = true;
        }

        if (
            creator.survey.description.length === 0 ||
            creator.survey.description.length < MIN_DESCRIPTION_LENGTH ||
            creator.survey.description.length > MAX_DESCRIPTION_LENGTH
        ) {
            dispatch(
                setErrorMessage(
                    `Form description must be between ${MIN_DESCRIPTION_LENGTH} and ${MAX_DESCRIPTION_LENGTH} characters.`
                )
            );
            error = true;
        }

        creator.survey.pages.forEach((page, index) => {
            if (page.title.length < MIN_TITLE_LENGTH || page.title.length > MAX_TITLE_LENGTH) {
                dispatch(
                    setErrorMessage(
                        `Form page ${
                            index + 1
                        } title must be between ${MIN_TITLE_LENGTH} and ${MAX_TITLE_LENGTH} characters.`
                    )
                );
                error = true;
            }
        });

        creator.survey.pages.forEach((page, index) => {
            if (
                page.description.length < MIN_DESCRIPTION_LENGTH ||
                page.description.length > MAX_DESCRIPTION_LENGTH
            ) {
                dispatch(
                    setErrorMessage(
                        `Form page ${
                            index + 1
                        } description must be  between ${MIN_DESCRIPTION_LENGTH}
                         and ${MAX_DESCRIPTION_LENGTH} characters or less`
                    )
                );
                error = true;
            }
        });

        if (error) {
            return;
        }

        if (Object.keys(currentForm).length > 0) {
            const payload = {
                id: currentForm.id,
                name: creator.survey.title,
                code: currentForm.code,
                date_created: currentForm.date_created,
                data: JSON.parse(creator.text),
                inactive: inactive,
                typeId: currentForm.typeId,
                emailTemplateId: currentForm.emailTemplateId
            };
            dispatch(updateForm(payload));
        } else {
            const payload = {
                name: creator.survey.title,
                data: JSON.parse(creator.text),
                inactive: false
            };
            dispatch(createForm(payload));
        }
        setFormChanged(true);
    };

    // HELPER FNS
    const getName = (id) => {
        if (!id) return '';
        return arrayEmailTemplates.find((item) => item.id === id)?.name || '';
    };

    // USE EFFECTS
    useEffect(() => {
        setIsClearSelectedEmailTemplate(Math.random());
        setIsClearSelectedType(Math.random());
        dispatch(loadCommunicationTemplates());
        dispatch(loadCommunicationTypes());
        dispatch(loadFormTypes());
    }, []);

    useEffect(() => {
        if (formChanged) {
            dispatch(loadForms());
            navigate('/manage_form');
        }
    }, [formChanged]);

    useEffect(() => {
        const hasResults =
            Object.keys(currentForm).length > 0 && Object.keys(formsParticipant).length > 0;
        const surveyOptions = {
            showDesignerTab: !hasResults,
            showLogicTab: !hasResults,
            showThemeTab: false,
            showJSONEditorTab: true,
            isAutoSave: false,
            questionTypes: questionTypes
        };

        setCreator(new SurveyCreator(surveyOptions));
    }, [formsParticipant]);

    useEffect(() => {
        creator.theme = themeJson;

        if (currentForm) {
            setInactive(currentForm?.inactive || false);
            creator.text = JSON.stringify(currentForm.data);
        }

        if (Object.keys(formsParticipant).length < 1) {
            creator.saveSurveyFunc = () => {
                handleUpdateForm();
            };
        }
    }, [creator, currentForm]);

    useEffect(() => {
        if (Object.keys(communicationTemplates)?.length < 1) return;

        setArrayEmailTemplates(
            communicationTemplates.filter(
                (el) => getNameFromId(communicationTypes, el.typeId).toLowerCase() === 'email'
            )
        );
    }, [communicationTemplates]);

    useEffect(() => {
        if (formTypes.length < 1) return;

        setArrayTypes(formTypes);
    }, [formTypes]);

    // RENDER
    return (
        <>
            <div className={classes.formBuilder}>
                <SurveyCreatorComponent creator={creator} />
            </div>
            <form>
                <div className={form.formWrapper}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <div className={local.check}>
                                <label htmlFor="activeCheckbox">Active</label>
                                <Checkbox
                                    disabled={!hasRole([SUPERUSER], roles) || !currentForm}
                                    key={currentForm?.id || 'id'}
                                    type="checkbox"
                                    sx={{ color: POLARIS_ROYAL_BLUE }}
                                    checked={!inactive}
                                    onChange={onCheck}
                                />
                            </div>
                            <DDLOptionPicker
                                label={'Form Type'}
                                disabled={!hasRole([SUPERUSER], roles) || !currentForm}
                                id={'typeId'}
                                key={isClearSelectedType}
                                menuItems={arrayTypes || []}
                                chosenName={
                                    currentForm?.typeId
                                        ? getNameFromId(formTypes, currentForm?.typeId)
                                        : ''
                                }
                                chosenId={currentForm?.typeId || ''}
                                onChange={(chosenId) => handleFormTypeChange(chosenId)}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <div className={local.ddl}>
                                <DDLOptionPicker
                                    label={'Email Template'}
                                    disabled={!hasRole([SUPERUSER], roles) || !currentForm}
                                    id={'emailTemplateId'}
                                    key={isClearSelectedEmailTemplate}
                                    menuItems={arrayEmailTemplates || []}
                                    chosenName={
                                        currentForm?.emailTemplateId
                                            ? getName(currentForm?.emailTemplateId)
                                            : ''
                                    }
                                    chosenId={currentForm?.emailTemplateId || ''}
                                    onChange={(chosenId) => handleEmailTemplateChange(chosenId)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default FormBuilder;
