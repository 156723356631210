import { combineReducers } from 'redux';

import calendarService from './calendarService';
import communicationServiceReducer from './communicationService';
import courseServiceReducer from './courseService';
import dataServiceReducer from './dataService';
import directusServiceReducer from './directusService';
import documentServiceReducer from './documentService';
import formsStateReducer from './formsState';
import participantServiceReducer from './participantService';
import recruitmentServiceReducer from './recruitmentService';
import userServiceReducer from './userService';

export default combineReducers({
    // Add main reducer for each slice here.
    // These get combined into one reducer with name-spacing in
    // reducer.js
    calendarService: calendarService,
    communicationService: communicationServiceReducer,
    courseService: courseServiceReducer,
    dataService: dataServiceReducer,
    directusService: directusServiceReducer,
    documentService: documentServiceReducer,
    formsState: formsStateReducer,
    participantService: participantServiceReducer,
    recruitmentService: recruitmentServiceReducer,
    userService: userServiceReducer
});
