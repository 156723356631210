import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_WHITE } from '../../../../themes/theme';
import { EMAIL_REGEXP, POSTCODE_REGEXP } from '../../../../validation/validation';
import * as validate from '../../../../validation/validation';
import LabelledEmailText from '../../editors/LabelledEmailText';
import LabelledPhoneText from '../../editors/LabelledPhoneText';
import LabelledPostcodeText from '../../editors/LabelledPostcodeText';
import LabelledTextField from '../../editors/LabelledTextField';

const RELATIONSHIP_MAX_LENGTH = 100; // See PPL-231
const FIRST_NAME_MAX_LENGTH = 50; // See PPL-231
const LAST_NAME_MAX_LENGTH = 50; // See PPL-231
const ADDRESS_LINE_MAX_LENGTH = 100; // See PPL-231
const CITY_MAX_LENGTH = 60; // See PPL-231
const POSTCODE_MAX_LENGTH = 10; // See PPL-231
const PHONE_MIN_LENGTH = 11; // See LabelledPhoneText.js
const PHONE_MAX_LENGTH = 15; // See PPL-231
const EMAIL_MAX_LENGTH = 50; // See PPL-231

/**
 * A dialog to edit contact details
 * @param cardData - Data for this editor
 * This has values which match the additional_contact_card table in the participant service
 * @param disabled
 * @param sendChange Callback function to tell parent that data has changed.
 * @returns {JSX.Element}
 * @constructor
 */
const ContactDetailsEditor = ({ cardData, disabled = false, sendChange }) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;
    const [open, setOpen] = useState(false);
    const [newEntry, setNewEntry] = useState({
        id: null,
        relationshipToParticipant: null,
        firstName: null,
        lastName: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        city: null,
        postcode: null,
        phoneNumber: null,
        emailAddress: null,
        participantId: null
    });

    const setCardData = () => {
        // Set info to cardData
        setNewEntry((prev) => ({
            ...prev,
            id: cardData.id,
            relationshipToParticipant: cardData.relationshipToParticipant,
            firstName: cardData.firstName,
            lastName: cardData.lastName,
            addressLine1: cardData.addressLine1,
            addressLine2: cardData.addressLine2,
            addressLine3: cardData.addressLine3,
            circumstancesId: cardData.circumstancesId,
            city: cardData.city,
            postcode: cardData.postcode,
            phoneNumber: cardData.phoneNumber,
            emailAddress: cardData.emailAddress,
            participantId: cardData.participantId
        }));
    };

    // First time on card and blank so must be new.
    // Open dialog to force user to enter data
    useEffect(() => {
        if (!cardData.relationshipToParticipant && !cardData.firstName) {
            if (disabled === false) {
                setOpen(true);
            }
        }
        setCardData();
    }, []);

    // EVENT HANDLERS
    const handleClickOpen = () => {
        if (disabled === false) {
            // Select relationship control ?
            setOpen(true);
        }
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit be clicking outside dialog.
    };

    const handleCancel = () => {
        setCardData();
        clearErrors();
        reset();
        setOpen(false);
    };

    const handleRelationshipChange = (e) => {
        setNewEntry((prev) => ({ ...prev, relationshipToParticipant: e.target.value }));
        clearErrors('relationshipToParticipant');
    };

    const handleFirstNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, firstName: e.target.value }));
        clearErrors('firstName');
    };

    const handleLastNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, lastName: e.target.value }));
        clearErrors('lastName');
    };

    const handleAddress1Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine1: e.target.value }));
        clearErrors('addressLine1');
    };

    const handleAddress2Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine2: e.target.value }));
        clearErrors('addressLine2');
    };
    const handleAddress3Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine3: e.target.value }));
        clearErrors('addressLine3');
    };
    const handleCityChange = (e) => {
        setNewEntry((prev) => ({ ...prev, city: e.target.value }));
        clearErrors('city');
    };

    const handlePostcodeChange = (e) => {
        setNewEntry((prev) => ({ ...prev, postcode: e.target.value }));
        clearErrors('postcode');
    };

    const handleEmailChange = (e) => {
        setNewEntry((prev) => ({ ...prev, emailAddress: e.target.value }));
        clearErrors('emailAddress');
    };

    const handlePhoneNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, phoneNumber: e.target.value }));
        clearErrors('phoneNumber');
    };

    const onSubmit = () => {
        clearErrors();
        sendChange(newEntry);
        setOpen(false);
    };

    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <EditIcon
                onClick={handleClickOpen}
                sx={{ color: iconColor }}
                data-testid="edit-icon"></EditIcon>

            <Dialog open={open} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Contact Details</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <LabelledTextField
                            id="relationship"
                            mandatory={true}
                            disabled={disabled}
                            label="Relationship to Participant"
                            value={newEntry.relationshipToParticipant || ''}
                            error={errors.relationshipToParticipant}
                            {...register('relationshipToParticipant')}
                            onChange={handleRelationshipChange}
                        />
                        <LabelledTextField
                            id="firstName"
                            mandatory={true}
                            disabled={disabled}
                            label="First Name"
                            value={newEntry.firstName || ''}
                            placeholder="First Name"
                            error={errors.firstName}
                            {...register('firstName')}
                            onChange={handleFirstNameChange}
                        />
                        <LabelledTextField
                            id="lastName"
                            disabled={disabled}
                            label="Last Name"
                            value={newEntry.lastName || ''}
                            error={errors.lastName}
                            {...register('lastName')}
                            onChange={handleLastNameChange}
                        />
                        <LabelledTextField
                            id="address1"
                            disabled={disabled}
                            label="Address Line 1"
                            value={newEntry.addressLine1 || ''}
                            error={errors.addressLine1}
                            {...register('addressLine1')}
                            onChange={handleAddress1Change}
                        />
                        <LabelledTextField
                            id="address2"
                            disabled={disabled}
                            label="Address Line 2"
                            value={newEntry.addressLine2 || ''}
                            error={errors.addressLine2}
                            {...register('addressLine2')}
                            onChange={handleAddress2Change}
                        />
                        <LabelledTextField
                            id="address3"
                            disabled={disabled}
                            label="Address Line 3"
                            value={newEntry.addressLine3 || ''}
                            error={errors.addressLine3}
                            {...register('addressLine3')}
                            onChange={handleAddress3Change}
                        />
                        <LabelledTextField
                            id="city"
                            disabled={disabled}
                            label="Town/City"
                            value={newEntry.city || ''}
                            error={errors.city}
                            {...register('city')}
                            onChange={handleCityChange}
                        />
                        <LabelledPostcodeText
                            id="postcode"
                            disabled={disabled}
                            label="Postcode"
                            value={newEntry.postcode || ''}
                            error={errors.postcode}
                            {...register('postcode')}
                            onChange={handlePostcodeChange}
                        />
                        <LabelledPhoneText
                            id="phonenumber"
                            disabled={disabled}
                            label="Phone Number"
                            value={newEntry.phoneNumber || ''}
                            error={errors.phoneNumber}
                            {...register('phoneNumber')}
                            onChange={handlePhoneNumberChange}
                        />
                        <LabelledEmailText
                            id="email"
                            disabled={disabled}
                            label="Email"
                            value={newEntry.emailAddress || ''}
                            error={errors.emailAddress}
                            {...register('emailAddress')}
                            onChange={handleEmailChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Update
                        </Button>
                        <Button
                            onClick={handleCancel}
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    relationshipToParticipant: Yup.string()
        .trim()
        .required('Relationship to participant is required')
        .max(
            RELATIONSHIP_MAX_LENGTH,
            `Relationship must be ${RELATIONSHIP_MAX_LENGTH} characters or shorter`
        ),
    firstName: Yup.string()
        .trim()
        .required('First name must be at least 1 character')
        .max(
            FIRST_NAME_MAX_LENGTH,
            `First name must be ${FIRST_NAME_MAX_LENGTH} characters or shorter`
        ),
    lastName: Yup.string()
        .trim()
        .max(
            LAST_NAME_MAX_LENGTH,
            `Last name must be ${LAST_NAME_MAX_LENGTH} characters or shorter`
        ),
    addressLine1: Yup.string()
        .trim()
        .max(ADDRESS_LINE_MAX_LENGTH, `Must be ${ADDRESS_LINE_MAX_LENGTH} characters or shorter`),
    addressLine2: Yup.string()
        .trim()
        .max(ADDRESS_LINE_MAX_LENGTH, `Must be ${ADDRESS_LINE_MAX_LENGTH} characters or shorter`),
    addressLine3: Yup.string()
        .trim()
        .max(ADDRESS_LINE_MAX_LENGTH, `Must be ${ADDRESS_LINE_MAX_LENGTH} characters or shorter`),
    city: Yup.string()
        .trim()
        .max(CITY_MAX_LENGTH, `Must be ${CITY_MAX_LENGTH} characters or shorter`),
    phoneNumber: Yup.string()
        .matches(/.{11,}/, {
            excludeEmptyString: true,
            message: `Phone number must be ${PHONE_MIN_LENGTH} characters or more`
        })
        .max(PHONE_MAX_LENGTH, `Phone number must be ${PHONE_MAX_LENGTH} characters or less`)
        .matches(validate.PHONE_REGEXP, {
            excludeEmptyString: true,
            message: 'Invalid Phone Number'
        }),
    postcode: Yup.string()
        .trim()
        .matches(POSTCODE_REGEXP, { excludeEmptyString: true, message: 'Post code is not valid' })
        .max(POSTCODE_MAX_LENGTH, `Must be ${POSTCODE_MAX_LENGTH} characters or shorter`),
    emailAddress: Yup.string()
        .trim()
        .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Email is not valid' })
        .max(EMAIL_MAX_LENGTH, `Must be ${EMAIL_MAX_LENGTH} characters or shorter`)
});

ContactDetailsEditor.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendChange: PropTypes.func.isRequired
};
export default ContactDetailsEditor;
