import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createBMI, updateBMI } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';

import {
    checkDate,
    checkHeightErrors,
    checkHeightFt,
    checkHeightIns,
    checkHeightWarnings,
    checkWeightErrors,
    checkWeightLbs,
    checkWeightSt,
    checkWeightWarnings,
    initialErrorState,
    initialHeightErrorState,
    initialWarningState,
    initialWeightErrorState,
    validate
} from './validateBMIEntry';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateBMIEntry = ({ onClose, roles, attendedModules, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null, // DB requires this to be null
        participantId: '',
        height: '',
        weight: '',
        eventDate: new Date().toISOString().slice(0, 10),
        linkedModuleId: null
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const initialImperialHeight = { ft: '', ins: '' };
    const initialImperialWeight = { st: '', lbs: '' };

    const [newEntry, setNewEntry] = useState(initialState);
    const [modules, setModules] = useState([]);
    const [heightOtherUnits, setHeightOtherUnits] = useState(false);
    const [weightOtherUnits, setWeightOtherUnits] = useState(false);
    const [imperialHeight, setImperialHeight] = useState(initialImperialHeight);
    const [imperialWeight, setImperialWeight] = useState(initialImperialWeight);
    const [errors, setErrors] = useState(initialErrorState);
    const [warnings, setWarnings] = useState(initialWarningState);
    const [warningHeightCheck, setWarningHeightCheck] = useState(false);
    const [warningWeightCheck, setWarningWeightCheck] = useState(false);
    const msg = `Participant BMI entry has been ${formType === 'create' ? 'added' : 'updated'}`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setWarnings(initialWarningState);
    };

    const clearHeightErrors = () => {
        setErrors((prev) => ({
            ...prev,
            ...initialHeightErrorState
        }));
        setWarnings((prev) => ({
            ...prev,
            height: initialWarningState.height
        }));
        setWarningHeightCheck(false);
    };

    const clearWeightErrors = () => {
        setErrors((prev) => ({
            ...prev,
            ...initialWeightErrorState
        }));
        setWarnings((prev) => ({
            ...prev,
            weight: initialWarningState.weight
        }));
        setWarningWeightCheck(false);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false, message: '' } }));
    };

    const clearWarning = (key) => {
        setWarnings((prev) => ({ ...prev, [key]: { warning: false, message: '' } }));
    };

    const onHeightErrorCheck = (value) => {
        const newErrors = checkHeightErrors(value, errors);
        const newWarnings = checkHeightWarnings(value, warnings);
        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const onWeightErrorCheck = (value) => {
        const newErrors = checkWeightErrors(value, errors);
        const newWarnings = checkWeightWarnings(value, warnings);
        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const convertHeight = (ft, ins) => Math.round((+ft * 30.48 + +ins * 2.54) * 100) / 100;

    const convertWeight = (st, lbs) => Math.round((+st * 6.35029 + +lbs / 14) * 100) / 100;

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            linkedModule: row.linkedModule === 'z' ? '' : row.linkedModule,
            eventDate: row.eventDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        let modules = attendedModules.map((el) => ({ ...el, name: el.title }));
        if (formType === 'edit') {
            if (row.linkedModuleId && !attendedModules.find((el) => el.id === row.linkedModuleId)) {
                modules = [...modules, { id: row.linkedModuleId, name: row.linkedModule }];
            }
        }
        setModules(modules);
    }, [attendedModules]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onSwitchHeightUnits = (e) => {
        const { checked } = e.target;
        setHeightOtherUnits(checked);
        clearHeightErrors();
        checked
            ? setNewEntry((prev) => ({ ...prev, height: '' }))
            : setImperialHeight(initialImperialHeight);
    };

    const onSwitchWeightUnits = (e) => {
        const { checked } = e.target;
        setWeightOtherUnits(checked);
        clearWeightErrors();
        checked
            ? setNewEntry((prev) => ({ ...prev, weight: '' }))
            : setImperialWeight(initialImperialWeight);
    };

    const onHeightChange = (value) => {
        clearError('height');
        clearWarning('height');
        setWarningHeightCheck(false);
        const height = value.replace(/[^\d*.?]/g, '').slice(0, 5);
        setNewEntry((prev) => ({ ...prev, height }));
        onHeightErrorCheck(height);
    };

    const onFeetChange = (value) => {
        clearError('heightFt');
        clearError('height');
        clearWarning('heightFt');
        const ft = value.replace(/[^\d]/g, '').slice(0, 2);
        setImperialHeight((prev) => ({ ...prev, ft }));
        const newErrors = checkHeightFt(ft, errors);
        const convertedValue = convertHeight(value, imperialHeight.ins);
        const newWarnings = checkHeightWarnings(convertedValue, warnings);
        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const onInchChange = (value) => {
        clearError('heightIn');
        clearWarning('heightIns');
        const ins = value.replace(/[^\d]/g, '').slice(0, 2);
        setImperialHeight((prev) => ({ ...prev, ins }));
        const newErrors = checkHeightIns(ins, errors);
        const convertedValue = convertHeight(imperialHeight.ft, value);
        const newWarnings = checkHeightWarnings(convertedValue, warnings);
        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const onWeightChange = (value) => {
        clearError('weight');
        clearWarning('weight');
        setWarningWeightCheck(false);
        const weight = value.replace(/[^\d*.?]/g, '').slice(0, 5);
        setNewEntry((prev) => ({ ...prev, weight }));
        onWeightErrorCheck(weight);
    };

    const onStoneChange = (value) => {
        clearError('weightSt');
        clearError('weight');
        clearWarning('weightSt');
        const st = value.replace(/[^\d]/g, '').slice(0, 2);
        setImperialWeight((prev) => ({ ...prev, st }));
        const newErrors = checkWeightSt(st, errors);
        const convertedValue = convertWeight(value, imperialWeight.lbs);
        const newWarnings = checkWeightWarnings(convertedValue, warnings);
        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const onLbsChange = (value) => {
        clearError('weightLb');
        clearWarning('weightLbs');
        const lbs = value.replace(/[^\d]/g, '').slice(0, 2);
        setImperialWeight((prev) => ({ ...prev, lbs }));
        const newErrors = checkWeightLbs(lbs, errors);
        const convertedValue = convertWeight(imperialWeight.st, value);
        const newWarnings = checkWeightWarnings(convertedValue, warnings);
        setErrors(newErrors);
        setWarnings(newWarnings);
    };

    const onDateChange = (date) => {
        clearError('eventDate');
        setNewEntry((prev) => ({ ...prev, eventDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // eslint-disable-next-line
        const { displayDate, linkedModule, lost, ...rest } = newEntry;
        let payload = rest;
        if (imperialHeight.ft) {
            const height = convertHeight(imperialHeight.ft, imperialHeight.ins);
            payload = { ...payload, height };
        }
        if (imperialWeight.st) {
            const weight = convertWeight(imperialWeight.st, imperialWeight.lbs);
            payload = { ...payload, weight };
        }

        const { isValid, newErrors, newWarnings } = validate(
            payload,
            errors,
            warnings,
            warningHeightCheck,
            warningWeightCheck
        );
        setErrors(newErrors);
        setWarnings(newWarnings);
        if (!isValid) return;
        payload = { ...payload, participantId: currentParticipant?.id };
        formType === 'create'
            ? dispatch(createBMI(payload, msg))
            : dispatch(updateBMI(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>{formType === 'create' ? 'Add a New BMI Value' : 'Edit BMI'}</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div>
                            <PolarisSwitchSet
                                id="heightOtherUnits"
                                label="Height"
                                leftLabel="cm"
                                rightLabel="ft & in"
                                checked={heightOtherUnits}
                                onSwitch={onSwitchHeightUnits}
                                disabled={false}
                            />
                        </div>

                        <div className={form.formSet}>
                            {heightOtherUnits ? (
                                <div className={form.formColumnSplit}>
                                    <div className={form.colSplitSection}>
                                        <LabelledTextField
                                            label={'Height (ft)'}
                                            id={'heightFt'}
                                            mandatory={true}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            value={imperialHeight.ft}
                                            placeholder={'Enter feet value'}
                                            onChange={(e) => onFeetChange(e.target.value)}
                                        />
                                        {errors.heightFt.error && (
                                            <div className={form.textInputError}>
                                                <IconError text={errors.heightFt} />
                                            </div>
                                        )}
                                        {errors.height.error && (
                                            <div className={form.textInputError}>
                                                <IconError text={errors.height} />
                                            </div>
                                        )}
                                    </div>
                                    <div className={form.colSplitSection}>
                                        <LabelledTextField
                                            label={'Height (in)'}
                                            id={'heightIn'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            value={imperialHeight.ins}
                                            placeholder={'Enter inches value'}
                                            onChange={(e) => onInchChange(e.target.value)}
                                        />
                                        {errors.heightIn.error && (
                                            <div className={form.textInputError}>
                                                <IconError text={errors.heightIn} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <LabelledTextField
                                        label={'Height (cm)'}
                                        id={'height'}
                                        mandatory={true}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        value={newEntry.height}
                                        placeholder={'Enter height'}
                                        onChange={(e) => onHeightChange(e.target.value)}
                                    />
                                    {errors.height.error && (
                                        <div className={form.textInputError}>
                                            <IconError text={errors.height} />
                                        </div>
                                    )}
                                </>
                            )}
                            {!errors.heightIn.error &&
                                !errors.height.error &&
                                warnings.height.warning && (
                                    <div
                                        className={`${form.fieldWarning} ${
                                            warningHeightCheck ? `${form.fieldWarningChecked}` : ''
                                        }`}>
                                        {warnings.height.message}
                                        <span
                                            className={form.checkCircle}
                                            onClick={() =>
                                                setWarningHeightCheck(!warningHeightCheck)
                                            }>
                                            {warningHeightCheck ? (
                                                <CheckCircleOutlineOutlinedIcon />
                                            ) : (
                                                <CancelOutlinedIcon />
                                            )}
                                        </span>
                                    </div>
                                )}
                        </div>
                        <div className={form.alignDown}>
                            <DDLOptionPicker
                                label={'Linked Module'}
                                id={'linkedModule'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={modules || []}
                                chosenName={newEntry.linkedModule}
                                chosenId={newEntry.linkedModuleId}
                                onChange={(chosenId) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        linkedModuleId: chosenId
                                    }))
                                }></DDLOptionPicker>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <div>
                            <PolarisSwitchSet
                                id="weightOtherUnits"
                                label="Weight"
                                leftLabel="kg"
                                rightLabel="st & lbs"
                                checked={weightOtherUnits}
                                onSwitch={onSwitchWeightUnits}
                                disabled={false}
                            />
                        </div>

                        <div className={form.formSet}>
                            {weightOtherUnits ? (
                                <div className={form.formColumnSplit}>
                                    <div className={form.colSplitSection}>
                                        <LabelledTextField
                                            label={'Weight (st)'}
                                            id={'weightSt'}
                                            mandatory={true}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            value={imperialWeight.st}
                                            placeholder={'Enter stone value'}
                                            onChange={(e) => onStoneChange(e.target.value)}
                                        />
                                        {errors.weightSt.error && (
                                            <div className={form.textInputError}>
                                                <IconError text={errors.weightSt} />
                                            </div>
                                        )}
                                        {errors.weight.error && (
                                            <div className={form.textInputError}>
                                                <IconError text={errors.weight} />
                                            </div>
                                        )}
                                    </div>
                                    <div className={form.colSplitSection}>
                                        <LabelledTextField
                                            label={'Weight (lb)'}
                                            id={'weightLb'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            value={imperialWeight.lbs}
                                            placeholder={'Enter pounds value'}
                                            onChange={(e) => onLbsChange(e.target.value)}
                                        />
                                        {errors.weightLb.error && (
                                            <div className={form.textInputError}>
                                                <IconError text={errors.weightLb} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <LabelledTextField
                                        label={'Weight (kg)'}
                                        id={'weight'}
                                        mandatory={true}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        value={newEntry.weight}
                                        placeholder={'Enter weight'}
                                        onChange={(e) => onWeightChange(e.target.value)}
                                    />
                                    {errors.weight.error && (
                                        <div className={form.textInputError}>
                                            <IconError text={errors.weight} />
                                        </div>
                                    )}
                                </>
                            )}
                            {!errors.weightLb.error &&
                                !errors.weight.error &&
                                warnings.weight.warning && (
                                    <div
                                        className={`${form.fieldWarning} ${
                                            warningWeightCheck ? `${form.fieldWarningChecked}` : ''
                                        }`}>
                                        {warnings.weight.message}
                                        <span
                                            className={form.checkCircle}
                                            onClick={() =>
                                                setWarningWeightCheck(!warningWeightCheck)
                                            }>
                                            {warningWeightCheck ? (
                                                <CheckCircleOutlineOutlinedIcon />
                                            ) : (
                                                <CancelOutlinedIcon />
                                            )}
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={formType === 'create' ? 'Save' : 'Update'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateBMIEntry;

CreateBMIEntry.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    attendedModules: PropTypes.arrayOf(PropTypes.object),
    formType: PropTypes.string,
    row: PropTypes.object
};
