import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from '@mui/material';

import { POLARIS_ROYAL_BLUE } from '../themes/theme';

import classes from './iconStyles.module.css';

const OnExitIcon = ({ onClick, title = 'Close' }) => {
    return (
        <Tooltip title={title} placement={'top'}>
            <div className={classes.exitIcon} onClick={onClick}>
                <ClearIcon style={{ fill: POLARIS_ROYAL_BLUE }} aria-label="exit-icon" />
            </div>
        </Tooltip>
    );
};

export default OnExitIcon;

OnExitIcon.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string
};
