import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
    selectQuestionnaireAnswers,
    selectQuestionnaires
} from '../../../../store/participantSelectors';
import { formatTime, reverseFormatDate } from '../../../../utils/dateFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const QuestionnaireTable = ({ onIsLoaded }) => {
    const questionnaireRef = useRef(null);
    const [rows, setRows] = useState([]);

    const questionnaires = useSelector(selectQuestionnaires);
    const questionnaireAnswers = useSelector(selectQuestionnaireAnswers);

    useEffect(() => {
        if (questionnaireAnswers?.length < 1) {
            onIsLoaded('questionnaire');
            return;
        }
        if (questionnaireAnswers?.length < 1) return;
        const data = questionnaireAnswers.map((el) => ({
            code: el?.code || ' - ',
            title: questionnaires.find((entry) => entry.id === el.questionnaireId)?.title || ' - ',
            dateStarted: el?.dateStarted ? reverseFormatDate(el?.dateStarted) : ' - ',
            dateCompleted: el?.dateCompleted ? reverseFormatDate(el?.dateCompleted) : ' - ',
            time: el?.dateCompleted ? formatTime(el.dateCompleted) : ' - ',
            status: el?.draft ? 'In-Progress' : 'Completed'
        }));
        setRows(data);
    }, [questionnaires, questionnaireAnswers]);

    useEffect(() => {
        questionnaireRef.current?.id.includes(`--${rows.length - 1}`) &&
            onIsLoaded('questionnaire');
    }, [questionnaireRef.current]);

    return (
        <div>
            <h3>Questionnaire</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>ID</span>
                    <span>Type</span>
                    <span>Date Started</span>
                    <span>Completed Date</span>
                    <span className={classes.alignColInMed}>Time</span>
                    <span>Status</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (questionnaireRef.current = el)}
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.title}</span>
                                <span>{el.dateStarted}</span>
                                <span
                                    className={`${
                                        el.dateCompleted === ' - '
                                            ? classes.alignColInMax
                                            : classes.alignColInMed
                                    }`}>
                                    {el.dateCompleted}
                                </span>
                                <span
                                    className={`${
                                        el.time === ' - '
                                            ? classes.alignColInMedPlus
                                            : classes.alignColInMed
                                    }`}>
                                    {el.time}
                                </span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuestionnaireTable;

QuestionnaireTable.propTypes = {
    onIsLoaded: PropTypes.func
};
