import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadMaximumTravelDistanceDetails } from '../../../../../store/directusService';
import { loadVacanciesByDistance } from '../../../../../store/recruitmentService';
import { selectUsersForVacancies } from '../../../../../store/userSelectors';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import DynamicSearch from '../../../../search/DynamicSearch';
import LoadingSpinner from '../../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';

import VacancySearchTable from './VacancySearchTable';

import classes from '../../submissionStyles/vacancy.module.css';

const VacancySearchTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [activeVacancies, setActiveVacancies] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMaxTravelToWork, setSelectedMaxTravelToWork] = useState('');
    const [vacanciesLoaded, setVacanciesLoaded] = useState(false);

    const [isClearSelectedMaxTravelToWork, setIsClearSelectedMaxTravelToWork] = useState('0');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const usersForVacancies = useSelector(selectUsersForVacancies);
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const businesses = useSelector((state) => state.entities.recruitmentService.businesses);
    const { vacanciesForDistance, vacanciesForOwners } = useSelector(
        (state) => state.entities.recruitmentService
    );
    const { maximumTravelDistanceDetails } = useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    const handleMaximumTravelToWork = (chosenId) => {
        if (!chosenId) {
            chosenId = '';
            setRows(activeVacancies);
            setVacanciesLoaded(false);
        }
        setSelectedMaxTravelToWork(chosenId);
    };

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // USE EFFECTS
    useEffect(() => {
        setIsClearSelectedMaxTravelToWork(Math.random());

        dispatch(loadMaximumTravelDistanceDetails());
    }, []);

    useEffect(() => {
        if (vacanciesForOwners?.length < 1 || !usersForVacancies || usersForVacancies?.length < 1) {
            setRows([]);
            setActiveVacancies([]);
            return;
        }

        const searchOwnerVacancies = vacanciesForOwners.map((el) => ({
            ...el,
            businessName: businesses.find((entry) => entry.id === el.businessRecordId)?.name,
            adviser:
                usersForVacancies.find((entry) => entry.id === el?.ownerId)?.firstName +
                ' ' +
                usersForVacancies.find((entry) => entry.id === el?.ownerId)?.lastName
        }));
        setRows(searchOwnerVacancies);
        setActiveVacancies(searchOwnerVacancies);
    }, [vacanciesForOwners, usersForVacancies]);

    useEffect(() => {
        if (selectedMaxTravelToWork?.length < 1) return;

        dispatch(
            loadVacanciesByDistance({
                latitude: currentParticipant.latitude,
                longitude: currentParticipant.longitude,
                maximumTravelDistanceId: selectedMaxTravelToWork
            })
        );
    }, [selectedMaxTravelToWork]);

    useEffect(() => {
        if (successMessage === `Vacancies have been loaded for distance`) {
            setVacanciesLoaded(true);
            if (vacanciesForDistance?.length < 1) {
                setRows([]);
            } else {
                const searchOwnerVacancies = vacanciesForDistance.map((el) => ({
                    ...el,
                    businessName: businesses.find((entry) => entry.id === el.businessRecordId)
                        ?.name,
                    adviser:
                        usersForVacancies.find((entry) => entry.id === el?.ownerId)?.firstName +
                        ' ' +
                        usersForVacancies.find((entry) => entry.id === el?.ownerId)?.lastName
                }));
                setRows(searchOwnerVacancies);
            }
        }
    }, [successMessage]);

    useEffect(() => {
        const rows = !searchTerm
            ? activeVacancies
            : activeVacancies.filter((el) =>
                  el.title.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setRows(rows);
    }, [searchTerm]);

    // RENDER
    let content = '';
    if (maximumTravelDistanceDetails?.length < 1) content = 'No maximum travel distance details';
    if (maximumTravelDistanceDetails?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <>
            <br />
            <div className={classes.vacanciesToolBar}>
                <DynamicSearch
                    search={onSearch}
                    disabled={vacanciesLoaded}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                />
                <DDLOptionPicker
                    label={'Maximum Travel To Work'}
                    id={'maximumTravelToWork'}
                    key={isClearSelectedMaxTravelToWork}
                    disabled={!!(!hasRole(acceptedRoles, roles) || searchTerm)}
                    sx={{ width: '160px', marginTop: '11px' }}
                    menuItems={maximumTravelDistanceDetails}
                    onChange={(chosenId) => handleMaximumTravelToWork(chosenId)}
                />
            </div>

            {rows.length < 1 ? (
                <LoadingSpinner content="No vacancies found" />
            ) : (
                <VacancySearchTable rows={rows} searchTerm={searchTerm} />
            )}
        </>
    );
};

export default VacancySearchTableManagement;
