import { createTheme } from '@mui/material/styles';

export const POLARIS_BLACK = '#000000';
export const POLARIS_BLUE = '#081351';
export const POLARIS_DARK_SLATE_GREY = '#333333';
export const POLARIS_DARKER_GREY = '#888888';
export const POLARIS_GREY = '#EEEDEE';
export const POLARIS_ROYAL_BLUE = '#1E1EF0';
export const POLARIS_WHITE = '#FFFFFF';
// mostly for backgrounds.
export const POLARIS_CREAM = '#FAF7EF';
export const POLARIS_DOUBLE_DECKER_RED = '#E32424';
export const POLARIS_GREEN = '#157A3F';
export const POLARIS_LIBERTY_LILAC = '#7855C3';
export const POLARIS_METROPOLITAN_CHERRY = '#A00A55';
export const POLARIS_ORANGE = '#FF6400';
export const POLARIS_MIKADO_YELLOW = '#FFC40C';
export const POLARIS_TOTTENHAM_TEAL = '#19D2C3';
export const POLARIS_VICTORIA_BLUE_BG = '#EEFAFC';
export const POLARIS_VICTORIA_BLUE = '#6FCFE1';

export const appTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: POLARIS_BLUE
        },
        secondary: {
            main: POLARIS_WHITE
        },
        action: {
            main: POLARIS_ROYAL_BLUE
        },
        info: {
            main: POLARIS_VICTORIA_BLUE
        },
        disabled: {
            main: POLARIS_DARKER_GREY
        },
        error: {
            main: POLARIS_DOUBLE_DECKER_RED
        }
    },

    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        height: '6px',
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: POLARIS_VICTORIA_BLUE,
                        borderRadius: '16px'
                    }
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        height: '6px',
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: POLARIS_VICTORIA_BLUE,
                        borderRadius: '16px'
                    }
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    // Over-ride LOWER-CASE button text.
                    textTransform: 'none',
                    margin: '1em 0 1em 0',
                    fontSize: '12px',
                    fontWeight: '700',
                    borderRadius: '2px',
                    background: POLARIS_ROYAL_BLUE
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    flexDirection: 'row-reverse',
                    '&:before': {
                        backgroundColor: 'transparent !important'
                    }
                }
            }
        },

        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: POLARIS_VICTORIA_BLUE,
                        borderRadius: '16px'
                    }
                },

                option: {
                    '&[data-focus="true"]': {
                        backgroundColor: POLARIS_VICTORIA_BLUE + ' !important'
                    },
                    '&[aria-selected="true"]': {
                        // selected menuItems
                        color: POLARIS_WHITE + ' !important',
                        backgroundColor: POLARIS_VICTORIA_BLUE + ' !important'
                    },
                    '&:hover': {
                        color: POLARIS_WHITE + ' !important',
                        backgroundColor: POLARIS_VICTORIA_BLUE + ' !important'
                    },
                    backgroundColor: POLARIS_WHITE + ' !important'
                },
                listbox: {
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#fff'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: POLARIS_VICTORIA_BLUE,
                        borderRadius: '16px'
                    },
                    borderLeft: `1px solid ${POLARIS_GREY}`,
                    borderRight: `1px solid ${POLARIS_GREY}`
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: POLARIS_VICTORIA_BLUE_BG
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    maxWidth: '100vw',
                    margin: '0.5em 0',
                    borderRadius: '0',
                    background: POLARIS_VICTORIA_BLUE_BG
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: POLARIS_DARK_SLATE_GREY,
                    fontSize: '16px',
                    fontWeight: '600'
                }
            }
        },
        MuiLabel: {
            styleOverrides: {
                root: {
                    margin: '1em 0 1em 0',
                    fontSize: '12px'
                }
            }
        },
        MuiMenuItem: {
            // For ListItem, change this to MuiListItem
            root: {
                '&$selected': {
                    background: POLARIS_VICTORIA_BLUE
                }
            }
        },
        MuiListItem: {},
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: POLARIS_WHITE
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    width: 'auto',
                    maxWidth: '100%',
                    background: POLARIS_WHITE
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '14px',
                    color: POLARIS_ROYAL_BLUE,
                    backgroundColor: POLARIS_WHITE,
                    border: `1px solid ${POLARIS_ROYAL_BLUE}`
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: POLARIS_WHITE
                }
            }
        }
    },

    typography: {
        fontFamily: ['Red Hat Display', 'Arial', 'Nunito', 'Roboto', 'sans-serif'].join(','),
        h1: {
            fontSize: 64,
            fontWeight: 700
        },
        h2: {
            fontSize: 48,
            fontWeight: 700
        },
        h3: {
            fontSize: 36,
            fontWeight: 700
        },

        h4: {
            fontSize: 24,
            fontWeight: 700
        },

        h5: {
            fontSize: 18,
            fontWeight: 700
        },

        h6: {
            fontSize: 16,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: 24,
            fontWeight: 700
        },
        subtitle2: {
            fontSize: 20,
            fontWeight: 700
        },
        subtitle3: {
            fontSize: 14,
            fontWeight: 700
        },
        body1Bold: {
            fontSize: 15,
            fontWeight: 700
        },
        body1: {
            fontSize: 14,
            fontWeight: 400
        },
        body2: {
            fontSize: 12,
            fontWeight: 400
        }
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
});

export const SCREEN_SETTINGS = {
    margin: {
        none: '0px',
        tiny: '2px',
        small: '5px',
        medium: '10px',
        large: '20px',
        huge: '40px'
    },
    padding: {
        none: '0px',
        tiny: '2px',
        small: '5px',
        medium: '10px',
        large: '20px',
        huge: '40px'
    },
    // Us the for small horizontal or vertical gaps
    gap: {
        none: '0px',
        tiny: '2px',
        small: '5px',
        medium: '10px',
        large: '20px',
        huge: '40px'
    },
    radius: {
        none: '0px',
        tiny: '2px',
        small: '5px',
        medium: '10px',
        large: '20px',
        huge: '40px'
    },
    maxHeight: {
        medium: '400px'
    }
};
