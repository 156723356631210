import { getTotalTimeToSignificantValue } from '../dateFunctions';

export const getCharMaxMessage = (subject, maxLength) =>
    `${subject} must be ${maxLength} characters or fewer.`;

export const getCharMinMessage = (subject, minLength) => {
    if (minLength === 1) return `${subject} must be at least ${minLength} character.`;
    return `${subject} must be at least ${minLength} characters.`;
};

export const getDateMinMessage = (date1, date2) =>
    `${date1} cannot be earlier or the same as ${date2}.`;

export const getDateMaxMessage = (date1, date2) => `${date1} cannot be later than ${date2}.`;

export const getDuplicatedMessage = (subject) => `${subject} must be unique.`;

export const getFormatMessage = (subject) => `${subject} must be in the correct format.`;

export const getIsLengthMessage = (subject, length) => {
    if (length === 1) return `${subject} must be ${length} character.`;
    return `${subject} must be ${length} characters.`;
};

export const getMatchMessage = (subject, matchSubject) =>
    `${subject} does not match ${matchSubject}.`;

export const getNoUpdateMessage = () => 'There is nothing to update.';

export const getNumMaxMessage = (subject, maxLength) =>
    `${subject} cannot be greater than ${maxLength}.`;

export const getNumMinMessage = (subject, maxLength) =>
    `${subject} cannot be lesser than ${maxLength}.`;

export const getReqMessage = (subject) => `${subject} is required.`;

export const getSelectMessage = (subject, indefiniteArticle = 'a') =>
    `Please select ${indefiniteArticle} ${subject}.`;

export const getYoungMessage = (subject, minAge) =>
    `${subject} must be more than ${getTotalTimeToSignificantValue(minAge)} ago.`;

export const getSelectValueMessage = () => 'Please select a value';

export const getMustBeBeforeEndDateMessage = () => 'Must be before end date';
