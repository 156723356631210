import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Button, Link, Typography } from '@mui/material';

import { loginRequest, msalConfig, msalInstance } from '../../masl/authConfig';
import Settings from '../../settings/Settings';
import { clearLocalStorage } from '../../store/userService';
import General from '../navigation/General';
import NavigationBar from '../navigation/NavigationBar';

import classes from '../../app.module.css';

const Header = () => {
    const navigate = useNavigate();

    const { firstName, lastName } = useSelector((state) => state.entities.userService.loggedInUser);
    const { idToken } = useSelector((state) => state.entities.userService);
    const name = `${firstName} ${lastName}`;

    const login = () => {
        msalInstance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const logoutRequest = {
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        idTokenHint: idToken,
        mainWindowRedirectUrl: msalConfig.auth.redirectUri
    };

    const logout = () => {
        msalInstance.logoutRedirect(logoutRequest);
        clearLocalStorage();
    };

    return (
        <div className={classes.header}>
            <div className={classes.topBar}>
                <Link
                    color="inherit"
                    onClick={() => {
                        navigate('/home');
                    }}>
                    <RocketLaunchIcon fontSize={'large'} htmlColor="#fff" elevation={3} />
                </Link>

                <AuthenticatedTemplate>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1.0 }}>
                        <span className={classes.headerTitle}>Welcome back {name || ''}</span>
                    </Typography>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1.0 }}>
                        <span className={classes.headerTitle}>
                            Welcome to Polaris. Please Login to Continue
                        </span>
                    </Typography>
                </UnauthenticatedTemplate>

                <General />

                <UnauthenticatedTemplate>
                    <Button color="primary" variant="contained" size={'small'} onClick={login}>
                        Login
                    </Button>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <div className={classes.settings}>
                        <Settings />
                    </div>
                    <Button color="primary" variant="contained" size={'small'} onClick={logout}>
                        Logout
                    </Button>
                </AuthenticatedTemplate>
            </div>
            <AuthenticatedTemplate>
                <div className={classes.navBar}>
                    <NavigationBar />
                </div>
            </AuthenticatedTemplate>
        </div>
    );
};

Header.propTypes = {
    text: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string
};

export default Header;
