import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classes from '../../../../commonStyles/pdf.module.css';

const JobGoalTable = ({ onIsLoaded }) => {
    const jobGoalRef = useRef(null);

    const [rows, setRows] = useState([]);

    const jobSectors = useSelector((state) => state.entities.directusService.jobSectorDetails);
    const jobGoals = useSelector(
        (state) => state.entities.participantService.currentParticipantJobGoals
    );

    useEffect(() => {
        if (jobSectors?.length < 1) return;
        if (jobGoals?.length < 1) {
            onIsLoaded('jobReadiness');
            return;
        }
        const data = jobGoals.map((el) => ({
            id: el.id,
            jobSector: jobSectors.find((entry) => entry.id === el.sectorId)?.name || '-',
            jobTitle: el.title
        }));
        setRows(data);
    }, [jobGoals, jobSectors]);

    useEffect(() => {
        jobGoalRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('jobReadiness');
    }, [jobGoalRef.current]);

    return (
        <div>
            <h3>Job Goals</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>Job Sector</span>
                    <span>Job Title</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (jobGoalRef.current = el)}
                                key={el.id}
                                id={`${el.id}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.jobSector}</span>
                                <span>{el.jobTitle}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default JobGoalTable;

JobGoalTable.propTypes = {
    onIsLoaded: PropTypes.func
};
