import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogTitle } from '@mui/material';

import { loadClaimQueueActionStatusDetails } from '../../../store/directusService';
import { updateClaimQueueActionStatus } from '../../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import IconError from '../../IconError';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import {
    initialErrorState,
    NOTE_CHAR_LIMIT,
    validateNotes,
    validateStatusId
} from './errorValidation';

import form from '../../../commonStyles/formStyles.module.css';
import local from '../claimStyles/validationModal.module.css';

const ActionEditor = ({ roles, isOpen, onCancel, formType, onUpdate, selectedRow }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const catOnlyRoles = [ADVISER, RECRUITMENT_MANAGER];
    const catCarEscalatedRoles = [MANAGER];
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const initialState = {
        id: '',
        actionStatusId: '',
        actionStatus: '',
        actionNotes: ''
    };
    const [actionStatuses, setActionStatuses] = useState([]);
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);

    // STORE STATE
    const claimQueueActionStatusDetails = useSelector(
        (state) => state.entities.directusService.claimQueueActionStatusDetails
    );

    // HELPER FNS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (roles.length < 1 || claimQueueActionStatusDetails?.length < 1) return;

        const catStatus = claimQueueActionStatusDetails.find(
            (el) => el.name === 'Corrective Action Taken'
        );
        const carStatus = claimQueueActionStatusDetails.find(
            (el) => el.name === 'Corrective Action Required'
        );
        const carEscalatedStatus = claimQueueActionStatusDetails.find(
            (el) => el.name === 'Escalate Corrective Action Required'
        );
        const actionStatus =
            claimQueueActionStatusDetails.find((el) => el.id === selectedRow.actionStatusId)
                ?.name || '';

        setNewEntry((prev) => ({
            ...prev,
            id: selectedRow.id,
            actionStatusId: formType === 'create' ? carStatus.id : selectedRow.actionStatusId || '',
            actionStatus: actionStatus
        }));

        if (formType === 'edit') {
            if (roles.some((el) => acceptedRoles.includes(el)))
                setActionStatuses(claimQueueActionStatusDetails);
            if (roles.some((el) => catOnlyRoles.includes(el))) setActionStatuses([catStatus]);
            if (roles.some((el) => catCarEscalatedRoles.includes(el)))
                setActionStatuses([carEscalatedStatus, catStatus]);
        }
    }, [roles, claimQueueActionStatusDetails]);

    // EVENT HANDLERS
    const onSubmit = (e) => {
        e.preventDefault();
        if (formType === 'edit') {
            const { isValid, newErrors } = validateStatusId(newEntry, errors);
            setErrors(newErrors);
            if (!isValid) return;
        }
        const { isValid, newErrors } = validateNotes(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        // eslint-disable-next-line
        const { actionStatus, actionNotes, ...rest } = newEntry;
        const payload = {
            ...rest,
            actionNotes: actionNotes
        };
        dispatch(updateClaimQueueActionStatus(payload));
        onUpdate();
    };

    // RENDER

    return (
        <div>
            <Dialog onClose={onCancel} open={isOpen} fullWidth={true}>
                <DialogTitle>{formType === 'edit' ? 'Update Action' : 'Create Action'}</DialogTitle>

                <div className={local.modalContent}>
                    <form onSubmit={onSubmit} className={form.formWrapper}>
                        {formType === 'create' ? (
                            <div className={form.staticField}>
                                <h3>Corrective Activity Status</h3>
                                Corrective Activity Required
                            </div>
                        ) : (
                            <>
                                <DDLOptionPicker
                                    label={'Corrective Activity Status'}
                                    id={'actionStatusId'}
                                    disabled={
                                        !hasRole(
                                            [
                                                ...acceptedRoles,
                                                ...catOnlyRoles,
                                                ...catCarEscalatedRoles
                                            ],
                                            roles
                                        )
                                    }
                                    mandatory={true}
                                    menuItems={actionStatuses || []}
                                    chosenName={newEntry.actionStatus}
                                    chosenId={
                                        newEntry.actionStatusId || selectedRow?.actionStatusId || ''
                                    }
                                    onChange={(chosenId) => {
                                        clearError('actionStatusId');
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            actionStatusId: chosenId
                                        }));
                                    }}></DDLOptionPicker>
                                {errors.actionStatusId.error && (
                                    <div className={form.selectInputError}>
                                        <IconError text={errors.actionStatusId} />
                                    </div>
                                )}
                            </>
                        )}

                        <div className={form.textAreaSet}>
                            <label htmlFor="note">Notes *</label>
                            <textarea
                                className={form.textAreaText}
                                id="actionNotes"
                                rows={8}
                                maxLength={NOTE_CHAR_LIMIT}
                                value={newEntry.actionNotes || ''}
                                placeholder={'Notes'}
                                disabled={
                                    !hasRole(
                                        [
                                            ...acceptedRoles,
                                            ...catOnlyRoles,
                                            ...catCarEscalatedRoles
                                        ],
                                        roles
                                    )
                                }
                                onChange={(e) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        actionNotes: e.target.value
                                    }))
                                }
                            />
                            <div>
                                {newEntry.actionNotes?.length}/{NOTE_CHAR_LIMIT}
                            </div>
                        </div>
                        {errors.actionNotes.error && (
                            <div className={`${form.textAreaSetError}`}>
                                <IconError text={errors.actionNotes} />
                            </div>
                        )}

                        <div className={local.actionButtons}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={
                                    !hasRole(
                                        [
                                            ...acceptedRoles,
                                            ...catOnlyRoles,
                                            ...catCarEscalatedRoles
                                        ],
                                        roles
                                    )
                                }
                                onClick={onSubmit}>
                                {formType === 'create' ? 'Create' : 'Update'}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={
                                    !hasRole(
                                        [
                                            ...acceptedRoles,
                                            ...catOnlyRoles,
                                            ...catCarEscalatedRoles
                                        ],
                                        roles
                                    )
                                }
                                onClick={onCancel}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ActionEditor;

ActionEditor.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    formType: PropTypes.string,
    onUpdate: PropTypes.func,
    selectedRow: PropTypes.object
};
