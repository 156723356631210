import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import {
    selectCurrentParticipant,
    selectRecentParticipants
} from '../../store/participantSelectors';
import {
    loadParticipantByPtCode,
    loadParticipantsSimpleSearch,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../store/participantService';

import SearchOnEnter from './SearchOnEnter';

import classes from './searchStyles/quickSearch.module.css';

const QuickSearchParticipant = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [term, setTerm] = useState('');
    const [active, setActive] = useState(false);
    const [ptCode, setPtCode] = useState('');
    const [recent, setRecent] = useState(false);

    const recentParticipants = useSelector(selectRecentParticipants);
    const currentParticipant = useSelector(selectCurrentParticipant);

    useEffect(() => {
        dispatch(loadRecentParticipants());
    }, []);

    useEffect(() => {
        if (currentParticipant.ptCode === ptCode && recent) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setRecent(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            setActive(false);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (active) document.addEventListener('click', onClose);

        return () => document.removeEventListener('click', onClose);
    }, [active]);

    const onClose = (e) => {
        const searchEl = document.getElementById('ptQuickSearch');
        if (e && typeof e.composedPath === 'function' && !e.composedPath().includes(searchEl)) {
            setActive(false);
        }
    };

    const onTermChange = (value) => {
        if (value !== '') {
            active && setActive(false);
        }
        setTerm(value);
    };

    const onSearch = () => {
        if (term.trim().length < 3) return;
        dispatch(loadParticipantsSimpleSearch(term));
        location.pathname !== '/participant_search_results' &&
            navigate('/participant_search_results');
    };

    const onSearchBarClick = () => {
        setActive(term === '');
    };

    const onNav = (ptCode) => {
        setPtCode(ptCode);
        dispatch(loadParticipantByPtCode(ptCode));
        setRecent(true);
    };

    return (
        <div className={classes.headerSearch} onClick={onSearchBarClick} id="ptQuickSearch">
            <ul className={`${classes.recentList} ${active ? classes.showRecentList : ''}`}>
                {recentParticipants.map((el) => (
                    <li key={el.ptCode} onClick={() => onNav(el.ptCode)}>
                        <span className={classes.firstCol}>{el.ptCode}</span>
                        <span>
                            {el.firstName} {el.lastName}
                        </span>
                    </li>
                ))}
            </ul>

            <div className={classes.searchbar}>
                <SearchOnEnter
                    searchTerm={term}
                    placeholder="Search participants..."
                    autocomplete="off"
                    search={onSearch}
                    onChange={onTermChange}
                />
            </div>
        </div>
    );
};
export default QuickSearchParticipant;
