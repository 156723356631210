import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { loadAttendanceDetails } from '../../../store/directusService';
import { loadAppointmentAttendanceDetailsBySearch } from '../../../store/participantService';

import '../../../app.module.css';
import '../styles/startAppointmentButtonStyles.css';

const StartAppointmentButton = ({ show, onClick, disabled, appointment, participant }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [attendanceStatus, setAttendanceStatus] = useState('Not known');
    const [participantAppointmentId, setParticipantAppointmentId] = useState('');

    // STORE STATE
    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );
    const participantAttendanceDetails = useSelector(
        (state) => state.entities.participantService.appointmentAttendanceDetails
    );

    useEffect(() => {
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (appointment?.id)
            dispatch(
                loadAppointmentAttendanceDetailsBySearch({
                    calendarServiceAppointmentIds: [appointment.id]
                })
            );
    }, [appointment?.id]);

    useEffect(() => {
        if (
            attendanceDetails?.length < 1 ||
            participantAttendanceDetails?.length < 1 ||
            !appointment?.participantId
        )
            return;
        const ptApp = participantAttendanceDetails.find(
            (el) => el.participantId === appointment?.participantId
        );
        setParticipantAppointmentId(ptApp?.id || '');
        const status =
            attendanceDetails.find((el) => el.id === ptApp?.attendanceId)?.name || 'Not known';
        setAttendanceStatus(status);
    }, [participantAttendanceDetails, attendanceDetails, appointment?.participantId]);

    const onStartAppointment = () => {
        let appointmentId = participantAppointmentId;
        if (!appointmentId) {
            appointmentId = participantAttendanceDetails.find(
                (el) => el.participantId === appointment?.participantId
            )?.id;
        }
        if (disabled || !appointmentId) return;
        onClick();
        navigate('/appointments', {
            state: { appointment, participantAppointmentId, returnPath: location.pathname }
        });
    };

    const onNav = () => {
        if (disabled) return;
        onClick();
        navigate('/edit_participant');
    };

    return (
        <div className={`start-app-btn ${show ? 'show-btn' : ''}`}>
            <div className="appointmentButtonRow startBtnAlign fadein">
                <span className={`rowName ${!disabled ? 'rowNameLink' : ''}`} onClick={onNav}>
                    {`${participant?.firstName} ${participant?.lastName}`}
                </span>
                <span className="rowButton">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={
                            disabled ||
                            (attendanceStatus && attendanceStatus !== 'Not known') ||
                            !participantAppointmentId
                        }
                        onClick={onStartAppointment}>
                        Start Appointment
                    </Button>
                </span>
                <span className="rowStatus">{attendanceStatus}</span>
            </div>
        </div>
    );
};

export default StartAppointmentButton;

StartAppointmentButton.propTypes = {
    show: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    appointment: PropTypes.object,
    participant: PropTypes.object
};
