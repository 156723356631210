import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadCommunicationTemplates } from '../../store/communicationService';
import {
    loadCommunicationTypes,
    loadContractDetailsForContractIds
} from '../../store/directusService';
import { selectLoggedInUser, selectUserRoles } from '../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../themes/theme';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import DynamicSearch from '../search/DynamicSearch';
import LoadingSpinner from '../ui/LoadingSpinner';

import CommunicationTemplateTable from './CommunicationTemplateTable';
import CreateTemplate from './CreateTemplate';
import EditTemplate from './EditTemplate';

import app from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';
import local from './communication.module.css';

const ManageCommunication = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER, LOCAL_ADMIN];
    const [templates, setTemplates] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const [selectedRow, setSelectedRow] = useState({});

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const roles = useSelector(selectUserRoles);
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );
    const communicationTemplates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );

    // USEEFFECTS
    useEffect(() => {
        dispatch(loadCommunicationTemplates());
        if (!communicationTypes?.length) dispatch(loadCommunicationTypes());
    }, []);

    useEffect(() => {
        if (!loggedInUser || loggedInUser?.contractIds?.length < 1) return;
        const ids = loggedInUser.contractIds;
        ids && dispatch(loadContractDetailsForContractIds(ids));
    }, [loggedInUser]);

    useEffect(() => {
        if (
            !communicationTypes ||
            communicationTypes?.length < 1 ||
            !Array.isArray(communicationTemplates)
        )
            return;
        const templates = communicationTemplates?.map((el) => ({
            ...el,
            dateCreated: el.dateCreated ? el.dateCreated : 'z',
            dateUpdated: el.dateUpdated ? el.dateUpdated : 'z',
            type: communicationTypes?.find((entry) => entry.id === el.typeId)?.name || ''
        }));
        setRows(templates);
        setTemplates(templates);
    }, [communicationTemplates, communicationTypes]);

    useEffect(() => {
        if (templates.length < 1) return;
        const rows = templates.filter((el) =>
            el.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setRows(rows);
    }, [searchTerm]);

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
    };

    return (
        <div className={app.container}>
            <div className={app.mainHeading}>Manage Communications Templates</div>
            <div className={app.blueContainer}>
                <div className={app.searchWrapper}>
                    <DynamicSearch
                        search={onSearch}
                        searchTerm={searchTerm}
                        placeholder="Enter search term"
                        disabled={!hasRole(acceptedRoles, roles)}
                    />
                </div>
                {rows.length < 1 ? (
                    <div className={local.noContent}>
                        <LoadingSpinner content="No templates found" />
                    </div>
                ) : (
                    <div className={local.tableContainer}>
                        <CommunicationTemplateTable
                            rows={rows}
                            searchTerm={searchTerm}
                            roles={roles}
                            onEdit={onEdit}
                        />
                    </div>
                )}

                <div className={form.formEnd}>
                    {!showForm && (
                        <Button
                            disabled={!hasRole(acceptedRoles, roles)}
                            color="primary"
                            variant="contained"
                            size={'small'}
                            onClick={onCreate}>
                            Create New Template
                            <Box width={SCREEN_SETTINGS.gap.medium} />
                            <AddCircleOutlineIcon />
                        </Button>
                    )}
                    <div>
                        {showForm && formType === 'create' && (
                            <CreateTemplate
                                onClose={() => setShowForm(false)}
                                roles={roles}
                                row={selectedRow}
                            />
                        )}

                        {showForm && formType === 'edit' && (
                            <EditTemplate
                                onClose={() => setShowForm(false)}
                                roles={roles}
                                row={selectedRow}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageCommunication;
