import { createSelector } from '@reduxjs/toolkit';

import { selectAttendedStatusId } from './dataSelectors';
import { selectCurrentParticipant } from './participantSelectors';

const selectCourseEntries = (state) => state.entities.courseService.courses;

const selectParticipantsCourseEntries = (state) => state.entities.courseService.participantsCourses;

const selectCourseModuleEntries = (state) => state.entities.courseService.courseModules;

const selectAllCourseModuleEntries = (state) => state.entities.courseService.allCourseModules;
const selectCurrentCourseEntry = (state) => state.entities.courseService.currentCourse;

const selectCurrentModuleEntry = (state) => state.entities.courseService.currentModule;

export const selectCourses = createSelector(selectCourseEntries, (courses) => courses);

export const selectParticipantsCourses = createSelector(
    selectParticipantsCourseEntries,
    (participantsCourses) => participantsCourses
);

export const selectParticipantAttendedModules = createSelector(
    selectParticipantsCourseEntries,
    selectAttendedStatusId,
    selectCurrentParticipant,
    (participantsCourses, attendedId, currentParticipant) => {
        const participantsModules = participantsCourses.map((el) => el.modules).flat();
        return participantsModules.filter((el) =>
            el.participants.some(
                (entry) =>
                    entry.participantId === currentParticipant.id && entry.statusId === attendedId
            )
        );
    }
);

export const selectCourseModules = createSelector(
    selectCourseModuleEntries,
    (courseModules) => courseModules
);

// This is for aggregated course modules - ie. for TimeView
export const selectAllCourseModules = createSelector(
    selectAllCourseModuleEntries,
    (allCourseModules) => allCourseModules
);

export const selectCurrentCourse = createSelector(
    selectCurrentCourseEntry,
    (currentCourse) => currentCourse
);

export const selectCurrentModule = createSelector(
    selectCurrentModuleEntry,
    (currentModule) => currentModule
);

export const selectCourseIds = createSelector(selectCourseModules, (courseModules) => [
    ...new Set(courseModules.map((el) => el.courseId))
]);

// This is for aggregated course ids - ie. for TimeView
export const selectAllCourseIds = createSelector(selectAllCourseModules, (allCourseModules) => [
    ...new Set(allCourseModules.map((el) => el.courseId))
]);
