import axios from 'axios';

import { getHeader } from '../../api/commonHTTP';
import { msalConfig, msalInstance } from '../../masl/authConfig';
import * as actions from '../api';
import { setErrorMessage, setSuccessMessage } from '../formsState';
import { clearLocalStorage, getAccessToken } from '../userService';

const api =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (
            action.type !== actions.apiUpload.type &&
            action.type !== actions.apiDownload.type &&
            action.type !== actions.apiCallBegan.type &&
            action.type !== actions.apiCallDirectusBegan.type &&
            action.type !== actions.apiSeqDelete.type &&
            action.type !== actions.apiSeqAdd.type
        )
            return next(action);

        const {
            url,
            method,
            data,
            filename,
            onStart,
            onSuccess,
            onError,
            successMessage,
            origin,
            onStartData
        } = action.payload;

        const startData = onStartData || data || '';
        if (onStart) dispatch({ type: onStart, payload: startData });

        next(action);

        if (action.type === actions.apiCallBegan.type) {
            try {
                const response = await axios.request({
                    url,
                    headers: getHeader(),
                    method,
                    data
                });
                // General success dispatch
                dispatch(actions.apiCallSuccess(response.data));

                if (successMessage) dispatch(setSuccessMessage(successMessage));

                // Specific success dispatch if present
                if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
            } catch (error) {
                // General error action
                dispatch(actions.apiCallFailed(error.message));

                // Tell formsState slice that error has occurred.
                if (error?.response?.data?.validationErrors)
                    dispatch(
                        setErrorMessage(
                            `${Object.keys(
                                error?.response?.data?.validationErrors
                            ).toString()} ${Object.values(
                                error?.response?.data?.validationErrors
                            ).toString()}`
                        )
                    );
                else if (error?.response?.data) {
                    dispatch(
                        setErrorMessage(
                            `${Object.keys(error?.response?.data).toString()} ${Object.values(
                                error?.response?.data
                            ).toString()}`
                        )
                    );
                    if (onError) dispatch({ type: onError, error: error?.response?.data });
                }
                // Specific error - dispatch if present
                else if (onError) dispatch({ type: onError, payload: error.message });
                else dispatch(setErrorMessage(error.message));
            }
        } else if (action.type === actions.apiUpload.type) {
            try {
                const response = await axios.request({
                    url,
                    headers: { ...getHeader(), 'Content-Type': 'multipart/form-data' },
                    method,
                    data
                });
                // General success dispatch
                dispatch(actions.apiCallSuccess(response.data));

                if (successMessage) dispatch(setSuccessMessage(successMessage));

                // Specific success dispatch if present
                if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
            } catch (error) {
                // General error action
                dispatch(actions.apiCallFailed(error.message));

                // Tell formsState slice that error has occurred.
                if (error?.response?.data?.validationErrors)
                    dispatch(setErrorMessage(error?.response?.data?.validationErrors));
                else if (error?.response?.data) {
                    if (onError) dispatch({ type: onError, error: error?.response?.data });
                    else setErrorMessage(error?.response?.data);
                }
                // Specific error - dispatch if present
                else if (onError) dispatch({ type: onError, payload: error.message });
                else dispatch(setErrorMessage(error.message));
            }
        } else if (action.type === actions.apiDownload.type) {
            try {
                const response = await axios.request({
                    url,
                    headers: getHeader(),
                    method,
                    responseType: 'blob'
                });
                const href = window.URL.createObjectURL(response.data);
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                anchorElement.download = filename;
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            } catch (error) {
                let message;
                // General error action
                if (error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else message = 'An error occurred during download';

                dispatch(actions.apiCallFailed(message));
            }
        } else if (action.type === actions.apiSeqDelete.type) {
            try {
                const BATCH_SIZE = 500;
                if (data.keys.length <= BATCH_SIZE) {
                    await axios.request({
                        url,
                        headers: getHeader(),
                        method,
                        data
                    });
                } else {
                    const entries = data.keys;
                    let count = 0;
                    const numBatches = Math.ceil(entries.length / BATCH_SIZE);
                    while (count < numBatches) {
                        const keys = entries.splice(0, BATCH_SIZE);
                        if (keys.length) {
                            const data = { keys };
                            await axios.request({
                                url,
                                headers: getHeader(),
                                method,
                                data
                            });
                        }
                        count++;
                    }
                }
                if (successMessage) dispatch(setSuccessMessage(successMessage));
                if (onSuccess) dispatch({ type: onSuccess });
            } catch (error) {
                let message;
                if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else message = 'An error has occurred';
                dispatch(actions.apiCallFailed(message));
                dispatch(setErrorMessage(message));
                if (onError) dispatch({ type: onError, payload: { origin, message } });
            }
        } else if (action.type === actions.apiSeqAdd.type) {
            try {
                const BATCH_SIZE = 500;
                let response;
                if (data.length <= BATCH_SIZE) {
                    response = await axios.request({
                        url,
                        headers: getHeader(),
                        method,
                        data
                    });
                } else {
                    let count = 0;
                    const numBatches = Math.ceil(data.length / BATCH_SIZE);
                    while (count < numBatches) {
                        const entries = data.splice(0, BATCH_SIZE);
                        if (entries.length) {
                            response = await axios.request({
                                url,
                                headers: getHeader(),
                                method,
                                data: entries
                            });
                        }
                        count++;
                    }
                }
                dispatch(actions.apiCallSuccess(response.data));
                if (successMessage) dispatch(setSuccessMessage(successMessage));
                if (onSuccess)
                    dispatch({
                        type: onSuccess,
                        payload: response.data,
                        status: response.status
                    });
            } catch (error) {
                let message;
                if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else message = 'An error has occurred';
                dispatch(actions.apiCallFailed(message));
                dispatch(setErrorMessage(message));
                if (onError) dispatch({ type: onError, payload: { origin, message } });
            }
        } else {
            // Must be a Directus call
            try {
                const response = await axios.request({
                    url,
                    headers: getHeader(),
                    method,
                    data
                });

                // General success dispatch
                dispatch(actions.apiCallSuccess(response.data));
                if (successMessage) dispatch(setSuccessMessage(successMessage));
                // Specific success dispatch if present
                if (onSuccess)
                    dispatch({
                        type: onSuccess,
                        payload: response.data,
                        status: response.status
                    });
            } catch (error) {
                let message;
                // General error action
                if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else if (error?.response?.data?.validationErrors?.title)
                    message = error?.response?.data?.validationErrors?.title;
                else message = 'An error has occurred';

                dispatch(actions.apiCallFailed(message));

                // Tell formsState slice that error has occurred.
                dispatch(setErrorMessage(message));
                // Specific error to the fn that called the req - dispatch if present
                if (onError) dispatch({ type: onError, payload: { origin, message } });
            }
        }
    };

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            const idToken = getAccessToken();
            clearLocalStorage();
            msalInstance.logoutRedirect({
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
                idTokenHint: idToken,
                mainWindowRedirectUrl: msalConfig.auth.redirectUri
            });
        } else throw error;
    }
);

export default api;
