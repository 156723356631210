import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Collapse, TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../../icons/OnEditIcon';
import {
    loadCommunicationTemplate,
    sendJobCentreEmailCommunication
} from '../../../store/communicationService';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { printFormParticipant } from '../../../store/participantService';
import { selectUsers } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';

import FormRowDetails from './FormRowDetails';

import classes from '../../../commonStyles/tables.module.css';

const FormTableRow = ({ row, roles, toggleDropdown, openId, showForm, editForm, onEdit }) => {
    // HOOKS
    const dispatch = useDispatch();

    //LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const authoriseRoles = [MANAGER, QUALITY, SUPERUSER];
    const [open, setOpen] = useState(null);
    const [createdEmailAddress, setCreatedEmailAddress] = useState('');
    const [authorisedEmailAddress, setAuthorisedEmailAddress] = useState('');
    const [createPdf, setCreatePdf] = useState(false);
    const [documentId, setDocumentId] = useState('');
    const [emailForm, setEmailForm] = useState({});

    //STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentParticipantEmployability = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const users = useSelector(selectUsers);
    const { formStatusDetails, formTypes } = useSelector((state) => state.entities.directusService);
    const communicationTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );

    // EVENT HANDLERS
    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    // USE EFFECTS
    useEffect(() => {
        if (users && users.length) {
            setCreatedEmailAddress(
                users.find((el) => el.id === row.createdByUserId)?.emailAddress || ''
            );

            if (row?.authorisedByUserId) {
                setAuthorisedEmailAddress(
                    users.find((el) => el.id === row?.authorisedByUserId)?.emailAddress || ''
                );
            }
        } else {
            dispatch(loadUser(row.createdByUserId));
            if (row?.authorisedByUserId) dispatch(loadUser(row?.authorisedByUserId));
        }
    }, [row]);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            setCreatedEmailAddress(
                users.find((el) => el.id === row.createdByUserId)?.emailAddress || ''
            );
            if (row?.authorisedByUserId) {
                setAuthorisedEmailAddress(
                    users.find((el) => el.id === row?.authorisedByUserId)?.emailAddress || ''
                );
            }
        }
    }, [successMessage]);

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    useEffect(() => {
        if (
            Object.keys(emailForm)?.length < 1 ||
            Object.keys(communicationTemplate)?.length < 1 ||
            formTypes.length < 1
        )
            return;

        const payload = {
            participantId: currentParticipant.id,
            subject: communicationTemplate.subject,
            formType: getNameFromId(formTypes, emailForm.typeId),
            body: communicationTemplate.details,
            attachmentId: documentId
        };

        dispatch(
            sendJobCentreEmailCommunication(payload, currentParticipantEmployability.jobCentreId)
        );
    }, [emailForm, communicationTemplate, formTypes]);

    // CALLBACKS
    const edit = () => {
        onEdit(row);
    };

    const onRowDetailsClose = () => {
        setOpen(null);
        setCreatePdf(false);
    };

    const onCreatePdf = (createPDF) => {
        setCreatePdf(createPDF);
    };

    // CALLBACKS
    const onPrint = (row) => {
        setCreatePdf(true);
        dispatch(printFormParticipant(row.id));
    };

    const onEmail = (documentId, form) => {
        dispatch(loadCommunicationTemplate(form.emailTemplateId));
        setDocumentId(documentId);
        setEmailForm(form);
    };

    // RENDER
    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell onClick={onToggleDropdown}>{row.name}</TableCell>
                <TableCell onClick={onToggleDropdown}>{createdEmailAddress}</TableCell>
                <TableCell onClick={onToggleDropdown}>{authorisedEmailAddress}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row.lastUpdated)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row.statusName}</TableCell>
                <TableCell align="right">
                    {(getNameFromId(formStatusDetails, row.statusId) === 'Open' ||
                        (getNameFromId(formStatusDetails, row.statusId) === 'Withdrawn' &&
                            hasRole(authoriseRoles, roles)) ||
                        hasRole([SUPERUSER], roles)) && (
                        <OnEditIcon
                            onEdit={edit}
                            roles={roles}
                            active={!showForm && !editForm}
                            acceptedRoles={acceptedRoles}
                            tooltip={
                                getNameFromId(formStatusDetails, row.statusId) !== 'Authorised'
                                    ? 'Edit form'
                                    : 'SUPERUSER can edit authorised forms'
                            }
                        />
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={8}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <FormRowDetails
                            row={row}
                            roles={roles}
                            onClose={onRowDetailsClose}
                            onPrint={onPrint}
                            onEmail={onEmail}
                            createPdf={createPdf}
                            onCreatePdf={onCreatePdf}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default FormTableRow;

FormTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string,
    showForm: PropTypes.bool,
    editForm: PropTypes.bool,
    onEdit: PropTypes.func
};
