/**
 * Use this for the drop-down icon on accordions.
 */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';

const AccordionIcon = () => {
    return (
        <ExpandMoreIcon
            fontSize={'medium'}
            sx={{
                color: POLARIS_ROYAL_BLUE
            }}
        />
    );
};

export default AccordionIcon;
