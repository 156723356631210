import { PERMISSIONS } from './permissions';
export const ADVISER = 'ADVISER';
export const AUDIT = 'AUDIT';
export const LOCAL_ADMIN = 'LOCAL_ADMIN';
export const MANAGER = 'MANAGER';
export const PRAP = 'PRAP';
export const QUALITY = 'QUALITY';
export const READ_ONLY = 'READ_ONLY';
export const RECRUITMENT_MANAGER = 'RECRUITMENT_MANAGER';
export const SUPERUSER = 'SUPERUSER';

const ROLES_ARRAY = [
    ADVISER,
    AUDIT,
    LOCAL_ADMIN,
    MANAGER,
    PRAP,
    QUALITY,
    READ_ONLY,
    RECRUITMENT_MANAGER,
    SUPERUSER
];

/**
 * Return true is any of permittedRoles is in the supplied roles
 * @param permittedRoles
 * @param suppliedRoles
 * @returns {*}
 */
export const hasRole = (permittedRoles, suppliedRoles) => {
    return suppliedRoles.some((item) => permittedRoles.includes(item));
};

/**
 * Get the list of Y's for a permission.
 * @param permission
 * @returns String with Ys and spaces for permission
 */
const getDataForPermission = (permission) => {
    if (!permission) {
        return '';
    }

    const data = PERMISSIONS.filter((el) => el[0] === permission);

    // Return string with 'Y's for permissions
    return data[0][1];
};

/**
 * Get a list of Roles which can carry out the specified permission.
 */
export const getRolesForPermission = (permission) => {
    let roles = [];

    const permissionData = getDataForPermission(permission);

    // Convert 'Y's to roles
    for (let i = 0; i < permissionData.length; i++) {
        if (permissionData[i] === 'Y') {
            roles.push(ROLES_ARRAY[i]);
        }
    }

    return roles;
};
