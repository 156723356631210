import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';

import {
    loadJobSectorDetails,
    loadVacancyRejectionReasonsDetails,
    loadVacancyStageDetails
} from '../../../store/directusService';
import {
    loadVacancySubmissionsByVacancyId,
    setCurrentVacancy
} from '../../../store/recruitmentService';
import { getNameFromId } from '../../../utils/directusFunctions';
import LoadingSpinner from '../../ui/LoadingSpinner';

import SubmissionPoolTableManagement from './submissions/SubmissionPoolTableManagement';
import SubmissionsTableManagement from './submissions/SubmissionsTableManagement';

const SubmissionManagement = ({ vacancy, accordionPanelFrom, jobsOfferedForVacancies }) => {
    // HOOKS
    const dispatch = useDispatch();
    const [vacancyStageIdsWithReject, setVacancyStageIdsWithReject] = useState([]);
    const [vacancySubmissionsLoaded, setVacancySubmissionsLoaded] = useState(false);

    // LOCAL STATE
    const [jobsOfferedForVacancy, setJobsOfferedForVacancy] = useState(0);

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { jobSectorDetails, vacancyStageDetails, vacancyRejectionReasonsDetails } = useSelector(
        (state) => state.entities.directusService
    );

    // USE EFFECTS
    useEffect(() => {
        if (!jobsOfferedForVacancies) {
            setJobsOfferedForVacancy(vacancy.jobsOffered);
        } else {
            setJobsOfferedForVacancy(
                jobsOfferedForVacancies?.find((entry) => entry.vacancyId === vacancy.id)
                    ?.jobsOffered || 0
            );
        }
        const vacancyStageIds = vacancy.stageIds.map((el) => ({
            id: el,
            name: getNameFromId(vacancyStageDetails, el)
        }));
        const rejectedStatusId = vacancyStageDetails.find(
            (entry) => entry.name.toLowerCase() === 'rejected'
        )?.id;

        const obj = { id: rejectedStatusId, name: 'Rejected' };
        const vacancyStageIdsWithReject = [...vacancyStageIds, obj];
        setVacancyStageIdsWithReject(vacancyStageIdsWithReject);

        dispatch(setCurrentVacancy(vacancy));
        dispatch(loadVacancySubmissionsByVacancyId(vacancy.id));
        dispatch(loadJobSectorDetails());
        dispatch(loadVacancyRejectionReasonsDetails());
        dispatch(loadVacancyStageDetails());
    }, []);

    // USE EFFECTS
    useEffect(() => {
        if (
            successMessage === `Vacancy submissions have been loaded for vacancy id: ${vacancy.id}`
        ) {
            setVacancySubmissionsLoaded(true);
        }
    }, [successMessage]);

    // RENDER
    let content = '';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (vacancyRejectionReasonsDetails?.length < 1) content = 'No vacancy rejection details found';
    if (vacancyStageDetails?.length < 1) content = 'No vacancy stage details';
    if (vacancyStageIdsWithReject?.length < 1) content = 'No vacancy stage reject ids';
    if (!vacancySubmissionsLoaded) content = 'No vacancy submissions loaded';
    if (
        jobSectorDetails?.length < 1 ||
        vacancyRejectionReasonsDetails?.length < 1 ||
        vacancyStageDetails?.length < 1 ||
        vacancyStageIdsWithReject?.length < 1 ||
        !vacancySubmissionsLoaded
    )
        return <LoadingSpinner content={content} />;

    return (
        <>
            <SubmissionPoolTableManagement />
            <Typography variant="h6">Submissions</Typography>
            <br />
            <SubmissionsTableManagement
                accordionPanelFrom={accordionPanelFrom}
                vacancyStageIdsWithReject={vacancyStageIdsWithReject}
                jobsRemaining={vacancy.numberOfPositions - jobsOfferedForVacancy}
            />
        </>
    );
};

SubmissionManagement.propTypes = {
    vacancy: PropTypes.object,
    accordionPanelFrom: PropTypes.string,
    jobsOfferedForVacancies: PropTypes.array
};

export default SubmissionManagement;
