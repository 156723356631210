import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { updateStep } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';

import { checkDate, checkValue, initialErrorState, validate } from './validateSteps';

import form from '../../../../commonStyles/formStyles.module.css';

const EditStep = ({ onClose, roles, row }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        participantId: '',
        stepsValue: '',
        stepsDate: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const msg = 'Participant step has been updated';

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            stepsDate: row.stepsDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onStepChange = (value) => {
        clearError('stepsValue');
        setNewEntry((prev) => ({
            ...prev,
            stepsValue: value.replace(/[^\d]/gi, '').slice(0, 6)
        }));
        const newErrors = checkValue(value, errors);
        setErrors(newErrors);
    };

    const onDateChange = (date) => {
        clearError('stepsDate');
        setNewEntry((prev) => ({ ...prev, stepsDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const payload = { ...newEntry, participantId: currentParticipant?.id };
        dispatch(updateStep(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>Edit Steps Value</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Steps'}
                            id={'stepsValue'}
                            mandatory={true}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            value={newEntry.stepsValue}
                            placeholder={'Enter value'}
                            onChange={(e) => onStepChange(e.target.value)}
                        />
                        {errors.stepsValue.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.stepsValue} />
                            </div>
                        )}
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.stepsDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.stepsDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText="Update"
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default EditStep;

EditStep.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    row: PropTypes.object
};
