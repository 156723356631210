import React from 'react';
import PropTypes from 'prop-types';

import { Switch } from '@mui/material';

const AppointmentSlotSwitch = ({ checked, onChange, disabled }) => {
    const onAppointmentToggle = (e) => {
        const { checked } = e.target;
        onChange({ appointmentSlots: checked });
    };
    return (
        <div className="is-appointment switch">
            <span>Appointment</span>
            <div className="switch-btn">
                <Switch
                    id="appointmentSlots"
                    checked={checked}
                    onChange={onAppointmentToggle}
                    disabled={disabled}
                />
            </div>
            <span>Appointment Slots</span>
        </div>
    );
};

export default AppointmentSlotSwitch;

AppointmentSlotSwitch.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};
