import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';

import PolarisLink from '../ui/PolarisLink';

const CalendarMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    const handleClickListItem = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                navigate('/user_calendar');
                break;
            case 1:
                navigate('/scheduling_assistant');
                break;
            case 2:
                navigate('/course_calendar');
                break;
            default:
                break;
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <PolarisLink label={'Calendar'} onClick={handleClickListItem} />

            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}>
                <MenuItem onClick={() => handleMenuItemClick(0)}>My Calendar</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(1)}>Scheduling Assistant</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(2)}>Course Calendar</MenuItem>
            </Menu>
        </div>
    );
};
export default CalendarMenu;
