import React from 'react';
import PropTypes from 'prop-types';

import { Switch } from '@mui/material';

const MandatoryActivitiesSwitch = ({ checked, onSwitch, disabled }) => {
    return (
        <div className="input-set is-mandatory-set">
            <label htmlFor="isMandatory">Mandatory</label>
            <div className={`is-mandatory switch ${disabled ? 'disabled-switch' : ''}`}>
                <span>No</span>
                <span className="switch-btn">
                    <Switch
                        id="isMandatory"
                        disabled={disabled}
                        checked={checked}
                        onChange={(e) => onSwitch({ mandatory: e.target.checked })}
                    />
                </span>
                <span>Yes</span>
            </div>
        </div>
    );
};

export default MandatoryActivitiesSwitch;

MandatoryActivitiesSwitch.propTypes = {
    checked: PropTypes.bool,
    onSwitch: PropTypes.func,
    disabled: PropTypes.bool
};
