import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadQuestionnaireAnswersByQuestionnaire,
    loadQuestionnaireById,
    setCurrentQuestionnaire
} from '../../store/participantService';
import { reverseFormatDate } from '../../utils/dateFunctions';

import classes from '../../commonStyles/tables.module.css';

const QuestionnaireTableRow = ({ row }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentQuestionnaire = useSelector(
        (state) => state.entities.participantService.currentQuestionnaire
    );

    const [questionnaire, setQuestionnaire] = useState([]);
    const [questionnaireSelected, setQuestionnaireSelected] = useState(false);

    useEffect(() => {
        setQuestionnaire(currentQuestionnaire);
    }, [currentQuestionnaire]);

    useEffect(() => {
        if (questionnaireSelected) {
            if (questionnaire && questionnaire.data) {
                dispatch(setCurrentQuestionnaire(questionnaire));
                navigate('/build_questionnaire');
            }
        }
    }, [questionnaire]);

    const onEditRow = (row) => {
        dispatch(loadQuestionnaireById(row.id));
        setQuestionnaireSelected(true);
        dispatch(loadQuestionnaireAnswersByQuestionnaire(row.id));
        dispatch(setCurrentQuestionnaire(row));
        navigate('/build_questionnaire');
    };

    return (
        <TableRow
            hover
            className={`${classes.tableRowStripes} ${classes.canOpen}`}
            onClick={() => onEditRow(row)}>
            <TableCell>{row.code}</TableCell>
            <TableCell>{row.title}</TableCell>
            <TableCell>{reverseFormatDate(row.dateCreated)}</TableCell>
            <TableCell>{row.inactive ? 'Inactive' : 'Active'}</TableCell>
        </TableRow>
    );
};

export default QuestionnaireTableRow;

QuestionnaireTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
