import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { Autocomplete, TextField } from '@mui/material';

const AppointmentLocationSelect = ({ locations, locationName, onLocationChange, disabled }) => {
    const onChange = (_, val) => {
        if (!val) return;
        onLocationChange({ locationId: val.id, location: val.name });
    };

    return (
        <div className="input-set">
            <label htmlFor="location">Location</label>
            <div className="selector-input input">
                <Autocomplete
                    disablePortal
                    id="location"
                    key={uuid()}
                    disabled={disabled}
                    options={locations || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => <TextField {...params} placeholder={locationName} />}
                    classes={{ listbox: 'menu-list' }}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default AppointmentLocationSelect;

AppointmentLocationSelect.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object),
    locationName: PropTypes.string,
    onLocationChange: PropTypes.func,
    disabled: PropTypes.bool
};
