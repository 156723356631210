/**
 * function to capitalize the first word of a string
 * @param string
 * @returns {`${*} ${*}`}
 */
export const capitalize = (string) => {
    if (!string || typeof string !== 'string') return;
    const capitalizeWord = (str) => str[0].toUpperCase() + str.slice(1);
    const [first, ...rest] = string.split(' ');
    return `${capitalizeWord(first)} ${rest.join(' ')}`;
};
