(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("survey-core"), require("survey-creator-core/themes"), require("survey-core/themes"));
	else if(typeof define === 'function' && define.amd)
		define("survey-creator-core", ["survey-core", "survey-creator-core/themes", "survey-core/themes"], factory);
	else if(typeof exports === 'object')
		exports["survey-creator-core"] = factory(require("survey-core"), require("survey-creator-core/themes"), require("survey-core/themes"));
	else
		root["SurveyCreatorCore"] = factory(root["Survey"], root["SurveyCreatorTheme"], root["SurveyTheme"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_survey_core__, __WEBPACK_EXTERNAL_MODULE_survey_creator_core_themes__, __WEBPACK_EXTERNAL_MODULE_survey_core_themes__) => {
return 