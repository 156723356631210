import { scrollToElement } from '../../../utils/scrollToElement';

const ADVISER_APPROVAL_MINIMUM_AMOUNT = 0.1;
const ADVISER_APPROVAL_MAXIMUM_AMOUNT = 9999.99;

export const NO_FINANCIAL_REQUEST_TYPE = 'No financial request type selected';
export const NO_PAYMENT_METHOD_TYPE = 'No financial payment method type selected';
export const NO_ADVISER_APPROVAL_LIMIT = 'Adviser approval limit is mandatory';
export const ADVISER_APPROVAL_LIMIT_FORMAT = 'Invalid Amount';
export const ADVISER_APPROVAL_LIMIT_MINIMUM = `Adviser Approval limit must be ${ADVISER_APPROVAL_MINIMUM_AMOUNT} or more`;
export const ADVISER_APPROVAL_LIMIT_MAXIMUM = `Adviser Approval limit must be ${ADVISER_APPROVAL_MAXIMUM_AMOUNT} or less`;

export const initialErrorState = {
    financialRequestTypeDetails: { error: false, message: '' },
    paymentMethodTypeDetails: { error: false, message: '' },
    adviserApprovalLimit: { error: false, message: '' }
};

export const validate = (
    newEntry,
    selectedFinancialRequestType,
    selectedPaymentMethodType,
    errors
) => {
    let isValid = true;
    let newErrors = errors;

    if (selectedFinancialRequestType?.length < 1) {
        newErrors = {
            ...newErrors,
            financialRequestTypeDetails: { error: true, message: NO_FINANCIAL_REQUEST_TYPE }
        };
        isValid = false;
    }

    if (selectedPaymentMethodType?.length < 1) {
        newErrors = {
            ...newErrors,
            paymentMethodTypeDetails: { error: true, message: NO_PAYMENT_METHOD_TYPE }
        };
        isValid = false;
    }

    if (newEntry.adviserApprovalLimit?.length < 1) {
        newErrors = {
            ...newErrors,
            adviserApprovalLimit: { error: true, message: NO_ADVISER_APPROVAL_LIMIT }
        };
        isValid = false;
    } else if (newEntry.adviserApprovalLimit < ADVISER_APPROVAL_MINIMUM_AMOUNT) {
        newErrors = {
            ...newErrors,
            adviserApprovalLimit: { error: true, message: ADVISER_APPROVAL_LIMIT_MINIMUM }
        };
        isValid = false;
    } else if (newEntry.adviserApprovalLimit > ADVISER_APPROVAL_MAXIMUM_AMOUNT) {
        newErrors = {
            ...newErrors,
            adviserApprovalLimit: { error: true, message: ADVISER_APPROVAL_LIMIT_MAXIMUM }
        };
        isValid = false;
    } else if (
        newEntry.adviserApprovalLimit &&
        !newEntry.adviserApprovalLimit.toString().match(/^\d{0,4}(\.\d{1,2})?$/i)
    ) {
        newErrors = {
            ...newErrors,
            adviserApprovalLimit: { error: true, message: ADVISER_APPROVAL_LIMIT_FORMAT }
        };
        isValid = false;
    }

    // TODO This isn't working.
    scrollToElement(newErrors);

    return { isValid, newErrors };
};
