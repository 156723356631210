import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectAttendanceStatus, selectContracts } from '../../store/dataSelectors';
import {
    loadAttendanceDetails,
    loadContractDetailsForContractIds,
    loadDataSetDetails,
    loadParticipantStatusDetails
} from '../../store/directusService';
import { clearParticipantsSearch, searchParticipants } from '../../store/participantService';
import { selectLoggedInUser } from '../../store/userSelectors';

import CreateAttendanceOutcomesCsv from './CreateAttendanceOutcomesCsv';
import CreateReferralCsv from './CreateReferralCsv';
import DataSetsModal from './DataSetsModal';

const PT_LOAD_SIZE = 50;
const DataSets = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [isDataSetsModalOpen, setIsDataSetsModalOpen] = useState(false);
    const [dataSetType, setDataSetType] = useState('');
    const [selectedOptions, setSelectedOptions] = useState({});

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const dataSets = useSelector((state) => state.entities.directusService.dataSetDetails);
    const contracts = useSelector(selectContracts);
    const attendanceStatusDetails = useSelector(selectAttendanceStatus);
    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const { participantsSearch, participantsSearchMetaData } = useSelector(
        (state) => state.entities.participantService
    );

    // USE EFFECTS
    useEffect(() => {
        if (dataSets?.length < 1) dispatch(loadDataSetDetails());
        if (attendanceStatusDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (participantStatusDetails?.length < 1) dispatch(loadParticipantStatusDetails());
    }, []);

    useEffect(() => {
        loggedInUser?.contractIds &&
            dispatch(loadContractDetailsForContractIds(loggedInUser.contractIds));
    }, [loggedInUser]);

    useEffect(() => {
        setIsDataSetsModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (participantsSearch?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { contractIds: [selectedOptions.contract.id] },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        }
    }, [participantsSearch, participantsSearchMetaData]);

    const onGenerate = (e, selects, dataSetType) => {
        e.preventDefault();
        setSelectedOptions(selects);
        setDataSetType(dataSetType);
        dispatch(searchParticipants({ contractIds: [selects.contract.id] }, 0, PT_LOAD_SIZE));
    };

    const onDataSetsModalClose = () => {
        setSelectedOptions({});
        setDataSetType('');
        dispatch(clearParticipantsSearch());
        setIsDataSetsModalOpen(false);
        onClose();
    };

    // RENDER

    return !dataSetType ? (
        <DataSetsModal
            dataSets={dataSets}
            contracts={contracts}
            attendanceStatuses={attendanceStatusDetails}
            onGenerate={onGenerate}
            isOpen={isDataSetsModalOpen}
            onCancel={onDataSetsModalClose}
        />
    ) : dataSetType === 'attendanceOutcomes' && Object.keys(selectedOptions.length > 0) ? (
        <CreateAttendanceOutcomesCsv
            selectedOptions={selectedOptions}
            isOpen={isDataSetsModalOpen}
            onClose={onDataSetsModalClose}
        />
    ) : (
        <CreateReferralCsv
            contract={selectedOptions.contract}
            isOpen={isDataSetsModalOpen}
            onClose={onDataSetsModalClose}
        />
    );
};

export default DataSets;

DataSets.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
