import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectContracts } from '../../../store/dataSelectors';
import {
    loadContractDetailsForContractIds,
    loadUploaderTypes
} from '../../../store/directusService';

import NdppUploaderModal from './NdppUploaderModal';

const NdppUploader = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();

    const [isNdppUploaderModalOpen, setIsNdppUploaderModalOpen] = useState(false);

    const uploaders = useSelector((state) => state.entities.directusService.uploaderTypes);
    const contracts = useSelector(selectContracts);

    useEffect(() => {
        if (uploaders?.length < 1) dispatch(loadUploaderTypes());
    }, []);

    useEffect(() => {
        //console.log(190, uploaders);
        if (uploaders?.length < 1) return;
        const ndppUploaderContractIds = uploaders
            .find((el) => el.name.toLowerCase() === 'ndpp uploader')
            ?.contract?.map((el) => el.contract_id?.id);
        // console.log(230, ndppUploaderContractIds);
        ndppUploaderContractIds?.length > 0 &&
            loadContractDetailsForContractIds(ndppUploaderContractIds);
    }, [uploaders]);

    useEffect(() => {
        setIsNdppUploaderModalOpen(isOpen);
    }, [isOpen]);

    const onNdppUploaderModalClose = (e, reason) => {
        if (reason && reason === 'backdropClick') return;
        setIsNdppUploaderModalOpen(false);
        onClose();
    };

    const onCancel = () => {
        setIsNdppUploaderModalOpen(false);
        onClose();
    };

    return (
        <NdppUploaderModal
            contracts={contracts}
            onClose={onNdppUploaderModalClose}
            isOpen={isNdppUploaderModalOpen}
            onCancel={onCancel}
        />
    );
};

export default NdppUploader;

NdppUploader.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
