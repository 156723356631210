import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { selectActionPlanStatus } from '../../../store/dataSelectors';
import {
    loadActionPlanStatusDetails,
    loadBarrierNameDetails
} from '../../../store/directusService';
import {
    selectCurrentParticipant,
    selectCurrentParticipantBarriers,
    selectCurrentParticipantEmployability,
    selectParticipantActionPlans
} from '../../../store/participantSelectors';
import {
    loadActionPlans,
    loadParticipantBarriers,
    loadParticipantEmployability
} from '../../../store/participantService';
import { selectUserRoles } from '../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ActionPlanTable from './ActionPlanTable';
import CreateActionPlan from './CreateActionPlan';
import EditActionPlan from './EditActionPlan';

import classes from './actionPlan.module.css';

const ActionPlan = () => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    // STORE STATE
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const participantActionPlans = useSelector(selectParticipantActionPlans);
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const currentParticipantEmployability = useSelector(selectCurrentParticipantEmployability);
    const actionPlanStatus = useSelector(selectActionPlanStatus);

    // USE EFFECTS
    useEffect(() => {
        barrierNameDetails.length < 1 && dispatch(loadBarrierNameDetails());
    }, []);

    useEffect(() => {
        setRows([]);
        if (!currentParticipant?.id) return;
        dispatch(loadActionPlans(currentParticipant.id));
        if (
            !currentParticipantEmployability?.participantId ||
            !currentParticipantEmployability?.participantId !== currentParticipant.id
        ) {
            dispatch(loadParticipantEmployability(currentParticipant.id));
        }
        if (!actionPlanStatus?.length) dispatch(loadActionPlanStatusDetails());
        if (
            !currentParticipantBarriers?.length ||
            currentParticipantBarriers?.participantId !== currentParticipant.id
        )
            dispatch(loadParticipantBarriers(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (!actionPlanStatus?.length) return;

        const rows = participantActionPlans.map((el) => ({
            ...el,
            actionStartDate: !el.actionStartDate ? '1960-01-01' : el.actionStartDate,
            actionDueDate: !el.actionDueDate ? '1960-01-01' : el.actionDueDate,
            actionOutcomeDate: !el.actionOutcomeDate ? '1960-01-01' : el.actionOutcomeDate,
            outcomeName: actionPlanStatus.find((entry) => entry.id === el.outcomeId)?.name || 'x',
            barrierName:
                getNameFromId(
                    barrierNameDetails,
                    currentParticipantBarriers.find((entry) => entry.id === el.barrierId)?.barrierId
                ) || 'zz'
        }));
        setRows(rows);
    }, [participantActionPlans, actionPlanStatus, currentParticipantBarriers, barrierNameDetails]);

    // EVENT HANDLERS
    const onCreateAction = () => {
        setFormType('create');
        setShowForm(true);
    };

    const onEditAction = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
    };

    return (
        <div className={classes.actionPlans}>
            <h3>Your action details</h3>
            <div className={classes.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No actions found" />
                ) : (
                    <ActionPlanTable rows={rows} roles={roles} onEdit={onEditAction} />
                )}
            </div>
            <div>
                {!showForm && (
                    <Button
                        disabled={!hasRole(acceptedRoles, roles)}
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreateAction}>
                        Add an action
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                {showForm && formType === 'create' && (
                    <CreateActionPlan onClose={() => setShowForm(false)} roles={roles} />
                )}
                {showForm && formType === 'edit' && (
                    <EditActionPlan
                        onClose={() => setShowForm(false)}
                        roles={roles}
                        row={selectedRow}
                    />
                )}
            </div>
        </div>
    );
};

export default ActionPlan;
