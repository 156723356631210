import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnCreateIcon from '../../../../icons/OnCreateIcon';
import { setCurrentBusiness } from '../../../../store/recruitmentService';
import { MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER } from '../../../../utils/userRoles';
import BusinessDetails from '../shared/BusinessDetails';

import classes from '../../../../commonStyles/tables.module.css';

const OtherBusinessTableRow = ({ row, roles, toggleDropdown, openId, jobsOfferedForVacancies }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [open, setOpen] = useState(false);

    // USE EFFECTS
    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // EVENT HANDLERS
    const onAddVacancy = (row) => {
        dispatch(setCurrentBusiness(row));
        navigate('/create_vacancy', { state: { id: row.id, accordionPanel: 'otherBusinesses' } });
    };

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">{row.vacancies?.length || ''}</TableCell>
                <TableCell align="center">
                    {row.positions > 0
                        ? `${row.positions - row.jobsOffered} / ${row.positions}`
                        : ''}
                </TableCell>
                <TableCell>{row.postcode}</TableCell>
                <TableCell align="right">
                    <OnCreateIcon
                        onCreate={() => onAddVacancy(row)}
                        titleString={'Vacancy'}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={7}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <BusinessDetails
                                row={row}
                                onClose={() => setOpen(null)}
                                jobsOfferedForVacancies={jobsOfferedForVacancies}
                                accordionPanelFrom="otherBusinesses"
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default OtherBusinessTableRow;

OtherBusinessTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    jobsOfferedForVacancies: PropTypes.array
};
