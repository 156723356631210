import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import OnWarningIcon from '../../../../icons/OnWarningIcon';
import { selectIsSecondNatureReferralAttemptSuccessful } from '../../../../store/participantSelectors';
import { POLARIS_ORANGE } from '../../../../themes/theme';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';

const BloodGlucoseRow = ({ row, roles, onEdit, onDelete }) => {
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const acceptedDeleteRoles = [MANAGER, QUALITY, SUPERUSER];

    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    return (
        <TableRow hover className={`${classes.basicRowStripes}`}>
            <TableCell>{row.displayDate}</TableCell>
            <TableCell align="center">{row.measurementValue}</TableCell>
            <TableCell>{row.measurement}</TableCell>
            <TableCell align="center">
                {row.measurementValue > 15.0 && (
                    <OnWarningIcon tooltipContent="" color={POLARIS_ORANGE} />
                )}
            </TableCell>
            <TableCell align="right">
                <OnEditIcon
                    onEdit={() => onEdit(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                    active={!isReferralHistoryAttemptSuccessful}
                />
            </TableCell>
            <TableCell align="right">
                <OnDeleteIcon
                    onDelete={() => onDelete(row)}
                    roles={roles}
                    acceptedRoles={acceptedDeleteRoles}
                    active={!isReferralHistoryAttemptSuccessful}
                />
            </TableCell>
        </TableRow>
    );
};

export default BloodGlucoseRow;

BloodGlucoseRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};
