import React from 'react';
import PropTypes from 'prop-types';

import { generateUniqueId } from '../../utils/stringUtils';
import IconError from '../IconError';

import common from './formElementStyles/commonFormElements.module.css';
import dates from './formElementStyles/dateSelect.module.css';

const DateSelect = ({
    value,
    onDateChange,
    label = 'Date',
    disabled = false,
    isDefault = false,
    mandatory = false,
    customClass = '',
    error = {}
}) => {
    const defaultDate = String(new Date().toISOString().split('T')[0]);
    const id = `date-label_${generateUniqueId()}`;
    return (
        <div className={`${common.fieldWrapper} ${dates[customClass]} ${dates.dateInput}`}>
            <label htmlFor={id} className={common.formLabel}>
                {label} {mandatory && <sup>*</sup>}
            </label>
            <input
                type="date"
                id={id}
                value={value || (isDefault ? defaultDate : '')}
                name="date"
                disabled={disabled}
                className={common.fieldInput}
                onChange={(e) => onDateChange(e.target.value)}
            />
            {error?.error && (
                <div className={dates.errorWrapper}>
                    <div className={dates.error}>
                        <IconError text={error} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateSelect;

DateSelect.propTypes = {
    value: PropTypes.string,
    onDateChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    isDefault: PropTypes.bool,
    customClass: PropTypes.string,
    error: PropTypes.object
};
