import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { Button } from '@mui/material';

import {
    clearCurrentTemplate,
    loadCommunicationTemplate,
    searchCommunicationTemplates,
    sendParticipantEmailCommunication
} from '../../../../store/communicationService';
import { loadCommunicationTypes } from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import {
    selectLoggedInUser,
    selectLoggedInUserName,
    selectUserRoles
} from '../../../../store/userSelectors';
import { loadLoggedInUser } from '../../../../store/userService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import SensitivityNotice from '../../../ui/notices/sensitivity/SensitivityNotice';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import MultiSelect from '../../../ui/pickers/MultiSelect';

import { initialErrorState, validate } from './validateCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../communicationStyles.module.css';

const EmailCommunication = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const TIME = 30;
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        emailAddress: '',
        templateId: '',
        templateName: '',
        subject: '',
        details: '',
        signature: '',
        sigLen: 0,
        attachmentIds: []
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [showData, setShowData] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [preSelectedDocuments, setPreSelectedDocuments] = useState([]);
    const [preSelectedDocumentIds, setPreSelectedDocumentIds] = useState([]);
    const [isClearSelectedDocuments, setIsClearSelectedDocuments] = useState('0');
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
    const msg = 'Email Communication sent to participant';

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const loggedInUser = useSelector(selectLoggedInUser);
    const loggedInUserDetails = useSelector(
        (state) => state.entities.userService.loggedInUserDetails
    );
    const userName = useSelector(selectLoggedInUserName);
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );
    const currentParticipant = useSelector(selectCurrentParticipant);
    const templates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const currentTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const clearForm = () => {
        const signature = configSignature();
        setNewEntry({
            ...initialState,
            signature,
            details: newEntry.details.slice(-signature.length)
        });
        setPreSelectedDocuments([]);
        setPreSelectedDocumentIds([]);
        setIsClearSelectedDocuments(Math.random());
        setErrors(initialErrorState);
        setIsSucceeded(false);
        dispatch(clearCurrentTemplate());
    };

    const configSignature = () => {
        const name = `<p><br></p><p>${userName}</p>`;
        const job = `<p>${loggedInUserDetails?.jobTitle}</p>`;
        const phone = `<p>${loggedInUserDetails?.phoneNumber}</p>`;
        return `${name}${loggedInUserDetails?.jobTitle ? job : ''}${
            loggedInUserDetails?.phoneNumber ? phone : ''
        }`;
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (!loggedInUser || !loggedInUser.id) return;
        dispatch(loadLoggedInUser(loggedInUser.id));
        if (communicationTypes?.length < 1) dispatch(loadCommunicationTypes());
    }, [loggedInUser]);

    useEffect(() => {
        if (!loggedInUserDetails?.id) return;
        const signature = configSignature();
        setNewEntry((prev) => ({
            ...prev,
            signature,
            sigLen: signature.length,
            details: newEntry.details.length ? newEntry.details : newEntry.details.concat(signature)
        }));
    }, [loggedInUserDetails, userName]);

    useEffect(() => {
        if (!currentParticipant || Object.keys(currentParticipant).length < 1) return;
        dispatch(loadParticipantDocumentDetails(currentParticipant.id));
        setNewEntry((prev) => ({ ...prev, emailAddress: currentParticipant.emailAddress }));
        dispatch(
            searchCommunicationTemplates({
                type: 'Email',
                contractIds: [currentParticipant.contractId],
                inactive: false
            })
        );
    }, [currentParticipant]);

    useEffect(() => {
        if (!participantDocuments) return;
        const newDocuments = participantDocuments.map((el) => ({ ...el, name: el.filename }));
        setDocuments(newDocuments);
    }, [participantDocuments]);

    useEffect(() => {
        if (
            !newEntry.templateId ||
            Object.keys(currentTemplate).length < 1 ||
            communicationTypes?.length < 1
        )
            return;
        if (communicationTypes.find((el) => el.id === currentTemplate.typeId)?.name !== 'Email')
            return;
        setNewEntry((prev) => ({
            ...prev,
            subject: currentTemplate.subject,
            details: currentTemplate.details.concat(newEntry.signature)
        }));
    }, [newEntry.templateId, currentTemplate, communicationTypes]);

    useEffect(() => {
        if (successMessage === msg) {
            setIsSendButtonDisabled(false);
            clearForm();
        }
    }, [successMessage]);

    // EVENT HANDLERS

    const onRevealData = () => {
        setShowData(!showData);
        setTimeout(() => setShowData((showData) => !showData), TIME * 1000);
    };

    const onDetailsChange = (details) => {
        setNewEntry((prev) => ({
            ...prev,
            details
        }));
        setNewEntry((prev) => ({ ...prev, details }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, 'Email');
        setErrors(newErrors);
        if (!isValid) return;
        setIsSendButtonDisabled(true);
        const payload = {
            subject: newEntry.subject,
            body: newEntry.details,
            attachmentIds: newEntry.attachmentIds
        };
        dispatch(sendParticipantEmailCommunication(payload, currentParticipant.id, msg));
    };

    return (
        <div className={app.container} id="emailScreen">
            <div className={app.mainHeading}>Communications</div>
            <form className={`${form.formWrapper} ${local.formContainer}`} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={`${form.textAreaSet} ${form.staticMinHeight}`}>
                            <label>To Email Address</label>
                            <div className={form.textAreaText}>
                                {currentParticipant.emailAddress}
                            </div>
                        </div>

                        <div className={form.textAreaSet}>
                            <label>From Email Address</label>
                            <div className={form.textAreaText}>{loggedInUser.emailAddress}</div>
                        </div>

                        {!showData && (
                            <div className={`${form.inputCover} ${form.coverHeightMed}`}>
                                <SensitivityNotice
                                    onClick={onRevealData}
                                    numRows={3}
                                    customClass={form.inputNotice}
                                />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <div className={form.alignDownMed}>
                            <DDLOptionPicker
                                label={'Template'}
                                id={'templates'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={templates || []}
                                chosenName={newEntry.templateName}
                                chosenId={newEntry.templateId}
                                onChange={(chosenId) => {
                                    clearError('templateId');
                                    chosenId && dispatch(loadCommunicationTemplate(chosenId));
                                    const chosenTemplate = templates?.find(
                                        (el) => el.id === chosenId
                                    );
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        templateId: chosenId || '',
                                        templateName: chosenTemplate?.name || ''
                                    }));
                                }}></DDLOptionPicker>
                        </div>
                        <div className={form.alignDownMed}>
                            <LabelledTextField
                                label={'Email Subject'}
                                id={'subject'}
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.subject}
                                placeholder={'Enter email subject'}
                                onChange={(e) => {
                                    clearError('subject');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        subject: e.target.value
                                    }));
                                }}
                            />
                            {errors.subject.error && (
                                <div className={form.textInputError}>
                                    <IconError text={errors.subject} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${form.formSection} ${form.alignDown}`}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            <ReactQuill
                                value={newEntry.details}
                                modules={EmailCommunication.modules}
                                placeholder="Enter details"
                                onChange={onDetailsChange}
                            />
                            {errors.details.error && (
                                <div className={`${form.textInputError} ${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={local.attachIcon}>
                            <AttachFileOutlinedIcon />
                            Attachments
                        </div>

                        <MultiSelect
                            id="documents"
                            label="Documents"
                            key={isClearSelectedDocuments}
                            placeholder="PT Document Storage"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={documents || []}
                            preSelectedIds={preSelectedDocumentIds}
                            preSelects={preSelectedDocuments}
                            onChange={(ids) => {
                                setNewEntry((prev) => ({
                                    ...prev,
                                    attachmentIds: ids.length === 0 ? [] : ids
                                }));
                            }}
                        />
                    </div>
                </div>

                <div className={form.buttonRight}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={
                            !hasRole(acceptedRoles, roles) ||
                            newEntry.details.length < 1 ||
                            isSendButtonDisabled
                        }>
                        Send Now
                    </Button>
                </div>
                {isSucceeded && <div className={form.successMessage}>Email sent</div>}
            </form>
        </div>
    );
};

export default EmailCommunication;

EmailCommunication.modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['clean']
    ]
};
