import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { loadParticipants } from '../../store/participantService';
import { searchUsersByLoggedInUserServiceIds } from '../../store/userService';
import { POLARIS_WHITE, SCREEN_SETTINGS } from '../../themes/theme';
import QuickSearchParticipant from '../search/QuickSearchParticipant';
import HeaderRibbon from '../ui/HeaderRibbon';

import ParticipantMenu from './participant/ParticipantMenu';
import AdminMenu from './AdminMenu';
import CalendarMenu from './CalendarMenu';
import CommunicationIdentifier from './CommunicationIdentifier';
import DashboardMenu from './DashboardMenu';
import JourneyMenu from './JourneyMenu';
import RecordedIdentifier from './RecordedIdentifier';

import classes from '../../app.module.css';

/**
 * This has the navigation UI
 *
 * This includes the main navigation menus (dashboard, participant, journey and
 * calendar).
 *
 * The RecordedIdentifier & CommunicationIdentifier
 *
 * todo: Add admin menu.
 *
 */
const participantPathNames = [
    '/edit_participant',
    '/course_information',
    '/financial_information',
    '/participant_calendar',
    '/timeline',
    '/documents',
    '/appointments',
    '/letter_communication',
    '/sms_communication',
    '/email_communication'
];

const communicationPathNames = ['/group_sms', '/group_email'];
const USER_LOAD_SIZE = 50;

const NavigationBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(loadParticipants());
    }, []);

    const {
        loggedInUser,
        usersByLoggedInUserServiceIds: users,
        usersByLoggedInUserServiceIdsMetaData: metaData,
        loadingUsersByLoggedInUserServiceIds
    } = useSelector((state) => state.entities.userService);

    useEffect(() => {
        if (Object.keys(loggedInUser).length < 1 || !('serviceIds' in loggedInUser)) return;
        if (users && users.length < 1 && !loadingUsersByLoggedInUserServiceIds) {
            console.log(68, 'START: ', new Date().toISOString());
            dispatch(
                searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds, 0, USER_LOAD_SIZE)
            );
        }
    }, [loggedInUser]);

    useEffect(() => {
        console.log(76, 'END: ', new Date().toISOString());
        if (metaData.last) return;
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                metaData.number + 1,
                USER_LOAD_SIZE
            )
        );
    }, [metaData]);

    return (
        <div>
            {/* The navigation menu */}
            <Box
                sx={{
                    backgroundColor: POLARIS_WHITE,
                    margin: '0px',
                    border: SCREEN_SETTINGS.margin.small
                }}>
                <div>
                    <div className={classes.topNavLinks}>
                        <DashboardMenu />
                        <ParticipantMenu />
                        <JourneyMenu />
                        <CalendarMenu />
                        <AdminMenu />
                        <div className={classes.spacer}></div>
                        <QuickSearchParticipant />
                    </div>
                </div>
                {participantPathNames.includes(location.pathname) && <RecordedIdentifier />}
                {communicationPathNames.includes(location.pathname) && <CommunicationIdentifier />}
                <HeaderRibbon />
            </Box>
        </div>
    );
};
export default NavigationBar;
