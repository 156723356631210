import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { stableSort } from '../../../../utils/sortFunctions';
import {
    DEFAULT_TABLE_ROW_OPTIONS,
    DEFAULT_TABLE_ROWS_PER_PAGE
} from '../../../../utils/uiConstants';

import OccProfileRow from './OccProfileRow';

const headCells = [
    { id: 'eventDate', numeric: false, label: 'Date', align: 'left' },
    { id: 'time', numeric: false, label: 'Time', align: 'left' },
    { id: 'user', numeric: false, label: 'User', align: 'left' },
    { id: 'status', numeric: false, label: 'Status', align: 'left' }
];

const OccProfileTable = ({ rows }) => {
    // LOCAL STATE
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('eventDate');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);

    // EVENT HANDLERS
    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    // RENDER

    return (
        rows.length > 0 && (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 320 }} aria-labelledby="tableTitle" size="medium">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            sx={{
                                                paddingLeft:
                                                    headCell.align === 'center' ? '32px' : ''
                                            }}
                                            sortDirection={orderBy === headCell.id ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={() => onSort(headCell.id)}>
                                                <Typography variant={'subtitle3'} color={'primary'}>
                                                    {headCell.label}
                                                </Typography>
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc'
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {stableSort(rows, orderBy, order)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <OccProfileRow key={row.id} row={row} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        data-testid="tablePagination"
                        rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        )
    );
};

export default OccProfileTable;

OccProfileTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
