import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    selectFinancialPaymentMethodType,
    selectFinancialRequestStatus,
    selectFinancialRequestType
} from '../../../../store/dataSelectors';
import {
    loadFinancialPaymentMethodDetailsByPaymentMethodType,
    loadFinancialPaymentMethodTypeDetails,
    loadFinancialRequestReasonDetailsByRequestType,
    loadFinancialRequestStatusDetails,
    loadFinancialRequestTypeDetails
} from '../../../../store/directusService';
import { setErrorMessage } from '../../../../store/formsState';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import {
    createFinancialRequest,
    loadContractConstants
} from '../../../../store/participantService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getConfiguredItems } from '../../../../utils/directusFunctions';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import * as validate from '../../../../validation/validation';
import SingleSelect from '../../../formElements/SingleSelect';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../ui/pickers/NoYesRadioPicker';

import form from '../../../../commonStyles/formStyles.module.css';
import actions from '../../../ui/formActions/formActions.module.css';

const REASON_DETAILS_LIMIT = 500;
const CreateFinancialRequest = ({ onClose, roles }) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        setFocus,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const { state } = useLocation();
    const initialState = {
        id: null,
        code: null,
        adviserId: null,
        amount: null,
        approverId: null,
        requestTypeId: null,
        paymentMethodId: null,
        paymentMethodTypeId: null,
        paymentReasonId: null,
        dateIssued: null,
        dateRequested: new Date().toISOString().slice(0, 10),
        statusChangeDate: '',
        taxNiIssued: false,
        reasonDetail: '',
        approverNote: null,
        statusId: null,
        participantId: null
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const adminRoles = [MANAGER, QUALITY, SUPERUSER];
    const advisorRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER, SUPERUSER];
    const [advisers, setAdvisers] = useState([]);
    const dateFormat = 'dd/MM/yyyy';
    const today = new Date();
    const [arrayFinancialRequestType, setArrayFinancialRequestType] = useState([]);
    const [arrayPaymentMethodType, setArrayPaymentMethodType] = useState([]);
    const [requestReasonRequired, setRequestReasonRequired] = useState(false);
    const [paymentMethodRequired, setPaymentMethodRequired] = useState(false);
    const [stateRoles, setStateRoles] = useState([]);
    const [stateChecked, setStateChecked] = useState(false);

    const [isClearSelectedAdviser, setIsClearSelectedAdviser] = useState('0');
    const [isClearSelectedRequestType, setIsClearSelectedRequestType] = useState('1');
    const [isClearSelectedRequestReason, setIsClearSelectedRequestReason] = useState('2');
    const [isClearSelectedPaymentMethodType, setIsClearSelectedPaymentMethodType] = useState('3');
    const [isClearSelectedPaymentMethod, setIsClearSelectedPaymentMethod] = useState('4');

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const financialPaymentMethodTypeDetails = useSelector(selectFinancialPaymentMethodType);
    const financialRequestStatusDetails = useSelector(selectFinancialRequestStatus);
    const financialRequestTypeDetails = useSelector(selectFinancialRequestType);
    const { financialRequestReasonDetails, financialPaymentMethodDetails } = useSelector(
        (state) => state.entities.directusService
    );

    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const dbContractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // EVENT HANDLERS
    const handleAdviserChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, adviserId: chosenId }));
    };

    const handleAmount = (e) => {
        setNewEntry((prev) => ({ ...prev, amount: e.target.value }));
    };

    const handleRequestTypeChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, requestTypeId: chosenId }));
        setNewEntry((prev) => ({ ...prev, paymentReasonId: '' }));
        setIsClearSelectedRequestReason(Math.random());

        clearErrors('requestTypeId,');
        clearErrors('financialRequestReasonId');

        if (chosenId) {
            dispatch(loadFinancialRequestReasonDetailsByRequestType(chosenId));
        }
    };

    const handleRequestReasonChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, paymentReasonId: chosenId }));
            clearErrors('financialRequestReasonId');
        }
    };

    const handlePaymentMethodTypeChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, paymentMethodTypeId: chosenId }));
        setNewEntry((prev) => ({ ...prev, paymentMethodId: '' }));
        setIsClearSelectedPaymentMethod(Math.random());

        clearErrors('paymentMethodTypeId');
        clearErrors('financialPaymentMethodId');

        if (chosenId) {
            dispatch(loadFinancialPaymentMethodDetailsByPaymentMethodType(chosenId));
        }
    };

    const handlePaymentMethodChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, paymentMethodId: chosenId }));
            clearErrors('financialPaymentMethodId');
        }
    };

    const handleTaxChange = (option) => {
        setNewEntry((prev) => ({ ...prev, taxNiIssued: option }));
    };

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setIsClearSelectedAdviser(Math.random());
        setIsClearSelectedRequestType(Math.random());
        setIsClearSelectedRequestReason(Math.random());
        setIsClearSelectedPaymentMethodType(Math.random());
        setIsClearSelectedPaymentMethod(Math.random());
    };

    const getStatusId = (name) =>
        financialRequestStatusDetails.find((item) => item.name.toLowerCase() === name)?.id || '';

    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addEmailAddressAsNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS
    useEffect(() => {
        if (state?.roles) {
            setStateRoles(state.roles);
            setNewEntry((prev) => ({ ...prev, amount: state?.provision.costPerParticipant }));
            setNewEntry((prev) => ({ ...prev, reasonDetail: state?.provision.title }));
        }
        setStateChecked(true);
    }, []);

    useEffect(() => {
        if (!currentParticipant?.id) return;
        setFocus('amount');
        setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));

        if (!financialRequestStatusDetails?.length) dispatch(loadFinancialRequestStatusDetails());
        if (!financialRequestTypeDetails?.length) dispatch(loadFinancialRequestTypeDetails());
        if (!financialPaymentMethodTypeDetails?.length)
            dispatch(loadFinancialPaymentMethodTypeDetails());
        dispatch(loadContractConstants(currentParticipant.contractId));
    }, [currentParticipant]);

    useEffect(() => {
        if (!loggedInUser?.id) return;
        setNewEntry((prev) => ({ ...prev, adviserId: loggedInUser.id }));
        setAdvisersArray([loggedInUser]);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter((el) =>
            el.userTypes?.find((entry) => advisorRoles.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < totalUsers) {
            onLoadMoreAdvisers();
            return;
        }
        if (newEntry.adviserId && !updatedAdvisers.some((el) => el.id === newEntry.adviserId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === newEntry.adviserId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (financialRequestStatusDetails?.length) {
            let status = financialRequestStatusDetails.find(
                (item) => item.name.toLowerCase() === 'requested'
            );
            let id = status.id;
            setNewEntry((prev) => ({ ...prev, statusId: id }));
        }
    }, [financialRequestStatusDetails]);

    useEffect(() => {
        if (financialRequestTypeDetails?.length && arrayFinancialRequestType?.length === 0) {
            setArrayFinancialRequestType(
                getConfiguredItems(financialRequestTypeDetails, currentParticipant?.contractId)
            );
        }
    }, [financialRequestTypeDetails]);

    useEffect(() => {
        if (financialRequestReasonDetails?.length) {
            setRequestReasonRequired(true);
            setValue('requestReasonRequired', true, {
                shouldValidate: true
            });
        } else {
            setRequestReasonRequired(false);
            setValue('requestReasonRequired', false, {
                shouldValidate: true
            });
        }
    }, [financialRequestReasonDetails]);

    useEffect(() => {
        if (financialPaymentMethodTypeDetails?.length && arrayPaymentMethodType?.length === 0) {
            setArrayPaymentMethodType(
                getConfiguredItems(
                    financialPaymentMethodTypeDetails,
                    currentParticipant?.contractId
                )
            );
        }
    }, [financialPaymentMethodTypeDetails]);

    useEffect(() => {
        if (financialPaymentMethodDetails?.length) {
            setPaymentMethodRequired(true);
            setValue('paymentMethodRequired', true, {
                shouldValidate: true
            });
        } else {
            setPaymentMethodRequired(false);
            setValue('paymentMethodRequired', false, {
                shouldValidate: true
            });
        }
    }, [financialPaymentMethodDetails]);

    useEffect(() => {
        if (successMessage === 'Participant financial request has been added') clearForm();
    }, [successMessage]);

    // EVENT HANDLERS
    const onFormExit = () => {
        if (state?.accordionPanelFrom === 'courses') {
            navigate('/course_information', { state: { accordionPanel: 'courses' } });
        } else {
            clearForm();
            clearErrors();
            onClose();
        }
    };

    const onLoadMoreAdvisers = () => {
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                !users.length ? 0 : lastPageOfUsersLoaded + 1
            )
        );
    };

    const onSubmit = () => {
        if (newEntry.amount === '0' || newEntry.amount === '0.0' || newEntry.amount === '0.00') {
            dispatch(setErrorMessage('Amount cannot be zero'));
        } else {
            const inputAmount = parseFloat(newEntry.amount);
            const adviserAmount = parseFloat(dbContractConstants.adviserApprovalLimit);
            const payload =
                inputAmount <= adviserAmount
                    ? {
                          ...newEntry,
                          statusId: getStatusId('approved'),
                          approverId: loggedInUser.id,
                          approverNote: 'Automatically approved as under approval limit'
                      }
                    : newEntry;

            dispatch(createFinancialRequest(payload));
            onFormExit();
        }
    };

    // RENDER
    let content = '';
    if (!stateChecked) content = 'State not checked';

    if (!stateChecked) {
        return <LoadingSpinner content={content} />;
    }

    return (
        <div className={form.formWrapper}>
            <h3>Create Financial Request</h3>
            <form className={form.form} onSubmit={handleSubmit(onSubmit)}>
                {/* This is needed for request reason validations */}
                <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                    <input
                        id="requestReasonRequired"
                        name="requestReasonRequired"
                        value={requestReasonRequired}
                        {...register('requestReasonRequired')}></input>
                </div>
                {/* This is needed for payment method validations */}
                <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                    <input
                        id="paymentMethodRequired"
                        name="paymentMethodRequired"
                        value={paymentMethodRequired}
                        {...register('paymentMethodRequired')}></input>
                </div>
                <br />
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'adviserId'}
                            key={isClearSelectedAdviser}
                            label={'Adviser'}
                            placeholder="Select adviser..."
                            disabled={!hasRole(adminRoles, roles ? roles : stateRoles)}
                            mandatory={true}
                            menuItems={advisers || []}
                            selectedId={newEntry.adviserId || ''}
                            selected={advisers?.find((el) => el.id === newEntry.adviserId) || {}}
                            error={errors.adviserId}
                            onChange={(chosenId) => handleAdviserChange(chosenId)}
                            onLoadMoreItems={onLoadMoreAdvisers}
                            moreItemsToLoad={!totalUsers || users.length < totalUsers}
                        />
                        <LabelledTextField
                            label={'Amount'}
                            id={'amount'}
                            disabled={!hasRole(acceptedRoles, roles ? roles : stateRoles)}
                            mandatory={true}
                            value={newEntry.amount || ''}
                            placeholder={'Enter amount'}
                            error={errors.amount}
                            {...register('amount')}
                            onChange={handleAmount}
                        />
                        <DDLOptionPicker
                            label={'Request Type'}
                            id={'requestTypeId'}
                            key={isClearSelectedRequestType}
                            disabled={!hasRole(acceptedRoles, roles ? roles : stateRoles)}
                            mandatory={true}
                            menuItems={arrayFinancialRequestType}
                            error={errors.requestTypeId}
                            {...register('requestTypeId')}
                            onChange={(chosenId) => handleRequestTypeChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Reason'}
                            id={'financialRequestReasonId'}
                            key={isClearSelectedRequestReason}
                            disabled={
                                !newEntry.requestTypeId ||
                                !hasRole(acceptedRoles, roles ? roles : stateRoles)
                            }
                            mandatory={requestReasonRequired}
                            menuItems={financialRequestReasonDetails || []}
                            error={errors.financialRequestReasonId}
                            {...register('financialRequestReasonId')}
                            onChange={(chosenId) => handleRequestReasonChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Payment Method Type'}
                            id={'paymentMethodTypeId'}
                            key={isClearSelectedPaymentMethodType}
                            disabled={!hasRole(acceptedRoles, roles ? roles : stateRoles)}
                            mandatory={true}
                            menuItems={arrayPaymentMethodType}
                            error={errors.paymentMethodTypeId}
                            {...register('paymentMethodTypeId')}
                            onChange={(chosenId) => handlePaymentMethodTypeChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Payment Method'}
                            id={'financialPaymentMethodId'}
                            key={isClearSelectedPaymentMethod}
                            disabled={
                                !newEntry.paymentMethodTypeId ||
                                !hasRole(acceptedRoles, roles ? roles : stateRoles)
                            }
                            mandatory={paymentMethodRequired}
                            menuItems={financialPaymentMethodDetails || []}
                            error={errors.financialPaymentMethodId}
                            {...register('financialPaymentMethodId')}
                            onChange={(chosenId) => handlePaymentMethodChange(chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Reason Details'}
                            id={'reasonDetail'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles ? roles : stateRoles)}
                            multiline
                            rows={10}
                            value={newEntry.reasonDetail}
                            placeholder={'Enter reason details'}
                            counter={'true'}
                            helperText={
                                `${newEntry.reasonDetail.length}` + '/' + REASON_DETAILS_LIMIT
                            }
                            inputProps={{ maxLength: REASON_DETAILS_LIMIT }}
                            error={errors.reasonDetail}
                            {...register('reasonDetail')}
                            onChange={(e) => {
                                clearErrors('reasonDetail');
                                setNewEntry((prev) => ({ ...prev, reasonDetail: e.target.value }));
                            }}
                        />
                        <LabelledTextField
                            label={'Date Requested'}
                            id={'dateRequested'}
                            disabled={true}
                            mandatory={true}
                            value={format(today, dateFormat)}
                        />
                        <NoYesRadioPicker
                            id="tax"
                            disabled={!hasRole(acceptedRoles, roles ? roles : stateRoles)}
                            radioButtonPick={newEntry.taxNiIssued || false}
                            text={'Tax and NI issued?'}
                            onChange={handleTaxChange}></NoYesRadioPicker>
                    </div>
                </div>
                <div className={actions.formActions}>
                    <Button type="submit" color="primary" variant="contained">
                        Add Financial Request
                    </Button>
                    <div className={actions.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    amount: Yup.string()
        .nullable()
        .min(0.1, 'Amount cannot be negative, blank or zero')
        .max(9999.99, 'Amount must be 9999.99 or less')
        .matches(validate.PRICE_REGEXP, 'Invalid Amount'),
    requestTypeId: Yup.string().required('Please select a request type'),
    requestReasonRequired: Yup.boolean(),
    financialRequestReasonId: Yup.string().when('requestReasonRequired', {
        is: (requestReasonRequired) => requestReasonRequired === true,
        then: () => Yup.string().required('Please select a request reason')
    }),
    paymentMethodTypeId: Yup.string().required('Please select a payment method type'),
    paymentMethodRequired: Yup.boolean(),
    financialPaymentMethodId: Yup.string().when('paymentMethodRequired', {
        is: (paymentMethodRequired) => paymentMethodRequired === true,
        then: () => Yup.string().required('Please select a payment method')
    }),
    reasonDetail: Yup.string().min(7, 'Reason details must be at least seven characters')
});

export default CreateFinancialRequest;

CreateFinancialRequest.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string
};
