import { getSelectValueMessage } from '../../../utils/formValidation/errorMessageFunctions';

const NOTE_MIN_CHAR = 1;
export const NOTE_CHAR_LIMIT = 750;

const ERROR_MIN_TEXT = `Word count cannot be less than ${NOTE_MIN_CHAR}`;
const ERROR_MAX_TEXT = `Word count cannot be more than ${NOTE_CHAR_LIMIT}`;

export const initialErrorState = {
    actionStatusId: { error: false, message: '' },
    actionNotes: { error: false, message: '' }
};

export const validateNotes = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (newEntry.actionNotes.trim().length < NOTE_MIN_CHAR) {
        newErrors = {
            ...newErrors,
            actionNotes: { error: true, message: ERROR_MIN_TEXT }
        };
        isValid = false;
    }

    if (newEntry.actionNotes.trim().length > NOTE_CHAR_LIMIT) {
        newErrors = {
            ...newErrors,
            actionNotes: { error: true, message: ERROR_MAX_TEXT }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};

export const validateStatusId = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;
    if (!newEntry.actionStatusId) {
        newErrors = {
            ...newErrors,
            actionStatusId: { error: true, message: getSelectValueMessage() }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};
