import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { updateWaistMeasurement } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';

import {
    checkDate,
    checkMeasurement,
    initialErrorState,
    validate
} from './validateWaistMeasurements';

import form from '../../../../commonStyles/formStyles.module.css';

const EditWaistMeasurement = ({ onClose, roles, row }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        participantId: '',
        measurement: '',
        measurementDate: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isClearMeasurement, setIsClearMeasurement] = useState('0');
    const msg = 'Participant waist measurement has been updated';

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setIsClearMeasurement(Math.random());
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            measurementDate: row.measurementDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onMeasurementChange = (value) => {
        clearError('measurement');
        setNewEntry((prev) => ({
            ...prev,
            measurement: value.replace(/[^\d]/gi, '').slice(0, 5)
        }));
        const newErrors = checkMeasurement(value, errors);
        setErrors(newErrors);
    };

    const onDateChange = (date) => {
        clearError('measurementDate');
        setNewEntry((prev) => ({ ...prev, measurementDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const payload = { ...newEntry, participantId: currentParticipant?.id };
        dispatch(updateWaistMeasurement(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>Edit Waist Measurement</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            key={isClearMeasurement}
                            label={'Measurement (cm)'}
                            id={'measurement'}
                            mandatory={true}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            value={newEntry.measurement}
                            placeholder={'Enter measurement'}
                            onChange={(e) => onMeasurementChange(e.target.value)}
                        />
                        {errors.measurement.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.measurement} />
                            </div>
                        )}
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.measurementDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.measurementDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText="Update"
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default EditWaistMeasurement;

EditWaistMeasurement.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    row: PropTypes.object
};
