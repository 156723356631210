import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import OnFileDownloadIcon from '../../../../icons/OnFileDownloadIcon';
import { downloadParticipantDocument } from '../../../../store/documentService';
import { selectLoggedInUser, selectUserName } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';

const PoolsTableRow = ({ row, roles }) => {
    //HOOKS
    const dispatch = useDispatch();
    const onSelectUserName = useMemo(selectUserName, []);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const adviser = useSelector((state) => onSelectUserName(state, row.ownerId));

    // Event HANDLERS
    const onDownload = () =>
        dispatch(downloadParticipantDocument(currentParticipant.id, row.filename));

    // RENDER
    return (
        <>
            <TableRow key={row.id} hover className={classes.basicRowStripes}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{adviser}</TableCell>
                <TableCell>{row.sectorName}</TableCell>
                <TableCell disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                    {row.filename}
                </TableCell>
                <TableCell align="center">
                    <OnFileDownloadIcon
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={hasRole(acceptedRoles, loggedInUser.roles) && row?.filename !== ''}
                        onDownload={onDownload}
                        participantId={currentParticipant.id}
                        filename={row.filename}
                    />
                </TableCell>
                <TableCell>{reverseFormatDate(row.startDate)}</TableCell>
                <TableCell>{row.statusName}</TableCell>
            </TableRow>
        </>
    );
};

export default PoolsTableRow;

PoolsTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string)
};
