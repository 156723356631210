import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { InboxOutlined } from '@ant-design/icons';
import { Button, CircularProgress } from '@mui/material';

import { deleteFileUploadErrorEntries, onClearUploadErrors } from '../../store/documentService';
import { setErrorMessage } from '../../store/formsState';
import { POLARIS_ROYAL_BLUE } from '../../themes/theme';
import LoadingTimeNotice from '../ui/notices/loadingTimeNotice/LoadingTimeNotice';

import '../../commonStyles/antUploaderStyles.css';
import modal from '../../commonStyles/modal.module.css';
import local from './uploaders.module.css';

const { Dragger } = Upload;

const Uploader = ({
    uploadData,
    acceptedFileTypes,
    fileTypes,
    maxFileSize = 20,
    onUpload,
    onClose,
    onCancel,
    btnText,
    hideCancel = false,
    showLoadingNotice = false,
    isDisabled = false,
    multiple = false
}) => {
    const dispatch = useDispatch();

    const [csvFileName, setCsvFileName] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [isPending, setIsPending] = useState(false);
    const uploadErrors = useSelector((state) => state.entities.documentService.uploadErrors);
    const uploadErrorFiles = useSelector(
        (state) => state.entities.documentService.uploadErrorFiles
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    const uploadProps = {
        name: 'file',
        beforeUpload(file) {
            const isValidSize = file.size / 1024 / 1024 <= maxFileSize;
            if (!isValidSize) {
                dispatch(setErrorMessage(`File must be smaller than ${maxFileSize}MB`));
                return false;
            }
            if (!acceptedFileTypes.includes(file.type)) {
                dispatch(setErrorMessage(`Please use a valid attachment type`));
                return false;
            }
        },

        multiple: multiple,
        onChange() {},
        customRequest(info) {
            const { status, name } = info.file;
            setCsvFileName(name);
            const payload = new FormData();
            payload.append('file', info.file);
            if (uploadData) uploadData.forEach((el) => payload.append(el.key, el.value));
            setIsPending(true);
            const successMsg = `Document ${info.file.name} has been uploaded`;
            setSuccessMsg(successMsg);
            onUpload(payload, successMsg);
            if (status === 'error') {
                dispatch(
                    setErrorMessage(
                        `${info.file.name} upload failed. Please use a valid attachment type. Accepted types are ${fileTypes}`
                    )
                );
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    };

    useEffect(() => {
        setIsPending(false);
        if (
            uploadErrors?.length < 1 &&
            Object.keys(uploadErrorFiles).length < 1 &&
            successMessage !== '' &&
            successMessage === successMsg
        ) {
            onFormExit();
            onClose();
        }
        if (uploadErrors?.length) {
            onClose(uploadErrors, csvFileName);
            dispatch(onClearUploadErrors());
        }
        if (Object.keys(uploadErrorFiles)?.length) {
            onClose();
        }
    }, [successMessage, uploadErrors, uploadErrorFiles]);

    // EVENT HANDLERS

    const onFormExit = async () => {
        uploadErrors?.length && dispatch(deleteFileUploadErrorEntries());
    };

    const onComplete = () => {
        onFormExit();
        onClose();
    };

    const onExit = () => {
        onFormExit();
        onCancel();
    };

    return (
        <div className={modal.modalUploaderContent}>
            <div className={local.modalUploaderForm}>
                {showLoadingNotice && <LoadingTimeNotice />}
                <div className={local.uploader}>
                    {isPending && (
                        <div className={local.loadingSpinner}>
                            <CircularProgress sx={{ color: POLARIS_ROYAL_BLUE }} />
                        </div>
                    )}
                    <Dragger {...uploadProps} disabled={isDisabled}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">{`Support for fileTypes: ${fileTypes}`}</p>
                    </Dragger>
                </div>

                <div className={modal.actionButtons}>
                    {btnText && (
                        <Button onClick={onComplete} color="primary" variant="contained">
                            {btnText}
                        </Button>
                    )}
                    {!hideCancel && (
                        <div className={modal.cancelButton}>
                            <Button variant={'contained'} onClick={onExit}>
                                Cancel
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Uploader;

Uploader.propTypes = {
    uploadData: PropTypes.arrayOf(PropTypes.object),
    acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
    fileTypes: PropTypes.string,
    maxFileSize: PropTypes.number,
    onUpload: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onError: PropTypes.func,
    btnText: PropTypes.string,
    hideCancel: PropTypes.bool,
    showLoadingNotice: PropTypes.bool,
    isDisabled: PropTypes.bool,
    multiple: PropTypes.bool
};
