import {
    getCharMinMessage,
    getFormatMessage,
    getReqMessage
} from '../../../../utils/formValidation/errorMessageFunctions';
import { charVal, names, regExps } from '../../../../utils/formValidation/validationPatterns';

export const validate = (newEntry, errors) => {
    let newErrors = errors;
    if (!newEntry.firstName) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: getReqMessage(names.FIRST_NAME) }
        };
    } else if (!regExps.NAME_R.test(newEntry.firstName)) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: getFormatMessage(names.FIRST_NAME) }
        };
    }

    if (!newEntry.lastName) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: getReqMessage(names.LAST_NAME) }
        };
    } else if (!regExps.LAST_NAME_R.test(newEntry.lastName)) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: getFormatMessage(names.LAST_NAME) }
        };
    }

    if (!newEntry.officeNumber) {
        newErrors = {
            ...newErrors,
            officeNumber: { error: true, message: getReqMessage(names.PHONE) }
        };
    } else if (newEntry.officeNumber.trim().length < charVal.MIN_PHONE) {
        newErrors = {
            ...newErrors,
            officeNumber: { error: true, message: getCharMinMessage(names.PHONE) }
        };
    } else if (!regExps.PHONE_R.test(newEntry.officeNumber)) {
        newErrors = {
            ...newErrors,
            officeNumber: { error: true, message: getFormatMessage(names.PHONE) }
        };
    }

    if (newEntry.mobileNumber) {
        if (newEntry.mobileNumber.trim().length < charVal.MIN_PHONE) {
            newErrors = {
                ...newErrors,
                mobileNumber: { error: true, message: getCharMinMessage(names.PHONE) }
            };
        } else if (!regExps.PHONE_R.test(newEntry.mobileNumber)) {
            newErrors = {
                ...newErrors,
                mobileNumber: { error: true, message: getFormatMessage(names.PHONE) }
            };
        }
    }

    if (!newEntry.email) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getReqMessage(names.EMAIL) }
        };
    } else if (newEntry.email.trim().length < charVal.MIN_EMAIL) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getCharMinMessage(names.EMAIL) }
        };
    } else if (!regExps.EMAIL_R.test(newEntry.email)) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getFormatMessage(names.EMAIL) }
        };
    }

    return newErrors;
};
