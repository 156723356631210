import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { clearGenders, loadGenderDetails } from '../../../store/directusService';
import { updatePreSelects, updateSelectedItems } from '../../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validatePrimaryDetailsAdmin';

const PrimaryDetailsAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const viewRoles = [LOCAL_ADMIN, SUPERUSER];
    const acceptedRoles = [SUPERUSER];
    const collectionName = 'gender';
    const urlName = 'gender';
    const submitMessage = 'Updating gender collection';
    const [errors, setErrors] = useState(initialErrorState);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [preSelectedGenderIds, setPreSelectedGenderIds] = useState([]);
    const [preSelectedGenders, setPreSelectedGenders] = useState([]);
    const [isClearSelectedGenders, setIsClearSelectedGenders] = useState('0');
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { genderDetails } = useSelector((state) => state.entities.directusService);

    // USE EFFECTS
    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (genderDetails?.length) {
            const { preSelects, preSelectIds } = updatePreSelects(
                collectionName,
                genderDetails,
                contractId
            );
            setPreSelectedGenders(preSelects);
            setPreSelectedGenderIds(preSelectIds);
        }
    }, [genderDetails]);

    useEffect(() => {
        if (successMessage.includes(submitMessage)) {
            contractSetup();
        }
    }, [successMessage]);

    // HELPER FNS
    const contractSetup = () => {
        setIsDisabled(false);
        setSelectedGenders([]);
        setPreSelectedGenders([]);
        setErrors(initialErrorState);
        setIsClearSelectedGenders(Math.random());
        dispatch(clearGenders());
        dispatch(loadGenderDetails());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onGenderChange = (chosenIds) => {
        setIsDisabled(false);
        clearError('genderDetails');
        if (!chosenIds) chosenIds = [];
        setSelectedGenders(chosenIds);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(selectedGenders, errors);
        setErrors(newErrors);
        setIsDisabled(true);
        if (!isValid) return;
        updateSelectedItems(
            urlName,
            genderDetails,
            selectedGenders,
            contractId,
            collectionName,
            dispatch
        );
    };

    //  AWAITING CONTENT
    let content = '';
    if (!contractId) content = 'No contract';
    if (genderDetails?.length < 1) content = 'No gender details';

    if (!hasRole(viewRoles, roles)) return <NotFound />;

    if (!contractId || genderDetails?.length < 1) {
        return <LoadingSpinner content={content} />;
    }

    // RENDER
    return (
        <form onSubmit={onSubmit} data-testid="form_start">
            <p>First Name</p>
            <p>Middle Name</p>
            <p>Last Name</p>
            <p>Preferred Name</p>
            <p>Preferred Pronouns</p>
            <div id="genderDetailsWrapper">
                <MultiSelect
                    id="genderDetails"
                    key={isClearSelectedGenders}
                    label="Gender"
                    placeholder="Gender"
                    disabled={!hasRole(acceptedRoles, roles)}
                    mandatory={true}
                    menuItems={genderDetails || []}
                    preSelectedIds={preSelectedGenderIds}
                    preSelects={preSelectedGenders}
                    error={errors.genderDetails}
                    onChange={(chosenIds) => onGenderChange(chosenIds)}
                />
            </div>
            <p>Date of Birth</p>
            <p>Address line 1</p>
            <p>Address line 2</p>
            <p>Address line 3</p>
            <p>Town/City</p>
            <p>Postcode</p>
            <p>Care of Address</p>
            <p>Primary Phone Number</p>
            <p>Secondary Phone Number</p>
            <p>Email Address</p>
            <p>Unique Identifier</p>
            <p>Eligibility Groups DDL</p>
            <p>Referral Sources DDL</p>
            <p>Referral Date</p>
            <p>Start Date</p>
            <p>Adviser</p>
            <p>Current Status</p>
            <p>Preferred method of contact</p>
            <p>Possible Good News Story?</p>
            <p>&nbsp;</p>
            <Button
                id="primaryDetailsAdmin"
                content="UPDATE PRIMARY DETAILS"
                disabled={!hasRole(acceptedRoles, roles) || isDisabled}
            />
        </form>
    );
};

PrimaryDetailsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default PrimaryDetailsAdmin;
