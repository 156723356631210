import PropTypes from 'prop-types';

import ErrorIcon from '@mui/icons-material/Error';
/**
 * Use this for the header text on accordions.
 */
import Typography from '@mui/material/Typography';

import { SCREEN_SETTINGS } from '../../../themes/theme';

import accordionHeaderClasses from './accordionHeader.module.css';

const AccordionHeader = ({ isValid = true, children }) => {
    return (
        <Typography variant={'h5'} sx={{ marginLeft: SCREEN_SETTINGS.gap.huge, flexGrow: 1 }}>
            {children}
            {!isValid && <ErrorIcon className={accordionHeaderClasses.errorIcon} />}
        </Typography>
    );
};
AccordionHeader.propTypes = {
    isValid: PropTypes.bool,
    children: PropTypes.any
};

export default AccordionHeader;
