import React from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

const OnEditProvisionIcon = ({ onEdit, roles, acceptedRoles }) => {
    acceptedRoles = acceptedRoles?.length > 0 ? acceptedRoles : [];
    if (hasRole(roles, acceptedRoles)) {
        return (
            <Tooltip title="Edit" placement="top">
                <EditIcon
                    sx={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}
                    aria-label="edit-icon"
                    data-testid="edit-icon"
                    onClick={onEdit}></EditIcon>
            </Tooltip>
        );
    }

    return (
        <EditIcon
            sx={{ color: POLARIS_DARKER_GREY }}
            data-testid="edit-icon"
            aria-label="edit-icon"></EditIcon>
    );
};

export default OnEditProvisionIcon;

OnEditProvisionIcon.propTypes = {
    onEdit: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};
