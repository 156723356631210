import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import { deleteStep, loadSteps, onDeleteStep } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CreateStep from './CreateStep';
import EditStep from './EditStep';
import StepsTable from './StepsTable';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const Steps = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const steps = useSelector((state) => state.entities.participantService.steps);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        currentParticipant?.id && dispatch(loadSteps(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        const rows = steps.map((el) => ({
            ...el,
            stepsDate: reverseFormatDate(el.stepsDate)
        }));
        setRows(rows);
    }, [steps]);

    useEffect(() => {
        if (successMessage === `Step deleted`) dispatch(onDeleteStep(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteStep(row.id));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Steps</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No steps found" />
                ) : (
                    <StepsTable rows={rows} roles={roles} onEdit={onEdit} onDelete={onDelete} />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Steps Value
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && formType === 'create' && (
                        <CreateStep onClose={() => setShowForm(false)} roles={roles} />
                    )}
                </div>
                {showForm && formType === 'edit' && (
                    <EditStep onClose={() => setShowForm(false)} roles={roles} row={selectedRow} />
                )}
            </div>
        </div>
    );
};

export default Steps;
