import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadContractDetails, loadServiceDetailsByContract } from '../../store/directusService';
import {
    clearOtherTeamsForService,
    clearTeamsForService,
    createUser,
    loadOtherTeamsForService,
    loadTeamsForService,
    loadUserTypes
} from '../../store/userService';
import { compareUnorderedArrays } from '../../utils/arrayUtils';
import { clearKeys } from '../../utils/objectUtils';
import { hasRole, QUALITY, SUPERUSER } from '../../utils/userRoles';
import Button from '../formElements/Button';
import MultiSelect from '../formElements/MultiSelect';
import RadioButtons from '../formElements/RadioButtons';
import SingleSelect from '../formElements/SingleSelect';
import TextInputField from '../formElements/TextInputField';
import LoadingSpinner from '../ui/LoadingSpinner';

import { checkEmailAddress, EMAIL_EXISTS, initialErrorState, validate } from './validateCreateUser';

import app from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const CreateUser = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE

    const initialState = {
        id: null,
        payroll: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        jobTitle: '',
        phoneNumber: '',
        contractIds: [],
        primaryServiceId: '',
        otherServiceIds: [],
        primaryTeamId: '',
        otherTeamIds: [],
        userTypeIds: [],
        active: true
    };
    const acceptedRoles = [QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [otherServiceDetails, setOtherServiceDetails] = useState([]);
    const [keys, setKeys] = useState({ primaryServiceId: '0', primaryTeamId: '1' });
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { contractDetails, serviceDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { userTypes, otherTeamsForService, teamsForService } = useSelector(
        (state) => state.entities.userService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadContractDetails());
        dispatch(loadUserTypes());
    }, []);

    useEffect(() => {
        dispatch(clearTeamsForService());
        dispatch(clearOtherTeamsForService());
        setKeys(clearKeys(keys));
        newEntry.contractIds?.length > 0 &&
            dispatch(loadServiceDetailsByContract(newEntry.contractIds));
    }, [newEntry.contractIds]);

    useEffect(() => {
        dispatch(clearTeamsForService());
        dispatch(clearOtherTeamsForService());
        setKeys((prev) => ({ ...prev, primaryTeamId: Math.random() }));
        if (newEntry.primaryServiceId) {
            dispatch(loadTeamsForService({ serviceIds: [newEntry.primaryServiceId] }));
            setOtherServiceDetails(
                serviceDetails.filter((item) => item.id !== newEntry.primaryServiceId)
            );
        }
    }, [newEntry.primaryServiceId]);

    useEffect(() => {
        if (newEntry.otherServiceIds.length > 0) {
            dispatch(loadOtherTeamsForService({ serviceIds: newEntry.otherServiceIds }));
        }
    }, [newEntry.otherServiceIds]);

    useEffect(() => {
        if (successMessage.includes(`Created user `)) {
            clearForm();
        }
    }, [successMessage]);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setKeys(clearKeys(keys));
        setIsDisabled(false);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onPayrollChange = (e) => {
        clearError('payroll');
        setNewEntry((prev) => ({ ...prev, payroll: e.target.value }));
    };

    const onFirstNameChange = (e) => {
        clearError('firstName');
        setNewEntry((prev) => ({ ...prev, firstName: e.target.value }));
    };

    const onLastNameChange = (e) => {
        clearError('lastName');
        setNewEntry((prev) => ({ ...prev, lastName: e.target.value }));
    };

    const onEmailChange = (e) => {
        const { value } = e.target;
        clearError('emailAddress');
        isDisabled && setIsDisabled(false);
        setNewEntry((prev) => ({ ...prev, emailAddress: value }));
    };

    const onJobTitleChange = (e) => {
        clearError('jobTitle');
        setNewEntry((prev) => ({ ...prev, jobTitle: e.target.value }));
    };

    const onPhoneNumberChange = (e) => {
        clearError('phoneNumber');
        setNewEntry((prev) => ({ ...prev, phoneNumber: e.target.value }));
    };

    const onContractsChange = (chosenIds) => {
        clearError('contractIds');
        clearError('primaryServiceId');
        clearError('primaryTeamId');
        if (!chosenIds) chosenIds = [];
        if (compareUnorderedArrays(chosenIds, newEntry.contractIds)) return;
        setNewEntry((prev) => ({
            ...prev,
            contractIds: chosenIds,
            primaryServiceId: '',
            otherServiceIds: [],
            primaryTeamId: '',
            otherTeamIds: []
        }));
    };

    const onPrimaryServiceChange = (chosenId) => {
        clearError('primaryServiceId');
        clearError('primaryTeamId');
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({
            ...prev,
            primaryServiceId: chosenId,
            otherServiceIds: [],
            primaryTeamId: '',
            otherTeamIds: []
        }));
    };

    const onOtherServicesChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        if (compareUnorderedArrays(chosenIds, newEntry.otherServiceIds)) return;
        setNewEntry((prev) => ({ ...prev, otherServiceIds: chosenIds }));
    };

    const onPrimaryTeamChange = (chosenId) => {
        clearError('primaryTeamId');
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, primaryTeamId: chosenId }));
    };

    const onOtherTeamsChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        if (compareUnorderedArrays(chosenIds, newEntry.otherTeamIds)) return;
        setNewEntry((prev) => ({ ...prev, otherTeamIds: chosenIds }));
    };

    const onSelectedUserTypesChange = (chosenIds) => {
        clearError('userTypeIds');
        if (!chosenIds) chosenIds = [];
        if (compareUnorderedArrays(chosenIds, newEntry.userTypeIds)) return;
        setNewEntry((prev) => ({ ...prev, userTypeIds: chosenIds }));
    };

    const onActiveChange = (value) => {
        clearError('active');
        setNewEntry((prev) => ({ ...prev, active: value }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, userTypes);
        setErrors(newErrors);
        if (!isValid) return;
        setIsDisabled(true);
        const isExisting = await checkEmailAddress(newEntry.emailAddress);
        if (isExisting) {
            setErrors({
                ...newErrors,
                emailAddress: { error: true, message: EMAIL_EXISTS }
            });
            return;
        }
        const payload = { ...newEntry, id: null };
        dispatch(createUser(payload));
    };

    // AWAITING CONTENT
    let content;
    if (contractDetails?.length < 1) content = 'No contract details found';
    if (contractDetails?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <div className={form.formWrapper} data-testid="form_start">
            <form className={form.form} onSubmit={onSubmit}>
                <h2 className={app.mainHeading}>Create User</h2>
                <h2 className={app.sectionHeading}>Contact Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'payroll'}
                            label={'Payroll'}
                            placeholder={'Enter payroll number'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.payroll || ''}
                            error={errors.payroll}
                            onChange={onPayrollChange}
                        />
                        <TextInputField
                            id={'firstName'}
                            label={'First Name'}
                            placeholder={'Enter first name'}
                            mandatory={true}
                            value={newEntry.firstName || ''}
                            error={errors.firstName}
                            onChange={onFirstNameChange}
                        />
                        <TextInputField
                            id={'lastName'}
                            label={'Last Name'}
                            placeholder={'Enter last name'}
                            mandatory={true}
                            value={newEntry.lastName || ''}
                            error={errors.lastName}
                            onChange={onLastNameChange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'emailAddress'}
                            label={'Email Address'}
                            placeholder={'Enter email address'}
                            mandatory={true}
                            value={newEntry.emailAddress || ''}
                            error={errors.emailAddress}
                            onChange={onEmailChange}
                        />
                        <TextInputField
                            id={'jobTitle'}
                            label={'Job Title'}
                            placeholder={'Enter job title'}
                            value={newEntry.jobTitle || ''}
                            error={errors.jobTitle}
                            onChange={onJobTitleChange}
                        />
                        <TextInputField
                            id="phoneNumber"
                            label="Phone Number"
                            placeholder={'Enter phone number'}
                            value={newEntry.phoneNumber || ''}
                            error={errors.phoneNumber}
                            onChange={onPhoneNumberChange}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Contract Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="contractIds"
                            label="Contracts"
                            placeholder="Select contract(s)"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={contractDetails || []}
                            preSelectedIds={newEntry.contractIds || []}
                            preSelects={
                                contractDetails.filter((el) =>
                                    newEntry.contractIds.includes(el.id)
                                ) || []
                            }
                            error={errors.contractIds}
                            onChange={(chosenIds) => onContractsChange(chosenIds)}
                        />
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'primaryServiceId'}
                            key={keys.primaryServiceId}
                            label={'Primary Service'}
                            placeholder="Select primary service..."
                            disabled={
                                !hasRole(acceptedRoles, roles) || newEntry.contractIds?.length < 1
                            }
                            mandatory={true}
                            menuItems={serviceDetails || []}
                            selectedId={newEntry.primaryServiceId}
                            selected={
                                serviceDetails.find((el) => el.id === newEntry.primaryServiceId) ||
                                {}
                            }
                            error={errors.primaryServiceId}
                            onChange={(chosenId) => onPrimaryServiceChange(chosenId)}
                        />
                        <MultiSelect
                            id="otherServices"
                            label="Other Services"
                            placeholder="Other service(s)"
                            disabled={
                                !hasRole(acceptedRoles, roles) || newEntry.contractIds?.length < 1
                            }
                            menuItems={otherServiceDetails || []}
                            preSelectedIds={newEntry.otherServiceIds || []}
                            preSelects={
                                otherServiceDetails.filter((el) =>
                                    newEntry.otherServiceIds.includes(el.id)
                                ) || []
                            }
                            onChange={(chosenIds) => onOtherServicesChange(chosenIds)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'primaryTeamId'}
                            key={keys.primaryTeamId}
                            label={'Primary Team'}
                            placeholder="Select primary team..."
                            disabled={!hasRole(acceptedRoles, roles) || !newEntry.primaryServiceId}
                            mandatory={true}
                            menuItems={teamsForService || []}
                            selectedId={newEntry.primaryTeamId || ''}
                            selected={
                                teamsForService.find((el) => el.id === newEntry.primaryTeamId) || {}
                            }
                            error={errors.primaryTeamId}
                            onChange={(chosenId) => onPrimaryTeamChange(chosenId)}
                        />

                        <MultiSelect
                            id="otherTeams"
                            label={'Other Teams'}
                            placeholder="Other team(s)"
                            disabled={!hasRole(acceptedRoles, roles) || !newEntry.primaryServiceId}
                            menuItems={otherTeamsForService || []}
                            preSelectedIds={newEntry.otherTeamIds || []}
                            preSelects={
                                otherTeamsForService.filter((el) =>
                                    newEntry.otherTeamIds.includes(el.id)
                                ) || []
                            }
                            onChange={(chosenIds) => onOtherTeamsChange(chosenIds)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>User Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="userTypeIds"
                            label={'User Types'}
                            placeholder="User type(s)"
                            selectAllDisabled={true}
                            mandatory={true}
                            menuItems={userTypes || []}
                            preSelectedIds={newEntry.userTypeIds}
                            preSelects={
                                userTypes.filter((el) => newEntry.userTypeIds.includes(el.id)) || []
                            }
                            error={errors.userTypeIds}
                            onChange={(chosenIds) => onSelectedUserTypesChange(chosenIds)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <RadioButtons
                            id="active"
                            disabled={!hasRole(acceptedRoles, roles)}
                            label="Active?"
                            value={newEntry.active}
                            error={errors.active}
                            onChange={onActiveChange}
                        />
                    </div>
                </div>
                <Button
                    id="createUser"
                    content="Create"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                />
            </form>
        </div>
    );
};

export default CreateUser;
