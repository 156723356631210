import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import expand from '../../../../commonStyles/rowExpand.module.css';
import content from './signPosting.module.css';

const SignPostingRowDetails = ({ row, onClose }) => {
    return (
        <div className={expand.rowDropdown}>
            <h3>Notes</h3>
            <div className={expand.content}>
                <div className={content.expandNotes}>{row.notes}</div>
            </div>
            <div className={expand.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default SignPostingRowDetails;

SignPostingRowDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
