import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Divider, Link, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../store/directusService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import BusinessView from '../../ui/cards/businessContact/BusinessView';
import LabelledPostcodeText from '../../ui/editors/LabelledPostcodeText';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

import cardClasses from '../../../commonStyles/cardContainer.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const VacancyManagement = ({ vacancy, accordionPanelFrom, jobsOfferedForVacancies }) => {
    // HOOKS
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();

    // LOCAL STATE
    const [jobsOfferedForVacancy, setJobsOfferedForVacancy] = useState(0);

    // STORE STATE
    const { jobSectorDetails, vacancyStageDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentBusiness } = useSelector((state) => state.entities.recruitmentService);

    // HELPER FNS
    const onNavigate = () => {
        if (!state?.accordionPanelFrom) {
            navigate('/recruitment_management', {
                state: { accordionPanel: 'myBusinesses' }
            });
        } else {
            navigate('/recruitment_management', {
                state: { accordionPanel: accordionPanelFrom }
            });
        }
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadJobSectorDetails());
        dispatch(loadVacancyStageDetails());

        if (!jobsOfferedForVacancies) setJobsOfferedForVacancy(vacancy.jobsOffered);
        else
            setJobsOfferedForVacancy(
                jobsOfferedForVacancies?.find((entry) => entry.vacancyId === vacancy.id)
                    ?.jobsOffered || 0
            );
    }, []);

    const onCancel = () => onNavigate();

    if (jobSectorDetails?.length < 1) return <LoadingSpinner content="No job sector found" />;
    if (vacancyStageDetails?.length < 1) return <LoadingSpinner content="No vacancy stage found" />;
    if (Object.keys(currentBusiness).length < 1)
        return <LoadingSpinner content="No current business found" />;

    return (
        <div className={form.formWrapper}>
            <form data-testid="form_start" className={form.form}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Business Record'}
                            id={'businessRecord'}
                            disabled={true}
                            value={currentBusiness.name}
                        />
                        <LabelledTextField
                            label={'Pay Rate (£)'}
                            id={'title'}
                            disabled={true}
                            value={`${vacancy.payRateFrom} - ${vacancy.payRateTo}`}
                        />
                        <LabelledTextField
                            label={'Expected Weekly Hours'}
                            id={'title'}
                            disabled={true}
                            value={vacancy.expectedWeeklyHours}
                        />
                        <LabelledTextField
                            label={'Anticipated Start Date'}
                            id={'title'}
                            disabled={true}
                            value={reverseFormatDate(vacancy.anticipatedStartDate)}
                        />
                        <LabelledTextField
                            label="Vacancy Closing Date"
                            id="vacancyClosingDate"
                            disabled={true}
                            value={reverseFormatDate(vacancy.vacancyClosingDate)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <Typography variant="h6">Hiring Manager</Typography>
                        <div className={cardClasses.cards}>
                            <BusinessView
                                cardData={currentBusiness.contactCards.find(
                                    (entry) => entry.id === vacancy.hiringManagerId
                                )}
                            />
                        </div>
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
                <LabelledTextField
                    label={'Description'}
                    id={'description'}
                    disabled={true}
                    multiline
                    rows={5}
                    value={vacancy.description}
                />
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Requirements'}
                            id={'requirements'}
                            disabled={true}
                            multiline
                            rows={5}
                            value={vacancy.requirement}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Shift Patterns/Working Hours'}
                            id={'shiftPattern'}
                            disabled={true}
                            multiline
                            rows={5}
                            value={vacancy.shiftPatternWorkingHours}
                        />
                    </div>
                </div>
                <NoYesRadioPicker
                    disabled={true}
                    text={'Remote?'}
                    radioButtonPick={vacancy.remote}
                />
                <div hidden={vacancy.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Address 1'}
                                id={'address1'}
                                disabled={true}
                                value={vacancy.addressLine1}
                            />
                            <LabelledTextField
                                label={'Address 2'}
                                id={'address2'}
                                disabled={true}
                                value={vacancy.addressLine2 || ''}
                            />
                            <LabelledTextField
                                label={'Address 3'}
                                id={'address3'}
                                disabled={true}
                                value={vacancy.addressLine3 || ''}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Town/City'}
                                id={'city'}
                                disabled={true}
                                value={vacancy.city}
                            />
                            <LabelledPostcodeText
                                label={'Postcode'}
                                id={'postcode'}
                                disabled={true}
                                value={vacancy.postcode}
                            />
                        </div>
                    </div>
                </div>
                <Typography variant="h6">Application Process</Typography>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {vacancy.stageIds.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <LabelledTextField
                                            label={
                                                i < vacancy.stageIds.length - 1
                                                    ? `Stage ${i + 1}`
                                                    : 'Final Stage'
                                            }
                                            id={'stageId'}
                                            name={'stageId'}
                                            disabled={true}
                                            value={getNameFromId(vacancyStageDetails, el)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Job Sector'}
                            id={'jobSector'}
                            disabled={true}
                            value={getNameFromId(jobSectorDetails, vacancy.sectorId)}
                        />
                        <LabelledTextField
                            label={'Number of Available Positions'}
                            id={'positions'}
                            disabled={true}
                            value={`${vacancy.numberOfPositions - jobsOfferedForVacancy} / ${
                                vacancy.numberOfPositions
                            }`}
                        />
                    </div>
                </div>
                <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                <Stack direction="row" spacing={4}>
                    <Link
                        label="Cancel X"
                        underline="always"
                        variant="body1"
                        color="primary"
                        onClick={() => onCancel()}
                        sx={{
                            paddingTop: SCREEN_SETTINGS.margin.large,
                            cursor: 'pointer'
                        }}>
                        Cancel X
                    </Link>
                </Stack>
                <br />
                <p>{}</p>
            </form>
        </div>
    );
};

VacancyManagement.propTypes = {
    vacancy: PropTypes.object,
    accordionPanelFrom: PropTypes.string,
    jobsOfferedForVacancies: PropTypes.array,
    jobsOffered: PropTypes.number
};

export default VacancyManagement;
