import React from 'react';
import PropTypes from 'prop-types';

const NotesPast = ({ notes }) => {
    return (
        <div className="notesFieldSet">
            <label>Notes from last Appointment</label>
            <div className="notesField">{notes}</div>
        </div>
    );
};

export default NotesPast;

NotesPast.propTypes = {
    notes: PropTypes.string
};
