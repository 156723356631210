import React from 'react';

import { countDays } from '../../../utils/dateFunctions';

import classes from './actionPlan.module.css';

export const calcDays = (arr, newEntry) => {
    const outcomeName = arr.find((el) => el.id === newEntry.outcomeId)?.name || '';
    let endDate = newEntry.actionDueDate;
    let res;
    if (
        (outcomeName.toLowerCase() === 'completed' ||
            outcomeName.toLowerCase() === 'partially achieved') &&
        newEntry.actionOutcomeDate
    ) {
        endDate = newEntry.actionOutcomeDate;
    }
    res = countDays(newEntry.actionStartDate, endDate);

    if (
        outcomeName.toLowerCase() === 'not completed' ||
        outcomeName.toLowerCase() === 'no longer applicable'
    ) {
        res = 'n/a';
    }
    return res < 0 ? <span className={classes.negative}>{res}</span> : res;
};
