import {
    getMustBeBeforeEndDateMessage,
    getSelectValueMessage
} from '../../utils/formValidation/errorMessageFunctions';

const MAX_NAME_CHAR = 100;
const MIN_NAME_CHAR = 1;
export const NAME_MIN = `Contract name must be at least ${MIN_NAME_CHAR} character`;
export const NAME_MAX = `Contract name must be ${MAX_NAME_CHAR} characters or less`;
export const NO_CONTRACT_TYPE_ID = 'Please enter a contract type';
export const NO_UNIQUE_ID = 'Select a unique id type';
const NO_VALUE_SELECTED = getSelectValueMessage();

export const initialErrorState = {
    name: { error: false, message: '' },
    contract_type: { error: false, message: '' },
    unique_identifier_type: { error: false, message: '' },
    startDate: { error: false, message: '' },
    endDate: { error: false, message: '' }
};

export const validate = (newEntry, errors, contractDetails) => {
    let isValid = true;
    let newErrors = errors;

    if (contractDetails.some((el) => el.contract.toLowerCase() === newEntry.name.toLowerCase())) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: `Contract ${newEntry.name} already exists.` }
        };
        isValid = false;
    } else if (!newEntry.name?.trim().length >= MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: NAME_MIN }
        };
        isValid = false;
    } else if (newEntry.name?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: NAME_MAX }
        };
        isValid = false;
    }
    if (!newEntry.contract_type) {
        newErrors = {
            ...newErrors,
            contract_type: { error: true, message: NO_CONTRACT_TYPE_ID }
        };
        isValid = false;
    }
    if (!newEntry.unique_identifier_type) {
        newErrors = {
            ...newErrors,
            unique_identifier_type: { error: true, message: NO_UNIQUE_ID }
        };
        isValid = false;
    }
    if (!newEntry.startDate) {
        newErrors = {
            ...newErrors,
            startDate: { error: true, message: NO_VALUE_SELECTED }
        };
        isValid = false;
    } else if (newEntry.endDate && newEntry.endDate <= newEntry.startDate) {
        newErrors = {
            ...newErrors,
            startDate: { error: true, message: getMustBeBeforeEndDateMessage() }
        };
        isValid = false;
    }
    if (!newEntry.endDate) {
        newErrors = {
            ...newErrors,
            endDate: { error: true, message: NO_VALUE_SELECTED }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};
