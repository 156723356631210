import { Box, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import { POLARIS_GREY, POLARIS_ROYAL_BLUE, POLARIS_WHITE } from '../../themes/theme';

// A switch based on MUI Switch and styled for Polaris.
//
// Usage:
//
//  <PolarisSwitch defaultChecked={true} label={'Selected   '} />
//
//  <PolarisSwitch defaultChecked={false} label={'Not Selected'} />
//
//  <PolarisSwitch disabled={true} label={'Disabled   '} />

export const PolarisSwitch = styled((props) => (
    <Box onClick={(e) => e.stopPropagation()}>
        {props.label}&nbsp;
        <Switch label={props.position || 'start'} {...props} />
    </Box>
))(() => ({
    width: 44,
    height: 24,
    padding: 2,
    borderRadius: 109,
    border: '2px solid ' + POLARIS_GREY,
    '& .MuiSwitch-thumb': {
        width: '16px',
        height: '16px'
    },
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        color: POLARIS_GREY, // default thumb color
        '&.Mui-checked .MuiSwitch-thumb': {
            transform: 'translateX(-1px)',
            color: POLARIS_ROYAL_BLUE // checked thumb color
        },
        '& + .MuiSwitch-track': {
            backgroundColor: POLARIS_WHITE, // default unchecked track color
            opacity: 1.0,
            border: 0
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: POLARIS_WHITE, // default checked track color
            opacity: 1.0,
            border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: POLARIS_GREY,
            opacity: 0.7
        }
    }
}));
