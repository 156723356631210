import React from 'react';
import PropTypes from 'prop-types';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Typography } from '@mui/material';

import classes from './sensitivityNotice.module.css';

const SensitivityNotice = ({ onClick, numRows = 3, customClass = '' }) => {
    return (
        <div
            className={`${classes.sensitivityNotice} ${numRows === 1 ? classes.oneRow : ''} ${
                numRows === 2 ? classes.twoRows : ''
            } ${customClass}`}>
            <VisibilityOffIcon />
            <Typography variant="h6">
                The hidden information is of a sensitive nature.
                <br />
                You must ensure that nobody else has the ability to see your screen before revealing
                it.
            </Typography>
            <div className={classes.reveal} onClick={onClick}>
                Click here to reveal
            </div>
        </div>
    );
};

export default SensitivityNotice;

SensitivityNotice.propTypes = {
    onClick: PropTypes.func.isRequired,
    numRows: PropTypes.number,
    customClass: PropTypes.string
};
