import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setErrorMessage } from '../../../store/formsState';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { updateParticipant } from '../../../store/participantService';
import { selectUserRoles } from '../../../store/userSelectors';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import * as validate from '../../../validation/validation';
import IconError from '../../IconError';
import FormActions from '../../ui/formActions/FormActions';

import './appointmentStyles.css';
import '../../scheduling/styles/appointmentSchedulerPanel.css';
const General = ({ onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE

    const acceptedRoles = [SUPERUSER, MANAGER, QUALITY, ADVISER];
    const initialErrorState = {
        address1: { error: false, message: 'The address1 field must be filled' },
        postcode: {
            error: false,
            message: 'The postcode field must be filled with the correct format'
        },
        primaryPhoneNumber: {
            error: false,
            message: 'The primary phone number must be in the correct format'
        },
        secondaryPhoneNumber: {
            error: false,
            message: 'The secondary phone number must be different to the primary phone number'
        },
        secondaryPhoneNumber2: {
            error: false,
            message: 'The secondary phone number must be in the correct format'
        },
        emailAddress: { error: false, message: 'Email address is not in the correct format' }
    };

    const initialParticipantState = {
        address1: '',
        address2: '',
        address3: '',
        city: '',
        postcode: '',
        emailAddress: '',
        primaryPhoneNumber: '',
        secondaryPhoneNumber: ''
    };

    const [errors, setErrors] = useState(initialErrorState);
    const [participantEntry, setParticipantEntry] = useState(initialParticipantState);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);

    // FNs

    const onValidateParticipantDetails = () => {
        let isValid = true;
        if (!participantEntry.address1) {
            dispatch(setErrorMessage(errors.address1.message));
            setErrors((prev) => ({
                ...prev,
                address1: { ...errors.address1, error: true }
            }));
            isValid = false;
        }
        if (!participantEntry.postcode?.match(validate.POSTCODE_REGEXP)) {
            dispatch(setErrorMessage(errors.postcode.message));
            setErrors((prev) => ({
                ...prev,
                postcode: { ...errors.postcode, error: true }
            }));
            isValid = false;
        }
        if (
            participantEntry.secondaryPhoneNumber &&
            participantEntry.primaryPhoneNumber === participantEntry.secondaryPhoneNumber
        ) {
            dispatch(setErrorMessage(errors.secondaryPhoneNumber.message));
            setErrors((prev) => ({
                ...prev,
                secondaryPhoneNumber: { ...errors.secondaryPhoneNumber, error: true }
            }));
            isValid = false;
        }
        if (!participantEntry.primaryPhoneNumber?.match(validate.PHONE_REGEXP)) {
            dispatch(setErrorMessage(errors.primaryPhoneNumber.message));
            setErrors((prev) => ({
                ...prev,
                primaryPhoneNumber: { ...errors.primaryPhoneNumber, error: true }
            }));
            isValid = false;
        }
        if (
            participantEntry.secondaryPhoneNumber &&
            !participantEntry.secondaryPhoneNumber?.match(validate.PHONE_REGEXP)
        ) {
            dispatch(setErrorMessage(errors.secondaryPhoneNumber2.message));
            setErrors((prev) => ({
                ...prev,
                secondaryPhoneNumber2: { ...errors.secondaryPhoneNumber2, error: true }
            }));
            isValid = false;
        }
        if (!participantEntry.emailAddress?.match(validate.EMAIL_REGEXP)) {
            dispatch(setErrorMessage(errors.emailAddress.message));
            setErrors((prev) => ({
                ...prev,
                emailAddress: { ...errors.emailAddress, error: true }
            }));
            isValid = false;
        }
        return isValid;
    };

    const resetFields = () => {
        setParticipantEntry(currentParticipant);
        setErrors(initialErrorState);
    };

    // USE EFFECTS

    useEffect(() => {
        currentParticipant.id && setParticipantEntry(currentParticipant);
    }, [currentParticipant]);

    // EVENT HANDLERS

    const onCancel = () => {
        resetFields();
        onClose('general');
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const isValid = onValidateParticipantDetails();
        if (!isValid) return;
        dispatch(updateParticipant(participantEntry));
        resetFields();
        onClose('general');
    };

    return Object.keys(currentParticipant).length < 1 ? null : (
        <div className="appointment-scheduler generalSection">
            <form onSubmit={onSubmit}>
                <div className="generalSectionSet">
                    <label>Address detail</label>
                    <div className="appointmentFormRow">
                        <input
                            id="address1"
                            type="text"
                            placeholder="Address 1"
                            className="textInput"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={participantEntry.address1 || ''}
                            onChange={(e) => {
                                errors.address1.error &&
                                    setErrors((prev) => ({
                                        ...prev,
                                        address1: {
                                            ...errors.address1,
                                            error: false
                                        }
                                    }));
                                setParticipantEntry((prev) => ({
                                    ...prev,
                                    address1: e.target.value
                                }));
                            }}
                        />
                        <input
                            id="address2"
                            type="text"
                            placeholder="Address 2"
                            className="textInput"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={participantEntry.address2 || ''}
                            onChange={(e) =>
                                setParticipantEntry((prev) => ({
                                    ...prev,
                                    address2: e.target.value
                                }))
                            }
                        />
                    </div>
                    <div className="appointmentFormRow">
                        <input
                            id="address3"
                            type="text"
                            placeholder="Address 3"
                            className="textInput"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={participantEntry.address3 || ''}
                            onChange={(e) =>
                                setParticipantEntry((prev) => ({
                                    ...prev,
                                    address3: e.target.value
                                }))
                            }
                        />
                        <input
                            id="city"
                            type="text"
                            placeholder="Town/City"
                            className="textInput"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={participantEntry.city || ''}
                            onChange={(e) =>
                                setParticipantEntry((prev) => ({ ...prev, city: e.target.value }))
                            }
                        />
                    </div>
                    <div className="appointmentFormRow">
                        <input
                            id="postcode"
                            type="text"
                            placeholder="Postcode"
                            className="textInput"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={participantEntry.postcode || ''}
                            onChange={(e) => {
                                errors.postcode.error &&
                                    setErrors((prev) => ({
                                        ...prev,
                                        postcode: {
                                            ...errors.postcode,
                                            error: false
                                        }
                                    }));
                                setParticipantEntry((prev) => ({
                                    ...prev,
                                    postcode: e.target.value
                                }));
                            }}
                        />
                    </div>
                    <div className="generalError">
                        {errors.address1.error && <IconError text={errors.address1} />}
                        {errors.postcode.error && <IconError text={errors.postcode} />}
                    </div>
                </div>

                <div className="generalSectionSet">
                    <div className="appointmentFormRow">
                        <div className="set">
                            <label>Email Address</label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Email address"
                                className="textInput"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={participantEntry.emailAddress || ''}
                                maxLength={50}
                                onChange={(e) => {
                                    errors.emailAddress.error &&
                                        setErrors((prev) => ({
                                            ...prev,
                                            emailAddress: {
                                                ...errors.emailAddress,
                                                error: false
                                            }
                                        }));
                                    setParticipantEntry((prev) => ({
                                        ...prev,
                                        emailAddress: e.target.value
                                    }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="generalError">
                        {errors.emailAddress.error && <IconError text={errors.emailAddress} />}
                    </div>
                </div>

                <div className="generalSectionSet">
                    <div className="appointmentFormRow">
                        <div className="set">
                            <label>Primary Number</label>
                            <input
                                id="primaryPhoneNumber"
                                type="tel"
                                placeholder="Primary phone number"
                                className="textInput"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={participantEntry.primaryPhoneNumber || ''}
                                maxLength={15}
                                onChange={(e) => {
                                    errors.primaryPhoneNumber.error &&
                                        setErrors((prev) => ({
                                            ...prev,
                                            primaryPhoneNumber: {
                                                ...errors.primaryPhoneNumber,
                                                error: false
                                            }
                                        }));
                                    setParticipantEntry((prev) => ({
                                        ...prev,
                                        primaryPhoneNumber: e.target.value
                                    }));
                                }}
                            />
                        </div>
                        <div className="set">
                            <label>Secondary Number</label>
                            <input
                                id="secondaryPhoneNumber"
                                type="tel"
                                placeholder="Secondary phone number"
                                className="textInput"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={participantEntry.secondaryPhoneNumber || ''}
                                onChange={(e) => {
                                    errors.secondaryPhoneNumber.error &&
                                        setErrors((prev) => ({
                                            ...prev,
                                            secondaryPhoneNumber: {
                                                ...errors.secondaryPhoneNumber,
                                                error: false
                                            }
                                        }));
                                    errors.secondaryPhoneNumber2.error &&
                                        setErrors((prev) => ({
                                            ...prev,
                                            secondaryPhoneNumber2: {
                                                ...errors.secondaryPhoneNumber2,
                                                error: false
                                            }
                                        }));
                                    setParticipantEntry((prev) => ({
                                        ...prev,
                                        secondaryPhoneNumber: e.target.value
                                    }));
                                }}
                                maxLength={15}
                            />
                        </div>
                    </div>
                    <div className="generalError">
                        {errors.primaryPhoneNumber.error && (
                            <IconError text={errors.primaryPhoneNumber} />
                        )}
                        {errors.secondaryPhoneNumber.error && (
                            <IconError text={errors.secondaryPhoneNumber} />
                        )}
                        {errors.secondaryPhoneNumber2.error && (
                            <IconError text={errors.secondaryPhoneNumber2} />
                        )}
                    </div>
                </div>
                <div className="generalFormActions">
                    <FormActions
                        onClose={onSubmit}
                        onCancel={onCancel}
                        btnText="Update"
                        disabled={!hasRole(acceptedRoles, roles)}
                    />
                </div>
            </form>
        </div>
    );
};

export default General;

General.propTypes = {
    onClose: PropTypes.func
};
