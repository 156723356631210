import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectContracts } from '../../../store/dataSelectors';
import {
    loadClaimEventStatuses,
    loadClaimEventTypes,
    loadClaimStatusUploaderTypes,
    loadContractDetailsForContractIds,
    loadUploaderTypes
} from '../../../store/directusService';

import ClaimStatusUploaderModal from './ClaimStatusUploaderModal';

const ClaimStatusUploader = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const [isClaimStatusUploaderModalOpen, setIsClaimStatusUploaderModalOpen] = useState(false);
    const [claimStatuses, setClaimStatuses] = useState([]);
    // STORE STATE
    const uploaders = useSelector((state) => state.entities.directusService.uploaderTypes);
    const claimStatusUploaderTypes = useSelector(
        (state) => state.entities.directusService.claimStatusUploaderTypes
    );
    const contracts = useSelector(selectContracts);
    const claimEventStatuses = useSelector(
        (state) => state.entities.directusService.claimEventStatuses
    );
    const claimEventTypes = useSelector((state) => state.entities.directusService.claimEventTypes);

    // USE EFFECTS
    useEffect(() => {
        if (uploaders?.length < 1) dispatch(loadUploaderTypes());
        if (claimStatusUploaderTypes?.length < 1) dispatch(loadClaimStatusUploaderTypes());
        if (claimEventStatuses?.length < 1) dispatch(loadClaimEventStatuses());
        if (claimEventTypes?.length < 1) dispatch(loadClaimEventTypes());
    }, []);

    useEffect(() => {
        if (uploaders?.length < 1) return;
        const claimStatusUploaderContractIds = uploaders
            .find((el) => el.name.toLowerCase() === 'claim status uploader')
            ?.contract?.map((el) => el.contract_id?.id);
        claimStatusUploaderContractIds?.length &&
            dispatch(loadContractDetailsForContractIds(claimStatusUploaderContractIds));
    }, [uploaders]);

    useEffect(() => {
        if (claimEventStatuses?.length < 1) return;
        const filtered = claimEventStatuses.filter(
            (el) => el.name.toLowerCase() === 'claimed' || el.name.toLowerCase() === 'verified'
        );
        setClaimStatuses(filtered);
    }, [claimEventStatuses]);

    useEffect(() => {
        setIsClaimStatusUploaderModalOpen(isOpen);
    }, [isOpen]);

    const onClaimStatusUploaderModalClose = (e, reason) => {
        if (reason && reason === 'backdropClick') return;
        setIsClaimStatusUploaderModalOpen(false);
        onClose();
    };

    const onCancel = () => {
        setIsClaimStatusUploaderModalOpen(false);
        onClose();
    };

    return (
        <ClaimStatusUploaderModal
            contracts={contracts}
            claimStatusUploaderTypes={claimStatusUploaderTypes}
            claimStatuses={claimStatuses}
            claimTypes={claimEventTypes}
            onClose={onClaimStatusUploaderModalClose}
            isOpen={isClaimStatusUploaderModalOpen}
            onCancel={onCancel}
        />
    );
};

export default ClaimStatusUploader;

ClaimStatusUploader.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
