import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Card, CardContent } from '@mui/material';

import { loadCourseTemplatesByContractIds } from '../../../store/courseService';
import { loadBarrierNameDetails } from '../../../store/directusService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { loadUsersByUserIds } from '../../../store/userService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { hasRole, LOCAL_ADMIN, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import FormHeader from '../../layout/FormHeader';
import DynamicSearch from '../../search/DynamicSearch';
import LoadingSpinner from '../../ui/LoadingSpinner';

import AllActiveCourseTemplateTable from './AllActiveCourseTemplateTable';

import classes from '../courseStyles/courseToolbar.module.css';

const AllActiveCourseTemplateManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [templates, setTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [rows, setRows] = useState([]);

    // STORE STATE
    const user = useSelector(selectLoggedInUser);
    const { courseTemplates } = useSelector((state) => state.entities.courseService);

    // USEEFFECTS
    useEffect(() => {
        if (!rows.length && user?.contractIds) {
            dispatch(loadCourseTemplatesByContractIds(user.contractIds));
        }
        dispatch(loadBarrierNameDetails());
    }, []);

    useEffect(() => {
        if (!courseTemplates?.length) return;
        const templates = courseTemplates.filter((el) => el.active);
        const userIds = [...new Set(courseTemplates.map((el) => el.userId))];
        userIds.length > 0 && dispatch(loadUsersByUserIds(userIds));
        setRows(templates);
        setTemplates(templates);
    }, [courseTemplates]);

    useEffect(() => {
        const rows = !searchTerm
            ? templates
            : templates.filter(
                  (el) =>
                      el.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      el.userId.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setRows(rows);
    }, [searchTerm]);

    // EVENT HANDLERS

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onCreateNewTemplate = () =>
        navigate('/create_course_template', { state: { accordionPanel: 'templates' } });

    // RENDER

    return (
        <Card data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'All Active Course Templates'}></FormHeader>
                    <div className={classes.coursesToolBar}>
                        <DynamicSearch
                            search={onSearch}
                            searchTerm={searchTerm}
                            placeholder="Enter Search Term"
                        />
                        <Button
                            disabled={!hasRole([QUALITY, SUPERUSER, LOCAL_ADMIN], user?.roles)}
                            color="primary"
                            variant="contained"
                            size={'small'}
                            onClick={onCreateNewTemplate}>
                            Add a new course template
                            <Box width={SCREEN_SETTINGS.gap.medium} />
                            <AddCircleOutlineIcon />
                        </Button>
                    </div>

                    {rows.length < 1 ? (
                        <LoadingSpinner content="No active course templates found" />
                    ) : (
                        <AllActiveCourseTemplateTable
                            rows={rows}
                            roles={user?.roles || []}
                            searchTerm={searchTerm}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default AllActiveCourseTemplateManagement;
