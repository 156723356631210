import { checkExists, getUserURL } from '../../../api/commonHTTP';
import { scrollToElement } from '../../../utils/scrollToElement';
import { READ_ONLY, SUPERUSER } from '../../../utils/userRoles';
import {
    EMAIL_REGEXP,
    FIRST_NAME_REGEXP,
    LAST_NAME_REGEXP,
    PHONE_REGEXP
} from '../../../validation/validation';

export const MIN_NAME_CHAR = 1;
export const MAX_NAME_CHAR = 100;
export const MIN_EMAIL_CHAR = 7;
export const MAX_EMAIL_CHAR = 50;
export const MIN_PHONE_CHAR = 11;
export const MAX_PHONE_CHAR = 15;
export const MAX_PAYROLL_CHAR = 100;
export const MAX_JOB_TITLE_CHAR = 100;

export const FIRST_NAME_MIN = `First name must be at least ${MIN_NAME_CHAR} character`;
export const FIRST_NAME_FORMAT = `First name must be in the correct format`;
export const LAST_NAME_MIN = `Last name must be at least ${MIN_NAME_CHAR} character`;

export const LAST_NAME_FORMAT = `Last name must be in the correct format`;
export const EMAIL_MIN = `Email address must be at least ${MIN_EMAIL_CHAR} characters`;
export const EMAIL_FORMAT = `Email must be in the correct format`;
export const EMAIL_EXISTS = 'A user with that email address already exists';
export const NO_PRIMARY_SERVICE = 'Please select a primary service';
export const NO_PRIMARY_TEAM = 'Please select a primary team';
export const PHONE_MIN = `Phone number must be at least ${MIN_PHONE_CHAR} characters`;
export const PHONE_FORMAT = `Phone number must be in the correct format`;

export const NO_USER_TYPE_IDS = 'Please select at least one user type';

export const initialErrorState = {};

export const onValidate = (newEntry, userTypes) => {
    let newErrors = {};

    if (newEntry.firstName.length < MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: FIRST_NAME_MIN }
        };
    } else if (!FIRST_NAME_REGEXP.test(newEntry.firstName)) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: FIRST_NAME_FORMAT }
        };
    }

    if (newEntry.lastName.length < MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: LAST_NAME_MIN }
        };
    } else if (!LAST_NAME_REGEXP.test(newEntry.lastName)) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: LAST_NAME_FORMAT }
        };
    }

    if (newEntry.phoneNumber) {
        if (newEntry.phoneNumber?.trim().length < MIN_PHONE_CHAR) {
            newErrors = {
                ...newErrors,
                phoneNumber: { error: true, message: PHONE_MIN }
            };
        } else if (!PHONE_REGEXP.test(newEntry.phoneNumber)) {
            newErrors = {
                ...newErrors,
                phoneNumber: { error: true, message: PHONE_FORMAT }
            };
        }
    }

    if (!newEntry.emailAddress || newEntry.emailAddress?.trim().length < MIN_EMAIL_CHAR) {
        newErrors = {
            ...newErrors,
            emailAddress: { error: true, message: EMAIL_MIN }
        };
    } else if (!EMAIL_REGEXP.test(newEntry.emailAddress)) {
        newErrors = {
            ...newErrors,
            emailAddress: { error: true, message: EMAIL_FORMAT }
        };
    }

    if (!newEntry.primaryServiceId) {
        newErrors = {
            ...newErrors,
            primaryServiceId: { error: true, message: NO_PRIMARY_SERVICE }
        };
    }
    if (!newEntry.primaryTeamId) {
        newErrors = {
            ...newErrors,
            primaryTeamId: { error: true, message: NO_PRIMARY_TEAM }
        };
    }

    if (newEntry.userTypeIds.length < 1) {
        newErrors = {
            ...newErrors,
            userTypeIds: {
                error: true,
                message: NO_USER_TYPE_IDS
            }
        };
    }

    if (newEntry.userTypeIds?.length > 1) {
        const superUserId = userTypes.find((entry) => entry.role === SUPERUSER)?.id;
        const readOnlyId = userTypes.find((entry) => entry.role === READ_ONLY)?.id;

        if (newEntry.userTypeIds.includes(superUserId)) {
            newErrors = {
                ...newErrors,
                userTypeIds: {
                    error: true,
                    message: `SuperUser cannot be mixed with other types`
                }
            };
        }
        if (newEntry.userTypeIds.includes(readOnlyId)) {
            newErrors = {
                ...newErrors,
                userTypeIds: {
                    error: true,
                    message: `Read Only user cannot be mixed with other types`
                }
            };
        }
    }

    scrollToElement(newErrors);
    return { newErrors };
};

export const checkEmailAddress = async (emailAddress) => {
    return await checkExists(getUserURL() + `rest/user/exists/email-address/${emailAddress}`).then(
        (ret) => ret
    );
};
