import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import { selectAttendedStatusId } from '../../../store/dataSelectors';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';

import CurrentCourseDetails from './CurrentCourseDetails';

import classes from '../../../commonStyles/tables.module.css';
const CurrentCourseTableRow = ({ row, toggleDropdown, openId }) => {
    const [open, setOpen] = useState(null);
    const [numberAttendedModules, setNumberAttendedModules] = useState(0);

    const currentParticipant = useSelector(selectCurrentParticipant);
    const attendedStatusId = useSelector(selectAttendedStatusId);

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    useEffect(() => {
        const { modules } = row;
        const participantsModules = modules.filter((el) =>
            el.participants.find(
                (entry) =>
                    entry.participantId === currentParticipant.id &&
                    entry.statusId === attendedStatusId
            )
        );
        setNumberAttendedModules(participantsModules.length);
    }, [row]);

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    return (
        <>
            <TableRow
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{reverseFormatDate(row.startDate)}</TableCell>
                <TableCell>{reverseFormatDate(row.endDate)}</TableCell>
                <TableCell style={{ fontWeight: 'bold', paddingLeft: '32px' }}>
                    {`${numberAttendedModules}/${row.modules?.length}`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={5}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <CurrentCourseDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default CurrentCourseTableRow;

CurrentCourseTableRow.propTypes = {
    row: PropTypes.object,
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
