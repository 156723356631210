import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

import { selectUsers } from '../../../../../store/userSelectors';
import { loadUser } from '../../../../../store/userService';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';

import form from '../../../../../commonStyles/formStyles.module.css';
import dropdownClasses from '../../../../courses/courseStyles/rowDropDown.module.css';

const InWorkSupportDetails = ({ row, onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const users = useSelector(selectUsers);
    const { successMessage } = useSelector((state) => state.entities.formsState);

    // USE EFFECTS
    useEffect(() => {
        if (row.contactedById) {
            if (users && users.length) {
                const user = users.find((el) => el.id === row.contactedById);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(row.contactedById));
            } else dispatch(loadUser(row.contactedById));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === row.contactedById);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    // RENDER
    return (
        <div className={dropdownClasses.rowDropdown}>
            <form>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Contacted By'}
                            id={'ownerId'}
                            disabled={true}
                            value={emailAddress}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Notes'}
                            id={'notes'}
                            disabled={true}
                            multiline
                            rows={5}
                            value={row.note || ''}
                        />
                    </div>
                </div>
                <div className={dropdownClasses.exit} onClick={onClose}>
                    Close details
                    <CloseIcon />
                </div>
            </form>
        </div>
    );
};

InWorkSupportDetails.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default InWorkSupportDetails;
