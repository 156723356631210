export const themeJson = {
    cssVariables: {
        //238 250 252
        //30 30 240
        '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
        '--sjs-general-backcolor-dark': 'rgba(238, 250, 252, 1)',
        '--sjs-general-backcolor-dim': 'rgba(238, 250, 252, 1)',
        '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
        '--sjs-general-backcolor-dim-dark': 'rgba(238, 250, 252, 1)',
        '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
        '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.91)',
        '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
        '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.91)',
        '--sjs-primary-backcolor': 'rgb(8,19,81,100)',
        '--sjs-primary-backcolor-light': 'rgba(217, 74, 100, 0.1)',
        '--sjs-primary-backcolor-dark': 'rgba(30, 30, 240, 1)',
        '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-primary-forecolor-light': 'rgba(238, 250, 252, 1)',
        '--sjs-base-unit': '8px',
        '--sjs-corner-radius': '4px',
        '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
        '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
        '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
        '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-secondary-forecolor-light': 'rgba(238, 250, 252, 1)',
        '--sjs-shadow-small':
            '0px 0px 0px 2px rgba(228, 228, 228, 1),0px 2px 0px 2px rgba(228, 228, 228, 1)',
        '--sjs-shadow-medium':
            '0px 8px 0px 2px rgba(228, 228, 228, 1),0px 0px 0px 2px rgba(228, 228, 228, 1)',
        '--sjs-shadow-large': '0px 0px 0px 0px rgba(0, 0, 0, 0.1)',
        '--sjs-shadow-inner':
            '0px -2px 0px 2px rgba(228, 228, 228, 1),0px 0px 0px 2px rgba(228, 228, 228, 1)',
        '--sjs-border-light': 'rgba(0, 0, 0, 0.08)',
        '--sjs-border-default': 'rgba(0, 0, 0, 0.08)',
        '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
        '--sjs-special-red': 'rgba(229, 10, 62, 1)',
        '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
        '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-special-green': 'rgba(25, 179, 148, 1)',
        '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
        '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
        '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
        '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
        '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
        '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)'
    },
    /* https://surveyjs.io/form-library/documentation/manage-default-themes-and-styles */
    isPanelless: false
};
