import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { updateParticipantDocument } from '../../store/documentService';
import { selectCurrentParticipant } from '../../store/participantSelectors';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';

import classes from './documents.module.css';

const EditDocument = ({ row, onClose }) => {
    const dispatch = useDispatch();

    const [documentType, setDocumentType] = useState({});

    const documentTypes = useSelector(
        (state) => state.entities.directusService.documentTypeIdsAndNamesForContract
    );
    const currentParticipant = useSelector(selectCurrentParticipant);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const findType = (id) => documentTypes.find((el) => el.id === id);

    // USE EFFECTS
    useEffect(() => {
        if (!row || !documentTypes?.length) return;
        const type = findType(row.typeId);
        setDocumentType(type);
    }, [row]);

    useEffect(() => {
        if (successMessage === `${row.filename} has been updated`) onFormExit();
    }, [successMessage]);

    // EVENT HANDLERS
    const onChange = (id) => {
        const type = findType(id);
        setDocumentType(type);
    };

    const onFormExit = () => {
        setDocumentType('');
        onClose();
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const payload = { typeId: documentType.id };
        dispatch(updateParticipantDocument(row.filename, currentParticipant.id, payload));
        onFormExit();
    };

    return !documentTypes?.length ? (
        <LoadingSpinner content="No documentTypes available" />
    ) : (
        <div className={classes.documentForm}>
            <div className={classes.heading}>
                <h3>File name</h3>
                <div>{row.filename}</div>
            </div>
            <form onSubmit={onSubmit}>
                <div className={classes.optionInput}>
                    <DDLOptionPicker
                        label={'Select Document Type'}
                        id={'DocumentType'}
                        chosenName={documentType?.name || ''}
                        menuItems={documentTypes || []}
                        chosenId={documentType?.id || ''}
                        onChange={(id) => onChange(id)}
                    />
                </div>

                <div className={classes.formActions}>
                    <Button type="submit" color="primary" variant="contained">
                        Update
                    </Button>
                    <div className={classes.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditDocument;

EditDocument.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func
};
