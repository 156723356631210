import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { loadAllExternalCourseProviderDetails } from '../../../store/directusService';
import { POLARIS_WHITE } from '../../../themes/theme';
import FormHeader from '../../layout/FormHeader';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CurrentProvisionTable from './CurrentProvisionTable';

const CurrentProvisionManagement = ({ courseProvisions }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [filteredCourseProvisions, setFilteredCourseProvisions] = useState([]);

    // STORE STATE
    const { externalCourseProviderDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentParticipant } = useSelector((state) => state.entities.participantService);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAllExternalCourseProviderDetails());
    }, []);

    useEffect(() => {
        if (courseProvisions.length < 1 || !externalCourseProviderDetails.length < 1) return;
        const filteredCourseProvisions = courseProvisions
            .filter((el) =>
                el.participants.some((pt) => pt.participantId === currentParticipant.id)
            )
            .map((el) => ({
                ...el,
                provisionName: el.title,
                providerName:
                    externalCourseProviderDetails.find((entry) => entry.id === el.nameId)?.name ||
                    '',
                statusId:
                    el.participants.find((entry) => entry.participantId === currentParticipant.id)
                        ?.statusId || ''
            }));
        setFilteredCourseProvisions(filteredCourseProvisions);
    }, [courseProvisions, externalCourseProviderDetails]);

    // RENDER
    return (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Current Provisions'}></FormHeader>

                    {filteredCourseProvisions.length < 1 ? (
                        <LoadingSpinner content="No current provisions found" />
                    ) : (
                        <CurrentProvisionTable rows={filteredCourseProvisions} />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

CurrentProvisionManagement.propTypes = {
    courseProvisions: PropTypes.array
};

export default CurrentProvisionManagement;
