import React, { useState } from 'react';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';

import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, CardHeader, Icon, Stack, Typography } from '@mui/material';

import {
    POLARIS_DARKER_GREY,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import ConfirmPrompt from '../../notices/confirmPrompt/ConfirmPrompt';

import WorkHistoryEditor from './WorkHistoryEditor';

import classes from '../cardStyles/cardView.module.css';

/**
 * Viewer for work history.
 *
 * Uses WorkHistoryEditor when edit icon is clicked
 *
 * Calls the sendDelete callback when delete icon is clicked and this causes
 * CardHandler to delete this item.
 *
 * @param cardData Data for this viewer. This has values which match the
 * job-goals table in the participant service
 * @param cardsLength - to check the number of cards
 * @param sendDelete  Callback function to tell parent  to delete cardData.
 * @param sendUpdate  Callback function to tell parent to update cardData.
 * @param disabled  If true, disables control.
 * @param employmentTypeDetails  employment type information
 * @param reasonForLeavingDetails  reason for leaving information
 * @returns {JSX.Element}
 *
 */

const WorkHistoryViewer = ({
    cardData,
    cardsLength,
    sendDelete,
    sendUpdate,
    disabled = false,
    employmentTypeDetails,
    reasonForLeavingDetails
}) => {
    const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);
    let displayStartDate = '';
    cardData?.startDate
        ? (displayStartDate = parse(
              cardData?.startDate?.substring(0, 10),
              'yyyy-MM-dd',
              new Date()
          ))
        : (displayStartDate = '');
    let displayEndDate;
    cardData?.endDate
        ? (displayEndDate = parse(cardData?.endDate?.substring(0, 10), 'yyyy-MM-dd', new Date()))
        : (displayEndDate = '');

    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;
    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_ROYAL_BLUE;

    const confirmDelete = (_, doDelete) => {
        if (!doDelete) {
            setShowConfirmPrompt(false);
            return;
        }
        sendDelete(cardData);
    };

    /*
     * This is the callback when there is a change in the editor.
     */
    const handleChange = (data) => {
        sendUpdate(data);
    };

    /**
     * Get a summary of card information.
     *
     * @returns {JSX.Element}
     */
    const getCardContent = () => {
        // Return incomplete if no name or type
        if (!cardData.companyName || !cardData.employmentTypeId) {
            return (
                <Typography variant="body1" sx={{ color: POLARIS_DOUBLE_DECKER_RED }}>
                    Incomplete Work History Details
                </Typography>
            );
        }

        return (
            <>
                <Typography variant="body1">
                    <strong>{cardData?.jobTitle || ''}</strong>
                </Typography>
                <Typography variant="body1" data-testid="past_education_viewer_date">
                    {cardData?.startDate?.substring(0, 8)
                        ? format(displayStartDate, 'MMM yyyy')
                        : ''}
                    {cardData?.endDate?.substring(0, 8)
                        ? '- ' + format(displayEndDate, 'MMM yyyy')
                        : ''}{' '}
                </Typography>
                <br />
                <Typography variant="body1" data-testid="past_education_viewer_name">
                    <strong>
                        {getNameFromId(employmentTypeDetails, cardData.employmentTypeId)}
                    </strong>
                </Typography>
                <Typography variant="body1" data-testid="past_education_viewer_name">
                    <strong>
                        {getNameFromId(reasonForLeavingDetails, cardData.reasonForLeavingId)}
                    </strong>
                </Typography>
                <Typography variant="body1">
                    <span className={classes.description}>
                        {cardData.description?.length > 200
                            ? cardData.description.substring(0, 197) + '...'
                            : cardData?.description}
                    </span>
                </Typography>
                <br />
                <br />
                <br />
            </>
        );
    };

    return (
        <>
            {showConfirmPrompt && (
                <ConfirmPrompt
                    isOpen={showConfirmPrompt}
                    onCancel={(e) => confirmDelete(e, false)}
                    onConfirm={(e) => confirmDelete(e, true)}
                    content="Are you sure you want to delete this work history?"
                />
            )}
            <div className={classes.cardView}>
                <Card
                    elevation={3}
                    sx={{
                        p: 0,
                        backgroundColor: POLARIS_WHITE,
                        width: 250,
                        height: 'calc(100% - 16px)',
                        borderRadius: SCREEN_SETTINGS.radius.small
                    }}>
                    <CardHeader
                        title={
                            <div
                                className={`${
                                    cardData.companyName?.length > 60
                                        ? classes.bannerDoubleRow
                                        : classes.banner
                                }`}>
                                <Typography variant="h6">
                                    {cardData.companyName || 'Work History'}
                                </Typography>
                            </div>
                        }
                        sx={{
                            p: 0.8,
                            m: 0.8,
                            // background color is gray if disabled
                            backgroundColor: { backgroundColor },
                            color: POLARIS_WHITE
                        }}
                        action={
                            <Stack direction="row">
                                <WorkHistoryEditor
                                    employmentTypeDetails={employmentTypeDetails}
                                    reasonForLeavingDetails={reasonForLeavingDetails}
                                    disabled={disabled}
                                    sendChange={handleChange}
                                    cardData={cardData}></WorkHistoryEditor>{' '}
                                <Icon
                                    data-testid="work_history_viewer_delete_icon"
                                    component={DeleteIcon}
                                    disabled={disabled || cardsLength < 2}
                                    sx={{
                                        color: iconColor,
                                        cursor: `${
                                            disabled || cardsLength < 2 ? 'not-allowed' : 'pointer'
                                        }`
                                    }}
                                    onClick={() => {
                                        if (disabled === false && cardsLength > 1)
                                            setShowConfirmPrompt(true);
                                    }}
                                />
                            </Stack>
                        }></CardHeader>

                    <CardContent sx={{ padding: 1 }}>{getCardContent()}</CardContent>
                </Card>
            </div>
        </>
    );
};

WorkHistoryViewer.propTypes = {
    cardData: PropTypes.object.isRequired,
    cardsLength: PropTypes.number,
    disabled: PropTypes.bool,
    sendDelete: PropTypes.func.isRequired,
    sendUpdate: PropTypes.func.isRequired,
    employmentTypeDetails: PropTypes.array.isRequired,
    reasonForLeavingDetails: PropTypes.array.isRequired
};

export default WorkHistoryViewer;
