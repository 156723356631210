import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';

import classes from '../../../../../commonStyles/tables.module.css';

const ReferralHistoryRow = ({ row }) => {
    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{reverseFormatDate(row.date)}</TableCell>
                <TableCell>{row.userEmail}</TableCell>
                <TableCell align="center">
                    {row.bloodTestDate === '' ? '-' : reverseFormatDate(row.bloodTestDate)}
                </TableCell>
                <TableCell align="center">
                    {row.bloodTestValue === '' ? '-' : row.bloodTestValue}
                </TableCell>
                <TableCell align="center"> {row.weight === 'z' ? '-' : row.weight} </TableCell>
                <TableCell> {row.result} </TableCell>
            </TableRow>
        </>
    );
};

export default ReferralHistoryRow;

ReferralHistoryRow.propTypes = {
    row: PropTypes.object
};
