import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

import { loadCallOutcomes } from '../../../store/directusService';
import { searchIWSRecords, searchParticipants } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../store/userService';
import { addFullNameToArray } from '../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import MultiSelect from '../../formElements/MultiSelect';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CallsDueTable from './CallsDueTable';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from '../homeStyles/calls.module.css';

const PT_LOAD_SIZE = 50;
const CallsDueManagement = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const acceptedUserTypes = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const [adviserIds, setAdviserIds] = useState([]);
    const [preSelectedAdviserIds, setPreSelectedAdviserIds] = useState([]);
    const [preSelectedAdvisers, setPreSelectedAdvisers] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [participantIds, setParticipantIds] = useState([]);
    const [rows, setRows] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const {
        usersByLoggedInUserServiceIds: users,
        usersByLoggedInUserServiceIdsMetaData: userMetaData
    } = useSelector((state) => state.entities.userService);
    const callOutcomes = useSelector((state) => state.entities.directusService.callOutcomes);
    const iwsRecords = useSelector((state) => state.entities.participantService.iwsRecordSearch);
    const { participantsSearch, participantsSearchMetaData } = useSelector(
        (state) => state.entities.participantService
    );

    // HELPER FNS

    const searchRecords = (userIds) => {
        if (adviserIds?.length < 1) {
            setRows([]);
            return;
        }
        dispatch(
            searchIWSRecords({
                iwsEventOwnerIds: userIds,
                dueDate: new Date().toISOString().slice(0, 10),
                callOutcomeId: callOutcomes.find((el) => el.name === 'Due')?.id || ''
            })
        );
    };

    const configResults = () => {
        const updatedRecords = iwsRecords.map((el) => {
            const pt = participants.find((entry) => entry.id === el.participantId);
            return {
                ...el,
                ptCode: pt?.ptCode || '',
                firstName: pt?.firstName || '',
                lastName: pt?.lastName || '',
                adviser: advisers.find((entry) => entry.id === el.iwsEventOwnerId)?.name || ''
            };
        });
        setRows(updatedRecords);
    };

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        callOutcomes?.length < 1 && dispatch(loadCallOutcomes());
        if (users?.length > 0) return;
        dispatch(searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds));
    }, []);

    useEffect(() => {
        if (loggedInUser.roles.some((el) => acceptedUserTypes.includes(el))) {
            if (!preSelectedAdviserIds.includes(loggedInUser.id)) {
                setPreSelectedAdviserIds((prev) => [...prev, loggedInUser.id]);
            }
            if (!preSelectedAdvisers.find((el) => el.id === loggedInUser.id)) {
                setPreSelectedAdvisers((prev) => addFullNameToArray([...prev, loggedInUser]));
                setAdvisers(addFullNameToArray([loggedInUser]));
            }
        }
    }, [loggedInUser.id, callOutcomes]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter((el) =>
            el.userTypes?.find((entry) => acceptedUserTypes.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < userMetaData.totalElements) {
            onLoadMoreAdvisers();
            return;
        }
        if (adviserIds.length && !updatedAdvisers.some((el) => adviserIds.includes(el.id))) {
            // Put selected advisers at the top of dropdown if all of them are not in the updated advisers array
            const selectedAdvisers = advisers.filter((el) => adviserIds.includes(el.id));
            const remainingAdvisers = updatedAdvisers.filter((el) => !adviserIds.includes(el.id));
            updatedAdvisers = [...selectedAdvisers, ...remainingAdvisers];
        }
        setAdvisers(addFullNameToArray(updatedAdvisers));
    }, [users]);

    useEffect(() => {
        if (iwsRecords?.length < 1) {
            setRows([]);
            return;
        }
        const ids = [...new Set(iwsRecords.map((el) => el.participantId))];
        setParticipantIds(ids);
        ids.length > 0 && dispatch(searchParticipants({ ids }, 0, PT_LOAD_SIZE));
    }, [iwsRecords]);

    useEffect(() => {
        if (participantsSearch?.length < 1) configResults();
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { ids: participantIds },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsSearch);
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (participants?.length < 1 || iwsRecords?.length < 1 || !participantsSearchMetaData.last)
            return;
        configResults();
    }, [participants]);

    useEffect(() => {
        searchRecords(adviserIds);
    }, [adviserIds]);

    // HELPER FNS
    const onLoadMoreAdvisers = () => {
        if (userMetaData.last) return;
        dispatch(
            searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds, userMetaData.number + 1)
        );
    };

    return (
        <div className={app.accordionContainer}>
            <div className={local.topBar}>
                <div className={local.iconContainer}>
                    <HeadsetMicIcon />
                </div>

                <div className={form.selectWidth}>
                    <MultiSelect
                        id="adviserIds"
                        label="Advisers"
                        placeholder="Advisers"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        menuItems={advisers || []}
                        preSelectedIds={preSelectedAdviserIds}
                        preSelects={preSelectedAdvisers}
                        onChange={(chosenIds) => setAdviserIds(chosenIds)}
                        onLoadMoreItems={onLoadMoreAdvisers}
                        moreItemsToLoad={
                            !userMetaData.totalElements || users.length < userMetaData.totalElements
                        }
                    />
                </div>
            </div>

            <div>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No calls found" />
                ) : (
                    <CallsDueTable rows={rows} roles={loggedInUser.roles} />
                )}
            </div>
        </div>
    );
};

export default CallsDueManagement;
