import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadIWSRecordsByPlacementId } from '../../../../../store/participantService';
import { selectLoggedInUser } from '../../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../../themes/theme';
import { stableSort } from '../../../../../utils/sortFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import ResultsTable from '../../../../table/ResultsTable';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import AddCallEditor from './AddCallEditor';
import CreateInWorkSupportCall from './CreateInWorkSupportCall';
import InWorkSupportTableRow from './InWorkSupportTableRow';

const headCells = [
    { id: 'dueDate', label: 'Due Date', sortable: true },
    { id: 'frequencyWeekNo', label: 'Frequency', sortable: true },
    { id: 'callDate', label: 'Call Date/Time', sortable: true },
    { id: 'workConfidenceName', label: 'Work Confidence', sortable: true },
    { id: 'outcomeName', label: 'Outcome', sortable: true }
];

const InWorkSupportTableManagement = ({
    businessName,
    currentCall,
    iwsRecordsForPlacement,
    placementId
}) => {
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    const [openId, setOpenId] = useState('');
    const [addCallEditor, setAddCallEditor] = useState(false);
    const [addCallRow, setAddCallRow] = useState({});

    const [callAddedTodayAlready, setCallAddedTodayAlready] = useState(false);
    const dateFormat = 'yyyy-MM-dd';
    const today = new Date();
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'frequencyWeekNo',
        page: 0,
        rowsPerPage: 100
    });

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { iwsRecordsByPlacementId, loadingIwsRecordsByPlacementId } = useSelector(
        (state) => state.entities.participantService
    );
    const iwsRecordsMetaData = iwsRecordsByPlacementId[placementId]?.metaData;

    // EVENT HANDLERS
    const onCreateInWorkSupportCall = () => {
        setFormType('create');
        setShowForm(true);
    };

    // USE EFFECTS
    useEffect(() => {
        const callToday = iwsRecordsForPlacement.find(
            (entry) => format(today, dateFormat) === entry.dueDate.substring(0, 10)
        );
        if (callToday) setCallAddedTodayAlready(true);
    }, [iwsRecordsForPlacement]);

    // CALLBACKS
    const onAddCallEditor = (setOpen) => {
        setAddCallEditor(setOpen);
    };

    const onAddCallRow = (row) => {
        setAddCallRow(row);
    };

    const onToggleDropdown = (id) => setOpenId(id);

    const createRows = () => {
        return stableSort(iwsRecordsForPlacement, rowMetaData.orderBy, rowMetaData.order).map(
            (el) => (
                <InWorkSupportTableRow
                    key={el.id}
                    row={el}
                    roles={loggedInUser.roles}
                    toggleDropdown={onToggleDropdown}
                    openId={openId}
                    onAddCallEditor={onAddCallEditor}
                    onAddCallRow={onAddCallRow}
                />
            )
        );
    };

    // RENDER
    return (
        <>
            <AddCallEditor
                open={addCallEditor}
                roles={loggedInUser.roles}
                onAddCallEditor={onAddCallEditor}
                addCallRow={addCallRow}
                businessName={businessName}
            />
            {iwsRecordsForPlacement.length < 1 ? (
                <LoadingSpinner content="No IWS records found." />
            ) : (
                <ResultsTable
                    defaultOrderBy={'frequencyWeekNo'}
                    loadPageSize={50}
                    defaultRowsPerPage={100}
                    headCells={headCells}
                    loadingValues={loadingIwsRecordsByPlacementId}
                    loadResults={loadIWSRecordsByPlacementId}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={placementId}
                    tableRows={createRows()}
                    totalResults={iwsRecordsMetaData.totalElements}
                    metaData={iwsRecordsMetaData}
                />
            )}
            <div>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, loggedInUser.roles) ||
                            !currentCall.frequencyWeekNo ||
                            callAddedTodayAlready
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreateInWorkSupportCall}>
                        Add In Work Support Call
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                {showForm && formType === 'create' && (
                    <CreateInWorkSupportCall
                        businessName={businessName}
                        currentCall={currentCall}
                        onClose={() => setShowForm(false)}
                        roles={loggedInUser.roles}
                    />
                )}
            </div>
        </>
    );
};
InWorkSupportTableManagement.propTypes = {
    businessName: PropTypes.string,
    currentCall: PropTypes.object,
    iwsRecordsForPlacement: PropTypes.array,
    placementId: PropTypes.string
};

export default InWorkSupportTableManagement;
