import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { checkExists, getCommunicationURL } from '../../../../api/commonHTTP';
import { createGroupCommunication } from '../../../../store/communicationService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';

import { ERROR_EXISTS, initialErrorState, validateName } from './validateGroupCommunications';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateCommunicationGroup = ({ onClose, roles }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        type: 'Email',
        name: '',
        participants: []
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [typeSwitch, setTypeSwitch] = useState(false);
    const [errors, setErrors] = useState(initialErrorState);
    const msg = `${newEntry.name} has been created`;

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    // USE EFFECTS

    useEffect(() => {
        setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSwitchType = (e) => {
        const { checked } = e.target;
        setTypeSwitch(checked);
        const type = checked ? 'SMS' : 'Email';
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validateName(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        if (
            await checkExists(
                getCommunicationURL() + `rest/group-communication/exists/${newEntry.name}`
            )
        ) {
            setErrors((errors) => ({
                ...errors,
                name: { error: true, message: ERROR_EXISTS }
            }));
            return;
        }
        dispatch(createGroupCommunication(newEntry, msg));

        onFormExit();
        navigate('/edit_communication_groups');
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3> Add New Group </h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label="Group Name"
                            id="groupName"
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.name}
                            placeholder="Enter value"
                            onChange={(e) =>
                                setNewEntry((prev) => ({ ...prev, name: e.target.value }))
                            }
                        />
                        {errors.name.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.name} />
                            </div>
                        )}

                        <div>
                            <PolarisSwitchSet
                                id="groupContactType"
                                label="Group Contact Type"
                                leftLabel="Email"
                                rightLabel="SMS"
                                checked={typeSwitch}
                                onSwitch={onSwitchType}
                                disabled={!hasRole(acceptedRoles, roles)}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}></div>
                </div>

                <FormActions onClose={onSubmit} onCancel={onFormExit} btnText="Save" />
            </form>
        </div>
    );
};

export default CreateCommunicationGroup;

CreateCommunicationGroup.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};
