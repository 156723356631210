import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BusyIndicator from './BusyIndicator';

import classes from './loading.module.css';

const LoadingSpinner = ({ content = '', time = 2 }) => {
    const [notFoundContent, setNotFoundContent] = useState(<BusyIndicator />);

    if (content) setTimeout(() => setNotFoundContent(content), time * 1000);

    return <div className={classes.loadingContent}>{notFoundContent}</div>;
};

export default LoadingSpinner;

LoadingSpinner.propTypes = {
    content: PropTypes.string,
    time: PropTypes.number
};
