import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const FormHeader = (props) => {
    return (
        <div>
            <Typography variant="h5" sx={{ paddingBottom: '20px' }}>
                {props.text}
            </Typography>
        </div>
    );
};

FormHeader.propTypes = {
    text: PropTypes.string
};

export default FormHeader;
