import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import OnFileDownloadIcon from '../../../../icons/OnFileDownloadIcon';
import {
    loadVacancyRejectionReasonsDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import { downloadParticipantDocument } from '../../../../store/documentService';
import { loadParticipantByPtCode } from '../../../../store/participantService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import { hasRole } from '../../../../utils/userRoles';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import classes from '../../../../commonStyles/tables.module.css';
import submissionClasses from '../../recruitmentStyles/submission.module.css';

const SubmissionsTableRow = ({
    row,
    roles,
    onUpdateParticipantStatus,
    onSubmissionsRejectEditor,
    onSubmissionsRejectedRow,
    rowCancelled
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [vacancySubmissionStatus, setVacancySubmissionStatus] = useState({});

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { vacancyRejectionReasonsDetails, vacancyStageDetails } = useSelector(
        (state) => state.entities.directusService
    );

    // EVENT HANDLERS
    function onEditParticipant() {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            navigate('/edit_participant');
        }
    }

    const onDownload = () => dispatch(downloadParticipantDocument(row.participantId, row.filename));

    // HELPER FNS
    const onStatusIdChange = (id) => {
        const selected = row.stageIds.find((el) => el.id === id);

        if (vacancySubmissionStatus?.id && vacancySubmissionStatus.id !== id) {
            onUpdateParticipantStatus(id, row.participantId, row.goldStandardCvId);
            if (
                selected.name.toLowerCase() === 'rejected' &&
                row.statusName.toLowerCase() !== 'rejected'
            ) {
                onSubmissionsRejectedRow(row);
                onSubmissionsRejectEditor(true);
            }
        }
        selected && setVacancySubmissionStatus(selected);
    };

    // USE EFFECTS
    useEffect(() => {
        setVacancySubmissionStatus({});
        if (vacancyRejectionReasonsDetails?.length < 1)
            dispatch(loadVacancyRejectionReasonsDetails());
        if (vacancyStageDetails?.length < 1) dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!vacancyStageDetails?.length) return;
        const vacancySubmissionStatus = vacancyStageDetails.find((el) => el.id === row.statusId);
        setVacancySubmissionStatus(vacancySubmissionStatus);
    }, [vacancyStageDetails]);

    useEffect(() => {
        rowCancelled &&
            setVacancySubmissionStatus(vacancyStageDetails.find((el) => el.id === row.statusId));
    }, [rowCancelled]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}>
                <TableCell
                    className={submissionClasses.ptCodeLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(roles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell>{row.ptName}</TableCell>
                <TableCell>{row.filename}</TableCell>
                <TableCell align="center">
                    <OnFileDownloadIcon
                        roles={roles}
                        acceptedRoles={roles}
                        active={
                            hasRole(roles, loggedInUser.roles) &&
                            row?.filename !== '' &&
                            row?.filename !== undefined
                        }
                        onDownload={onDownload}
                        participantId={row?.participantId}
                        filename={row?.filename}
                    />
                </TableCell>
                <TableCell sx={{ padding: '0', paddingRight: '5px' }}>
                    <div className={submissionClasses.rowOptionPicker}>
                        <DDLOptionPicker
                            key={0}
                            id={'jobStatus'}
                            disabled={
                                !hasRole(roles, loggedInUser.roles) ||
                                row?.statusId === '' ||
                                row?.statusId === undefined ||
                                row?.statusName?.toLowerCase() === 'rejected' ||
                                row?.statusName?.toLowerCase() === 'job offered'
                            }
                            menuItems={row?.stageIds || []}
                            chosenName={vacancySubmissionStatus.name || ''}
                            chosenId={vacancySubmissionStatus.id || ''}
                            onChange={(chosenId) => onStatusIdChange(chosenId)}
                        />
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SubmissionsTableRow;

SubmissionsTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onUpdateParticipantStatus: PropTypes.func,
    onSubmissionsRejectEditor: PropTypes.func,
    onSubmissionsRejectedRow: PropTypes.func,
    rowCancelled: PropTypes.bool
};
