import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { loadBusinessSectorDetails } from '../../../../store/directusService';
import { updateBusinessRecord } from '../../../../store/recruitmentService';
import { selectLoggedInUser, selectUsersById } from '../../../../store/userSelectors';
import { loadUserById, searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import {
    getEmptyErrorState,
    validate,
    validateSingleField
} from '../../../../utils/formValidation/validator';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import SingleSelect from '../../../formElements/SingleSelect';
import TextInputField from '../../../formElements/TextInputField';
import FormActions from '../../../ui/formActions/FormActions';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import BusinessContactCard from '../cards/BusinessContactCard';
import { businessValidationFields } from '../shared/BusinessValidationFields';

import CreateBusinessContact from './CreateBusinessContact';

import app from '../../../../app.module.css';
import cards from '../../../../commonStyles/cardContainer.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../../recruitmentStyles/editBusinessStyles.module.css';

const EditBusiness = () => {
    // HOOKS
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        name: '',
        sectorId: '',
        website: '',
        size: '',
        ownerId: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        postcode: '',
        inactive: '',
        contactCards: []
    };
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const businessOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [businessOwners, setBusinessOwners] = useState([]);
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);
    const [editContactType, setEditContactType] = useState('create');
    const [currentContactCard, setCurrentContactCard] = useState({});
    const [errors, setErrors] = useState(getEmptyErrorState(businessValidationFields));
    const [isValid, setIsValid] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [isCreateBusinessButtonDisabled, setIsCreateBusinessButtonDisabled] = useState(false);
    const onCancel = () => onNavigate();

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const { errorMessage, successMessage } = useSelector((state) => state.entities.formsState);
    const businessSectorDetails = useSelector(
        (state) => state.entities.directusService.businessSectorDetails
    );
    const { businesses, currentBusiness } = useSelector(
        (state) => state.entities.recruitmentService
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const usersById = useSelector(selectUsersById);
    const loggedInUser = useSelector(selectLoggedInUser);

    // HELPER FNS
    const setBusinessOwnersArray = (businessOwners) => {
        const updatedBusinessOwners = addEmailAddressAsNameToArray(businessOwners);
        setBusinessOwners(updatedBusinessOwners);
    };

    // USE EFFECTS
    useEffect(() => {
        businessSectorDetails?.length < 1 && dispatch(loadBusinessSectorDetails());
    }, []);

    useEffect(() => {
        if (!currentBusiness || Object.keys(currentBusiness).length < 1) return;
        setNewEntry(currentBusiness);
        if (currentBusiness.ownerId) {
            const initialBusinessOwner =
                usersById[currentBusiness.ownerId] ||
                users.find((el) => el.id === currentBusiness.ownerId);
            if (!initialBusinessOwner) {
                dispatch(loadUserById(currentBusiness.ownerId));
            } else {
                setBusinessOwnersArray([initialBusinessOwner]);
            }
        }
    }, [currentBusiness]);

    useEffect(() => {
        if (!users.length) return;
        let updatedBusinessOwners = users.filter((el) =>
            el.userTypes?.find((entry) => businessOwnerRoles.includes(entry.role))
        );
        if (!updatedBusinessOwners.length && users.length < totalUsers) {
            onLoadMoreBusinessOwners();
            return;
        }
        if (newEntry.ownerId && !updatedBusinessOwners.some((el) => el.id === newEntry.ownerId)) {
            // Put selected business owner at the top of dropdown if it's not in the updated business owners array
            const selectedAdvisor = businessOwners.find((el) => el.id === newEntry.ownerId);
            updatedBusinessOwners = [selectedAdvisor, ...updatedBusinessOwners];
        }
        setBusinessOwnersArray(updatedBusinessOwners);
    }, [users]);

    useEffect(() => {
        if (newEntry.ownerId && usersById[newEntry.ownerId]) {
            setBusinessOwnersArray([usersById[newEntry.ownerId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (!isDisabled) setDisableFields(false);
    }, [isDisabled]);

    useEffect(() => {
        setIsDisabled(true);
    }, [isCreateBusinessButtonDisabled]);

    useEffect(() => {
        if (errorMessage) {
            setDisableFields(false);
            setIsDisabled(false);
            setIsCreateBusinessButtonDisabled(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === `Business record has been updated`) onNavigate();
    }, [successMessage]);

    // HELPER FNS
    const getPreSelect = (arr, id) => {
        return arr.find((el) => el.id === id) || {};
    };

    const loadingErrors = () => {
        if (businessSectorDetails?.length < 1) return 'No business sector found';
        if (businessOwners?.length < 1) return 'No record business owners';
    };

    const onNavigate = () => {
        navigate('/recruitment_management', {
            state: { accordionPanel: state?.accordionPanel || 'myBusinesses' }
        });
    };

    // EVENT HANDLERS
    const onAddBusinessContact = () => {
        setShowCreateContactModal(true);
        setEditContactType('create');
        setCurrentContactCard({});
    };

    const addBusinessContact = (newCard) => {
        setNewEntry((prev) => ({
            ...prev,
            contactCards: [...newEntry.contactCards, { ...newCard, id: uuid() }]
        }));
        setShowCreateContactModal(false);
    };

    const onEditBusinessContact = (entry) => {
        setShowCreateContactModal(true);
        setEditContactType('edit');
        setCurrentContactCard(entry);
    };

    const editBusinessContact = (entry) => {
        setNewEntry((prev) => ({
            ...prev,
            contactCards: newEntry.contactCards.map((el) => (el.id === entry.id ? entry : el))
        }));
        setShowCreateContactModal(false);
        setCurrentContactCard({});
    };

    const onCancelBusinessContact = () => {
        setShowCreateContactModal(false);
        setCurrentContactCard({});
    };

    const onUpdate = (key, value, clearKeys = []) => {
        if (disableFields) return;
        const entryToSet = { ...newEntry, [key]: value };
        const emptyErrorState = getEmptyErrorState(businessValidationFields);

        if (emptyErrorState[key]) {
            const validation = validateSingleField(
                entryToSet,
                key,
                errors,
                businessValidationFields,
                isValid
            );
            setErrors(validation.errors);
            setIsValid(validation.isValid);
        }
        for (const clearKey of clearKeys) {
            entryToSet[clearKey] = initialState[clearKey];
        }
        setNewEntry(entryToSet);
    };

    const onLoadMoreBusinessOwners = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(newEntry, businessValidationFields, {
            businessNames: businesses.filter((el) => el.id !== newEntry.id).map((el) => el.name)
        });
        if (!newErrors.isValid) {
            setErrors(newErrors.errors);
            setIsValid(newErrors.isValid);
            setIsCreateBusinessButtonDisabled(true);
            return;
        }

        const payload = {
            ...newEntry,
            contactCards: newEntry.contactCards.map((el) => ({
                ...el,
                id: null
            }))
        };
        setDisableFields(true);
        setIsDisabled(true);
        dispatch(updateBusinessRecord(payload, newEntry.ownerId === loggedInUser.id));
    };

    // AWAITING CONTENT
    const errorMsg = loadingErrors();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h1 className={app.mainHeading}>Edit Business</h1>
                <h2 className={app.sectionHeading}>Business Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'name'}
                            label={'Business Name'}
                            placeholder={'Enter business name'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={(e) => onUpdate('name', e.target.value)}
                        />
                        <SingleSelect
                            id={'sectorId'}
                            label={'Business Sector'}
                            placeholder="Select Business Sector..."
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            mandatory={true}
                            menuItems={businessSectorDetails}
                            selectedId={newEntry.sectorId}
                            selected={getPreSelect(businessSectorDetails, newEntry.sectorId)}
                            error={errors.sectorId}
                            onChange={(chosenId) => onUpdate('sectorId', chosenId)}
                        />
                        <TextInputField
                            id={'website'}
                            label={'Business Website'}
                            placeholder={'Enter business website'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            value={newEntry.website || ''}
                            error={errors.website}
                            onChange={(e) => onUpdate('website', e.target.value)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'size'}
                            label={'Business Size'}
                            placeholder={'Enter business size'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={String(newEntry.size) || ''}
                            error={errors.size}
                            onChange={(e) =>
                                onUpdate('size', e.target.value.replace(/[^\d]/g, '').slice(0, 5))
                            }
                        />
                        <SingleSelect
                            id={'ownerId'}
                            label={'Business Record Owner'}
                            placeholder="Select Business Record Owner..."
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            mandatory={true}
                            menuItems={businessOwners}
                            selectedId={newEntry.ownerId}
                            selected={getPreSelect(businessOwners, newEntry.ownerId)}
                            error={errors.ownerId}
                            onChange={(chosenId) => onUpdate('ownerId', chosenId)}
                            onLoadMoreItems={onLoadMoreBusinessOwners}
                            moreItemsToLoad={!totalUsers || users.length < totalUsers}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Contact Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'address1'}
                            label={'Address 1'}
                            placeholder={'Enter address 1'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            mandatory={true}
                            value={newEntry.addressLine1 || ''}
                            error={errors.addressLine1}
                            onChange={(e) => onUpdate('addressLine1', e.target.value)}
                        />
                        <TextInputField
                            id={'address2'}
                            label={'Address 2'}
                            placeholder={'Enter address 2'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            value={newEntry.addressLine2 || ''}
                            error={errors.addressLine2}
                            onChange={(e) => onUpdate('addressLine2', e.target.value)}
                        />
                        <TextInputField
                            id={'address3'}
                            label={'Address 3'}
                            placeholder={'Enter address 3'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            value={newEntry.addressLine3 || ''}
                            error={errors.addressLine3}
                            onChange={(e) => onUpdate('addressLine3', e.target.value)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'city'}
                            label={'Town/City'}
                            placeholder={'Enter town/city'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            mandatory={true}
                            value={newEntry.city || ''}
                            error={errors.city}
                            onChange={(e) => onUpdate('city', e.target.value)}
                        />
                        <TextInputField
                            id={'postcode'}
                            label={'Postcode'}
                            placeholder={'Enter postcode'}
                            disabled={!hasRole(acceptedRoles, roles) || disableFields}
                            mandatory={true}
                            value={newEntry.postcode || ''}
                            error={errors.postcode}
                            onChange={(e) => onUpdate('postcode', e.target.value)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Business Contacts</h2>
                <div className={`${cards.cards} ${cards.cardContainer}`}>
                    {newEntry.contactCards.map((el) => (
                        <div key={el.id}>
                            <BusinessContactCard
                                entry={el}
                                roles={roles}
                                acceptedRoles={acceptedRoles}
                                onEdit={onEditBusinessContact}
                                editable={true}
                            />
                        </div>
                    ))}
                </div>
                {showCreateContactModal && (
                    <CreateBusinessContact
                        type={editContactType}
                        entry={currentContactCard}
                        onAddContact={addBusinessContact}
                        onEditContact={editBusinessContact}
                        onCancel={onCancelBusinessContact}
                        isOpen={showCreateContactModal}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                )}

                <div className={local.buttonMargin}>
                    <Button
                        id="createBusinessContact"
                        type="button"
                        content="Add a Business Contact"
                        disabled={!hasRole(acceptedRoles, roles) || disableFields}
                        onClick={onAddBusinessContact}
                    />
                </div>

                <FormActions
                    id="updateBusiness"
                    btnText="Update Business"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled || disableFields}
                    onClose={onSubmit}
                    onCancel={onCancel}
                />
            </form>
        </div>
    );
};

export default EditBusiness;
