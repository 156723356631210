import { commonFieldValidations } from '../../../utils/formValidation/commonFieldValidations';

export const addressAndContactInfoValidationFields = {
    formAddressLine1: commonFieldValidations.address1,
    formAddressLine2: commonFieldValidations.address2,
    formAddressLine3: commonFieldValidations.address3,
    formAddressLine4: commonFieldValidations.address4,
    formCity: commonFieldValidations.city,
    formCounty: commonFieldValidations.county,
    formPostcode: commonFieldValidations.postcode,
    formPhoneNumber: commonFieldValidations.primaryPhoneNumber,
    exitFormEmailAddress: commonFieldValidations.emailAddress,
    sanctionFormFromEmailAddress: commonFieldValidations.emailAddress,
    serviceAddressLine1: commonFieldValidations.address1,
    serviceAddressLine2: commonFieldValidations.address2,
    serviceAddressLine3: commonFieldValidations.address3,
    serviceAddressLine4: commonFieldValidations.address4,
    serviceCity: commonFieldValidations.city,
    serviceCounty: commonFieldValidations.county,
    servicePostcode: commonFieldValidations.postcode,
    button: {
        global: true,
        checkDiff: {}
    }
};
