import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button } from '@mui/material';

import {
    clearComorbidity,
    clearGp,
    clearReligion,
    clearSmokingTypes,
    loadComorbidityDetails,
    loadGpDetails,
    loadReligionDetails,
    loadSmokingTypes
} from '../../../store/directusService';
import {
    loadParticipantHealthInformation,
    updateParticipantHealthInformation
} from '../../../store/participantService';
import { getConfiguredItems, getNameFromId } from '../../../utils/directusFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import * as validate from '../../../validation/validation';
import LabelledEmailText from '../../ui/editors/LabelledEmailText';
import LabelledPhoneText from '../../ui/editors/LabelledPhoneText';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import FuzzySearchOptionPicker from '../../ui/pickers/FuzzySearchOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';
import { RADIO_BUTTON_NO } from '../../ui/pickers/SelectorOptions';

import form from '../../../commonStyles/formStyles.module.css';

export const MIDWIFE_NAME_MAX_LENGTH = 100; // This is the value in PPL-212
export const MIDWIFE_PHONE_MAX_LENGTH = 15; // This is the value in PPL-212
export const MIDWIFE_EMAIL_MAX_LENGTH = 50; // This is the value in PPL-212
export const DOCTORS_STATEMENT_MAX_LENGTH = 200; // This is the value in PPL-212

const HealthInformation = ({ expectedGpDetailsLength = 2000 }) => {
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState({
        doctorsStatement: null,
        gpId: null,
        hasComorbidities: RADIO_BUTTON_NO,
        comorbidityIds: [],
        participantId: '',
        pregnant: false, // false (default) or true
        midwifeName: null, // 0-MIDWIFE_NAME_MAX_LENGTH chars
        midwifePhone: null, // 0-MIDWIFE_PHONE_MAX_LENGTH chars (digits+ spaces)
        midwifeEmail: null, // 0-MIDWIFE_EMAIL_MAX_LENGTH chars
        religionId: null,
        smokingTypeId: '',
        employed: false // false (default) or true
    });

    const [gpId, setGpId] = useState('');
    const [gp, setGp] = useState('');
    const [arrayGps, setArrayGps] = useState([]);
    const [isClearGp, setIsClearGp] = useState('0');

    const [religionId, setReligionId] = useState('');
    const [religion, setReligion] = useState('');
    const [arrayReligions, setArrayReligions] = useState([]);
    const [isClearReligion, setIsClearReligion] = useState('1');

    const [arraySmokingTypes, setArraySmokingTypes] = useState([]);
    const [isClearSmokingTypes, setIsClearSmokingTypes] = useState('2');

    const [arrayComorbidities, setArrayComorbidities] = useState([]);

    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const { gpDetails, comorbidityDetails, religionDetails, smokingTypes } = useSelector(
        (state) => state.entities.directusService
    );
    const healthInformation = useSelector(
        (state) => state.entities.participantService.currentParticipantHealthInformation
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const dispatch = useDispatch();

    // EVENT HANDLERS
    const handleDoctorsStatementChange = (e) => {
        setNewEntry((prev) => ({ ...prev, doctorsStatement: e.target.value }));
    };

    const handleGpChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setGpId(chosenId);
        setNewEntry((prev) => ({ ...prev, gpId: chosenId }));
    };

    const handleSmokingTypeChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, smokingTypeId: chosenId }));
    };

    const handleComorbidityChange = (ids, yesNo) => {
        setNewEntry((prev) => ({
            ...prev,
            hasComorbidities: yesNo,
            comorbidityIds: ids
        }));
    };

    const handlePregnancyChange = (option) => {
        setNewEntry((prev) => ({ ...prev, pregnant: option }));
    };

    const handleMidwifeNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, midwifeName: e.target.value }));
    };

    const handleMidwifePhoneChange = (e) => {
        setNewEntry((prev) => ({ ...prev, midwifePhone: e.target.value }));
    };

    const handleMidwifeEmailChange = (e) => {
        setNewEntry((prev) => ({ ...prev, midwifeEmail: e.target.value }));
    };

    const handleReligionChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setReligionId(chosenId);
        setNewEntry((prev) => ({ ...prev, religionId: chosenId }));
    };

    const handleEmployedChange = (option) => {
        setNewEntry((prev) => ({ ...prev, employed: option }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length && !newEntry.participantId) {
            setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
        }
    }, [currentParticipant]);

    useEffect(() => {
        // clear local
        setGp('');
        setReligion('');
        setArrayGps([]);
        setArrayReligions([]);
        setArraySmokingTypes([]);
        setArrayComorbidities([]);
        setIsClearGp(Math.random());
        setIsClearReligion(Math.random());
        setIsClearSmokingTypes(Math.random());

        // clear from store
        dispatch(clearGp());
        dispatch(clearComorbidity());
        dispatch(clearReligion());
        dispatch(clearSmokingTypes());

        // load from store
        dispatch(loadGpDetails());
        dispatch(loadGpDetails(2));
        dispatch(loadGpDetails(3));
        dispatch(loadComorbidityDetails());
        dispatch(loadReligionDetails());
        dispatch(loadSmokingTypes());

        if (currentParticipant?.id) {
            dispatch(loadParticipantHealthInformation(currentParticipant.id));
        }
    }, [currentParticipant.id]);

    useEffect(() => {
        if (Object.keys(healthInformation)?.length > 0) {
            setNewEntry(healthInformation);
            setGpId(healthInformation.gpId);
            setReligionId(healthInformation.religionId);
        }
    }, [healthInformation]);

    /**
     * gpDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (gpDetails?.length > expectedGpDetailsLength && arrayGps?.length === 0) {
            if (healthInformation?.gpId !== undefined) {
                setGp(getNameFromId(gpDetails, healthInformation.gpId));
            }
            setArrayGps(getConfiguredItems(gpDetails, currentParticipant?.contractId));
        }
    }, [gpDetails]);

    useEffect(() => {
        if (smokingTypes?.length > 0 && arraySmokingTypes?.length === 0) {
            setArraySmokingTypes(smokingTypes);
        }
    }, [smokingTypes]);

    /**
     * comorbidityDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (comorbidityDetails?.length && arrayComorbidities?.length === 0) {
            const configItems = getConfiguredItems(
                comorbidityDetails,
                currentParticipant?.contractId
            );
            const sorted = configItems.sort((a, b) => a.name.localeCompare(b.name));
            setArrayComorbidities(sorted);
        }
    }, [comorbidityDetails]);

    /**
     * religionDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (religionDetails?.length && arrayReligions?.length === 0) {
            if (healthInformation.religionId !== undefined) {
                setReligion(getNameFromId(religionDetails, healthInformation?.religionId));
            }
            setArrayReligions(getConfiguredItems(religionDetails, currentParticipant?.contractId));
        }
    }, [religionDetails]);

    useEffect(() => {
        if (successMessage === 'Participant health details have been updated') {
            setIsUpdateButtonDisabled(false);
        }
    }, [successMessage]);

    // FORM SUBMIT
    const onSubmit = () => {
        setIsUpdateButtonDisabled(true);
        const payload = { ...newEntry, participantId: currentParticipant.id };
        dispatch(updateParticipantHealthInformation(payload));
    };

    let content = '';
    if (gpDetails?.length < 1) content = 'No GP details';
    if (religionDetails?.length < 1) content = 'No religion details';
    if (Object.keys(currentParticipant)?.length < 1) content = 'No participant';
    if (arrayGps?.length < 1) content = 'No GPs';
    if (arrayReligions?.length < 1) content = 'No religions';
    if (arraySmokingTypes?.length < 1) content = 'No smoking types';

    // RENDER
    if (
        gpDetails?.length < 1 ||
        religionDetails?.length < 1 ||
        Object.keys(currentParticipant)?.length < 1 ||
        arrayGps?.length < 1 ||
        arrayReligions?.length < 1 ||
        arraySmokingTypes?.length < 1
    ) {
        return <LoadingSpinner content={content} />;
    }

    return (
        <div className={form.formWrapper}>
            <form onSubmit={handleSubmit(onSubmit)} className={form.form}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'GP'}
                            id={'gpId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            key={isClearGp}
                            chosenName={
                                gp || healthInformation?.gpId
                                    ? getNameFromId(gpDetails, healthInformation.gpId)
                                    : ''
                            }
                            chosenId={gpId}
                            menuItems={arrayGps}
                            onChange={(chosenId) => handleGpChange(chosenId)}
                        />

                        <div className={form.textArea}>
                            <LabelledTextField
                                label={"Doctor's Statement"}
                                disabled={!hasRole(acceptedRoles, roles)}
                                id={'doctorsStatement'}
                                value={newEntry.doctorsStatement || ''}
                                placeholder={"Enter doctor's statement"}
                                rows={4}
                                multiline
                                counter={'true'}
                                helperText={`${newEntry.doctorsStatement?.length}/${DOCTORS_STATEMENT_MAX_LENGTH}`}
                                error={errors.doctorsStatement}
                                inputProps={{
                                    maxLength: DOCTORS_STATEMENT_MAX_LENGTH,
                                    autoComplete: 'off',
                                    form: { autoComplete: 'off' }
                                }}
                                {...register('doctorsStatement')}
                                onChange={handleDoctorsStatementChange}
                            />
                        </div>

                        <FuzzySearchOptionPicker
                            label={'Co-Morbidities'}
                            inputLabel="Enter Co-Morbidities"
                            disabled={!hasRole(acceptedRoles, roles)}
                            enterPrompt={'comorbidity'}
                            allOptions={arrayComorbidities || []}
                            preSelectedIds={healthInformation.comorbidityIds || []}
                            radioButtonPick={healthInformation.hasComorbidities || RADIO_BUTTON_NO}
                            onChange={handleComorbidityChange}
                        />
                        <DDLOptionPicker
                            label={'Smoker'}
                            id={'smokerId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            key={isClearSmokingTypes}
                            chosenName={getNameFromId(smokingTypes, newEntry.smokingTypeId)}
                            chosenId={newEntry.smokingTypeId}
                            menuItems={arraySmokingTypes || []}
                            onChange={(chosenId) => handleSmokingTypeChange(chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <NoYesRadioPicker
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={healthInformation.pregnant || false}
                            label={'Pregnant'}
                            data-testid="testIdPregnant"
                            onChange={handlePregnancyChange}></NoYesRadioPicker>
                        {/* Only show midwife newEntry if pregnant*/}
                        <div hidden={!newEntry.pregnant}>
                            <LabelledTextField
                                label={"Midwife's Name"}
                                disabled={!hasRole(acceptedRoles, roles)}
                                id={'midwifeName'}
                                value={newEntry.midwifeName || ''}
                                placeholder={"Enter midwife's name"}
                                error={errors.midwifeName}
                                inputProps={{
                                    maxLength: MIDWIFE_NAME_MAX_LENGTH,
                                    autoComplete: 'off',
                                    form: { autoComplete: 'off' }
                                }}
                                onChange={handleMidwifeNameChange}
                            />
                            <LabelledPhoneText
                                label={"Midwife's Phone"}
                                id={'midwifePhone'}
                                value={newEntry.midwifePhone || ''}
                                error={errors.midwifePhone}
                                mandatory={false}
                                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                                placeholder={"Enter midwife's phone"}
                                inputProps={{
                                    maxLength: MIDWIFE_PHONE_MAX_LENGTH,
                                    autoComplete: 'off',
                                    form: { autoComplete: 'off' }
                                }}
                                {...register('midwifePhone')}
                                onChange={handleMidwifePhoneChange}
                            />

                            <LabelledEmailText
                                label={"Midwife's Email"}
                                disabled={!hasRole(acceptedRoles, roles)}
                                id={'midwifeEmail'}
                                mandatory={false}
                                value={newEntry.midwifeEmail || ''}
                                placeholder={"Enter midwife's email"}
                                error={errors.midwifeEmail}
                                name="midwifeEmail"
                                {...register('midwifeEmail')}
                                inputProps={{
                                    maxLength: MIDWIFE_EMAIL_MAX_LENGTH,
                                    autoComplete: 'off',
                                    form: { autoComplete: 'off' }
                                }}
                                onChange={handleMidwifeEmailChange}
                            />
                        </div>
                        <NoYesRadioPicker
                            onChange={handleEmployedChange}
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={healthInformation.employed || false}
                            label={'Employed'}></NoYesRadioPicker>

                        <DDLOptionPicker
                            label={'Religion'}
                            id={'religionId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            key={isClearReligion}
                            chosenName={
                                religion || healthInformation?.religionId
                                    ? getNameFromId(religionDetails, healthInformation.religionId)
                                    : ''
                            }
                            chosenId={religionId}
                            menuItems={arrayReligions}
                            onChange={(chosenId) => handleReligionChange(chosenId)}
                        />
                    </div>
                </div>

                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!hasRole(acceptedRoles, roles) || isUpdateButtonDisabled}
                    data-testid="testIdHISubmitButton">
                    Update Health Information
                </Button>
            </form>
        </div>
    );
};

/**
 * The TextFields check their max length input is not exceeded and have no
 * mandatory minimum length
 * NoYesRadioPicker cannot return an invalid value.
 * DDLOptionPickers are still not connected to data.
 *
 */
const validationSchema = Yup.object().shape({
    midwifePhone: Yup.string()
        .matches(/.{11,}/, {
            excludeEmptyString: true,
            message: 'Midwife phone number must be 11 characters or more'
        })
        .max(15, 'Midwife phone number must be 15 characters or less')
        .matches(validate.PHONE_REGEXP, {
            excludeEmptyString: true,
            message: 'Invalid Midwife Phone Number'
        }),

    midwifeEmail: Yup.string()
        .email('Please enter a valid email address')
        .max(50, 'Email Address must be 50 characters or less')
});

HealthInformation.propTypes = {
    expectedGpDetailsLength: PropTypes.number
};

export default HealthInformation;
