import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Tooltip } from '@mui/material';

import { setErrorMessage } from '../../../store/formsState';
import { createDar, loadParticipantByPtCode } from '../../../store/participantService';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import actions from '../../ui/formActions/formActions.module.css';
import classes from '../homeStyles/dar.module.css';

const NOTES_LIMIT = 750;

const CreateDar = ({ onClose, roles, darRequestTypes, darPriorities }) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const PRIORITY_INFO = (
        <>
            <p>
                <span className={`${classes.redText}`}>Red Requests</span> - Potential data breach
                changes - Documents uploaded to the wrong record/Participant
            </p>
            <p>
                <span className={`${classes.amberText}`}>Amber Requests</span> - Changes to
                placements/appointments that may be associated to a claim point.
            </p>
            <p>
                <span className={`${classes.greenText}`}>Green Requests</span> - General participant
                data changes i.e. NINOs
            </p>
        </>
    );

    const toolTipContent = <div className="">{PRIORITY_INFO}</div>;
    const initialState = {
        id: null,
        requestTypeId: null,
        participantId: null,
        priorityId: null,
        notes: '',
        ptCode: null
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

    const [clearSelectedRequestType, setClearSelectedRequestType] = useState('0');
    const [clearSelectedPriority, setClearSelectedPriority] = useState('1');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    // EVENT HANDLERS
    const handleRequestTypeChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, requestTypeId: chosenId }));
    };

    const handlePriorityChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, priorityId: chosenId }));
    };

    const handlePTCodeChange = (e) => {
        clearErrors('ptCode');
        setNewEntry((prev) => ({ ...prev, ptCode: e.target.value.toUpperCase() }));
        if (e.target.value.length < 9) return;
        if (!e.target.value.toUpperCase().startsWith('PT')) {
            dispatch(setErrorMessage(`PT code must start with PT.`));
        } else {
            dispatch(loadParticipantByPtCode(e.target.value));
        }
    };

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setClearSelectedRequestType(Math.random());
        setClearSelectedPriority(Math.random());
    };

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant.ptCode === newEntry.ptCode)
            setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
    }, [currentParticipant.id]);

    useEffect(() => {
        if (successMessage === 'DAR has been added') clearForm();
    }, [successMessage]);

    // EVENT HANDLERS
    const onFormExit = () => {
        clearForm();
        clearErrors();
        onClose();
    };

    const onSubmit = () => {
        if (!newEntry.ptCode.startsWith('PT')) {
            dispatch(setErrorMessage(`PT code must start with PT.`));
        } else if (!newEntry.participantId) {
            dispatch(setErrorMessage(`Error: PT ID not found.`));
        } else {
            // eslint-disable-next-line
            const { ptCode, ...rest } = newEntry;
            const payload = {
                ...rest
            };
            dispatch(createDar(payload));
            onFormExit();
        }
    };

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form className={form.form} onSubmit={handleSubmit(onSubmit)}>
                <h2 className={app.sectionHeading}>Create Data Amendment Request:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Request Type'}
                            key={clearSelectedRequestType}
                            id={'requestTypeId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={darRequestTypes || []}
                            error={errors.requestTypeId}
                            {...register('requestTypeId')}
                            onChange={(chosenId) => handleRequestTypeChange(chosenId)}
                        />
                        <LabelledTextField
                            label={'PT ID'}
                            id={'ptCode'}
                            mandatory={true}
                            placeholder={'Enter PT code'}
                            value={newEntry.ptCode || ''}
                            error={errors.ptCode}
                            {...register('ptCode')}
                            onChange={handlePTCodeChange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <div className={form.withInfoWrapper}>
                            <DDLOptionPicker
                                label={'Priority'}
                                key={clearSelectedPriority}
                                id={'priorityId'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                menuItems={darPriorities || []}
                                error={errors.priorityId}
                                {...register('priorityId')}
                                onChange={(chosenId) => handlePriorityChange(chosenId)}
                            />
                            <div className={form.infoIcon}>
                                <Tooltip title={toolTipContent} placement={'top'}>
                                    <InfoIcon
                                        sx={{ color: POLARIS_ROYAL_BLUE }}
                                        width={20}
                                        height={20}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Notes'}
                            id={'notes'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            multiline
                            rows={5}
                            value={newEntry.notes}
                            placeholder={'Enter data amendment request notes'}
                            counter={'true'}
                            helperText={`${newEntry.notes.length}` + '/' + NOTES_LIMIT}
                            inputProps={{ maxLength: NOTES_LIMIT }}
                            error={errors.notes}
                            {...register('notes')}
                            onChange={(e) => {
                                clearErrors('notes');
                                setNewEntry((prev) => ({ ...prev, notes: e.target.value }));
                            }}
                        />
                    </div>
                </div>
                <div className={actions.formActions}>
                    <Button type="submit" color="primary" variant="contained">
                        Add Data Amendment Request
                    </Button>
                    <div className={actions.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    requestTypeId: Yup.string().required('Please select a request type'),
    priorityId: Yup.string().required('Please select a priority'),
    ptCode: Yup.string()
        .min(9, 'PT code must be nine character')
        .max(9, 'PT code must be nine character'),
    notes: Yup.string().min(5, 'Notes must be at least five characters')
});

export default CreateDar;

CreateDar.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    darRequestTypes: PropTypes.array.isRequired,
    darPriorities: PropTypes.array.isRequired
};
