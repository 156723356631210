import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import AccordionHeader from '../../../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../../../ui/accordion/AccordionIcon';
import InWorkSupport from '../inWorkSupport/InWorkSupport';

import OwnPlacement from './OwnPlacement';
import RecruitmentPlacement from './RecruitmentPlacement';

import local from '../../submissionStyles/placement.module.css';

const Placement = ({
    row,
    roles,
    placementEmploymentTypeDetails,
    placementStatusDetails,
    hoursPerWeekDetails,
    jobSectorDetails,
    ownPlacementDetails,
    callOutcomes,
    frequencyDetails,
    workConfidence
}) => {
    // LOCAL STATE
    const [expanded, setExpanded] = useState('');

    const onAccordionChange = (panel) => setExpanded(expanded === panel ? '' : panel);

    return (
        <div>
            <Accordion
                expanded={expanded === 'workSupport'}
                onChange={() => onAccordionChange('workSupport')}
                TransitionProps={{ mountOnEnter: true }}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls={`panel1a-content`}
                    sx={{ borderTop: '1px solid #000' }}
                    id={`panel1a-header`}>
                    <AccordionHeader>
                        <div className={local.accordionTitle}>In Work Support</div>
                    </AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <InWorkSupport
                        row={row}
                        roles={roles}
                        callOutcomes={callOutcomes}
                        frequencyDetails={frequencyDetails}
                        workConfidence={workConfidence}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'placementInfo'}
                onChange={() => onAccordionChange('placementInfo')}
                TransitionProps={{ mountOnEnter: true }}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls={`panel1a-content`}
                    sx={{ borderTop: '1px solid #000' }}
                    id={`panel1a-header`}>
                    <AccordionHeader>Placement Information</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    {row.code.slice(0, 2) === 'RP' ? (
                        <RecruitmentPlacement
                            row={row}
                            roles={roles}
                            placementEmploymentTypeDetails={placementEmploymentTypeDetails}
                            placementStatusDetails={placementStatusDetails}
                            hoursPerWeekDetails={hoursPerWeekDetails}
                        />
                    ) : (
                        <OwnPlacement
                            row={row}
                            roles={roles}
                            placementEmploymentTypeDetails={placementEmploymentTypeDetails}
                            placementStatusDetails={placementStatusDetails}
                            hoursPerWeekDetails={hoursPerWeekDetails}
                            jobSectorDetails={jobSectorDetails}
                            ownPlacementDetails={ownPlacementDetails}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Placement;

Placement.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    placementEmploymentTypeDetails: PropTypes.array.isRequired,
    placementStatusDetails: PropTypes.array.isRequired,
    hoursPerWeekDetails: PropTypes.array.isRequired,
    jobSectorDetails: PropTypes.array.isRequired,
    ownPlacementDetails: PropTypes.array.isRequired,
    callOutcomes: PropTypes.array.isRequired,
    frequencyDetails: PropTypes.array.isRequired,
    workConfidence: PropTypes.array.isRequired
};
