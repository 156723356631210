import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setSuccessMessage } from '../../../store/formsState';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import LabelledTextField from '../../ui/editors/LabelledTextField';

const CHARACTER_LIMIT = 750;

const StatusRevertEditor = ({ open, onStatusRevertEditor, revertedRow, onStatusRevertUpdated }) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [notes, setNotes] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        onStatusRevertEditor(false);
        clearData();
    };

    const clearData = () => {
        setNotes('');
    };

    // USE EFFECTS
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const onSubmit = () => {
        dispatch(setSuccessMessage(`Reversion notes added.`));
        onStatusRevertUpdated({ ...revertedRow, notes });
        onStatusRevertEditor(false);
        clearData();
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <Dialog open={isOpen} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Claim Status Reversion</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <LabelledTextField
                            label={'Reversion Notes'}
                            id={'notes'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            multiline
                            rows={5}
                            value={notes}
                            placeholder={'Enter reversion notes'}
                            counter={'true'}
                            helperText={`${notes.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            error={errors.notes}
                            {...register('notes')}
                            onChange={handleNotesChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                            Update
                        </Button>
                        <Button onClick={handleCancel} color="primary" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    notes: Yup.string().trim().min(5, 'Reversion notes must be at least five characters.')
});

StatusRevertEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onStatusRevertEditor: PropTypes.func,
    revertedRow: PropTypes.object,
    onStatusRevertUpdated: PropTypes.func
};

export default StatusRevertEditor;
