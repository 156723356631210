import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import {
    addClaimQueueCreateCsv,
    addClaimQueueCreatePaidCsv,
    addClaimQueueProceedCsv
} from '../../../store/documentService';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import Uploader from '../Uploader';
import UploaderResults from '../UploaderResults';

import { initialErrorState, validate } from './validateClaimStatusLoader';

import '../../../commonStyles/muiStyles.css';
import form from '../../../commonStyles/formStyles.module.css';
import modal from '../../../commonStyles/modal.module.css';

const maxFileSize = 25;
const fileTypes = '.csv';
const acceptedFileTypes = ['text/csv'];
const MAX_NOTES_CHAR = 750;

const ClaimStatusUploaderModal = ({
    contracts,
    claimStatusUploaderTypes,
    claimStatuses,
    claimTypes,
    onClose,
    onCancel,
    isOpen
}) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialErrorState);
    const [failures, setFailures] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fileName, setFileName] = useState('');
    const [chosenClaimStatusUploaderType, setChosenClaimStatusUploaderType] = useState({});
    const [chosenContract, setChosenContract] = useState({});
    const [chosenClaimStatus, setChosenClaimStatus] = useState({});
    const [chosenClaimType, setChosenClaimType] = useState({});
    const [notes, setNotes] = useState('');
    const [uploadData, setUploadData] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    useEffect(() => {
        const isValid = validate(chosenClaimStatusUploaderType, uploadData, errors);
        setIsDisabled(!isValid);
    }, [uploadData, chosenClaimStatusUploaderType]);

    const onUpload = (payload, successMsg) => {
        chosenClaimStatusUploaderType?.name === 'Proceed' &&
            dispatch(addClaimQueueProceedCsv(payload, successMsg));
        chosenClaimStatusUploaderType?.name === 'All to Paid' &&
            dispatch(addClaimQueueCreatePaidCsv(payload, successMsg));
        chosenClaimStatusUploaderType?.name === 'Starting' &&
            dispatch(addClaimQueueCreateCsv(payload, successMsg));
    };
    const onLoaded = (uploadErrors, csvFileName) => {
        setChosenClaimStatusUploaderType({});
        uploadErrors && setFailures(uploadErrors);
        csvFileName && setFileName(csvFileName.split('.')[0]);
        setIsLoaded(true);
    };

    const onResultsClose = () => {
        setErrors([]);
        setFailures([]);
        setIsLoaded(false);
        onClose();
    };

    return claimStatusUploaderTypes?.length < 1 ? null : (
        <Dialog onClose={onClose} open={isOpen} fullWidth={true} className="muiDialogWrapper">
            <div className={modal.modalHeader}>
                <DialogTitle>Claim Status Uploader</DialogTitle>
            </div>
            <DialogContent>
                {!isLoaded ? (
                    <>
                        <div className={form.formWrapper}>
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <div>
                                        <DDLOptionPicker
                                            label="Claim Status Uploader Type"
                                            id="ClaimStatusUploaderType"
                                            mandatory={true}
                                            menuItems={claimStatusUploaderTypes || []}
                                            chosenName={chosenClaimStatusUploaderType?.name || ''}
                                            chosenId={chosenClaimStatusUploaderType?.id || ''}
                                            onChange={(chosenId) => {
                                                clearError('chosenClaimStatusUploaderType');
                                                const type = claimStatusUploaderTypes.find(
                                                    (el) => el.id === chosenId
                                                );
                                                type && setChosenClaimStatusUploaderType(type);
                                            }}></DDLOptionPicker>
                                    </div>
                                    {chosenClaimStatusUploaderType?.name === 'Proceed' && (
                                        <div>
                                            <DDLOptionPicker
                                                label="Contract"
                                                id="contractId"
                                                mandatory={true}
                                                menuItems={contracts || []}
                                                chosenName={chosenContract?.name || ''}
                                                chosenId={chosenContract?.id || ''}
                                                onChange={(chosenId) => {
                                                    clearError('contractId');
                                                    const contract = contracts.find(
                                                        (el) => el.id === chosenId
                                                    );
                                                    setChosenContract(contract);
                                                    setUploadData((prev) => ({
                                                        ...prev,
                                                        ['contractId']: {
                                                            key: 'contractId',
                                                            value: contract?.id
                                                        }
                                                    }));
                                                }}></DDLOptionPicker>
                                        </div>
                                    )}
                                </div>
                                <div className={form.formColumn}>
                                    {chosenClaimStatusUploaderType?.name === 'Proceed' && (
                                        <>
                                            <div>
                                                <DDLOptionPicker
                                                    label="Claim Status"
                                                    id="claimStatusId"
                                                    mandatory={true}
                                                    menuItems={claimStatuses || []}
                                                    chosenName={chosenClaimStatus?.name || ''}
                                                    chosenId={chosenClaimStatus?.id || ''}
                                                    onChange={(chosenId) => {
                                                        clearError('claimStatusId');
                                                        const claimStatus = claimStatuses.find(
                                                            (el) => el.id === chosenId
                                                        );
                                                        setChosenClaimStatus(claimStatus);
                                                        setUploadData((prev) => ({
                                                            ...prev,
                                                            ['claimStatusId']: {
                                                                key: 'claimStatusId',
                                                                value: claimStatus?.id
                                                            }
                                                        }));
                                                    }}></DDLOptionPicker>
                                            </div>
                                            <div>
                                                <DDLOptionPicker
                                                    label="Claim Type"
                                                    id="claimTypeId"
                                                    mandatory={true}
                                                    menuItems={claimTypes || []}
                                                    chosenName={chosenClaimType?.name || ''}
                                                    chosenId={chosenClaimType?.id || ''}
                                                    onChange={(chosenId) => {
                                                        clearError('claimTypeId');
                                                        const claimType = claimTypes.find(
                                                            (el) => el.id === chosenId
                                                        );
                                                        setChosenClaimType(claimType);
                                                        setUploadData((prev) => ({
                                                            ...prev,
                                                            ['claimTypeId']: {
                                                                key: 'claimTypeId',
                                                                value: claimType?.id
                                                            }
                                                        }));
                                                    }}></DDLOptionPicker>
                                            </div>
                                        </>
                                    )}

                                    {(chosenClaimStatusUploaderType?.name === 'All to Paid' ||
                                        chosenClaimStatusUploaderType?.name === 'Starting') && (
                                        <div>
                                            <DDLOptionPicker
                                                label="Contract"
                                                id="contractId"
                                                mandatory={true}
                                                menuItems={contracts || []}
                                                chosenName={chosenContract?.name || ''}
                                                chosenId={chosenContract?.id || ''}
                                                onChange={(chosenId) => {
                                                    clearError('contractId');
                                                    const contract = contracts.find(
                                                        (el) => el.id === chosenId
                                                    );
                                                    setChosenContract(contract);
                                                    setUploadData((prev) => ({
                                                        ...prev,
                                                        ['contractId']: {
                                                            key: 'contractId',
                                                            value: contract?.id
                                                        }
                                                    }));
                                                }}></DDLOptionPicker>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <div className={form.textAreaSet}>
                                        <label htmlFor="notes">Notes</label>
                                        <textarea
                                            className={form.textAreaText}
                                            id="notes"
                                            rows={5}
                                            maxLength={MAX_NOTES_CHAR}
                                            value={notes || ''}
                                            placeholder={'Notes'}
                                            onChange={(e) => {
                                                setNotes(e.target.value);
                                                setUploadData((prev) => ({
                                                    ...prev,
                                                    ['notes']: {
                                                        key: 'notes',
                                                        value: e.target.value
                                                    }
                                                }));
                                            }}
                                        />
                                        <div className="counter">
                                            {notes?.length}/{MAX_NOTES_CHAR}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Uploader
                            uploadData={Object.values(uploadData).filter((el) => el.value)}
                            acceptedFileTypes={acceptedFileTypes}
                            fileTypes={fileTypes}
                            maxFileSize={maxFileSize}
                            onUpload={onUpload}
                            onClose={onLoaded}
                            onCancel={onCancel}
                            isDisabled={isDisabled}
                        />
                    </>
                ) : (
                    <UploaderResults
                        fileName={fileName}
                        failures={failures}
                        onClose={onResultsClose}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ClaimStatusUploaderModal;

ClaimStatusUploaderModal.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    claimStatuses: PropTypes.arrayOf(PropTypes.object),
    claimStatusUploaderTypes: PropTypes.arrayOf(PropTypes.object),
    claimTypes: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
};
