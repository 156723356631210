import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
    selectAttendanceStatus,
    selectLocationsForServices
} from '../../../../store/dataSelectors';
import { selectUsers } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const CourseTable = ({ onIsLoaded, currentParticipant }) => {
    const coursesRef = useRef(null);
    const [rows, setRows] = useState([]);
    const courses = useSelector((state) => state.entities.courseService.participantsCourses);
    const attendanceDetails = useSelector(selectAttendanceStatus);
    const locations = useSelector(selectLocationsForServices);
    const users = useSelector(selectUsers);

    useEffect(() => {
        if (users?.length < 1 || locations?.length < 1 || attendanceDetails?.length < 1) return;
        if (courses?.length < 1) {
            onIsLoaded('courses');
            return;
        }
        const moduleList = courses
            .map((el) =>
                el.modules.map((entry) => ({
                    ...entry,
                    courseCode: el.code,
                    currentParticipant:
                        entry.participants.find(
                            (pt) => pt.participantId === currentParticipant.id
                        ) || null
                }))
            )
            .flat()
            .filter((el) => el.currentParticipant)
            .map((el) => {
                const trainer = users.find((entry) => entry.id === el.userId);
                const { firstName, lastName } = trainer
                    ? trainer
                    : { firstName: ' - ', lastName: '' };
                return {
                    id: el.courseCode,
                    module: el?.title,
                    startDate: el?.date ? reverseFormatDate(el.date) : ' - ',
                    startTime: el?.startTime || ' - ',
                    // eslint-disable-next-line no-constant-binary-expression
                    trainer: `${firstName} ${lastName}` || ' - ',
                    location: getNameFromId(locations, el?.locationId) || ' - ',
                    status:
                        getNameFromId(attendanceDetails, el?.currentParticipant?.statusId) || ' - '
                };
            });
        setRows(moduleList);
    }, [courses, users, locations, attendanceDetails]);

    useEffect(() => {
        coursesRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('courses');
    }, [coursesRef.current]);

    return (
        <div>
            <h3>Courses</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>Course ID</span>
                    <span>Module</span>
                    <span>Start Date</span>
                    <span>Start Time</span>
                    <span>Trainer</span>
                    <span>Location</span>
                    <span>Status</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (coursesRef.current = el)}
                                key={i}
                                id={`${el.id}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.id}</span>
                                <span>{el.module}</span>
                                <span>{el.startDate}</span>
                                <span>{el.startTime}</span>
                                <span>{el.trainer}</span>
                                <span>{el.location}</span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CourseTable;

CourseTable.propTypes = {
    currentParticipant: PropTypes.object,
    onIsLoaded: PropTypes.func
};
