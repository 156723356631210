import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardContent } from '@mui/material';

import { POLARIS_WHITE } from '../../../themes/theme';
import LoadingSpinner from '../../ui/LoadingSpinner';

import TimelineAppointmentEventHistoryTable from './TimelineAppointmentEventHistoryTable';

const TimelineAppointmentEventHistoryManagement = ({ eventHistory = [] }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(eventHistory).length < 1) return;
        setRows((prev) => [...prev, eventHistory]);
    }, []);

    // RENDER
    return rows.length < 1 ? (
        <LoadingSpinner content="No timeline event history found" />
    ) : (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    {rows.length < 1 ? (
                        <LoadingSpinner content="No timeline event history found" />
                    ) : (
                        <TimelineAppointmentEventHistoryTable rows={rows} />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

TimelineAppointmentEventHistoryManagement.propTypes = {
    eventHistory: PropTypes.object
};

export default TimelineAppointmentEventHistoryManagement;
