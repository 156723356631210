import { HTML_REGEX } from '../../../../validation/validation';

const MAX_NAME_CHAR = 20;
const MIN_NAME_CHAR = 3;
const MIN_LENGTH = 3;
const MIN_SUBJECT_LENGTH = 5;
const MAX_SUBJECT_LENGTH = 100;
const MAX_EMAIL_BODY = 1048576; // 1 MB
const ERROR_NO_NAME = 'Please enter a name';
const ERROR_MIN_LENGTH = `Please enter at least ${MIN_NAME_CHAR} characters`;
const ERROR_MAX_LENGTH = 'Maximum length has been exceeded';
const ERROR_MAX_CONTENT_SIZE = 'Maximum size has been exceeded';
const ERROR_NO_SUBJECT = 'Please enter an email subject';
const ERROR_MIN_SUBJECT_LENGTH = `Please enter at least ${MIN_SUBJECT_LENGTH} characters`;
const ERROR_MAX_SUBJECT_SIZE = `Subject length must be less than ${MAX_SUBJECT_LENGTH} characters`;
export const ERROR_EXISTS = 'You already have a group with this name';

const ERROR_NO_TEXT = 'Please enter details';

export const initialErrorState = {
    name: { error: false, message: '' },
    details: { error: false, message: '' },
    subject: { error: false, message: '' },
    emailAddress: { error: false, message: '' },
    contactMode: { error: false, message: '' }
};

const validateDetails = (newEntry, errors) => {
    let newErrors = errors;
    const signatureLength = newEntry.signature?.replace(HTML_REGEX, '').trim().length || 0;
    if (!newEntry.details) {
        newErrors = {
            ...newErrors,
            details: { error: true, message: ERROR_NO_TEXT }
        };
    } else if (
        newEntry.details.replace(HTML_REGEX, '').trim().length - signatureLength <
        MIN_LENGTH
    ) {
        newErrors = {
            ...newErrors,
            details: { error: true, message: ERROR_MIN_LENGTH }
        };
    } else {
        newErrors = { ...newErrors, details: initialErrorState.details };
    }
    return newErrors;
};

export const validateGroupSMS = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    newErrors = validateDetails(newEntry, newErrors);
    if (newErrors.details.error) isValid = false;

    return { isValid, newErrors };
};

export const validateGroupEmail = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.subject) {
        newErrors = {
            ...newErrors,
            subject: { error: true, message: ERROR_NO_SUBJECT }
        };
        isValid = false;
    } else if (newEntry.subject.trim().length < MIN_SUBJECT_LENGTH) {
        newErrors = {
            ...newErrors,
            subject: { error: true, message: ERROR_MIN_SUBJECT_LENGTH }
        };
        isValid = false;
    } else if (newEntry.subject.length > MAX_SUBJECT_LENGTH) {
        newErrors = {
            ...newErrors,
            subject: { error: true, message: ERROR_MAX_SUBJECT_SIZE }
        };
        isValid = false;
    } else {
        newErrors = { ...newErrors, subject: initialErrorState.subject };
    }

    newErrors = validateDetails(newEntry, newErrors);
    if (newErrors.details.error) isValid = false;

    if (new Blob([newEntry.details]).size > MAX_EMAIL_BODY) {
        newErrors = {
            ...newErrors,
            details: { error: true, message: ERROR_MAX_CONTENT_SIZE }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};

export const validateName = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.name) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_NO_NAME }
        };
        isValid = false;
    } else if (newEntry.name.trim().length < MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_MIN_LENGTH }
        };
        isValid = false;
    } else if (newEntry.name.length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_MAX_LENGTH }
        };
        isValid = false;
    } else {
        newErrors = { ...newErrors, name: initialErrorState.name };
    }

    return { isValid, newErrors };
};
