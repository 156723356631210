import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Card, CardContent, Typography } from '@mui/material';

import { POLARIS_VICTORIA_BLUE_BG, SCREEN_SETTINGS } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../cardStyles/cardView.module.css';

/**
 * Viewer for smart target card
 * @param module Data for this viewer. This has values which match the
 * module-template table in the course service
 * @returns {JSX.Element}
 */

const ModuleTemplateView = ({ module: { name, description, smartTarget } }) => {
    const { barrierNameDetails } = useSelector((state) => state.entities.directusService);

    return (
        <div className={classes.cardView}>
            <Card
                elevation={3}
                sx={{
                    backgroundColor: POLARIS_VICTORIA_BLUE_BG,
                    width: 250,
                    height: 'calc(100% - 16px)',
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <div className={`${name?.length > 60 ? classes.bannerDoubleRow : classes.banner}`}>
                    <Typography variant="h6">{name || 'Module Template'}</Typography>
                </div>
                <CardContent sx={{ padding: 1 }}>
                    <div>
                        <p>
                            <span className={classes.label}>Module Description</span>
                            {description.length > 100
                                ? `${description.slice(0, 97)}...`
                                : description}
                        </p>
                        {smartTarget && (
                            <>
                                <p>
                                    <span className={classes.label}>SMART Target Name</span>
                                    {smartTarget.name}
                                </p>

                                {smartTarget.barrierNameId && (
                                    <p>
                                        <span className={classes.label}>SMART Target Barrier</span>
                                        {getNameFromId(
                                            barrierNameDetails,
                                            smartTarget.barrierNameId
                                        )}
                                    </p>
                                )}
                                {smartTarget.action.length && (
                                    <p>
                                        <span className={classes.label}>SMART Target Action</span>
                                        {smartTarget.action.length > 200
                                            ? `${smartTarget.action.slice(0, 197)}...`
                                            : smartTarget.action}
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

ModuleTemplateView.propTypes = {
    module: PropTypes.object.isRequired
};

export default ModuleTemplateView;
