import { checkExists, getUserURL } from '../../api/commonHTTP';

const MIN_NAME_CHAR = 1;
const MAX_NAME_CHAR = 100;
const MAX_COST_CODE_CHAR = 15;

export const NAME_MIN_ERROR = `Team name must be at least ${MIN_NAME_CHAR} character`;
export const NAME_MAX_ERROR = `Team name must be ${MAX_NAME_CHAR} characters or shorter`;
export const NO_SERVICE_ID_ERROR = 'Please select a service name.';
export const COST_CODE_MAX_ERROR = `Cost Code must be ${MAX_COST_CODE_CHAR} characters or shorter`;

export const initialErrorState = {
    name: { error: false, message: '' },
    serviceId: { error: false, message: '' },
    costCode: { error: false, message: '' }
};

export const validate = (newEntry) => {
    let errors = {};

    if (!newEntry.name || newEntry.name.trim().length < MIN_NAME_CHAR) {
        errors = {
            ...errors,
            name: { error: true, message: NAME_MIN_ERROR }
        };
    } else if (newEntry.name.trim().length > MAX_NAME_CHAR) {
        errors = {
            ...errors,
            name: { error: true, message: NAME_MAX_ERROR }
        };
    }
    if (!newEntry.serviceId) {
        errors = {
            ...errors,
            serviceId: { error: true, message: NO_SERVICE_ID_ERROR }
        };
    }
    if (newEntry.costCode?.trim().length > MAX_COST_CODE_CHAR) {
        errors = {
            ...errors,
            costCode: { error: true, message: COST_CODE_MAX_ERROR }
        };
    }

    return { isValid: Object.keys(errors).length === 0, errors };
};

export const checkTeamName = async (teamName) => {
    return await checkExists(getUserURL() + `rest/team/exists/${teamName}`).then((ret) => ret);
};
