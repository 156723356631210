import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { addParticipantCsv, onClearUploadErrors } from '../../../store/documentService';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import Uploader from '../Uploader';

import BatchUploaderResults from './BatchUploaderResults';
import { initialErrorState, validate } from './validateBatchUploader';

import modal from '../../../commonStyles/modal.module.css';

const maxFileSize = 25;
const fileTypes = '.csv';
const acceptedFileTypes = ['text/csv'];
const multiple = true;
const batchName = 'BatchUploader';

const BatchUploaderModal = ({ contracts, onClose, onCancel, isOpen }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialErrorState);
    const [failures, setFailures] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [chosenContract, setChosenContract] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);

    const uploadErrorFiles = useSelector(
        (state) => state.entities.documentService.uploadErrorFiles
    );
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    useEffect(() => {
        const isValid = validate(chosenContract, errors);
        setIsDisabled(!isValid);
    }, [chosenContract]);

    useEffect(() => {
        if (Object.keys(uploadErrorFiles)?.length < 1) return;
        setFailures(uploadErrorFiles);
    }, [uploadErrorFiles]);

    const onUpload = (payload, successMsg) => {
        dispatch(addParticipantCsv(payload, successMsg));
    };
    const onLoaded = () => {
        setChosenContract({});
        setIsLoaded(true);
    };

    const onResultsClose = () => {
        dispatch(onClearUploadErrors());
        setErrors([]);
        setFailures([]);
        setIsLoaded(false);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth={true}>
            <div className={modal.modalHeader}>
                <DialogTitle>Batch Participant Uploader</DialogTitle>
            </div>
            <DialogContent>
                {!isLoaded ? (
                    <>
                        <div>
                            <DDLOptionPicker
                                label="Contract"
                                id="contractId"
                                mandatory={true}
                                menuItems={contracts || []}
                                chosenName={chosenContract?.name || ''}
                                chosenId={chosenContract?.id || ''}
                                onChange={(chosenId) => {
                                    clearError('contractId');
                                    const contract = contracts.find((el) => el.id === chosenId);
                                    setChosenContract(contract);
                                }}></DDLOptionPicker>
                        </div>
                        <Uploader
                            uploadData={[{ key: 'contractId', value: chosenContract?.id }]}
                            acceptedFileTypes={acceptedFileTypes}
                            fileTypes={fileTypes}
                            maxFileSize={maxFileSize}
                            onUpload={onUpload}
                            onClose={onLoaded}
                            onCancel={onCancel}
                            isDisabled={isDisabled}
                            multiple={multiple}
                        />
                    </>
                ) : (
                    <BatchUploaderResults
                        fileName={batchName}
                        failures={failures}
                        onClose={onResultsClose}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default BatchUploaderModal;

BatchUploaderModal.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
};
