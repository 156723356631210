import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import classes from '../../../../commonStyles/pdf.module.css';

const SignDetail = ({
    adviserName = '-',
    currentParticipant,
    confirmationText = '-',
    adviserSignature = '-',
    participantSignature = '-',
    signatureDateTime = '-',
    currentAppointment
}) => {
    const adviserSignatureData =
        adviserSignature && adviserSignature.slice(1 + adviserSignature.indexOf(','));
    const participantSignatureData =
        participantSignature && participantSignature.slice(1 + participantSignature.indexOf(','));
    const documentId =
        currentAppointment?.calendarAppointmentId?.slice(-9) || uuid().slice(-9) || '-';
    return (
        <>
            <div className={classes.statement}>
                <h3>Statement</h3>
                <p>{confirmationText}</p>
            </div>

            <div className={classes.signatureRow}>
                {adviserSignatureData && (
                    <div className={classes.signatureEntry}>
                        <h5>Signed:</h5>
                        <img
                            alt="adviser signature"
                            src={`data:image/png;base64,${adviserSignatureData}`}
                        />
                        <p>{adviserName}</p>
                    </div>
                )}
                {participantSignatureData && (
                    <div className={classes.signatureEntry}>
                        <h5>Signed:</h5>
                        <img
                            alt="participant signature"
                            src={`data:image/png;base64,${participantSignatureData}`}
                        />
                        <p>{`${currentParticipant.firstName} ${currentParticipant.lastName}`}</p>
                    </div>
                )}
            </div>

            <div className={classes.recordId}>
                <div className={classes.recordIdEntry}>Document ID {documentId}</div>
                <div className={classes.recordIdEntry}>{signatureDateTime}</div>
            </div>
        </>
    );
};

export default SignDetail;

SignDetail.propTypes = {
    currentParticipant: PropTypes.object,
    currentAppointment: PropTypes.object,
    participantSignature: PropTypes.string,
    adviserSignature: PropTypes.string,
    confirmationText: PropTypes.string,
    adviserName: PropTypes.string,
    signatureDateTime: PropTypes.string
};
