import { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    Stack,
    Typography
} from '@mui/material';

import {
    POLARIS_DARKER_GREY,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';

import ContactDetailsEditor from './ContactDetailsEditor';

/**
 * Viewer for contact details dards
 *
 * Uses ContactDetailsEditor when edit icon is clicked
 *
 * Calls the sendDelete callback when delete icon is clicked and this causes
 * CardHandler to delete this item.
 *
 * @param cardData Data for this viewer. This has values which match the
 *     additional-contact-card table in the participant service
 * @param sendDelete  Callback function to tell parent  to delete cardData.
 * @param sendUpdate  Callback function to tell parent to update cardData.
 * @param disabled  If true, disables control.
 *
 * @returns {JSX.Element}
 *
 */

const ContactDetailsViewer = ({ cardData, sendDelete, sendUpdate, disabled = false }) => {
    const [open, setOpen] = useState(false);

    const handleCancelDelete = () => {
        setOpen(false);
    };

    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;

    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_ROYAL_BLUE;

    const handleDelete = () => {
        if (disabled) {
            return;
        }
        setOpen(false);
        // Call onDelete in CardHandler.
        sendDelete(cardData);
    };

    /*
     * This is the callback when there is a change in the editor.
     */
    const handleChange = (data) => {
        // Update handler with new data.
        // This will cause a render of this component
        // which will show the new data.
        sendUpdate(data);
    };

    const getRelationship = () => {
        if (
            cardData.relationshipToParticipant === undefined ||
            cardData.relationshipToParticipant === null ||
            cardData.relationshipToParticipant.trim() === ''
        ) {
            return '';
        }
        return ' (' + cardData.relationshipToParticipant + ')';
    };

    const maxLineLength = 25;
    /**
     * Split text into lines which are no longer than maxLineLength
     *
     * This ignores any spaces in the text.
     *
     * @param text Text to split
     */
    const splitText = (text) => {
        if (text === undefined || text === null) {
            return [];
        }
        let lines = [];
        if (text.length > 25) {
            const element1 = text.substring(0, maxLineLength);
            const element2 = text.substring(maxLineLength, text.length);
            lines = [element1, element2];
        } else {
            lines.push(text.substring(0, text.length));
        }
        return lines;
    };

    /**
     * Get a summary of card information.
     *
     * IF the first name or relationship is not set, then return 'Incomplete Details'
     *
     * ELSE return any of the following (See PPL-231) which are not blank
     * first name
     * last name
     * relationship
     * First line of address
     * Postcode
     * Email
     * Phone
     *
     * @returns {JSX.Element}
     */
    const getCardContent = () => {
        if (
            cardData.relationshipToParticipant === undefined ||
            cardData.relationshipToParticipant === null ||
            cardData.relationshipToParticipant.trim() === '' ||
            cardData.firstName === undefined ||
            cardData.firstName === null ||
            cardData.firstName.trim() === ''
        ) {
            return (
                <>
                    {' '}
                    <Typography variant="body1" sx={{ color: POLARIS_DOUBLE_DECKER_RED }}>
                        Incomplete Contact Details
                    </Typography>
                </>
            );
        }

        return (
            <div style={{ width: '200 px' }}>
                {cardData.firstName} {cardData.lastName} {getRelationship()}
                <Typography variant="body1">{cardData.addressLine1}</Typography>
                <Typography noWrap variant="body1">
                    {cardData.postcode}
                </Typography>
                <Typography noWrap variant="body1">
                    {cardData.phoneNumber}
                </Typography>
                <Typography variant="body1">
                    {splitText(cardData.emailAddress).map((line) => {
                        return (
                            <span key={line}>
                                {line}
                                <br />
                            </span>
                        );
                    })}
                </Typography>
            </div>
        );
    };

    return (
        <>
            <Card
                elevation={3}
                sx={{
                    p: 0,
                    backgroundColor: POLARIS_WHITE,
                    width: 200,
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <CardHeader
                    title={<Typography variant="h6">Contact Details</Typography>}
                    sx={{
                        p: 0.8,
                        m: 0.8,
                        // background color is gray if disabled
                        backgroundColor: { backgroundColor },
                        color: POLARIS_WHITE
                    }}
                    action={
                        <Stack direction="row">
                            <ContactDetailsEditor
                                disabled={disabled}
                                sendChange={handleChange}
                                cardData={cardData}></ContactDetailsEditor>{' '}
                            <Icon
                                component={DeleteIcon}
                                disabled={disabled}
                                sx={{ color: iconColor }}
                                onClick={() => {
                                    if (disabled === false) setOpen(true);
                                }}
                            />
                        </Stack>
                    }></CardHeader>

                <CardContent sx={{ padding: 1 }}>{getCardContent()}</CardContent>
            </Card>

            <Dialog open={open} onClose={handleCancelDelete}>
                <DialogTitle id="alert-dialog-title">{`Delete Contact Details?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete these contact details?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={handleCancelDelete}>
                        No
                    </Button>
                    <Button variant={'contained'} onClick={handleDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

ContactDetailsViewer.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendDelete: PropTypes.func.isRequired,
    sendUpdate: PropTypes.func.isRequired
};

export default ContactDetailsViewer;
