import { isDateAfter, isDateEarlier } from '../../../../utils/dateFunctions';
import { getSelectValueMessage } from '../../../../utils/formValidation/errorMessageFunctions';

const DATE_LATER_THAN_QUIT_DATE = 'Date set must be before quit date';
const DATE_EARLIER_THAN_SET_DATE = 'Quit date must be later than date set';
const NO_VALUE_SELECTED = getSelectValueMessage();

export const initialErrorState = {
    dateSet: { error: false, message: '' },
    quitDate: { error: false, message: '' },
    baselineCoMeasurementId: { error: false, message: '' },
    usingECigaretteId: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.baselineCoMeasurementId) {
        newErrors = {
            ...newErrors,
            baselineCoMeasurementId: { error: true, message: NO_VALUE_SELECTED }
        };
        isValid = false;
    }
    if (!newEntry.usingECigaretteId) {
        newErrors = {
            ...newErrors,
            usingECigaretteId: { error: true, message: NO_VALUE_SELECTED }
        };
        isValid = false;
    }

    newErrors = checkDateSet(newEntry.dateSet, newEntry.quitDate, newErrors);
    if (newErrors.dateSet.error) isValid = false;

    newErrors = checkQuitDate(newEntry.quitDate, newEntry.dateSet, newErrors);
    if (newErrors.quitDate.error) isValid = false;

    return { isValid, newErrors };
};

export const checkQuitDate = (quitDate, dateSet, errors) => {
    if (!dateSet) return errors;
    return isDateAfter(dateSet, quitDate)
        ? {
              ...errors,
              quitDate: { error: true, message: DATE_EARLIER_THAN_SET_DATE },
              dateSet: { error: true, message: DATE_LATER_THAN_QUIT_DATE }
          }
        : {
              ...errors,
              quitDate: initialErrorState.quitDate,
              dateSet: initialErrorState.dateSet
          };
};

export const checkDateSet = (dateSet, quitDate, errors) => {
    if (!quitDate) return errors;
    return isDateEarlier(dateSet, quitDate)
        ? {
              ...errors,
              dateSet: { error: true, message: DATE_LATER_THAN_QUIT_DATE },
              quitDate: { error: true, message: DATE_EARLIER_THAN_SET_DATE }
          }
        : {
              ...errors,
              dateSet: initialErrorState.dateSet,
              quitDate: initialErrorState.quitDate
          };
};

export const checkIsUpdated = (row, payload) => {
    return (
        row.baselineCoMeasurementId !== payload.baselineCoMeasurementId ||
        row.usingECigaretteId !== payload.usingECigaretteId ||
        row.dateSet !== payload.dateSet ||
        row.entitledToFreePrescription !== payload.entitledToFreePrescription ||
        row.participantConfirmed !== payload.participantConfirmed ||
        row.pregnantAtQuitDate !== payload.pregnantAtQuitDate ||
        row.quitDate !== payload.quitDate ||
        row.quitStatus !== payload.quitStatus
    );
};
