import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { selectWellbeingTypes } from '../../store/dataSelectors';
import { loadWellbeingDetails } from '../../store/directusService';
import { selectUserRoles } from '../../store/userSelectors';
import { updatePreSelects, updateSelectedItems } from '../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import NotFound from '../notFound/NotFound';
import LoadingSpinner from '../ui/LoadingSpinner';
import MultiSelect from '../ui/pickers/MultiSelect';

const WellbeingAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const submitMessage = 'wellbeing';
    const [selectedWellbeingTypes, setSelectedWellbeingTypes] = useState([]);
    const [preSelectedWellbeingTypes, setPreSelectedWellbeingTypes] = useState([]);
    const [preSelectedWellbeingTypeIds, setPreSelectedWellbeingTypeIds] = useState([]);
    const [arrayWellbeingTypes, setArrayWellbeingTypes] = useState([]);
    const [isClearSelectedWellbeingTypes, setIsClearSelectedWellbeingTypes] = useState('1');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const wellbeingTypes = useSelector(selectWellbeingTypes);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    const contractSetup = () => {
        setSelectedWellbeingTypes([]);
        setPreSelectedWellbeingTypes([]);
        setPreSelectedWellbeingTypeIds([]);
        setArrayWellbeingTypes([]);
        setIsClearSelectedWellbeingTypes(Math.random());
        dispatch(loadWellbeingDetails());
    };

    // USEEFFECT FNS

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (wellbeingTypes?.length && arrayWellbeingTypes?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'wellbeing',
                wellbeingTypes,
                contractId
            );
            setPreSelectedWellbeingTypes(preSelects);
            setPreSelectedWellbeingTypeIds(preSelectIds);
            setArrayWellbeingTypes(wellbeingTypes);
        }
    }, [wellbeingTypes]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${submitMessage} collection`)) {
            contractSetup();
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onWellbeingChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedWellbeingTypes(chosenIds);
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        updateSelectedItems(
            'wellbeing',
            wellbeingTypes,
            selectedWellbeingTypes,
            contractId,
            submitMessage,
            dispatch
        );
    };

    // RENDER
    let content = '';
    if (wellbeingTypes?.length < 1) content = 'No wellbeing types';
    if (!contractId) content = 'No contract Id';

    if (!hasRole([SUPERUSER, LOCAL_ADMIN], roles)) return <NotFound />;

    if (!contractId || wellbeingTypes?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <form onSubmit={onSubmit}>
            <MultiSelect
                id="wellbeing"
                key={isClearSelectedWellbeingTypes}
                disabled={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}
                data-testid="wellbeing"
                label="Wellbeing"
                placeholder="Wellbeing"
                menuItems={wellbeingTypes || []}
                preSelectedIds={preSelectedWellbeingTypeIds}
                preSelects={preSelectedWellbeingTypes}
                onChange={(chosenIds) => onWellbeingChange(chosenIds)}
            />

            <div style={{ marginTop: '-24px' }}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="testIdSubmitButton">
                    {'UPDATE WELLBEING'}
                </Button>
            </div>
        </form>
    );
};

WellbeingAdmin.propTypes = {
    contractId: PropTypes.string
};

export default WellbeingAdmin;
