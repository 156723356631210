import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    deleteGroupCommunication,
    loadGroupCommunications,
    onDeleteGroupCommunication
} from '../../../../store/communicationService';
import { loadParticipantStatusDetails } from '../../../../store/directusService';
import { clearParticipantsSearch } from '../../../../store/participantService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { loadUsersForComms } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import CommunicationGroupsTable from './CommunicationGroupsTable';
import CreateCommunicationGroup from './CreateCommunicationGroup';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';

const ManageCommunicationGroups = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Group communication deleted`;

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const roles = loggedInUser.roles;
    const groupCommunications = useSelector(
        (state) => state.entities.communicationService.groupCommunications
    );
    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadGroupCommunications());
        dispatch(clearParticipantsSearch());
        participantStatusDetails?.length < 1 && dispatch(loadParticipantStatusDetails());
    }, []);

    useEffect(() => {
        if (loggedInUser?.serviceIds?.length < 1) return;
        dispatch(loadUsersForComms(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!groupCommunications) return;
        const rows =
            groupCommunications?.length < 1
                ? []
                : groupCommunications
                      .filter((el) => !el.inactive)
                      .map((el) => ({
                          ...el,
                          groupSize: el.participants.length
                      }));
        setRows(rows);
    }, [groupCommunications]);

    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeleteGroupCommunication(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteGroupCommunication(row.id, deleteMsg));
    };

    const onCreateGroupClose = () => setShowForm(false);

    return (
        <div className={app.container}>
            <div className={app.mainHeading}>Manage Groups</div>
            <div className={app.blueContainer}>
                {rows.length < 1 ? null : (
                    <div>
                        <CommunicationGroupsTable rows={rows} roles={roles} onDelete={onDelete} />
                    </div>
                )}

                <div className={form.formEnd}>
                    {!showForm && (
                        <Button
                            disabled={!hasRole(acceptedRoles, roles)}
                            color="primary"
                            variant="contained"
                            size={'small'}
                            onClick={onCreate}>
                            Create New Group
                            <Box width={SCREEN_SETTINGS.gap.medium} />
                            <AddCircleOutlineIcon />
                        </Button>
                    )}
                    <div>
                        {showForm && formType === 'create' && (
                            <CreateCommunicationGroup onClose={onCreateGroupClose} roles={roles} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageCommunicationGroups;
