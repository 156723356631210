import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Checkbox, TableCell, TableRow } from '@mui/material';

import { loadPoolStatusDetails } from '../../../../store/directusService';
import { loadParticipantByPtCode } from '../../../../store/participantService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import { hasRole } from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';
import submissionClasses from '../../recruitmentStyles/submission.module.css';

const SubmissionTableRow = ({ row, roles, onRowChange }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    function onEditParticipant() {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            navigate('/edit_participant');
        }
    }

    const onCheck = (e) => {
        const { checked } = e.target;
        const updatedRow = {
            ...row,
            update: checked
        };
        onRowChange(updatedRow);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadPoolStatusDetails());
    }, []);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}>
                <TableCell
                    className={submissionClasses.ptCodeLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(roles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell>{row.ptName}</TableCell>
                <TableCell>{row.filename}</TableCell>
                <TableCell>{row.jobSectorName}</TableCell>
                <TableCell disabled={!hasRole(roles, loggedInUser.roles)}>
                    <div className={submissionClasses.check}>
                        <Checkbox
                            key={row.id}
                            type="checkbox"
                            sx={{ color: POLARIS_ROYAL_BLUE }}
                            checked={row.update}
                            onClick={onCheck}
                        />
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SubmissionTableRow;

SubmissionTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onRowChange: PropTypes.func
};
