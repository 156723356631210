// COMMON FIELDS TO BUILD VALIDATIONS
import { charVal, names, regExps } from './validationPatterns';

export const commonFieldValidations = {
    address1: {
        subject: `${names.ADDRESS} 1`,
        charMax: charVal.MAX_ADDRESS
    },
    address2: {
        subject: `${names.ADDRESS} 2`,
        charMax: charVal.MAX_ADDRESS
    },
    address3: {
        subject: `${names.ADDRESS} 3`,
        charMax: charVal.MAX_ADDRESS
    },
    address4: {
        subject: `${names.ADDRESS} 4`,
        charMax: charVal.MAX_ADDRESS
    },
    businessName: {
        subject: names.BUSINESS_NAME,
        charMin: charVal.MIN_BUSINESS_NAME,
        charMax: charVal.MAX_BUSINESS_NAME,
        duplicate: 'businessNames'
    },
    businessSectorId: {
        subject: names.BUSINESS_SECTOR_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    businessOwnerId: {
        subject: names.BUSINESS_OWNER_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    businessSize: {
        subject: names.BUSINESS_SIZE
    },
    city: {
        subject: names.CITY,
        charMax: charVal.MAX_CITY
    },
    contractId: {
        subject: names.CONTRACT_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    county: {
        subject: names.COUNTY,
        charMax: charVal.MAX_COUNTY
    },
    emailAddress: {
        subject: names.EMAIL,
        charMin: charVal.MIN_EMAIL,
        charMax: charVal.MAX_EMAIL,
        format: regExps.EMAIL_R
    },
    firstName: {
        subject: names.FIRST_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.NAME_R
    },
    lastName: {
        subject: names.LAST_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.LAST_NAME_R
    },
    middleName: {
        subject: names.MIDDLE_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.NAME_R
    },
    postcode: {
        subject: names.POSTCODE,
        format: regExps.POSTCODE_R
    },
    preferredName: {
        subject: names.PREFERRED_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.NAME_R
    },
    primaryPhoneNumber: {
        subject: names.PHONE,
        charMin: charVal.MIN_PHONE,
        charMax: charVal.MAX_PHONE,
        format: regExps.PHONE_R
    },
    repeatUniqueIdentifier: {
        subject: 'Repeat Unique Identifier',
        conditional: [
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NINO',
                subject: `Repeat ${names.NINO}`,
                matches: { sub: 'uniqueIdentifier', name: names.NINO }
            },
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NHS_NUMBER',
                subject: `Repeat ${names.NHS_NUMBER}`,
                matches: { sub: 'uniqueIdentifier', name: names.NHS_NUMBER }
            }
        ],
        required: 'text'
    },
    serviceId: {
        subject: names.SERVICE_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    teamId: {
        subject: names.TEAM_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    uniqueIdentifier: {
        subject: 'Unique Identifier',
        required: 'text',
        conditional: [
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NINO',
                subject: names.NINO,
                format: regExps.NINO_R
            },
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NHS_NUMBER',
                subject: names.NHS_NUMBER,
                format: regExps.NUMBERS_R,
                length: charVal.NHS_LENGTH,
                nhsChecksum: true
            }
        ]
    },
    website: {
        subject: names.WEBSITE_URL,
        format: regExps.WEBSITE_R
    }
};
