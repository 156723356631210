import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

import './searchStyles/autoCompleteNameSearch.css';

const AutoCompleteNameSearch = ({
    id,
    resultLength,
    list,
    placeholder,
    onSearch,
    disabled = false,
    customStyle
}) => {
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        if (!list || list.length < 1) return;
        let newList;
        if ('name' in list[0]) newList = list;
        else
            newList = list.map((el, i, arr) => {
                if ('firstName' in arr[i] && 'lastName' in arr[i]) {
                    return {
                        ...el,
                        name: `${el.firstName} ${el.lastName}`
                    };
                }
            });
        const sortedList = newList.sort((a, b) => (a.name > b.name ? 1 : -1));
        setSearchList(sortedList);
    }, [list]);

    const filterOptions = (options, state) => {
        const defaultFilterOptions = createFilterOptions();
        return defaultFilterOptions(options, state).slice(0, resultLength);
    };

    return (
        <div className={`auto-search-input ${customStyle}`}>
            <Autocomplete
                disablePortal
                id={id}
                filterOptions={filterOptions}
                options={searchList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) =>
                    `${option.name} ${option?.ptCode ? ` - ${option.ptCode}` : ''}`
                }
                renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
                classes={{ listbox: 'menu-list' }}
                onChange={onSearch}
                disabled={disabled}
            />
        </div>
    );
};
export default AutoCompleteNameSearch;

AutoCompleteNameSearch.propTypes = {
    id: PropTypes.string,
    resultLength: PropTypes.number,
    list: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
    disabled: PropTypes.bool,
    customStyle: PropTypes.string
};
