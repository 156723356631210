import { Box } from '@mui/material';

import { POLARIS_GREY, POLARIS_WHITE, SCREEN_SETTINGS } from '../../themes/theme';

const HeaderRibbon = () => {
    return (
        <div>
            <Box
                sx={{
                    height: '2px',
                    padding: 0,
                    marginLeft: SCREEN_SETTINGS.margin.large,
                    marginRight: SCREEN_SETTINGS.margin.large,
                    backgroundColor: POLARIS_GREY
                }}
            />
            <Box
                sx={{
                    height: '4px',
                    padding: 0,
                    marginLeft: SCREEN_SETTINGS.margin.large,
                    marginRight: SCREEN_SETTINGS.margin.large,
                    backgroundColor: POLARIS_WHITE
                }}
            />
        </div>
    );
};
export default HeaderRibbon;
