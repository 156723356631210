import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectUsers } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { formatDateTime } from '../../../utils/dateFunctions';

const FormStatusHistory = ({ history }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const users = useSelector(selectUsers);

    useEffect(() => {
        if (history.userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === history.userId);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(history.userId));
            } else dispatch(loadUser(history.userId));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === history.userId);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    // RENDER
    return (
        <div>
            <div>
                <span>
                    {history.action} By: {emailAddress} {formatDateTime(history.date)}{' '}
                </span>
            </div>
        </div>
    );
};

export default FormStatusHistory;

FormStatusHistory.propTypes = {
    history: PropTypes.object
};
