import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadCommunicationTemplates } from '../../../store/communicationService';
import { loadFormStatusDetails, loadFormTypes } from '../../../store/directusService';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { loadFormContractByContract, loadForms } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { loadUser, loadUsersByServiceIds } from '../../../store/userService';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ConductExitForm from './ConductExitForm';

import classes from './formStyles/form.module.css';

const Form = () => {
    // HOOKS
    const surveyRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    // LOCAL STATE

    const [formId, setFormId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const formStatusDetails = useSelector(
        (state) => state.entities.directusService.formStatusDetails
    );

    // EVENT HANDLERS

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadCommunicationTemplates());
        dispatch(loadFormTypes());
        dispatch(loadFormStatusDetails());
        dispatch(loadForms());
        dispatch(loadFormContractByContract(currentParticipant.contractId));
    }, []);

    useEffect(() => {
        if (state && 'formId' in state) {
            setFormId(state.formId);
        }
    }, [state]);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser)?.length < 1) return;
        dispatch(loadUser(loggedInUser.id));
        if ('serviceIds' in loggedInUser)
            loggedInUser.serviceIds.length > 0 &&
                dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    const onComplete = () => {
        navigate('/edit_participant');
    };

    // RENDER
    let content = '';
    if (formStatusDetails?.length < 1) content = 'No form status details';
    if (formStatusDetails?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <div className={classes.forms}>
            <div ref={surveyRef}>
                <ConductExitForm formId={formId} onClose={onComplete} roles={loggedInUser.roles} />
            </div>
        </div>
    );
};

export default Form;
