import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearDisability,
    clearMentalHealth,
    clearModStatus,
    loadDisabilityDetails,
    loadMentalHealthDetails,
    loadModStatusDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import { updatePreSelects, updateSelectedItems } from '../../../utils/directusFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

// Note! This code uses 'console.log' for logging and 'alert' to tell the user about successes/errors.
// Replace these when logging and notifications are implemented.

/**
 * CircumstancesAdmin component.
 *
 * This updates the participant information details DDL for disability & mental health
 *
 * When the form is submitted onSubmit is called. This validates that DDLs are not blank.
 *
 */

const CircumstancesAdmin = ({ contractId }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        disabilities: false,
        mentalHealth: false,
        modStatus: false
    };

    const [selectedDisabilities, setSelectedDisabilities] = useState([]);
    const [selectedMentalHealth, setSelectedMentalHealth] = useState([]);
    const [selectedModStatus, setSelectedModStatus] = useState([]);

    const [preSelectedDisabilitiesIds, setPreSelectedDisabilitiesIds] = useState([]);
    const [preSelectedDisabilities, setPreSelectedDisabilities] = useState([]);
    const [preSelectedMentalHealthIds, setPreSelectedMentalHealthIds] = useState([]);
    const [preSelectedMentalHealth, setPreSelectedMentalHealth] = useState([]);
    const [preSelectedModStatusIds, setPreSelectedModStatusIds] = useState([]);
    const [preSelectedModStatus, setPreSelectedModStatus] = useState([]);

    const [arrayDisabilities, setArrayDisabilities] = useState([]);
    const [arrayMentalHealth, setArrayMentalHealth] = useState([]);
    const [arrayModStatus, setArrayModStatus] = useState([]);

    const [keys, setKeys] = useState({ disabilities: '1', mentalHealth: '2', modStatus: '3' });

    const [isChanged, setIsChanged] = useState(initialState);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

    const submitMessages = {
        disabilities: 'Disabilities updated',
        mentalHealth: 'Mental health updated',
        modStatus: 'MOD statuses updated'
    };

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const disabilityDetails = useSelector(
        (state) => state.entities.directusService.disabilityDetails
    );
    const mentalHealthDetails = useSelector(
        (state) => state.entities.directusService.mentalHealthDetails
    );
    const modStatusDetails = useSelector(
        (state) => state.entities.directusService.modStatusDetails
    );

    // USE EFFECTS
    useEffect(() => {
        if (contractId !== '') contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (disabilityDetails?.length && arrayDisabilities?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'disability',
                disabilityDetails,
                contractId
            );
            setPreSelectedDisabilities(preSelects);
            setPreSelectedDisabilitiesIds(preSelectIds);
            setArrayDisabilities(disabilityDetails);
        }
    }, [disabilityDetails]);

    useEffect(() => {
        if (mentalHealthDetails?.length && arrayMentalHealth.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'mental_health',
                mentalHealthDetails,
                contractId
            );
            setPreSelectedMentalHealth(preSelects);
            setPreSelectedMentalHealthIds(preSelectIds);
            setArrayMentalHealth(mentalHealthDetails);
        }
    }, [mentalHealthDetails]);

    useEffect(() => {
        if (modStatusDetails?.length && arrayModStatus?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'mod_status',
                modStatusDetails,
                contractId
            );
            setPreSelectedModStatus(preSelects);
            setPreSelectedModStatusIds(preSelectIds);
            setArrayModStatus(modStatusDetails);
        }
    }, [modStatusDetails]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${submitMessages.disabilities} collection`)) {
            setSelectedDisabilities([]);
            setPreSelectedDisabilitiesIds([]);
            setArrayDisabilities([]);
            setKeys((prev) => ({ ...prev, disabilities: Math.random() }));
            dispatch(clearDisability());
            dispatch(loadDisabilityDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.mentalHealth} collection`)) {
            setSelectedMentalHealth([]);
            setPreSelectedMentalHealthIds([]);
            setArrayMentalHealth([]);
            setKeys((prev) => ({ ...prev, mentalHealth: Math.random() }));
            dispatch(clearMentalHealth());
            dispatch(loadMentalHealthDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.modStatus} collection`)) {
            setSelectedModStatus([]);
            setPreSelectedModStatusIds([]);
            setArrayModStatus([]);
            setKeys((prev) => ({ ...prev, modStatus: Math.random() }));
            dispatch(clearModStatus());
            dispatch(loadModStatusDetails());
        }
        setIsSubmitButtonDisabled(false);
    }, [successMessage]);

    // HELPER FNS
    const contractSetup = () => {
        // clearing local state
        setSelectedDisabilities([]);
        setSelectedMentalHealth([]);
        setSelectedModStatus([]);

        setPreSelectedDisabilitiesIds([]);
        setPreSelectedMentalHealthIds([]);
        setPreSelectedModStatusIds([]);

        setArrayDisabilities([]);
        setArrayMentalHealth([]);
        setArrayModStatus([]);

        setKeys(clearKeys(keys));
        // fetch new state - does it really need to be cleared first?
        dispatch(clearDisability());
        dispatch(clearMentalHealth());
        dispatch(clearModStatus());
        dispatch(loadDisabilityDetails());
        dispatch(loadMentalHealthDetails());
        dispatch(loadModStatusDetails());
    };

    // EVENT HANDLERS
    const onFieldChange = (field, selectedFieldSetter, preSelectedFieldValues) => (chosenIds) => {
        selectedFieldSetter(chosenIds || []);
        if (chosenIds.length !== preSelectedFieldValues.length)
            setIsChanged((prev) => ({ ...prev, [field]: true }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!selectedDisabilities.length) {
            dispatch(setErrorMessage('No disability selected'));
        } else if (!selectedMentalHealth.length) {
            dispatch(setErrorMessage('No mental health selected'));
        } else {
            setIsSubmitButtonDisabled(true);
            isChanged.disabilities &&
                updateSelectedItems(
                    'disability',
                    disabilityDetails,
                    selectedDisabilities,
                    contractId,
                    submitMessages.disabilities,
                    dispatch
                );
            isChanged.mentalHealth &&
                updateSelectedItems(
                    'mental_health',
                    mentalHealthDetails,
                    selectedMentalHealth,
                    contractId,
                    submitMessages.mentalHealth,
                    dispatch
                );

            isChanged.modStatus &&
                updateSelectedItems(
                    'mod_status',
                    modStatusDetails,
                    selectedModStatus,
                    contractId,
                    submitMessages.modStatus,
                    dispatch
                );
        }
    };

    // RENDER
    let content = '';
    if (!contractId) content = 'No Contract Id';
    if (disabilityDetails?.length < 1) content = 'No disability details';
    if (mentalHealthDetails?.length < 1) content = 'No mental health details';
    if (modStatusDetails?.length < 1) content = 'No MOD status';

    if (!hasRole([SUPERUSER, LOCAL_ADMIN], roles)) return <NotFound />;

    if (
        !contractId ||
        disabilityDetails?.length < 1 ||
        mentalHealthDetails?.length < 1 ||
        modStatusDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <form onSubmit={onSubmit} data-testid="form_start">
            <p>Disability</p>
            <MultiSelect
                id="disabilities"
                key={keys.disabilities}
                label="Disability"
                placeholder="Disability"
                disabled={!hasRole([SUPERUSER], roles)}
                menuItems={disabilityDetails || []}
                preSelectedIds={preSelectedDisabilitiesIds}
                preSelects={preSelectedDisabilities}
                onChange={onFieldChange(
                    'disabilities',
                    setSelectedDisabilities,
                    preSelectedDisabilitiesIds
                )}
            />
            <p>Health/Mental Health Problems</p>
            <MultiSelect
                id="mentalHealth"
                key={keys.mentalHealth}
                label="Mental Health"
                placeholder="Mental Health"
                disabled={!hasRole([SUPERUSER], roles)}
                menuItems={mentalHealthDetails || []}
                preSelectedIds={preSelectedMentalHealthIds}
                preSelects={preSelectedMentalHealth}
                onChange={onFieldChange(
                    'mentalHealth',
                    setSelectedMentalHealth,
                    preSelectedMentalHealthIds
                )}
            />
            <p>Are you part of the Armed Forces Community?</p>
            <MultiSelect
                id="modStatus"
                key={keys.modStatus}
                label="MOD Status"
                placeholder="MOD Status"
                disabled={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}
                menuItems={modStatusDetails || []}
                preSelectedIds={preSelectedModStatusIds}
                preSelects={preSelectedModStatus}
                onChange={onFieldChange('modStatus', setSelectedModStatus, preSelectedModStatusIds)}
            />
            <p>Health Comment</p>
            <p>Criminal Record</p>
            <p>Criminal Record Comment</p>
            <p>Criminal Conviction Spent</p>
            <p>Required Adjustments</p>
            <p>Additional Contact</p>

            <Button
                id={'updateCircumstances'}
                content={'UPDATE CIRCUMSTANCES'}
                disabled={isSubmitButtonDisabled}
            />
        </form>
    );
};

CircumstancesAdmin.propTypes = {
    contractId: PropTypes.string
};

export default CircumstancesAdmin;
