import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
    loadClaimEventStatuses,
    loadClaimEventStatusReasons,
    loadClaimEventTypes,
    loadContractDetails
} from '../../store/directusService';
import { selectLoggedInUser } from '../../store/userSelectors';
import { loadUsersByServiceIds } from '../../store/userService';
import { PRAP, QUALITY, SUPERUSER } from '../../utils/userRoles';
import LoadingSpinner from '../ui/LoadingSpinner';

import ClaimTableManagement from './claim/ClaimTableManagement';
import ValidationTableManagement from './validation/ValidationTableManagement';

import local from './claimStyles/claimQueues.module.css';

const Claims = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [tab, setTab] = useState(0);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { claimEventStatuses, claimEventStatusReasons, claimEventTypes, contractDetails } =
        useSelector((state) => state.entities.directusService);

    const onChange = (e, tab) => {
        setTab(tab);
    };

    useEffect(() => {
        dispatch(loadClaimEventStatuses());
        dispatch(loadClaimEventStatusReasons());
        dispatch(loadClaimEventTypes());
        dispatch(loadContractDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;

        loggedInUser?.serviceIds?.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));

        if (loggedInUser.roles.includes(QUALITY) || loggedInUser.roles.includes(SUPERUSER)) {
            setTab(0);
        } else if (loggedInUser.roles.includes(PRAP)) {
            setTab(1);
        }
    }, [loggedInUser.id]);

    // RENDER
    let content = '';
    if (claimEventStatuses?.length < 1) content = 'No claim event statuses';
    if (claimEventStatusReasons?.length < 1) content = 'No claim event status reasons';
    if (claimEventTypes?.length < 1) content = 'No claim event types';
    if (contractDetails?.length < 1) content = 'No contract details';
    if (
        claimEventStatuses?.length < 1 ||
        claimEventStatusReasons?.length < 1 ||
        claimEventTypes?.length < 1 ||
        contractDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <div className={local.claimQueues}>
            <div className={local.navBar}>
                <Tabs value={tab} onChange={onChange} aria-label="icon tabs example">
                    <Tab
                        icon={<FactCheckIcon />}
                        iconPosition="end"
                        label="Validations"
                        aria-label="validations"
                        className={local.tab}
                    />
                    <Tab
                        icon={<AccountBalanceIcon />}
                        iconPosition="end"
                        label="Claims"
                        aria-label="claims"
                        className={local.tab}
                    />
                </Tabs>
            </div>
            <div className={local.claimsContainer}>
                {tab === 0 && <ValidationTableManagement />}
                {tab === 1 && <ClaimTableManagement />}
            </div>
        </div>
    );
};

export default Claims;
