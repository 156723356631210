import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();

const connectionStringEnv =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING;

const connectionString =
    connectionStringEnv !== '' ? connectionStringEnv : 'InstrumentationKey=blank';

const appInsights = new ApplicationInsights({
    config: {
        connectionString: connectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        // disable telemetry for local installs
        disableTelemetry: connectionStringEnv === ''
        /* ...Other Configuration Options... */
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export { appInsights, reactPlugin };
