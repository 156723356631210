import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { clearBarrierName, loadBarrierNameDetailsByContract } from '../../../store/directusService';
import {
    loadParticipantBarriers,
    updateParticipantBarrier
} from '../../../store/participantService';
import {
    POLARIS_DARKER_GREY,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_ROYAL_BLUE,
    POLARIS_VICTORIA_BLUE_BG,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getConfiguredItems, getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import BusyIndicator from '../../ui/BusyIndicator';

import BarrierEditor from './BarrierEditor';

/**
 * Participant Barriers. See PPL-353 and PPL-382
 *
 * Overview
 *
 * This shows a participant's barriers as a table with a row for each barrier. (PPL-353)
 *
 * Clicking on the edit icon on any row shows an editor (PLL=382) for that row.
 *
 * Clicking on the [Add New Barrier] button shows BarrierEditor for the new barrier.
 *
 * The editor uses the data in newEntry. This is filled with blank data when the [Add New Barrier]
 * button is pressed and with the row data when an edit icon is pressed.
 *
 * The editor is shown when the showEditor flag is set to true.
 *
 * The editor is closed when the 'Cancel X' link is pressed.
 *
 * @returns {JSX.Element}
 *
 */

const Barriers = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [newEntry, setNewEntry] = useState({
        participantId: '',
        id: '',
        barrierId: null,
        startDate: null,
        dueDate: null,
        priority: null
    });

    const [showBarrierEditor, setShowBarrierEditor] = useState(false);
    const [arrayBarrierNames, setArrayBarrierNames] = useState([]);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { currentParticipant, currentParticipantBarriers } = useSelector(
        (state) => state.entities.participantService
    );
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    /**
     * Utility function to check if the current user has a suitable role to edit a barrier.
     * @returns {boolean} - true if the user has a suitable role.
     */
    const hasSuitableRole = () => {
        return hasRole(roles, [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER]);
    };

    useEffect(() => {
        if (barrierNameDetails?.length && arrayBarrierNames.length === 0) {
            setArrayBarrierNames(
                getConfiguredItems(barrierNameDetails, currentParticipant.contractId)
            );
        }
    }, [barrierNameDetails]);

    useEffect(() => {
        if (Object.keys(currentParticipant).length) {
            setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
            dispatch(loadParticipantBarriers(currentParticipant.id));

            // Don't show barrier editor when participant changes.
            setShowBarrierEditor(false);
            dispatch(clearBarrierName());
            dispatch(loadBarrierNameDetailsByContract(currentParticipant.contractId));
        }
    }, [currentParticipant.id]);

    /**
     * barrierNameDetails has changed so check to see if we can populate fields.
     */

    /*
     * Add a blank barrier and show editor
     */
    const addBlankBarrier = () => {
        if (showBarrierEditor) {
            return;
        }

        // Get date as iso string without time.
        const currentDate = new Date().toISOString().slice(0, 10);

        // Set the barrier to blank data
        // Use blank information for a new barrier
        setNewEntry({
            participantId: currentParticipant.id,
            id: null,
            barrierId: null,
            startDate: currentDate,
            dueDate: currentDate,
            completeDate: '',
            priority: ''
        });
        setShowBarrierEditor(true);
    };

    /**
     * Edit selected barrier
     *
     * This is called when the edit icon is clicked
     *
     * @param row - Selected row in table
     * .
     */
    const editBarrier = (row) => {
        // Cannot edit if already editing or wrong roles
        if (showBarrierEditor || !hasSuitableRole()) return;

        // Set newEntry to data for selected row.
        setNewEntry({
            participantId: currentParticipant.id,
            id: row.id,
            barrierId: row.barrierId,
            startDate: row.startDate,
            dueDate: row.dueDate,
            priority: row.priority,
            completeDate: row.completeDate
        });

        // Show barrier editor
        setShowBarrierEditor(true);
    };

    const hideEditor = () => {
        setShowBarrierEditor(false);
    };

    // Edit icon is blue unless editing or wrong role.
    const editIconColor = () => {
        if (showBarrierEditor) {
            return POLARIS_DARKER_GREY;
        }

        if (hasSuitableRole()) {
            return POLARIS_ROYAL_BLUE;
        }

        return POLARIS_DARKER_GREY;
    };

    /**
     * Get priority label with appropriate colour
     * @param priority
     * @returns {JSX.Element|string}
     */
    const getPriorityLabel = (priority) => {
        const color =
            hasSuitableRole() && !showBarrierEditor
                ? POLARIS_DOUBLE_DECKER_RED
                : POLARIS_DARKER_GREY;

        switch (priority) {
            case 'High':
                return (
                    <Typography variant={'body2'} sx={{ color: color, fontWeight: 'bold', pb: 1 }}>
                        High
                        <ErrorIcon fontSize={'small'} sx={{ color: color, mb: 0, pb: 0 }} />
                    </Typography>
                );
            case 'Medium':
                return <Typography variant={'body2'}>Medium</Typography>;
            case 'Low':
                return <Typography variant={'body2'}>Low</Typography>;
            default:
                return '-';
        }
    };

    /**
     * Get complete icon for a row
     * @param row
     * @returns {JSX.Element} with appropriate icon
     */
    const getCompleteIcon = (row) => {
        if (row.completeDate) {
            // Cannot use if editing or invalid role so get suitable color
            const color =
                showBarrierEditor || !hasSuitableRole() ? POLARIS_DARKER_GREY : POLARIS_ROYAL_BLUE;

            return <CheckBoxOutlinedIcon sx={{ color: color }} />;
        } else {
            const color =
                showBarrierEditor || !hasSuitableRole() ? POLARIS_DARKER_GREY : POLARIS_ROYAL_BLUE;
            return (
                <CheckBoxOutlineBlankOutlinedIcon
                    sx={{
                        color: color
                    }}
                />
            );
        }
    };

    /**
     * Complete icon has been clicked so toggle completion date
     *
     * @param row - currently selected row
     *
     * If the current row has no complete date then set it to today's day
     * Otherwise clear the complete date.
     *
     * Then update barrier in DB with row
     *
     * @param row
     */
    const handleDoneClick = (row) => {
        if (showBarrierEditor || !hasSuitableRole()) {
            // Don't toggle while editing or incorrect role
            return;
        }

        // Copy row
        const newRow = { ...row };

        // If this row has a complete date then set it to ''
        if (row.completeDate) {
            // Clear complete date
            newRow.completeDate = '';
        } else {
            // Set complete date to today
            newRow.completeDate = new Date().toISOString();
        }

        // Update barrier in DB
        dispatch(updateParticipantBarrier(newRow));
    };

    /**
     * Get data string formatted as en-GB
     * @param dateString - String with date from DB in ISO format yyyy-MM-dd
     * @returns String with date in format dd/MM/yyyy or '' if date is null
     */
    const getDateString = (dateString) => {
        // If date is not defined return empty string
        if (!dateString) {
            return '';
        }

        return new Date(dateString).toLocaleDateString('en-GB');
    };

    const StyledTableRow = styled(TableRow)`
        &:nth-of-type(odd) {
            background-color: ${() => POLARIS_VICTORIA_BLUE_BG};
        }

        &:nth-of-type(even) {
            background-color: ${() => POLARIS_WHITE};
        }
    `;

    if (barrierNameDetails?.length === 0 || Object.keys(currentParticipant)?.length === 0) {
        return <BusyIndicator />;
    }

    return (
        <>
            <Typography variant={'h5'} sx={{ marginTop: '16px', marginBottom: '16px' }}>
                Your barrier details
            </Typography>
            <TableContainer sx={{ m: 0, p: 0 }}>
                <Table aria-label="barriers table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: POLARIS_WHITE }}>
                            <TableCell>
                                <Typography variant="subtitle3">Barrier Name</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle3">Start Date</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle3">Due Date</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle3">Completed</Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="subtitle3">Priority</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle3">Done</Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="subtitle3">Edit</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentParticipantBarriers.map((row) => (
                            <StyledTableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {getNameFromId(barrierNameDetails, row.barrierId) || ''}
                                </TableCell>
                                <TableCell align="right">
                                    {reverseFormatDate(row.startDate)}
                                </TableCell>
                                <TableCell align="right">{getDateString(row.dueDate)}</TableCell>
                                <TableCell align="right">
                                    {getDateString(row.completeDate)}
                                </TableCell>
                                <TableCell align="left">
                                    {getPriorityLabel(row.priority) || ''}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    onClick={() => {
                                        handleDoneClick(row);
                                    }}>
                                    {getCompleteIcon(row)}
                                </TableCell>
                                <TableCell align="left">
                                    <EditIcon
                                        onClick={() => editBarrier(row)}
                                        sx={{ color: editIconColor }}
                                        data-testid="edit-icon"></EditIcon>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider
                sx={{
                    mt: SCREEN_SETTINGS.margin.large,
                    mb: SCREEN_SETTINGS.margin.large
                }}
            />
            <div hidden={showBarrierEditor}>
                <Stack direction="row" justifyContent={'space-between'}>
                    <Button
                        disabled={!hasSuitableRole()}
                        variant={'contained'}
                        data-testid="testIdAddBarrierButton"
                        onClick={() => addBlankBarrier()}>
                        Add a New Barrier
                    </Button>
                </Stack>
            </div>
            <div hidden={!showBarrierEditor}>
                <BarrierEditor
                    barrier={newEntry}
                    barrierNameDetails={arrayBarrierNames || []}
                    onComplete={hideEditor}
                />
            </div>
        </>
    );
};

export default Barriers;
