import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

import { SEARCH_REGEX } from '../../validation/validation';

import classes from './searchStyles/dynamicSearch.module.css';

const SearchOnEnter = ({
    onChange,
    search,
    searchTerm,
    placeholder,
    disabled = false,
    autocomplete = 'on'
}) => {
    const onInputChange = (e) => {
        onChange(e.target.value.replace(SEARCH_REGEX, ''));
    };
    const onSearch = (e) => {
        if (e.key !== 'Enter') return;
        e.preventDefault();
        search();
    };

    return (
        <div className={classes.dynamicSearch}>
            <TextField
                type="search"
                placeholder={placeholder}
                size={'small'}
                value={searchTerm}
                disabled={disabled}
                autoComplete={autocomplete}
                onChange={onInputChange}
                onKeyDown={onSearch}></TextField>
            <div className={`${classes.icons} ${disabled ? classes.disabled : ''}`}>
                {!searchTerm ? (
                    <SearchIcon />
                ) : (
                    <ClearIcon onClick={() => onChange('')} className={classes.clear} />
                )}
            </div>
        </div>
    );
};

export default SearchOnEnter;

SearchOnEnter.propTypes = {
    onChange: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autocomplete: PropTypes.string
};
