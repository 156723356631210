import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent, Divider } from '@mui/material';

import { loadCourseProvisions, searchActiveCourses } from '../../../store/courseService';
import { loadAttendanceDetails, loadProvisionStatusDetails } from '../../../store/directusService';
import { POLARIS_WHITE } from '../../../themes/theme';
import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';
import CurrentCourseManagement from '../currentCourse/CurrentCourseManagement';
import CurrentProvisionManagement from '../currentProvision/CurrentProvisionManagement';

import AllCourseAndProvisionTableRow from './AllCourseAndProvisionTableRow';

import classes from '../../../commonStyles/tables.module.css';

const headCells = [
    { id: 'code', label: 'ID', sortable: true },
    { id: 'name', label: 'Name', sortable: true },
    { id: 'startDate', label: 'Start Date', sortable: true },
    { id: 'endDate', label: 'End Date', sortable: true },
    { id: 'numberOfModules', label: 'Modules', sortable: true }
];

const AllActiveCourseAndProvisionManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [filteredActiveCourses, setFilteredActiveCourses] = useState([]);
    const [filteredActiveProvisions, setFilteredActiveProvisions] = useState([]);
    const [openActiveCourseId, setOpenActiveCourseId] = useState('');
    const [openActiveProvisionId, setOpenActiveProvisionId] = useState('');
    const [activeCoursesRowMetaData, setActiveCoursesRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });
    const [activeProvisionsRowMetaData, setActiveProvisionsRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const { provisionStatusDetails } = useSelector((state) => state.entities.directusService);
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const {
        activeCourses,
        activeCoursesMetaData,
        courseProvisions,
        courseProvisionsMetaData,
        loadingActiveCourses,
        loadingProvisions
    } = useSelector((state) => state.entities.courseService);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const activeCoursesSearchData = {
        active: true,
        serviceIds: [currentParticipant.serviceId]
    };

    // USE EFFECTS
    useEffect(() => {
        if (activeCourses.length < 1) return;
        const filteredActiveCourses = activeCourses.filter(
            (el) =>
                el.endDate?.substring(0, 10) >= new Date().toISOString().substring(0, 10) &&
                !el.modules?.some(
                    (entry) =>
                        entry.capacity === entry.participants.length &&
                        // keep the courses where no modules have the PT as an entry in participants
                        entry.participants?.some((pt) => pt.participantId === currentParticipant.id)
                )
        );
        setFilteredActiveCourses(filteredActiveCourses);
    }, [activeCourses]);

    useEffect(() => {
        if (courseProvisions.length < 1) return;
        const filteredActiveProvisions = courseProvisions
            .filter(
                (el) =>
                    el.active &&
                    el.contractIds.includes(currentParticipant.contractId) &&
                    !el.participants.some((pt) => pt.participantId === currentParticipant.id)
            )
            .map((el) => ({ ...el, name: el.title }));
        setFilteredActiveProvisions(filteredActiveProvisions);
    }, [courseProvisions]);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAttendanceDetails());
        dispatch(loadProvisionStatusDetails());
        dispatch(
            searchActiveCourses(activeCoursesSearchData, 0, activeCoursesRowMetaData.rowsPerPage)
        );
        dispatch(loadCourseProvisions(0, activeProvisionsRowMetaData.rowsPerPage));
    }, []);

    // HELPER FNS
    const createRows = (rows, rowMetaData, onToggleDropdown, openRowId, rowClassName = '') =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <AllCourseAndProvisionTableRow
                key={el.id}
                row={el}
                roles={roles}
                toggleDropdown={onToggleDropdown}
                openId={openRowId}
                rowClassName={rowClassName}
            />
        ));

    // RENDER
    return provisionStatusDetails.length < 1 ? (
        <LoadingSpinner content="No current provision status found" />
    ) : (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
                    <CardContent>
                        <div>
                            <FormHeader text={'All Active Courses'}></FormHeader>
                            {filteredActiveCourses.length < 1 ? (
                                <LoadingSpinner content="No active course found" />
                            ) : (
                                <ResultsTable
                                    defaultOrderBy={'name'}
                                    defaultRowsPerPage={activeCoursesRowMetaData.rowsPerPage}
                                    apiParams={activeCoursesSearchData}
                                    headCells={headCells}
                                    loadingValues={loadingActiveCourses}
                                    loadResults={searchActiveCourses}
                                    passRowMetaDataUp={setActiveCoursesRowMetaData}
                                    tableRows={createRows(
                                        filteredActiveCourses,
                                        activeCoursesRowMetaData,
                                        (id) => setOpenActiveCourseId(id),
                                        openActiveCourseId
                                    )}
                                    totalResults={activeCoursesMetaData.totalElements}
                                    metaData={activeCoursesMetaData}
                                />
                            )}
                        </div>
                    </CardContent>
                </Card>
                <Divider />
                <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
                    <CardContent>
                        <div>
                            <FormHeader text={'All Active Provisions'}></FormHeader>
                            {filteredActiveProvisions.length < 1 ? (
                                <LoadingSpinner content="No active provision found" />
                            ) : (
                                <ResultsTable
                                    defaultOrderBy={'name'}
                                    defaultRowsPerPage={activeProvisionsRowMetaData.rowsPerPage}
                                    headCells={headCells}
                                    loadingValues={loadingProvisions}
                                    loadResults={loadCourseProvisions}
                                    passRowMetaDataUp={setActiveProvisionsRowMetaData}
                                    tableRows={createRows(
                                        filteredActiveProvisions,
                                        activeProvisionsRowMetaData,
                                        (id) => setOpenActiveProvisionId(id),
                                        openActiveProvisionId,
                                        classes.lavendar
                                    )}
                                    totalResults={courseProvisionsMetaData.totalElements}
                                    metaData={courseProvisionsMetaData}
                                />
                            )}
                        </div>
                    </CardContent>
                </Card>
                <Divider />
                <CurrentCourseManagement activeCourses={activeCourses} />
                <Divider sx={{ marginTop: '-30px', marginBottom: '10px' }} />
                <CurrentProvisionManagement courseProvisions={courseProvisions} />
            </CardContent>
        </Card>
    );
};

AllActiveCourseAndProvisionManagement.propTypes = {
    participantId: PropTypes.string
};

export default AllActiveCourseAndProvisionManagement;
