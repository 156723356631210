import { Box, Card, CardContent, CircularProgress } from '@mui/material';

import FormHeader from '../layout/FormHeader';

const BusyIndicator = () => {
    return (
        <Card data-testid="testIdBusyIndicator">
            <CardContent>
                <FormHeader text={'Please wait...'}></FormHeader>

                <Box fullwidth="true">
                    <CircularProgress />
                </Box>
            </CardContent>
        </Card>
    );
};

export default BusyIndicator;
