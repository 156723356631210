import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setErrorMessage } from '../../../store/formsState';
import { updateDar } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import form from '../../../commonStyles/formStyles.module.css';

const CHARACTER_LIMIT = 750;

const DarChangeEditor = ({ open, onDarChangeEditor, darRow, darStatuses }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        statusId: null,
        notes: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [clearSelectedDarStatus, setClearSelectedDarStatus] = useState('0');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentUser = useSelector((state) => state.entities.userService.currentUser);

    // EVENT HANDLERS
    const handleDarStatusChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, statusId: chosenId }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, notes: e.target.value }));
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        clearData();
    };

    const clearData = () => {
        setNewEntry(initialState);
        setClearSelectedDarStatus(Math.random());
        setNotes('');
        onDarChangeEditor(false);
    };

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(darRow)?.length > 0) dispatch(loadUser(darRow.userId));
    }, [darRow]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const onSubmit = () => {
        setValue('statusId', newEntry.statusId, { shouldValidate: true });
        setValue('notes', notes, { shouldValidate: true });

        if (newEntry.notes === '') {
            dispatch(setErrorMessage(`Notes are mandatory.`));
        } else if (newEntry.statusId === '') {
            dispatch(setErrorMessage(`Status is mandatory.`));
        } else {
            const payload = newEntry;
            payload.id = darRow.id;
            dispatch(updateDar(payload));
            clearData();
        }
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <Dialog
                className="muiDialogWrapper"
                open={isOpen}
                onClose={handleClickOutside}
                aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Update Data Amendment Request</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <div className={form.formSection}>
                            <div className={form.formColumn}>
                                <LabelledTextField
                                    label={'Requester'}
                                    id={'requester'}
                                    disabled={true}
                                    mandatory={true}
                                    value={currentUser.emailAddress}
                                />
                                <LabelledTextField
                                    label={'Request Type'}
                                    id={'requestType'}
                                    disabled={true}
                                    mandatory={true}
                                    value={darRow.requestTypeName}
                                />
                            </div>
                            <div className={form.formColumn}>
                                <DDLOptionPicker
                                    label={'Status'}
                                    key={clearSelectedDarStatus}
                                    id={'statusId'}
                                    mandatory={true}
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    menuItems={darStatuses || []}
                                    error={errors.statusId}
                                    {...register('statusId')}
                                    onChange={(chosenId) => handleDarStatusChange(chosenId)}
                                />
                            </div>
                        </div>
                        <LabelledTextField
                            label={'Notes'}
                            id={'notes'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            multiline
                            rows={5}
                            value={notes}
                            placeholder={'Enter reversion notes'}
                            counter={'true'}
                            helperText={`${notes.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            error={errors.notes}
                            {...register('notes')}
                            onChange={handleNotesChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                            Update
                        </Button>
                        <Button onClick={handleCancel} color="primary" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    statusId: Yup.string().required('Please select a status.'),
    notes: Yup.string().trim().min(5, 'Notes must be at least five characters.')
});

DarChangeEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onDarChangeEditor: PropTypes.func,
    darRow: PropTypes.object,
    darStatuses: PropTypes.array
};

export default DarChangeEditor;
