import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../../icons/OnEditIcon';
import OnWarningIcon from '../../../icons/OnWarningIcon';
import { setCurrentCourse } from '../../../store/courseService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

import CourseInstanceDetails from './CourseInstanceDetails';

import classes from '../../../commonStyles/tables.module.css';

const CourseInstanceTableRow = ({ row, roles, toggleDropdown, openId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    const onEditRow = (row) => {
        dispatch(setCurrentCourse(row));
        navigate('/edit_course', { state: { id: row.id, accordionPanel: 'courses' } });
    };

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    return (
        <>
            <TableRow
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell className={classes.rowWarningIcon}>
                    {!row.active ? <OnWarningIcon tooltipContent="Missing Module Date" /> : ''}
                </TableCell>
                <TableCell>
                    {row.endDate === '1960-01-01' ? ' - ' : reverseFormatDate(row.endDate)}
                </TableCell>
                <TableCell>
                    {row.startDate === '1960-01-01' ? ' - ' : reverseFormatDate(row.startDate)}
                </TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEditRow(row)}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={6}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <CourseInstanceDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default CourseInstanceTableRow;

CourseInstanceTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
