import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PortraitIcon from '@mui/icons-material/Portrait';
import RadarIcon from '@mui/icons-material/Radar';
import TodayIcon from '@mui/icons-material/Today';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

import { setCurrentCourse, setCurrentModule } from '../../../store/courseService';
import { loadLocation } from '../../../store/directusService';
import { loadUser } from '../../../store/userService';
import { isTimePast, reverseFormatDate } from '../../../utils/dateFunctions';
import FullCourseAttendanceDialog from '../courseInstances/FullCourseAttendanceDialog';

import card from '../../../commonStyles/card.module.css';

const ScheduleModuleAttendanceView = ({ course, module, prevPage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const {
        title,
        description,
        locationId,
        date,
        startTime,
        endTime,
        userId,
        capacity,
        smartTarget
    } = module;
    const [openFullCourseDialog, setOpenFullCourseDialog] = useState(false);
    const [courseLocation, setCourseLocation] = useState('');
    const [adviser, setAdviser] = useState('');

    // STORE STATE
    const users = useSelector((state) => state.entities.userService.users);
    const locations = useSelector((state) => state.entities.directusService.locationDetails);

    // USE EFFECTS
    useEffect(() => {
        if (userId) {
            let user = '';
            if (users && users.length > 0) user = users.find((el) => el.id === userId);
            user ? addAdviser(user) : dispatch(loadUser(userId));
        }
        if (locationId) {
            let location = '';
            if (locations && locations.length > 0)
                location = locations.find((el) => el.id === locationId);
            location ? setCourseLocation(location.name) : dispatch(loadLocation(locationId));
        }
    }, []);

    useEffect(() => {
        if (adviser || users?.length < 1) return;
        const user = users.find((el) => el.id === userId);
        user && addAdviser(user);
    }, [users]);

    useEffect(() => {
        if (courseLocation || !locations || locations?.length < 1) return;
        const location = locations.find((el) => el.id === locationId);
        location && setCourseLocation(location.name);
    }, [locations]);

    // HELPER FNS
    const addAdviser = (adviser) => {
        const { firstName, lastName } = adviser;
        setAdviser(`${firstName} ${lastName}`);
    };

    // EVENT HANDLERS
    const onSchedule = () => {
        dispatch(setCurrentModule(module));
        dispatch(setCurrentCourse(course));
        navigate('/course_calendar');
    };
    const isOpen = () => setOpenFullCourseDialog(false);

    // RENDER
    return (
        <div
            className={`${card.card} ${prevPage === 'editCourse' ? card.attendanceCardEditCourse : prevPage === 'createCourse' ? card.attendanceCardCreateCourse : card.attendanceCard}`}
            data-testid="module_attendance_card_data">
            <div className={`${title?.length > 60 ? card.bannerDoubleRow : card.banner}`}>
                <h6>{title || 'Schedule Module'}</h6>
            </div>

            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Module Description:</div>
                    <div className={card.description}>
                        {description && description.length > 100
                            ? description.substring(0, 97) + '...'
                            : description}
                    </div>
                </div>
                <div className={`${card.rows} ${card.compactRows}`}>
                    <div className={card.row}>
                        <LocationOnIcon />
                        {` ${courseLocation ? courseLocation : ' - '}`}
                    </div>

                    <div className={card.row}>
                        <TodayIcon />
                        {!date && !startTime && !endTime
                            ? ' - '
                            : ` ${date ? reverseFormatDate(date) : ''} ${
                                  startTime ? startTime : ''
                              } - ${endTime ? endTime : ''}`}
                    </div>
                    <div className={card.row}>
                        <PortraitIcon />
                        {adviser || ' - '}
                    </div>
                    <div className={`${card.row} ${card.doubleRowWrapper}`}>
                        <RadarIcon />
                        <span className={card.doubleRow}>{smartTarget?.name || ' - '}</span>
                    </div>
                </div>
                {prevPage !== 'createCourse' ? (
                    <>
                        <div className={card.section}>
                            {date && startTime && isTimePast(`${date}T${startTime}`) ? (
                                <span className={card.nonInteractive}>Reschedule this module</span>
                            ) : (
                                <a href="#" onClick={onSchedule}>
                                    {!date && !startTime && !endTime && <>Schedule this module</>}
                                    {date && <>Reschedule this module</>}
                                </a>
                            )}
                        </div>
                        <div className={card.attendanceView}>
                            <span className={card.spaces}>
                                <WorkspacesIcon />
                                {module.participants &&
                                    ` ${module.participants.length} of ${
                                        capacity ? capacity : ''
                                    } spaces filled`}
                                {!module.participants && ` 0 spaces filled`}
                            </span>
                            <div
                                className={`${card.attlink} ${card.link}`}
                                onClick={() => setOpenFullCourseDialog(!openFullCourseDialog)}>
                                See attendance list
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {openFullCourseDialog && (
                    <FullCourseAttendanceDialog module={module} isOpen={isOpen} />
                )}
            </div>
        </div>
    );
};

ScheduleModuleAttendanceView.propTypes = {
    course: PropTypes.object.isRequired,
    module: PropTypes.object.isRequired,
    prevPage: PropTypes.string
};

export default ScheduleModuleAttendanceView;
