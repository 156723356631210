import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    clearAttraction,
    clearDriversLicence,
    clearEthnicity,
    clearFirstLanguage,
    clearHousing,
    clearMaritalStatus,
    loadAttractionDetails,
    loadDriversLicenceDetails,
    loadEthnicityDetails,
    loadFirstLanguageDetails,
    loadHousingDetails,
    loadMaritalStatusDetails
} from '../../store/directusService';
import {
    updatePreSelectedMultiItems,
    updatePreSelects,
    updateSelectedItems
} from '../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import NotFound from '../notFound/NotFound';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';
import MultiSelect from '../ui/pickers/MultiSelect';

import '../../commonStyles/loading.css';
import form from '../../commonStyles/formStyles.module.css';
// Note! This code uses 'console.log' for logging and 'alert' to tell the user about successes/errors.
// Replace these when logging and notifications are implemented.

/**
 * ParticipantInformationAdmin component.
 *
 * This updates the participant information details DDL for ethnicity, first language &
 *
 * When the form is submitted onSubmit is called. This validates that DDLs are not blank. *
 */

const ParticipantInformationAdmin = ({ contractId }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    const initialState = {
        selectedEthnicities: [],
        selectedFirstLanguages: [],
        selectedDriversLicences: [],
        selectedMaritalStatuses: [],
        selectedHousing: [],
        selectedAttractions: [],

        preSelectedEthnicities: [],
        preSelectedFirstLanguages: [],
        preSelectedDriversLicences: [],
        preSelectedMaritalStatuses: [],
        preSelectedHousing: [],
        preSelectedAttractions: [],
        preSelectedAttractionIds: [],

        arrayEthnicities: [],
        arrayFirstLanguages: [],
        arrayDriversLicences: [],
        arrayMaritalStatuses: [],
        arrayHousing: [],
        arrayAttractions: []
    };

    const isChangedInitialState = {
        ethnicities: false,
        driversLicences: false,
        maritalStatuses: false,
        housing: false,
        attractions: false,
        firstLanguages: false
    };

    const initialClearState = {
        isClearSelectedEthnicities: '0',
        isClearSelectedFirstLanguages: '1',
        isClearSelectedDriversLicences: '2',
        isClearSelectedMaritalStatuses: '3',
        isClearSelectedHousing: '4',
        isClearSelectedAttractions: '5',
        isClearSelectedAttractionDetails: '6'
    };

    const clearState = {
        isClearSelectedEthnicities: Math.random(),
        isClearSelectedFirstLanguages: Math.random(),
        isClearSelectedDriversLicences: Math.random(),
        isClearSelectedMaritalStatuses: Math.random(),
        isClearSelectedHousing: Math.random(),
        isClearSelectedAttractions: Math.random()
    };

    const [admin, setAdmin] = useState(initialState);
    const [clear, setClear] = useState(initialClearState);
    const [isChanged, setIsChanged] = useState(isChangedInitialState);
    const [showAttractionsLoading, setShowAttractionsLoading] = useState(false);
    const [showAttractionsPending, setShowAttractionsPending] = useState(false);

    const submitMessages = {
        ethnicities: 'Ethnicities updated',
        firstLanguages: 'First languages updated',
        driversLicences: 'Drivers licences updated',
        maritalStatuses: 'Marital statuses updated',
        housing: 'Housing updated',
        attractions: 'Attraction updated'
    };

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const {
        attractionDetails,
        attractionDetailsLoading,
        attractionUpdatePending,
        ethnicityDetails,
        firstLanguageDetails,
        driversLicenceDetails,
        maritalStatusDetails,
        housingDetails
    } = useSelector((state) => state.entities.directusService);

    const dispatch = useDispatch();

    // EVENT HANDLERS
    const handleDriversLicenceChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('driversLicences', chosenIds, { shouldValidate: true });
        setAdmin((admin) => ({ ...admin, selectedDriversLicences: chosenIds }));
        if (
            !isChanged.driversLicences &&
            chosenIds.length !== admin.preSelectedDriversLicences.length
        )
            setIsChanged((prev) => ({ ...prev, driversLicences: true }));
    };

    const handleMaritalStatusChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('maritalStatuses', chosenIds, { shouldValidate: true });
        setAdmin((admin) => ({ ...admin, selectedMaritalStatuses: chosenIds }));
        if (
            !isChanged.maritalStatuses &&
            chosenIds.length !== admin.preSelectedMaritalStatuses.length
        )
            setIsChanged((prev) => ({ ...prev, maritalStatuses: true }));
    };

    const handleHousingChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('housing', chosenIds, { shouldValidate: true });
        setAdmin((admin) => ({ ...admin, selectedHousing: chosenIds }));
        if (!isChanged.housing && chosenIds.length !== admin.preSelectedHousing.length)
            setIsChanged((prev) => ({ ...prev, housing: true }));
    };

    const handleAttractionChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('attractions', chosenIds, { shouldValidate: true });
        setAdmin((admin) => ({ ...admin, selectedAttractions: chosenIds }));
        if (!isChanged.attractions && chosenIds.length !== admin.preSelectedAttractionIds.length)
            setIsChanged((prev) => ({ ...prev, attractions: true }));
    };

    const handleEthnicityChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('ethnicities', chosenIds, { shouldValidate: true });
        setAdmin((admin) => ({ ...admin, selectedEthnicities: chosenIds }));
        if (!isChanged.ethnicities && chosenIds.length !== admin.preSelectedEthnicities.length)
            setIsChanged((prev) => ({ ...prev, ethnicities: true }));
    };

    const handleFirstLanguageChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('firstLanguages', chosenIds, { shouldValidate: true });
        setAdmin((admin) => ({ ...admin, selectedFirstLanguages: chosenIds }));
        if (
            !isChanged.firstLanguages &&
            chosenIds.length !== admin.preSelectedFirstLanguages.length
        )
            setIsChanged((prev) => ({ ...prev, firstLanguages: true }));
    };

    // USEEFFECTS
    const contractSetup = () => {
        setAdmin(initialState);
        setClear(clearState);

        dispatch(clearEthnicity());
        dispatch(clearFirstLanguage());
        dispatch(clearDriversLicence());
        dispatch(clearMaritalStatus());
        dispatch(clearHousing());
        dispatch(clearAttraction());

        dispatch(loadEthnicityDetails());
        dispatch(loadFirstLanguageDetails());
        dispatch(loadDriversLicenceDetails());
        dispatch(loadMaritalStatusDetails());
        dispatch(loadHousingDetails());
        dispatch(loadAttractionDetails());
    };

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (ethnicityDetails?.length && admin.arrayEthnicities?.length === 0) {
            const update = updatePreSelectedMultiItems('ethnicity', ethnicityDetails, contractId);
            setAdmin((admin) => ({
                ...admin,
                preSelectedEthnicities: update,
                arrayEthnicities: ethnicityDetails
            }));
        }
    }, [ethnicityDetails]);

    useEffect(() => {
        if (firstLanguageDetails?.length && admin.arrayFirstLanguages?.length === 0) {
            const update = updatePreSelectedMultiItems(
                'first_language',
                firstLanguageDetails,
                contractId
            );
            setAdmin((admin) => ({
                ...admin,
                preSelectedFirstLanguages: update,
                arrayFirstLanguages: firstLanguageDetails
            }));
        }
    }, [firstLanguageDetails]);

    useEffect(() => {
        if (driversLicenceDetails?.length && admin.arrayDriversLicences?.length === 0) {
            const update = updatePreSelectedMultiItems(
                'drivers_licence',
                driversLicenceDetails,
                contractId
            );
            setAdmin((admin) => ({
                ...admin,
                preSelectedDriversLicences: update,
                arrayDriversLicences: driversLicenceDetails
            }));
        }
    }, [driversLicenceDetails]);

    useEffect(() => {
        if (maritalStatusDetails?.length && admin.arrayMaritalStatuses?.length === 0) {
            const update = updatePreSelectedMultiItems(
                'marital_status',
                maritalStatusDetails,
                contractId
            );
            setAdmin((admin) => ({
                ...admin,
                preSelectedMaritalStatuses: update,
                arrayMaritalStatuses: maritalStatusDetails
            }));
        }
    }, [maritalStatusDetails]);

    useEffect(() => {
        if (housingDetails?.length && admin.arrayHousing?.length === 0) {
            const update = updatePreSelectedMultiItems('housing', housingDetails, contractId);
            setAdmin((admin) => ({
                ...admin,
                preSelectedHousing: update,
                arrayHousing: housingDetails
            }));
        }
    }, [housingDetails]);

    useEffect(() => {
        if (attractionDetails?.length && admin.arrayAttractions?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'attraction',
                attractionDetails,
                contractId
            );
            setAdmin((admin) => ({
                ...admin,
                preSelectedAttractions: preSelects,
                preSelectedAttractionIds: preSelectIds,
                arrayAttractions: attractionDetails
            }));
        }
    }, [attractionDetails]);

    useEffect(() => {
        attractionDetailsLoading
            ? setShowAttractionsLoading(true)
            : setShowAttractionsLoading(false);
    }, [attractionDetailsLoading]);

    useEffect(() => {
        attractionUpdatePending
            ? setShowAttractionsPending(true)
            : setShowAttractionsPending(false);
    }, [attractionUpdatePending]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${submitMessages.ethnicities} collection`)) {
            setClear((prev) => ({ ...prev, isClearSelectedEthnicities: Math.random() }));
            setAdmin((prev) => ({
                ...prev,
                selectedEthnicities: [],
                preSelectedEthnicities: [],
                arrayEthnicities: []
            }));
            dispatch(clearEthnicity());
            dispatch(loadEthnicityDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.firstLanguages} collection`)) {
            setClear((prev) => ({ ...prev, isClearSelectedFirstLanguages: Math.random() }));
            setAdmin((prev) => ({
                ...prev,
                selectedFirstLanguages: [],
                preSelectedFirstLanguages: [],
                arrayFirstLanguages: []
            }));
            dispatch(clearFirstLanguage());
            dispatch(loadFirstLanguageDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.driversLicences} collection`)) {
            setClear((prev) => ({ ...prev, isClearSelectedDriversLicences: Math.random() }));
            setAdmin((prev) => ({
                ...prev,
                selectedDriversLicences: [],
                preSelectedDriversLicences: [],
                arrayDriversLicences: []
            }));
            dispatch(clearDriversLicence());
            dispatch(loadDriversLicenceDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.maritalStatuses} collection`)) {
            setClear((prev) => ({ ...prev, isClearSelectedMaritalStatuses: Math.random() }));
            setAdmin((prev) => ({
                ...prev,
                selectedMaritalStatuses: [],
                preSelectedMaritalStatuses: [],
                arrayMaritalStatuses: []
            }));
            dispatch(clearMaritalStatus());
            dispatch(loadMaritalStatusDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.housing} collection`)) {
            setClear((prev) => ({ ...prev, isClearSelectedHousing: Math.random() }));
            setAdmin((prev) => ({
                ...prev,
                selectedHousing: [],
                preSelectedHousing: [],
                arrayHousing: []
            }));
            dispatch(clearHousing());
            dispatch(loadHousingDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.attractions} collection`)) {
            setClear((prev) => ({ ...prev, isClearSelectedAttractions: Math.random() }));
            setAdmin((prev) => ({
                ...prev,
                selectedAttractions: [],
                preSelectedAttractions: [],
                preSelectedAttractionIds: [],
                arrayAttractions: []
            }));
            dispatch(clearAttraction());
            dispatch(loadAttractionDetails());
        }
        setIsChanged(isChangedInitialState);
    }, [successMessage]);

    // FORM SUBMIT
    const onSubmit = () => {
        isChanged.ethnicities &&
            updateSelectedItems(
                'ethnicity',
                ethnicityDetails,
                admin.selectedEthnicities,
                contractId,
                submitMessages.ethnicities,
                dispatch
            );

        isChanged.firstLanguages &&
            updateSelectedItems(
                'first_language',
                firstLanguageDetails,
                admin.selectedFirstLanguages,
                contractId,
                submitMessages.firstLanguages,
                dispatch
            );

        isChanged.driversLicences &&
            updateSelectedItems(
                'drivers_licence',
                driversLicenceDetails,
                admin.selectedDriversLicences,
                contractId,
                submitMessages.driversLicences,
                dispatch
            );

        isChanged.maritalStatuses &&
            updateSelectedItems(
                'marital_status',
                maritalStatusDetails,
                admin.selectedMaritalStatuses,
                contractId,
                submitMessages.maritalStatuses,
                dispatch
            );

        isChanged.housing &&
            updateSelectedItems(
                'housing',
                housingDetails,
                admin.selectedHousing,
                contractId,
                submitMessages.housing,
                dispatch
            );

        isChanged.attractions &&
            updateSelectedItems(
                'attraction',
                attractionDetails,
                admin.selectedAttractions,
                contractId,
                submitMessages.attractions,
                dispatch
            );
    };

    // RENDER
    let content = '';
    if (!contractId) content = 'No Contract Id';
    if (ethnicityDetails?.length < 1) content = 'No ethnicity details';
    if (firstLanguageDetails?.length < 1) content = 'No first language details';
    if (driversLicenceDetails?.length < 1) content = "No driver's licence details";
    if (maritalStatusDetails?.length < 1) content = 'No marital status details';
    if (housingDetails?.length < 1) content = 'No housing details';
    if (attractionDetails?.length < 1) content = 'No attraction details';

    if (!hasRole([SUPERUSER, LOCAL_ADMIN], roles)) return <NotFound />;
    if (
        !contractId ||
        ethnicityDetails?.length < 1 ||
        firstLanguageDetails?.length < 1 ||
        driversLicenceDetails?.length < 1 ||
        maritalStatusDetails?.length < 1 ||
        housingDetails?.length < 1 ||
        attractionDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="form_start_ParticipantInformationAdmin">
            <br />
            <DDLMultiOptionPicker
                heading={'Configure Ethnicity DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="ethnicities"
                id="ethnicities"
                mandatory={true}
                key={clear.isClearSelectedEthnicities}
                label="Ethnicities"
                placeholder="Ethnicity"
                menuItems={ethnicityDetails || []}
                preSelectedIds={admin.preSelectedEthnicities}
                chosenIds={admin.selectedEthnicities}
                error={errors.ethnicities}
                {...register('ethnicities')}
                onChange={(chosenIds) => handleEthnicityChange(chosenIds)}
            />
            <DDLMultiOptionPicker
                heading={'Configure First Language DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="firstLanguages"
                id="firstLanguages"
                mandatory={true}
                key={clear.isClearSelectedFirstLanguages}
                label="First Languages"
                placeholder="First Language"
                menuItems={firstLanguageDetails || []}
                preSelectedIds={admin.preSelectedFirstLanguages}
                chosenIds={admin.selectedFirstLanguages}
                error={errors.firstLanguages}
                {...register('firstLanguages')}
                onChange={(chosenIds) => handleFirstLanguageChange(chosenIds)}
            />
            <DDLMultiOptionPicker
                heading={'Configure Drivers Licence DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="driversLicences"
                id="driversLicences"
                mandatory={true}
                key={clear.isClearSelectedDriversLicences}
                label="Drivers Licences"
                placeholder="Drivers Licence"
                menuItems={driversLicenceDetails || []}
                preSelectedIds={admin.preSelectedDriversLicences}
                chosenIds={admin.selectedDriversLicences}
                error={errors.driversLicences}
                {...register('driversLicences')}
                onChange={(chosenIds) => handleDriversLicenceChange(chosenIds)}
            />
            <p>Access to own transport</p>
            <p>Access to Public transport </p>
            <p>Hobbies/Interests</p>
            <DDLMultiOptionPicker
                heading={'Configure Marital Status DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="maritalStatuses"
                id="maritalStatuses"
                mandatory={true}
                key={clear.isClearSelectedMaritalStatuses}
                label="Marital Statuses"
                placeholder="Marital Status"
                menuItems={maritalStatusDetails || []}
                preSelectedIds={admin.preSelectedMaritalStatuses}
                chosenIds={admin.selectedMaritalStatuses}
                error={errors.maritalStatuses}
                {...register('maritalStatuses')}
                onChange={(chosenIds) => handleMaritalStatusChange(chosenIds)}
            />
            <p>Dependants</p>
            <p>Add a Dependant</p>
            <DDLMultiOptionPicker
                heading={'Configure Housing DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="housing"
                id="housing"
                mandatory={true}
                key={clear.isClearSelectedHousing}
                label="Housing"
                placeholder="Housing"
                menuItems={housingDetails || []}
                preSelectedIds={admin.preSelectedHousing}
                chosenIds={admin.selectedHousing}
                error={errors.housing}
                {...register('housing')}
                onChange={(chosenIds) => handleHousingChange(chosenIds)}
            />
            <div className={form.pageMultiSelectorWrapper}>
                <MultiSelect
                    id="attractions"
                    key={clear.isClearSelectedAttractions}
                    disabled={!hasRole([SUPERUSER], roles)}
                    heading={'Configure Attraction DDL'}
                    data-testid="attractions"
                    mandatory={true}
                    label="Attractions"
                    placeholder="Attraction"
                    menuItems={attractionDetails || []}
                    preSelectedIds={admin.preSelectedAttractionIds}
                    preSelects={admin.preSelectedAttractions}
                    error={errors.attractions}
                    {...register('attractions')}
                    onChange={(chosenIds) => handleAttractionChange(chosenIds)}
                />
                {showAttractionsLoading && (
                    <div className="ddlLoadingIcon ddlMargin">Loading...</div>
                )}
                {showAttractionsPending && (
                    <div className="ddlLoadingIcon ddlMargin">Pending...</div>
                )}
            </div>
            <p>Contract</p>
            <p>Service</p>
            <p>&nbsp;</p>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton">
                {'UPDATE PARTICIPANT INFORMATION'}
            </Button>
        </form>
    );
};

const validationSchema = Yup.object().shape({
    ethnicities: Yup.array().min(1, 'No ethnicities selected.').nullable(),
    firstLanguages: Yup.array().min(1, 'No first languages selected.').nullable(),
    driversLicences: Yup.array().min(1, 'No drivers licences selected.').nullable(),
    maritalStatuses: Yup.array().min(1, 'No marital statuses selected.').nullable(),
    housing: Yup.array().min(1, 'No housing selected.').nullable(),
    attractions: Yup.array().min(1, 'No attractions selected.').nullable(),
    attractionDetails: Yup.array().min(1, 'No attraction details selected.').nullable()
});

ParticipantInformationAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ParticipantInformationAdmin;
