import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import { getNameFromId } from '../../../../../utils/directusFunctions';

import RejectedVacancyDetails from './RejectedVacancyDetails';

import classes from '../../../../../commonStyles/tables.module.css';

const RejectedVacanciesTableRow = ({ row, toggleDropdown, openId }) => {
    // LOCAL STATE
    const [open, setOpen] = useState(false);

    // STORE STATE
    const { jobSectorDetails } = useSelector((state) => state.entities.directusService);

    // CALLBACKS
    const onToggleDropdown = (e) => {
        if (e.target.nodeName !== 'TD') return;
        toggleDropdown(row.id, e);
        setOpen(open === row.id ? null : row.id);
    };

    // USE EFFECTS
    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.lavendar} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.adviser}</TableCell>
                <TableCell>{getNameFromId(jobSectorDetails, row.sectorId)}</TableCell>
                <TableCell>{`£${row.payRateFrom} - £${row.payRateTo}`}</TableCell>
                <TableCell>{row.postcode}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={6}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <RejectedVacancyDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default RejectedVacanciesTableRow;

RejectedVacanciesTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string
};
