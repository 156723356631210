import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
    selectFinancialRequestStatus,
    selectFinancialRequestType
} from '../../../../store/dataSelectors';
import { selectParticipantFinancialRequests } from '../../../../store/participantSelectors';
import { selectUsers } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const FinancialRequestTable = ({ onIsLoaded }) => {
    const financialRef = useRef(null);
    const [rows, setRows] = useState([]);
    const financialRequests = useSelector(selectParticipantFinancialRequests);
    const financialRequestTypeDetails = useSelector(selectFinancialRequestType);
    const financialRequestStatusDetails = useSelector(selectFinancialRequestStatus);
    const users = useSelector(selectUsers);

    useEffect(() => {
        if (
            financialRequestTypeDetails?.length < 1 ||
            financialRequestStatusDetails?.length < 1 ||
            users?.length < 1
        )
            return;
        if (financialRequests?.length < 1) {
            onIsLoaded('financialRequest');
            return;
        }
        const data = financialRequests.map((el) => {
            const approver = users.find((entry) => entry.id === el.approverId);
            const { firstName, lastName } = approver
                ? approver
                : { firstName: ' - ', lastName: '' };
            return {
                code: el.code,
                type: getNameFromId(financialRequestTypeDetails, el?.requestTypeId) || ' - ',
                amount: el?.amount || ' - ',
                status: getNameFromId(financialRequestStatusDetails, el?.statusId) || ' - ',
                statusChangeDate: el?.statusChangeDate
                    ? reverseFormatDate(el?.statusChangeDate)
                    : ' - ',
                dateIssued: el?.dateIssued ? reverseFormatDate(el?.dateIssued) : ' - ',
                approver: firstName && lastName ? '${firstName} ${lastName}' : ' - '
            };
        });
        setRows(data);
    }, [financialRequests, financialRequestTypeDetails, financialRequestStatusDetails, users]);

    useEffect(() => {
        financialRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('financialRequest');
    }, [financialRef.current]);

    return (
        <div>
            <h3>Financial Request</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>ID</span>
                    <span>Type</span>
                    <span>Amount</span>
                    <span className={classes.alignLeftMinusMin}>Status Change Date</span>
                    <span>Status</span>
                    <span>Date Issued</span>
                    <span>Authoriser</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (financialRef.current = el)}
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.type}</span>
                                <span>{el.amount}</span>
                                <span
                                    className={`${
                                        el.statusChangeDate === ' - ' ? classes.alignCenter : ''
                                    }`}>
                                    {el.statusChangeDate}
                                </span>
                                <span>{el.status}</span>
                                <span
                                    className={`${
                                        el.dateIssued === ' - ' ? classes.alignCenter : ''
                                    }`}>
                                    {el.dateIssued}
                                </span>
                                <span>{el.approver}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FinancialRequestTable;

FinancialRequestTable.propTypes = {
    onIsLoaded: PropTypes.func
};
