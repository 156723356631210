import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAssetRequestAsset, selectAssetRequestStatus } from '../../../../store/dataSelectors';
import { selectParticipantAssetRequests } from '../../../../store/participantSelectors';
import { selectUsers } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const AssetRequestTable = ({ onIsLoaded }) => {
    const assetRef = useRef(null);
    const [rows, setRows] = useState([]);
    const assetRequests = useSelector(selectParticipantAssetRequests);
    const assetRequestStatusDetails = useSelector(selectAssetRequestStatus);
    const assets = useSelector(selectAssetRequestAsset);
    const users = useSelector(selectUsers);

    useEffect(() => {
        if (assets?.length < 1 || assetRequestStatusDetails?.length < 1 || users?.length < 1)
            return;
        if (assetRequests?.length < 1) {
            onIsLoaded('assetRequest');
            return;
        }
        const data = assetRequests.map((el) => {
            const authoriser = users.find((entry) => entry.id === el.authoriserId);
            const { firstName, lastName } = authoriser
                ? authoriser
                : { firstName: ' - ', lastName: '' };
            return {
                code: el.code,
                asset: getNameFromId(assets, el?.assetId) || ' - ',
                status: getNameFromId(assetRequestStatusDetails, el?.statusId) || ' - ',
                statusChangeDate: el?.statusChangeDate
                    ? reverseFormatDate(el?.statusChangeDate)
                    : ' - ',
                quantity: el?.quantity,
                dateIssued: el?.dateIssued ? reverseFormatDate(el?.dateIssued) : ' - ',
                authoriser: firstName && lastName ? '${firstName} ${lastName}' : ' - '
            };
        });
        setRows(data);
    }, [assets, assetRequests, assetRequestStatusDetails, users]);

    useEffect(() => {
        assetRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('assetRequest');
    }, [assetRef.current]);

    return (
        <div>
            <h3>Asset Request</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>ID</span>
                    <span>Asset</span>
                    <span>Status</span>
                    <span className={classes.alignLeftMinusMin}>Status Change Date</span>
                    <span className={classes.alignCenter}>Quantity</span>
                    <span>Date Issued</span>
                    <span>Authoriser</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (assetRef.current = el)}
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.asset}</span>
                                <span>{el.status}</span>
                                <span>{el.statusChangeDate}</span>
                                <span className={classes.alignCellCenter}>{el.quantity}</span>
                                <span
                                    className={`${
                                        el.dateIssued === ' - ' ? classes.alignCenter : ''
                                    }`}>
                                    {el.dateIssued}
                                </span>

                                <span
                                    className={`${
                                        el.authoriser === ' - ' ? classes.alignCenter : ''
                                    }`}>
                                    {el.authoriser}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssetRequestTable;

AssetRequestTable.propTypes = {
    onIsLoaded: PropTypes.func
};
