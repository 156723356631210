import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { hasRole, SUPERUSER } from '../../utils/userRoles';
import MultiSelect from '../ui/pickers/MultiSelect';

import form from '../../commonStyles/formStyles.module.css';

const ClaimStatusField = ({
    status,
    claimId,
    roles,
    newEntry,
    existingClaim,
    onUpdateToStatuses,
    onUpdateFromStatus,
    onUpdateStarting
}) => {
    const acceptedRoles = [SUPERUSER];
    const initialCheckState = {
        status: false,
        starting: false
    };
    const [checks, setChecks] = useState(initialCheckState);
    const [toStatusesArray, setToStatusesArray] = useState([]);
    const [isOptionsDisabled, setIsOptionsDisabled] = useState(true);
    const [isStartingDisabled, setIsStartingDisabled] = useState(true);
    const [preSelectedStatusIds, setPreSelectedStatusIds] = useState([]);
    const [preSelectedStatuses, setPreSelectedStatuses] = useState([]);

    const claimEventStatuses = useSelector(
        (state) => state.entities.directusService.claimEventStatuses
    );

    const onUpdatedState = () => {
        const statusExists = newEntry.claimTypeStatuses.find((el) => el.statusId === status.id);
        const startingExists = newEntry.claimTypeStatuses.find((el) => el.startingStatus === true);

        setIsOptionsDisabled(!statusExists);
        setIsStartingDisabled(
            (startingExists && startingExists.statusId !== status.id) || !statusExists
        );
        setChecks({
            status: !!statusExists,
            starting: startingExists?.statusId === status.id
        });

        const updatedOptions = claimEventStatuses.filter((el) =>
            newEntry.claimTypeStatuses.some(
                (entry) => entry.statusId === el.id && entry.statusId !== status.id
            )
        );
        setToStatusesArray(updatedOptions);
    };

    useEffect(() => {
        if (Object.keys(existingClaim).length < 1) return;
        const foundOption = existingClaim.claimTypeStatuses.find((el) => el.statusId === status.id);
        const foundOptionIds = foundOption?.claimTypeToStatusIds || [];
        setPreSelectedStatusIds(foundOptionIds);
        setPreSelectedStatuses(
            claimEventStatuses.filter((el) => foundOptionIds.includes(el.id)) || []
        );
    }, [existingClaim]);

    useEffect(() => {
        if (!newEntry) return;
        onUpdatedState();
    }, [newEntry]);

    const onCheckStarting = (e) => onUpdateStarting(status.id, e.target.checked);
    const onCheckStatus = (e) => onUpdateFromStatus(status.id, e.target.checked);
    const onSelectedChange = (ids) => onUpdateToStatuses(status.id, ids);

    return (
        <div className={form.formSection}>
            <div className={form.formColumn}>
                <div className={form.checkboxes}>
                    <div className={form.checkboxSetLabel}>From Status</div>
                    <div className={form.checkboxSet}>
                        <input
                            type="checkbox"
                            checked={checks.status}
                            onChange={(e) => onCheckStatus(e)}
                        />
                        <label htmlFor={status.name}>{status.name}</label>
                    </div>
                    <div className={form.checkboxSet}>
                        <input
                            type="checkbox"
                            checked={checks.starting}
                            onChange={onCheckStarting}
                            disabled={isStartingDisabled}
                        />
                        <label
                            htmlFor="startingStatus"
                            className={`${isStartingDisabled ? form.disabledLabel : ''}`}>
                            Starting Status
                        </label>
                    </div>
                </div>
            </div>

            <div className={form.formColumn}>
                <div className={form.alignUpLg}>
                    <MultiSelect
                        disabled={!hasRole(acceptedRoles, roles) || isOptionsDisabled}
                        id={`toStatuses-${claimId}-${status.id}`}
                        label="To Statuses"
                        placeholder="Select statuses"
                        menuItems={toStatusesArray || []}
                        preSelectedIds={preSelectedStatusIds}
                        preSelects={preSelectedStatuses}
                        compareUpdatedItems={true}
                        onChange={(chosenIds) => onSelectedChange(chosenIds)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClaimStatusField;

ClaimStatusField.propTypes = {
    newEntry: PropTypes.object,
    existingClaim: PropTypes.object,
    status: PropTypes.object,
    claimId: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    onUpdateToStatuses: PropTypes.func,
    onUpdateFromStatus: PropTypes.func,
    onUpdateStarting: PropTypes.func
};
