import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';

import { setErrorMessage } from '../../../../store/formsState';
import { POLARIS_DARKER_GREY, POLARIS_WHITE } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LabelledTextField from '../../editors/LabelledTextField';
import DDLOptionPicker from '../../pickers/DDLOptionPicker';

const WorkHistoryEditor = ({
    cardData,
    disabled = false,
    sendChange,
    employmentTypeDetails,
    reasonForLeavingDetails
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        companyName: null,
        employmentTypeId: null,
        jobTitle: null,
        startDate: '',
        endDate: '',
        reasonForLeavingId: null,
        description: ''
    };

    const MAX_DESCRIPTION_LENGTH = 2000;
    const [newEntry, setNewEntry] = useState(initialState);
    const [open, setOpen] = React.useState(false);
    const [description, setDescription] = useState('');

    const [isClearSelectedEmploymentType, setIsClearSelectedEmploymentType] = useState('1');
    const [isClearSelectedReasonForLeaving, setIsClearSelectedReasonForLeaving] = useState('2');

    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;

    // First time on card and blank so must be new.
    // Open dialog to force user to enter data
    useEffect(() => {
        if (!cardData.companyName) {
            if (disabled === false) {
                setOpen(true);
            }
        }
        setCardData();
    }, []);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);

    // EVENT HANDLERS
    const handleCompanyNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, companyName: e.target.value }));
    };
    const handleEmploymentTypeIdChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, employmentTypeId: chosenId }));
        setValue('employmentTypeId', chosenId, { shouldValidate: true });
    };
    const handleJobTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, jobTitle: e.target.value }));
    };

    const handleStartDateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, startDate: e.target.value }));
    };

    const handleEndDateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, endDate: e.target.value }));
    };
    const handleReasonForLeavingIdChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, reasonForLeavingId: chosenId }));
    };

    const handleDescriptionChange = (e) => {
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
        setDescription(e.target.value);
    };

    const handleClickOpen = () => {
        if (disabled === false) {
            setOpen(true);
        }
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        // Reset info to cardData
        setCardData();
        reset();
        clearErrors();
        setIsClearSelectedEmploymentType(Math.random());
        setIsClearSelectedReasonForLeaving(Math.random());

        setOpen(false);
    };

    const handleUpdate = () => {
        clearErrors();
        sendChange(newEntry);
        setOpen(false);
    };

    const setCardData = () => {
        setNewEntry((prev) => ({
            ...prev,
            id: cardData.id,
            companyName: cardData.companyName,
            employmentTypeId: cardData.employmentTypeId,
            jobTitle: cardData.jobTitle,
            startDate: cardData.startDate?.substring(0, 10) || null,
            endDate: cardData.endDate?.substring(0, 10) || null,
            reasonForLeavingId: cardData.reasonForLeavingId,
            description: cardData.description
        }));
        setDescription(cardData.description);
    };

    const onSubmit = () => {
        if (newEntry?.endDate < newEntry.startDate) {
            dispatch(setErrorMessage('End date must not be earlier than start date'));
        } else {
            handleUpdate();
        }
    };

    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <EditIcon
                onClick={handleClickOpen}
                sx={{ color: iconColor }}
                data-testid="editor-icon"></EditIcon>

            <Dialog open={open} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Past Employment</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <LabelledTextField
                            id="companyName"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            mandatory={true}
                            label="Company Name"
                            value={newEntry.companyName || ''}
                            placeholder="Enter company name..."
                            error={errors.companyName}
                            {...register('companyName')}
                            onChange={handleCompanyNameChange}
                        />
                        <DDLOptionPicker
                            label={'Employment Type'}
                            id="employmentTypeId"
                            key={isClearSelectedEmploymentType}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            mandatory={true}
                            menuItems={employmentTypeDetails}
                            chosenName={
                                newEntry.employmentTypeId
                                    ? getNameFromId(
                                          employmentTypeDetails,
                                          newEntry.employmentTypeId
                                      )
                                    : ''
                            }
                            chosenId={newEntry?.employmentTypeId}
                            error={errors.employmentTypeId}
                            {...register('employmentTypeId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleEmploymentTypeIdChange(e);
                                }
                            }}
                        />
                        <LabelledTextField
                            id="jobTitle"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            mandatory={true}
                            label="Job Title"
                            value={newEntry.jobTitle || ''}
                            placeholder="Enter job title..."
                            error={errors.jobTitle}
                            {...register('jobTitle')}
                            onChange={handleJobTitleChange}
                        />
                        <TextField
                            id="startDate"
                            label="Start Date"
                            type="date"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            value={newEntry.startDate || ''}
                            sx={{ width: 220, marginBottom: '40px', marginRight: '20px' }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleStartDateChange}
                        />
                        <TextField
                            id="endDate"
                            label="End Date"
                            type="date"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            value={newEntry.endDate || ''}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleEndDateChange}
                        />
                        <DDLOptionPicker
                            label={'Reason For Leaving'}
                            id="reasonForLeavingId"
                            key={isClearSelectedReasonForLeaving}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            menuItems={reasonForLeavingDetails}
                            chosenName={
                                newEntry.reasonForLeavingId
                                    ? getNameFromId(
                                          reasonForLeavingDetails,
                                          newEntry.reasonForLeavingId
                                      )
                                    : ''
                            }
                            chosenId={newEntry?.reasonForLeavingId}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleReasonForLeavingIdChange(e);
                                }
                            }}
                        />
                        <br />
                        <br />
                        <br />
                        <LabelledTextField
                            label={'Description'}
                            id={'notes'}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            mandatory={false}
                            multiline
                            rows={5}
                            value={description}
                            placeholder={'Enter job description'}
                            counter={'true'}
                            helperText={`${description?.length}` + '/' + MAX_DESCRIPTION_LENGTH}
                            inputProps={{
                                maxLength: MAX_DESCRIPTION_LENGTH
                            }}
                            onChange={handleDescriptionChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Update
                        </Button>
                        <Button
                            onClick={handleCancel}
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    companyName: Yup.string()
        .trim()
        .min(1, 'Company name must be at least one character')
        .max(200, 'Company name must be 200 characters or less'),
    jobTitle: Yup.string()
        .trim()
        .min(1, 'Job title must be at least one character')
        .max(100, 'Job title must be 100 characters or less'),
    employmentTypeId: Yup.string().required('Please select an employment type.')
});

WorkHistoryEditor.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendChange: PropTypes.func.isRequired,
    employmentTypeDetails: PropTypes.array.isRequired,
    reasonForLeavingDetails: PropTypes.array.isRequired
};
export default WorkHistoryEditor;
