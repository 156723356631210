import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUsers } from '../../../../../store/userSelectors';
import SummaryData from '../summaryData/SummaryData';

import ReferralHistoryTable from './ReferralHistoryTable';

import app from '../../../../../app.module.css';
import local from '../../wellbeing.module.css';

const results = {
    201: 'Second nature referral successfully created',
    400: 'A referral already exists for this email/phone number',
    409: 'A referral already exists with Second Nature for this member',
    500: 'API Error, please contact the Systems Administration team',
    504: 'API Error, please contact the Systems Administration team'
};

const ReferralHistory = () => {
    const [rows, setRows] = useState([]);
    const users = useSelector(selectUsers);
    const referralHistory = useSelector(
        (state) => state.entities.participantService.referralHistory
    );

    useEffect(() => {
        if (!referralHistory || !users) return;
        const rows = referralHistory.map((el) => ({
            ...el,
            result: results[el.responseCode],
            userEmail: users.find((entry) => entry.id === el.userId)?.emailAddress || ''
        }));
        setRows(rows);
    }, [referralHistory, users]);

    return (
        <div className={local.tableSection}>
            {rows.length < 1 ? null : (
                <>
                    <div className={app.sectionHeading}>Second Nature Referral History</div>
                    <ReferralHistoryTable rows={rows} />
                    <SummaryData />
                </>
            )}
        </div>
    );
};

export default ReferralHistory;

ReferralHistory.propTypes = {
    disableCreate: PropTypes.bool
};
