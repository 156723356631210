import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { TableCell, TableRow } from '@mui/material';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';

import classes from '../../../../../commonStyles/tables.module.css';

const SummaryDataRow = ({ row }) => {
    return (
        <TableRow hover className={`${classes.basicRowStripes}`}>
            <TableCell>{reverseFormatDate(row.date)}</TableCell>
            <TableCell align="center">
                {row.attended === true ? (
                    <CheckBoxOutlinedIcon />
                ) : (
                    <CheckBoxOutlineBlankOutlinedIcon />
                )}
            </TableCell>
            <TableCell align="center"> {row.sessionNumber} </TableCell>
            <TableCell align="center"> {row.weight || ' - '} </TableCell>
            <TableCell align="center"> {row.height || ' - '} </TableCell>
        </TableRow>
    );
};

export default SummaryDataRow;

SummaryDataRow.propTypes = {
    row: PropTypes.object
};
