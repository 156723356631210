import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadContractDetailsForContractIds } from '../../store/directusService';
import { loadEligibilityGroups } from '../../store/participantService';
import { selectUserRoles } from '../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../themes/theme';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import DynamicSearch from '../search/DynamicSearch';

import CreateEligibilityGroup from './CreateEligibilityGroup';
import EligibilityGroupTable from './EligibilityGroupTable';

import classes from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const EligibilityGroupManagement = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER];
    const [groups, setGroups] = useState([]);
    const [rows, setRows] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const eligibilityGroups = useSelector(
        (state) => state.entities.participantService.eligibilityGroups
    );
    const contracts = useSelector((state) => state.entities.directusService.contractDetails);

    // HELPER FNS
    const scrollEditIntoView = () => {
        if (formRef?.current) {
            formRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        eligibilityGroups?.length < 1 && dispatch(loadEligibilityGroups());
    }, []);

    useEffect(() => {
        if (!eligibilityGroups) return;
        const contractIds = [...new Set(eligibilityGroups.map((el) => el.contractId))];
        contractIds.length > 0 && dispatch(loadContractDetailsForContractIds(contractIds));
    }, [eligibilityGroups]);

    useEffect(() => {
        if (contracts.length < 1 || eligibilityGroups?.length < 1) return;
        const groups = eligibilityGroups.map((el) => ({
            ...el,
            contractName: contracts.find((entry) => entry.id === el.contractId)?.name || ''
        }));
        setGroups(groups);
        setRows(groups);
    }, [contracts]);

    useEffect(() => {
        if (groups.length < 1) return;
        const filteredRows = !searchTerm
            ? groups
            : groups.filter((el) => el.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setRows(filteredRows);
    }, [searchTerm]);

    // EVENT HANDLERS

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
        scrollEditIntoView();
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        scrollEditIntoView();
    };

    const noContent = 'No eligibility groups';

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.mainHeading}>Manage Eligibility Groups</div>

                <div className={classes.whiteContainer}>
                    <div className={classes.searchWrapper}>
                        <DynamicSearch
                            search={onSearch}
                            searchTerm={searchTerm}
                            placeholder="Enter search term"
                        />
                    </div>

                    {rows.length < 1 ? (
                        noContent
                    ) : (
                        <div>
                            <EligibilityGroupTable
                                rows={rows}
                                roles={roles}
                                onEdit={onEdit}
                                searchTerm={searchTerm}
                            />
                        </div>
                    )}
                </div>

                <div className={form.formEnd}>
                    {!showForm && (
                        <Button
                            disabled={!hasRole(acceptedRoles, roles)}
                            color="primary"
                            variant="contained"
                            size={'small'}
                            onClick={onCreate}>
                            Create Eligibility Group
                            <Box width={SCREEN_SETTINGS.gap.medium} />
                            <AddCircleOutlineIcon />
                        </Button>
                    )}
                    <div ref={formRef}>
                        {showForm && (
                            <CreateEligibilityGroup
                                onClose={() => setShowForm(false)}
                                roles={roles}
                                formType={formType}
                                row={selectedRow}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EligibilityGroupManagement;
