import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import { POLARIS_BLACK, POLARIS_ROYAL_BLUE } from '../../../../../themes/theme';
import { reverseFormatDate } from '../../../../../utils/dateFunctions';

import InWorkSupportDetails from './InWorkSupportDetails';

import classes from '../../../../../commonStyles/tables.module.css';
import claimsClasses from '../../../../claims/claimStyles/validations.module.css';
import iwsClasses from '../../submissionStyles/iws.module.css';

const InWorkSupportTableRow = ({ row, toggleDropdown, openId, onAddCallEditor, onAddCallRow }) => {
    // LOCAL STATE
    const [open, setOpen] = useState(false);
    const dateFormat = 'yyyy-MM-dd';
    const today = new Date();

    // CALLBACKS
    const onToggleDropdown = (e) => {
        if (e.target.nodeName !== 'TD') return;
        toggleDropdown(row.id, e);
        setOpen(open === row.id ? null : row.id);
    };

    function onAddCall() {
        onAddCallRow(row);
        onAddCallEditor(true);
    }

    // USE EFFECTS
    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}>
                <TableCell
                    className={
                        (format(today, dateFormat) === row.dueDate.substring(0, 10) &&
                            row.outcomeName !== 'Contacted' &&
                            claimsClasses.claimsCodeLink) ||
                        ''
                    }
                    onClick={
                        format(today, dateFormat) === row.dueDate.substring(0, 10) &&
                        row.outcomeName !== 'Contacted'
                            ? onAddCall
                            : null
                    }
                    style={{
                        color:
                            format(today, dateFormat) === row.dueDate.substring(0, 10) &&
                            row.outcomeName !== 'Contacted'
                                ? POLARIS_ROYAL_BLUE
                                : POLARIS_BLACK,
                        cursor:
                            format(today, dateFormat) === row.dueDate.substring(0, 10) &&
                            row.outcomeName !== 'Contacted'
                                ? 'pointer'
                                : 'default'
                    }}>
                    {reverseFormatDate(row.dueDate)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{`Week ${row.frequencyWeekNo}`}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row.callDate
                        ? `${reverseFormatDate(row?.callDate)} ${row.callDate?.substring(16, 11)}`
                        : '-'}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row.workConfidenceName === 'Low' ? (
                        <section className={iwsClasses.green}>{row.workConfidenceName}</section>
                    ) : row.workConfidenceName === 'Medium' ? (
                        <section className={iwsClasses.amber}>{row.workConfidenceName}</section>
                    ) : row.workConfidenceName === 'High' ? (
                        <section className={iwsClasses.red}>{row.workConfidenceName}</section>
                    ) : (
                        '-'
                    )}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row.outcomeName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={5}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <InWorkSupportDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default InWorkSupportTableRow;

InWorkSupportTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string,
    onAddCallEditor: PropTypes.func,
    onAddCallRow: PropTypes.func
};
