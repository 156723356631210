import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    clearQuestionnaireAnswers,
    loadQuestionnaires,
    setCurrentQuestionnaire
} from '../../store/participantService';
import { SCREEN_SETTINGS } from '../../themes/theme';
import { hasRole, MANAGER, QUALITY, SUPERUSER } from '../../utils/userRoles';
import FormHeader from '../layout/FormHeader';
import DynamicSearch from '../search/DynamicSearch';
import LoadingSpinner from '../ui/LoadingSpinner';

import QuestionnaireTable from './QuestionnaireTable';

import classes from './questionnaireStyles/questionnaireToolbar.module.css';

const QuestionnaireManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState(rows);
    const [searchTerm, setSearchTerm] = useState('');

    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { questionnaires } = useSelector((state) => state.entities.participantService);

    useEffect(() => {
        dispatch(loadQuestionnaires());
    }, []);

    useEffect(() => {
        setRows(questionnaires);
        setFilteredRows(questionnaires);
    }, [questionnaires]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.title.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredRows(filteredRows);
    }, [searchTerm]);

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onCreateQuestionnaire = () => {
        dispatch(setCurrentQuestionnaire({})); // clear current questionnaire if selected
        dispatch(clearQuestionnaireAnswers()); //clear results
        navigate('/build_questionnaire');
    };

    return (
        <div>
            <br />
            <FormHeader text={'Manage Questionnaire'}></FormHeader>
            <div className={classes.questionnaireToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter search term"
                />
            </div>
            {filteredRows.length < 1 ? (
                <LoadingSpinner content="No questionnaires found" />
            ) : (
                <QuestionnaireTable rows={filteredRows} roles={roles} searchTerm={searchTerm} />
            )}
            <Button
                disabled={!hasRole([SUPERUSER, MANAGER, QUALITY], roles)}
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton"
                onClick={onCreateQuestionnaire}>
                {'Create Questionnaire'}
                <Box width={SCREEN_SETTINGS.gap.medium} />
                <AddCircleOutlineIcon />
            </Button>
        </div>
    );
};

export default QuestionnaireManagement;
