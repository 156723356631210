import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from '@mui/material';

const AppointmentServiceSelect = ({
    serviceDetails,
    serviceName,
    currentServiceId,
    onServiceChange,
    disabled
}) => {
    const onChange = (_, val) => {
        if (!val || val.id === currentServiceId) return;
        onServiceChange({
            serviceId: val?.id || '',
            service: val?.name || ''
        });
    };

    return (
        <div className="input-set">
            <label htmlFor="service">Service</label>
            <div className="selector-input input">
                <Autocomplete
                    disablePortal
                    id="service"
                    key={Math.random().toString(36).slice(0, 5)}
                    disabled={disabled}
                    options={serviceDetails || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => <TextField {...params} placeholder={serviceName} />}
                    classes={{ listbox: 'menu-list' }}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default AppointmentServiceSelect;

AppointmentServiceSelect.propTypes = {
    serviceDetails: PropTypes.arrayOf(PropTypes.object),
    serviceName: PropTypes.string,
    currentServiceId: PropTypes.string,
    onServiceChange: PropTypes.func,
    disabled: PropTypes.bool
};
