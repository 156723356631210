import { isDateFuture } from '../../../../utils/dateFunctions';

const ERROR_BAD_DATE = 'Date must be in the past or present';
const ERROR_BAD_VALUE = 'Value must be between 1 and 999999';

export const initialErrorState = {
    stepsValue: { error: false, message: 'Please enter a value' },
    stepsDate: { error: false, message: 'Please enter a date' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (newEntry.stepsValue && +newEntry.stepsValue <= 0) {
        newErrors = {
            ...newErrors,
            stepsValue: { error: true, message: ERROR_BAD_VALUE }
        };
        isValid = false;
    }
    if (!newEntry.stepsValue) {
        newErrors = {
            ...newErrors,
            stepsValue: { ...errors.stepsValue, error: true }
        };
        isValid = false;
    }
    if (!newEntry.stepsDate) {
        newErrors = {
            ...newErrors,
            stepsDate: { ...errors.stepsDate, error: true }
        };
        isValid = false;
    }
    if (isDateFuture(newEntry.stepsDate)) {
        newErrors = {
            ...newErrors,
            stepsDate: { error: true, message: ERROR_BAD_DATE }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};

export const checkDate = (date, errors) => {
    return isDateFuture(date)
        ? {
              ...errors,
              stepsDate: { error: true, message: ERROR_BAD_DATE }
          }
        : {
              ...errors,
              stepsDate: initialErrorState.stepsDate
          };
};

export const checkValue = (value, errors) => {
    return value <= 0
        ? {
              ...errors,
              stepsValue: { error: true, message: ERROR_BAD_VALUE }
          }
        : {
              ...errors,
              stepsValue: initialErrorState.stepsValue
          };
};
