import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import { selectActionPlanOutcomes } from '../../store/dataSelectors';
import { loadActionPlanStatusDetails } from '../../store/directusService';
import { selectUserRoles } from '../../store/userSelectors';
import { updatePreSelectedMultiItems, updateSelectedItems } from '../../utils/directusFunctions';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';
const ParticipantActionPlanAndGoalsAdmin = ({ contractId }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();

    // LOCAL STATE
    const submitMessage = 'action plan status';
    const [selectedOutcomes, setSelectedOutcomes] = useState([]);
    const [preSelectedOutcomes, setPreSelectedOutcomes] = useState([]);
    const [arrayOutcomes, setArrayOutcomes] = useState([]);
    const [isClearSelectedOutcomes, setIsClearSelectedOutcomes] = useState('0');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const outcomes = useSelector(selectActionPlanOutcomes);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    //HELPER FNS
    const contractSetup = () => {
        setSelectedOutcomes([]);
        setPreSelectedOutcomes([]);
        setArrayOutcomes([]);
        setIsClearSelectedOutcomes(Math.random());
        dispatch(loadActionPlanStatusDetails());
    };
    // USEEFFECTS

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (outcomes?.length && arrayOutcomes?.length === 0) {
            setPreSelectedOutcomes(
                updatePreSelectedMultiItems('action_plan_status', outcomes, contractId)
            );
            setArrayOutcomes(outcomes);
        }
    }, [outcomes]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${submitMessage} collection`)) {
            contractSetup();
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onOutcomeChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('outcomes', chosenIds, { shouldValidate: true });
        setSelectedOutcomes(chosenIds);
    };

    // FORM SUBMIT
    const onSubmit = () => {
        updateSelectedItems(
            'action_plan_status',
            outcomes,
            selectedOutcomes,
            contractId,
            submitMessage,
            dispatch
        );
    };

    // RENDER
    let content;
    if (!contractId) content = 'No Contract Id';
    if (outcomes?.length < 1) content = 'No outcomes';

    return !contractId || outcomes?.length < 1 ? (
        <LoadingSpinner content={content} />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DDLMultiOptionPicker
                heading={'Outcomes'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="Outcomes"
                id="outcomes"
                mandatory={true}
                key={isClearSelectedOutcomes}
                label="Action Plan Outcomes"
                placeholder="Action Plan Outcomes"
                menuItems={outcomes || []}
                preSelectedIds={preSelectedOutcomes}
                chosenIds={selectedOutcomes}
                error={errors.outcomes}
                {...register('outcomes')}
                onChange={(chosenIds) => onOutcomeChange(chosenIds)}
            />

            <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton">
                {'UPDATE OUTCOMES'}
            </Button>
        </form>
    );
};

const validationSchema = Yup.object().shape({
    outcomes: Yup.array().min(1, 'No outcome selected.').nullable()
});

ParticipantActionPlanAndGoalsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ParticipantActionPlanAndGoalsAdmin;
