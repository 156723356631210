import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { addPostcodeCsv } from '../../store/documentService';

import Uploader from './Uploader';
import UploaderResults from './UploaderResults';

import modal from '../../commonStyles/modal.module.css';

const maxFileSize = 5;
const fileTypes = '.csv';
const acceptedFileTypes = ['text/csv'];

const PostcodeUploader = ({ contractId, onClose, onCancel, isOpen }) => {
    const dispatch = useDispatch();

    const [failures, setFailures] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fileName, setFileName] = useState('');

    const onUpload = (payload, successMsg) => {
        dispatch(addPostcodeCsv(contractId, payload, successMsg));
    };

    const onLoaded = (uploadErrors, csvFileName) => {
        uploadErrors && setFailures(uploadErrors);
        csvFileName && setFileName(csvFileName.split('.')[0]);
        setIsLoaded(true);
    };

    const onResultsClose = () => {
        setFailures([]);
        setIsLoaded(false);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth={true}>
            <div className={modal.modalHeader}>
                <DialogTitle>Postcode Uploader</DialogTitle>
            </div>
            <DialogContent>
                {!isLoaded ? (
                    <Uploader
                        uploadData={[{ key: 'contractId', value: contractId }]}
                        acceptedFileTypes={acceptedFileTypes}
                        fileTypes={fileTypes}
                        maxFileSize={maxFileSize}
                        onUpload={onUpload}
                        onClose={onLoaded}
                        onCancel={onCancel}
                        showLoadingNotice={true}
                    />
                ) : (
                    <UploaderResults
                        fileName={fileName}
                        failures={failures}
                        onClose={onResultsClose}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PostcodeUploader;

PostcodeUploader.propTypes = {
    contractId: PropTypes.string,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
};
