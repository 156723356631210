import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from '@mui/material';

const AppointmentTitleSelect = ({ appointmentTypes, appointmentType, onTitleChange }) => {
    const onChange = (val) => {
        if (!val) return;
        onTitleChange({ typeId: val.id, appointmentType: val.name });
    };

    return (
        <div className="appointment-title">
            <Autocomplete
                disablePortal
                id="appointment-title"
                options={appointmentTypes || []}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                    <TextField {...params} placeholder={appointmentType || 'Appointment type'} />
                )}
                classes={{ listbox: 'menu-list' }}
                onChange={(_, val) => onChange(val)}
            />
        </div>
    );
};

export default AppointmentTitleSelect;

AppointmentTitleSelect.propTypes = {
    appointmentTypes: PropTypes.arrayOf(PropTypes.object),
    appointmentType: PropTypes.string,
    onTitleChange: PropTypes.func
};
