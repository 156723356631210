export const CANCEL = 'Cancel';
export const CREATE = 'Create';
export const UPDATE = 'Update';

export const SAVE = 'Save';
export const RETURN = 'Return';

export const NO_SELECTION = '  no selection';

export const NHS_NUMBER = 'NHS_NUMBER';
export const NINO = 'NINO';

export const DEFAULT_TABLE_ROWS_PER_PAGE = 10;
export const MAX_TABLE_ROWS_PER_PAGE = 100;

// Number of rows user can view on a table
export const DEFAULT_TABLE_ROW_OPTIONS = [5, 10, 25, 50, 100];
