import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../utils/dateFunctions';

import OccSummaryDataTable from './OccSummaryDataTable';

import app from '../../../../app.module.css';
import local from './occStyles.module.css';

const OccSummaryData = ({ currentParticipant }) => {
    const [rows, setRows] = useState([]);
    const occActivity = useSelector((state) => state.entities.participantService.occActivity);

    useEffect(() => {
        setRows([]);
    }, []);

    useEffect(() => {
        if (occActivity?.length < 1 || occActivity[0].participantId !== currentParticipant.id)
            return;
        const rows = occActivity.map((el) => ({
            ...el,
            eventDate: reverseFormatDate(el.date),
            visits: el.visits || 'zz',
            group: el.group || 'zz',
            type: el.type || 'zz'
        }));

        setRows(rows);
    }, [occActivity]);

    return (
        <div className={local.occSummaryWrapper}>
            <div className={app.sectionHeading}>OCC Summary Data</div>
            {rows.length < 1 ? <div>No summary data</div> : <OccSummaryDataTable rows={rows} />}
        </div>
    );
};

export default OccSummaryData;

OccSummaryData.propTypes = {
    currentParticipant: PropTypes.object
};
