import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { loadVacancyStageDetails } from '../../store/directusService';
import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';

import BusinessManagement from './businesses/myBusinesses/BusinessManagement';
import OtherBusinessManagement from './businesses/otherBusinesses/OtherBusinessManagement';
import PoolTableManagement from './vacanciesAndPooling/pools/PoolTableManagement';
import VacancyTableManagement from './vacanciesAndPooling/vacancies/VacancyTableManagement';

import app from '../../app.module.css';

const RecruitmentManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const { state } = useLocation();
    const [expanded, setExpanded] = useState('');

    // STORE STATE
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );

    // USE EFFECTS
    useEffect(() => {
        if (!state?.accordionPanel) {
            setExpanded('');
        } else {
            setExpanded(state.accordionPanel);
        }
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    // EVENT HANDLERS
    const onChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={app.pageContainer}>
            <h1 className={app.mainHeading}>Recruitment Management</h1>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'myBusinesses'}
                onChange={onChange('myBusinesses')}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls="myBusinesses-content"
                    id="myBusinesses-header">
                    <AccordionHeader>My Businesses</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <BusinessManagement isOpen={expanded === 'myBusinesses'} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'otherBusinesses'}
                onChange={onChange('otherBusinesses')}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls="otherBusinesses-content"
                    id="otherBusinesses-header">
                    <AccordionHeader>Other Businesses</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <OtherBusinessManagement isOpen={expanded === 'otherBusinesses'} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'vacancies'}
                onChange={onChange('vacancies')}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls="vacancies-content"
                    id="vacancies-header">
                    <AccordionHeader>Vacancies</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <VacancyTableManagement />
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'pooling'}
                onChange={onChange('pooling')}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls="pooling-content"
                    id="pooling-header">
                    <AccordionHeader>Pooling</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <PoolTableManagement />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default RecruitmentManagement;
