import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from '@mui/material';

import { updateCourseProvision } from '../../../store/courseService';
import {
    loadAllExternalCourseProviderDetails,
    loadContractDetails
} from '../../../store/directusService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import * as validate from '../../../validation/validation';
import MultiSelect from '../../formElements/MultiSelect';
import FormHeader from '../../layout/FormHeader';
import BusyIndicator from '../../ui/BusyIndicator';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

import form from '../../../commonStyles/formStyles.module.css';
import pageClasses from '../courseStyles/editProvisionStyles.module.css';

const CHARACTER_LIMIT = 250;

const EditProvision = () => {
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    // State for edit provision newEntry
    // This matches the payload sent to the backend
    const initialState = {
        nameId: null,
        title: '',
        description: '',
        deliveryInformation: '',
        qualificationRequired: '',
        registrationMethods: '',
        active: true,
        paymentMethod: 'Invoice',
        costPerParticipant: null,
        ofqualNumber: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);

    const [selectedContracts, setSelectedContracts] = useState([]);

    const [arrayContracts, setArrayContracts] = useState([]);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { currentProvision } = useSelector((state) => state.entities.courseService);
    const { contractDetails, externalCourseProviderDetails } = useSelector(
        (state) => state.entities.directusService
    );

    const handleDescriptionChange = (e) => {
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };

    const handleDeliveryInformationChange = (e) => {
        setNewEntry((prev) => ({ ...prev, deliveryInformation: e.target.value }));
    };

    const handleQualificationRequiredChange = (e) => {
        setNewEntry((prev) => ({ ...prev, qualificationRequired: e.target.value }));
    };

    const handleRegistrationMethodsChange = (e) => {
        setNewEntry((prev) => ({ ...prev, registrationMethods: e.target.value }));
    };

    const handleActiveChange = (option) => {
        setNewEntry((prev) => ({ ...prev, active: option }));
    };

    const handlePaymentMethodChange = (e) => {
        setNewEntry((prev) => ({ ...prev, paymentMethod: e.target.value }));
    };

    const handleCostPerParticipantChange = (e) => {
        setNewEntry((prev) => ({ ...prev, costPerParticipant: e.target.value }));
    };

    const onContractChange = (chosenIds) => {
        setSelectedContracts(chosenIds);
    };

    const handleOfqualNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, ofqualNumber: e.target.value }));
    };

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
    };

    const getProviderName = (nameId) => {
        return externalCourseProviderDetails.find((el) => el.id === nameId)?.name || '';
    };

    useEffect(() => {
        setNewEntry(currentProvision);
        setSelectedContracts(currentProvision.contractIds);
        dispatch(loadContractDetails());
        dispatch(loadAllExternalCourseProviderDetails());

        setValue('description', currentProvision.description, {
            shouldValidate: true
        });
        setValue('deliveryInformation', currentProvision.deliveryInformation, {
            shouldValidate: true
        });
        setValue('qualificationRequired', currentProvision.qualificationRequired, {
            shouldValidate: true
        });
        setValue('registrationMethods', currentProvision.registrationMethods, {
            shouldValidate: true
        });
        setValue('costPerParticipant', currentProvision.costPerParticipant, {
            shouldValidate: true
        });
    }, []);

    useEffect(() => {
        if (
            contractDetails !== undefined &&
            contractDetails.length !== 0 &&
            arrayContracts.length === 0
        ) {
            setArrayContracts(contractDetails);
        }
    }, [contractDetails]);

    useEffect(() => {
        if (successMessage === `Course Provision details for ${newEntry.title} have been updated`)
            onNavigate();
    }, [successMessage]);

    const onSubmit = () => {
        const payload = { ...newEntry, contractIds: selectedContracts };
        dispatch(
            updateCourseProvision(
                `Course Provision details for ${newEntry.title} have been updated`,
                payload
            )
        );
    };

    const onNavigate = () => {
        clearData();
        navigate('/course_management', { state: { accordionPanel: 'provisions' } });
    };

    const onCancel = () => onNavigate();

    if (
        contractDetails.length < 1 ||
        externalCourseProviderDetails.length < 1 ||
        (!currentProvision && Object.keys(currentProvision).length !== 0)
    ) {
        return <BusyIndicator />;
    }

    return (
        <Card data-testid="form_start">
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start">
                    <FormHeader text={'Edit Provision'}></FormHeader>
                    <Typography variant="h6">Provider Name</Typography>
                    <p className={pageClasses.providerName}>{getProviderName(newEntry.nameId)}</p>
                    <Typography variant="h6">Provision Title</Typography>
                    <p className={pageClasses.provisionTitle}>{newEntry.title}</p>
                    <LabelledTextField
                        label={'Provision Description'}
                        id={'description'}
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        multiline
                        rows={5}
                        value={newEntry.description || ''}
                        placeholder={'Enter provision description'}
                        counter={'true'}
                        helperText={`${newEntry.description.length}` + '/' + CHARACTER_LIMIT}
                        inputProps={{
                            maxLength: CHARACTER_LIMIT
                        }}
                        error={errors.description}
                        {...register('description')}
                        onChange={handleDescriptionChange}
                    />
                    <LabelledTextField
                        label={'Delivery Information'}
                        id={'deliveryInformation'}
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        multiline
                        rows={5}
                        value={newEntry.deliveryInformation || ''}
                        placeholder={'Enter delivery information'}
                        counter={'true'}
                        helperText={
                            `${newEntry.deliveryInformation.length}` + '/' + CHARACTER_LIMIT
                        }
                        inputProps={{
                            maxLength: CHARACTER_LIMIT
                        }}
                        error={errors.deliveryInformation}
                        {...register('deliveryInformation')}
                        onChange={handleDeliveryInformationChange}
                    />
                    <LabelledTextField
                        label={'Qualifications Required'}
                        id={'qualificationRequired'}
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        multiline
                        rows={5}
                        value={newEntry.qualificationRequired || ''}
                        placeholder={'Enter qualifications required'}
                        counter={'true'}
                        helperText={
                            `${newEntry.qualificationRequired.length}` + '/' + CHARACTER_LIMIT
                        }
                        inputProps={{
                            maxLength: CHARACTER_LIMIT
                        }}
                        error={errors.qualificationRequired}
                        {...register('qualificationRequired')}
                        onChange={handleQualificationRequiredChange}
                    />
                    <LabelledTextField
                        label={'Registration Methods'}
                        id={'registrationMethods'}
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        multiline
                        rows={5}
                        value={newEntry.registrationMethods || ''}
                        placeholder={'Enter registration methods'}
                        counter={'true'}
                        helperText={
                            `${newEntry.registrationMethods.length}` + '/' + CHARACTER_LIMIT
                        }
                        inputProps={{
                            maxLength: CHARACTER_LIMIT
                        }}
                        error={errors.registrationMethods}
                        {...register('registrationMethods')}
                        onChange={handleRegistrationMethodsChange}
                    />
                    <NoYesRadioPicker
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        text={'Is Current Provision Active?'}
                        radioButtonPick={newEntry.active || true}
                        onChange={handleActiveChange}
                    />

                    <div className={form.radioGroup}>
                        <label htmlFor="paymentMethod" className={form.formLabel}>
                            Payment Method
                        </label>
                        <RadioGroup
                            row
                            aria-labelledby="paymentMethod"
                            id="paymentMethod"
                            name="paymentMethod"
                            value={newEntry.paymentMethod || 'Invoice'}
                            onChange={(e) => handlePaymentMethodChange(e)}>
                            <FormControlLabel
                                value="Invoice"
                                control={<Radio />}
                                label="Invoice"
                                size="small"
                            />
                            <FormControlLabel
                                value="Spendesk"
                                control={<Radio />}
                                label="Spendesk"
                                size="small"
                            />
                        </RadioGroup>
                    </div>
                    <LabelledTextField
                        label={'Cost Per participant'}
                        id={'costPerParticipant'}
                        value={newEntry.costPerParticipant || ''}
                        placeholder={'Enter amount'}
                        error={errors.costPerParticipant}
                        {...register('costPerParticipant')}
                        onChange={handleCostPerParticipantChange}
                    />
                    <MultiSelect
                        id="selectedContracts"
                        label="Contracts"
                        placeholder="Contract"
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        nonEditableIds={currentProvision.contractIds}
                        menuItems={arrayContracts || []}
                        preSelectedIds={selectedContracts}
                        preSelects={contractDetails.filter((el) =>
                            selectedContracts.includes(el.id)
                        )}
                        onChange={(chosenIds) => onContractChange(chosenIds)}
                    />
                    <LabelledTextField
                        label={'Ofqual Number'}
                        id={'ofqualNumber'}
                        value={newEntry.ofqualNumber || ''}
                        placeholder={'Enter ofqual number'}
                        error={errors.ofqualNumber}
                        {...register('ofqualNumber')}
                        onChange={handleOfqualNumberChange}
                    />
                    <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                    <Stack direction="row" spacing={4}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="testIdSubmitButton1"
                            endIcon={<KeyboardArrowRightIcon />}>
                            {'Update Provision'}
                        </Button>
                        <Link
                            label="Cancel X"
                            underline="always"
                            variant="body1"
                            color="primary"
                            onClick={() => onCancel()}
                            sx={{
                                paddingTop: SCREEN_SETTINGS.margin.large,
                                cursor: 'pointer'
                            }}>
                            Cancel X
                        </Link>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    );
};

const validationSchema = Yup.object().shape({
    description: Yup.string()
        .trim()
        .min(50, 'Provision description must be at least fifty characters.'),
    deliveryInformation: Yup.string()
        .trim()
        .min(50, 'Delivery information must be at least fifty characters.'),
    qualificationRequired: Yup.string()
        .trim()
        .min(50, 'Qualifications required must be at least fifty characters.'),
    registrationMethods: Yup.string()
        .trim()
        .min(50, 'Registration methods must be at least fifty characters.'),
    costPerParticipant: Yup.string()
        .required('Please enter a cost per participant')
        .nullable()
        .min(0, 'Cost per participant limit cannot be negative')
        .max(9999.99, 'Cost per participant limit must be 9999.99 or less')
        .matches(validate.PRICE_REGEXP, 'Invalid Amount'),
    ofqualNumber: Yup.string().trim().max(10, 'Ofqual number must be 10 characters or less.')
});

export default EditProvision;
