import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import { loadClaimEventStatuses, loadClaimEventTypes } from '../../../../store/directusService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { loadUsersByServiceIds } from '../../../../store/userService';
import { reverseFormatDate } from '../../../../utils/dateFunctions';

import classes from '../../../../commonStyles/tables.module.css';

const ClaimsForecastingTableRow = ({ row }) => {
    // HOOKS
    const dispatch = useDispatch();

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { claimEventStatuses, claimEventTypes } = useSelector(
        (state) => state.entities.directusService
    );

    // USE EFFECTS
    useEffect(() => {
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser)?.length < 1) return;
        loggedInUser.serviceIds.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{row.claimTypeName}</TableCell>
                <TableCell>{row.claimQueueStatusName}</TableCell>
                <TableCell>{row.dueDate ? reverseFormatDate(row.dueDate) : '-'}</TableCell>
                <TableCell>{row.eventDate ? reverseFormatDate(row.eventDate) : '-'}</TableCell>
            </TableRow>
        </>
    );
};

export default ClaimsForecastingTableRow;

ClaimsForecastingTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    onEdit: PropTypes.func
};
