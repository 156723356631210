import { getSelectValueMessage } from '../../utils/formValidation/errorMessageFunctions';
import { HTML_REGEX } from '../../validation/validation';

const MAX_SUBJECT_LENGTH = 100;
const MAX_NAME_LENGTH = 255;
const MAX_DETAIL = 1048576;
const MIN_LENGTH = 5;
const ERROR_NO_SELECTIONS = 'Please select at least one value';
const ERROR_NO_TEXT = 'Please enter details';
const ERROR_NO_NAME = 'Please enter a template name';
const ERROR_NO_SUBJECT = 'Please enter an email subject';
const ERROR_MIN_LENGTH = `Please enter at least ${MIN_LENGTH} characters`;
const ERROR_MAX_NAME_SIZE = `Please enter less than ${MAX_NAME_LENGTH} characters`;
const ERROR_MAX_CONTENT_SIZE = 'Maximum size has been exceeded';
const ERROR_MAX_SUBJECT_SIZE = `Subject length must be less than ${MAX_SUBJECT_LENGTH} characters`;
export const ERROR_EXISTS = 'Name already exists';

export const initialErrorState = {
    typeId: { error: false, message: '' },
    name: { error: false, message: '' },
    subject: { error: false, message: '' },
    details: { error: false, message: '' },
    contractIds: { error: false, message: '' },
    serviceIds: { error: false, message: '' }
};

export const validate = (newEntry, errors, type, existingTemplates) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.typeId) {
        newErrors = {
            ...newErrors,
            typeId: { error: true, message: getSelectValueMessage() }
        };
        isValid = false;
    }

    if (!newEntry.name) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_NO_NAME }
        };
    } else if (newEntry.name.length < MIN_LENGTH) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_MIN_LENGTH }
        };
        isValid = false;
    } else if (newEntry.name.length > MAX_NAME_LENGTH) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_MAX_NAME_SIZE }
        };
        isValid = false;
    } else if (
        existingTemplates &&
        existingTemplates.length > 0 &&
        existingTemplates.find((el) => el.name === newEntry.name)
    ) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_EXISTS }
        };
        isValid = false;
    }

    if (type === 'Email') {
        if (!newEntry.subject) {
            newErrors = {
                ...newErrors,
                subject: { error: true, message: ERROR_NO_SUBJECT }
            };
            isValid = false;
        } else if (newEntry.subject.length < MIN_LENGTH) {
            newErrors = {
                ...newErrors,
                subject: { error: true, message: ERROR_MIN_LENGTH }
            };
            isValid = false;
        } else if (newEntry.subject.length > MAX_SUBJECT_LENGTH) {
            newErrors = {
                ...newErrors,
                subject: { error: true, message: ERROR_MAX_SUBJECT_SIZE }
            };
            isValid = false;
        }
    }

    if (newEntry.typeId) {
        if (!newEntry.details) {
            newErrors = {
                ...newErrors,
                details: { error: true, message: ERROR_NO_TEXT }
            };
            isValid = false;
        } else if (newEntry.details.replace(HTML_REGEX, '').length < MIN_LENGTH) {
            newErrors = {
                ...newErrors,
                details: { error: true, message: ERROR_MIN_LENGTH }
            };
            isValid = false;
        }

        if (
            (type === 'Letter' || type === 'Email') &&
            new Blob([newEntry.details]).size > MAX_DETAIL
        ) {
            newErrors = {
                ...newErrors,
                details: { error: true, message: ERROR_MAX_CONTENT_SIZE }
            };
            isValid = false;
        }
    }

    if (newEntry.contractIds.length < 1) {
        newErrors = {
            ...newErrors,
            contractIds: { error: true, message: ERROR_NO_SELECTIONS }
        };
        isValid = false;
    }

    if (newEntry.serviceIds.length < 1) {
        newErrors = {
            ...newErrors,
            serviceIds: { error: true, message: ERROR_NO_SELECTIONS }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};
