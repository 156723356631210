import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-pad-wrapper';

import { Button } from '@mui/material';

const ESignature = ({
    onSkip,
    onCancel,
    onConfirm,
    confirmationText,
    onESigSaveAdviserSignature,
    onESigSaveParticipantSignature
}) => {
    const signaturePadRef = useRef(null);
    const [hasFirstConfirmation, setHasFirstConfirmation] = useState(false);
    const [hasSecondConfirmation, setHasSecondConfirmation] = useState(false);
    const [hasThirdConfirmation, setHasThirdConfirmation] = useState(false);

    const clearPad = () => signaturePadRef.current?.clear();

    const saveSignature = () => signaturePadRef.current?.toDataURL();

    const handleReset = () => clearPad();

    const handleSkip = () => {
        setHasFirstConfirmation(true);
        setHasSecondConfirmation(true);
        setHasThirdConfirmation(true);
        onSkip();
    };

    const handleConfirmation = () => {
        if (!hasFirstConfirmation && signaturePadRef.current.isEmpty()) {
            return;
        }
        if (!hasFirstConfirmation) {
            setHasFirstConfirmation(true);
            onESigSaveAdviserSignature(saveSignature());
        } else if (hasFirstConfirmation && !hasSecondConfirmation) {
            setHasSecondConfirmation(true);
            clearPad();
        } else if (hasFirstConfirmation && hasSecondConfirmation) {
            setHasThirdConfirmation(true);
            onESigSaveParticipantSignature(saveSignature());
            onConfirm();
        }
    };

    return (
        <div>
            <div className="eSigHeaderRow">
                <h3>
                    {hasSecondConfirmation
                        ? hasThirdConfirmation
                            ? ''
                            : "Participant's Signature"
                        : hasFirstConfirmation
                          ? 'Statement'
                          : "Adviser's Signature"}
                </h3>
                {!hasFirstConfirmation && (
                    <Button type="text" color="primary" variant="contained" onClick={handleSkip}>
                        Skip
                    </Button>
                )}
            </div>
            <div>
                {(!hasFirstConfirmation || (hasFirstConfirmation && hasSecondConfirmation)) &&
                    !hasThirdConfirmation && (
                        <>
                            <div className="eSigPad">
                                <SignaturePad
                                    width={200}
                                    height={150}
                                    ref={signaturePadRef}
                                    options={{ minWidth: 1, maxWidth: 2, penColor: 'rgb(0, 0, 0)' }}
                                />
                            </div>
                            <div className="eSigPadPrompt">Sign your name</div>
                        </>
                    )}
                {hasFirstConfirmation && !hasSecondConfirmation && <div>{confirmationText}</div>}
            </div>
            {!hasThirdConfirmation && (
                <div className="eSigModalButtons">
                    {!(hasFirstConfirmation && !hasSecondConfirmation) && (
                        <Button
                            type="text"
                            color="primary"
                            variant="contained"
                            onClick={handleReset}>
                            Reset
                        </Button>
                    )}
                    <Button
                        type="text"
                        color="primary"
                        variant="contained"
                        onClick={handleConfirmation}>
                        Confirm
                    </Button>
                    <Button type="text" color="primary" variant="contained" onClick={onCancel}>
                        Return
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ESignature;

ESignature.propTypes = {
    onSkip: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onESigSaveParticipantSignature: PropTypes.func,
    onESigSaveAdviserSignature: PropTypes.func,
    confirmationText: PropTypes.string
};
