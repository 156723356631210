import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import {
    createEmailCommunicationTemplate,
    createLetterCommunicationTemplate,
    createSMSCommunicationTemplate
} from '../../store/communicationService';
import { selectContracts, selectServices } from '../../store/dataSelectors';
import { clearServiceDetails, loadServiceDetailsByContract } from '../../store/directusService';
import { POLARIS_DARKER_GREY, POLARIS_ORANGE } from '../../themes/theme';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import IconError from '../IconError';
import LabelledTextField from '../ui/editors/LabelledTextField';
import FormActions from '../ui/formActions/FormActions';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';
import MultiSelect from '../ui/pickers/MultiSelect';
import PolarisSwitchSet from '../ui/pickers/PolarisSwitchSet';

import { initialErrorState, validate } from './validateCommunicationTemplates';

import 'react-quill/dist/quill.snow.css';
import '../../commonStyles/reactQuillRTE.css';
import form from '../../commonStyles/formStyles.module.css';

const CreateTemplate = ({ onClose, roles }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const SMS_CHARACTERS_PER_SMS = 140;
    const SMS_CHARACTER_LIMIT = 560;
    const initialState = {
        id: null,
        typeId: '',
        inactive: false,
        name: '',
        subject: '',
        details: '',
        serviceIds: [],
        contractIds: []
    };
    const acceptedRoles = [SUPERUSER, LOCAL_ADMIN];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [type, setType] = useState('');
    const [smsColor, setSmsColor] = useState(POLARIS_DARKER_GREY);
    const [selectedServices, setSelectedServices] = useState([]);

    // STORE STATE
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );
    const communicationTemplates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const contracts = useSelector(selectContracts);
    const services = useSelector(selectServices);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        dispatch(clearServiceDetails());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (newEntry.details.length > 140) setSmsColor(POLARIS_ORANGE);
        else {
            if (smsColor === POLARIS_ORANGE) setSmsColor(POLARIS_DARKER_GREY);
        }
    }, [newEntry.details]);

    useEffect(() => {
        const serviceIds = selectedServices
            .filter((el) => newEntry.contractIds.includes(el.contract.id))
            .map((el) => el.id);
        setNewEntry((prev) => ({ ...prev, serviceIds }));
    }, [newEntry.contractIds]);

    // EVENT HANDLERS

    const onDetailsChange = (details) => setNewEntry((prev) => ({ ...prev, details }));

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, type, communicationTemplates);
        setErrors(newErrors);
        if (!isValid) return;
        const { subject, contractIds, ...rest } = newEntry;
        const updatedContractIds = contractIds.filter((el) =>
            selectedServices.find((entry) => entry.contract?.id === el)
        );
        const payload = { ...rest, contractIds: updatedContractIds };
        if (type === 'SMS') dispatch(createSMSCommunicationTemplate(payload));
        if (type === 'Letter') dispatch(createLetterCommunicationTemplate(payload));
        if (type === 'Email') dispatch(createEmailCommunicationTemplate({ ...payload, subject }));
        onFormExit();
    };

    // RENDERING

    return (
        <div className={form.formWrapper}>
            <h3>Create Communications Template</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div>
                            <DDLOptionPicker
                                label={'Form of communication'}
                                id={'communicationType'}
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={communicationTypes || []}
                                chosenName={newEntry.type}
                                chosenId={newEntry.typeId}
                                onChange={(chosenId) => {
                                    clearError('typeId');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        typeId: chosenId
                                    }));
                                    setType(
                                        communicationTypes.find((el) => el.id === chosenId)?.name ||
                                            ''
                                    );
                                }}></DDLOptionPicker>
                            {errors.typeId.error && (
                                <div className={form.multiOptionPickerErrorWrapper}>
                                    <IconError text={errors.typeId} />
                                </div>
                            )}

                            <div className={form.alignDownMed}>
                                <LabelledTextField
                                    label={'Template Name'}
                                    id={'name'}
                                    mandatory={true}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    value={newEntry.name}
                                    placeholder={'Enter template name'}
                                    onChange={(e) => {
                                        clearError('name');
                                        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
                                    }}
                                />
                                {errors.name.error && (
                                    <div className={form.alignUpLg}>
                                        <IconError text={errors.name} />
                                    </div>
                                )}
                            </div>

                            {type === 'Email' && (
                                <div className={form.alignDownMed}>
                                    <LabelledTextField
                                        label={'Email Subject'}
                                        id={'subject'}
                                        mandatory={true}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        value={newEntry.subject}
                                        placeholder={'Enter email subject'}
                                        onChange={(e) => {
                                            clearError('subject');
                                            setNewEntry((prev) => ({
                                                ...prev,
                                                subject: e.target.value
                                            }));
                                        }}
                                    />
                                    {errors.subject.error && (
                                        <div className={form.alignUpLg}>
                                            <IconError text={errors.subject} />
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={form.switchWrapper}>
                                <PolarisSwitchSet
                                    id="inactive"
                                    disabled={false}
                                    label=""
                                    leftLabel="Active"
                                    rightLabel="Inactive"
                                    checked={newEntry.inactive}
                                    onSwitch={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            inactive: e.target.checked
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.formErrorSet}>
                            <MultiSelect
                                id="contractIds"
                                key={0}
                                label="Contracts *"
                                placeholder="Contract"
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={contracts || []}
                                preSelectedIds={newEntry.contractIds}
                                preSelects={contracts.filter((el) =>
                                    newEntry.contractIds.includes(el.id)
                                )}
                                onChange={(ids) => {
                                    clearError('contractIds');
                                    if (
                                        (newEntry.contractIds.length === ids.length &&
                                            ids.length !== 0) ||
                                        ids.length === 0
                                    )
                                        return;
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        contractIds: ids,
                                        serviceIds: ids?.length === 0 ? [] : newEntry.serviceIds
                                    }));
                                    ids?.length > 0 && dispatch(loadServiceDetailsByContract(ids));
                                }}
                            />

                            {errors.contractIds.error && (
                                <div className={`${form.alignUpLg}`}>
                                    <IconError text={errors.contractIds} />
                                </div>
                            )}
                        </div>

                        <div className={form.alignUpMed}>
                            <MultiSelect
                                id="serviceIds"
                                key={1}
                                label="Services *"
                                disabled={
                                    !hasRole(acceptedRoles, roles) ||
                                    newEntry.contractIds.length < 1
                                }
                                placeholder="Service"
                                menuItems={services || []}
                                preSelectedIds={newEntry.serviceIds}
                                preSelects={services.filter((el) =>
                                    newEntry.serviceIds.includes(el.id)
                                )}
                                onChange={(ids) => {
                                    clearError('serviceIds');
                                    if (ids.length < 1 && newEntry.serviceIds?.length < 1) return;
                                    setSelectedServices(
                                        services.filter((el) => ids.includes(el.id))
                                    );
                                    setNewEntry((prev) => ({ ...prev, serviceIds: ids }));
                                }}
                            />
                        </div>

                        {errors.serviceIds.error && (
                            <div className={form.alignUpLg}>
                                <IconError text={errors.serviceIds} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            {type === 'SMS' && (
                                <>
                                    <label htmlFor="sms">Message *</label>
                                    <textarea
                                        className={form.textAreaText}
                                        id="details"
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        maxLength={SMS_CHARACTER_LIMIT}
                                        rows={10}
                                        placeholder={'Enter template details'}
                                        value={newEntry.details || ''}
                                        onChange={(e) =>
                                            setNewEntry((prev) => ({
                                                ...prev,
                                                details: e.target.value
                                            }))
                                        }
                                    />
                                    <div>
                                        <div>
                                            <span>Characters: </span>
                                            <span style={{ color: smsColor }}>
                                                {newEntry.details?.length}
                                            </span>
                                        </div>
                                        <div>
                                            <span>SMS Count: </span>
                                            <span>
                                                {Math.ceil(
                                                    newEntry.details?.length /
                                                        SMS_CHARACTERS_PER_SMS
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            {(type === 'Letter' || type === 'Email') && (
                                <ReactQuill
                                    value={newEntry.details}
                                    modules={CreateTemplate.modules}
                                    placeholder="Enter template details"
                                    onChange={onDetailsChange}
                                />
                            )}
                            {errors.details.error && (
                                <div className={`${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText="Create"
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateTemplate;

CreateTemplate.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};

CreateTemplate.modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['clean']
    ]
};
