import React from 'react';
import PropTypes from 'prop-types';

const TimeSelect = ({
    startTime,
    endTime,
    onTimeChange,
    disabled = false,
    hasDefaultStart = true,
    hasDefaultEnd = true
}) => {
    return (
        <div className="time-inputs">
            <div className="input-set start-input">
                <label htmlFor="startTime"> Start Time</label>
                <input
                    type="time"
                    id="startTime"
                    data-testid="startTime"
                    disabled={disabled}
                    value={startTime || (hasDefaultStart ? '08:00' : '')}
                    min={'08:00'}
                    max={'17:55'}
                    step={'00:05'}
                    onChange={(e) => onTimeChange({ startTime: e.target.value })}
                />
            </div>
            <div className="input-set end-input">
                <label htmlFor="endTime"> End Time</label>
                <input
                    type="time"
                    id="endTime"
                    data-testid="endTime"
                    disabled={disabled}
                    value={endTime || (hasDefaultEnd ? '09:00' : '')}
                    min={'08:05'}
                    max={'18:00'}
                    step={'00:05'}
                    onChange={(e) => onTimeChange({ endTime: e.target.value })}
                />
            </div>
        </div>
    );
};

export default TimeSelect;

TimeSelect.propTypes = {
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    onTimeChange: PropTypes.func,
    disabled: PropTypes.bool,
    hasDefaultStart: PropTypes.bool,
    hasDefaultEnd: PropTypes.bool
};
