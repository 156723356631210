import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Card, CardContent } from '@mui/material';

import {
    loadAppointmentTypeDetails,
    loadAttendanceDetails,
    loadLocationsByServices,
    loadParticipantStatusDetails,
    loadParticipantStatusReasonDetails,
    loadTimelineStatusDetails,
    loadTimelineTypeDetails,
    loadVacancyStageDetails
} from '../../store/directusService';
import { hideStickyMenus, showParticipantStickyMenu } from '../../store/formsState';
import {
    deleteParticipantTimelineEvent,
    loadParticipantTimelineEvent
} from '../../store/participantService';
import {
    clearVacancySubmissions,
    loadBusinessRecords,
    loadRecruitmentParticipantTimelineEvent,
    loadVacanciesByOwners,
    loadVacancySubmissionsByParticipantId
} from '../../store/recruitmentService';
import { loadUser, loadUsersByServiceIds } from '../../store/userService';
import { SCREEN_SETTINGS } from '../../themes/theme';
import { getNameFromId } from '../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import ParticipantStickyMenu from '../navigation/participant/ParticipantStickyMenu';
import LoadingSpinner from '../ui/LoadingSpinner';
import DateRangePicker from '../ui/pickers/DateRangePicker';

import TimeLineEditor from './TimeLineEditor';
import TimeLineView from './TimeLineView';

import classes from '../../app.module.css';
import timelineClasses from './timeLineStyles/timeLine.module.css';

const BUS_LOAD_SIZE = 50;
const TimeLine = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [timeLineEditor, setTimeLineEditor] = useState(false);
    const [rows, setRows] = useState([]);
    const [savedRows, setSavedRows] = useState([]);
    const [currentRowId, setCurrentRowId] = useState('');
    const [savedHistory, setSavedHistory] = useState(false);
    const [participantEventsLoaded, setParticipantEventsLoaded] = useState(false);
    const [recruitmentParticipantEventsProcessed, setRecruitmentParticipantEventsProcessed] =
        useState(false);
    const [vacancySubmissionsHaveBeenLoaded, setVacancySubmissionsHaveBeenLoaded] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [businessesForTimeLine, setBusinessesForTimeLine] = useState([]);

    const setTimeLine = () => {
        onTimeLineEditor(true);
    };

    const onTimeLineEditor = (setOpen) => {
        setTimeLineEditor(setOpen);
    };

    const onSearch = (searchRes) => {
        if (searchRes) {
            const inDate = parse(searchRes.substring(0, 10), 'dd/MM/yyyy', new Date());
            const startDate = format(inDate, 'yyyy-MM-dd');
            const outDate = parse(searchRes.substring(13), 'dd/MM/yyyy', new Date());
            const endDate = format(outDate, 'yyyy-MM-dd');
            setSearchTerm(startDate + endDate);
        } else {
            setSearchTerm('');
        }
    };

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const users = useSelector((state) => state.entities.userService.users);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { currentParticipant, participantTimelineEvent, participantTimelineEvents } = useSelector(
        (state) => state.entities.participantService
    );
    const {
        businesses,
        businessesMetaData,
        recruitmentParticipantTimelineEvents,
        vacanciesForOwners,
        vacancySubmissions
    } = useSelector((state) => state.entities.recruitmentService);
    const {
        appointmentTypeDetails,
        attendanceDetails,
        participantStatusDetails,
        participantStatusReasonDetails,
        timelineStatusDetails,
        timelineTypeDetails,
        vacancyStageDetails
    } = useSelector((state) => state.entities.directusService);

    //  USE EFFECTS
    useEffect(() => {
        appointmentTypeDetails?.length < 1 && dispatch(loadAppointmentTypeDetails());
        attendanceDetails?.length < 1 && dispatch(loadAttendanceDetails());
        businesses?.length < 1
            ? dispatch(loadBusinessRecords(0, BUS_LOAD_SIZE))
            : setBusinessesForTimeLine(businesses);
        participantStatusDetails?.length < 1 && dispatch(loadParticipantStatusDetails());
        participantStatusReasonDetails?.length < 1 &&
            dispatch(loadParticipantStatusReasonDetails());
        timelineStatusDetails?.length < 1 && dispatch(loadTimelineStatusDetails());
        timelineTypeDetails?.length < 1 && dispatch(loadTimelineTypeDetails());
        vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        dispatch(showParticipantStickyMenu());

        return () => {
            dispatch(hideStickyMenus());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(currentParticipant).length < 1) return;

        dispatch(clearVacancySubmissions());
        dispatch(loadVacancySubmissionsByParticipantId(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (!('serviceIds' in loggedInUser) || usersLoading) return;
        loggedInUser.serviceIds.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
        loggedInUser.serviceIds.length > 0 &&
            dispatch(loadLocationsByServices(loggedInUser.serviceIds));
        setUsersLoading(true);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (businessesForTimeLine?.length < 1) return;
        else if (!businessesMetaData.last) {
            dispatch(loadBusinessRecords(businessesMetaData.number + 1, BUS_LOAD_SIZE));
        } else setBusinessesForTimeLine(businesses);
    }, [businesses, businessesMetaData]);

    useEffect(() => {
        if (
            businesses?.length < 1 ||
            // vacanciesForOwners?.length < 1 ||
            !vacancySubmissionsHaveBeenLoaded
        )
            return;

        if (vacancySubmissions?.length > 0 && participantEventsLoaded) {
            dispatch(loadRecruitmentParticipantTimelineEvent(currentParticipant.id));
        } else if (vacancySubmissions?.length === 0 && participantEventsLoaded) {
            setRecruitmentParticipantEventsProcessed(true);
        }
    }, [businesses, vacanciesForOwners, vacancySubmissionsHaveBeenLoaded, participantEventsLoaded]);

    useEffect(() => {
        if (successMessage === `Users loaded` && usersLoading) {
            dispatch(loadParticipantTimelineEvent({ participantIds: [currentParticipant.id] }));
        } else if (successMessage.startsWith('Vacancy submissions have been loaded')) {
            setVacancySubmissionsHaveBeenLoaded(true);
        } else if (successMessage === 'TimelineEvents have been loaded') {
            setRows(
                participantTimelineEvents.map((el) => {
                    let emailAddress = '';
                    if (users && users.length) {
                        emailAddress =
                            users.find((entry) => entry.id === el.userId)?.emailAddress || '';
                        if (!emailAddress) dispatch(loadUser(el.userId));
                    } else dispatch(loadUser(el.userId));
                    if (el.eventType === 'Outbound Communication') {
                        return {
                            ...el,
                            emailAddress: emailAddress ? emailAddress : '',
                            details: el.communicationEvent?.details || '',
                            subject: el.communicationEvent?.subject || '',
                            recipient: el.communicationEvent?.recipient || ''
                        };
                    } else if (el?.history) {
                        const temp = el?.history.map((entry) => {
                            if (entry.field.endsWith(' Id') || entry.field === 'Attendance') {
                                let arr = [];
                                if (entry.field === 'Status Id') {
                                    arr = participantStatusDetails;
                                } else if (entry.field === 'Status Reason Id') {
                                    arr = participantStatusReasonDetails;
                                } else if (entry.field === 'Attendance') {
                                    arr = attendanceDetails;
                                }
                                return {
                                    ...entry,
                                    oldValueName: getNameFromId(arr, entry.oldValue),
                                    newValueName: getNameFromId(arr, entry.newValue),
                                    loggedDate: el.loggedDate,
                                    loggedTime: el.loggedTime
                                };
                            } else return entry;
                        });
                        if (temp?.length > 0) {
                            return {
                                ...el,
                                emailAddress: emailAddress ? emailAddress : '',
                                eventHistory: temp
                            };
                        } else {
                            return {
                                ...el,
                                emailAddress: emailAddress ? emailAddress : '',
                                eventHistory: []
                            };
                        }
                    } else {
                        return {
                            ...el,
                            emailAddress: emailAddress ? emailAddress : ''
                        };
                    }
                })
            );
            setParticipantEventsLoaded(true);
        } else if (successMessage === 'Recruitment Participant TimelineEvents have been loaded') {
            setRecruitmentParticipantEventsProcessed(true);
            const temp = recruitmentParticipantTimelineEvents.map((el) => {
                let emailAddress = '';
                if (users && users.length) {
                    emailAddress =
                        users?.find((entry) => entry.id === el.history[0].userId)?.emailAddress ||
                        '';
                    if (!emailAddress) dispatch(loadUser(el.history[0].userId));
                } else dispatch(loadUser(el.history[0].userId));
                return {
                    ...el,
                    id: uuid(),
                    eventDate: el.history[0].eventDate.substring(0, 10),
                    eventTime: el.history[0].eventDate,
                    eventType: el.history[0].eventType,
                    vacancyStage: getNameFromId(vacancyStageDetails, el.history[0].newValue),
                    vacancyName: vacanciesForOwners?.find((entry) => entry.id === el.vacancyId)
                        ?.title,
                    businessName: businesses.find(
                        (entry) =>
                            entry.id ===
                            vacanciesForOwners?.find((entry) => entry.id === el.vacancyId)
                                ?.businessRecordId
                    )?.name,
                    notes: vacancySubmissions.find((entry) => entry.vacancyId === el.vacancyId)
                        ?.rejection?.feedback,
                    emailAddress: emailAddress ? emailAddress : ''
                };
            });
            if (temp?.length > 0) {
                setRows((prev) => [...prev, ...temp]);
            }
        } else if (successMessage === 'Timeline entry has been added') {
            setRows((prev) => [participantTimelineEvent, ...prev]);
            setSavedRows((prev) => [participantTimelineEvent, ...prev]);
        } else if (successMessage === 'Timeline entry has been deleted') {
            const events = rows.filter((el) => el.id !== currentRowId);
            setRows(events);
            setSavedRows(events);
            setCurrentRowId('');
        }
    }, [successMessage]);

    useEffect(() => {
        const sortedRows = !searchTerm
            ? rows
            : rows.filter(
                  (el) =>
                      el.eventDate >= searchTerm.substring(0, 10) &&
                      el.eventDate <= searchTerm.substring(10)
              );
        const sortedFilterRows = sortedRows
            .map((el) => {
                const dateForSort = el.eventDate;
                const timeForSort =
                    el.eventTime.length === 3
                        ? `0${el.eventTime.substring(0, 1)}:${el.eventTime.substring(1)}`
                        : `${el.eventTime.substring(0, 2)}:${el.eventTime.substring(2)}`;
                const dateTime = `${dateForSort}${timeForSort}`;
                return { ...el, dateTime: dateTime };
            })
            .sort((a, b) => b.dateTime.localeCompare(a.dateTime));
        setSavedRows(sortedFilterRows);
    }, [searchTerm]);

    // EVENTHANDLERS

    const onDelete = (id) => {
        setCurrentRowId(id);
        dispatch(deleteParticipantTimelineEvent(id));
    };

    if (rows?.length < 1 || !participantEventsLoaded || !recruitmentParticipantEventsProcessed) {
        return <LoadingSpinner content="No timeline found" time={3} />;
    } else {
        // We need to sort & save rows for searches first time through
        // and after a manual event has been added
        if (!savedHistory) {
            const sortedFilterRows = rows
                .map((el) => {
                    const dateForSort = el.eventDate;
                    const timeForSort =
                        el.eventTime.length === 3
                            ? `0${el.eventTime.substring(0, 1)}:${el.eventTime.substring(1)}`
                            : `${el.eventTime.substring(0, 2)}:${el.eventTime.substring(2)}`;
                    const dateTime = `${dateForSort}${timeForSort}`;
                    return { ...el, dateTime: dateTime };
                })
                .sort((a, b) => b.dateTime.localeCompare(a.dateTime));
            setRows(sortedFilterRows);
            setSavedRows(sortedFilterRows);
            setSavedHistory(true);
        }
    }

    // RENDER
    return (
        <Card data-testid="form_start">
            <CardContent>
                <div className={classes.withSideBar}>
                    <div style={{ flex: '1 1 auto' }}>
                        <div className={timelineClasses.timeLineToolBar}>
                            <DateRangePicker onSearch={onSearch} />
                            <div>
                                <Button
                                    className={timelineClasses.timeLineToolBarButton}
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    color="primary"
                                    variant="contained"
                                    size={'small'}
                                    onClick={setTimeLine}>
                                    Create Timeline Entry
                                    <Box width={SCREEN_SETTINGS.gap.medium} />
                                    <AddCircleOutlineIcon />
                                </Button>
                                <TimeLineEditor
                                    open={timeLineEditor}
                                    onTimeLineEditor={onTimeLineEditor}
                                    timelineStatusDetails={timelineStatusDetails}
                                />
                            </div>
                        </div>
                        <div className={timelineClasses.timeLineBody}>
                            {savedRows?.length < 1 ? (
                                <div className={timelineClasses.noTimeLines}>
                                    <LoadingSpinner content="No timeline found" />
                                </div>
                            ) : (
                                savedRows.map((el) => (
                                    <div key={el.id}>
                                        <TimeLineView
                                            row={el}
                                            timelineTypeDetails={timelineTypeDetails}
                                            onDelete={onDelete}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className={classes.sideBar}>
                        <ParticipantStickyMenu />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default TimeLine;
