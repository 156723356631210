import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';

import PolarisLink from '../../ui/PolarisLink';

const ParticipantMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const navigate = useNavigate();

    const handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                navigate('/create_participant');
                break;
            case 1:
                navigate('/search_participant');
                break;
            case 2:
                navigate('/manage_groups');
                break;
            default:
                break;
        }
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <PolarisLink label={'Participant'} onClick={handleClickListItem} />

            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}>
                <MenuItem key={0} selected={false} onClick={() => handleMenuItemClick(0)}>
                    Create New Participant
                </MenuItem>
                <MenuItem key={1} onClick={() => handleMenuItemClick(1)}>
                    Search Participant
                </MenuItem>
                <MenuItem key={2} onClick={() => handleMenuItemClick(2)}>
                    Manage Groups
                </MenuItem>
            </Menu>
        </div>
    );
};
export default ParticipantMenu;
