import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    TableCell,
    TableRow,
    TextField
} from '@mui/material';

import { setErrorMessage } from '../../../../store/formsState';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';
import barriersClasses from './barriers.module.css';

const DATE_RANGE_INVALID = 'Due date must be same as or after start date';

const SelectBarriersTableRow = ({ row, onRowChange }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    function priorityChange(selection) {
        const updatedRow = {
            ...row,
            priority: selection
        };
        onRowChange(updatedRow);
    }

    const handleStartDateChange = (e) => {
        const updatedRow = {
            ...row,
            startDate: e.target.value
        };

        if (e.target.value > row.dueDate) {
            dispatch(setErrorMessage(DATE_RANGE_INVALID));
            onRowChange(updatedRow, true);
        } else {
            onRowChange(updatedRow);
        }
    };

    const handleDueDateChange = (e) => {
        const updatedRow = {
            ...row,
            dueDate: e.target.value
        };

        if (row.startDate > e.target.value) {
            dispatch(setErrorMessage(DATE_RANGE_INVALID));
            onRowChange(updatedRow, true);
        } else {
            onRowChange(updatedRow);
        }
    };

    const onCheck = (e) => {
        const { checked } = e.target;
        const updatedRow = {
            ...row,
            update: checked
        };
        onRowChange(updatedRow);
    };

    // RENDER
    return (
        <>
            <TableRow key={row.id} hover className={classes.basicRowStripes}>
                <TableCell disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                    <div className={barriersClasses.check}>
                        <Checkbox
                            key={row.id}
                            type="checkbox"
                            sx={{ color: POLARIS_ROYAL_BLUE }}
                            checked={row.update}
                            onClick={onCheck}
                        />
                    </div>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                    {' '}
                    <RadioGroup
                        row
                        aria-labelledby="priorityUpdate"
                        value={row.priority}
                        id="priorityUpdate"
                        onChange={(e) => priorityChange(e.target.value)}>
                        <FormControlLabel
                            disabled={row.update !== true}
                            required={false}
                            value="Low"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            disabled={row.update !== true}
                            required={false}
                            value="Medium"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            disabled={row.update !== true}
                            required={false}
                            value="High"
                            control={<Radio />}
                        />
                    </RadioGroup>
                </TableCell>
                <TableCell>
                    <TextField
                        sx={{
                            width: '140px',
                            borderBottom: `${row.dueDate < row.startDate ? '1px solid red' : ''}`,
                            borderRadius: '5px'
                        }}
                        id="startDate"
                        disabled={row.update !== true}
                        required={row.update === true}
                        type="date"
                        value={row.startDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={handleStartDateChange}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        sx={{ width: '140px' }}
                        id="dueDate"
                        disabled={row.update !== true}
                        required={row.update === true}
                        type="date"
                        value={row.dueDate || ''}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={handleDueDateChange}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default SelectBarriersTableRow;

SelectBarriersTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onRowChange: PropTypes.func
};
