// This is a slice of state for the Participant Service.
import { createSlice } from '@reduxjs/toolkit';

import { applyPagination, getParticipantURL } from '../api/commonHTTP';
import { RADIO_BUTTON_NO } from '../components/ui/pickers/SelectorOptions';
import { getEmptyPaginationState } from '../utils/objectUtils';

import { apiCallBegan, apiCallDirectusBegan } from './api';

const currentParticipantId = localStorage.getItem('currentParticipantId');

const slice = createSlice({
    name: 'participantService',
    initialState: {
        participantActionPlans: [],
        alcoholConsumedEntries: [],
        appointmentAttendanceDetails: [],
        mostRecentAppointment: {},
        bloodGlucose: [],
        bloodPressure: [],
        bloodTests: [],
        bmiEntries: [],
        contractConstants: {},
        claimEvent: {},
        claimEvents: [],
        claimQueue: {},
        claimQueueForecast: [],
        claimQueues: [],
        claimQueuesMetaData: getEmptyPaginationState(),
        currentForm: {},
        currentFormParticipant: {},
        currentParticipant: {},
        currentParticipantId: currentParticipantId ? currentParticipantId : '',
        currentQuestionnaire: {},
        darEntry: {},
        darEntries: [],
        darQueuesSearch: [],
        eligibilityGroups: [],
        eligibilityGroupSearch: [],
        participantFinancialRequests: [],
        formContracts: {},
        forms: [],
        formsByContract: [],
        formsParticipant: [],
        healthIncidents: [],
        iwsRecordsByPlacementId: {},
        iwsRecordSearch: [],
        iwsRecordSearchMetaData: getEmptyPaginationState(),
        occActivity: [],
        occProfiles: [],
        participants: [],
        participantsByLoggedInUserServiceIds: [],
        participantsByLoggedInUserServiceIdsMetaData: getEmptyPaginationState(),
        recentParticipants: [],
        participantsSearch: [],
        participantsSearchMetaData: getEmptyPaginationState(),
        currentParticipantCircumstances: {},
        currentParticipantGeneralInformation: {},
        currentParticipantHealthInformation: {},
        currentParticipantInformation: {},
        currentParticipantBarriers: [],
        currentParticipantJobGoals: [],
        currentParticipantEmployability: {},
        currentParticipantPastEducation: {},
        currentParticipantWorkConditionality: {},
        currentParticipantWorkHistory: {},
        participantSectionHeadings: {},
        participantTimelineEvent: {},
        participantTimelineEvents: [],
        participantTimelineSearchData: [],
        participantTimelineSearchDataLoading: false,
        participantTimelineSearchDataLoaded: false,
        physicalActivityEntries: [],
        questionnaires: [],
        questionnaireAnswers: [],
        questionnaireContracts: {},
        referralHistory: [],
        referralSummary: [],
        serviceConstants: {},
        signPostings: [],
        smokingQuitAttempts: [],
        smokingQuitAttemptReviews: {},
        steps: [],
        waistMeasurements: [],
        loadedAppointmentAttendanceDetails: false,
        loadingClaimQueuesForecast: true,
        loadingClaimQueues: false,
        loadingClaimQueuesSearch: false,
        loadingDarQueuesSearch: false,
        loadingIwsRecords: false,
        loadingIwsRecordsByPlacementId: false,
        participantSearchData: {
            type: null,
            data: null
        },
        participantsByLoggedInUserServiceIdsLoading: false,
        errorMessage: '',
        successMessage: ''
    },
    reducers: {
        // These update the state. They can use mutable code and Immer is used
        // to convert this to  immutable code
        errorMessageSet: (state, action) => {
            state.errorMessage = action.payload;
            state.successMessage = '';
        },

        actionPlansLoading: (state) => {
            state.participantActionPlans = [];
        },

        actionPlansLoaded: (state, action) => {
            state.participantActionPlans = action.payload;
        },

        actionPlanCreated: (state, action) => {
            state.participantActionPlans = [...state.participantActionPlans, action.payload];
        },

        actionPlanUpdated: (state, action) => {
            state.participantActionPlans = state.participantActionPlans.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        alcoholConsumedLoading: (state) => {
            state.alcoholConsumedEntries = [];
        },
        alcoholConsumedLoaded: (state, action) => {
            state.alcoholConsumedEntries = action.payload;
        },
        alcoholConsumedCreated: (state, action) => {
            state.alcoholConsumedEntries = [...state.alcoholConsumedEntries, action.payload];
        },
        alcoholConsumedUpdated: (state, action) => {
            state.alcoholConsumedEntries = state.alcoholConsumedEntries.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },
        alcoholConsumedDeleted: (state, action) => {
            state.alcoholConsumedEntries = state.alcoholConsumedEntries.filter(
                (el) => el.id !== action.payload
            );
        },

        appointmentAttendanceDetailsLoading: (state) => {
            state.appointmentAttendanceDetails = [];
            state.loadedAppointmentAttendanceDetails = false;
        },

        appointmentAttendanceDetailsLoaded: (state, action) => {
            state.loadedAppointmentAttendanceDetails = true;
            state.appointmentAttendanceDetails = action.payload;
        },

        clearAppointmentAttendanceDetails: (state) => {
            state.loadedAppointmentAttendanceDetails = false;
            state.appointmentAttendanceDetails = [];
        },

        mostRecentAppointmentAttendanceDetailsLoaded: (state, action) => {
            state.mostRecentAppointment = action.payload;
        },

        appointmentAttendanceDetailCreated: (state, action) => {
            state.appointmentAttendanceDetails = [
                ...state.appointmentAttendanceDetails,
                action.payload
            ];
        },

        appointmentAttendanceDetailUpdated: (state, action) => {
            state.appointmentAttendanceDetails = state.appointmentAttendanceDetails.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        assetRequestCreated: (state, action) => {
            state.participantAssetRequests = [...state.participantAssetRequests, action.payload];
        },

        assetRequestsLoaded: (state, action) => {
            state.participantAssetRequests = action.payload;
        },

        assetRequestsUpdated: (state, action) => {
            state.participantAssetRequests = state.participantAssetRequests.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        bloodGlucoseLoading: (state) => {
            state.bloodGlucose = [];
        },
        bloodGlucoseLoaded: (state, action) => {
            state.bloodGlucose = action.payload;
        },
        bloodGlucoseCreated: (state, action) => {
            state.bloodGlucose = [...state.bloodGlucose, action.payload];
        },
        bloodGlucoseUpdated: (state, action) => {
            state.bloodGlucose = state.bloodGlucose.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },
        bloodGlucoseDeleted: (state, action) => {
            state.bloodGlucose = state.bloodGlucose.filter((el) => el.id !== action.payload);
        },

        bloodPressureLoading: (state) => {
            state.bloodPressure = [];
        },
        bloodPressureLoaded: (state, action) => {
            state.bloodPressure = action.payload;
        },
        bloodPressureCreated: (state, action) => {
            state.bloodPressure = [...state.bloodPressure, action.payload];
        },
        bloodPressureUpdated: (state, action) => {
            state.bloodPressure = state.bloodPressure.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },
        bloodPressureDeleted: (state, action) => {
            state.bloodPressure = state.bloodPressure.filter((el) => el.id !== action.payload);
        },

        bloodTestsLoading: (state) => {
            state.bloodTests = [];
        },
        bloodTestsLoaded: (state, action) => {
            state.bloodTests = action.payload;
        },
        bloodTestCreated: (state, action) => {
            state.bloodTests = [...state.bloodTests, action.payload];
        },
        bloodTestUpdated: (state, action) => {
            state.bloodTests = state.bloodTests.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },
        bloodTestDeleted: (state, action) => {
            state.bloodTests = state.bloodTests.filter((el) => el.id !== action.payload);
        },

        bmiLoading: (state) => {
            state.bmiEntries = [];
        },
        bmiLoaded: (state, action) => {
            state.bmiEntries = action.payload;
        },
        bmiCreated: (state, action) => {
            state.bmiEntries = [...state.bmiEntries, action.payload];
        },
        bmiUpdated: (state, action) => {
            state.bmiEntries = state.bmiEntries.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },
        bmiDeleted: (state, action) => {
            state.bmiEntries = state.bmiEntries.filter((el) => el.id !== action.payload);
        },

        claimEventCreated: (state, action) => {
            state.claimEvents = [...state.claimEvents, action.payload];
            state.claimEvent = action.payload;
        },

        claimEventLoading: (state) => {
            state.claimEvent = {};
        },

        claimEventLoaded: (state, action) => {
            state.claimEvents = [
                ...state.claimEvents.filter((el) => el.id !== action.payload.id),
                action.payload
            ];
            state.claimEvent = action.payload;
        },

        claimEventsLoaded: (state, action) => {
            state.claimEvents = action.payload.content;
        },

        claimEventSearchLoading: (state) => {
            state.claimEvent = {};
            state.claimEvents = [];
        },

        claimEventSearchLoaded: (state, action) => {
            if (action.payload.length > 1) state.claimEvents = action.payload;
            if (action.payload.length === 1) {
                state.claimEvent = action.payload[0];
                state.claimEvents = [
                    ...state.claimEvents.filter((el) => el.id !== action.payload[0].id),
                    action.payload[0]
                ];
            }
        },

        claimEventDeleted: (state, action) => {
            state.claimEvents = state.claimEvents.filter((el) => el.id !== action.payload);
        },

        claimQueueForecastLoaded: (state, action) => {
            state.loadingClaimQueueForecast = false;
            state.claimQueueForecast = action.payload;
        },

        claimQueueForecastLoading: (state) => {
            state.loadingClaimQueuesForecast = true;
            state.claimQueueForecast = [];
        },

        clearClaimQueues: (state) => {
            state.claimQueues = [];
            state.claimQueuesMetaData = getEmptyPaginationState();
        },

        claimQueuesSearchLoaded: (state, action) => {
            state.loadingClaimQueuesSearch = false;
            const { content, ...rest } = action.payload;
            state.claimQueues = rest.first ? content : [...state.claimQueues, ...content];
            state.claimQueuesMetaData = rest;
        },

        claimQueuesSearchLoading: (state) => {
            state.loadingClaimQueuesSearch = true;
        },

        claimQueueUpdated: (state, action) => {
            state.claimQueues = state.claimQueues.map((el) =>
                el.id !== action.payload.id ? el : action.payload
            );
            state.claimQueue = action.payload;
        },

        claimQueuesUpdated: (state, action) => {
            state.claimQueues = state.claimQueues.map((el) =>
                el.id !== action.payload.id ? el : action.payload
            );
        },

        contractConstantsLoaded: (state, action) => {
            state.contractConstants = action.payload === '' ? {} : action.payload;
        },

        contractConstantsLoading: (state) => {
            state.contractConstants = {};
        },

        contractConstantsUpdated: (state, action) => {
            state.contractConstants = action.payload;
        },

        darCreated: (state, action) => {
            state.darEntries = [...state.darEntries, action.payload];
            state.darEntry = action.payload;
        },

        darQueuesSearchLoaded: (state, action) => {
            state.loadingDarQueuesSearch = false;
            state.darQueuesSearch = action.payload;
        },

        darUpdated: (state, action) => {
            const darEntries = state.darEntries.filter((el) => el.id !== action.payload.id);
            state.darEntries = [...darEntries, action.payload];
            state.darEntry = action.payload;
        },

        darQueuesSearchLoading: (state) => {
            state.loadingDarQueuesSearch = true;
            state.darQueuesSearch = [];
        },

        eligibilityGroupCreated: (state, action) => {
            state.eligibilityGroups = [...state.eligibilityGroups, action.payload];
        },

        eligibilityGroupLoaded: (state, action) => {
            state.eligibilityGroups = state.eligibilityGroups.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        eligibilityGroupsLoaded: (state, action) => {
            state.eligibilityGroups = action.payload.content;
        },

        eligibilityGroupSearchLoading: (state) => {
            state.eligibilityGroupSearch = [];
        },

        eligibilityGroupSearchLoaded: (state, action) => {
            state.eligibilityGroupSearch = action.payload;
        },

        eligibilityGroupUpdated: (state, action) => {
            state.eligibilityGroups = state.eligibilityGroups.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        eligibilityGroupDeleted: (state, action) => {
            state.eligibilityGroups = state.eligibilityGroups.filter(
                (el) => el.id !== action.payload
            );
        },

        financialRequestCreated: (state, action) => {
            state.participantFinancialRequests = [
                ...state.participantFinancialRequests,
                action.payload
            ];
        },

        financialRequestsLoaded: (state, action) => {
            state.participantFinancialRequests = action.payload;
        },

        financialRequestUpdated: (state, action) => {
            state.participantFinancialRequests = state.participantFinancialRequests.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        formContractsLoaded: (state, action) => {
            state.formContracts = action.payload;
        },

        formContractUpdated: (state, action) => {
            state.formContracts = action.payload;
        },

        formCreated: (state, action) => {
            state.currentForm = action.payload;
            state.forms = [...state.forms, action.payload];
        },

        formLoading: (state) => {
            state.currentForm = {};
        },

        formLoaded: (state, action) => {
            state.currentForm = action.payload;
        },

        formUpdated: (state, action) => {
            const forms = state.forms.filter((el) => el.id !== action.payload.id);
            state.forms = [...forms, action.payload];
            state.currentForm = action.payload;
        },

        formsLoading: (state) => {
            state.forms = [];
        },

        formsLoaded: (state, action) => {
            state.forms = action.payload;
        },

        formsByContractLoaded: (state, action) => {
            state.formsByContract = action.payload;
        },

        setCurrentForm: (state, action) => {
            state.currentForm = action.payload;
        },

        formParticipantCreated: (state, action) => {
            state.currentFormParticipant = action.payload;
            state.formsParticipant = [...state.formsParticipant, action.payload];
        },

        formParticipantLoaded: (state, action) => {
            state.currentFormParticipant = action.payload;
        },

        formParticipantUpdated: (state, action) => {
            const formsParticipant = state.formsParticipant.filter(
                (el) => el.id !== action.payload.id
            );
            state.formsParticipant = [...formsParticipant, action.payload];
            state.currentFormParticipant = action.payload;
        },

        formsParticipantLoaded: (state, action) => {
            state.formsParticipant = action.payload;
        },

        healthIncidentsLoading: (state) => {
            state.healthIncidents = [];
        },
        healthIncidentsLoaded: (state, action) => {
            state.healthIncidents = action.payload;
        },
        healthIncidentCreated: (state, action) => {
            state.healthIncidents = [...state.healthIncidents, action.payload];
        },
        healthIncidentUpdated: (state, action) => {
            state.healthIncidents = state.healthIncidents.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },
        healthIncidentDeleted: (state, action) => {
            state.healthIncidents = state.healthIncidents.map((el) =>
                el.id === action.payload ? { ...el, inactive: true } : el
            );
        },

        iwsRecordsByPlacementIdLoading: (state, action) => {
            state.loadingIwsRecordsByPlacementId = true;
            const { placementIds } = action.payload;
            placementIds.forEach((placementId) => {
                if (!state.iwsRecordsByPlacementId[placementId]) {
                    state.iwsRecordsByPlacementId[placementId] = {
                        iwsRecords: [],
                        metaData: getEmptyPaginationState()
                    };
                }
            });
        },

        iwsRecordsByPlacementIdLoaded: (state, action) => {
            state.loadingIwsRecordsByPlacementId = false;
            const { content, ...rest } = action.payload;
            if (content.length) {
                const placementId = content[0].placementId;
                state.iwsRecordsByPlacementId[placementId] = {
                    iwsRecords: rest.first
                        ? content
                        : [...state.iwsRecordsByPlacementId[placementId].iwsRecords, ...content],
                    metaData: rest
                };
            }
        },

        iwsRecordsLoading: (state) => {
            state.loadingIwsRecords = true;
        },

        iwsSearchRecordsLoaded: (state, action) => {
            state.loadingIwsRecords = false;
            const { content, ...rest } = action.payload;
            state.iwsRecordSearch = rest.first ? content : [...state.iwsRecordSearch, ...content];
            state.iwsRecordSearchMetaData = rest;
        },

        occActivityLoaded: (state, action) => {
            state.occActivity = action.payload;
        },

        occProfilesLoaded: (state, action) => {
            state.occProfiles = action.payload;
        },

        occProfileCreated: (state, action) => {
            state.occProfiles = [...state.occProfiles, ...action.payload];
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload;
        },

        participantCreated: (state, action) => {
            state.currentParticipant = action.payload;
            state.participants = [...state.participants, action.payload];
        },

        participantGeneralInformationLoaded: (state, action) => {
            state.currentParticipantGeneralInformation =
                action.payload === '' ? { hasOwnTransport: RADIO_BUTTON_NO } : action.payload;
        },

        participantHealthInformationLoaded: (state, action) => {
            state.currentParticipantHealthInformation =
                action.payload === '' ? { hasComorbidities: RADIO_BUTTON_NO } : action.payload;
        },

        participantCircumstancesLoaded: (state, action) => {
            state.currentParticipantCircumstances =
                action.payload === '' ? { additionalContactCards: [] } : action.payload;
        },

        participantPastEducationLoaded: (state, action) => {
            state.currentParticipantPastEducation = action.payload === '' ? {} : action.payload;
        },

        participantWorkConditionalityLoaded: (state, action) => {
            state.currentParticipantWorkConditionality =
                action.payload === '' ? {} : action.payload;
        },

        participantWorkHistoryLoaded: (state, action) => {
            state.currentParticipantWorkHistory = action.payload === '' ? {} : action.payload;
        },

        participantWorkHistoryLoading: (state) => {
            state.currentParticipantWorkHistory = {};
        },

        participantJobGoalCreated: (state, action) => {
            state.currentParticipantJobGoals = [
                ...state.currentParticipantJobGoals,
                action.payload
            ];
        },

        participantJobGoalDeleted: (state, action) => {
            state.currentParticipantJobGoals = state.currentParticipantJobGoals.filter(
                (el) => el.id !== action.payload
            );
        },

        participantJobGoalUpdated: (state, action) => {
            const existing = state.currentParticipantJobGoals.find(
                (el) => el.id === action.payload.id
            );
            state.currentParticipantJobGoals = existing
                ? state.currentParticipantJobGoals.map((el) =>
                      el.id === action.payload.id ? action.payload : el
                  )
                : [...state.currentParticipantJobGoals, action.payload];
        },

        participantJobGoalsLoaded: (state, action) => {
            state.currentParticipantJobGoals = action.payload === '' ? {} : action.payload;
        },

        participantEmployabilityLoading: (state) => {
            state.currentParticipantEmployability = {};
        },

        participantEmployabilityLoaded: (state, action) => {
            state.currentParticipantEmployability = action.payload === '' ? {} : action.payload;
        },

        participantLoading: (state) => {
            state.currentParticipant = {};
        },

        participantLoaded: (state, action) => {
            state.currentParticipant = action.payload;
        },

        participantsLoaded: (state, action) => {
            // this is necessary as different endpoints for loading participants return a different structure
            state.participants =
                'content' in action.payload ? action.payload.content : action.payload;
        },

        participantsByLoggedInUserServiceIdsLoading: (state) => {
            state.participantsByLoggedInUserServiceIdsLoading = true;
        },

        participantsByLoggedInUserServiceIdsLoaded: (state, action) => {
            state.participantsByLoggedInUserServiceIdsLoading = false;
            const { content, ...rest } = action.payload;
            state.participantsByLoggedInUserServiceIds = rest.first
                ? content
                : [...state.participantsByLoggedInUserServiceIds, ...content];
            state.participantsByLoggedInUserServiceIdsMetaData = rest;
        },

        clearParticipantsSearch: (state) => {
            state.participantsSearch = [];
            state.participantsSearchMetaData = getEmptyPaginationState();
        },

        participantsSearchLoaded: (state, action) => {
            const { content, ...rest } = action.payload;
            state.participantsSearch = rest.first
                ? content
                : [...state.participantsSearch, ...content];
            state.participantsSearchMetaData = rest;
        },

        recentParticipantsLoaded: (state, action) => {
            state.recentParticipants = action.payload;
        },

        participantInformationLoaded: (state, action) => {
            state.currentParticipantInformation = action.payload;
        },

        participantBarriersLoaded: (state, action) => {
            state.currentParticipantBarriers = action.payload;
        },

        participantBarrierAdded: (state, action) => {
            state.currentParticipantBarriers.push(action.payload);
        },

        participantBarrierUpdated: (state, action) => {
            const index = state.currentParticipantBarriers.findIndex(
                (barrier) => barrier.id === action.payload.id
            );
            state.currentParticipantBarriers[index] = action.payload;
        },

        participantInformationUpdated: (state, action) => {
            state.currentParticipantInformation = action.payload;
        },

        participantPastEducationUpdated: (state, action) => {
            state.currentParticipantPastEducation = action.payload;
        },

        participantWorkHistoryUpdated: (state, action) => {
            state.currentParticipantWorkHistory = action.payload;
        },

        participantWorkConditionalityUpdated: (state, action) => {
            state.currentParticipantWorkConditionality = action.payload;
        },

        participantUpdated: (state, action) => {
            state.currentParticipant = action.payload;
        },
        participantSectionHeadingsCreated: (state, action) => {
            state.participantSectionHeadings = action.payload;
        },

        participantSectionHeadingsLoaded: (state, action) => {
            state.participantSectionHeadings = action.payload;
        },

        participantSectionHeadingsUpdated: (state, action) => {
            state.participantSectionHeadings = action.payload;
        },

        participantTimelineEventCreated: (state, action) => {
            state.participantTimelineEvent = action.payload;
            state.participantTimelineEvents = [...state.participantTimelineEvents, action.payload];
        },

        participantTimelineEventsLoaded: (state, action) => {
            state.participantTimelineEvents =
                'content' in action.payload ? action.payload.content : action.payload;
        },

        participantTimelineSearchDataLoading: (state) => {
            state.participantTimelineSearchDataLoading = true;
            state.participantTimelineSearchData = [];
        },

        participantTimelineSearchDataLoaded: (state, action) => {
            state.participantTimelineSearchDataLoading = false;
            state.participantTimelineSearchDataLoaded = true;
            state.participantTimelineSearchData = action.payload;
        },

        clearTimeLineSearchData: (state) => {
            state.participantTimelineSearchDataLoaded = false;
            state.participantTimelineSearchData = [];
        },

        physicalActivitiesLoading: (state) => {
            state.physicalActivityEntries = [];
        },

        physicalActivitiesLoaded: (state, action) => {
            state.physicalActivityEntries = action.payload;
        },

        physicalActivityCreated: (state, action) => {
            state.physicalActivityEntries = [...state.physicalActivityEntries, action.payload];
        },

        physicalActivityUpdated: (state, action) => {
            state.physicalActivityEntries = state.physicalActivityEntries.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        physicalActivityDeleted: (state, action) => {
            state.physicalActivityEntries = state.physicalActivityEntries.filter(
                (el) => el.id !== action.payload
            );
        },

        questionnaireAnswerDeleted: (state, action) => {
            state.questionnaireAnswers = state.questionnaireAnswers.filter(
                (el) => el.id !== action.payload
            );
        },

        questionnaireAnswersLoaded: (state, action) => {
            state.questionnaireAnswers = action.payload;
        },

        clearQuestionnaireAnswers: (state) => {
            state.questionnaireAnswers = [];
        },

        questionnaireAnswersUpdated: (state, action) => {
            let questionnaireAnswers;
            if (Array.isArray(state.questionnaireAnswers)) {
                questionnaireAnswers = state.questionnaireAnswers.filter(
                    (el) => el.id !== action.payload.id
                );
            }

            if (questionnaireAnswers) {
                state.questionnaireAnswers = [...questionnaireAnswers, action.payload];
            } else {
                state.questionnaireAnswers = [action.payload];
            }
        },

        questionnairesLoaded: (state, action) => {
            state.questionnaires = action.payload;
        },

        questionnaireLoaded: (state, action) => {
            state.currentQuestionnaire = action.payload;
        },

        questionnaireCreated: (state, action) => {
            state.currentQuestionnaire = action.payload;
            state.questionnaires = [...state.questionnaires, action.payload];
        },

        questionnaireUpdated: (state, action) => {
            const questionnaires = state.questionnaires.filter((el) => el.id !== action.payload.id);
            state.questionnaires = [...questionnaires, action.payload];
            state.currentQuestionnaire = action.payload;
        },

        setCurrentQuestionnaire: (state, action) => {
            state.currentQuestionnaire = action.payload;
        },

        questionnaireContractsLoading: (state) => {
            state.questionnaireContracts = {};
        },

        questionnaireContractsLoaded: (state, action) => {
            state.questionnaireContracts = action.payload;
        },

        questionnaireContractUpdated: (state, action) => {
            state.questionnaireContracts = action.payload;
        },

        referralDataLoaded: (state, action) => {
            state.referralHistory = action.payload.referralHistory;
            state.referralSummary = action.payload.referralSummary;
        },

        referralDataUpdated: (state, action) => {
            state.referralHistory = [...state.referralHistory, ...action.payload.referralHistory];
        },

        serviceConstantsLoaded: (state, action) => {
            state.serviceConstants =
                action.payload === ''
                    ? state.serviceConstants
                    : {
                          ...state.serviceConstants,
                          [action.payload.serviceId]: action.payload
                      };
        },

        serviceConstantsCreated: (state, action) => {
            state.serviceConstants = {
                ...state.serviceConstants,
                [action.payload.serviceId]: action.payload
            };
        },

        serviceConstantsUpdated: (state, action) => {
            state.serviceConstants = {
                ...state.serviceConstants,
                [action.payload.serviceId]: action.payload
            };
        },

        signPostingsLoading: (state) => {
            state.signPostings = [];
        },

        signPostingsLoaded: (state, action) => {
            state.signPostings = action.payload;
        },

        signPostingCreated: (state, action) => {
            state.signPostings = [...state.signPostings, action.payload];
        },

        signPostingUpdated: (state, action) => {
            state.signPostings = state.signPostings.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        signPostingDeleted: (state, action) => {
            state.signPostings = state.signPostings.filter((el) => el.id !== action.payload);
        },

        smokingQuitAttemptsLoading: (state) => {
            state.smokingQuitAttempts = [];
            state.smokingQuitAttemptReviews = [];
        },

        smokingQuitAttemptsLoaded: (state, action) => {
            state.smokingQuitAttempts = action.payload;
            state.smokingQuitAttemptReviews = action.payload?.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur.id]: cur.smokingQuitAttemptReviews
                }),
                {}
            );
        },

        smokingQuitAttemptCreated: (state, action) => {
            state.smokingQuitAttempts = [...state.smokingQuitAttempts, action.payload];
            // no reviews yet at this point
        },

        smokingQuitAttemptUpdated: (state, action) => {
            state.smokingQuitAttempts = state.smokingQuitAttempts.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            // reviews are updated separately
        },

        smokingQuitAttemptReviewLoaded: (state, action) => {
            const id = action.payload?.smokingQuitAttemptId;
            if (!id) return;
            const smokingQuitAttemptReviews = {
                ...state.smokingQuitAttemptReviews,
                [id]:
                    state.smokingQuitAttemptReviews[id] &&
                    Array.isArray(state.smokingQuitAttemptReviews[id])
                        ? [
                              ...state.smokingQuitAttemptReviews[id].filter(
                                  (el) => el.id !== action.payload.id
                              ),
                              action.payload
                          ]
                        : [action.payload]
            };

            state.smokingQuitAttemptReviews = smokingQuitAttemptReviews;
            state.smokingQuitAttempts = state.smokingQuitAttempts.map((el) =>
                el.id === id
                    ? { ...el, smokingQuitAttemptReviews: smokingQuitAttemptReviews[el.id] }
                    : el
            );
        },

        smokingQuitAttemptReviewDeleted: (state, action) => {
            state.smokingQuitAttempts = state.smokingQuitAttempts.map((el) => ({
                ...el,
                smokingQuitAttemptReviews: el.smokingQuitAttemptReviews.map((entry) => ({
                    ...entry,
                    inactive: entry.id === action.payload.reviewId ? true : entry.inactive
                }))
            }));

            state.smokingQuitAttemptReviews = {
                ...state.smokingQuitAttemptReviews,
                [action.payload.quitAttemptId]: state.smokingQuitAttemptReviews[
                    action.payload.quitAttemptId
                ].map((el) => ({
                    ...el,
                    inactive: el.id === action.payload.reviewId ? true : el.inactive
                }))
            };
            // soft delete - inactive col. set to true
        },

        stepsLoading: (state) => {
            state.steps = [];
        },

        stepsLoaded: (state, action) => {
            state.steps = action.payload;
        },

        stepCreated: (state, action) => {
            state.steps = [...state.steps, action.payload];
        },

        stepUpdated: (state, action) => {
            state.steps = state.steps.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        stepDeleted: (state, action) => {
            state.steps = state.steps.filter((el) => el.id !== action.payload);
        },

        waistMeasurementsLoading: (state) => {
            state.waistMeasurements = [];
        },

        waistMeasurementsLoaded: (state, action) => {
            state.waistMeasurements = action.payload;
        },

        waistMeasurementCreated: (state, action) => {
            state.waistMeasurements = [...state.waistMeasurements, action.payload];
        },

        waistMeasurementUpdated: (state, action) => {
            state.waistMeasurements = state.waistMeasurements.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        waistMeasurementDeleted: (state, action) => {
            state.waistMeasurements = state.waistMeasurements.filter(
                (el) => el.id !== action.payload
            );
        },

        setParticipantSearchData: (state, action) => {
            const { type, data } = action.payload;
            state.participantSearchData.type = type;
            state.participantSearchData.data = data;
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        }
    }
});

// ActionCreators. These are commands so 'createParticipant' (command) rather
// than 'participantCreated' (event that has happened)

export const loadActionPlans = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/action-plan/participant/${participantId}`,
        onStart: actionPlansLoading.type,
        onSuccess: actionPlansLoaded.type,
        onError: errorOccurred.type
    });

export const createActionPlan = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/action-plan',
        method: 'post',
        data,
        onSuccess: actionPlanCreated.type,
        successMessage: 'Participant action plan has been added',
        onError: errorOccurred.type
    });

export const updateActionPlan = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/action-plan/${data.id}`,
        method: 'put',
        data,
        onSuccess: actionPlanUpdated.type,
        successMessage: 'Participant action plan has been updated',
        onError: errorOccurred.type
    });

export const loadAlcoholConsumed = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/alcohol-consumed/participant/${participantId}`,
        onStart: alcoholConsumedLoading.type,
        onSuccess: alcoholConsumedLoaded.type,
        successMessage: `Alcohol consumed entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createAlcoholConsumed = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/alcohol-consumed`,
        method: 'post',
        data,
        onSuccess: alcoholConsumedCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateAlcoholConsumed = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/alcohol-consumed/${id}`,
        method: 'put',
        data,
        onSuccess: alcoholConsumedUpdated.type,
        successMessage: `Alcohol consumed entry updated`,
        onError: errorOccurred.type
    });

export const deleteAlcoholConsumed = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/alcohol-consumed/${id}`,
        method: 'delete',
        successMessage: `Alcohol consumed entry deleted`,
        onError: errorOccurred.type
    });

export const loadAppointmentAttendanceDetails = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/appointment/participant/${participantId}`,
        onStart: appointmentAttendanceDetailsLoading.type,
        onSuccess: appointmentAttendanceDetailsLoaded.type,
        successMessage: `Appointments loaded`,
        onError: errorOccurred.type
    });

export const loadAppointmentAttendanceDetailsBySearch = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/appointment/search`,
        method: 'post',
        data,
        onStart: appointmentAttendanceDetailsLoading.type,
        onSuccess: appointmentAttendanceDetailsLoaded.type,
        successMessage: `Appointments loaded`,
        onError: errorOccurred.type
    });

export const loadMostRecentAppointmentAttendanceDetails = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/appointment/participant/${participantId}/recent`,
        onSuccess: mostRecentAppointmentAttendanceDetailsLoaded.type,
        successMessage: `Last appointment loaded`,
        onError: errorOccurred.type
    });

export const createAppointmentAttendanceDetail = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/appointment`,
        method: 'post',
        data,
        onSuccess: appointmentAttendanceDetailCreated.type,
        successMessage: `Appointment created`,
        onError: errorOccurred.type
    });

export const updateAppointmentAttendanceDetail = (data, id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/appointment/${id}`,
        method: 'put',
        data,
        onSuccess: appointmentAttendanceDetailUpdated.type,
        successMessage: `Appointment created`,
        onError: errorOccurred.type
    });

export const createAssetRequest = (data) => {
    return apiCallBegan({
        url: getParticipantURL() + `rest/asset-request`,
        method: 'post',
        data,
        onSuccess: assetRequestCreated.type,
        successMessage: `Asset request created`,
        onError: errorOccurred.type
    });
};
export const loadAssetRequests = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/asset-request/participant/${participantId}`,
        onSuccess: assetRequestsLoaded.type,
        onError: errorOccurred.type
    });

export const updateAssetRequest = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/asset-request/${data.id}`,
        method: 'put',
        data,
        onSuccess: assetRequestsUpdated.type,
        successMessage: 'Asset request updated',
        onError: errorOccurred.type
    });

export const updateAssetIssueRequest = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/asset-request/issue/${data.id}`,
        method: 'put',
        data,
        onSuccess: assetRequestsUpdated.type,
        successMessage: 'Asset issue request updated',
        onError: errorOccurred.type
    });

export const loadParticipantBarriers = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/barrier/participant/' + id,
        onSuccess: participantBarriersLoaded.type,
        successMessage: 'Participant barriers loaded',
        onError: errorOccurred.type
    });

export const addParticipantBarrier = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/barrier',
        method: 'post',
        data: data,
        onSuccess: participantBarrierAdded.type,
        successMessage: 'Participant barrier(s) added',
        onError: errorOccurred.type
    });

export const updateParticipantBarrier = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/barrier/${data.id}`,
        method: 'put',
        data: data,
        onSuccess: participantBarrierUpdated.type,
        successMessage: 'Participant barrier details have been updated',
        onError: errorOccurred.type
    });

export const loadBloodGlucose = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-glucose/participant/${participantId}`,
        onStart: bloodGlucoseLoading.type,
        onSuccess: bloodGlucoseLoaded.type,
        successMessage: `Blood glucose entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createBloodGlucose = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-glucose`,
        method: 'post',
        data,
        onSuccess: bloodGlucoseCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateBloodGlucose = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-glucose/${id}`,
        method: 'put',
        data,
        onSuccess: bloodGlucoseUpdated.type,
        successMessage: `Blood glucose entry updated`,
        onError: errorOccurred.type
    });

export const deleteBloodGlucose = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-glucose/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadBloodPressure = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-pressure/participant/${participantId}`,
        onStart: bloodPressureLoading.type,
        onSuccess: bloodPressureLoaded.type,
        successMessage: `Blood pressure entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createBloodPressure = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-pressure`,
        method: 'post',
        data,
        onSuccess: bloodPressureCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateBloodPressure = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-pressure/${id}`,
        method: 'put',
        data,
        onSuccess: bloodPressureUpdated.type,
        successMessage: `Blood pressure entry updated`,
        onError: errorOccurred.type
    });

export const deleteBloodPressure = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-pressure/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadBloodTests = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-test/participant/${participantId}`,
        onStart: bloodTestsLoading.type,
        onSuccess: bloodTestsLoaded.type,
        successMessage: `Blood test entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createBloodTest = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-test`,
        method: 'post',
        data,
        onSuccess: bloodTestCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateBloodTest = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-test/${id}`,
        method: 'put',
        data,
        onSuccess: bloodTestUpdated.type,
        successMessage: `Blood test entry updated`,
        onError: errorOccurred.type
    });

export const deleteBloodTest = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/blood-test/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadBMIEntries = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/bmi/participant/${participantId}`,
        onStart: bmiLoading.type,
        onSuccess: bmiLoaded.type,
        successMessage: `BMI entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createBMI = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/bmi`,
        method: 'post',
        data,
        onSuccess: bmiCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateBMI = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/bmi/${id}`,
        method: 'put',
        data,
        onSuccess: bmiUpdated.type,
        successMessage: `BMI entry updated`,
        onError: errorOccurred.type
    });

export const deleteBMI = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/bmi/${id}`,
        method: 'delete',
        successMessage: `BMI entry deleted`,
        onError: errorOccurred.type
    });

export const loadParticipantCircumstances = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/circumstances/' + id,
        onSuccess: participantCircumstancesLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantCircumstances = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/circumstances/' + data.participantId,
        method: 'put',
        data,
        onSuccess: participantCircumstancesLoaded.type,
        successMessage: 'Participant circumstance details have been updated',
        onError: errorOccurred.type
    });

export const createClaimEvent = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/claim-type`,
        method: 'post',
        data,
        onStart: claimEventLoading.type,
        onSuccess: claimEventCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadClaimEvent = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/claim-type/${id}`,
        onStart: claimEventLoading.type,
        onSuccess: claimEventLoaded.type,
        successMessage: `Claim event loaded`,
        onError: errorOccurred.type
    });

export const loadClaimEvents = () =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/claim-type?size=500`,
        onSuccess: claimEventsLoaded.type,
        successMessage: `Claim events loaded`,
        onError: errorOccurred.type
    });

export const searchClaimEvents = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/claim-type/search`,
        method: 'post',
        data,
        onStart: claimEventSearchLoading.type,
        onSuccess: claimEventSearchLoaded.type,
        onError: errorOccurred.type
    });

export const updateClaimEvent = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/claim-type/${data.id}`,
        method: 'put',
        data,
        onStart: claimEventLoading.type,
        onSuccess: claimEventLoaded.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const searchClaimQueue = (
    data,
    page = 0,
    size = 10,
    sort = 'lastStatusUpdated',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getParticipantURL() +
            `rest/claim-queue/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: claimQueuesSearchLoading.type,
        onSuccess: claimQueuesSearchLoaded.type,
        onError: errorOccurred.type
    });

export const updateClaimQueue = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/claim-queue/${data.id}`,
        method: 'put',
        data,
        onSuccess: claimQueueUpdated.type,
        successMessage: `Claim queue has been updated`,
        onError: errorOccurred.type
    });

export const updateClaimQueueActionStatus = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/claim-queue/action-status/${data.id}`,
        method: 'put',
        data,
        onSuccess: claimQueueUpdated.type,
        successMessage: `Claim queue action status has been updated`,
        onError: errorOccurred.type
    });

export const updateClaimQueueProgress = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/claim-queue/progress',
        method: 'put',
        data,
        onSuccess: claimQueuesUpdated.type,
        successMessage: `Claim queues have been updated`,
        onError: errorOccurred.type
    });

export const loadClaimQueueForecast = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/claim-queue/forecast/${participantId}`,
        onStart: claimQueueForecastLoading.type,
        onSuccess: claimQueueForecastLoaded.type,
        onError: errorOccurred.type
    });

export const loadContractConstants = (contractId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/contract-constants/contract/${contractId}`,
        onStart: contractConstantsLoading.type,
        onSuccess: contractConstantsLoaded.type,
        onError: errorOccurred.type
    });

export const updateContractConstants = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/contract-constants/${data.id}`,
        method: 'put',
        data,
        onSuccess: contractConstantsUpdated.type,
        successMessage: 'Contract constants has been updated',
        onError: errorOccurred.type
    });

export const createDar = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/dar',
        method: 'post',
        data,
        onSuccess: darCreated.type,
        successMessage: 'DAR has been added',
        onError: errorOccurred.type
    });

export const searchDarQueue = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/dar/search`,
        method: 'post',
        data,
        onStart: darQueuesSearchLoading.type,
        onSuccess: darQueuesSearchLoaded.type,
        onError: errorOccurred.type
    });

export const updateDar = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/dar/${data.id}`,
        method: 'put',
        data,
        onSuccess: darUpdated.type,
        successMessage: `DAR has been updated`,
        onError: errorOccurred.type
    });

export const loadParticipantPastEducation = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/past-education/participant/${participantId}`,
        onSuccess: participantPastEducationLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantPastEducation = (data, participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/past-education/participant/${participantId}`,
        method: 'put',
        data,
        onSuccess: participantPastEducationUpdated.type,
        successMessage: 'Participant past education details have been updated',
        onError: errorOccurred.type
    });

export const createEligibilityGroup = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group`,
        method: 'post',
        data,
        onSuccess: eligibilityGroupCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadEligibilityGroup = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/${id}`,
        onSuccess: eligibilityGroupLoaded.type,
        successMessage: `Eligibility group loaded`,
        onError: errorOccurred.type
    });

export const loadEligibilityGroups = () =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group?size=500`,
        onSuccess: eligibilityGroupsLoaded.type,
        successMessage: `Eligibility groups loaded`,
        onError: errorOccurred.type
    });

export const updateEligibilityGroup = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/${data.id}`,
        method: 'put',
        data,
        onSuccess: eligibilityGroupUpdated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const deleteEligibilityGroup = (msg, id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/${id}`,
        method: 'delete',
        onSuccess: eligibilityGroupUpdated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const searchEligibilityGroups = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/eligibility-group/search`,
        method: 'post',
        data,
        onStart: eligibilityGroupSearchLoading.type,
        onSuccess: eligibilityGroupSearchLoaded.type,
        onError: errorOccurred.type
    });

export const loadParticipantEmployability = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/employability/' + id,
        onStart: participantEmployabilityLoading.type,
        onSuccess: participantEmployabilityLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantEmployability = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/participant/employability/${data.participantId}`,
        method: 'put',
        data,
        onSuccess: participantEmployabilityLoaded.type,
        successMessage: 'Participant employability details have been updated',
        onError: errorOccurred.type
    });

export const loadFinancialRequests = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `/rest/financial-request/participant/${participantId}`,
        onSuccess: financialRequestsLoaded.type,
        onError: errorOccurred.type
    });

export const createFinancialRequest = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/financial-request',
        method: 'post',
        data,
        onSuccess: financialRequestCreated.type,
        successMessage: 'Participant financial request has been added',
        onError: errorOccurred.type
    });

export const updateFinancialRequest = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/financial-request/${data.id}`,
        method: 'put',
        data,
        onSuccess: financialRequestUpdated.type,
        successMessage: 'Participant financial request has been updated',
        onError: errorOccurred.type
    });

export const updateFinancialIssueRequest = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/financial-request/issue/${data.id}`,
        method: 'put',
        data,
        onSuccess: financialRequestUpdated.type,
        successMessage: 'Participant financial issue request updated',
        onError: errorOccurred.type
    });

export const createForm = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + 'rest/form',
        method: 'post',
        data,
        onSuccess: formCreated.type,
        successMessage: 'Form has been saved successfully',
        onError: errorOccurred.type
    });

export const loadForm = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/${id}`,
        onStart: formLoading.type,
        onSuccess: formLoaded.type,
        onError: errorOccurred.type
    });

export const loadForms = () =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form`,
        onStart: formsLoading.type,
        onSuccess: formsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFormsByContract = (contractId) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/contract/${contractId}`,
        onSuccess: formsByContractLoaded.type,
        onError: errorOccurred.type
    });

export const updateForm = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/${data.id}`,
        method: 'put',
        data,
        onSuccess: formUpdated.type,
        successMessage: 'Form has been updated successfully',
        onError: errorOccurred.type
    });

export const loadFormContractByContract = (contractId) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/contract/${contractId}`,
        method: 'get',
        onSuccess: formContractsLoaded.type,
        onError: errorOccurred.type
    });

export const updateFormContract = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/contract/${data.contractId}`,
        method: 'put',
        data,
        onSuccess: formContractUpdated.type,
        successMessage: 'Form Contracts have been updated',
        onError: errorOccurred.type
    });

export const authoriseFormParticipant = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${id}/authorise`,
        method: 'put',
        onSuccess: formParticipantUpdated.type,
        successMessage: 'Form participant has been authorised successfully',
        onError: errorOccurred.type
    });

export const createFormParticipant = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${data.participantId}`,
        method: 'post',
        data,
        onSuccess: formParticipantCreated.type,
        successMessage: 'Form participant has been created successfully',
        onError: errorOccurred.type
    });

export const emailFormParticipant = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${id}/email`,
        method: 'put',
        onSuccess: formParticipantUpdated.type,
        successMessage: 'Form participant has been emailed successfully',
        onError: errorOccurred.type
    });

export const loadFormsParticipantByParticipantId = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `/rest/form/participant/${id}`,
        onSuccess: formsParticipantLoaded.type,
        onError: errorOccurred.type
    });

export const printFormParticipant = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${id}/print`,
        method: 'put',
        onSuccess: formParticipantUpdated.type,
        successMessage: 'Form participant has been printed successfully',
        onError: errorOccurred.type
    });

export const reopenFormParticipant = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${id}/re-open`,
        method: 'put',
        onSuccess: formParticipantUpdated.type,
        successMessage: 'Form participant has been reopened successfully',
        onError: errorOccurred.type
    });

export const searchFormParticipant = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `/rest/form/participant/search`,
        method: 'post',
        data,
        onSuccess: formsParticipantLoaded.type,
        successMessage: 'Form participant has been loaded successfully',
        onError: errorOccurred.type
    });

export const updateFormParticipant = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${data.id}`,
        method: 'put',
        data,
        onSuccess: formParticipantUpdated.type,
        successMessage: 'Form participant has been updated successfully',
        onError: errorOccurred.type
    });

export const withdrawFormParticipant = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/form/participant/${id}/withdraw`,
        method: 'put',
        onSuccess: formParticipantUpdated.type,
        successMessage: 'Form participant has been withdrawn successfully',
        onError: errorOccurred.type
    });

export const loadParticipantGeneralInformation = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/general-information/' + id,
        onSuccess: participantGeneralInformationLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantGeneralInformation = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/general-information/' + data.participantId,
        method: 'put',
        data,
        onSuccess: participantInformationUpdated.type,
        successMessage: 'Participant general details have been updated',
        onError: errorOccurred.type
    });

export const loadHealthIncidents = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/health-incident/participant/${participantId}`,
        onStart: healthIncidentsLoading.type,
        onSuccess: healthIncidentsLoaded.type,
        successMessage: `Health Incident entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createIWSRecord = (data, businessName = '') =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/in-work-support/record',
        method: 'post',
        data,
        successMessage: `IWS Record ${businessName ? `for ${businessName} ` : ''}has been added`,
        onError: errorOccurred.type
    });

export const loadIWSRecordsByPlacementId = (
    placementId,
    page = 0,
    size = 50,
    sort = 'frequencyWeekNo',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getParticipantURL() +
            `rest/participant/in-work-support/record/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data: { placementIds: [placementId] },
        onStart: iwsRecordsByPlacementIdLoading.type,
        onSuccess: iwsRecordsByPlacementIdLoaded.type,
        successMessage: `IWS Record entries for placement have been loaded`,
        onError: errorOccurred.type
    });

export const updateIWSRecord = (data, businessName = '') =>
    apiCallBegan({
        url: getParticipantURL() + `rest/participant/in-work-support/record/${data.id}`,
        method: 'put',
        data,
        successMessage: `IWS Record ${businessName ? `for ${businessName} ` : ''}has been updated`,
        onError: errorOccurred.type
    });

export const updateIWSRecordAdhocCall = (data, businessName = '') =>
    apiCallBegan({
        url: getParticipantURL() + `rest/participant/in-work-support/record/adhoc/${data.id}`,
        method: 'put',
        data,
        successMessage: `IWS Record ${businessName ? `for ${businessName} ` : ''}has been updated`,
        onError: errorOccurred.type
    });

export const searchIWSRecords = (data, page = 0, size = 10, sort = 'dueDate', dir = 'asc') =>
    apiCallBegan({
        url:
            getParticipantURL() +
            `rest/participant/in-work-support/record/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: iwsRecordsLoading.type,
        onSuccess: iwsSearchRecordsLoaded.type,
        successMessage: `IWS Record entries for selected users have been loaded`,
        onError: errorOccurred.type
    });

export const createHealthIncident = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/health-incident`,
        method: 'post',
        data,
        onSuccess: healthIncidentCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateHealthIncident = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/health-incident/${id}`,
        method: 'put',
        data,
        onSuccess: healthIncidentUpdated.type,
        successMessage: `Health Incident entry updated`,
        onError: errorOccurred.type
    });

export const deleteHealthIncident = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/health-incident/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadOccActivity = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/occ/participant/${participantId}/activity`,
        onSuccess: occActivityLoaded.type,
        successMessage: `OCC activity for ${participantId} has been loaded`,
        onError: errorOccurred.type
    });

export const loadOccProfiles = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/occ/participant/${participantId}`,
        onSuccess: occProfilesLoaded.type,
        successMessage: `OCC profile entries for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createOccProfile = (participantId, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/occ/participant/${participantId}`,
        method: 'post',
        data,
        onSuccess: occProfileCreated.type,
        successMessage: `OCC profile for ${participantId} has been created`,
        onError: errorOccurred.type
    });

export const loadParticipantHealthInformation = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/health-information/' + id,
        onSuccess: participantHealthInformationLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantHealthInformation = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/health-information/' + data.participantId,
        method: 'put',
        data,
        onSuccess: participantHealthInformationLoaded.type,
        successMessage: 'Participant health details have been updated',
        onError: errorOccurred.type
    });

export const loadParticipantJobGoals = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/job-goal/participant/${id}`,
        onSuccess: participantJobGoalsLoaded.type,
        onError: errorOccurred.type
    });

export const createParticipantJobGoal = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/job-goal`,
        method: 'post',
        data,
        successMessage: msg ? msg : 'Job goal created',
        onSuccess: participantJobGoalCreated.type,
        onError: errorOccurred.type
    });

export const updateParticipantJobGoal = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/job-goal/${data.id}`,
        method: 'put',
        data,
        successMessage: msg ? msg : 'Job goal updated',
        onSuccess: participantJobGoalUpdated.type,
        onError: errorOccurred.type
    });

export const deleteParticipantJobGoal = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/job-goal/${id}`,
        method: 'delete',
        successMessage: msg,
        onSuccess: participantJobGoalDeleted.type,
        onError: errorOccurred.type
    });

export const loadParticipant = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/' + id,
        onStart: participantLoading.type,
        onSuccess: participantLoaded.type,
        onError: errorOccurred.type
    });

export const loadParticipantByPtCode = (ptCode) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/participant/code/${ptCode}`,
        onStart: participantLoading.type,
        onSuccess: participantLoaded.type,
        onError: errorOccurred.type
    });

export const loadParticipants = () =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant?size=500',
        onSuccess: participantsLoaded.type,
        onError: errorOccurred.type
    });

// gets 10 most recent participants a user has accessed
export const loadRecentParticipants = () =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/simple-search-recent',
        onSuccess: recentParticipantsLoaded.type,
        onError: errorOccurred.type
    });

export const loadParticipantsSimpleSearch =
    (term, page = 0, size = 10, sortBy = 'firstName', dir = 'asc') =>
    (dispatch) => {
        if (page === 0) {
            dispatch(setParticipantSearchData({ type: 'simple', data: term }));
        }
        dispatch(
            apiCallBegan({
                url:
                    getParticipantURL() +
                    applyPagination(
                        'rest/participant/simple-search/',
                        term,
                        page,
                        size,
                        dir,
                        sortBy
                    ),
                onSuccess: participantsSearchLoaded.type,
                onError: errorOccurred.type
            })
        );
    };

export const loadParticipantsAdvancedSearch =
    (data, page = 0, size = 10, sortBy = 'firstName', dir = 'asc') =>
    (dispatch) => {
        if (page === 0) {
            dispatch(setParticipantSearchData({ type: 'advanced', data }));
        }
        dispatch(
            apiCallBegan({
                url:
                    getParticipantURL() +
                    applyPagination(
                        'rest/participant/advanced-search',
                        null,
                        page,
                        size,
                        dir,
                        sortBy
                    ),
                method: 'post',
                data,
                onSuccess: participantsSearchLoaded.type,
                onError: errorOccurred.type
            })
        );
    };

export const searchParticipants = (data, page = 0, size = 50, sort = 'firstName', dir = 'asc') =>
    apiCallBegan({
        url:
            getParticipantURL() +
            `rest/participant/advanced-search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onSuccess: participantsSearchLoaded.type,
        onError: errorOccurred.type
    });

export const searchParticipantsByLoggedInUserServiceIds = (
    serviceIds,
    page = 0,
    size = 50,
    sort = 'firstName',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getParticipantURL() +
            `rest/participant/advanced-search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data: { serviceIds },
        onStart: participantsByLoggedInUserServiceIdsLoading.type,
        onSuccess: participantsByLoggedInUserServiceIdsLoaded.type,
        onError: errorOccurred.type
    });

export const createParticipant = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant',
        method: 'post',
        data,
        onSuccess: participantCreated.type,
        successMessage: `New participant created`,
        onError: errorOccurred.type
    });

export const updateParticipant = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant/' + data.id,
        method: 'put',
        data,
        onSuccess: participantUpdated.type,
        successMessage: `Participant primary details have been updated`,
        onError: errorOccurred.type
    });

export const loadPhysicalActivities = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/physical-activity/participant/${participantId}`,
        onStart: physicalActivitiesLoading.type,
        onSuccess: physicalActivitiesLoaded.type,
        successMessage: `Physical activities for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createPhysicalActivity = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/physical-activity`,
        method: 'post',
        data,
        onSuccess: physicalActivityCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updatePhysicalActivity = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/physical-activity/${id}`,
        method: 'put',
        data,
        onSuccess: physicalActivityUpdated.type,
        successMessage: `Physical activity updated`,
        onError: errorOccurred.type
    });

export const deletePhysicalActivity = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/physical-activity/${id}`,
        method: 'delete',
        successMessage: `Physical activity deleted`,
        onError: errorOccurred.type
    });

export const loadParticipantSectionHeadings = (id) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-section-heading/' + id,
        onSuccess: participantSectionHeadingsLoaded.type,
        onError: errorOccurred.type
    });

export const createParticipantSectionHeadings = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-section-heading/',
        method: 'post',
        data,
        onSuccess: participantSectionHeadingsCreated.type,
        onError: errorOccurred.type
    });

export const updateParticipantSectionHeadings = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-section-heading/' + data.id,
        method: 'put',
        data,
        onSuccess: participantSectionHeadingsUpdated.type,
        successMessage: 'Participant section headings have been updated',
        onError: errorOccurred.type
    });

export const createQuestionnaire = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + 'rest/questionnaire',
        method: 'post',
        data,
        onSuccess: questionnaireCreated.type,
        successMessage: 'Questionnaire has been created successfully',
        onError: errorOccurred.type
    });

export const deleteQuestionnaireAnswer = (id, msg) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/questionnaire/answer/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadQuestionnaires = () =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/questionnaire`,
        onSuccess: questionnairesLoaded.type,
        onError: errorOccurred.type
    });

export const loadQuestionnaireById = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/questionnaire/${id}`,
        onSuccess: questionnaireLoaded.type,
        onError: errorOccurred.type
    });

export const updateQuestionnaire = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/questionnaire/${data.id}`,
        method: 'put',
        data,
        onSuccess: questionnaireUpdated.type,
        successMessage: 'Questionnaire has been updated successfully',
        onError: errorOccurred.type
    });

export const loadQuestionnaireAnswersById = (id) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/questionnaire/answer/${id}`,
        onSuccess: questionnaireAnswersLoaded.type,
        onError: errorOccurred.type
    });

export const loadQuestionnaireAnswersByParticipant = (participantId) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `/rest/questionnaire/answer/participant/${participantId}`,
        onSuccess: questionnaireAnswersLoaded.type,
        onError: errorOccurred.type
    });

export const loadQuestionnaireAnswersByQuestionnaire = (questionnaireId) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `/rest/questionnaire/answer/questionnaire/${questionnaireId}`,
        onSuccess: questionnaireAnswersLoaded.type,
        onError: errorOccurred.type
    });

export const postQuestionnaireAnswersComplete = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + 'rest/questionnaire/answer/complete',
        method: 'post',
        data,
        onSuccess: questionnaireAnswersUpdated.type,
        successMessage: 'Questionnaire answers have been completed successfully',
        onError: errorOccurred.type
    });

export const postQuestionnaireAnswersDraft = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + 'rest/questionnaire/answer/draft',
        method: 'post',
        data,
        onSuccess: questionnaireAnswersUpdated.type,
        successMessage: 'Questionnaire answers have been drafted successfully',
        onError: errorOccurred.type
    });

export const createQuestionnaireContract = (data) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + 'rest/questionnaire/contract',
        method: 'post',
        data,
        onSuccess: questionnaireContractUpdated.type,
        successMessage: 'Questionnaire Contracts have been updated',
        onError: errorOccurred.type
    });

export const loadQuestionnaireContractByContract = (contractId) =>
    apiCallDirectusBegan({
        url: getParticipantURL() + `rest/questionnaire/contract/${contractId}`,
        method: 'get',
        onSuccess: questionnaireContractsLoaded.type,
        onError: errorOccurred.type
    });

export const loadReferralData = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/referral-history/participant/${participantId}`,
        onSuccess: referralDataLoaded.type,
        successMessage: `Referral data for ${participantId} has been loaded`,
        onError: errorOccurred.type
    });

export const submitReferralHistory = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/referral-history/participant/submit/${participantId}`,
        method: 'put',
        onSuccess: referralDataUpdated.type,
        successMessage: `Referral history for ${participantId} has been loaded`,
        onError: errorOccurred.type
    });

export const loadServiceConstants = (serviceId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/service-constants/service/${serviceId}`,
        onSuccess: serviceConstantsLoaded.type,
        onError: errorOccurred.type
    });

export const createServiceConstants = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/service-constants',
        method: 'post',
        data,
        onSuccess: serviceConstantsCreated.type,
        successMessage: 'Service constants has been added',
        onError: errorOccurred.type
    });
export const updateServiceConstants = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/service-constants/${data.id}`,
        method: 'put',
        data,
        onSuccess: serviceConstantsUpdated.type,
        successMessage: 'Service constants has been updated',
        onError: errorOccurred.type
    });

export const loadSignPostings = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/sign-posting/participant/${participantId}`,
        onStart: signPostingsLoading.type,
        onSuccess: signPostingsLoaded.type,
        successMessage: `SignPostings for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createSignPosting = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/sign-posting`,
        method: 'post',
        data,
        onSuccess: signPostingCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateSignPosting = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/sign-posting/${id}`,
        method: 'put',
        data,
        onSuccess: signPostingUpdated.type,
        successMessage: `SignPosting updated`,
        onError: errorOccurred.type
    });

export const deleteSignPosting = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/sign-posting/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadSmokingQuitAttempts = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt/participant/${participantId}`,
        onStart: smokingQuitAttemptsLoading.type,
        onSuccess: smokingQuitAttemptsLoaded.type,
        successMessage: `Smoking quit attempts for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createSmokingQuitAttempt = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt`,
        method: 'post',
        data,
        onSuccess: smokingQuitAttemptCreated.type,
        successMessage: `Smoking quit attempt created`,
        onError: errorOccurred.type
    });

export const updateSmokingQuitAttempt = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt/${id}`,
        method: 'put',
        data,
        onSuccess: smokingQuitAttemptUpdated.type,
        successMessage: `Smoking quit attempt updated`,
        onError: errorOccurred.type
    });

export const deleteSmokingQuitAttempt = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt/${id}`,
        method: 'delete',
        successMessage: `Smoking quit attempt deleted`,
        onError: errorOccurred.type
    });

export const createSmokingQuitAttemptReviews = (id, data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt/${id}/reviews`,
        method: 'post',
        data,
        onSuccess: smokingQuitAttemptReviewsCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const createSmokingQuitAttemptReview = (smokingQuitAttemptId, data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt/${smokingQuitAttemptId}/review`,
        method: 'post',
        data,
        onSuccess: smokingQuitAttemptReviewLoaded.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateSmokingQuitAttemptReview = (smokingQuitAttemptId, data, msg) =>
    apiCallBegan({
        url:
            getParticipantURL() +
            `rest/smoking-quit-attempt/${smokingQuitAttemptId}/review/${data.id}`,
        method: 'put',
        data,
        onSuccess: smokingQuitAttemptReviewLoaded.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const deleteSmokingQuitAttemptReview = (id, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/smoking-quit-attempt/review/${id}`,
        method: 'delete',
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadSteps = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/step/participant/${participantId}`,
        onStart: stepsLoading.type,
        onSuccess: stepsLoaded.type,
        successMessage: `Steps for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createStep = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/step`,
        method: 'post',
        data,
        onSuccess: stepCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateStep = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/step/${id}`,
        method: 'put',
        data,
        onSuccess: stepUpdated.type,
        successMessage: `Step updated`,
        onError: errorOccurred.type
    });

export const deleteStep = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/step/${id}`,
        method: 'delete',
        successMessage: `Step deleted`,
        onError: errorOccurred.type
    });

export const loadParticipantTimelineEvent = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-timeline-event/search/',
        method: 'post',
        data,
        onSuccess: participantTimelineEventsLoaded.type,
        successMessage: `TimelineEvents have been loaded`,
        onError: errorOccurred.type
    });

export const searchParticipantTimelineEvent = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-timeline-event/search/',
        method: 'post',
        data,
        onStart: participantTimelineSearchDataLoading.type,
        onSuccess: participantTimelineSearchDataLoaded.type,
        onError: errorOccurred.type
    });

export const createParticipantTimelineEvent = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-timeline-event',
        method: 'post',
        data,
        onSuccess: participantTimelineEventCreated.type,
        successMessage: `Timeline entry has been added`,
        onError: errorOccurred.type
    });

export const deleteParticipantTimelineEvent = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/participant-timeline-event/${id}`,
        method: 'delete',
        successMessage: `Timeline entry has been deleted`,
        onError: errorOccurred.type
    });

export const createParticipantTimelineCommunicationEvent = (data) =>
    apiCallBegan({
        url: getParticipantURL() + 'rest/participant-timeline-communication-event',
        method: 'post',
        data,
        onSuccess: participantTimelineEventCreated.type,
        successMessage: `Timeline communication entry has been added`,
        onError: errorOccurred.type
    });

export const loadWaistMeasurements = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/waist-measurement/participant/${participantId}`,
        onStart: waistMeasurementsLoading.type,
        onSuccess: waistMeasurementsLoaded.type,
        successMessage: `Waist measurements for ${participantId} have been loaded`,
        onError: errorOccurred.type
    });

export const createWaistMeasurement = (data, msg) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/waist-measurement`,
        method: 'post',
        data,
        onSuccess: waistMeasurementCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateWaistMeasurement = (id, data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/waist-measurement/${id}`,
        method: 'put',
        data,
        onSuccess: waistMeasurementUpdated.type,
        successMessage: `Waist measurement updated`,
        onError: errorOccurred.type
    });

export const deleteWaistMeasurement = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/waist-measurement/${id}`,
        method: 'delete',
        successMessage: `Waist measurement deleted`,
        onError: errorOccurred.type
    });

export const loadParticipantWorkConditionality = (id) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/work-conditionality/participant/${id}`,
        onSuccess: participantWorkConditionalityLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantWorkConditionality = (data) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/work-conditionality/participant/${data.participantId}`,
        method: 'put',
        data,
        onSuccess: participantWorkConditionalityUpdated.type,
        successMessage: 'Participant work conditionality details have been updated',
        onError: errorOccurred.type
    });

export const loadParticipantWorkHistory = (participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/work-history/participant/${participantId}`,
        onStart: participantWorkHistoryLoading.type,
        onSuccess: participantWorkHistoryLoaded.type,
        onError: errorOccurred.type
    });

export const updateParticipantWorkHistory = (data, participantId) =>
    apiCallBegan({
        url: getParticipantURL() + `rest/work-history/participant/${participantId}`,
        method: 'put',
        data,
        onSuccess: participantWorkHistoryUpdated.type,
        successMessage: 'Participant work history details have been updated',
        onError: errorOccurred.type
    });

export const setSuccessMessage = (message) => successMessageSet(message);
export const setErrorMessage = (message) => errorMessageSet(message);
export const setCurrentlySelectedParticipant = (participant) => participantUpdated(participant);
export const saveCurrentParticipantId = (currentParticipantId) => {
    localStorage.setItem('currentParticipantId', currentParticipantId);
};

export const onDeleteAlcoholConsumed = (id) => alcoholConsumedDeleted(id);

export const onDeleteBloodGlucose = (id) => bloodGlucoseDeleted(id);

export const onDeleteBloodPressure = (id) => bloodPressureDeleted(id);
export const onDeleteBloodTest = (id) => bloodTestDeleted(id);
export const onDeleteBMIEntry = (id) => bmiDeleted(id);

export const onDeleteEligibilityGroup = (id) => eligibilityGroupDeleted(id);
export const deleteJobGoal = (id) => participantJobGoalDeleted(id);

export const onDeleteHealthIncident = (id) => healthIncidentDeleted(id);
export const onDeleteQuestionnaireAnswer = (id) => questionnaireAnswerDeleted(id);
export const onDeletePhysicalActivity = (id) => physicalActivityDeleted(id);

export const onDeleteSmokingQuitAttempt = (id) => smokingQuitAttemptDeleted(id);

export const onDeleteSmokingQuitAttemptReview = (quitAttemptId, reviewId) =>
    smokingQuitAttemptReviewDeleted({
        quitAttemptId,
        reviewId
    });
export const onDeleteStep = (id) => stepDeleted(id);
export const onDeleteSignPosting = (id) => signPostingDeleted(id);
export const onDeleteWaistMeasurement = (id) => waistMeasurementDeleted(id);

export const {
    clearAppointmentAttendanceDetails,
    clearClaimQueues,
    clearParticipantsSearch,
    clearQuestionnaireAnswers,
    clearTimeLineSearchData,
    setCurrentQuestionnaire,
    setCurrentForm
} = slice.actions;
// Put selectors for getting data from state here
// Selectors take state and return computed state

// Used internally
const {
    actionPlanCreated,
    actionPlansLoading,
    actionPlansLoaded,
    actionPlanUpdated,
    alcoholConsumedLoading,
    alcoholConsumedLoaded,
    alcoholConsumedCreated,
    alcoholConsumedUpdated,
    alcoholConsumedDeleted,
    appointmentAttendanceDetailsLoading,
    appointmentAttendanceDetailsLoaded,
    assetRequestCreated,
    assetRequestsLoaded,
    assetRequestsUpdated,
    mostRecentAppointmentAttendanceDetailsLoaded,
    appointmentAttendanceDetailCreated,
    appointmentAttendanceDetailUpdated,
    bloodGlucoseLoading,
    bloodGlucoseLoaded,
    bloodGlucoseCreated,
    bloodGlucoseUpdated,
    bloodGlucoseDeleted,
    bloodPressureLoading,
    bloodPressureLoaded,
    bloodPressureCreated,
    bloodPressureUpdated,
    bloodPressureDeleted,
    bloodTestsLoading,
    bloodTestsLoaded,
    bloodTestCreated,
    bloodTestUpdated,
    bloodTestDeleted,
    bmiLoading,
    bmiLoaded,
    bmiCreated,
    bmiUpdated,
    bmiDeleted,
    claimEventCreated,
    claimEventLoading,
    claimEventLoaded,
    claimEventsLoaded,
    claimEventSearchLoading,
    claimEventSearchLoaded,
    claimQueueForecastLoading,
    claimQueueForecastLoaded,
    claimQueueUpdated,
    claimQueuesUpdated,
    claimQueuesSearchLoading,
    claimQueuesSearchLoaded,
    contractConstantsLoading,
    contractConstantsLoaded,
    contractConstantsUpdated,
    darCreated,
    darQueuesSearchLoading,
    darQueuesSearchLoaded,
    darUpdated,
    errorMessageSet,
    errorOccurred,
    eligibilityGroupCreated,
    eligibilityGroupDeleted,
    eligibilityGroupLoaded,
    eligibilityGroupsLoaded,
    eligibilityGroupSearchLoading,
    eligibilityGroupSearchLoaded,
    eligibilityGroupUpdated,
    financialRequestCreated,
    financialRequestsLoaded,
    financialRequestUpdated,
    formContractsLoaded,
    formContractUpdated,
    formCreated,
    formLoaded,
    formLoading,
    formUpdated,
    formsLoaded,
    formsByContractLoaded,
    formsLoading,
    formParticipantCreated,
    formsParticipantLoaded,
    formParticipantUpdated,
    healthIncidentCreated,
    healthIncidentDeleted,
    healthIncidentsLoaded,
    healthIncidentsLoading,
    healthIncidentUpdated,
    iwsRecordsByPlacementIdLoading,
    iwsRecordsByPlacementIdLoaded,
    iwsRecordsLoading,
    iwsSearchRecordsLoaded,
    occActivityLoaded,
    occProfileCreated,
    occProfilesLoaded,
    participantCreated,
    participantLoaded,
    participantLoading,
    participantsLoaded,
    participantsByLoggedInUserServiceIdsLoading,
    participantsByLoggedInUserServiceIdsLoaded,
    recentParticipantsLoaded,
    participantsSearchLoaded,
    participantGeneralInformationLoaded,
    participantHealthInformationLoaded,
    participantCircumstancesLoaded,
    participantJobGoalCreated,
    participantJobGoalDeleted,
    participantJobGoalUpdated,
    participantJobGoalsLoaded,
    participantEmployabilityLoaded,
    participantEmployabilityLoading,
    participantPastEducationLoaded,
    participantWorkConditionalityLoaded,
    participantWorkHistoryLoaded,
    participantWorkHistoryLoading,
    participantBarriersLoaded,
    participantBarrierAdded,
    participantBarrierUpdated,
    participantUpdated,
    participantInformationUpdated,
    participantPastEducationUpdated,
    participantWorkConditionalityUpdated,
    participantWorkHistoryUpdated,
    participantSectionHeadingsCreated,
    participantSectionHeadingsLoaded,
    participantSectionHeadingsUpdated,
    participantTimelineEventCreated,
    participantTimelineEventsLoaded,
    participantTimelineSearchDataLoading,
    participantTimelineSearchDataLoaded,
    physicalActivitiesLoading,
    physicalActivitiesLoaded,
    physicalActivityCreated,
    physicalActivityUpdated,
    physicalActivityDeleted,
    questionnaireAnswerDeleted,
    questionnaireAnswersLoaded,
    questionnaireAnswersUpdated,
    questionnaireContractUpdated,
    questionnaireContractsLoaded,
    questionnaireCreated,
    questionnaireLoaded,
    questionnaireUpdated,
    questionnairesLoaded,
    referralDataLoaded,
    referralDataUpdated,
    serviceConstantsCreated,
    serviceConstantsLoaded,
    serviceConstantsUpdated,
    signPostingsLoading,
    signPostingsLoaded,
    signPostingCreated,
    signPostingUpdated,
    signPostingDeleted,
    smokingQuitAttemptsLoading,
    smokingQuitAttemptsLoaded,
    smokingQuitAttemptCreated,
    smokingQuitAttemptDeleted,
    smokingQuitAttemptReviewsCreated,
    smokingQuitAttemptReviewLoaded,
    smokingQuitAttemptReviewDeleted,
    smokingQuitAttemptUpdated,
    stepsLoading,
    stepsLoaded,
    stepCreated,
    stepUpdated,
    stepDeleted,
    waistMeasurementsLoading,
    waistMeasurementsLoaded,
    waistMeasurementCreated,
    waistMeasurementUpdated,
    waistMeasurementDeleted,
    setParticipantSearchData,
    successMessageSet
} = slice.actions;

export default slice.reducer;
