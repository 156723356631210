import React from 'react';
import PropTypes from 'prop-types';

import EmailIcon from '@mui/icons-material/Email';
import { Tooltip } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

const OnEmailIcon = ({ onEmail, roles, acceptedRoles, active = true, tooltip = 'Edit' }) => {
    acceptedRoles = acceptedRoles?.length > 0 ? acceptedRoles : [];
    if (hasRole(roles, acceptedRoles) && active) {
        return (
            <Tooltip title={tooltip} placement="top">
                <EmailIcon
                    sx={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}
                    aria-label="email-icon"
                    data-testid="email-icon"
                    onClick={onEmail}></EmailIcon>
            </Tooltip>
        );
    }
    return (
        <Tooltip title={tooltip} placement="top">
            <EmailIcon
                sx={{ color: POLARIS_DARKER_GREY, cursor: 'not-allowed' }}
                data-testid="email-icon"
                aria-label="email-icon"></EmailIcon>
        </Tooltip>
    );
};

export default OnEmailIcon;

OnEmailIcon.propTypes = {
    onEmail: PropTypes.func,
    active: PropTypes.bool,
    tooltip: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};
