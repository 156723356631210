import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import { Button, Divider } from '@mui/material';

import {
    loadJobSectorDetails,
    loadPoolStatusDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { setErrorMessage } from '../../../../store/formsState';
import { loadParticipant } from '../../../../store/participantService';
import {
    createVacancySubmissions,
    loadPoolApplicationsForOwnersByPoolIds,
    loadPoolsForOwnersByUser
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getFutureDate } from '../../../../utils/dateFunctions';
import { getNoUpdateMessage } from '../../../../utils/formValidation/errorMessageFunctions';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import SingleSelect from '../../../formElements/SingleSelect';
import DynamicSearch from '../../../search/DynamicSearch';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../../../ui/pickers/DDLMultiOptionPicker';

import SubmissionPoolTable from './SubmissionPoolTable';

import classes from '../../recruitmentStyles/submission.module.css';

const SubmissionPoolTableManagement = () => {
    const { handleSubmit } = useForm({});
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const poolOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [rows, setRows] = useState([]);
    // const [filteredRows, setFilteredRows] = useState([]);
    const [updatedRows, setUpdatedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [poolApplicationsLoaded, setPoolApplicationsLoaded] = useState(false);
    const [poolOwners, setPoolOwners] = useState([]);
    const [selectedPoolOwner, setSelectedPoolOwner] = useState('');
    const [preSelectedJobSectorIds, setPreSelectedJobSectorIds] = useState([]);
    const [selectedJobSectorIds, setSelectedJobSectorIds] = useState([]);
    const [arrayJobSectors, setArrayJobSectors] = useState([]);

    const [clearSelectedPoolOwner, setClearSelectedPoolOwner] = useState('0');
    const [clearSelectedJobSector, setClearSelectedJobSector] = useState('1');

    // STORE STATE
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { currentVacancy, poolApplicationsForOwners, poolsForOwners, vacancySubmissions } =
        useSelector((state) => state.entities.recruitmentService);
    const { jobSectorDetails, poolStatusDetails, vacancyStageDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // HELPER FNS
    const setPoolOwnersArray = (poolOwners) => {
        const updatedPoolOwners = addEmailAddressAsNameToArray(poolOwners);
        setPoolOwners(updatedPoolOwners);
    };

    const onRowChange = (row) => {
        const rows = updatedRows.map((el) => (el.id === row.id ? row : el));
        setUpdatedRows(rows);
    };

    // USE EFFECTS
    useEffect(() => {
        setClearSelectedPoolOwner(Math.random());
        setClearSelectedJobSector(Math.random());

        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        poolStatusDetails?.length < 1 && dispatch(loadPoolStatusDetails());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        if (loggedInUser.roles.some((el) => poolOwnerRoles.includes(el))) {
            setSelectedPoolOwner(loggedInUser.id);
            setPoolOwnersArray([loggedInUser]);
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedPoolOwners = users.filter((el) =>
            el.userTypes?.find((entry) => poolOwnerRoles.includes(entry.role))
        );
        if (!updatedPoolOwners.length && users.length < totalUsers) {
            onLoadMorePoolOwners();
            return;
        }
        if (selectedPoolOwner && !updatedPoolOwners.some((el) => el.id === selectedPoolOwner)) {
            // Put selected pool owner at the top of dropdown if it's not in the updated pool owners array
            const selectedAdvisor = poolOwners.find((el) => el.id === selectedPoolOwner);
            updatedPoolOwners = [selectedAdvisor, ...updatedPoolOwners];
        }
        setPoolOwnersArray(updatedPoolOwners);
    }, [users]);

    useEffect(() => {
        if (!selectedPoolOwner) return;
        dispatch(loadPoolsForOwnersByUser(selectedPoolOwner));
    }, [selectedPoolOwner]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (poolsForOwners?.length < 1) {
            setRows([]);
            // setFilteredRows([]);
            setUpdatedRows([]);
            setPoolApplicationsLoaded(true);
            return;
        }

        setPoolApplicationsLoaded(false);
        const filteredPools = poolsForOwners.filter((el) => el.ownerId === selectedPoolOwner);

        if (filteredPools?.length < 1) {
            setRows([]);
            setUpdatedRows([]);
            setPoolApplicationsLoaded(true);
            return;
        }

        const filteredPoolIds = filteredPools.map((el) => el.id);
        const filteredPoolsWithId = filteredPools.map((el) => ({
            ...el,
            id: uuid(),
            poolId: el.id
        }));

        const filteredRowsWithSectorName = filteredPoolsWithId.map((el) => ({
            ...el,
            jobSectorName: jobSectorDetails.find((entry) => entry.id === el.jobSectorId)?.name || ''
        }));

        dispatch(loadPoolApplicationsForOwnersByPoolIds(filteredPoolIds));
        setRows(filteredRowsWithSectorName);
        setUpdatedRows(filteredRowsWithSectorName);
    }, [poolsForOwners]);

    useEffect(() => {
        if (poolApplicationsForOwners.length < 1 || rows?.length < 1) return;
        let updatedPoolsWithApplications = rows;

        poolApplicationsForOwners.forEach((el) => {
            let newPoolEntryArray = [];
            updatedPoolsWithApplications = updatedPoolsWithApplications.map((entry) => {
                if (entry.poolId === el.participantPoolId) {
                    if (entry.participantId) {
                        const newPoolEntry = {
                            id: uuid(),
                            ownerId: entry.ownerId,
                            jobSectorId: entry.jobSectorId,
                            jobSectorName: entry.jobSectorName,
                            teamIds: entry.teamIds,
                            poolId: entry.poolId,
                            requirements: entry.requirements,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || '',
                            statusId: el.statusId
                        };
                        // Check for duplicate
                        if (newPoolEntryArray.length > 0) {
                            if (
                                newPoolEntryArray.some(
                                    (npe) =>
                                        npe.jobSectorId === newPoolEntry.jobSectorId &&
                                        npe.poolId === newPoolEntry.poolId
                                )
                            ) {
                                return entry;
                            }
                        }
                        newPoolEntryArray = [...newPoolEntryArray, newPoolEntry];
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || '',
                            statusId: el.statusId
                        };
                    }
                } else {
                    if (entry.participantId) {
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantId: null,
                            goldStandardCVId: null,
                            startDate: '',
                            statusId: '',
                            statusName: ''
                        };
                    }
                }
            });
            if (Object.keys(newPoolEntryArray).length > 0) {
                updatedPoolsWithApplications = [
                    ...updatedPoolsWithApplications,
                    ...newPoolEntryArray
                ];
            }
        });
        // We only want entries where the participant has been accepted to a pool
        const statusId =
            poolStatusDetails.find((el) => el.name.toLowerCase() === 'accepted')?.id || '';
        const acceptedPools = updatedPoolsWithApplications.filter((el) => el.statusId === statusId);
        // We also need to check whether they have already been submitted to
        // a vacancy submission and remove duplicates without having to dispatch
        // again for efficiency
        const poolsNotAlreadySubmitted = acceptedPools.filter(
            (el) => !vacancySubmissions.find((entry) => entry.participantId === el.participantId)
        );
        if (!poolApplicationsLoaded) {
            setPoolApplicationsLoaded(true);
            setRows(poolsNotAlreadySubmitted);
            setUpdatedRows(poolsNotAlreadySubmitted);
            const participantIds = [
                ...new Set(poolsNotAlreadySubmitted.map((el) => el.participantId))
            ];
            participantIds.map((el) => {
                if (el) {
                    dispatch(loadParticipant(el));
                    dispatch(loadParticipantDocumentDetails(el));
                }
            });
        } else {
            const poolsRemoveDuplicates = poolsNotAlreadySubmitted.filter(
                (
                    (el) =>
                    ({ ptCode, participantId, poolId }) =>
                        (ptCode !== '' && !el.has(participantId)) ||
                        (ptCode !== '' && !el.has(poolId) && el.add(poolId))
                )(new Set())
            );
            setRows(poolsRemoveDuplicates);
            setUpdatedRows(poolsRemoveDuplicates);
        }
    }, [poolApplicationsForOwners, vacancySubmissions]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length > 0) {
            rows.map((el) => {
                if (el.participantId === currentParticipant.id) {
                    el.ptCode = currentParticipant.ptCode;
                    el.ptName = `${currentParticipant.firstName} ${currentParticipant.lastName}`;
                }
            });
            setUpdatedRows(rows);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (participantDocuments?.length > 0) {
            rows.map((el) => {
                participantDocuments.map((entry) => {
                    if (
                        el.participantId === entry.participantId &&
                        el.goldStandardCVId === entry.id
                    )
                        el.filename = entry?.filename || '';
                });
            });
            setUpdatedRows(rows);
        }
    }, [participantDocuments]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (rows.length < 1 || selectedJobSectorIds.length < 1) return;
        const filteredRows = rows.filter((el) =>
            selectedJobSectorIds.some((v) => v === el.jobSectorId)
        );
        // setFilteredRows(rows);
        setUpdatedRows(filteredRows);
    }, [selectedJobSectorIds]);

    useEffect(() => {
        if (successMessage === `Pool applications for owners have been loaded`) {
            setPoolApplicationsLoaded(true);
        }
    }, [successMessage]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.ptName.toLowerCase().includes(searchTerm.toLowerCase()));
        setUpdatedRows(filteredRows);
    }, [searchTerm]);

    // EVENT HANDLERS
    const onLoadMorePoolOwners = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    const handlePoolOwnerChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setSelectedPoolOwner(chosenId);
    };

    const handleJobSectorChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedJobSectorIds(chosenIds);
    };

    const onSubmit = () => {
        const statusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'submitted')?.id || '';

        const vacancySubmissionIds = updatedRows
            .filter((el) => el.update === true)
            .map((el) => ({
                participantId: el.participantId,
                statusId: statusId,
                goldStandardCvId: el.goldStandardCVId
            }));
        if (vacancySubmissionIds?.length < 1) {
            dispatch(setErrorMessage(getNoUpdateMessage()));
        } else {
            const payload = { submissions: vacancySubmissionIds };
            dispatch(createVacancySubmissions(payload, currentVacancy.id));
        }
    };

    // RENDER
    let content = '';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (!poolApplicationsLoaded) content = 'pool applications not loaded';
    if (jobSectorDetails?.length < 1 || !poolApplicationsLoaded)
        return <LoadingSpinner content={content} />;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <br />
                <div className={classes.submissionToolBar}>
                    <DynamicSearch
                        search={onSearch}
                        searchTerm={searchTerm}
                        placeholder="Enter Search Term"
                    />
                    <SingleSelect
                        id={'poolOwnerId'}
                        key={clearSelectedPoolOwner}
                        label={'Pool Owner'}
                        placeholder="Select Pool Owner..."
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        mandatory={true}
                        menuItems={poolOwners}
                        selectedId={selectedPoolOwner}
                        selected={poolOwners?.find((el) => el.id === selectedPoolOwner) || {}}
                        onChange={(e) => {
                            if (e !== null && e !== undefined) {
                                handlePoolOwnerChange(e);
                            }
                        }}
                        onLoadMoreItems={onLoadMorePoolOwners}
                        moreItemsToLoad={!totalUsers || users.length < totalUsers}
                    />
                    <DDLMultiOptionPicker
                        label="Job Sector"
                        id="jobSectors"
                        key={clearSelectedJobSector}
                        placeholder="Job Sector"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        menuItems={jobSectorDetails || []}
                        preSelectedIds={preSelectedJobSectorIds}
                        chosenIds={selectedJobSectorIds}
                        limitTags={1}
                        onChange={(chosenIds) => handleJobSectorChange(chosenIds)}
                    />
                </div>

                {updatedRows.length < 1 ? (
                    <LoadingSpinner content="No pools found" />
                ) : (
                    <SubmissionPoolTable
                        rows={updatedRows}
                        roles={loggedInUser.roles}
                        searchTerm={searchTerm}
                        onRowChange={onRowChange}
                    />
                )}
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={
                        !hasRole(acceptedRoles, loggedInUser.roles) ||
                        !getFutureDate(currentVacancy.vacancyClosingDate)
                    }
                    data-testid="testIdSubmitButton">
                    Update
                </Button>
                <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
            </form>
        </>
    );
};

export default SubmissionPoolTableManagement;
