import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import {
    clearCurrentTemplate,
    loadCommunicationTemplate,
    searchCommunicationTemplates,
    sendGroupEmailCommunication
} from '../../../../store/communicationService';
import { searchParticipants } from '../../../../store/participantService';
import {
    selectLoggedInUser,
    selectLoggedInUserName,
    selectUserRoles
} from '../../../../store/userSelectors';
import { loadLoggedInUser } from '../../../../store/userService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import SensitivityNotice from '../../../ui/notices/sensitivity/SensitivityNotice';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import { initialErrorState, validateGroupEmail } from './validateGroupCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../communicationStyles.module.css';

const PT_LOAD_SIZE = 65;
const GroupEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    // LOCAL STATE
    const TIME = 30;
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        templateId: '',
        templateName: '',
        emailAddresses: '',
        subject: '',
        details: '',
        signature: '',
        sigLen: 0
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [showData, setShowData] = useState(false);
    const msg = `Email Communication has been sent to ${state.group.name}`;

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const loggedInUserDetails = useSelector(
        (state) => state.entities.userService.loggedInUserDetails
    );
    const roles = useSelector(selectUserRoles);
    const userName = useSelector(selectLoggedInUserName);
    const participants = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const templates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const currentTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        dispatch(clearCurrentTemplate());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS
    useEffect(() => {
        if (!loggedInUser || !loggedInUser.id) return;
        dispatch(loadLoggedInUser(loggedInUser.id));
    }, [loggedInUser]);

    useEffect(() => {
        if (state.group.participants.length < 1) return;
        const ids = state.group.participants.map((el) => el.participantId);
        ids.length && dispatch(searchParticipants({ ids }, 0, PT_LOAD_SIZE));
    }, [state.group]);

    useEffect(() => {
        if (!loggedInUserDetails?.id) return;
        const name = `<p><br></p><p>${userName}</p>`;
        const job = `<p>${loggedInUserDetails?.jobTitle}</p>`;
        const phone = `<p>${loggedInUserDetails?.phoneNumber}</p>`;
        const signature = `${name}${loggedInUserDetails?.jobTitle ? job : ''}${
            loggedInUserDetails?.phoneNumber ? phone : ''
        }`;
        const sigLen = signature.length;
        setNewEntry((prev) => ({
            ...prev,
            signature,
            sigLen,
            details: newEntry.details.length ? newEntry.details : newEntry.details.concat(signature)
        }));
    }, [loggedInUserDetails, userName]);

    useEffect(() => {
        if (!participants || participants.length < 1) return;
        const emailAddresses = participants
            .map((el) =>
                state.group.participants.find((entry) => entry.participantId === el.id)
                    ?.contactMode === 'EMAIL'
                    ? el.emailAddress
                    : ''
            )
            .join('; ');

        setNewEntry((prev) => ({ ...prev, emailAddresses }));
        const contractIds = participants.map((el) => el.contractId);
        dispatch(
            searchCommunicationTemplates({
                type: 'EMAIL',
                contractIds: [...new Set(contractIds)],
                inactive: false
            })
        );
    }, [participants]);

    useEffect(() => {
        if (!newEntry.templateId || Object.keys(currentTemplate).length < 1) return;
        setNewEntry((prev) => ({
            ...prev,
            subject: currentTemplate.subject,
            details: currentTemplate.details.concat(newEntry.signature)
        }));
    }, [newEntry.templateId, currentTemplate]);

    useEffect(() => {
        if (successMessage === msg) {
            clearForm();
            setTimeout(() => navigate('/manage_groups'), 2000);
        }
    }, [successMessage]);

    // EVENT HANDLERS

    const onRevealData = () => {
        setShowData(!showData);
        setTimeout(() => setShowData((showData) => !showData), TIME * 1000);
    };

    const onDetailsChange = (details) => setNewEntry((prev) => ({ ...prev, details }));

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validateGroupEmail(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const { subject, details } = newEntry;
        const payload = { subject, body: details };
        dispatch(sendGroupEmailCommunication(payload, state.group.id, msg));
    };

    return (
        <div className={app.container} id="emailScreen">
            <div className={app.mainHeading}>Communications</div>
            <form className={`${form.formWrapper} ${local.formContainer}`} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.staticText}>
                            <div className={form.formLabel}>To Email Addresses</div>
                            <div className={`${form.textBox} ${form.alignLeft}`}>
                                {newEntry.emailAddresses}
                            </div>
                        </div>

                        {!showData && (
                            <div className={`${form.inputCover} ${form.coverHeightSm}`}>
                                <SensitivityNotice
                                    onClick={onRevealData}
                                    numRows={1}
                                    customClass={form.inputNotice}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${form.formSection} ${form.alignDownAlot}`}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Template'}
                            id={'templates'}
                            placeholder="Template"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={templates || []}
                            chosenName={newEntry.templateName}
                            chosenId={newEntry.templateId}
                            onChange={(chosenId) => {
                                chosenId && dispatch(loadCommunicationTemplate(chosenId));
                                const chosenTemplate = templates?.find((el) => el.id === chosenId);
                                setNewEntry((prev) => ({
                                    ...prev,
                                    templateId: chosenId || '',
                                    templateName: chosenTemplate?.name || ''
                                }));
                            }}></DDLOptionPicker>
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Email Subject'}
                            id={'subject'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.subject}
                            placeholder={'Enter email subject'}
                            onChange={(e) => {
                                clearError('subject');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    subject: e.target.value
                                }));
                            }}
                        />
                        {errors.subject.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.subject} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${form.formSection} ${form.alignDown}`}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            <ReactQuill
                                value={newEntry.details}
                                modules={GroupEmail.modules}
                                placeholder="Enter details"
                                onChange={onDetailsChange}
                                readOnly={!hasRole(acceptedRoles, roles)}
                            />
                            {errors.details.error && (
                                <div className={`${form.textInputError} ${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={form.buttonRight}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={!hasRole(acceptedRoles, roles) || newEntry.details.length < 1}>
                        Send Now
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default GroupEmail;

GroupEmail.modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['clean']
    ]
};
