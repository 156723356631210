import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import WarningIcon from '@mui/icons-material/Warning';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createBloodGlucose, updateBloodGlucose } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import { checkDate, checkGlucoseValue, initialErrorState, validate } from './validateBloodGlucose';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateBloodGlucose = ({ onClose, roles, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        participantId: '',
        eventDate: new Date().toISOString().slice(0, 10),
        measurementValue: '',
        measurementId: '',
        measurement: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const msg = `Participant blood glucose measurement has been ${
        formType === 'create' ? 'added' : 'updated'
    }`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const bloodPressureMeasurementTypes = useSelector(
        (state) => state.entities.directusService.bloodPressureMeasurementTypes
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            eventDate: row.displayDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onGlucoseValueChange = (value) => {
        clearError('measurementValue');
        const maxChar = value >= 10 ? 4 : 3;
        setNewEntry((prev) => ({
            ...prev,
            measurementValue: value.replace(/[^\d*.?]/g, '').slice(0, maxChar)
        }));

        const newErrors = checkGlucoseValue(value.slice(0, maxChar), errors);
        setErrors(newErrors);
    };

    const onDateChange = (date) => {
        clearError('eventDate');
        setNewEntry((prev) => ({ ...prev, eventDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const {
            // eslint-disable-next-line
            measurement,
            ...rest
        } = newEntry;
        const payload = { ...rest, participantId: currentParticipant?.id };
        formType === 'create'
            ? dispatch(createBloodGlucose(payload, msg))
            : dispatch(updateBloodGlucose(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>{formType === 'create' ? 'Add a New Blood Glucose' : 'Edit Blood Glucose'}</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label="Glucose Value"
                            id={'measurementValue'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.measurementValue}
                            placeholder={'Enter glucose value'}
                            onChange={(e) => onGlucoseValueChange(e.target.value)}
                        />

                        {errors.measurementValue.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.measurementValue} />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Measurement'}
                            id={'measurementId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={bloodPressureMeasurementTypes || []}
                            chosenName={newEntry.measurement}
                            chosenId={newEntry.measurementId}
                            onChange={(chosenId) => {
                                clearError('measurementId');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    measurementId: chosenId
                                }));
                            }}></DDLOptionPicker>
                        {errors.measurementId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.measurementId} />
                            </div>
                        )}
                    </div>
                </div>
                {newEntry.measurementValue > 15.0 && newEntry.measurementValue < 100 && (
                    <div className={`${form.amberWarning} ${form.alignWarningUp}`}>
                        <WarningIcon />
                        Values outside of healthy range, see Contract guidance for Health Incidents
                    </div>
                )}

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={
                        formType === 'create' ? 'Add New Blood Glucose' : 'Update Blood Glucose'
                    }
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateBloodGlucose;

CreateBloodGlucose.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};
