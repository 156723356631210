import { isDateFuture } from '../../../../utils/dateFunctions';

const ERROR_BAD_DATE = 'Date must be in the past or present';
const ERROR_BAD_VALUE = 'Value must be greater than zero';

export const initialErrorState = {
    measurement: { error: false, message: 'Please enter a measurement' },
    measurementDate: { error: false, message: 'Please enter a date' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (newEntry.measurement && +newEntry.measurement <= 0) {
        newErrors = {
            ...newErrors,
            measurement: { error: true, message: ERROR_BAD_VALUE }
        };
        isValid = false;
    }
    if (!newEntry.measurement) {
        newErrors = {
            ...newErrors,
            measurement: { ...errors.measurement, error: true }
        };
        isValid = false;
    }
    if (!newEntry.measurementDate) {
        newErrors = {
            ...newErrors,
            measurementDate: { ...errors.measurementDate, error: true }
        };
        isValid = false;
    }
    if (isDateFuture(newEntry.measurementDate)) {
        newErrors = {
            ...newErrors,
            stepsDate: { error: true, message: ERROR_BAD_DATE }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};

export const checkDate = (date, errors) => {
    return isDateFuture(date)
        ? {
              ...errors,
              measurementDate: { error: true, message: ERROR_BAD_DATE }
          }
        : {
              ...errors,
              measurementDate: initialErrorState.measurementDate
          };
};

export const checkMeasurement = (measurement, errors) => {
    return measurement <= 0
        ? {
              ...errors,
              measurement: { error: true, message: ERROR_BAD_VALUE }
          }
        : {
              ...errors,
              measurement: initialErrorState.measurement
          };
};
