import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {
    createOccProfile,
    loadOccActivity,
    loadOccProfiles
} from '../../../../store/participantService';
import { selectLoggedInUser, selectUsers } from '../../../../store/userSelectors';
import { loadUsersByServiceIds } from '../../../../store/userService';
import { formatDate, formatTime } from '../../../../utils/dateFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import AccordionHeader from '../../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../../ui/accordion/AccordionIcon';

import OccProfileTable from './OccProfileTable';
import OccSummaryData from './OccSummaryData';

import local from './occStyles.module.css';

const statuses = {
    200: { code: 200, message: 'Profile successfully created' },
    400: { code: 400, message: 'API Error. Please contact the system administrator' },
    401: { code: 401, message: 'API Error. Please contact the system administrator' },
    500: { code: 500, message: 'API Error. Please contact the system administrator' }
};

const OccManagement = ({ currentParticipant }) => {
    const dispatch = useDispatch();

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [isAddProfileDisabled, setIsAddProfileDisabled] = useState(false);

    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector(selectUsers);
    const occProfiles = useSelector((state) => state.entities.participantService.occProfiles);

    useEffect(() => {
        setRows([]);
    }, []);

    useEffect(() => {
        if (!loggedInUser || !('serviceIds' in loggedInUser)) return;
        loggedInUser.serviceIds.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        dispatch(loadOccProfiles(currentParticipant.id));
        dispatch(loadOccActivity(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (
            users?.length < 1 ||
            occProfiles?.length < 1 ||
            occProfiles[0].participantId !== currentParticipant.id
        )
            return;
        const rows = occProfiles.map((el) => ({
            ...el,
            eventDate: formatDate(el.date),
            time: formatTime(el.date),
            user: users.find((entry) => entry.id === el.userId)?.emailAddress || 'zz',
            status: statuses[el.statusCode]?.message || 'zz'
        }));
        setRows(rows);
        setIsAddProfileDisabled(occProfiles.some((el) => el.statusCode === 200));
    }, [occProfiles, users]);

    const onAddProfile = () => {
        const payload = {
            participantId: currentParticipant.id,
            userId: loggedInUser.id,
            theme: null
        };
        dispatch(createOccProfile(currentParticipant.id, payload));
        setIsAddProfileDisabled(true);
    };

    return (
        <div className={local.occWrapper}>
            <Accordion
                disabled={!hasRole(acceptedRoles, loggedInUser?.roles)}
                TransitionProps={{ mountOnEnter: true }}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls={`panel1a-content`}
                    sx={{ borderTop: '1px solid #000' }}
                    id={`panel1a-header`}>
                    <AccordionHeader>Online Career Centre</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={local.occActions}>
                        <Button
                            type="text"
                            color="primary"
                            variant="contained"
                            onClick={onAddProfile}
                            disabled={isAddProfileDisabled}>
                            Create OCC Profile
                        </Button>
                    </div>
                    <OccProfileTable rows={rows} />
                    <OccSummaryData currentParticipant={currentParticipant} />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default OccManagement;

OccManagement.propTypes = {
    currentParticipant: PropTypes.object
};
