import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import { loadVacancyStageDetails } from '../../../store/directusService';
import { loadUser } from '../../../store/userService';
import { formatTime, reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';

import classes from '../../../commonStyles/tables.module.css';

const TimelineRecruitmentEventHistoryTableRow = ({ row }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const users = useSelector((state) => state.entities.userService.users);
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    //  USE EFFECTS
    useEffect(() => {
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        if (row.userId) {
            if (users && users.length) {
                const emailAddress = users.find((el) => el.id === row.userId)?.emailAddress || '';
                emailAddress ? setEmailAddress(emailAddress) : dispatch(loadUser(row.userId));
            } else dispatch(loadUser(row.userId));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const emailAddress = users.find((el) => el.id === row.userId)?.emailAddress || '';
            emailAddress ? setEmailAddress(emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{reverseFormatDate(row?.eventDate.substring(0, 10))}</TableCell>
                <TableCell>{formatTime(row.eventDate)}</TableCell>
                <TableCell>{emailAddress}</TableCell>
                <TableCell>{getNameFromId(vacancyStageDetails, row.oldValue)}</TableCell>
                <TableCell>{getNameFromId(vacancyStageDetails, row.newValue)}</TableCell>
            </TableRow>
        </>
    );
};

export default TimelineRecruitmentEventHistoryTableRow;

TimelineRecruitmentEventHistoryTableRow.propTypes = {
    row: PropTypes.object
};
