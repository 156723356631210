import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import { setErrorMessage } from '../../../../../store/formsState';
import {
    loadBusinessRecord,
    loadVacancy,
    updatePlacement
} from '../../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../../store/userSelectors';
import { getFutureDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import * as validate from '../../../../../validation/validation';
import IconError from '../../../../IconError';
import BusinessView from '../../../../ui/cards/businessContact/BusinessView';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../../ui/pickers/NoYesRadioPicker';
import PolarisSwitchSet from '../../../../ui/pickers/PolarisSwitchSet';

import cardClasses from '../../../../../commonStyles/cardContainer.module.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import classes from '../../submissionStyles/placement.module.css';

const EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT = 'Expected weekly hours must be 48 or less';
const START_DATE_INVALID = 'Start date must not be a future date';

const RecruitmentPlacement = ({
    row,
    roles,
    placementEmploymentTypeDetails,
    placementStatusDetails,
    hoursPerWeekDetails
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    // State for create own placement newEntry
    // This matches the payload sent to the backend
    const initialState = {
        id: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        businessName: '',
        city: null,
        claimableEvent: true,
        hiringManagerEmail: null,
        employmentTypeId: null,
        endDate: '',
        endStatusId: null,
        fixedFlexibleHour: 'fixed',
        fullServiceDeclined: false,
        hiringManagerId: null,
        hiringManagerName: null,
        hiringManagerOfficeNumber: null,
        hiringManagerMobileNumber: null,
        hourlyPay: null,
        hoursPerWeek: null,
        hoursPerWeekId: null,
        jobSectorId: null,
        jobStartEvidence: false,
        participantId: null,
        postcode: null,
        startDate: '',
        vacancyId: null,
        vacancyTitle: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [notFixedHours, setNotFixedHours] = useState(false);
    const [endStatus, setEndStatus] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const { currentBusiness, currentVacancy } = useSelector(
        (state) => state.entities.recruitmentService
    );

    // EVENT HANDLERS
    const handleStartDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            startDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleEndDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            endDate: e.target.value === '' ? null : e.target.value
        }));
        if (!e.target.value) {
            setEndStatus(false);
            setNewEntry((prev) => ({
                ...prev,
                endStatusId: ''
            }));
        } else {
            setEndStatus(true);
            setValue('statusId', newEntry.endStatusId, {
                shouldValidate: true
            });
        }
    };

    const handleEndStatusChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, endStatusId: chosenId }));
        }
    };

    const handleVacancyTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, vacancyTitle: e.target.value }));
    };

    const handlePayRateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hourlyPay: e.target.value }));
    };

    const handleHoursPerWeekChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hoursPerWeek: e.target.value }));
    };

    const handleHoursPerWeekChangeDDL = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: chosenId }));
        }
    };

    const handleClaimableChange = (option) => {
        setNewEntry((prev) => ({ ...prev, claimableEvent: option }));
    };

    const handleJobStartChange = (option) => {
        setNewEntry((prev) => ({ ...prev, jobStartEvidence: option }));
    };

    const handleFullServiceChange = (option) => {
        setNewEntry((prev) => ({ ...prev, fullServiceDeclined: option }));
    };

    const onSwitchFixedOrFlexible = (e) => {
        const { checked } = e.target;
        setNotFixedHours(checked);

        if (checked) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeek: null }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'flexible' }));
        } else {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: '' }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'fixed' }));
        }
    };

    // USE EFFECTS
    useEffect(() => {
        if (!row) return;
        dispatch(loadVacancy(row.vacancyId));

        setNewEntry(row);
        setNewEntry((prev) => ({
            ...prev,
            startDate: row.startDate.substring(0, 10)
        }));

        setNotFixedHours(row.fixedFlexibleHour !== 'fixed');

        if (row.fixedFlexibleHour === 'fixed') {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: '' }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'fixed' }));
        } else {
            setNewEntry((prev) => ({ ...prev, hoursPerWeek: null }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'flexible' }));
        }

        // Force Yup validation on pre-populated fields
        setValue('startDate', row.startDate.substring(0, 10), {
            shouldValidate: true
        });
        if (row?.endDate) {
            setNewEntry((prev) => ({
                ...prev,
                endDate: row.endDate.substring(0, 10)
            }));
            setValue('statusId', row.statusId, {
                shouldValidate: true
            });
            setEndStatus(true);
        }
        setValue('title', row.vacancyTitle, {
            shouldValidate: true
        });
        setValue('hourlyPay', row.hourlyPay, {
            shouldValidate: true
        });
    }, []);

    useEffect(() => {
        if (Object.keys(currentVacancy).length < 1) return;

        dispatch(loadBusinessRecord(currentVacancy.businessRecordId));
    }, [currentVacancy]);

    const onSubmit = () => {
        if (newEntry?.startDate > newEntry.endDate) {
            dispatch(setErrorMessage('End date must be same as or after start date'));
            return;
        } else if (newEntry?.endDate && getFutureDate(newEntry?.endDate) === true) {
            dispatch(setErrorMessage('End date must be a date in the past or in the present'));
            return;
        } else if (newEntry?.endDate && !newEntry.endStatusId) {
            dispatch(setErrorMessage('End status must be entered when end date present'));
            return;
        } else if (
            newEntry.hourlyPay === null ||
            newEntry.hourlyPay === '0' ||
            newEntry.hourlyPay === '0.0' ||
            newEntry.hourlyPay === '0.00'
        ) {
            dispatch(setErrorMessage('Hourly pay cannot be zero or blank'));
            return;
        }
        if (newEntry.hoursPerWeek > '') {
            const hoursPerWeek = parseFloat(newEntry.hoursPerWeek);
            if (hoursPerWeek > 48) {
                dispatch(setErrorMessage(EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT));
            } else if (
                (!notFixedHours && newEntry.hoursPerWeek === null) ||
                (!notFixedHours && newEntry.hoursPerWeek === '0') ||
                (!notFixedHours && newEntry.hoursPerWeek === '0.0') ||
                (!notFixedHours && newEntry.hoursPerWeek === '0.00')
            ) {
                dispatch(setErrorMessage('Hours per week cannot be zero or blank'));
                return;
            }
        } else if (
            (notFixedHours && newEntry.hoursPerWeekId === '') ||
            (notFixedHours && newEntry.hoursPerWeekId === null)
        ) {
            dispatch(setErrorMessage('Please select hours per week'));
            return;
        }
        const payload = {
            ...newEntry,
            participantId: currentParticipant.id
        };
        dispatch(updatePlacement(payload));
    };

    // RENDER
    let content;
    if (Object.keys(currentVacancy).length < 1) content = 'No vacancy information found';
    if (Object.keys(currentBusiness).length < 1) content = 'No business information found';

    return Object.keys(currentVacancy).length < 1 || Object.keys(currentBusiness).length < 1 ? (
        <LoadingSpinner content={content} />
    ) : (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Employment Type'}
                                id={'type'}
                                disabled={true}
                                value={getNameFromId(
                                    placementEmploymentTypeDetails,
                                    row.employmentTypeId
                                )}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <TextField
                                className={classes.startDate}
                                label="Start Date *"
                                id="startDate"
                                disabled={!hasRole(acceptedRoles, roles)}
                                type="date"
                                value={newEntry.startDate || ''}
                                sx={{ width: '45%' }}
                                InputLabelProps={{ shrink: true }}
                                {...register('startDate')}
                                onChange={handleStartDateChange}
                            />
                            <TextField
                                label="End Date"
                                id="endDate"
                                disabled={!hasRole(acceptedRoles, roles)}
                                type="date"
                                value={newEntry.endDate || ''}
                                sx={{ width: '45%' }}
                                InputLabelProps={{ shrink: true }}
                                {...register('endDate')}
                                onChange={handleEndDateChange}
                            />
                            <IconError text={errors.startDate || null} />
                        </div>
                        <DDLOptionPicker
                            label={'End Status'}
                            id={'statusId'}
                            disabled={!(!hasRole(acceptedRoles, roles) || newEntry?.endDate)}
                            mandatory={endStatus}
                            menuItems={placementStatusDetails}
                            chosenName={getNameFromId(placementStatusDetails, newEntry.endStatusId)}
                            chosenId={newEntry.endStatusId}
                            error={errors.statusId}
                            {...register('statusId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleEndStatusChange(e);
                                }
                            }}
                        />
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Vacancy Title'}
                                id={'title'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.vacancyTitle || ''}
                                placeholder={'Enter vacancy title'}
                                error={errors.title}
                                {...register('title')}
                                onChange={handleVacancyTitleChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Hourly pay'}
                                id={'hourlyPay'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.hourlyPay || ''}
                                placeholder={'Enter hourly pay'}
                                error={errors.hourlyPay}
                                {...register('hourlyPay')}
                                onChange={handlePayRateChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <PolarisSwitchSet
                                id="fixedOrFlexible"
                                disabled={!hasRole(acceptedRoles, roles)}
                                label="Fixed or Flexible"
                                leftLabel="Fixed"
                                rightLabel="Flexible"
                                checked={notFixedHours}
                                onSwitch={onSwitchFixedOrFlexible}
                            />
                        </div>
                        {!notFixedHours ? (
                            <div className={classes.inputField}>
                                <LabelledTextField
                                    label={'Hours per Week'}
                                    id={'hoursPerWeek'}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={!notFixedHours && true}
                                    value={newEntry.hoursPerWeek || ''}
                                    placeholder={'Enter hours'}
                                    onChange={handleHoursPerWeekChange}
                                />
                            </div>
                        ) : (
                            <>
                                <DDLOptionPicker
                                    label={'Hours Per Week'}
                                    id="hoursPerWeekId"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={notFixedHours && true}
                                    menuItems={hoursPerWeekDetails}
                                    chosenName={getNameFromId(
                                        hoursPerWeekDetails,
                                        newEntry.hoursPerWeekId
                                    )}
                                    chosenId={newEntry.hoursPerWeekId}
                                    onChange={(e) => {
                                        if (e !== null && e !== undefined) {
                                            handleHoursPerWeekChangeDDL(e);
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <NoYesRadioPicker
                            id="claimable"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.claimableEvent}
                            text={'Claimable Event?'}
                            onChange={handleClaimableChange}></NoYesRadioPicker>
                        <NoYesRadioPicker
                            id="jobStart"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.jobStartEvidence}
                            text={'Job Start Evidence?'}
                            onChange={handleJobStartChange}></NoYesRadioPicker>
                        <NoYesRadioPicker
                            id="fullService"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.fullServiceDeclined}
                            text={'Full Service Declined?'}
                            onChange={handleFullServiceChange}></NoYesRadioPicker>
                        <Typography variant="h6">Hiring Manager</Typography>
                        <div className={cardClasses.cards}>
                            <BusinessView
                                cardData={currentBusiness.contactCards.find(
                                    (entry) => entry.id === row.hiringManagerId
                                )}
                            />
                        </div>
                    </div>
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                    Update
                </Button>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    startDate: Yup.string()
        .required('Please select a start date')
        .nullable()
        .test('startDate', START_DATE_INVALID, function (startDate) {
            return getFutureDate(startDate) === false;
        }),
    title: Yup.string()
        .min(1, 'Vacancy title must be at least one character')
        .max(100, 'Vacancy title must be 100 characters or less'),
    hourlyPay: Yup.string()
        .required('Please enter a valid hourly pay')
        .min(0, 'Hourly pay rate limit cannot be negative')
        .max(999.99, 'Hourly pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid hourly pay')
});

RecruitmentPlacement.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    placementEmploymentTypeDetails: PropTypes.array.isRequired,
    placementStatusDetails: PropTypes.array.isRequired,
    hoursPerWeekDetails: PropTypes.array.isRequired
};

export default RecruitmentPlacement;
