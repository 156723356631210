import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import { setErrorMessage } from '../../store/formsState';

const ErrorSnackbar = () => {
    const message = useSelector((state) => state.entities.formsState.errorMessage);
    const open = message.length > 0;

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setErrorMessage(''));
    };

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} action={action}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Error {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ErrorSnackbar;
