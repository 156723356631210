import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PortraitIcon from '@mui/icons-material/Portrait';
import RadarIcon from '@mui/icons-material/Radar';
import TodayIcon from '@mui/icons-material/Today';
import WarningIcon from '@mui/icons-material/Warning';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Card, CardContent, Checkbox, Typography } from '@mui/material';

import { selectAttendedStatusId } from '../../../../store/dataSelectors';
import { loadLocation } from '../../../../store/directusService';
import { loadUser } from '../../../../store/userService';
import {
    POLARIS_ROYAL_BLUE,
    POLARIS_VICTORIA_BLUE_BG,
    SCREEN_SETTINGS
} from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import DDLOptionPicker from '../../pickers/DDLOptionPicker';

import attendanceClasses from '../cardStyles/cardAttendanceView.module.css';
import classes from '../cardStyles/cardView.module.css';

const ParticipantAttendanceStatusView = ({
    module,
    currentParticipant,
    information,
    attendanceDetails,
    onModuleChange,
    disabled = false
}) => {
    const dispatch = useDispatch();
    // STORE STATE
    // const { roles } = useSelector((state) => state.entities.userService.loggedInUser);

    // LOCAL STATE
    const {
        title,
        description,
        locationId,
        date,
        startTime,
        endTime,
        userId,
        capacity,
        smartTarget,
        participants
    } = module;

    const [courseLocation, setCourseLocation] = useState('');
    const [adviser, setAdviser] = useState('');
    const [status, setStatus] = useState({});
    const [newModule, setNewModule] = useState({});

    // STORE STATE
    const users = useSelector((state) => state.entities.userService.users);
    const locations = useSelector((state) => state.entities.directusService.locationDetails);
    const attendedStatusId = useSelector(selectAttendedStatusId);
    // FNS
    const addAdviser = (adviser) => {
        const { firstName, lastName } = adviser;
        setAdviser(`${firstName} ${lastName}`);
    };
    const updateParticipants = (checked, status) => {
        if (checked) {
            return !participants.some((el) => el.participantId === currentParticipant.id)
                ? [
                      ...participants,
                      {
                          participantId: currentParticipant.id,
                          statusId: status?.id,
                          notes: information
                      }
                  ]
                : participants;
        } else {
            return date + 'T' + startTime > format(new Date(), "yyyy-MM-dd'T'HH:mm")
                ? participants.filter((el) => el.participantId !== currentParticipant.id)
                : participants;
        }
    };

    // USEEFFECTS

    useEffect(() => {
        if (userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === userId);
                user ? addAdviser(user) : dispatch(loadUser(userId));
            } else dispatch(loadUser(userId));
        }
        if (locationId) {
            if (locations && locations.length) {
                const location = locations.find((el) => el.id === locationId);
                location ? setCourseLocation(location.name) : dispatch(loadLocation(locationId));
            } else dispatch(loadLocation(locationId));
        }
    }, []);

    useEffect(() => {
        if (users.length) {
            const user = users.find((el) => el.id === userId);
            user && addAdviser(user);
        }
    }, [users]);

    useEffect(() => {
        if (locations.length) {
            const location = locations.find((el) => el.id === locationId);
            location && setCourseLocation(location.name);
        }
    }, [locations]);

    useEffect(() => {
        setNewModule(module);
        const status = attendanceDetails.find(
            (entry) =>
                entry.id ===
                (participants.find((el) => el.participantId === currentParticipant.id)?.statusId ||
                    '')
        );
        if (status?.name?.toLowerCase() === 'not known') {
            const updatedParticipants = updateParticipants(true, status.id);
            const updatedModule = {
                ...module,
                checked: true,
                participants: updatedParticipants
            };
            setNewModule(updatedModule);
        }
        setStatus(status);
    }, [module]);

    // EVENT HANDLERS
    const onCheck = (e) => {
        const { checked } = e.target;
        let getStatus = status;
        if (!getStatus) {
            getStatus = attendanceDetails.find((item) => item.name.toLowerCase() === 'not known');
            setStatus(getStatus);
        } else {
            getStatus = status;
        }
        const updatedParticipants = updateParticipants(checked, getStatus);
        const updatedModule = {
            ...module,
            checked,
            participants: updatedParticipants
        };
        setNewModule(updatedModule);
        onModuleChange(updatedModule, null);
    };

    const onStatusIdChange = (chosenId) => {
        if (chosenId !== '') {
            const updatedParticipants = participants.map((el) =>
                el.participantId === currentParticipant.id ? { ...el, statusId: chosenId } : el
            );
            const updatedModule = { ...newModule, participants: updatedParticipants };
            setNewModule(updatedModule);
            let actionPlanData = null;
            if (chosenId === attendedStatusId && module.smartTarget) {
                actionPlanData = {
                    participantId: currentParticipant.id,
                    mandatory: '',
                    actionTitle: smartTarget.name,
                    action: smartTarget.action,
                    actionStartDate: '',
                    actionDueDate: '',
                    actionOutcomeDate: '',
                    outcomeId: '',
                    moduleId: module.id,
                    comments: ''
                };
            }
            onModuleChange(updatedModule, actionPlanData);
        }
    };

    // RENDER
    return (
        <div className={classes.cardView}>
            <Card
                // disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                elevation={3}
                sx={{
                    backgroundColor: POLARIS_VICTORIA_BLUE_BG,
                    width: 250,
                    height: 'calc(100% - 16px)',
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <div className={`${title?.length > 60 ? classes.bannerDoubleRow : classes.banner}`}>
                    <Typography variant={'h6'}>{title || 'Participant Status'}</Typography>
                </div>
                <CardContent sx={{ padding: 1 }}>
                    <div>
                        <p>
                            <span className={classes.label}>Module Description: </span>
                            <span className={classes.description}>
                                {description && description.length > 100
                                    ? description.substring(0, 97) + '...'
                                    : description}
                            </span>
                        </p>
                        <section>
                            <LocationOnIcon />
                            {` ${courseLocation ? courseLocation : ' - '}`}
                        </section>
                        <section>
                            <TodayIcon />
                            {!date && !startTime && !endTime
                                ? ' - '
                                : ` ${date ? reverseFormatDate(date) : ''} ${
                                      startTime ? startTime : ''
                                  } ${endTime ? endTime : ''}`}
                        </section>
                        <section>
                            <PortraitIcon />
                            {adviser || ' - '}
                        </section>
                        <section>
                            <RadarIcon />
                            <span className={classes.smartTargetName}>
                                {smartTarget?.name || ' - '}
                            </span>
                        </section>
                        <div className={classes.check}>
                            <label
                                hidden={
                                    date + 'T' + startTime <=
                                    format(new Date(), "yyyy-MM-dd'T'HH:mm")
                                }>
                                <p>&nbsp;</p>
                                <Checkbox
                                    key={module.id}
                                    type="checkbox"
                                    sx={{ color: POLARIS_ROYAL_BLUE }}
                                    disabled={disabled}
                                    checked={newModule.checked || false}
                                    onClick={onCheck}
                                />
                                Module selected to attend
                            </label>
                        </div>
                        <br />

                        <span>
                            {' '}
                            {date + 'T' + startTime <= format(new Date(), "yyyy-MM-dd'T'HH:mm") ? (
                                status?.name?.toLowerCase() === 'not known' ? (
                                    <DDLOptionPicker
                                        key={0}
                                        id={'selectedAttendanceStatus'}
                                        mandatory={false}
                                        menuItems={attendanceDetails || []}
                                        onChange={(chosenId) => onStatusIdChange(chosenId)}
                                    />
                                ) : status?.name?.toLowerCase() === 'attended' ? (
                                    <section>
                                        <CheckCircleIcon className={classes.green} /> Attended
                                        Module
                                    </section>
                                ) : status?.name?.toLowerCase() === 'failed to attend' ? (
                                    <section>
                                        <CancelIcon className={classes.red} /> Failed To Attend
                                    </section>
                                ) : status?.name?.toLowerCase() === 'cancelled – adviser' ? (
                                    <section>
                                        <CancelIcon className={classes.red} /> Adviser Cancelled
                                    </section>
                                ) : status?.name?.toLowerCase() === 'cancelled – participant' ? (
                                    <section>
                                        <CancelIcon className={classes.red} /> Participant Cancelled
                                    </section>
                                ) : (
                                    <section>
                                        <WarningIcon className={classes.yellow} /> Status Not Set
                                    </section>
                                )
                            ) : (
                                ''
                            )}
                        </span>

                        <div className={attendanceClasses.cardAttendanceView}>
                            <span className={attendanceClasses.spaces}>
                                <WorkspacesIcon />
                                {participants &&
                                    ` ${participants.length} of ${
                                        capacity ? capacity : ''
                                    } spaces filled`}
                                {!participants && ` 0 spaces filled`}
                            </span>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

ParticipantAttendanceStatusView.propTypes = {
    module: PropTypes.object.isRequired,
    attendanceDetails: PropTypes.array,
    currentParticipant: PropTypes.object,
    information: PropTypes.string,
    onModuleChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default ParticipantAttendanceStatusView;
