import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../../store/participantService';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';

import classes from '../../../commonStyles/tables.module.css';

const CallsDueTableRow = ({ row, roles, show }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL_STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [loaded, setLoaded] = useState(false);
    const [showCell, setShowCell] = useState(false);

    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    useEffect(() => {
        setShowCell(show);
    }, [show]);

    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && loaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    const onNav = () => {
        dispatch(loadParticipantByPtCode(row.ptCode));
        setLoaded(true);
    };

    return (
        <>
            <TableRow key={row.id} hover className={`${classes.basicRowStripes}`}>
                <TableCell
                    className={classes.rowLink}
                    disabled={!hasRole(roles, acceptedRoles)}
                    onClick={onNav}
                    style={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell className={!showCell ? classes.hideCell : ''}>
                    {row?.firstName}
                </TableCell>
                <TableCell className={!showCell ? classes.hideCell : ''}>{row?.lastName}</TableCell>
                <TableCell>{row?.adviser}</TableCell>
            </TableRow>
        </>
    );
};

export default CallsDueTableRow;

CallsDueTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    show: PropTypes.bool.isRequired
};
