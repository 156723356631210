import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    selectAssetRequestAsset,
    selectAssetRequestStatus,
    selectAssetRequestType
} from '../../../../store/dataSelectors';
import {
    loadAssetRequestAssetDetails,
    loadAssetRequestRequestTypeDetails,
    loadAssetRequestStatusDetails
} from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectParticipantAssetRequests
} from '../../../../store/participantSelectors';
import { loadAssetRequests } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import AssetRequestTable from './AssetRequestTable';
import CreateAssetRequest from './CreateAssetRequest';
import EditAssetRequest from './EditAssetRequest';

import classes from './assetRequest.module.css';

const AssetRequest = () => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const participantAssetRequests = useSelector(selectParticipantAssetRequests);
    const assetRequestStatusDetails = useSelector(selectAssetRequestStatus);
    const assetRequestRequest = useSelector(selectAssetRequestType);
    const assetRequestAsset = useSelector(selectAssetRequestAsset);

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        if (!currentParticipant?.id) return;

        dispatch(loadAssetRequests(currentParticipant.id));
        dispatch(loadAssetRequestStatusDetails());
        dispatch(loadAssetRequestRequestTypeDetails());
        dispatch(loadAssetRequestAssetDetails());
    }, [currentParticipant]);

    useEffect(() => {
        if (
            !participantAssetRequests?.length ||
            !assetRequestStatusDetails?.length ||
            !assetRequestRequest?.length ||
            !assetRequestAsset.length
        )
            return;
        const rows = participantAssetRequests.map((el) => ({
            ...el,
            asset: assetRequestAsset.find((entry) => entry.id === el.assetId)?.name || el.asset,
            status: assetRequestStatusDetails.find((entry) => entry.id === el.statusId)?.name || ''
        }));

        setRows(rows);
    }, [
        participantAssetRequests,
        assetRequestStatusDetails,
        assetRequestRequest,
        assetRequestAsset
    ]);

    // EVENT HANDLERS
    const onCreateAssetRequest = () => {
        setFormType('create');
        setShowForm(true);
    };

    const onEditAssetRequest = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
    };

    const rest = () => (
        <div>
            {!showForm && (
                <Button
                    disabled={
                        !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles)
                    }
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onCreateAssetRequest}>
                    Add Asset Request
                    <Box width={SCREEN_SETTINGS.gap.medium} />
                    <AddCircleOutlineIcon />
                </Button>
            )}
            {showForm && formType === 'create' && (
                <CreateAssetRequest onClose={() => setShowForm(false)} roles={roles} />
            )}
            {showForm && formType === 'edit' && (
                <EditAssetRequest
                    onClose={() => setShowForm(false)}
                    roles={roles}
                    row={selectedRow}
                />
            )}
        </div>
    );

    return (
        <div className={classes.assetRequests}>
            <h3>Asset Request Details</h3>
            <div className={classes.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No asset requests found" />
                ) : (
                    <AssetRequestTable rows={rows} roles={roles} onEdit={onEditAssetRequest} />
                )}
            </div>
            {rest()}
        </div>
    );
};

export default AssetRequest;
