import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { arrayDif, compareUnorderedArrays, getUniqueElements } from '../../../utils/arrayUtils';
import { trimEntries } from '../../../utils/objectUtils';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';

import form from '../../../commonStyles/formStyles.module.css';
import local from './editParticipantStatuses.module.css';

const EditParticipantStatuses = ({
    status,
    toStatuses,
    onStatusChanged,
    participantStatusReasonDetails,
    error
}) => {
    const [selectedToStatusIds, setSelectedToStatusIds] = useState([]);
    const [selectedToStatuses, setSelectedToStatuses] = useState([]);

    useEffect(() => {
        const toStatusDDLSelectedIds = status.toParticipantStatuses.map(
            (el) => el.participantStatusId
        );
        setSelectedToStatusIds(toStatusDDLSelectedIds);
        setSelectedToStatuses(toStatuses.filter((el) => toStatusDDLSelectedIds.includes(el.id)));
    }, [status.toParticipantStatuses, toStatuses]);

    const onToStatusesChanged = (chosenIds) => {
        if (getUniqueElements(chosenIds, selectedToStatusIds).length === 0) return;
        const idsToRemove = arrayDif(selectedToStatusIds, chosenIds);
        let tempStatuses = status.toParticipantStatuses.filter(
            (el) => !idsToRemove.includes(el.participantStatusId)
        );
        arrayDif(chosenIds, selectedToStatusIds).forEach((id) => {
            tempStatuses = [
                ...tempStatuses,
                {
                    participantStatusId: id,
                    selectableBy: 'bm_and_up',
                    participantStatusReasonIds: []
                }
            ];
        });
        const trimmedToParticipantStatusesErrors = trimEntries(
            idsToRemove,
            error.toParticipantStatuses
        );
        const isValid = !Object.values(trimmedToParticipantStatusesErrors).some(
            (value) => typeof value === 'object' && value !== null && value.isValid === false
        );
        const newError = {
            ...error,
            toParticipantStatuses: {
                ...trimEntries(idsToRemove, error.toParticipantStatuses),
                error: false,
                message: '',
                isValid: isValid
            },
            isValid: isValid
        };
        onStatusChanged({ ...status, toParticipantStatuses: tempStatuses }, newError);
    };

    const onToStatusChanged = (toStatus) => {
        if (
            compareUnorderedArrays(
                toStatus.participantStatusReasonIds,
                status.toParticipantStatuses.find(
                    (el) => el.participantStatusId === toStatus.participantStatusId
                ).participantStatusReasonIds
            )
        )
            return;
        const indexOfToStatus = status.toParticipantStatuses.findIndex(
            (el) => el.participantStatusId === toStatus.participantStatusId
        );
        const newError = {
            ...error,
            toParticipantStatuses: {
                ...error.toParticipantStatuses,
                [toStatus.participantStatusId]: {
                    isValid: true,
                    participantStatusReasonIds: { error: false, message: '' }
                }
            }
        };
        newError.toParticipantStatuses.isValid =
            !Object.values(newError.toParticipantStatuses).some(
                (value) => typeof value === 'object' && value !== null && value.isValid === false
            ) && newError.toParticipantStatuses.error === false;
        newError.isValid = newError.toParticipantStatuses.isValid;
        onStatusChanged(
            {
                ...status,
                toParticipantStatuses: [
                    ...status.toParticipantStatuses.slice(0, indexOfToStatus),
                    toStatus,
                    ...status.toParticipantStatuses.slice(indexOfToStatus + 1)
                ]
            },
            newError
        );
    };

    const onChangeRole = (toStatus) => {
        const indexOfToStatus = status.toParticipantStatuses.findIndex(
            (el) => el.participantStatusId === toStatus.participantStatusId
        );
        onStatusChanged(
            {
                ...status,
                toParticipantStatuses: [
                    ...status.toParticipantStatuses.slice(0, indexOfToStatus),
                    toStatus,
                    ...status.toParticipantStatuses.slice(indexOfToStatus + 1)
                ]
            },
            error
        );
    };

    return (
        <div className={form.formWrapper}>
            <div className={form.leftColOnly}>
                <MultiSelect
                    id={`toStatuses-${status.participantStatusId}`}
                    key={`toStatuses-${status.participantStatusId}`}
                    label="To Statuses"
                    placeholder="To Statuses"
                    mandatory={true}
                    maxPills={10}
                    menuItems={toStatuses || []}
                    preSelectedIds={selectedToStatusIds}
                    preSelects={selectedToStatuses}
                    error={error.toParticipantStatuses}
                    onChange={onToStatusesChanged}
                />
            </div>
            {status.toParticipantStatuses.map((toParticipantStatus) => {
                const id = `${status.participantStatusId}_${toParticipantStatus.participantStatusId}`;
                const toStatus = toStatuses.find(
                    (el) => el.id === toParticipantStatus.participantStatusId
                );

                if (!toStatus) return;
                return (
                    <div key={`toStatuses-${id}`} className={local.statusRow}>
                        <label
                            id={`label-${id}`}
                            htmlFor={id}
                            className={`${form.formLabel} ${local.statusLabel}`}>
                            {toStatus.name}
                        </label>
                        <div
                            id={id}
                            aria-labelledby={`label-${id}`}
                            className={local.toStatusesMultiSelect}>
                            <MultiSelect
                                id={`toStatusReasons-${id}`}
                                label="To Status Reasons"
                                placeholder="To Status Reasons"
                                mandatory={true}
                                maxPills={10}
                                blockingElementIds={[
                                    `selectableBy-${id}`,
                                    `toStatuses-${status.participantStatusId}`
                                ]}
                                menuItems={participantStatusReasonDetails}
                                preSelectedIds={toParticipantStatus.participantStatusReasonIds}
                                preSelects={participantStatusReasonDetails.filter((el) =>
                                    toParticipantStatus.participantStatusReasonIds.includes(el.id)
                                )}
                                error={
                                    error.toParticipantStatuses[
                                        toParticipantStatus.participantStatusId
                                    ]?.participantStatusReasonIds
                                }
                                onChange={(reasonsIds) =>
                                    onToStatusChanged({
                                        ...toParticipantStatus,
                                        participantStatusReasonIds: reasonsIds
                                    })
                                }
                            />
                        </div>
                        <div className={local.radioButtonGroup}>
                            <RadioButtons
                                id={`selectableBy-${id}`}
                                label="Selectable By"
                                radioButtons={[
                                    {
                                        id: `BM-${id}`,
                                        label: 'BM & up',
                                        onChangeVal: 'bm_and_up'
                                    },
                                    {
                                        id: `PRaP-${id}`,
                                        label: 'PRaP & up',
                                        onChangeVal: 'prap_and_up'
                                    },
                                    {
                                        id: `Quality-${id}`,
                                        label: 'Quality & up',
                                        onChangeVal: 'quality_and_up'
                                    }
                                ]}
                                value={toParticipantStatus.selectableBy}
                                onChange={(val) =>
                                    onChangeRole({
                                        ...toParticipantStatus,
                                        selectableBy: val
                                    })
                                }
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

EditParticipantStatuses.propTypes = {
    status: PropTypes.shape({
        id: PropTypes.string,
        participantStatusId: PropTypes.string,
        active: PropTypes.bool,
        toParticipantStatuses: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                participantStatusId: PropTypes.string,
                selectableByBmAndUp: PropTypes.bool,
                selectableByPrapAndUp: PropTypes.bool,
                selectableByQualityAndUp: PropTypes.bool,
                participantStatusReasonIds: PropTypes.arrayOf(PropTypes.string)
            })
        )
    }),
    toStatuses: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            status: PropTypes.string
        })
    ),
    onStatusChanged: PropTypes.func,
    participantStatusReasonDetails: PropTypes.array,
    error: PropTypes.object
};

export default EditParticipantStatuses;
