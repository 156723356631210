import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';

import {
    ADVISER,
    hasRole,
    LOCAL_ADMIN,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import DataSets from '../dataSets/DataSets';
import PolarisLink from '../ui/PolarisLink';
import BatchUploader from '../uploaders/batchUploader/BatchUploader';
import ClaimStatusUploader from '../uploaders/claimStatusUploader/ClaimStatusUploader';
import NdppUploader from '../uploaders/ndppUploader/NdppUploader';

const JourneyMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showBatchUploader, setShowBatchUploader] = useState(false);
    const [showNdppUploader, setShowNdppUploader] = useState(false);
    const [showClaimStatusUploader, setShowClaimStatusUploader] = useState(false);
    const [showDataSets, setShowDataSets] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const handleClickListItem = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                navigate('/recruitment_management');
                break;
            case 1:
                navigate('/course_management');
                break;
            case 2:
                navigate('/create_course_template', { state: { accordionPanel: 'templates' } });
                break;
            case 3:
                navigate('/create_provision', { state: { accordionPanel: 'provisions' } });
                break;
            case 4:
                navigate('/claims');
                break;
            case 5:
                setShowDataSets(true);
                break;
            case 6:
                setShowClaimStatusUploader(true);
                break;
            case 7:
                setShowBatchUploader(true);
                break;
            case 8:
                setShowNdppUploader(true);
                break;
            case 9:
                navigate('/tbc', {
                    state: { text: 'PPL-198 Website Uploader is not available yet' }
                });
                break;
            default:
                break;
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <PolarisLink label={'Journey'} onClick={handleClickListItem} />

            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}>
                <MenuItem key={0} onClick={() => handleMenuItemClick(0)}>
                    Recruitment Management
                </MenuItem>
                <MenuItem key={1} onClick={() => handleMenuItemClick(1)}>
                    Course Management
                </MenuItem>
                <MenuItem
                    key={2}
                    onClick={() => handleMenuItemClick(2)}
                    disabled={!hasRole([QUALITY, SUPERUSER, LOCAL_ADMIN], roles)}>
                    Create Course Template
                </MenuItem>
                <MenuItem key={3} onClick={() => handleMenuItemClick(3)}>
                    Create Provision
                </MenuItem>
                <MenuItem
                    key={4}
                    onClick={() => handleMenuItemClick(4)}
                    disabled={!hasRole([PRAP, QUALITY, SUPERUSER], roles)}>
                    Claim Queue
                </MenuItem>
                <MenuItem
                    key={5}
                    onClick={() => handleMenuItemClick(5)}
                    disabled={
                        !hasRole(
                            [ADVISER, MANAGER, PRAP, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                            roles
                        )
                    }>
                    Data Sets
                </MenuItem>
                <MenuItem
                    key={6}
                    onClick={() => handleMenuItemClick(6)}
                    disabled={!hasRole([PRAP, QUALITY, SUPERUSER], roles)}>
                    Claim Status Uploader
                </MenuItem>
                <MenuItem
                    key={7}
                    onClick={() => handleMenuItemClick(7)}
                    disabled={!hasRole([PRAP, QUALITY, SUPERUSER], roles)}>
                    Batch Uploader
                </MenuItem>
                <MenuItem
                    key={8}
                    onClick={() => handleMenuItemClick(8)}
                    disabled={
                        !hasRole(
                            [
                                ADVISER,
                                LOCAL_ADMIN,
                                MANAGER,
                                PRAP,
                                QUALITY,
                                RECRUITMENT_MANAGER,
                                SUPERUSER
                            ],
                            roles
                        )
                    }>
                    NDPP Uploader
                </MenuItem>
                <MenuItem
                    key={9}
                    onClick={() => handleMenuItemClick(9)}
                    disabled={
                        !hasRole([ADVISER, PRAP, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles)
                    }>
                    Website Uploader
                </MenuItem>
            </Menu>

            <div>
                {showDataSets && (
                    <DataSets isOpen={showDataSets} onClose={() => setShowDataSets(false)} />
                )}
                {showBatchUploader && (
                    <BatchUploader
                        isOpen={showBatchUploader}
                        onClose={() => setShowBatchUploader(false)}
                    />
                )}
                {showClaimStatusUploader && (
                    <ClaimStatusUploader
                        isOpen={showClaimStatusUploader}
                        onClose={() => setShowClaimStatusUploader(false)}
                    />
                )}
                {showNdppUploader && (
                    <NdppUploader
                        isOpen={showNdppUploader}
                        onClose={() => setShowNdppUploader(false)}
                    />
                )}
            </div>
        </div>
    );
};
export default JourneyMenu;
