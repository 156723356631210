import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import { loadCallOutcomes, loadWorkConfidence } from '../../../../../store/directusService';
import {
    loadContractConstants,
    updateIWSRecordAdhocCall
} from '../../../../../store/participantService';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';

import app from '../../../../../app.module.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import actions from '../../../../ui/formActions/formActions.module.css';

const NOTES_LIMIT = 750;

const CreateInWorkSupportCall = ({ businessName, currentCall, onClose, roles }) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        callOutcomeId: null,
        workConfidenceId: null,
        note: '',
        notesFromLastCall: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [notes, setNotes] = useState('');
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const dateFormat = 'dd/MM/yyyy';
    const today = new Date();
    const [callOutcomesWithoutDue, setCallOutcomesWithoutDue] = useState([]);

    const [isClearSelectedOutcome, setIsClearSelectedOutcome] = useState('0');
    const [isClearSelectedConfidence, setIsClearSelectedConfidence] = useState('1');

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { callOutcomes, workConfidence } = useSelector((state) => state.entities.directusService);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    // EVENT HANDLERS
    const handleOutcomeChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, callOutcomeId: chosenId }));
    };
    const handleConfidenceChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, workConfidenceId: chosenId }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, note: e.target.value }));
    };

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setNotes('');
        setIsClearSelectedOutcome(Math.random());
        setIsClearSelectedConfidence(Math.random());
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadContractConstants(currentParticipant.contractId));
        dispatch(loadCallOutcomes());
        dispatch(loadWorkConfidence());
        if (currentCall) setNewEntry(currentCall);
    }, []);

    useEffect(() => {
        if (callOutcomes?.length < 1) return;

        setCallOutcomesWithoutDue(callOutcomes.filter((el) => el.name !== 'Due'));
    }, [callOutcomes]);

    useEffect(() => {
        if (successMessage === `IWS Record for ${businessName} has been updated`) {
            onFormExit();
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onFormExit = () => {
        clearForm();
        clearErrors();
        onClose();
    };

    const onSubmit = () => {
        dispatch(updateIWSRecordAdhocCall(newEntry, businessName));
    };

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form className={form.form} onSubmit={handleSubmit(onSubmit)}>
                <h2 className={app.sectionHeading}>{`Complete IWS Call: Week ${
                    currentCall.frequencyWeekNo || ''
                }`}</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Call Outcome'}
                            id={'outcomeId'}
                            key={isClearSelectedOutcome}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={callOutcomesWithoutDue || []}
                            chosenName={getNameFromId(callOutcomes, newEntry.outcomeId)}
                            chosenId={newEntry.outcomeId}
                            error={errors.outcomeId}
                            {...register('outcomeId')}
                            onChange={(chosenId) => handleOutcomeChange(chosenId)}
                        />
                        <LabelledTextField
                            label={'Date Of Call'}
                            id={'dateRequested'}
                            disabled={true}
                            mandatory={true}
                            value={format(today, dateFormat)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Work Confidence'}
                            id={'confidenceId'}
                            key={isClearSelectedConfidence}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={workConfidence || []}
                            error={errors.confidenceId}
                            {...register('confidenceId')}
                            onChange={(chosenId) => handleConfidenceChange(chosenId)}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Notes'}
                            id={'note'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            multiline
                            rows={5}
                            value={notes || ''}
                            placeholder={'Enter In Work Support Call notes'}
                            counter={'true'}
                            helperText={
                                notes ? `${notes.length}/${NOTES_LIMIT}` : `0/${NOTES_LIMIT}`
                            }
                            inputProps={{ maxLength: NOTES_LIMIT }}
                            error={errors.notes}
                            {...register('notes')}
                            onChange={(e) => handleNotesChange(e)}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Notes From Last Call'}
                            id={'notesFromLastCall'}
                            disabled={true}
                            multiline
                            rows={5}
                            value={newEntry.notesFromLastCall}
                        />
                    </div>
                </div>
                <div className={actions.formActions}>
                    <Button type="submit" color="primary" variant="contained">
                        Add In Work Support Call
                    </Button>
                    <div className={actions.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    outcomeId: Yup.string().required('Please select an outcome'),
    confidenceId: Yup.string().required('Please select a work confidence'),
    notes: Yup.string().min(10, 'Notes must be at least ten characters')
});

export default CreateInWorkSupportCall;

CreateInWorkSupportCall.propTypes = {
    businessName: PropTypes.string,
    currentCall: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string
};
