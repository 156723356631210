import React, { useEffect, useRef } from 'react';
import { jsPDF } from 'jspdf';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AddDocument from '../documents/AddDocument';

import classes from '../../commonStyles/pdf.module.css';

const GenerateAppointmentPDF = ({
    children,
    onSavePDF,
    onCancelPDF,
    fileName,
    appointmentType,
    appointmentLocationId,
    attendanceStatusId,
    exitFormTemplateId,
    complianceActivityFormQuestionnaireId,
    isLoaded
}) => {
    const navigate = useNavigate();

    const contentTemplateContainerTemplateRef = useRef(null);

    const onGeneratePdf = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.html(contentTemplateContainerTemplateRef.current, {
            async callback(doc) {
                doc.save(fileName);
            },
            autoPaging: 'text',
            margin: [10, 0, 10, 0],
            width: 210,
            windowWidth: 1050
        });
    };

    useEffect(() => {
        if (!contentTemplateContainerTemplateRef?.current) return;
        setTimeout(() => onGeneratePdf(), 3000);
    }, [fileName, isLoaded]);

    const onExit = () => {
        onSavePDF();
        navigate('/edit_participant', {
            state: {
                attendanceStatusId,
                appointmentTypeName: appointmentType.name,
                appointmentLocationId: appointmentLocationId,
                exitFormTemplateId,
                complianceActivityFormQuestionnaireId
            }
        });
    };

    return (
        <>
            <div
                id="contentTemplateContainer"
                className={classes.pdfWrapper}
                ref={contentTemplateContainerTemplateRef}>
                {children}
            </div>

            <div className={classes.uploaderWrapper}>
                <AddDocument
                    onClose={onExit}
                    onCancel={onCancelPDF}
                    btnText="Complete"
                    currentDocumentType={appointmentType}
                    hideCancel={true}
                />
            </div>
        </>
    );
};
GenerateAppointmentPDF.propTypes = {
    children: PropTypes.element,
    onSavePDF: PropTypes.func,
    onCancelPDF: PropTypes.func,
    fileName: PropTypes.string,
    appointmentType: PropTypes.object,
    appointmentLocationId: PropTypes.string,
    attendanceStatusId: PropTypes.string,
    exitFormTemplateId: PropTypes.string,
    complianceActivityFormQuestionnaireId: PropTypes.string,
    isLoaded: PropTypes.bool
};
export default GenerateAppointmentPDF;
