import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';

import { hasRole, SUPERUSER } from '../../utils/userRoles';
import PolarisLink from '../ui/PolarisLink';

const DashboardMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const acceptedRoles = [SUPERUSER];

    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    const handleClickListItem = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                navigate('/tbc', {
                    state: { text: 'PPL-441 Adviser Dashboard is not available yet' }
                });
                break;
            case 1:
                navigate('/tbc', {
                    state: { text: 'PPL-??? Business Manager Dashboard is not available yet' }
                });
                break;
            case 2:
                navigate('/tbc', {
                    state: { text: 'PPL-??? Finance Dashboard is not available yet' }
                });
                break;
            case 3:
                navigate('/tbc', {
                    state: { text: 'PPL-??? PRaP Dashboard is not available yet' }
                });
                break;
            default:
                break;
        }

        setAnchorEl(null);
    };

    return (
        <div>
            <PolarisLink label={'Dashboard'} onClick={handleClickListItem} />

            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}>
                <MenuItem
                    key={0}
                    onClick={() => handleMenuItemClick(0)}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    Adviser
                </MenuItem>
                <MenuItem
                    key={1}
                    onClick={() => handleMenuItemClick(1)}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    Business Manager
                </MenuItem>
                <MenuItem
                    key={2}
                    onClick={() => handleMenuItemClick(2)}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    Finance
                </MenuItem>
                <MenuItem
                    key={3}
                    onClick={() => handleMenuItemClick(3)}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    PRaP
                </MenuItem>
            </Menu>
        </div>
    );
};
export default DashboardMenu;
