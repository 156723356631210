import PropTypes from 'prop-types';

/**
 * Use this for the sub header text on accordions.
 */
import Typography from '@mui/material/Typography';

import { SCREEN_SETTINGS } from '../../../themes/theme';

const AccordionSubHeader = ({ children }) => {
    return (
        <Typography variant={'h6'} sx={{ marginLeft: SCREEN_SETTINGS.gap.huge, flexGrow: 1 }}>
            {children}
        </Typography>
    );
};
AccordionSubHeader.propTypes = {
    children: PropTypes.any
};

export default AccordionSubHeader;
