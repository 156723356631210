import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/Info';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import {
    loadAttendanceDetails,
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { loadParticipant } from '../../../store/participantService';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import { isTimePast } from '../../../utils/dateFunctions';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import table from '../../../commonStyles/tables.module.css';
import classes from '../courseStyles/fullCourseAttendanceStyles.module.css';

const FullCourseAttendanceRow = ({ row, module, hideInfo, onUpdateParticipantDetail }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [attendanceStatus, setAttendanceStatus] = useState({});
    const [disabled, setDisabled] = useState(false);

    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );

    useEffect(() => {
        setAttendanceStatus({});
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (!module) return;

        const dateTime = `${module?.date}T${module?.startTime}`;
        if (!isTimePast(dateTime)) setDisabled(true);
    }, []);

    useEffect(() => {
        if (attendanceDetails?.length > 0) {
            const participantStatus = attendanceDetails.find(
                (el) =>
                    el.id ===
                    module?.participants?.find((entry) => entry.participantId === row.id)?.statusId
            );
            setAttendanceStatus(participantStatus);
            if (participantStatus?.name !== 'Not known') setDisabled(true);
        }
    }, [attendanceDetails]);

    const onStatusIdChange = (id) => {
        if (attendanceStatus?.id && attendanceStatus.id !== id)
            onUpdateParticipantDetail(id, row.id);
        const selected = attendanceDetails.find((el) => el.id === id);
        selected && setAttendanceStatus(selected);
    };

    const renderAttendanceDDL = () => {
        return (
            <div className={classes.rowOptionPicker}>
                <DDLOptionPicker
                    key={0}
                    id={'attendanceStatus'}
                    disabled={disabled}
                    menuItems={attendanceDetails || []}
                    chosenName={attendanceStatus?.name || ''}
                    chosenId={attendanceStatus?.id || ''}
                    onChange={(chosenId) => onStatusIdChange(chosenId)}
                />
            </div>
        );
    };

    const onItemClick = (participantId, contractId) => {
        // Set current participant in Redux participant slice.
        dispatch(loadParticipant(participantId));
        dispatch(loadContractDetailsForContractId(contractId));
        dispatch(loadServiceDetailsForContractId(contractId));
        navigate('/edit_participant');
    };

    return (
        <>
            <TableRow hover className={table.basicRowStripes}>
                <TableCell
                    sx={{
                        width: 'auto',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        hideInfo && onItemClick(row.id, row.contractId);
                    }}>
                    {row.ptCode}
                </TableCell>
                {hideInfo && <TableCell> {row.firstName}</TableCell>}
                {hideInfo && <TableCell>{row.lastName}</TableCell>}
                {hideInfo && <TableCell sx={{ padding: '0' }}>{renderAttendanceDDL()}</TableCell>}
                {hideInfo && row.notes ? (
                    <TableCell>
                        <Tooltip title={row.notes}>
                            <InfoIcon sx={{ color: POLARIS_ROYAL_BLUE }} width={20} height={20} />
                        </Tooltip>
                    </TableCell>
                ) : (
                    hideInfo && <TableCell />
                )}
            </TableRow>
        </>
    );
};

export default FullCourseAttendanceRow;

FullCourseAttendanceRow.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        ptCode: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        notes: PropTypes.string.isRequired
    }).isRequired,
    module: PropTypes.object,
    hideInfo: PropTypes.bool.isRequired,
    onUpdateParticipantDetail: PropTypes.func
};
