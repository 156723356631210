import React from 'react';

import local from './loadingTimeNotice.module.css';

const LoadingTimeNotice = () => (
    <div className={local.waitText}>
        Please NOTE, long uploading times expected. Users will not be able to exit screen while
        processing.
    </div>
);

export default LoadingTimeNotice;
