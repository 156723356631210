import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { selectDocumentTypes } from '../../../store/dataSelectors';
import {
    loadDocumentTypesForContractId,
    loadParticipantStatusDetails,
    loadParticipantStatusReasonDetails
} from '../../../store/directusService';
import { loadContractConstants, updateContractConstants } from '../../../store/participantService';
import { arrayDif, compareUnorderedArrays, getUniqueElements } from '../../../utils/arrayUtils';
import {
    getEmptyErrorState,
    isErrorsValid,
    validate
} from '../../../utils/formValidation/validator';
import { clearKeys, onlyEntries, trimEntries } from '../../../utils/objectUtils';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import Switch from '../../formElements/Switch';
import TextInputField from '../../formElements/TextInputField';
import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';
import LoadingSpinner from '../../ui/LoadingSpinner';

import EditParticipantStatuses from './EditParticipantStatuses';
import { participantStatusAdminValidationFields } from './ParticipantStatusAdminValidationFields';

import '../../../commonStyles/loading.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from './participantStatusAdminStyles.module.css';

const ParticipantStatusAdmin = ({ contractId }) => {
    const dispatch = useDispatch();
    const validationFields = { ...participantStatusAdminValidationFields };

    // LOCAL STATE
    const initialState = {
        id: '',
        contractId: '',
        activeStatusDocumentTypeIds: [],
        allowFirstCourseModuleToSetActive: false,
        allowCourseSubmissionOrDigitalToSetCourseBooked: false,
        daysWithoutInitialAppointmentUntilRecordClosure: '',
        setNoInitialClosureReason: '',
        requirePoNumber: true,
        deleteParticipantPiOnContractClosure: false,
        deleteParticipantPiOnContractClosureDays: null,
        deleteParticipantPiOnParticipantClosure: false,
        deleteParticipantPiOnParticipantClosureDays: null,
        uniqueIdRequiredOnCreation: true,
        documentTypes: [],
        closedAfter3XMissedCourseModules: false,
        participantStatuses: []
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [initialStateOnEntry, setInitialStateOnEntry] = useState(initialState);
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [isValid, setIsValid] = useState(true);
    const [preSelectedDocumentTypes, setPreSelectedDocumentTypes] = useState([]);
    const [preSelectedDocumentTypeIds, setPreSelectedDocumentTypeIds] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedStatusesIds, setSelectedStatusesIds] = useState([]);
    const [keys, setKeys] = useState({
        activeStatusDocumentTypeIds: '0',
        setNoInitialClosureReason: '1'
    });
    const [statusAccordionState, setStatusAccordionState] = useState({});
    const [toStatuses, setToStatuses] = useState([]);
    const [lockedStatuses, setLockedStatuses] = useState([]);
    const [awaitingUpdate, setAwaitingUpdate] = useState(true);

    // STORE STATE
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );
    const documentTypes = useSelector(selectDocumentTypes);
    const { participantStatusDetails, participantStatusReasonDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);

    // OTHER FNS
    const clearValues = () => {
        setAwaitingUpdate(true);
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
        setPreSelectedDocumentTypeIds([]);
        setPreSelectedDocumentTypes([]);
        setSelectedStatusesIds([]);
        setSelectedStatuses([]);
        setToStatuses([]);
        setLockedStatuses([]);
        setErrors(getEmptyErrorState(validationFields));
    };

    const contractSetup = () => {
        clearValues();
        dispatch(loadContractConstants(contractId));
        dispatch(loadDocumentTypesForContractId(contractId));
        dispatch(loadParticipantStatusDetails());
        setTimeout(() => {
            setAwaitingUpdate(false);
        }, 0);
    };

    const clearError = (key, tempNewEntry, newError = { error: false, message: '' }) => {
        const newErrors = { ...errors, [key]: newError };
        setIsValid(!Object.values(newErrors).some((el) => el.error || el.isValid === false));
        setErrors(newErrors);
    };

    // USE EFFECTS
    useEffect(() => {
        validationFields.button.checkDiff = initialStateOnEntry;
    }, [initialStateOnEntry]);

    useEffect(() => {
        if (
            successMessage === 'Contract constants has been updated' ||
            successMessage === `Contract constants has been added`
        )
            setInitialStateOnEntry(newEntry);
    }, [successMessage]);

    useEffect(() => {
        if (participantStatusReasonDetails?.length < 1)
            dispatch(loadParticipantStatusReasonDetails());
    }, []);

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (documentTypes.length < 1 || Object.keys(contractConstants).length < 1) return;
        setPreSelectedDocumentTypeIds(contractConstants?.activeStatusDocumentTypeIds);
        const preSelectedDocumentTypesTemp = documentTypes.filter((el) =>
            contractConstants.activeStatusDocumentTypeIds?.includes(el.id)
        );
        setPreSelectedDocumentTypes(preSelectedDocumentTypesTemp);
        const tempNewEntry = {
            ...contractConstants,
            requirePoNumber:
                contractConstants.requirePoNumber === null
                    ? true
                    : contractConstants.requirePoNumber,
            uniqueIdRequiredOnCreation:
                contractConstants.uniqueIdRequiredOnCreation === null
                    ? true
                    : contractConstants.uniqueIdRequiredOnCreation,
            allowFirstCourseModuleToSetActive:
                contractConstants.allowFirstCourseModuleToSetActive ?? false,
            allowCourseSubmissionOrDigitalToSetCourseBooked:
                contractConstants.allowCourseSubmissionOrDigitalToSetCourseBooked ?? false,
            closedAfter3XMissedCourseModules:
                contractConstants.closedAfter3XMissedCourseModules ?? false,
            participantStatuses: contractConstants.participantStatuses
        };
        setNewEntry(tempNewEntry);
        setInitialStateOnEntry(tempNewEntry);
        setLockedStatuses(
            contractConstants.participantStatuses.map((el) => el.participantStatusId)
        );
        setErrors({
            ...errors,
            ...getEmptyErrorState(validationFields, tempNewEntry)
        });
    }, [documentTypes, contractConstants]);

    useEffect(() => {
        setSelectedStatusesIds(newEntry.participantStatuses.map((el) => el.participantStatusId));
        const newPreselectedStatuses = participantStatusDetails.filter((el) =>
            newEntry.participantStatuses.find((entry) => entry.participantStatusId === el.id)
        );

        setToStatuses(
            participantStatusDetails.filter((el) =>
                newPreselectedStatuses.find((entry) => entry.id === el.id)
            )
        );

        setSelectedStatuses(newPreselectedStatuses);
    }, [participantStatusDetails, newEntry.participantStatuses]);

    // EVENT HANDLERS
    const onStatusesChanged = (chosenIds) => {
        const selectedStatusIds = newEntry.participantStatuses.map((el) => el.participantStatusId);
        if (compareUnorderedArrays(selectedStatusIds, chosenIds)) return;
        if (getUniqueElements(chosenIds, selectedStatusIds).length === 0) return;
        const idsToRemove = arrayDif(selectedStatusIds, chosenIds);
        const tempNewEntry = {
            ...newEntry,
            participantStatuses: [
                ...newEntry.participantStatuses
                    .filter((el) => !idsToRemove.includes(el.participantStatusId))
                    .map((el) => ({
                        ...el,
                        toParticipantStatuses: el.toParticipantStatuses.filter(
                            (entry) => !idsToRemove.includes(entry.participantStatusId)
                        )
                    })),
                ...arrayDif(chosenIds, selectedStatusIds).map((el) => ({
                    participantStatusId: el,
                    active: true,
                    toParticipantStatuses: []
                }))
            ]
        };
        setToStatuses(participantStatusDetails.filter((el) => chosenIds.includes(el.id)));
        setNewEntry(tempNewEntry);

        if (!isValid) {
            setErrors({
                ...errors,
                participantStatuses: trimEntries(idsToRemove, errors.participantStatuses)
            });
            setIsValid(
                isErrorsValid({
                    ...errors,
                    participantStatuses: trimEntries(idsToRemove, errors.participantStatuses)
                })
            );
        } else {
            setErrors(getEmptyErrorState(validationFields, tempNewEntry));
        }
    };

    const onStatusChanged = (participantStatus, newError) => {
        const participantStatusId = participantStatus.participantStatusId;
        const tempParticipantStatuses = newEntry.participantStatuses.map((el) =>
            el.participantStatusId === participantStatusId ? participantStatus : el
        );

        const tempNewEntry = {
            ...newEntry,
            participantStatuses: tempParticipantStatuses
        };

        setNewEntry(tempNewEntry);
        const newParticipantStatusesError = {
            ...errors.participantStatuses,
            [participantStatus.participantStatusId]: newError
        };
        newParticipantStatusesError.isValid =
            !Object.values(newParticipantStatusesError).some(
                (value) => typeof value === 'object' && value !== null && value.isValid === false
            ) &&
            (newError.toParticipantStatuses?.error === false || newError.error === false);
        clearError('participantStatuses', tempNewEntry, newParticipantStatusesError);
    };

    const onUpdate = (key, value) => {
        setNewEntry({ ...newEntry, [key]: value });
        clearError(key, { ...newEntry, [key]: value });
    };

    const onUpdateMultipleFields = (entries) => {
        const newErrors = {
            ...errors,
            ...getEmptyErrorState(onlyEntries(Object.keys(entries), validationFields))
        };
        setNewEntry({ ...newEntry, ...entries });
        setErrors(newErrors);
        setIsValid(!Object.values(newErrors).some((el) => el.error));
    };

    const onUpdateParticipantClosure = (option) => {
        if (option) {
            onUpdate('deleteParticipantPiOnParticipantClosure', option);
        } else {
            onUpdateMultipleFields({
                deleteParticipantPiOnParticipantClosureDays: null,
                deleteParticipantPiOnParticipantClosure: option
            });
        }
    };

    const onUpdateContractClosure = (option) => {
        if (option) {
            onUpdate('deleteParticipantPiOnContractClosure', option);
        } else {
            onUpdateMultipleFields({
                deleteParticipantPiOnContractClosureDays: null,
                deleteParticipantPiOnContractClosure: option
            });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validation = validate(newEntry, validationFields);
        setErrors(validation.errors);
        setIsValid(validation.isValid);
        if (!validation.isValid) return;
        setInitialStateOnEntry(newEntry);
        const payload = { ...newEntry, contractId };
        dispatch(updateContractConstants(payload));
    };

    // RENDER
    const participantStatusesAccordions = () => {
        return newEntry.participantStatuses.map((el) => {
            const statusDetails = participantStatusDetails.find(
                (entry) => entry.id === el.participantStatusId
            );
            if (!statusDetails) return;
            const filteredToStatuses = toStatuses.filter(
                (entry) => entry.id !== el.participantStatusId
            );
            const accordionContent = [
                <AccordionSummary
                    key={`accordionSummary${statusDetails.id}`}
                    expandIcon={<AccordionIcon />}
                    aria-controls="panel3b-content"
                    id="panel3b-header">
                    <AccordionHeader isValid={errors.participantStatuses[statusDetails.id].isValid}>
                        Participant Status - {statusDetails.name}
                    </AccordionHeader>
                    <Switch
                        id={`isParticipantStatusActive-${statusDetails.id}`}
                        checked={el.active}
                        inline={true}
                        onOff={true}
                        onChange={(e) => {
                            onStatusChanged({
                                ...el,
                                active: e.target.checked
                            });
                        }}
                    />
                </AccordionSummary>,
                <AccordionDetails key={`accordionDetails${statusDetails.id}`}>
                    <EditParticipantStatuses
                        key={statusDetails.id}
                        status={{ ...el }}
                        toStatuses={filteredToStatuses}
                        onStatusChanged={onStatusChanged}
                        participantStatusReasonDetails={participantStatusReasonDetails}
                        error={errors.participantStatuses[statusDetails.id]}
                    />
                </AccordionDetails>
            ];
            if (errors.participantStatuses[statusDetails.id].isValid === false) {
                if (!Object.hasOwn(statusAccordionState, el.participantStatusId))
                    setStatusAccordionState({
                        ...statusAccordionState,
                        [el.participantStatusId]: true
                    });
                return (
                    <Accordion
                        key={`accordionControlled${el.participantStatusId}`}
                        slotProps={{ transition: { mountOnEnter: true, unmountOnExit: true } }}
                        expanded={true}>
                        {accordionContent}
                    </Accordion>
                );
            }
            return (
                <Accordion
                    key={`accordionUncontrolled${el.participantStatusId}`}
                    slotProps={{ transition: { mountOnEnter: true, unmountOnExit: true } }}
                    defaultExpanded={!!statusAccordionState[el.participantStatusId]}>
                    {accordionContent}
                </Accordion>
            );
        });
    };

    if (!contractId) return <LoadingSpinner content={'No Contract Id'} time={0} />;
    if (awaitingUpdate) return <LoadingSpinner />;

    return (
        <div>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="activeStatusDocumentTypeIds"
                            key={keys.activeStatusDocumentTypeIds}
                            label="Set Active Status Document Types"
                            placeholder="Document Types"
                            menuItems={documentTypes || []}
                            preSelectedIds={preSelectedDocumentTypeIds}
                            preSelects={preSelectedDocumentTypes}
                            onChange={(chosenIds) =>
                                onUpdate('activeStatusDocumentTypeIds', chosenIds)
                            }
                        />

                        <RadioButtons
                            id="allowFirstCourseModuleToSetActive"
                            label="Allow First Course Module To Set Active"
                            disabled={!hasRole([SUPERUSER], roles)}
                            mandatory={true}
                            value={newEntry.allowFirstCourseModuleToSetActive}
                            onChange={(option) =>
                                onUpdate('allowFirstCourseModuleToSetActive', option)
                            }
                        />

                        <RadioButtons
                            id="allowCourseSubmissionOrDigitalToSetCourseBooked"
                            label="Allow Course Submission Or Digital To Set Course Booked"
                            disabled={!hasRole([SUPERUSER], roles)}
                            mandatory={true}
                            value={newEntry.allowCourseSubmissionOrDigitalToSetCourseBooked}
                            onChange={(option) =>
                                onUpdate('allowCourseSubmissionOrDigitalToSetCourseBooked', option)
                            }
                        />

                        <TextInputField
                            id={'daysWithoutInitialAppointmentUntilRecordClosure'}
                            label={'Days without Initial Appointment until Record Closure'}
                            placeholder={'Enter number of days'}
                            mandatory={true}
                            value={newEntry.daysWithoutInitialAppointmentUntilRecordClosure || ''}
                            error={errors.daysWithoutInitialAppointmentUntilRecordClosure}
                            onChange={(e) =>
                                onUpdate(
                                    'daysWithoutInitialAppointmentUntilRecordClosure',
                                    e.target.value.replace(/[^\d]/g, '').slice(0, 3)
                                )
                            }
                        />

                        <SingleSelect
                            id={'setNoInitialClosureReason'}
                            key={keys.setNoInitialClosureReason}
                            label={'Set No Initial Closure Reason'}
                            placeholder="Search reasons..."
                            disabled={!hasRole([SUPERUSER], roles)}
                            menuItems={participantStatusReasonDetails || []}
                            selectedId={newEntry.setNoInitialClosureReason || ''}
                            selected={
                                participantStatusReasonDetails.find(
                                    (el) => el.id === newEntry.setNoInitialClosureReason
                                ) || {}
                            }
                            onChange={(chosenId) => onUpdate('setNoInitialClosureReason', chosenId)}
                        />

                        <MultiSelect
                            id="participantStatuses"
                            label="Participant Statuses"
                            placeholder="Participant Statuses"
                            mandatory={true}
                            nonEditableIds={lockedStatuses}
                            menuItems={participantStatusDetails || []}
                            preSelectedIds={selectedStatusesIds}
                            preSelects={selectedStatuses}
                            error={errors.participantStatuses}
                            onChange={onStatusesChanged}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <RadioButtons
                            id="requirePoNumber"
                            label="Requires PO Number to be set to Registered"
                            mandatory={true}
                            value={newEntry.requirePoNumber}
                            onChange={(option) => onUpdate('requirePoNumber', option)}
                        />

                        <RadioButtons
                            id="deleteParticipantPiOnParticipantClosure"
                            label="Delete Participant PI on Participant Closure"
                            value={!!newEntry.deleteParticipantPiOnParticipantClosure}
                            onChange={onUpdateParticipantClosure}
                        />

                        <TextInputField
                            id="deleteParticipantPiOnParticipantClosureDays"
                            label={"If 'Yes', how many Days after?"}
                            placeholder={'Enter number of days'}
                            mandatory={newEntry.deleteParticipantPiOnParticipantClosure}
                            disabled={!newEntry.deleteParticipantPiOnParticipantClosure}
                            value={newEntry.deleteParticipantPiOnParticipantClosureDays || ''}
                            error={errors.deleteParticipantPiOnParticipantClosureDays}
                            onChange={(e) =>
                                onUpdate(
                                    'deleteParticipantPiOnParticipantClosureDays',
                                    e.target.value.replace(/[^\d]/g, '').slice(0, 3)
                                )
                            }
                        />

                        <RadioButtons
                            id="deleteParticipantPiOnContractClosure"
                            label="Delete Participant PI on Contract Closure"
                            value={!!newEntry.deleteParticipantPiOnContractClosure}
                            onChange={onUpdateContractClosure}
                        />

                        <TextInputField
                            id="deleteParticipantPiOnContractClosureDays"
                            label={"If 'Yes', how many Days after?"}
                            placeholder={'Enter number of days'}
                            mandatory={newEntry.deleteParticipantPiOnContractClosure}
                            disabled={!newEntry.deleteParticipantPiOnContractClosure}
                            value={newEntry.deleteParticipantPiOnContractClosureDays || ''}
                            error={errors.deleteParticipantPiOnContractClosureDays}
                            onChange={(e) =>
                                onUpdate(
                                    'deleteParticipantPiOnContractClosureDays',
                                    e.target.value.replace(/[^\d]/g, '').slice(0, 3)
                                )
                            }
                        />

                        <RadioButtons
                            id="uniqueIdRequiredOnCreation"
                            label="Unique ID Required on Creation?"
                            mandatory={true}
                            value={newEntry.uniqueIdRequiredOnCreation}
                            onChange={(option) => onUpdate('uniqueIdRequiredOnCreation', option)}
                        />

                        <RadioButtons
                            id="closedAfter3XMissedCourseModules"
                            label="Set Closed After 3x Missed Course Modules"
                            value={newEntry.closedAfter3XMissedCourseModules}
                            onChange={(option) =>
                                onUpdate('closedAfter3XMissedCourseModules', option)
                            }
                        />
                    </div>
                </div>
                <div className={local.accordions}>{participantStatusesAccordions()}</div>

                <Button
                    id="updateParticipantStatusButton"
                    content="UPDATE PARTICIPANT STATUS"
                    disabled={!isValid}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </div>
    );
};

ParticipantStatusAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ParticipantStatusAdmin;
