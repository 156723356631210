import React from 'react';
import PropTypes from 'prop-types';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Tooltip } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

import classes from './iconStyles.module.css';

const OnFileDownloadIcon = ({ roles, acceptedRoles, onDownload, active = true }) => {
    acceptedRoles = acceptedRoles?.length > 0 ? acceptedRoles : [];
    if (hasRole(roles, acceptedRoles) && active) {
        return (
            <div className={classes.iconLink} onClick={onDownload}>
                <Tooltip title="Download" placement="top">
                    <FileDownloadIcon
                        sx={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}
                        aria-label="file-download-icon"
                        data-testid="file-download-icon"
                    />
                </Tooltip>
            </div>
        );
    }
    return (
        <div className={classes.iconLink}>
            <FileDownloadIcon
                sx={{ color: POLARIS_DARKER_GREY }}
                data-testid="file-download-icon"
                aria-label="file-download-icon"></FileDownloadIcon>
        </div>
    );
};

export default OnFileDownloadIcon;

OnFileDownloadIcon.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.bool,
    onDownload: PropTypes.func,
    participantId: PropTypes.string,
    filename: PropTypes.string
};
