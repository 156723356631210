import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectSmokingCoResults } from '../../../../store/dataSelectors';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import {
    createSmokingQuitAttemptReview,
    updateSmokingQuitAttemptReview
} from '../../../../store/participantService';
import { formatDateToHyphens } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../ui/pickers/NoYesRadioPicker';

import { checkDate, initialErrorState, validate } from './validateReview';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateQuitReview = ({
    onClose,
    roles,
    smokingQuitAttemptId,
    quitDate,
    type,
    quitAttemptReview = {}
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        smokingQuitAttemptId: '',
        coResultId: '',
        coValidation: true,
        eventDate: new Date().toISOString().slice(0, 10),
        inactive: false,
        interventionTypeId: '',
        quitReviewSuccessful: true,
        selfReportedQuitId: '',
        coResult: '',
        intervention: '',
        selfReportedQuit: '',
        arraySelfReportedQuitLength: 0
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isClearInterventionType, setIsClearInterventionType] = useState('0');
    const [arraySelfReportedQuits, setArraySelfReportedQuits] = useState([]);
    const [isClearSelfReportedQuit, setIsClearSelfReportedQuit] = useState('1');
    const [isClearCoResultId, setIsClearCoResultId] = useState('2');
    const msg = `Participant smoking quit attempt review has been ${
        type === 'create' ? 'created' : 'updated'
    }`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const interventionTypes = useSelector(
        (state) => state.entities.directusService.interventionTypes
    );
    const selfReportedQuits = useSelector(
        (state) => state.entities.directusService.smokingSelfReportedQuits
    );
    const coResults = useSelector(selectSmokingCoResults);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setIsClearInterventionType(Math.random());
        setIsClearSelfReportedQuit(Math.random());
        setArraySelfReportedQuits([]);
        setIsClearCoResultId(Math.random());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const configArraySelfReportedQuits = (id) => {
        const arr = selfReportedQuits.filter(
            (el) =>
                el.intervention_type?.filter((entry) => entry.intervention_type_id?.id === id)
                    .length > 0
        );
        setArraySelfReportedQuits(arr);
        setNewEntry((prev) => ({
            ...prev,
            interventionTypeId: id,
            selfReportedQuitId: '',
            selfReportedQuit: '',
            arraySelfReportedQuitLength: arr.length
        }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (type === 'create') {
            setNewEntry((prev) => ({
                ...prev,
                smokingQuitAttemptId,
                attemptQuitDate: quitDate
            }));
        }
        if (type === 'edit' && Object.keys(quitAttemptReview).length > 0) {
            const { eventDate, ...rest } = quitAttemptReview;
            configArraySelfReportedQuits(quitAttemptReview.interventionTypeId);
            setNewEntry((prev) => ({
                ...prev,
                smokingQuitAttemptId,
                eventDate: formatDateToHyphens(eventDate),
                attemptQuitDate: quitDate,
                ...rest
            }));
        }
    }, []);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onDateChange = (date) => {
        clearError('eventDate');
        setNewEntry((prev) => ({ ...prev, eventDate: date }));
        const newErrors = checkDate(date, quitDate, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const {
            /* eslint-disable no-unused-vars */
            coResult,
            intervention,
            selfReportedQuit,
            arraySelfReportedQuitLength,
            smokingQuitAttemptId,
            ...rest
        } = newEntry;
        const payload = { ...rest, participantId: currentParticipant?.id };

        payload.id
            ? dispatch(updateSmokingQuitAttemptReview(smokingQuitAttemptId, payload, msg))
            : dispatch(createSmokingQuitAttemptReview(smokingQuitAttemptId, payload, msg));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.innerWrapper}>
            <h3>{type === 'create' ? 'Add a Quit Review' : 'Edit Quit Review'}</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.baseMargin}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>

                        <DDLOptionPicker
                            label={'Intervention Type'}
                            id={'interventionTypeId'}
                            key={isClearInterventionType}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={interventionTypes || []}
                            chosenName={newEntry.intervention}
                            chosenId={newEntry.interventionTypeId}
                            onChange={(chosenId) => {
                                clearError('interventionTypeId');
                                configArraySelfReportedQuits(chosenId);
                            }}></DDLOptionPicker>
                        {errors.interventionTypeId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.interventionTypeId} />
                            </div>
                        )}

                        <DDLOptionPicker
                            label={'Self Reported Quit'}
                            id={'selfReportedQuitId'}
                            key={isClearSelfReportedQuit}
                            mandatory={arraySelfReportedQuits.length > 0}
                            disabled={
                                !hasRole(acceptedRoles, roles) || !arraySelfReportedQuits.length
                            }
                            menuItems={arraySelfReportedQuits || []}
                            chosenName={newEntry.selfReportedQuit}
                            chosenId={newEntry.selfReportedQuitId}
                            onChange={(chosenId) => {
                                clearError('selfReportedQuitId');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    selfReportedQuitId: chosenId
                                }));
                            }}></DDLOptionPicker>
                        {errors.selfReportedQuitId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.selfReportedQuitId} />
                            </div>
                        )}
                    </div>

                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'CO Result'}
                            id={'coResultId'}
                            key={isClearCoResultId}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={coResults || []}
                            chosenName={newEntry.coResult}
                            chosenId={newEntry.coResultId}
                            onChange={(chosenId) => {
                                clearError('coResultId');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    coResultId: chosenId
                                }));
                            }}></DDLOptionPicker>
                        {errors.coResultId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.coResultId} />
                            </div>
                        )}

                        <NoYesRadioPicker
                            id="coValidation"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.coValidation}
                            text={'CO Validation'}
                            onChange={(val) =>
                                setNewEntry((prev) => ({ ...prev, coValidation: val }))
                            }></NoYesRadioPicker>

                        <NoYesRadioPicker
                            id="quitReviewSuccessful"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.quitReviewSuccessful}
                            text={'Quit Review Successful'}
                            onChange={(val) =>
                                setNewEntry((prev) => ({ ...prev, quitReviewSuccessful: val }))
                            }></NoYesRadioPicker>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={type === 'create' ? 'Add Quit Review' : 'Update Quit Review'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateQuitReview;

CreateQuitReview.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    smokingQuitAttemptId: PropTypes.string,
    quitDate: PropTypes.string,
    type: PropTypes.string,
    quitAttemptReview: PropTypes.object
};
