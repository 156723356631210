import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider } from '@mui/material';

import {
    createIWSRecord,
    loadIWSRecordsByPlacementId,
    updateIWSRecord
} from '../../../../../store/participantService';
import { selectLoggedInUser, selectUsers } from '../../../../../store/userSelectors';
import { getFutureDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';
import ActionPlan from '../../../actionPlan/ActionPlan';
import Barriers from '../../../barriers/Barriers';

import InWorkSupportTableManagement from './InWorkSupportTableManagement';

import app from '../../../../../app.module.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import classes from '../../submissionStyles/iws.module.css';

const FREQUENCY_NOTE_LENGTH = 750;

const InWorkSupport = ({ row, roles, callOutcomes, frequencyDetails, workConfidence }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        participantId: null,
        frequencyId: null,
        placementId: null,
        iwsEventOwnerId: null,
        frequencyNote: '',
        dueDate: '',
        frequencyWeekNo: null,
        callDate: '',
        workConfidenceId: null,
        callOutcomeId: null,
        note: '',
        notesFromLastCall: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [eventOwners, setEventOwners] = useState([]);
    const [iwsRecordsForPlacement, setIwsRecordsForPlacement] = useState([]);
    const [callAddedTodayAlready, setCallAddedTodayAlready] = useState(false);
    const dateFormat = 'yyyy-MM-dd';
    const today = new Date();

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector(selectUsers);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { contractConstants, currentParticipant, iwsRecordsByPlacementId } = useSelector(
        (state) => state.entities.participantService
    );
    const { iwsRecords } = iwsRecordsByPlacementId[row.id] || {};

    // USE EFFECTS
    useEffect(() => {
        !iwsRecords && loadIwsRecordsForPlacement();
        if (
            loggedInUser.roles.includes(ADVISER) ||
            loggedInUser.roles.includes(MANAGER) ||
            loggedInUser.roles.includes(RECRUITMENT_MANAGER)
        ) {
            handleIwsEventOwnerIdChange(loggedInUser.id);
            setValue('iwsEventOwnerId', loggedInUser.id, { shouldValidate: true });
        }
    }, []);

    useEffect(() => {
        setEventOwners(
            users
                .filter((el) =>
                    el.userTypes?.find(
                        (entry) =>
                            entry.role === 'ADVISER' ||
                            entry.role === 'MANAGER' ||
                            entry.role === 'RECRUITMENT_MANAGER'
                    )
                )
                .map(({ id, emailAddress: name }) => ({
                    id,
                    name
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        );
    }, [users]);

    useEffect(() => {
        if (!iwsRecords?.length) return;
        const updatedIwsRecords = iwsRecords.map((el) => ({
            ...el,
            workConfidenceName: getNameFromId(workConfidence, el.workConfidenceId),
            outcomeName: getNameFromId(callOutcomes, el.callOutcomeId)
        }));
        setIwsRecordsForPlacement(updatedIwsRecords);

        const callToday = updatedIwsRecords.find(
            (entry) => format(today, dateFormat) === entry.dueDate.substring(0, 10)
        );
        if (callToday) {
            setNewEntry(callToday);
            setValue('frequencyId', callToday.frequencyId, { shouldValidate: true });
            setValue('iwsEventOwnerId', callToday.iwsEventOwnerId, { shouldValidate: true });

            if (
                !callToday.callOutcomeId ||
                getNameFromId(callOutcomes, callToday.callOutcomeId).toLowerCase() === 'due' ||
                getNameFromId(callOutcomes, callToday.callOutcomeId).toLowerCase() === 'missed'
            )
                setCallAddedTodayAlready(false);
            else setCallAddedTodayAlready(true);
        } else {
            const firstFutureDatedCall = updatedIwsRecords.find((entry) =>
                getFutureDate(entry.dueDate.substring(0, 10))
            );

            if (firstFutureDatedCall) {
                handleFrequencyChange(
                    updatedIwsRecords.find((entry) => getFutureDate(entry.dueDate.substring(0, 10)))
                        ?.frequencyId || ''
                );

                setNewEntry(
                    updatedIwsRecords.find((entry) => getFutureDate(entry.dueDate.substring(0, 10)))
                );
                setValue('frequencyId', firstFutureDatedCall.frequencyId, { shouldValidate: true });
                setValue('iwsEventOwnerId', firstFutureDatedCall.iwsEventOwnerId, {
                    shouldValidate: true
                });
            }
        }
    }, [iwsRecords]);

    useEffect(() => {
        if (
            [
                `IWS Record for ${row.businessName} has been added`,
                `IWS Record for ${row.businessName} has been updated`
            ].includes(successMessage)
        ) {
            loadIwsRecordsForPlacement();
        }
    }, [successMessage]);

    // HELPER FNS
    const getName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return eventOwners.find((item) => item.id === id)?.name || '';
    };

    const loadIwsRecordsForPlacement = () => {
        dispatch(loadIWSRecordsByPlacementId(row.id));
    };

    // EVENT HANDLERS
    const handleFrequencyChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, frequencyId: chosenId }));
    };

    const handleIwsEventOwnerIdChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, iwsEventOwnerId: chosenId }));
    };

    const handleFrequencyNoteChange = (e) => {
        setNewEntry((prev) => ({ ...prev, frequencyNote: e.target.value }));
    };

    const onSubmit = () => {
        setValue('frequencyId', newEntry.frequencyId, { shouldValidate: true });
        setValue('iwsEventOwnerId', newEntry.iwsEventOwnerId, { shouldValidate: true });
        const payload = {
            ...newEntry,
            participantId: currentParticipant.id,
            placementId: row.id
        };
        if (!newEntry.id) {
            dispatch(createIWSRecord(payload, row.businessName));
        } else {
            dispatch(updateIWSRecord(payload, row.businessName));
        }
    };

    return (
        <div className={classes.divTop}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className={app.sectionHeading}>IWS Overview:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Frequency'}
                            id={'frequencyId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={frequencyDetails}
                            chosenName={
                                newEntry?.frequencyId
                                    ? getNameFromId(frequencyDetails, newEntry.frequencyId)
                                    : ''
                            }
                            chosenId={newEntry?.frequencyId || ''}
                            error={errors.frequencyId}
                            {...register('frequencyId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleFrequencyChange(e);
                                }
                            }}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'IWS Event Owner'}
                            id={'iwsEventOwnerId'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            menuItems={eventOwners}
                            chosenName={
                                newEntry?.iwsEventOwnerId ? getName(newEntry.iwsEventOwnerId) : ''
                            }
                            chosenId={newEntry?.iwsEventOwnerId || ''}
                            error={errors.iwsEventOwnerId}
                            {...register('iwsEventOwnerId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleIwsEventOwnerIdChange(e);
                                }
                            }}
                        />
                    </div>
                </div>
                <div>
                    <LabelledTextField
                        label={'Frequency Notes'}
                        id={'note'}
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        mandatory={false}
                        multiline
                        rows={5}
                        value={newEntry?.frequencyNote || ''}
                        placeholder={'Enter frequency note'}
                        counter={'true'}
                        helperText={
                            newEntry?.frequencyNote
                                ? `${newEntry?.frequencyNote?.length}/${FREQUENCY_NOTE_LENGTH}`
                                : `0/${FREQUENCY_NOTE_LENGTH}`
                        }
                        inputProps={{
                            maxLength: FREQUENCY_NOTE_LENGTH
                        }}
                        onChange={handleFrequencyNoteChange}
                    />
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={
                        !hasRole(acceptedRoles, loggedInUser.roles) ||
                        (Object.keys(iwsRecordsForPlacement).length > 0 && !callAddedTodayAlready)
                    }>
                    Update
                </Button>
                <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
            </form>
            <h2 className={app.sectionHeading}>IWS Schedule:</h2>
            <InWorkSupportTableManagement
                businessName={row.businessName}
                currentCall={newEntry}
                iwsRecordsForPlacement={iwsRecordsForPlacement}
                placementId={row.id}
            />
            <p></p>
            <div className={form.formSection}>
                <div className={form.formColumn}>
                    {contractConstants?.displayBarrierTableInIWS ? <Barriers /> : ''}
                </div>
            </div>
            <p></p>
            <div className={form.formSection}>
                <div className={form.formColumn}>
                    {contractConstants?.displayActionTableInIWS ? <ActionPlan /> : ''}
                </div>
            </div>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    frequencyId: Yup.string().required('Please select a frequency.'),
    iwsEventOwnerId: Yup.string().required('Please select an IWS event owner.')
});

InWorkSupport.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    callOutcomes: PropTypes.array.isRequired,
    frequencyDetails: PropTypes.array.isRequired,
    workConfidence: PropTypes.array.isRequired
};

export default InWorkSupport;
