import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import classes from '../../../commonStyles/tables.module.css';

const TimelineEventHistoryTableRow = ({ row }) => {
    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{row.field}</TableCell>
                <TableCell>{row?.oldValueName ? row.oldValueName : row.oldValue}</TableCell>
                <TableCell>{row.newValueName ? row.newValueName : row.newValue}</TableCell>
            </TableRow>
        </>
    );
};

export default TimelineEventHistoryTableRow;

TimelineEventHistoryTableRow.propTypes = {
    row: PropTypes.object
};
