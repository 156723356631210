import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

import { loadVacancyStageDetails } from '../../../../../store/directusService';
import { loadBusinessSectorDetails } from '../../../../../store/directusService';
import { loadBusinessRecord } from '../../../../../store/recruitmentService';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import LabelledPostcodeText from '../../../../ui/editors/LabelledPostcodeText';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../../ui/pickers/NoYesRadioPicker';

import app from '../../../../../app.module.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import dropdownClasses from '../../../../courses/courseStyles/rowDropDown.module.css';

const CurrentVacancyDetails = ({ row, onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [hiringManagers, setHiringManagers] = useState([]);

    // STORE STATE
    const { businessSectorDetails, vacancyStageDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentBusiness } = useSelector((state) => state.entities.recruitmentService);

    const getHiringManagerName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return hiringManagers.find((item) => item.id === id)?.name || '';
    };

    // USE EFFECTS
    useEffect(() => {
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        businessSectorDetails?.length < 1 && dispatch(loadBusinessSectorDetails());
    }, []);

    useEffect(() => {
        if (row && row.length !== 0) {
            dispatch(loadBusinessRecord(row.businessRecordId));
        }
    }, [row]);

    useEffect(() => {
        if (!currentBusiness?.ownerId) return;

        setHiringManagers(
            currentBusiness?.contactCards?.map((el) => ({
                id: el.id,
                name: `${el.firstName} ${el.lastName} ( ${el.officeNumber} )`
            }))
        );
    }, [currentBusiness]);

    // RENDER
    let content;
    if (businessSectorDetails?.length < 1) content = 'No business sector found';
    if (currentBusiness?.length < 1) content = 'No business information found';

    return !currentBusiness || currentBusiness.length < 1 || businessSectorDetails.length < 1 ? (
        <LoadingSpinner content={content} />
    ) : (
        <div className={dropdownClasses.rowDropdown}>
            <form>
                <h2 className={app.sectionHeading}>Vacancy Details:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Business Record'}
                            id={'title'}
                            disabled={true}
                            value={currentBusiness.name || ''}
                        />
                        <LabelledTextField
                            label={'Hiring Manager'}
                            id={'managerId'}
                            disabled={true}
                            value={
                                row.hiringManagerId ? getHiringManagerName(row.hiringManagerId) : ''
                            }
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Number of Positions'}
                            id={'positions'}
                            type="number"
                            disabled={true}
                            value={row.numberOfPositions || ''}
                        />
                        <LabelledTextField
                            label={'Expected Weekly Hours'}
                            id={'expectedWeeklyHours'}
                            type="number"
                            disabled={true}
                            value={row.expectedWeeklyHours || ''}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Description'}
                            id={'description'}
                            disabled={true}
                            multiline
                            rows={5}
                            value={row.description}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Requirements'}
                            id={'requirements'}
                            disabled={true}
                            multiline
                            rows={3}
                            value={row.requirement}
                        />
                        <LabelledTextField
                            label={'Shift Patterns/Working Hours'}
                            id={'shiftPattern'}
                            disabled={true}
                            multiline
                            rows={3}
                            value={row.shiftPatternWorkingHours}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Address:</h2>
                <NoYesRadioPicker
                    disabled={true}
                    text={'Remote?'}
                    radioButtonPick={row.remote || false}
                />
                <div hidden={row.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Address 1'}
                                id={'address1'}
                                disabled={true}
                                value={row.addressLine1 || ''}
                            />
                            <LabelledTextField
                                label={'Address 2'}
                                id={'address2'}
                                disabled={true}
                                value={row.addressLine2 || ''}
                            />
                            <LabelledTextField
                                label={'Address 3'}
                                id={'address3'}
                                disabled={true}
                                value={row.addressLine3 || ''}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Town/City'}
                                id={'city'}
                                disabled={true}
                                value={row.city || ''}
                            />
                            <LabelledPostcodeText
                                label={'Postcode'}
                                id={'postcode'}
                                disabled={true}
                                value={row.postcode || ''}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {row.stageIds.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <DDLOptionPicker
                                            label={
                                                row.stageIds.length === i + 1
                                                    ? 'Final Stage'
                                                    : 'Stage ' + `${i + 1}`
                                            }
                                            id={'stageId'}
                                            name={'stageId'}
                                            disabled={true}
                                            chosenName={getNameFromId(vacancyStageDetails, el)}
                                            chosenId={el || ''}
                                            menuItems={vacancyStageDetails}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <div className={dropdownClasses.exit} onClick={onClose}>
                    Close details
                    <CloseIcon />
                </div>
            </form>
        </div>
    );
};

CurrentVacancyDetails.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default CurrentVacancyDetails;
