import { isDateFuture } from '../../../../utils/dateFunctions';

const ERROR_BAD_DATE = 'Date must be in the past or present';
const NO_TYPE_ID = 'Please select a type';
const NO_ORGANISATION_ID = 'Please select an organisation';

export const initialErrorState = {
    organisationId: { error: false, message: '' },
    typeId: { error: false, message: '' },
    date: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.typeId) {
        newErrors = {
            ...newErrors,
            typeId: { error: true, message: NO_TYPE_ID }
        };
        isValid = false;
    }
    if (!newEntry.organisationId) {
        newErrors = {
            ...newErrors,
            organisationId: { error: true, message: NO_ORGANISATION_ID }
        };
        isValid = false;
    }
    if (!newEntry.date) {
        newErrors = {
            ...newErrors,
            date: { ...errors.date, error: true }
        };
        isValid = false;
    }
    newErrors = checkDate(newEntry.date, newErrors);
    if (newErrors.date.error) isValid = false;

    return { isValid, newErrors };
};

export const checkDate = (date, errors) => {
    return isDateFuture(date)
        ? {
              ...errors,
              date: { error: true, message: ERROR_BAD_DATE }
          }
        : {
              ...errors,
              date: initialErrorState.date
          };
};
