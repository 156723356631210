import PropTypes from 'prop-types';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { Card, CardContent, Typography } from '@mui/material';

import {
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';

import classes from '../cardStyles/cardView.module.css';

/**
 * Viewer for smart target card
 *
 * Uses ScheduleModuleEditor when edit icon is clicked
 *
 * Calls the sendDelete callback when delete icon is clicked and this causes
 * CardHandler to delete this item.
 * @param cardData Data for this viewer. This has values which match the
 * module table in the course service
 * @param disabled  If true, disables control.
 * @returns {JSX.Element}
 *
 */

const BusinessView = ({ cardData, disabled = false }) => {
    // LOCAL STATE
    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_ROYAL_BLUE;
    const { firstName, lastName, positions, notes, officeNumber, mobileNumber, email } = cardData;

    return (
        <div className={classes.cardView}>
            <Card
                elevation={3}
                sx={{
                    backgroundColor: POLARIS_WHITE,
                    width: 250,
                    height: 'calc(100% - 16px)',
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <div
                    className={`${
                        firstName?.length > 30 || lastName?.length > 30
                            ? classes.bannerDoubleRow
                            : classes.banner
                    }`}
                    style={{ backgroundColor }}>
                    <Typography variant="h6">{'Contact ' + firstName + ' ' + lastName}</Typography>
                </div>
                <CardContent sx={{ padding: 1 }}>
                    <div>
                        <Typography variant="body1">
                            <span className={classes.label}>Contact Notes: </span>
                            <span className={classes.description}>
                                {notes?.length > 100 ? notes.substring(0, 97) + '...' : notes}
                            </span>
                        </Typography>
                        <Typography variant="body1">
                            <span className={classes.span}>
                                <AccountCircleIcon />
                                {` ${positions ? positions : ' - '}`}
                            </span>
                        </Typography>
                        <Typography variant="body1">
                            <span className={classes.span}>
                                <PhoneIcon />
                                {` ${officeNumber ? officeNumber : ' - '}`}
                            </span>
                        </Typography>
                        <Typography variant="body1">
                            <span className={classes.span}>
                                <PhoneIphoneIcon />
                                {` ${mobileNumber ? mobileNumber : ' - '}`}
                            </span>
                        </Typography>
                        <Typography variant="body1">
                            <span className={classes.span}>
                                <EmailIcon />
                                {` ${email ? email : ' - '}`}
                            </span>
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

BusinessView.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default BusinessView;
