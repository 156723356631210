import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { selectParticipantAttendedModules } from '../../../../store/courseSelectors';
import { loadCoursesByParticipantId } from '../../../../store/courseService';
import { selectAttendedStatusId } from '../../../../store/dataSelectors';
import { loadAttendanceDetails } from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import { deleteBMI, loadBMIEntries, onDeleteBMIEntry } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { formatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import BMITable from './BMITable';
import CreateBMIEntry from './CreateBMIEntry';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const BMI = () => {
    const dispatch = useDispatch();
    const formRef = useRef(null);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [modules, setModules] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const attendedStatusId = useSelector(selectAttendedStatusId);
    const attendedModules = useSelector(selectParticipantAttendedModules);
    const BMIEntries = useSelector((state) => state.entities.participantService.bmiEntries);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS

    useEffect(() => {
        !attendedStatusId && dispatch(loadAttendanceDetails());
    }, []);

    useEffect(() => {
        if (currentParticipant?.id) {
            dispatch(loadBMIEntries(currentParticipant.id));
            dispatch(loadCoursesByParticipantId(currentParticipant?.id));
        }
    }, [currentParticipant]);

    useEffect(() => {
        const entries = BMIEntries.map((el) => ({ ...el, displayDate: Date.parse(el.eventDate) }));
        const rows = entries
            .sort((a, b) => a.displayDate - b.displayDate)
            .map((el, i, arr) => ({
                ...el,
                eventDate: formatDate(el.eventDate),
                lost: arr[i - 1]
                    ? (((arr[i - 1].weight - el.weight) / arr[i - 1].weight) * 100).toFixed(1)
                    : 0,
                bmi: ((el.weight / (el.height * el.height)) * 10000).toFixed(1) || 0,
                linkedModule: el.linkedModuleId
                    ? attendedModules?.find((entry) => entry.id === el.linkedModuleId)?.title
                    : 'z'
            }));
        setRows(rows);
        attendedModules?.length &&
            setModules(
                attendedModules.filter(
                    (el) => !BMIEntries.find((entry) => entry.linkedModuleId === el.id)
                )
            );
    }, [BMIEntries, attendedModules]);

    useEffect(() => {
        if (successMessage === `BMI entry deleted`) dispatch(onDeleteBMIEntry(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteBMI(row.id));
    };

    const onClose = () => {
        setShowForm(false);
        setSelectedRow({});
    };

    return (
        <div>
            <div className={app.sectionHeading}>BMI</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No BMI entries found" />
                ) : (
                    <BMITable rows={rows} roles={roles} onEdit={onEdit} onDelete={onDelete} />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New BMI
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreateBMIEntry
                            onClose={onClose}
                            roles={roles}
                            attendedModules={modules}
                            formType={formType}
                            row={selectedRow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BMI;
