import { compareUnorderedArrays } from '../../../utils/arrayUtils';

export const ERROR_NO_SELECTION = 'Please select at least one value';

export const BUTTON_ERROR = 'There is nothing to update';

export const initialErrorState = {
    appointmentTypes: { error: false, message: '' },
    button: { error: false, message: '' }
};
export const validate = (selections) => {
    let newErrors = {};

    if (selections.appointmentTypes.length < 1) {
        newErrors = {
            ...newErrors,
            appointmentTypes: { error: true, message: ERROR_NO_SELECTION }
        };
    }

    return { newErrors };
};

export const checkDiff = (entries, initialStateOnEntry) => {
    let isSame = false;
    if (compareUnorderedArrays(Object.values(entries), Object.values(initialStateOnEntry))) {
        isSame = true;
    }
    return { isSame };
};
