import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button } from '@mui/material';

import {
    clearDisability,
    clearMentalHealth,
    loadDisabilityDetails,
    loadMentalHealthDetails,
    loadModStatusDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    loadParticipantCircumstances,
    updateParticipantCircumstances
} from '../../../store/participantService';
import { getConfiguredItems, getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import BusyIndicator from '../../ui/BusyIndicator';
import CardHandler from '../../ui/cards/CardHandler';
import { CONTACT_DETAILS_VIEWER } from '../../ui/cards/ViewerTypes';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import FuzzySearchOptionPicker from '../../ui/pickers/FuzzySearchOptionPicker';
import NoYesOtherRadioPicker from '../../ui/pickers/NoYesOtherRadioPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';
import { RADIO_BUTTON_NO, RADIO_BUTTON_YES } from '../../ui/pickers/SelectorOptions';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const HEALTH_COMMENT_MAX_LENGTH = 100;
const REQUIRED_ADJUSTMENTS_MAX_LENGTH = 250;

const Circumstances = () => {
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        participantId: null,
        hasDisability: null,
        disabilityDetailIds: [],
        hasMentalHealthProblems: null,
        mentalHealthConditionIds: [],
        requiredAdjustments: null,
        healthComment: null,
        hasCriminalRecord: null,
        criminalConvictionSpent: null,
        modStatusId: '',
        additionalContactCards: []
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [arrayDisabilities, setArrayDisabilities] = useState([]);
    const [arrayMentalHealth, setArrayMentalHealth] = useState([]);
    const [arrayModStatus, setArrayModStatus] = useState([]);
    const [displayHealthComment, setDisplayHealthComment] = useState(true);
    const [isClearModStatus, setIsClearModStatus] = useState('0');
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const { mentalHealthDetails, disabilityDetails, modStatusDetails } = useSelector(
        (state) => state.entities.directusService
    );
    let dbCircumstancesInformation = useSelector(
        (state) => state.entities.participantService.currentParticipantCircumstances
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const clearForm = () => {
        setIsClearModStatus(Math.random());
    };

    // EVENT HANDLERS
    const handleCriminalConvictionSpentChange = (value) =>
        setNewEntry((prev) => ({ ...prev, criminalConvictionSpent: value }));

    const handleHealthCommentChange = (e) =>
        setNewEntry((prev) => ({ ...prev, healthComment: e.target.value }));

    const handleRequiredAdjustmentsChange = (e) =>
        setNewEntry((prev) => ({ ...prev, requiredAdjustments: e.target.value }));

    const handleDisabilityDetailsChange = (ids, yesNo) => {
        setNewEntry((prev) => ({
            ...prev,
            disabilityDetailIds: ids,
            hasDisability: yesNo
        }));
    };

    const handleMentalHealthConditionsChange = (ids, yesNo) => {
        setNewEntry((prev) => ({
            ...prev,
            mentalHealthConditionIds: ids,
            hasMentalHealthProblems: yesNo
        }));
        if (yesNo === 'yes') {
            setDisplayHealthComment(false);
        } else {
            setDisplayHealthComment(true);
        }
    };

    const handleCriminalRecordChange = (option) =>
        setNewEntry((prev) => ({ ...prev, hasCriminalRecord: option }));

    const deleteContactChange = (contact) => {
        setNewEntry((prev) => ({
            ...prev,
            additionalContactCards: newEntry.additionalContactCards.filter(
                (c) => c.id !== contact.id
            )
        }));
    };

    const handleModStatusChange = (option) =>
        setNewEntry((prev) => ({ ...prev, modStatusId: option }));

    const addBlankContactChange = () => {
        // If there is already a blank then don't add another
        // Check for 'blank' should check firstName and relationship
        if (
            newEntry.additionalContactCards.some(
                (c) =>
                    c.firstName === null ||
                    c.firstName.length < 1 ||
                    c.relationshipToParticipant === null ||
                    c.relationshipToParticipant.length < 1
            )
        ) {
            dispatch(setErrorMessage(`Cannot add another blank. Please complete existing card.`));
            return;
        }

        setNewEntry((prev) => ({
            // Add blank contact card
            ...prev,
            additionalContactCards: [
                ...newEntry.additionalContactCards,
                {
                    addressLine1: null,
                    addressLine2: null,
                    addressLine3: null,
                    circumstancesId: null,
                    city: null,
                    emailAddress: null,
                    firstName: null,
                    // Need a key for sorting but this will be replaced by null when saved
                    id: 'newCard' + uuid(),
                    lastName: '',
                    phoneNumber: null,
                    postcode: null,
                    relationshipToParticipant: null,
                    newCard: true
                }
            ]
        }));
    };

    const updateContactChange = (contact) => {
        // Change value in newEntry to use contact
        setNewEntry((prev) => ({
            ...prev,
            additionalContactCards: newEntry.additionalContactCards.map((el) =>
                el.id === contact.id ? contact : el
            )
            // Find matching contact in additionalContactCards
        }));
    };

    const updateContact = (contact) => updateContactChange(contact);
    const deleteContact = (contact) => deleteContactChange(contact);

    // USEEFFECTS
    /**
     * disabilityDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (disabilityDetails?.length && arrayDisabilities?.length < 1) {
            const configItems = getConfiguredItems(
                disabilityDetails,
                currentParticipant?.contractId
            );
            const sorted = configItems.sort((a, b) => a.name.localeCompare(b.name));
            setArrayDisabilities(sorted);
        }
    }, [disabilityDetails]);

    /**
     * mentalHealthDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (mentalHealthDetails?.length && arrayMentalHealth?.length < 1) {
            const configItems = getConfiguredItems(
                mentalHealthDetails,
                currentParticipant?.contractId
            );
            const sorted = configItems.sort((a, b) => a.name.localeCompare(b.name));
            setArrayMentalHealth(sorted);
        }
    }, [mentalHealthDetails]);

    useEffect(() => {
        if (modStatusDetails?.length && arrayModStatus?.length < 1) {
            const configItems = getConfiguredItems(
                modStatusDetails,
                currentParticipant?.contractId
            );
            const sorted = configItems.sort((a, b) => a.name.localeCompare(b.name));
            setArrayModStatus(sorted);
        }
    }, [modStatusDetails]);

    useEffect(() => {
        dbCircumstancesInformation = {};
        dispatch(clearDisability());
        dispatch(clearMentalHealth());
        dispatch(loadDisabilityDetails());
        dispatch(loadMentalHealthDetails());
        dispatch(loadModStatusDetails());
        dispatch(loadParticipantCircumstances(currentParticipant?.id));

        setArrayDisabilities([]);
        setArrayMentalHealth([]);
        setArrayModStatus([]);
    }, [currentParticipant.id]);

    useEffect(() => {
        if (Object.keys(dbCircumstancesInformation)?.length) {
            setNewEntry(dbCircumstancesInformation);
        }
    }, [dbCircumstancesInformation]);

    useEffect(() => {
        if (successMessage === 'Participant circumstance details have been updated') {
            setIsUpdateButtonDisabled(false);
        }
    }, [successMessage]);

    // HELPER FNS

    const hasIncompleteCard = () => {
        return newEntry.additionalContactCards.some(
            (c) =>
                c.firstName === null ||
                c.firstName.length < 1 ||
                c.relationshipToParticipant === null ||
                c.relationshipToParticipant.length < 1
        );
    };

    // FORM SUBMIT

    const onSubmit = () => {
        if (hasIncompleteCard()) {
            dispatch(setErrorMessage(`Cannot save. Please complete all contact cards.`));
            return;
        }
        setIsUpdateButtonDisabled(true);
        // Ensure additional contact card ids of new cards are blank
        const updatedCards = newEntry.additionalContactCards.map((el) =>
            el.id.startsWith('newCard') ? { ...el, id: null } : el
        );

        const payload = {
            ...newEntry,
            participantId: currentParticipant.id,
            additionalContactCards: updatedCards
        };

        dispatch(updateParticipantCircumstances(payload));
        clearForm();
    };

    //  RENDER
    if (
        disabilityDetails?.length < 1 ||
        mentalHealthDetails?.length < 1 ||
        modStatusDetails?.length < 1 ||
        Object.keys(currentParticipant)?.length < 1 ||
        arrayDisabilities?.length < 1 ||
        arrayMentalHealth?.length < 1 ||
        arrayModStatus?.length < 1
    ) {
        return <BusyIndicator />;
    }

    return (
        <div className={form.formWrapper}>
            <form onSubmit={handleSubmit(onSubmit)} className={form.form}>
                <h2 className={app.sectionHeading}>General Details</h2>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <FuzzySearchOptionPicker
                            label={'Disability'}
                            inputLabel="Disability - Info"
                            disabled={!hasRole(acceptedRoles, roles)}
                            enterPrompt={'disability'}
                            allOptions={arrayDisabilities}
                            preSelectedIds={dbCircumstancesInformation.disabilityDetailIds || []}
                            radioButtonPick={
                                dbCircumstancesInformation.hasDisability || RADIO_BUTTON_NO
                            }
                            onChange={handleDisabilityDetailsChange}
                        />

                        <FuzzySearchOptionPicker
                            label={'Health/Mental Health Problems?'}
                            inputLabel="Health/Mental Health Problems - Info"
                            disabled={!hasRole(acceptedRoles, roles)}
                            enterPrompt={'problem'}
                            allOptions={arrayMentalHealth}
                            preSelectedIds={
                                dbCircumstancesInformation.mentalHealthConditionIds || []
                            }
                            radioButtonPick={
                                dbCircumstancesInformation.hasMentalHealthProblems ||
                                RADIO_BUTTON_NO
                            }
                            onChange={handleMentalHealthConditionsChange}
                        />

                        <div hidden={displayHealthComment}>
                            <LabelledTextField
                                label={'Health Comment'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                id={'healthComment'}
                                value={newEntry.healthComment || ''}
                                placeholder={'Enter health comment'}
                                error={errors.healthComment}
                                {...register('healthComment')}
                                onChange={handleHealthCommentChange}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <NoYesOtherRadioPicker
                            id="criminalRecord"
                            disabled={!hasRole(acceptedRoles, roles)}
                            label={'Criminal Record'}
                            radioButtonPick={
                                dbCircumstancesInformation.hasCriminalRecord || RADIO_BUTTON_NO
                            }
                            thirdButtonText={'Unknown'}
                            onChange={handleCriminalRecordChange}
                        />

                        <div hidden={newEntry.hasCriminalRecord !== RADIO_BUTTON_YES}>
                            <NoYesRadioPicker
                                disabled={!hasRole(acceptedRoles, roles)}
                                text={'Criminal Conviction Spent?'}
                                radioButtonPick={
                                    dbCircumstancesInformation.criminalConvictionSpent || false
                                }
                                onChange={handleCriminalConvictionSpentChange}
                            />
                        </div>
                        <div className={form.textArea}>
                            <LabelledTextField
                                label={'Required Adjustments'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                id={'requiredAdjustments'}
                                rows={4}
                                value={newEntry.requiredAdjustments || ''}
                                placeholder={'Enter required adjustments'}
                                error={errors.requiredAdjustments}
                                {...register('requiredAdjustments')}
                                onChange={handleRequiredAdjustmentsChange}
                            />
                        </div>
                        <DDLOptionPicker
                            label={'Are you part of the Armed Forces Community?'}
                            id={'modStatusId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            key={isClearModStatus}
                            chosenName={getNameFromId(modStatusDetails, newEntry.modStatusId)}
                            chosenId={newEntry.modStatusId}
                            menuItems={arrayModStatus}
                            onChange={(chosenId) => handleModStatusChange(chosenId)}
                        />
                    </div>
                </div>

                <h2 className={app.sectionHeading}>Additional Contacts</h2>

                <div>
                    <CardHandler
                        viewerType={CONTACT_DETAILS_VIEWER}
                        cards={newEntry.additionalContactCards || []}
                        disabled={!hasRole(acceptedRoles, roles)}
                        itemName={'Contact'}
                        label={'Add Additional Contact'}
                        sendAdd={addBlankContactChange}
                        sendDelete={deleteContact}
                        sendUpdate={updateContact}></CardHandler>
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!hasRole(acceptedRoles, roles) || isUpdateButtonDisabled}
                    data-testid="testIdHISubmitButton">
                    Update Circumstances
                </Button>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    healthComment: Yup.string().max(
        HEALTH_COMMENT_MAX_LENGTH,
        `Health comment must be ${HEALTH_COMMENT_MAX_LENGTH} characters or shorter`
    ),
    requiredAdjustments: Yup.string().max(
        REQUIRED_ADJUSTMENTS_MAX_LENGTH,
        `Required adjustments must be ${REQUIRED_ADJUSTMENTS_MAX_LENGTH} characters or shorter`
    )
});

export default Circumstances;
