import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

export const configDisabled = (newEntry, roles, allowMandatoryActivities) => {
    return {
        barrierId: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles),

        mandatory:
            !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles) || !allowMandatoryActivities,

        actionTitle: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles),

        action: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles),

        actionStartDate: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles),

        actionDueDate: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles),

        actionOutcomeDate: !hasRole([QUALITY, SUPERUSER], roles) || !newEntry.outcomeId,

        outcomeId: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles),

        comments: !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)
    };
};
