import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TimelineIcon from '@mui/icons-material/Timeline';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import {
    Box,
    Card,
    Divider,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from '@mui/material';
import Drawer from '@mui/material/Drawer';

import { POLARIS_ROYAL_BLUE, POLARIS_WHITE, SCREEN_SETTINGS } from '../../../themes/theme';

import ParticipantCommsMenu from './ParticipantCommsMenu';

// import ParticipantSecondaryMenu from './ParticipantSecondaryMenu';
import participant from './participant.module.css';

/**
 * @see PPL-
 * Shown on top right of screen below NavigationBar
 * This stays in place.
 *
 * It should only be shown when options from the 'Participant Menu' are selected.
 */

const ParticipantStickyMenu = () => {
    const navigate = useNavigate();
    const [showMenuIcon, setShowMenuIcon] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
            return;
        }
        setShowDrawer(open);
        setShowMenuIcon(!open);
    };

    const handleItemClick = (index) => {
        setShowDrawer(false);
        setShowMenuIcon(true);
        switch (index) {
            case -1:
                setShowDrawer(true);
                setShowMenuIcon(false);
                break;
            case 0:
                navigate('/edit_participant', { state: { accordionPanel: 'primaryDetails' } });
                break;
            case 1:
                navigate('/course_information', { state: { accordionPanel: 'courses' } });
                break;
            case 2:
                navigate('/financial_information', {
                    state: { accordionPanel: 'financialRequests' }
                });
                break;

            case 3:
                navigate('/documents');
                break;

            case 4:
                navigate('/timeline');
                break;

            case 5:
                navigate('/participant_calendar');
                break;
            case 6:
                navigate('/tbc', {
                    state: { text: 'PPL-19 Participants Notes Screen is not available yet' }
                });
                break;

            default:
                break;
        }
    };
    const sXMoreThanWidth768 = {
        position: 'fixed',
        backgroundColor: POLARIS_ROYAL_BLUE,

        background: `linear-gradient(to top,  #0000FF, #000055)`,

        borderRadius: SCREEN_SETTINGS.radius.small,
        width: '40px',
        // Keep off right edge of screen
        // Ensure sticky menu top isn't hidden if screen narrow and menus etc. take two lines.
        transform: 'translateX(-5px) translateY(20px)'
    };
    const sXLessThanWidth768 = { ...sXMoreThanWidth768, height: '40px' };
    const sXFull768Width = {
        width: '100%',
        height: '100%',
        background: `linear-gradient(to top,  #0000FF, #000055)`,
        borderRadius: SCREEN_SETTINGS.radius.small
    };
    const personalOutlineIcon = (size) => (
        <Tooltip title="Contact Participant" placement="left-start">
            <PersonOutlineIcon
                fontSize={size}
                sx={{
                    padding: 0,
                    marginTop: size === 'large' ? SCREEN_SETTINGS.margin.large : 0,
                    marginBottom: 0,
                    color: POLARIS_WHITE
                }}
            />
        </Tooltip>
    );
    const workspacePremiumOutlinedIcon = (size) => (
        <Tooltip title="Course, Work & Education" placement="left-start">
            <WorkspacePremiumOutlinedIcon
                fontSize={size}
                sx={{
                    padding: 0,
                    color: POLARIS_WHITE
                }}
            />
        </Tooltip>
    );
    const creditCardIcon = (size) => (
        <Tooltip title="Financial Requests & Assets" placement="left-start">
            <CreditCardIcon
                fontSize={size}
                sx={{
                    padding: '0px',
                    color: POLARIS_WHITE
                }}
            />
        </Tooltip>
    );
    const descriptionIcon = (size) => (
        <Tooltip title="Documents" placement="left-start">
            <DescriptionIcon
                fontSize={size}
                sx={{
                    padding: '0px',
                    color: POLARIS_WHITE,
                    transform: 'rotate(180deg)'
                }}
            />
        </Tooltip>
    );
    const timelineIcon = (size) => (
        <Tooltip title="Timeline" placement="left-start">
            <TimelineIcon
                fontSize={size}
                sx={{
                    padding: '0px',
                    marginBottom: size === 'large' ? SCREEN_SETTINGS.margin.large : 0,
                    color: POLARIS_WHITE
                }}
            />
        </Tooltip>
    );

    const calendarMonthIcon = (size) => (
        <Tooltip title="Participant Calendar" placement="left-start">
            <CalendarMonthIcon
                fontSize={size}
                sx={{
                    padding: '0px',
                    marginBottom: size === 'large' ? SCREEN_SETTINGS.margin.large : 0,
                    color: POLARIS_WHITE
                }}
            />
        </Tooltip>
    );

    const noteOutlineIcon = (size) => (
        <Tooltip title="Participants Notes" placement="left-start">
            <NoteOutlinedIcon
                fontSize={size}
                sx={{
                    padding: '0px',
                    marginBottom: size === 'large' ? SCREEN_SETTINGS.margin.large : 0,
                    color: POLARIS_WHITE,
                    transform: 'rotate(90deg)'
                }}
            />
        </Tooltip>
    );

    const list = () => (
        <Card sx={sXFull768Width}>
            <div className={participant.titleContainer}>
                <Typography variant={'h6'} sx={{ color: POLARIS_WHITE }}>
                    Participant Options
                </Typography>
                <CloseIcon
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            toggleDrawer(false)();
                        }
                    }}
                    tabIndex={0}
                    fontSize={'large'}
                    sx={{ color: POLARIS_WHITE, marginRight: '10px' }}
                    onClick={toggleDrawer(false)}
                />
            </div>
            <Divider style={{ background: POLARIS_WHITE }} variant="middle" />
            <List>
                {[
                    {
                        title: 'Contact',
                        icon: personalOutlineIcon('small'),
                        onClick: () => handleItemClick(0)
                    },
                    {
                        title: 'Work, Course, Education & Placements',
                        icon: workspacePremiumOutlinedIcon('small'),
                        onClick: () => handleItemClick(1)
                    },
                    {
                        title: 'Assets',
                        icon: creditCardIcon('small'),
                        onClick: () => handleItemClick(2)
                    },
                    {
                        title: 'Documents',
                        icon: descriptionIcon('small'),
                        onClick: () => handleItemClick(3)
                    },
                    {
                        title: 'Timeline',
                        icon: timelineIcon('small'),
                        onClick: () => handleItemClick(4)
                    },
                    {
                        title: 'Participant Calendar',
                        icon: calendarMonthIcon('small'),
                        onClick: () => handleItemClick(5)
                    },
                    {
                        title: 'Participant Notes',
                        icon: noteOutlineIcon('small'),
                        onClick: () => handleItemClick(6)
                    }
                ].map((itemMeta, index) => (
                    <ListItem key={index} disablePadding onClick={itemMeta.onClick}>
                        <ListItemButton>
                            <div className={participant.listItemIconText}>
                                <ListItemIcon>{itemMeta.icon}</ListItemIcon>
                                <ListItemText
                                    primary={itemMeta.title}
                                    sx={{ color: POLARIS_WHITE }}
                                />
                            </div>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
    return (
        <>
            {showMenuIcon && (
                <div className={participant.smallParticipantStickyMenu}>
                    <Link onClick={() => handleItemClick(-1)}>
                        <Card sx={sXLessThanWidth768}>
                            <MoreVertIcon
                                fontSize={'large'}
                                sx={{
                                    padding: '0px',
                                    color: POLARIS_WHITE
                                }}
                            />
                        </Card>
                    </Link>
                </div>
            )}
            {!showMenuIcon && (
                <div className={participant.smallParticipantStickyMenu}>
                    <Drawer
                        PaperProps={{
                            sx: { width: '100%' }
                        }}
                        anchor={'right'}
                        open={showDrawer}
                        onClose={toggleDrawer(false)}>
                        {list()}
                    </Drawer>
                </div>
            )}
            <div className={participant.largeParticipantStickyMenu}>
                <Card sx={sXMoreThanWidth768}>
                    {/*<ParticipantSecondaryMenu />*/}
                    <ParticipantCommsMenu />

                    <Box height={SCREEN_SETTINGS.margin.large} />
                    <Link onClick={() => handleItemClick(1)}>
                        {workspacePremiumOutlinedIcon('large')}
                    </Link>

                    <Box height={SCREEN_SETTINGS.margin.large} />
                    <Link onClick={() => handleItemClick(2)}>{creditCardIcon('large')}</Link>

                    <Box height={SCREEN_SETTINGS.margin.large} />
                    <Link onClick={() => handleItemClick(3)}>{descriptionIcon('large')}</Link>

                    <Box height={SCREEN_SETTINGS.margin.large} />

                    <Link onClick={() => handleItemClick(4)}>{timelineIcon('large')}</Link>
                    <Link onClick={() => handleItemClick(5)}>{calendarMonthIcon('large')}</Link>
                    {/*<Link onClick={() => handleItemClick(6)}>{noteOutlineIcon('large')}</Link>*/}
                </Card>
            </div>
        </>
    );
};

export default ParticipantStickyMenu;
