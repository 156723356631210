import { React } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { Card, CardContent, Typography } from '@mui/material';
/**
 * Shown whenever the correct form is not available.
 *
 * The form shows an icon and the name of the form which will be shown.
 *
 * At some point this will disappear.
 */

const TBC = () => {
    const { state } = useLocation();

    return (
        <Card
            data-testid="form_start"
            sx={{
                height: '800px'
            }}>
            <CardContent>
                <div>
                    <Typography variant="h4" sx={{ paddingBottom: '20px' }}>
                        TBC: {state?.text || ''}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};
TBC.propTypes = {
    text: PropTypes.string
};

export default TBC;
