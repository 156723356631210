import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '@mui/material';

import { SCREEN_SETTINGS } from '../../themes/theme';

// A Link based on MUI Link and styled for Polaris.
const PolarisLink = (props) => (
    <Link
        label="Start"
        underline="hover"
        variant="h5"
        color="primary"
        onClick={props.onClick}
        sx={{
            paddingLeft: SCREEN_SETTINGS.margin.large,
            paddingRight: SCREEN_SETTINGS.margin.tiny
        }}>
        {props.label}{' '}
        <ExpandMoreIcon
            color="action"
            fontSize="medium"
            sx={{ paddingTop: SCREEN_SETTINGS.margin.small }}
        />
    </Link>
);
export default PolarisLink;
PolarisLink.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func
};
