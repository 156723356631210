import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';
import AccordionSubHeader from '../ui/accordion/AccordionSubHeader';
import { PolarisSwitch } from '../ui/PolarisSwitch';

import AppointmentsAdmin from './appointmentsAdmin/AppointmentsAdmin';
import AssetRequestsAdmin from './assetRequestsAdmin/AssetRequestsAdmin';
import CircumstancesAdmin from './circumstancesAdmin/CircumstancesAdmin';
import ContractDetailsAdmin from './contractDetailsAdmin/ContractDetailsAdmin';
import DocumentsAdmin from './documentsAdmin/DocumentsAdmin';
import FinancialRequestsAdmin from './financialRequestsAdmin/FinancialRequestsAdmin';
import FormsDefaultsConfigAdmin from './formsDefaultsConfigAdmin/FormsDefaultsConfigAdmin';
import ParticipantStatusAdmin from './participantStatusAdmin/ParticipantStatusAdmin';
import PrimaryDetailsAdmin from './primaryDetailsAdmin/PrimaryDetailsAdmin';
import EmployabilityAdmin from './EmployabilityAdmin';
import HealthInformationAdmin from './HealthInformationAdmin';
import ParticipantActionPlanAndGoalsAdmin from './ParticipantActionPlanAndGoalsAdmin';
import ParticipantBarriersAdmin from './ParticipantBarriersAdmin';
import ParticipantInformationAdmin from './ParticipantInformationAdmin';
import ParticipantQuestionnairesAndFormsAdmin from './ParticipantQuestionnairesAndFormsAdmin';
import PlacementsAdmin from './PlacementsAdmin';
import WellbeingAdmin from './WellbeingAdmin';

const ParticipantDetails = ({
    education,
    workHistory,
    workRequirements,
    wellbeing,
    placements,
    submissions,
    serviceStandards,
    actionPlanGoals,
    participantBarriers,
    employability,
    circumstances,
    healthInformation,
    financialRequests,
    assetRequests,
    contractId,
    onHeadingChange
}) => {
    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);

    return (
        <Card data-testid="form_start2">
            <CardContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <AccordionHeader>Contract Details</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ContractDetailsAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel1b-content"
                        id="panel1b-header">
                        <AccordionHeader>Primary Details</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PrimaryDetailsAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Participant Information</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ParticipantInformationAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header">
                        <AccordionHeader>Personal Information</AccordionHeader>
                    </AccordionSummary>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel3b-content"
                            id="panel3b-header">
                            <AccordionSubHeader>Health Information</AccordionSubHeader>
                            <PolarisSwitch
                                id="isHealthInformation"
                                checked={healthInformation}
                                onChange={(e) =>
                                    onHeadingChange((prev) => ({
                                        ...prev,
                                        healthInformation: e.target.checked
                                    }))
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <HealthInformationAdmin contractId={contractId} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel3c-content"
                            id="panel3c-header">
                            <AccordionSubHeader>Circumstances</AccordionSubHeader>
                            <PolarisSwitch
                                id="isCircumstances"
                                checked={circumstances}
                                onChange={(e) =>
                                    onHeadingChange((prev) => ({
                                        ...prev,
                                        circumstances: e.target.checked
                                    }))
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <CircumstancesAdmin contractId={contractId} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel3d-content"
                            id="panel3d-header">
                            <AccordionSubHeader>Employability</AccordionSubHeader>
                            <PolarisSwitch
                                id="isEmployability"
                                sx={{ flexGrow: 1 }}
                                checked={employability}
                                onChange={(e) =>
                                    onHeadingChange((prev) => ({
                                        ...prev,
                                        employability: e.target.checked
                                    }))
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <EmployabilityAdmin contractId={contractId} />
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header">
                        <AccordionHeader>Participant Barriers</AccordionHeader>
                        <PolarisSwitch
                            id="isParticipantBarriers"
                            checked={participantBarriers}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    participantBarriers: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <ParticipantBarriersAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    hidden={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel5a-content"
                        id="panel5a-header">
                        <AccordionHeader>Action Plan and Goals</AccordionHeader>
                        <PolarisSwitch
                            id="isActionPlanGoals"
                            checked={actionPlanGoals}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    actionPlanGoals: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <ParticipantActionPlanAndGoalsAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>

                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6a-content"
                        id="panel6a-header">
                        <Typography sx={{ flexGrow: 10 }}>Service Standards</Typography>
                        <PolarisSwitch
                            id="isServiceStandards"
                            sx={{ flexGrow: 1 }}
                            checked={serviceStandards}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    serviceStandards: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Panel will be populated with information from PPL-78: Administration -
                            Service Standards .
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel7a-content"
                        id="panel7a-header">
                        <AccordionHeader>Wellbeing</AccordionHeader>
                        <PolarisSwitch
                            id="isWellbeing"
                            sx={{ flexGrow: 1 }}
                            checked={wellbeing}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    wellbeing: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <WellbeingAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel8a-content"
                        id="panel8a-header">
                        <AccordionHeader>Work Requirements</AccordionHeader>
                        <PolarisSwitch
                            id="isWorkRequirements"
                            sx={{ flexGrow: 1 }}
                            checked={workRequirements}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    workRequirements: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel9a-content"
                        id="panel9a-header">
                        <AccordionHeader>Work History</AccordionHeader>
                        <PolarisSwitch
                            id="isWorkHistory"
                            sx={{ flexGrow: 1 }}
                            checked={workHistory}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    workHistory: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel10a-content"
                        id="panel10a-header">
                        <AccordionHeader>Education</AccordionHeader>
                        <PolarisSwitch
                            id="isEducation"
                            sx={{ flexGrow: 1 }}
                            checked={education}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    education: e.target.checked
                                }))
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                </Accordion>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    hidden={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel15a-content"
                        id="panel15a-header">
                        <AccordionHeader>Questionnaires and Forms</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ParticipantQuestionnairesAndFormsAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>

                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel11a-content"
                        id="panel11a-header">
                        <AccordionHeader>Submissions</AccordionHeader>
                        <PolarisSwitch
                            id="isSubmissions"
                            sx={{ flexGrow: 1 }}
                            checked={submissions}
                            onChange={(e) =>
                                onHeadingChange((prev) => ({
                                    ...prev,
                                    submissions: e.target.checked
                                }))
                            }
                        />{' '}
                    </AccordionSummary>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel3b-content"
                            id="panel3b-header">
                            <AccordionSubHeader>Placements</AccordionSubHeader>
                            <PolarisSwitch
                                id="isPlacements"
                                checked={placements}
                                onChange={(e) =>
                                    onHeadingChange((prev) => ({
                                        ...prev,
                                        placements: e.target.checked
                                    }))
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <PlacementsAdmin contractId={contractId} />
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    hidden={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel27a-content"
                        id="panel27a-header">
                        <AccordionHeader>Appointments</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AppointmentsAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel30a-content"
                        id="panel30a-header">
                        <AccordionHeader>Requests</AccordionHeader>
                    </AccordionSummary>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel30b-content"
                            id="panel30b-header">
                            <AccordionSubHeader>Financial Requests</AccordionSubHeader>
                            <PolarisSwitch
                                id="isFinancialRequests"
                                checked={financialRequests}
                                onChange={(e) =>
                                    onHeadingChange((prev) => ({
                                        ...prev,
                                        financialRequests: e.target.checked
                                    }))
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <FinancialRequestsAdmin contractId={contractId} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel30c-content"
                            id="panel30c-header">
                            <AccordionSubHeader>Asset Requests</AccordionSubHeader>
                            <PolarisSwitch
                                id="isAssetRequests"
                                checked={assetRequests}
                                onChange={(e) =>
                                    onHeadingChange((prev) => ({
                                        ...prev,
                                        assetRequests: e.target.checked
                                    }))
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <AssetRequestsAdmin contractId={contractId} />
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    hidden={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel31a-content"
                        id="panel31a-header">
                        <AccordionHeader>Documents</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DocumentsAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    hidden={!hasRole([SUPERUSER], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel32a-content"
                        id="panel32a-header">
                        <AccordionHeader>Participant Status</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ParticipantStatusAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    hidden={!hasRole([SUPERUSER], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel33a-content"
                        id="panel33a-header">
                        <AccordionHeader>Forms, Defaults & Configuration</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormsDefaultsConfigAdmin contractId={contractId} />
                    </AccordionDetails>
                </Accordion>
            </CardContent>
        </Card>
    );
};

export default ParticipantDetails;

ParticipantDetails.propTypes = {
    education: PropTypes.bool.isRequired,
    workHistory: PropTypes.bool.isRequired,
    workRequirements: PropTypes.bool.isRequired,
    wellbeing: PropTypes.bool.isRequired,
    submissions: PropTypes.bool.isRequired,
    placements: PropTypes.bool.isRequired,
    serviceStandards: PropTypes.bool.isRequired,
    actionPlanGoals: PropTypes.bool.isRequired,
    participantBarriers: PropTypes.bool.isRequired,
    employability: PropTypes.bool.isRequired,
    circumstances: PropTypes.bool.isRequired,
    healthInformation: PropTypes.bool.isRequired,
    financialRequests: PropTypes.bool.isRequired,
    assetRequests: PropTypes.bool.isRequired,
    contractId: PropTypes.string.isRequired,
    onHeadingChange: PropTypes.func.isRequired
};
