import {
    addContractsToCollection,
    addServicesToCollection,
    removeContractsFromCollection,
    removeServicesFromCollection
} from '../store/directusService';

/**
 * Function to populate a select with configured items
 * from a generic collection_contract in Directus.
 *
 * Return an array of configured items for a collection.
 *
 * @param {Object} collection - Object containing an array of objects holding collection details to be updated. e.g. The genderDetails object
 * @param {string} contractId - string containing the contract id
 */

export function getConfiguredItems(collection, contractId) {
    if (!Array.isArray(collection)) {
        console.log('THIS SHOULD BE AN ARRAY!', collection, contractId);
        return;
    }
    if (!collection.length) {
        console.log('THIS ARRAY IS EMPTY!', collection, contractId);
        return;
    }

    return collection
        .filter((el) => el.contract?.find((entry) => entry.contract_id?.id === contractId))
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
}

/**
 * Function to retrieve referral sources with a service id
 * from a referral source collection for a contract in Directus.
 *
 * Return an array of referral sources for a contract that match a service id.
 *
 * @param {Object} collection - Object containing an array of objects holding referral sources for a contract
 * @param {string} serviceId - string containing the service id
 */

export function getReferralSourcesForServiceId(collection, serviceId) {
    return collection.filter((el) =>
        el.service?.find((entry) => entry.service_id?.id === serviceId)
    );
}

/**
 * Function to get the name from an array that matches an id
 */
export const getNameFromId = (arr, id) => arr.find((el) => el.id === id)?.name || '';

/**
 * Function to populate a multi option select with preselected ids obtained
 * from a generic collection_contract in Directus.
 *
 * Return an array of preselected ids.
 *
 * @param {string} name - string containing the name of the collection. e.g. 'gender'
 * Ensure that name if a valid collection in Directus or code below can throw a TypeError when trying to read the ID. So we display an error to the log
 * @param {Object} collection - Object containing an array of objects holding collection details to be updated. e.g. The genderDetails object
 * @param {string} contractId - string containing the contract id
 */

export const getPreSelects = (name, collection, contractId) =>
    collection.filter((el) =>
        el.contract?.find(
            (entry) => entry.contract_id?.id === contractId && entry[`${name}_id`]?.id === el.id
        )
    );

export const updatePreSelectedMultiItems = (name, collection, contractId) =>
    getPreSelects(name, collection, contractId).map((el) => el.id);

export const updatePreSelects = (name, collection, contractId) => {
    const preSelects = getPreSelects(name, collection, contractId);
    return { preSelects, preSelectIds: preSelects.map((el) => el.id) };
};

/**
 * Function to trawl through a collection array and add / delete items from directus
 * for the generic collection_contract for the contract id passed.
 *
 * Return a string of collection items updated totals from user selections.
 *
 * @param {string} urlName - string containing the name of the matching Directus url for the collection. e.g. 'document_type'
 * @param {Object} collection - Object containing an array of objects holding collection details to be updated. e.g. The genderDetails object
 * @param {array} selectedItems - array containing items selected by user
 * @param {string} contractId - string containing the contract id
 * @param {function} dispatch - function containing call to dispatch *
 * @param {string} collectionName - a key for the api call successMessage to relay to the components so the right one updates
 */

export function updateSelectedItems(
    urlName,
    collection,
    selectedItems,
    contractId,
    collectionName,
    dispatch
) {
    const toAdd = selectedItems
        .map((el) => collection?.find((entry) => entry.id === el))
        .filter((el) => !el?.contract?.find((entry) => entry?.contract_id?.id === contractId));

    const toRemove = collection
        .filter((el) => !selectedItems?.find((entry) => entry === el.id))
        .filter((el) => el?.contract?.find((entry) => entry?.contract_id?.id === contractId))
        .map((el) => el?.contract?.find((entry) => entry?.contract_id?.id === contractId)?.id);

    const genericRowAdded = toAdd.length;
    const genericRowDeleted = toRemove.length;
    const message = `Updating ${collectionName} collection: ${genericRowAdded} items are being added and ${genericRowDeleted} items are being removed.`;
    const addMessage = genericRowAdded > genericRowDeleted ? message : '';
    const deleteMessage = genericRowDeleted >= genericRowAdded ? message : '';

    if (toAdd.length > 0) {
        const additions = toAdd.map((el) => ({
            [`${urlName}_id`]: el.id,
            ['contract_id']: contractId
        }));
        dispatch(addContractsToCollection(urlName, contractId, additions, addMessage));
    }

    if (toRemove.length > 0) {
        dispatch(removeContractsFromCollection(urlName, toRemove, deleteMessage));
    }

    return toAdd.length + toRemove.length;
}

export function updateDirectusContractSelections(
    name,
    preSelectedIds,
    preSelections,
    selectedItems,
    contractId,
    submitMessage,
    dispatch
) {
    const toAdd = selectedItems.filter((el) => !preSelectedIds.includes(el));
    const toRemove = preSelections
        .filter((el) => !selectedItems?.includes(el.id))
        .map((el) => el.contract.find((entry) => entry.contract_id?.id === contractId).id);
    const rowsAdded = toAdd.length;
    const rowsDeleted = toRemove.length;
    const message = `${submitMessage} : ${rowsAdded} items are being added and ${rowsDeleted} items are being removed.`;

    if (toAdd.length > 0) {
        const additions = toAdd.map((el) => ({ [`${name}_id`]: el, ['contract_id']: contractId }));
        dispatch(addContractsToCollection(name, contractId, additions, message));
    }
    if (toRemove.length > 0) dispatch(removeContractsFromCollection(name, toRemove, message));
    const totalUpdates = toAdd.length + toRemove.length;
    return totalUpdates > 0;
}

export function addDirectusContractSelections(
    name,
    preSelectedIds,
    preSelections,
    selectedItems,
    contractId,
    submitMessage,
    dispatch
) {
    const toAdd = selectedItems.filter((el) => !preSelectedIds.includes(el));
    const message = `${submitMessage} : ${toAdd.length} items are being added`;
    if (toAdd.length > 0) {
        const additions = toAdd.map((el) => ({ [`${name}_id`]: el, ['contract_id']: contractId }));
        dispatch(addContractsToCollection(name, contractId, additions, message));
    }
    return toAdd.length > 0;
}

/**
 * Function to update additions and deletions to collection's services in Directus
 * @param {string} name - name of the collection. e.g. 'gender'
 * @param {Object} preSelectedIds - array of the ids already selected.
 * @param {Object} preSelections - array of the whole objects for the ids already selected.
 * @param {array} selectedItems - array ids selected by user
 * @param {string} serviceId - service id
 * @param {function} dispatch - dispatch fn
 * @param {string} submitMessage - return successMessage after api has been called
 **/
export function updateDirectusServiceSelections(
    name,
    preSelectedIds,
    preSelections,
    selectedItems,
    serviceId,
    submitMessage,
    dispatch
) {
    const toAdd = selectedItems.filter((el) => !preSelectedIds.includes(el));
    const toRemove = preSelections
        .filter((el) => !selectedItems?.includes(el.id))
        .map((el) => el.service.find((entry) => entry.service_id === serviceId).id);

    const rowsAdded = toAdd.length;
    const rowsDeleted = toRemove.length;
    const message = `${submitMessage} : ${rowsAdded} items are being added and ${rowsDeleted} items are being removed.`;

    if (toAdd.length > 0) {
        const additions = toAdd.map((el) => ({ [`${name}_id`]: el, ['service_id']: serviceId }));
        dispatch(addServicesToCollection(name, serviceId, additions, message));
    }

    if (toRemove.length > 0) dispatch(removeServicesFromCollection(name, toRemove, message));

    return rowsAdded + rowsDeleted;
}
