// This is a slice of store to keep the current state of fields for each  form.

import { createSlice } from '@reduxjs/toolkit';

const CREATE_CONTRACT_FORM_STATE = 'CREATE_CONTRACT_FORM_STATE';
const CREATE_ELIGIBILITY_GROUP_FORM_STATE = 'CREATE_ELIGIBILITY_GROUP_FORM_STATE';
const CREATE_PARTICIPANT_FORM_STATE = 'CREATE_PARTICIPANT_FORM_STATE';
const CREATE_REFERRAL_SOURCE_FORM_STATE = 'CREATE_REFERRAL_SOURCE_FORM_STATE';
const CREATE_SERVICE_FORM_STATE = 'CREATE_SERVICE_FORM_STATE';
const CREATE_TEAM_FORM_STATE = 'CREATE_TEAM_FORM_STATE';
const CREATE_USER_FORM_STATE = 'CREATE_USER_FORM_STATE';

const slice = createSlice({
    name: 'formsState',
    // The  store
    initialState: {
        // This has the values of form fields for each form
        successMessage: '',
        errorMessage: '',
        teamFormData: {},
        contractFormData: {},
        participantFormData: {},
        serviceFormData: {},
        userFormData: {},
        eligibilityGroupFormData: {},
        referralSourceFormData: {},
        // Whether to show sticky menus. Initially all are hidden (false).
        // These values are modified when
        // [1] The user selects options from the Navigations menus.
        // [2] Forms are visited using the forward-back buttons.
        showStickyMenus: { dashboard: false, participant: false, journey: false, calendar: false }
    },
    reducers: {
        // These update the store. They can use mutable code and Immer is used
        // to convert this to immutable code
        // This object maps actions to action handlers
        // This calls createAction and createReducer

        // Update contract form data
        contractFormUpdated: (store, action) => {
            store.contractFormData = action.payload;
            window.localStorage.setItem(CREATE_CONTRACT_FORM_STATE, JSON.stringify(action.payload));
        },

        // Update eligibility Group form data
        eligibilityGroupFormUpdated: (store, action) => {
            store.eligibilityGroupFormData = action.payload;
            window.localStorage.setItem(
                CREATE_ELIGIBILITY_GROUP_FORM_STATE,
                JSON.stringify(action.payload)
            );
        },

        // Update participant form data
        participantFormUpdated: (store, action) => {
            store.participantFormData = action.payload;
            window.localStorage.setItem(
                CREATE_PARTICIPANT_FORM_STATE,
                JSON.stringify(action.payload)
            );
        },

        // Update service form data
        serviceFormUpdated: (store, action) => {
            store.serviceFormData = action.payload;
            window.localStorage.setItem(CREATE_SERVICE_FORM_STATE, JSON.stringify(action.payload));
        },

        // Update team form data
        teamFormUpdated: (store, action) => {
            store.teamFormData = action.payload;
            // Save the data to local storage to survive closing app or F5 refresh
            window.localStorage.setItem(CREATE_TEAM_FORM_STATE, JSON.stringify(action.payload));
        },

        // Update team form data
        referralSourceFormUpdated: (store, action) => {
            store.referralSourceFormData = action.payload;
            // Save the data to local storage to survive closing app or F5 refresh
            window.localStorage.setItem(
                CREATE_REFERRAL_SOURCE_FORM_STATE,
                JSON.stringify(action.payload)
            );
        },

        // Update user form data
        userFormUpdated: (store, action) => {
            // Update store with new data but keep any old data.
            store.userFormData = { ...store.userFormData, ...action.payload };

            if (!store.userFormData.primaryService || !store.userFormData.primaryServiceId) {
                console.log('missing data...');
            }

            window.localStorage.setItem(CREATE_USER_FORM_STATE, JSON.stringify(store.userFormData));
        },

        successMessageSet: (store, action) => {
            store.successMessage = action.payload;
            store.errorMessage = '';
        },
        errorMessageSet: (store, action) => {
            store.errorMessage = action.payload;
            store.successMessage = '';
        },
        clearMessageSet: (store) => {
            store.errorMessage = '';
            store.successMessage = '';
        },
        dashboardStickyMenuShown: (store) => {
            store.showStickyMenus.dashboard = true;
            store.showStickyMenus.participant = false;
            store.showStickyMenus.journey = false;
            store.showStickyMenus.calendar = false;
        },
        participantStickyMenuShown: (store) => {
            store.showStickyMenus.dashboard = false;
            store.showStickyMenus.participant = true;
            store.showStickyMenus.journey = false;
            store.showStickyMenus.calendar = false;
        },
        stickyMenusHidden: (store) => {
            store.showStickyMenus.dashboard = false;
            store.showStickyMenus.participant = false;
            store.showStickyMenus.journey = false;
            store.showStickyMenus.calendar = false;
        }
    }
});

// ActionCreators. These are commands so 'createContract' (command) rather
// than 'contractCreated' (event that has happened)
export const updateContractForm = (data) => contractFormUpdated(data);
export const updateEligibilityGroupForm = (data) => eligibilityGroupFormUpdated(data);
export const updateParticipantForm = (data) => participantFormUpdated(data);
export const updateServiceForm = (data) => serviceFormUpdated(data);
export const updateReferralSourceForm = (data) => referralSourceFormUpdated(data);
export const updateTeamForm = (data) => teamFormUpdated(data);
export const updateUserForm = (data) => userFormUpdated(data);

export const setSuccessMessage = (message) => successMessageSet(message);
export const setErrorMessage = (message) => errorMessageSet(message);

// Show or hide sticky menus
export const showDashboardStickyMenu = () => dashboardStickyMenuShown();
export const showParticipantStickyMenu = () => participantStickyMenuShown();
export const hideStickyMenus = () => stickyMenusHidden();

// Put selectors for getting data from store here
// Selectors take state and return computed state

// Get contract form info.
// If this isn't in store (e.g. because app has restarted) check browser storage.
export const getContractFormState = (state) => {
    let data = state.entities.formsState.contractFormData;

    if (!data || !data.contractName) {
        // Any data in browser storage ?
        const browserData = JSON.parse(window.localStorage.getItem(CREATE_CONTRACT_FORM_STATE));

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

// Get eligibility form info.
// If this isn't in store (e.g. because app has restarted) check browser storage.
export const getEligibilityFormState = (state) => {
    let data = state.entities.formsState.eligibilityGroupFormData;

    if (!data || !data.name) {
        // Any data in browser storage ?
        const browserData = JSON.parse(
            window.localStorage.getItem(CREATE_ELIGIBILITY_GROUP_FORM_STATE)
        );

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

// Get participant form info.
// If this isn't in store (because app has restarted) check browser storage.
export const getParticipantFormState = (state) => {
    let data = state.entities.formsState.participantFormData;

    if (!data || !data.name) {
        // No current session data. Any data in browser storage ?
        const browserData = JSON.parse(window.localStorage.getItem(CREATE_PARTICIPANT_FORM_STATE));

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

// Get service form info.
// If this isn't in store (because app has restarted) check browser storage.
export const getServiceFormState = (state) => {
    let data = state.entities.formsState.serviceFormData;

    if (!data || !data.serviceName) {
        // Any data in browser storage ?
        const browserData = JSON.parse(window.localStorage.getItem(CREATE_SERVICE_FORM_STATE));

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

// Get team form info.
// If this isn't in store (e.g. because app has restarted) check browser storage.
export const getTeamFormState = (state) => {
    let data = state.entities.formsState.teamFormData;

    if (!data || !data.teamName) {
        // Any data in browser storage ?
        const browserData = JSON.parse(window.localStorage.getItem(CREATE_TEAM_FORM_STATE));

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

// Get team form info.
// If this isn't in store (e.g. because app has restarted) check browser storage.
export const getReferralSourceFormState = (state) => {
    let data = state.entities.formsState.referralSourceFormData;

    if (!data || !data.teamName) {
        // Any data in browser storage ?
        const browserData = JSON.parse(
            window.localStorage.getItem(CREATE_REFERRAL_SOURCE_FORM_STATE)
        );

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

// Get user form info.
// If this isn't in store (e.g. because app has restarted) check browser storage.
export const getUserFormState = (state) => {
    let data = state.entities.formsState.userFormData;

    if (!data || !data.payroll) {
        // Any data in browser storage ?
        const browserData = JSON.parse(window.localStorage.getItem(CREATE_USER_FORM_STATE));

        if (browserData !== null) {
            // Yes so use that.
            data = browserData;
        }
    }
    return data;
};

export const {
    contractFormUpdated,
    dashboardStickyMenuShown,
    errorMessageSet,
    clearMessageSet,
    eligibilityGroupFormUpdated,
    participantStickyMenuShown,
    stickyMenusHidden,
    successMessageSet,
    teamFormUpdated,
    referralSourceFormUpdated,
    participantFormUpdated,
    serviceFormUpdated,
    userFormUpdated
} = slice.actions;

export default slice.reducer;
