import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, TextField, Typography } from '@mui/material';

import {
    clearMaximumTravelDistance,
    clearTransportMethod,
    clearWorkConditionalityGroup,
    loadMaximumTravelDistanceDetails,
    loadTransportMethodDetails,
    loadWorkConditionalityGroupDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    loadParticipantWorkConditionality,
    updateParticipantWorkConditionality
} from '../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import * as validate from '../../../validation/validation';
import IconError from '../../IconError';
import BusyIndicator from '../../ui/BusyIndicator';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLMultiOptionPicker from '../../ui/pickers/DDLMultiOptionPicker';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import classes from './workRequirements.module.css';

const CHARACTER_LIMIT = 2000;
const MINIMUM_HOURS_GREATER_THAN_MAXIMUM_HOURS =
    'Minimum hours must be less or equal to maximum hours.';
const END_TIME_LESS_THAN_START_TIME = 'End time cannot be less than start time.';
const MINIMUM_HOURS_GREATER_THAN_FORTY_EIGHT = 'Minimum hours must be 48 or less.';
const MAXIMUM_HOURS_GREATER_THAN_FORTY_EIGHT = 'Maximum hours must be 48 or less.';
const ENTER_BOTH_MINIMUN_AND_MAXIMUM_HOURS_OR_NEITHER =
    'Enter both minimum and maximum hours or neither.';

const WorkRequirements = () => {
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    // This matches the payload sent to the backend
    const initialState = {
        id: null,
        groupId: null,
        minimumHoursPerWeek: null,
        maximumHoursPerWeek: null,
        weekdayStartTime: null,
        weekdayEndTime: null,
        saturdayStartTime: null,
        saturdayEndTime: null,
        sundayStartTime: null,
        sundayEndTime: null,
        workingHoursNotes: '',
        maximumTravelToWorkId: null,
        participantId: null,
        methodOfTransportIds: []
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [notes, setNotes] = useState('');
    const [isClearWorkConditionalityGroup, setIsClearWorkConditionalityGroup] = useState('0');
    const [isClearMaximumTravelToWork, setIsClearMaximumTravelToWork] = useState('1');
    const [isClearMethodOfTransport, setIsClearMethodOfTransport] = useState('2');

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { maximumTravelDistanceDetails, transportMethodDetails, workConditionalityGroupDetails } =
        useSelector((state) => state.entities.directusService);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    let dbWorkConditionality = useSelector(
        (state) => state.entities.participantService.currentParticipantWorkConditionality
    );
    const dispatch = useDispatch();

    // EVENT HANDLERS
    const handleWorkConditionalityChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, groupId: chosenId }));
    };

    const handleMinimumHoursPerWeekChange = (e) => {
        setNewEntry((prev) => ({ ...prev, minimumHoursPerWeek: e.target.value }));
    };

    const handleMaximumHoursPerWeekChange = (e) => {
        setNewEntry((prev) => ({ ...prev, maximumHoursPerWeek: e.target.value }));
    };

    const handleWeekdayStartTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            weekdayStartTime: e.target.value
        }));
    };

    const handleWeekdayEndTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            weekdayEndTime: e.target.value
        }));
    };

    const handleSaturdayStartTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            saturdayStartTime: e.target.value
        }));
    };

    const handleSaturdayEndTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            saturdayEndTime: e.target.value
        }));
    };

    const handleSundayStartTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            sundayStartTime: e.target.value
        }));
    };

    const handleSundayEndTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            sundayEndTime: e.target.value
        }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, workingHoursNotes: e.target.value }));
    };

    const handleMaximumTravelToWork = (chosenId) => {
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, maximumTravelToWorkId: chosenId }));
    };

    const handleAvailableMethodsOfTransport = (chosenIds) => {
        setNewEntry((prev) => ({ ...prev, methodOfTransportIds: chosenIds }));
    };

    // USE EFFECTS
    useEffect(() => {
        dbWorkConditionality = {};
        setNewEntry(initialState);
        dispatch(loadParticipantWorkConditionality(currentParticipant?.id));

        dispatch(clearWorkConditionalityGroup());
        setIsClearWorkConditionalityGroup(Math.random());
        dispatch(loadWorkConditionalityGroupDetails());

        dispatch(clearMaximumTravelDistance());
        setIsClearMaximumTravelToWork(Math.random());
        dispatch(loadMaximumTravelDistanceDetails());

        dispatch(clearTransportMethod());
        setIsClearMethodOfTransport(Math.random());
        dispatch(loadTransportMethodDetails());
    }, [currentParticipant.id]);

    useEffect(() => {
        if (Object.keys(dbWorkConditionality)?.length) {
            setNewEntry(dbWorkConditionality);
            if (dbWorkConditionality.workingHoursNotes)
                setNotes(dbWorkConditionality.workingHoursNotes);
        }
    }, [dbWorkConditionality]);

    // HELPER FNS
    function getWorkConditionalityName(nameId) {
        return workConditionalityGroupDetails.find((el) => el.id === nameId)?.name || '';
    }

    function getMaximumTravelToWorkName(nameId) {
        return maximumTravelDistanceDetails.find((el) => el.id === nameId)?.name || '';
    }

    // FORM SUBMIT
    const onSubmit = () => {
        let minHours;
        let maxHours;
        if (newEntry.minimumHoursPerWeek > '') minHours = parseInt(newEntry.minimumHoursPerWeek);
        if (newEntry.maximumHoursPerWeek > '') maxHours = parseInt(newEntry.maximumHoursPerWeek);
        if (minHours > 48) {
            dispatch(setErrorMessage(MINIMUM_HOURS_GREATER_THAN_FORTY_EIGHT));
        } else if (maxHours > 48) {
            dispatch(setErrorMessage(MAXIMUM_HOURS_GREATER_THAN_FORTY_EIGHT));
        } else if (minHours > maxHours) {
            dispatch(setErrorMessage(MINIMUM_HOURS_GREATER_THAN_MAXIMUM_HOURS));
        } else if (minHours && !maxHours) {
            dispatch(setErrorMessage(ENTER_BOTH_MINIMUN_AND_MAXIMUM_HOURS_OR_NEITHER));
        } else if (maxHours && !minHours) {
            dispatch(setErrorMessage(ENTER_BOTH_MINIMUN_AND_MAXIMUM_HOURS_OR_NEITHER));
        } else if (
            newEntry.weekdayStartTime &&
            newEntry.weekdayStartTime > newEntry.weekdayEndTime
        ) {
            dispatch(setErrorMessage(END_TIME_LESS_THAN_START_TIME));
        } else if (
            newEntry.saturdayStartTime &&
            newEntry.saturdayStartTime > newEntry.saturdayEndTime
        ) {
            dispatch(setErrorMessage(END_TIME_LESS_THAN_START_TIME));
        } else if (newEntry.sundayStartTime && newEntry.sundayStartTime > newEntry.sundayEndTime) {
            dispatch(setErrorMessage(END_TIME_LESS_THAN_START_TIME));
        } else {
            const payload = {
                ...newEntry,
                minimumHoursPerWeek: minHours || null,
                maximumHoursPerWeek: maxHours || null,
                participantId: currentParticipant.id
            };
            dispatch(updateParticipantWorkConditionality(payload));
        }
    };

    //  RENDER
    if (
        Object.keys(currentParticipant)?.length < 1 ||
        maximumTravelDistanceDetails.length < 1 ||
        transportMethodDetails.length < 1 ||
        workConditionalityGroupDetails.length < 1
    ) {
        return <BusyIndicator />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <DDLOptionPicker
                label={'Work Conditionality Group'}
                id={'workConditionality'}
                key={isClearWorkConditionalityGroup}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                chosenName={getWorkConditionalityName(dbWorkConditionality.groupId || '')}
                chosenId={dbWorkConditionality.groupId || ''}
                menuItems={workConditionalityGroupDetails}
                onChange={(chosenId) => handleWorkConditionalityChange(chosenId)}
            />
            <br />
            <br />
            <Typography variant="h5">Work Preferences</Typography>
            <br />
            <Typography variant="h6">Working Hours</Typography>
            <span>Hours Required Per Week</span>
            <div className={classes.minHoursSection}>
                <TextField
                    className={classes.minimumHoursPerWeek}
                    id={'minimumHoursPerWeek'}
                    disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                    size="small"
                    value={newEntry.minimumHoursPerWeek || ''}
                    placeholder={'Enter minimum'}
                    error={errors.minimumHoursPerWeek}
                    {...register('minimumHoursPerWeek')}
                    onChange={handleMinimumHoursPerWeekChange}
                />
                <TextField
                    id={'maximumHoursPerWeek'}
                    disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                    size="small"
                    value={newEntry.maximumHoursPerWeek || ''}
                    placeholder={'Enter maximum'}
                    error={errors.maximumHoursPerWeek}
                    {...register('maximumHoursPerWeek')}
                    onChange={handleMaximumHoursPerWeekChange}
                />
            </div>
            <IconError text={errors.minimumHoursPerWeek || errors.maximumHoursPerWeek || null} />
            <Typography variant="h6">Tell us about your preferred working hours</Typography>
            <span>
                These will be used as a guide to your ideal working hours. We’ll try our best, where
                possible, to match them.
            </span>
            <div className={classes.hoursSection}>
                <span>
                    Monday - Friday
                    <TextField
                        className={classes.hoursPerDay}
                        id="weekdayStartTime"
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        data-testid="weekdayStartTime"
                        type="time"
                        value={newEntry.weekdayStartTime || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleWeekdayStartTimeChange}
                    />
                    <TextField
                        id="weekdayEndTime"
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        data-testid="weekdayEndTime"
                        type="time"
                        value={newEntry.weekdayEndTime || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleWeekdayEndTimeChange}
                    />
                </span>
            </div>
            <div className={classes.hoursSection}>
                <span>
                    Saturday
                    <TextField
                        className={classes.hoursPerSaturday}
                        id="saturdayStartTime"
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        data-testid="saturdayStartTime"
                        type="time"
                        value={newEntry.saturdayStartTime || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleSaturdayStartTimeChange}
                    />
                    <TextField
                        id="saturdayEndTime"
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        data-testid="saturdayEndTime"
                        type="time"
                        value={newEntry.saturdayEndTime || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleSaturdayEndTimeChange}
                    />
                </span>
            </div>
            <div className={classes.hoursSection}>
                <span>
                    Sunday
                    <TextField
                        className={classes.hoursPerSunday}
                        id="sundayStartTime"
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        data-testid="sundayStartTime"
                        type="time"
                        value={newEntry.sundayStartTime || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleSundayStartTimeChange}
                    />
                    <TextField
                        id="sundayEndTime"
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        data-testid="sundayEndTime"
                        type="time"
                        value={newEntry.sundayEndTime || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleSundayEndTimeChange}
                    />
                </span>
            </div>
            <LabelledTextField
                label={'Working Hour Notes'}
                id={'notes'}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                multiline
                rows={8}
                value={notes}
                placeholder={'Add additional working hour details here'}
                counter={'true'}
                helperText={`${notes?.length || 0}` + '/' + CHARACTER_LIMIT}
                inputProps={{
                    maxLength: CHARACTER_LIMIT
                }}
                onChange={handleNotesChange}
            />
            <Typography variant="h5">Travel Overview</Typography>
            <br />
            <DDLOptionPicker
                label={'Maximum Travel To Work'}
                id={'maximumTravelToWork'}
                key={isClearMaximumTravelToWork}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                chosenName={getMaximumTravelToWorkName(
                    dbWorkConditionality.maximumTravelToWorkId || ''
                )}
                chosenId={dbWorkConditionality.maximumTravelToWorkId || ''}
                menuItems={maximumTravelDistanceDetails}
                onChange={(chosenId) => handleMaximumTravelToWork(chosenId)}
            />
            <br />
            <br />
            <DDLMultiOptionPicker
                heading={'Available method(s) of transport'}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                id="methodOfTransport"
                key={isClearMethodOfTransport}
                placeholder="Available Method of Transport"
                menuItems={transportMethodDetails || []}
                preSelectedIds={dbWorkConditionality.methodOfTransportIds}
                chosenIds={newEntry.methodOfTransportIds || ''}
                onChange={(chosenIds) => handleAvailableMethodsOfTransport(chosenIds)}
            />
            <br />
            <br />
            <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                data-testid="testIdHISubmitButton">
                Update Work Requirements
            </Button>
        </form>
    );
};

const validationSchema = Yup.object().shape({
    minimumHoursPerWeek: Yup.string()
        .nullable()
        .min(0, 'Minimum hours per week cannot be negative')
        .max(48.0, 'Minimum hours per week must be 48 or less')
        .matches(validate.PRICE_REGEXP, 'Invalid Hours'),
    maximumHoursPerWeek: Yup.string()
        .nullable()
        .min(0, 'Maximum hours per week cannot be negative')
        .max(48.0, 'Maximum hours per week must be 48 or less')
        .matches(validate.PRICE_REGEXP, 'Invalid Hours')
});

export default WorkRequirements;
