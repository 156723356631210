import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createBloodTest, updateBloodTest } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';

import { checkDate, checkTestValue, initialErrorState, validate } from './validateBloodTest';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateBloodTest = ({ onClose, roles, attendedModules, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        participantId: '',
        eventDate: new Date().toISOString().slice(0, 10),
        eventId: '',
        sourceId: '',
        testType: 'HbA1c',
        testValueHb: '',
        testValueFpg: '',
        testValueType: 'mmol/mol',
        testResult: '',
        linkedModuleId: null,
        event: '',
        source: '',
        testValue: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [modules, setModules] = useState([]);
    const [testTypeSwitch, setTestTypeSwitch] = useState(false);
    const [errors, setErrors] = useState(initialErrorState);
    const msg = `Participant blood test has been ${formType === 'create' ? 'added' : 'updated'}`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const bloodTestEvents = useSelector((state) => state.entities.directusService.bloodTestEvents);
    const bloodTestSources = useSelector(
        (state) => state.entities.directusService.bloodTestSources
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            linkedModule: row.linkedModule === 'z' ? '' : row.linkedModule,
            eventDate: row.displayDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
        setTestTypeSwitch(row.testType === 'FPG');
    }, [row]);

    useEffect(() => {
        let modules = attendedModules.map((el) => ({ ...el, name: el.title }));
        if (formType === 'edit') {
            if (row.linkedModuleId && !attendedModules.find((el) => el.id === row.linkedModuleId)) {
                modules = [...modules, { id: row.linkedModuleId, name: row.linkedModule }];
            }
        }
        setModules(modules);
    }, [attendedModules]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onTestValueChange = (value) => {
        clearError('testValue');
        let testResult;
        if (newEntry.testType === 'HbA1c') {
            if (value >= 31 && value <= 41) testResult = 'Normal';
            else if (value >= 42 && value <= 47) testResult = 'Pre-diabetes';
            else if (value >= 48 && value <= 119) testResult = 'Diabetes';
            else testResult = 'Invalid Value';
            setNewEntry((prev) => ({
                ...prev,
                testValue: value.replace(/[^\d]/g, '').slice(0, 3),
                testValueHb: value.replace(/[^\d]/g, '').slice(0, 3),
                testResult
            }));
        }
        if (newEntry.testType === 'FPG') {
            const maxChar = value >= 10 ? 5 : 4;
            if (value >= 2 && value <= 5.4) testResult = 'Normal';
            else if (value >= 5.5 && value <= 6.9) testResult = 'Pre-diabetes';
            else if (value >= 7.0 && value <= 13.0) testResult = 'Diabetes';
            else testResult = 'Invalid Value';
            setNewEntry((prev) => ({
                ...prev,
                testValue: value.replace(/[^\d*.?]/g, '').slice(0, maxChar),
                testValueFpg: value.replace(/[^\d*.?]/g, '').slice(0, maxChar),
                testResult
            }));
        }
        const newErrors = checkTestValue(value, errors, newEntry.testType);
        setErrors(newErrors);
    };

    const onDateChange = (date) => {
        clearError('eventDate');
        setNewEntry((prev) => ({ ...prev, eventDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onSwitchTestType = (e) => {
        const { checked } = e.target;
        setTestTypeSwitch(checked);
        const testType = checked ? 'FPG' : 'HbA1c';
        let testValueType = checked ? 'mmol/l' : 'mmol/mol';
        clearError('testValue');
        setNewEntry((prev) => ({
            ...prev,
            testType,
            testValueType,
            testValue: '',
            testResult: ''
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const {
            /* eslint-disable no-unused-vars */
            event,
            source,
            testValue,
            ...rest
        } = newEntry;
        const payload = { ...rest, participantId: currentParticipant?.id };
        formType === 'create'
            ? dispatch(createBloodTest(payload, msg))
            : dispatch(updateBloodTest(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>{formType === 'create' ? 'Add a New Blood Test' : 'Edit Blood Test'}</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                        <DDLOptionPicker
                            label={'Source'}
                            id={'sourceId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={bloodTestSources || []}
                            chosenName={newEntry.source}
                            chosenId={newEntry.sourceId}
                            onChange={(chosenId) => {
                                clearError('sourceId');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    sourceId: chosenId
                                }));
                            }}></DDLOptionPicker>
                        {errors.sourceId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.sourceId} />
                            </div>
                        )}
                    </div>

                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Event'}
                            id={'eventId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={bloodTestEvents || []}
                            chosenName={newEntry.event}
                            chosenId={newEntry.eventId}
                            onChange={(chosenId) => {
                                clearError('eventId');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    eventId: chosenId
                                }));
                            }}></DDLOptionPicker>
                        {errors.eventId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.eventId} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div>
                            <PolarisSwitchSet
                                id="heightOtherUnits"
                                label="Test Type"
                                leftLabel="HbA1c"
                                rightLabel="FPG"
                                checked={testTypeSwitch}
                                onSwitch={onSwitchTestType}
                                disabled={false}
                            />
                        </div>

                        <LabelledTextField
                            label="Test Value"
                            id={'testValue'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.testValue}
                            placeholder={'Enter test value'}
                            onChange={(e) => onTestValueChange(e.target.value)}
                        />

                        {errors.testValue.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.testValue} />
                            </div>
                        )}

                        <div className={form.alignDown}>
                            <DDLOptionPicker
                                label={'Linked Module'}
                                id={'linkedModule'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={modules || []}
                                chosenName={newEntry.linkedModule}
                                chosenId={newEntry.linkedModuleId}
                                onChange={(chosenId) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        linkedModuleId: chosenId
                                    }))
                                }></DDLOptionPicker>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.staticField}>
                            <div className={form.formLabel}>Test Value Type</div>
                            <div className={form.staticText}>{newEntry.testValueType}</div>
                        </div>
                        <div className={form.staticFieldAlignDown}>
                            <div className={form.formLabel}>Test Result</div>
                            <div className={form.staticText}>{newEntry.testResult}</div>
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={formType === 'create' ? 'Save' : 'Update'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateBloodTest;

CreateBloodTest.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    attendedModules: PropTypes.arrayOf(PropTypes.object),
    formType: PropTypes.string,
    row: PropTypes.object
};
