import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ActionPlanTable from './pdfContent/ActionPlanTable';
import AppointmentDetails from './pdfContent/AppointmentDetails';
import AssetRequestTable from './pdfContent/AssetRequestTable';
import BarrierTable from './pdfContent/BarrierTable';
import CourseTable from './pdfContent/CourseTable';
import FinancialRequestTable from './pdfContent/FinancialRequestTable';
import JobGoalTable from './pdfContent/JobGoalTable';
import QuestionnaireTable from './pdfContent/QuestionnaireTable';
import SignDetail from './pdfContent/SignDetail';
import VacancyTable from './pdfContent/VacancyTable';

import classes from '../../../commonStyles/pdf.module.css';

const PDFContent = ({
    adviserName = '-',
    currentParticipant,
    confirmationText = '-',
    adviserSignature = '-',
    participantSignature = '-',
    currentAppointment,
    appointment,
    nextAppointment,
    signatureDateTime = '-',
    onIsLoaded,
    participantsActiveAccordions
}) => {
    const [isFormLoaded, setIsFormLoaded] = useState({});

    useEffect(() => {
        let activeForms = Object.entries(participantsActiveAccordions)
            // eslint-disable-next-line
            .filter(([_, v]) => v)
            .reduce((acc, cur) => ({ ...acc, [cur[0]]: false }), {});
        if ('request' in activeForms) {
            // eslint-disable-next-line
            const { request, ...rest } = activeForms;
            activeForms = { ...rest, assetRequest: false, financialRequest: false };
        }

        setIsFormLoaded(activeForms);
    }, []);

    useEffect(() => {
        if (Object.keys(isFormLoaded).length > 0 && Object.values(isFormLoaded).every((el) => el))
            onIsLoaded();
    }, [isFormLoaded]);

    const onIsFormLoaded = (key) => setIsFormLoaded((prev) => ({ ...prev, [key]: true }));

    return (
        <>
            <div className={classes.section}>
                <AppointmentDetails
                    appointment={appointment}
                    currentAppointment={currentAppointment}
                    nextAppointment={nextAppointment}
                />
            </div>

            {participantsActiveAccordions.questionnaire && (
                <div className={classes.section}>
                    <QuestionnaireTable onIsLoaded={onIsFormLoaded} />
                </div>
            )}

            {participantsActiveAccordions.barriers && (
                <div className={classes.section}>
                    <BarrierTable onIsLoaded={onIsFormLoaded} />
                </div>
            )}

            {participantsActiveAccordions.actionPlan && (
                <div className={classes.section}>
                    <ActionPlanTable onIsLoaded={onIsFormLoaded} />
                </div>
            )}

            {participantsActiveAccordions.courses && (
                <div className={classes.section}>
                    <CourseTable
                        onIsLoaded={onIsFormLoaded}
                        currentParticipant={currentParticipant}
                    />
                </div>
            )}

            {participantsActiveAccordions.jobReadiness && (
                <div className={classes.section}>
                    <JobGoalTable
                        onIsLoaded={onIsFormLoaded}
                        currentParticipant={currentParticipant}
                    />
                </div>
            )}

            {participantsActiveAccordions.request && (
                <div className={classes.section}>
                    <FinancialRequestTable onIsLoaded={onIsFormLoaded} />
                </div>
            )}

            {participantsActiveAccordions.request && (
                <div className={classes.section}>
                    <AssetRequestTable onIsLoaded={onIsFormLoaded} />
                </div>
            )}

            {participantsActiveAccordions.vacancy && (
                <div className={classes.section}>
                    <VacancyTable
                        onIsLoaded={onIsFormLoaded}
                        currentParticipant={currentParticipant}
                    />
                </div>
            )}

            <div className={classes.section}>
                <h3>Appointment Notes</h3>
                <h4>Notes from this appointment</h4>
                <p className={classes.appointmentNotes}>
                    {currentAppointment.currentNotes || 'None'}
                </p>
            </div>

            <div className={classes.section}>
                <SignDetail
                    adviserName={adviserName}
                    confirmationText={confirmationText}
                    signatureDateTime={signatureDateTime}
                    currentParticipant={currentParticipant}
                    currentAppointment={currentAppointment}
                    participantSignature={participantSignature}
                    adviserSignature={adviserSignature}
                />
            </div>
        </>
    );
};

PDFContent.propTypes = {
    adviserName: PropTypes.string,
    currentParticipant: PropTypes.object,
    confirmationText: PropTypes.string,
    adviserSignature: PropTypes.string,
    participantSignature: PropTypes.string,
    currentAppointment: PropTypes.object,
    appointment: PropTypes.object,
    nextAppointment: PropTypes.object,
    signatureDateTime: PropTypes.string,
    onIsLoaded: PropTypes.func,
    participantsActiveAccordions: PropTypes.object
};

export default PDFContent;
