import { HTML_REGEX } from '../../../../validation/validation';

const MAX_SUBJECT_LENGTH = 100;
const MAX_DETAIL = 1048576;
const MIN_LENGTH = 5;
const ERROR_NO_TEXT = 'Please enter details';
const ERROR_NO_CONTACTMODE = 'Please enter a contact mode';
const ERROR_NO_SUBJECT = 'Please enter an email subject';
const ERROR_MIN_LENGTH = `Please enter at least ${MIN_LENGTH} characters`;
const ERROR_MAX_CONTENT_SIZE = 'Maximum size has been exceeded';
const ERROR_MAX_SUBJECT_SIZE = `Subject length must be less than ${MAX_SUBJECT_LENGTH} characters`;

export const initialErrorState = {
    details: { error: false, message: '' },
    subject: { error: false, message: '' },
    emailAddress: { error: false, message: '' },
    contactMode: { error: false, message: '' }
};

export const validate = (newEntry, errors, type) => {
    let isValid = true;
    let newErrors = errors;

    if (type === 'SMS') {
        if (!newEntry.contactMode) {
            newErrors = {
                ...newErrors,
                contactMode: { error: true, message: ERROR_NO_CONTACTMODE }
            };
            isValid = false;
        }
    }

    if (type === 'Email') {
        if (!newEntry.subject) {
            newErrors = {
                ...newErrors,
                subject: { error: true, message: ERROR_NO_SUBJECT }
            };
            isValid = false;
        } else if (newEntry.subject.trim().length < MIN_LENGTH) {
            newErrors = {
                ...newErrors,
                subject: { error: true, message: ERROR_MIN_LENGTH }
            };
            isValid = false;
        } else if (newEntry.subject.length > MAX_SUBJECT_LENGTH) {
            newErrors = {
                ...newErrors,
                subject: { error: true, message: ERROR_MAX_SUBJECT_SIZE }
            };
            isValid = false;
        } else {
            newErrors = { ...newErrors, subject: initialErrorState.subject };
        }
    }

    if (!newEntry.details) {
        newErrors = {
            ...newErrors,
            details: { error: true, message: ERROR_NO_TEXT }
        };
        isValid = false;
    } else if (newEntry.details.replace(HTML_REGEX, '').length < MIN_LENGTH) {
        newErrors = {
            ...newErrors,
            details: { error: true, message: ERROR_MIN_LENGTH }
        };
        isValid = false;
    } else {
        newErrors = { ...newErrors, details: initialErrorState.details };
    }

    if ((type === 'Letter' || type === 'Email') && new Blob([newEntry.details]).size > MAX_DETAIL) {
        newErrors = {
            ...newErrors,
            details: { error: true, message: ERROR_MAX_CONTENT_SIZE }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};
