import { compareUnorderedArrays } from '../../../utils/arrayUtils';

export const ERROR_NO_CONTRACT = 'Please select a contract';
export const ERROR_NO_DIFF = 'There is nothing to update';

export const initialErrorState = {
    contract: { error: false, message: '' },
    button: { error: false, message: '' }
};

export const validate = (contractId, initialHeadings, newHeadings) => {
    let newErrors = {};

    if (!contractId) {
        newErrors = {
            ...newErrors,
            contract: { error: true, message: ERROR_NO_CONTRACT }
        };
    }

    if (compareUnorderedArrays(Object.values(initialHeadings), Object.values(newHeadings))) {
        newErrors = {
            ...newErrors,
            button: { error: true, message: ERROR_NO_DIFF }
        };
    }

    return { newErrors };
};
