import React from 'react';
import PropTypes from 'prop-types';

import { reverseFormatDate } from '../../../utils/dateFunctions';

const StaticTimes = ({ startTime, endTime, date }) => {
    return (
        <>
            <div className="time-inputs">
                <div className="input-set start-input">
                    <label htmlFor="startTime">Start Time</label>
                    <div className="static">{startTime}</div>
                </div>
                <div className="input-set end-input">
                    <label htmlFor="endTime">End Time</label>
                    <div className="static">{endTime}</div>
                </div>
            </div>
            <div className="input date-input">
                <label htmlFor="date">Date</label>
                <div className="static">{reverseFormatDate(date)}</div>
            </div>
        </>
    );
};

export default StaticTimes;

StaticTimes.propTypes = {
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    date: PropTypes.string
};
