import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { loadCourseProvisions, loadCourses } from '../../../store/courseService';
import { selectBarrierDetails } from '../../../store/dataSelectors';
import {
    loadAllExternalCourseProviderDetails,
    loadBarrierNameDetails
} from '../../../store/directusService';
import { selectCurrentParticipantBarriers } from '../../../store/participantSelectors';
import { loadParticipantBarriers } from '../../../store/participantService';
import { POLARIS_WHITE } from '../../../themes/theme';
import CurrentCourseTable from '../../courses/currentCourse/CurrentCourseTable';
import CurrentProvisionTable from '../../courses/currentProvision/CurrentProvisionTable';
import FormHeader from '../../layout/FormHeader';
import DynamicSearch from '../../search/DynamicSearch';
import LoadingSpinner from '../../ui/LoadingSpinner';

import toolbarClasses from '../../courses/courseStyles/courseToolbar.module.css';

const Courses = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [searchTerm, setSearchTerm] = useState('');
    const [courseRows, setCourseRows] = useState([]);
    const [provisionRows, setProvisionRows] = useState([]);
    const [filteredCourseRows, setFilteredCourseRows] = useState([]);
    const [filteredProvisionRows, setFilteredProvisionRows] = useState([]);

    // STORE STATE
    // const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const courseProvisions = useSelector((state) => state.entities.courseService.courseProvisions);
    const externalCourseProviderDetails = useSelector(
        (state) => state.entities.directusService.externalCourseProviderDetails
    );
    const courses = useSelector((state) => state.entities.courseService.courses);
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const barrierNameDetails = useSelector(selectBarrierDetails);

    // USE EFFECTS
    useEffect(() => {
        if (!courseRows.length) {
            dispatch(loadCourses());
            if (
                !currentParticipantBarriers?.length ||
                currentParticipantBarriers?.participantId !== currentParticipant.id
            )
                dispatch(loadParticipantBarriers(currentParticipant.id));
        }
        if (barrierNameDetails?.length < 1) dispatch(loadBarrierNameDetails());
        if (!provisionRows.length) {
            dispatch(loadCourseProvisions());
            dispatch(loadAllExternalCourseProviderDetails());
        }
    }, []);

    useEffect(() => {
        if (!courses?.length) return;
        const currentCourses = courses
            .filter((el) => el.active)
            .filter((el) => el.serviceIds.includes(currentParticipant.serviceId))
            .filter((el) =>
                el.modules.some((entry) =>
                    entry.participants.some((pt) => pt.participantId === currentParticipant.id)
                )
            );

        setCourseRows(currentCourses);
        setFilteredCourseRows(currentCourses);
    }, [courses]);

    useEffect(() => {
        if (!courseProvisions?.length || !externalCourseProviderDetails?.length) return;

        const getProviderName = (nameId) => {
            return externalCourseProviderDetails.find((el) => el.id === nameId)?.name || '';
        };

        function getStatus(participants) {
            const index = participants.findIndex(
                (item) => item.participantId === currentParticipant.id
            );
            let statusId;
            if (index >= 0) statusId = participants[index].statusId;
            return statusId;
        }

        const currentProvisions = courseProvisions
            .filter((el) =>
                el.participants.some((pt) => pt.participantId === currentParticipant.id)
            )
            .map((el) => ({
                ...el,
                provisionName: el.title,
                providerName: getProviderName(el.nameId),
                statusId: getStatus(el.participants)
            }));
        setProvisionRows(currentProvisions);
        setFilteredProvisionRows(currentProvisions);
    }, [courseProvisions, externalCourseProviderDetails]);

    useEffect(() => {
        const filteredCourseRows = !searchTerm
            ? courseRows
            : courseRows.filter(
                  (el) =>
                      el.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      el.code?.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setFilteredCourseRows(filteredCourseRows);

        const filteredProvisionRows = !searchTerm
            ? provisionRows
            : provisionRows.filter(
                  (el) =>
                      el.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      el.code?.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setFilteredProvisionRows(filteredProvisionRows);
    }, [searchTerm]);

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    return (
        <div>
            <h4>Search available courses</h4>
            <div className={toolbarClasses.coursesToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter search term"
                />
            </div>

            <div>
                <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
                    <CardContent>
                        <div>
                            <FormHeader text={'Current Courses'}></FormHeader>

                            {filteredCourseRows.length < 1 ? (
                                <LoadingSpinner content="No current courses found" />
                            ) : (
                                <CurrentCourseTable
                                    rows={filteredCourseRows}
                                    nameLabel="Course Name"
                                />
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div>
                <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
                    <CardContent>
                        <div>
                            <FormHeader text={'Current Provisions'}></FormHeader>

                            {filteredProvisionRows.length < 1 ? (
                                <LoadingSpinner content="No current provisions found" />
                            ) : (
                                <CurrentProvisionTable rows={filteredProvisionRows} />
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default Courses;
