import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import OnDeleteIcon from '../../icons/OnDeleteIcon';
import {
    appointmentSlotDeleted,
    deleteAppointmentSlot,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointmentSlot
} from '../../store/calendarService';
import { selectAppointmentTypesForContracts, selectServices } from '../../store/dataSelectors';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadAttendanceDetails,
    loadServiceDetailsByService
} from '../../store/directusService';
import {
    createAppointmentAttendanceDetail,
    loadAppointmentAttendanceDetailsBySearch,
    searchParticipants
} from '../../store/participantService';
import { selectUserRoles, selectUsersById } from '../../store/userSelectors';
import { clearUsersBySearch, loadUserById, loadUsersBySearch } from '../../store/userService';
import { isTimePast } from '../../utils/dateFunctions';
import { UPDATE } from '../../utils/uiConstants';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import SingleSelect from '../formElements/SingleSelect';
import ParticipantsSelector from '../scheduling/components/ParticipantsSelector';
import StartSlotButtons from '../scheduling/components/StartSlotButtons';
import StaticTimes from '../scheduling/components/StaticTimes';
import { validateUpdates } from '../scheduling/utils/appointmentSchedulerUtils';
import Panel from '../ui/panel/Panel';

import '../scheduling/styles/appointmentSchedulerPanel.css';

const PT_LOAD_SIZE = 50;

const UserAppointmentSlotsEdit = ({
    usersCurrentAppointment,
    currentCalendarOwner,
    currentLocation
}) => {
    const dispatch = useDispatch();
    // LOCAL STATE

    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantIds: [],
        numberOfSlots: 1,
        appointmentType: '',
        service: '',
        location: ''
    };

    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const deleteRestrictedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [participants, setParticipants] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [addedParticipants, setAddedParticipants] = useState([]);
    const [isDisabledSlotAppointmentButtons, setIsDisabledSlotAppointmentButtons] = useState(false);
    const [isPast, setIsPast] = useState(false);
    const [statuses, setStatuses] = useState({});
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const users = useSelector((state) => state.entities.userService.usersBySearch);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersBySearchMetaData
    );
    const usersById = useSelector(selectUsersById);
    const { participantsSearch, participantsSearchMetaData, appointmentAttendanceDetails } =
        useSelector((state) => state.entities.participantService);
    const appointmentTypes = useSelector(selectAppointmentTypesForContracts);
    const services = useSelector(selectServices);
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
    };

    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    const getSelectedAdviserName = () => {
        const selectedAdviser = advisers.find((el) => el.id === newEntry.userId);
        return `${selectedAdviser?.firstName} ${selectedAdviser?.lastName}`;
    };
    // USE EFFECTS

    useEffect(() => {
        dispatch(clearUsersBySearch());
        if (!currentCalendarOwner || Object.keys(currentCalendarOwner).length < 1) return;

        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (usersCurrentAppointment?.id)
            dispatch(
                loadAppointmentAttendanceDetailsBySearch({
                    calendarServiceAppointmentIds: [usersCurrentAppointment.id]
                })
            );
        const { serviceId } = usersCurrentAppointment;

        if (appointmentTypes?.length < 1) {
            dispatch(loadAppointmentTypeDetailsByContractIds(currentCalendarOwner?.contractIds));
        }
        if (services?.length < 1 && serviceId) dispatch(loadServiceDetailsByService([serviceId]));
        if (participantsSearch?.length < 1 && serviceId) {
            dispatch(searchParticipants({ serviceId }, 0, PT_LOAD_SIZE));
        } else setParticipants(participantsSearch);

        const currentAppointmentType =
            appointmentTypes.find((el) => el.id === usersCurrentAppointment.typeId)?.name || '';
        const currentServiceName = services?.find((el) => el.id === serviceId)?.name || '';

        setNewEntry({
            ...usersCurrentAppointment,
            startTime: usersCurrentAppointment.startTime?.slice(0, 5),
            endTime: usersCurrentAppointment.endTime?.slice(0, 5),
            date: usersCurrentAppointment.date?.split('T')[0] || '',
            service: currentServiceName,
            location: currentLocation,
            appointmentType: currentAppointmentType
        });
        if (usersCurrentAppointment.userId) {
            const selectedAdviser = usersById[usersCurrentAppointment.userId];
            if (!selectedAdviser) {
                dispatch(loadUserById(usersCurrentAppointment.userId));
            } else {
                setAdvisersArray([selectedAdviser]);
            }
        }

        const canEdit = hasRole(acceptedRoles, roles);
        setIsDisabledSlotAppointmentButtons(!canEdit);
        setIsPast(
            isTimePast(`${usersCurrentAppointment.date}T${usersCurrentAppointment.startTime}`)
        );
    }, []);

    useEffect(() => {
        if (participantsSearch?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { serviceIds: [usersCurrentAppointment.serviceId] },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsSearch);
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (participants.length < 1) return;
        setFilteredParticipants(
            participants.filter((el) => el.serviceId === usersCurrentAppointment.serviceId)
        );
        if (usersCurrentAppointment.participantIds?.length) {
            const entries = usersCurrentAppointment.participantIds.map((el) =>
                participants.find((entry) => entry.id === el)
            );
            setAddedParticipants(entries);
        }
    }, [participants]);

    useEffect(() => {
        if (appointmentAttendanceDetails?.length < 1 || participants.length < 1) return;
        const statuses = participants.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.id]:
                    attendanceDetails.find(
                        (row) =>
                            row.id ===
                            appointmentAttendanceDetails.find(
                                (entry) => entry.participantId === cur.id
                            )?.attendanceId
                    )?.name || 'Not known'
            }),
            {}
        );
        setStatuses(statuses);
    }, [appointmentAttendanceDetails, participants]);

    useEffect(() => {
        if (!users.flat().length) return;
        let updatedAdvisers = users
            .flat()
            .filter(
                (el) =>
                    el.active &&
                    el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
            );
        if (!updatedAdvisers.length && users.flat().length < totalUsers) {
            onLoadMoreAdvisers();
            return;
        }
        if (!updatedAdvisers.some((el) => el.id === newEntry.userId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === newEntry.userId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (newEntry.userId && usersById[newEntry.userId]) {
            setAdvisersArray([usersById[newEntry.userId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (successMessage === `Appointment slot ${newEntry.appointmentType} has been updated`) {
            setIsUpdateButtonDisabled(false);
            if (addedParticipants.length) {
                setIsDisabledSlotAppointmentButtons(false);
            } else onPanelClose();
        }

        if (
            successMessage.includes(`Appointment slot ${newEntry.appointmentType} has been deleted`)
        ) {
            setIsUpdateButtonDisabled(false);
            dispatch(appointmentSlotDeleted(newEntry.id));
            onPanelClose();
        }
    }, [successMessage]);

    // HELPER FNS
    const onPanelClose = () => {
        clearForm();
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        setCurrentAppointment({});
    };

    const onUpdateAdviser = (chosenId) => {
        setNewEntry((prev) => ({
            ...prev,
            userId: chosenId
        }));
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onDelete = () => {
        dispatch(deleteAppointmentSlot(newEntry.userId, newEntry.appointmentType, newEntry.id));
    };

    const onAddParticipant = (entry) => {
        setAddedParticipants([...addedParticipants, entry]);
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onRemoveParticipant = (entries) => {
        setAddedParticipants(entries);
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onLoadMoreAdvisers = () => {
        if (!users.flat().length || users.flat().length < totalUsers) {
            dispatch(
                loadUsersBySearch(
                    { serviceIds: [usersCurrentAppointment.serviceId] },
                    !users.flat().length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const valid = validateUpdates(newEntry, dispatch);
        if (!valid) return;
        setIsUpdateButtonDisabled(true);
        if (valid) {
            const {
                /* eslint-disable no-unused-vars */
                appointmentType,
                service,
                location,
                ...rest
            } = newEntry;

            const participantIds = addedParticipants.map((el) => el.id);
            const payload = { ...rest, participantIds };
            dispatch(
                updateAppointmentSlot(
                    usersCurrentAppointment.userId,
                    newEntry.appointmentType,
                    payload
                )
            );
            const newParticipantIds = participantIds.filter(
                (el) => !newEntry.participantIds.includes(el)
            );
            newParticipantIds.forEach((el) => {
                const payload = {
                    participantId: el,
                    calendarServiceAppointmentId: newEntry.id
                };
                dispatch(createAppointmentAttendanceDetail(payload));
            });
            setNewEntry((prev) => ({
                ...prev,
                participantIds: [...newEntry.participantIds, ...newParticipantIds]
            }));
        }
    };

    // RENDER

    return filteredParticipants.length < 1 ? null : (
        <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
            <div className="delete-appointment">
                <OnDeleteIcon
                    onDelete={onDelete}
                    roles={roles}
                    acceptedRoles={deleteRestrictedRoles}
                    active={!!newEntry.id}
                />
            </div>
            <div className="appointment-scheduler">
                <form onSubmit={onSubmit}>
                    <div className="appointment-title static appointment-title-static">
                        {newEntry.appointmentType}
                        <span>
                            {addedParticipants.length} / {newEntry.numberOfSlots}
                        </span>
                    </div>

                    <div className="input-set">
                        <label htmlFor="service">Service</label>
                        <div className="static">{newEntry.service}</div>
                    </div>
                    {isPast ? (
                        <div className="input-set">
                            <label htmlFor="adviser">Adviser</label>
                            <div className="static">{getSelectedAdviserName()}</div>
                        </div>
                    ) : (
                        <div className="input-set">
                            <SingleSelect
                                id={'adviserId'}
                                label={'Adviser'}
                                placeholder="Select Adviser..."
                                disabled={!newEntry.service}
                                menuItems={advisers}
                                selectedId={newEntry.userId || ''}
                                selected={
                                    newEntry.userId
                                        ? advisers?.find((el) => el.id === newEntry.userId) || {}
                                        : {}
                                }
                                onChange={onUpdateAdviser}
                                onLoadMoreItems={onLoadMoreAdvisers}
                                moreItemsToLoad={!totalUsers || users.flat().length < totalUsers}
                            />
                        </div>
                    )}

                    <div className="input-set">
                        <label htmlFor="location">Location</label>
                        <div className="static">{newEntry.location}</div>
                    </div>
                    <StaticTimes
                        startTime={newEntry.startTime}
                        endTime={newEntry.endTime}
                        date={newEntry.date}
                    />

                    <div className="last-group">
                        <ParticipantsSelector
                            participants={filteredParticipants}
                            add={(entry) => onAddParticipant(entry)}
                            remove={(entries) => onRemoveParticipant(entries)}
                            added={addedParticipants}
                            numOfSlots={newEntry.numberOfSlots}
                            isPast={isPast}
                            statuses={statuses}
                        />

                        <div className="appointmentSlotSaveBtn">
                            <Button
                                type="submit"
                                disabled={isPast || isUpdateButtonDisabled}
                                color="primary"
                                variant="contained"
                                data-testid="testIdSubmitButton">
                                {UPDATE}
                            </Button>
                        </div>
                    </div>
                </form>

                <div className="startSlotButtonWrapper">
                    <StartSlotButtons
                        participants={addedParticipants}
                        appointment={newEntry}
                        disabled={isDisabledSlotAppointmentButtons}
                        onPanelClose={onPanelClose}
                        statuses={statuses}
                    />
                </div>
            </div>
        </Panel>
    );
};

export default UserAppointmentSlotsEdit;

UserAppointmentSlotsEdit.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object,
    currentLocation: PropTypes.string
};
