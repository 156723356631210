import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { downloadCsv } from '../../utils/downloadCSV';

import modal from '../../commonStyles/modal.module.css';
import local from './uploaders.module.css';

const UploaderResults = ({ fileName, failures, onClose, fields = 'RowNumber,Error' }) => {
    const onExport = () => {
        const name = `${fileName}_${format(new Date(), `dd_MMM_yyyy_HH_mm`)}`;
        const values = failures
            ?.filter((el) => 'errors' in el)
            ?.map((el) => el.errors.map((entry) => [el?.rowNumber, entry]))
            .flat();
        downloadCsv(fields, values, name);
    };

    return (
        <>
            <div className={`${modal.modalUploaderContent} ${local.results}`}>
                <div className={local.header}>Upload Results</div>
                <div className={local.errors}>
                    <span>Failures reported: </span>
                    <span> {failures.length} </span>
                </div>
            </div>
            <div className={modal.actionButtons}>
                {failures?.length > 0 && (
                    <Button onClick={onExport} color="primary" variant="contained">
                        Export Report
                    </Button>
                )}
                <div className={modal.cancelButton}>
                    <Button variant={'contained'} onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </>
    );
};

export default UploaderResults;

UploaderResults.propTypes = {
    fileName: PropTypes.string,
    failures: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    fields: PropTypes.string
};
