import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { getNameFromId } from '../../../../utils/directusFunctions';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import dropdownClasses from '../../recruitmentStyles/rowDropDown.module.css';

const RejectionDetails = ({ row, onClose }) => {
    // LOCAL STATE
    const [reasonNames, setReasonNames] = useState([]);

    // STORE STATE
    const { poolAcceptedRejectionReasonsDetails, poolRequestedRejectionReasonsDetails } =
        useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    useEffect(() => {
        if (row?.reasons === '') return;
        let reasonNames = row?.reasons.map((el) =>
            getNameFromId(poolAcceptedRejectionReasonsDetails, el)
        );
        if (reasonNames[0] === '')
            reasonNames = row?.reasons.map((el) =>
                getNameFromId(poolRequestedRejectionReasonsDetails, el)
            );
        setReasonNames(reasonNames);
    }, []);

    return !row ? (
        <LoadingSpinner content="No rejection information found" />
    ) : (
        <div className={dropdownClasses.rowDropdown}>
            <Typography variant="h5">
                {row.ptCode} ({row.ptName})
            </Typography>
            <p></p>
            <Typography variant="h6">Rejection Reasons</Typography>
            <LabelledTextField
                id={'reasons'}
                disabled={true}
                value={reasonNames.join(', ')}
                rows={3}
            />
            <Typography variant="h6">Rejection Feedback</Typography>
            <LabelledTextField id={'notes'} disabled={true} value={row?.notes || ''} rows={4} />
            <div className={dropdownClasses.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default RejectionDetails;

RejectionDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
