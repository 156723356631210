import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import {
    loadAttendanceDetails,
    loadBarrierNameDetails,
    loadJobCentreDetails,
    loadJobReadinessDetails
} from '../../../store/directusService';
import { selectCurrentForm, selectCurrentParticipant } from '../../../store/participantSelectors';
import {
    createFormParticipant,
    loadAppointmentAttendanceDetails,
    loadForm,
    loadParticipantBarriers,
    loadParticipantEmployability
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { selectCurrentUser } from '../../../store/userSelectors';
import { loadUserForForms, loadUsersForForms } from '../../../store/userService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import { removeNonValuesFromObject } from '../../../utils/objectUtils';
import GenerateFormPDF from '../../pdfGenerator/GenerateFormPDF';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { themeJson } from './FormTheme';

import form from '../../../commonStyles/formStyles.module.css';

const ConductExitForm = ({ formId, onClose }) => {
    //HOOKS
    const dispatch = useDispatch();

    //LOCAL STATE
    const [surveyModel, setSurveyModel] = useState(new Model('{}'));
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [userName, setUserName] = useState('');
    const [generatePdf, setGeneratePdf] = useState(false);
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [populatedUserName, setPopulatedUserName] = useState(false);

    //STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentForm = useSelector(selectCurrentForm);
    const formStatusDetails = useSelector(
        (state) => state.entities.directusService.formStatusDetails
    );
    const currentUser = useSelector(selectCurrentUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const {
        appointmentAttendanceDetails,
        currentParticipantBarriers,
        currentParticipantEmployability
    } = useSelector((state) => state.entities.participantService);
    const { attendanceDetails, barrierNameDetails, jobReadinessDetails, jobCentreDetails } =
        useSelector((state) => state.entities.directusService);
    const usersForForms = useSelector((state) => state.entities.userService.usersForForms);
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    const onSurveyComplete = () => {
        setSurveyComplete(true);
    };

    const onFormExit = () => {
        setSurveyComplete(false);
        onClose();
    };

    // USE EFFECTS
    useEffect(() => {
        attendanceDetails.length < 1 && dispatch(loadAttendanceDetails());
        barrierNameDetails.length < 1 && dispatch(loadBarrierNameDetails());
        jobReadinessDetails.length < 1 && dispatch(loadJobReadinessDetails());
        jobCentreDetails.length < 1 && dispatch(loadJobCentreDetails());
    }, []);

    useEffect(() => {
        if (!('serviceIds' in loggedInUser)) return;
        if (loggedInUser.serviceIds.length < 1) return;
        if (!usersForForms || usersForForms?.length < 1)
            dispatch(loadUsersForForms(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        dispatch(loadForm(formId));
    }, [formId]);

    useEffect(() => {
        if (usersForForms && usersForForms.length) {
            const user = usersForForms.find((el) => el.id === currentParticipant.userId);
            if (user) {
                setPopulatedUserName(true);
                setUserName(`${user.firstName} ${user.lastName}`);
            } else dispatch(loadUserForForms(currentParticipant.userId));
        } else dispatch(loadUserForForms(currentParticipant.userId));

        dispatch(loadAppointmentAttendanceDetails(currentParticipant.id));
        dispatch(loadParticipantBarriers(currentParticipant.id));
        dispatch(loadParticipantEmployability(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (
            Object.keys(currentForm).length < 1 ||
            Object.keys(appointmentAttendanceDetails)?.length < 1 ||
            Object.keys(currentParticipantBarriers)?.length < 1 ||
            Object.keys(currentParticipantEmployability)?.length < 1 ||
            attendanceDetails.length < 1 ||
            barrierNameDetails.length < 1 ||
            jobCentreDetails.length < 1 ||
            jobReadinessDetails.length < 1 ||
            formComplete ||
            !populatedUserName
        )
            return;

        const model = new Model(currentForm.data);
        model.applyTheme(themeJson);

        model.setValue('Q_1', currentParticipant?.lastName);

        const jobReadiness = getNameFromId(
            jobReadinessDetails,
            currentParticipantEmployability.jobReadinessId
        );

        if (jobReadiness === 'A' || jobReadiness === 'B') {
            model.setValue('Q_3', 'Job ready');
        } else {
            model.setValue('Q_3', 'Requires further support');
        }

        const attendanceId = attendanceDetails.find((entry) => entry.name === 'Attended')?.id;
        const attendedAppointments = appointmentAttendanceDetails.filter(
            (el) => attendanceId === el.attendanceId
        );
        const appointmentPercentage =
            (attendedAppointments.length / appointmentAttendanceDetails.length) * 100;
        model.setValue('Q_4', `Attendance rate : ${parseInt(appointmentPercentage)}%`);

        const barriers = currentParticipantBarriers.map((el) => ({
            barrierName: getNameFromId(barrierNameDetails, el.barrierId),
            priority: el.priority,
            startDate: reverseFormatDate(el.startDate),
            endDate: reverseFormatDate(el.dueDate)
        }));
        const barrierString = (el) => {
            return `
            Barrier name: ${el.barrierName}
            Priority: ${el.priority}
            Start date: ${el.startDate}
            End date: ${el.endDate}
            `;
        };
        const barrierStrings = barriers.map((el) => barrierString(el)).join('\n');
        model.setValue('Q_5', barrierStrings);
        model.setValue('Q_10', userName);
        model.setValue('Q_11', currentParticipant?.primaryPhoneNumber);
        model.setValue('Q_12', currentParticipant?.emailAddress);

        const getAddressFromCurrentParticipant = (({
            address1,
            address2,
            address3,
            city,
            postcode
        }) => ({
            address1,
            address2,
            address3,
            city,
            postcode
        }))(currentParticipant);
        const formattedAddress = removeNonValuesFromObject(getAddressFromCurrentParticipant);
        model.setValue('Q_14', Object.values(formattedAddress).join('\n'));

        const jobCentre = jobCentreDetails.find(
            (entry) => currentParticipantEmployability.jobCentreId === entry.id
        );
        const getNameAndAddressFromJobCentre = (({
            name,
            address1,
            address2,
            address3,
            address4,
            city,
            county
        }) => ({
            name,
            address1,
            address2,
            address3,
            address4,
            city,
            county
        }))(jobCentre);
        const addressAndPostcode = {
            ...getNameAndAddressFromJobCentre,
            postcode: `${jobCentre.post_out_code} ${jobCentre.post_in_code}`
        };
        const formattedJobCentreAddress = removeNonValuesFromObject(addressAndPostcode);
        model.setValue('Q_15', Object.values(formattedJobCentreAddress).join('\n'));

        model.onComplete.add((sender, options) => {
            console.log('options', options);
            console.log(JSON.stringify(sender.data, null, 3));
        });

        setSurveyModel(model);
        setFormComplete(true);
    }, [
        currentForm,
        appointmentAttendanceDetails,
        attendanceDetails,
        barrierNameDetails,
        currentParticipantBarriers,
        currentParticipantEmployability,
        jobCentreDetails,
        jobReadinessDetails,
        populatedUserName
    ]);

    useEffect(() => {
        if (surveyComplete) {
            const payload = {
                participantId: currentParticipant.id,
                statusId: formStatusDetails.find((el) => el.name === 'Open')?.id || '',
                pdfName: currentForm.name,
                formId: currentForm.id,
                data: surveyModel.data
                // data: Object.values(surveyModel.data)
                //     .map((el) => el.replaceAll('\n', ','))
                //     .join(',')
            };
            dispatch(createFormParticipant(payload));
        }
    }, [surveyComplete]);

    useEffect(() => {
        if (successMessage === `Users for forms loaded`) {
            setPopulatedUserName(true);
            const user = usersForForms.find((el) => el.id === currentParticipant.userId);
            user ? setUserName(`${user.firstName} ${user.lastName}`) : setUserName('');
        } else if (successMessage === `Form participant has been created successfully`)
            setGeneratePdf(true);
    }, [successMessage]);

    // EVENT HANDLER CALLBACKS
    const onPdfGenerated = (isPdfGenerated) => {
        setIsSucceeded(isPdfGenerated);
        setGeneratePdf(false);
        onFormExit();
    };

    // AWAITING CONTENT
    let content;
    if (Object.keys(currentForm)?.length < 1) content = 'No current form found';
    if (Object.keys(appointmentAttendanceDetails)?.length < 1)
        content = 'No appointment attendance details found';
    if (Object.keys(currentParticipantBarriers)?.length < 1)
        content = 'No current participant barriers found';
    if (Object.keys(currentParticipantEmployability)?.length < 1)
        content = 'No current participant employability found';
    if (attendanceDetails?.length < 1) content = 'No attendance details found';
    if (barrierNameDetails?.length < 1) content = 'No barrier name details found';
    if (jobCentreDetails?.length < 1) content = 'No job centre details found';
    if (jobReadinessDetails?.length < 1) content = 'No job readiness details found';

    if (
        Object.keys(currentForm).length < 1 ||
        Object.keys(appointmentAttendanceDetails)?.length < 1 ||
        Object.keys(currentParticipantBarriers)?.length < 1 ||
        Object.keys(currentParticipantEmployability)?.length < 1 ||
        attendanceDetails.length < 1 ||
        barrierNameDetails.length < 1 ||
        jobCentreDetails.length < 1 ||
        jobReadinessDetails.length < 1 ||
        !formComplete ||
        !populatedUserName
    )
        return <LoadingSpinner content={content} />;

    // RENDER
    return (
        <div>
            <div>
                {surveyModel ? (
                    <Survey model={surveyModel} onComplete={onSurveyComplete} />
                ) : (
                    <div>
                        <LoadingSpinner />
                    </div>
                )}
            </div>
            {generatePdf && (
                <GenerateFormPDF
                    surveyModel={surveyModel}
                    form={currentForm}
                    row={currentForm}
                    currentParticipant={currentParticipant}
                    currentUser={currentUser}
                    onPdfGenerated={onPdfGenerated}
                />
            )}
            {isSucceeded && (
                <div className={form.successMessage}>PDF {currentForm.name} downloaded</div>
            )}
        </div>
    );
};

ConductExitForm.propTypes = {
    formId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default ConductExitForm;
