import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { createPool } from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import DDLMultiOptionPicker from '../../../ui/pickers/DDLMultiOptionPicker';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import classes from '../../recruitmentStyles/pool.module.css';

const CHARACTER_LIMIT = 750;

const PoolEditor = ({
    open,
    onPoolEditor,
    jobSectorDetails,
    selectedPoolOwner,
    poolOwners,
    teamDetails
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        id: null,
        jobSectorId: null,
        ownerId: '',
        teamIds: null,
        requirements: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [isOpen, setIsOpen] = React.useState(false);
    const [requirements, setRequirements] = useState('');
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    const [clearSelectedPoolOwner, setClearSelectedPoolOwner] = useState('0');
    const [clearSelectedJobSector, setClearSelectedJobSector] = useState('1');
    const [clearSelectedTeams, setClearSelectedTeams] = useState('2');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    const handleJobSectorChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, jobSectorId: chosenId }));
    };

    const handleTeamChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('teamIds', chosenIds, { shouldValidate: true });
        setNewEntry((prev) => ({ ...prev, teamIds: chosenIds }));
    };

    const handleRequirementsChange = (e) => {
        setRequirements(e.target.value);
        setNewEntry((prev) => ({ ...prev, requirements: e.target.value }));
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        onPoolEditor(false);
        clearData();
    };

    const clearData = () => {
        setNewEntry(initialState);
        setRequirements('');
        setClearSelectedPoolOwner(Math.random());
        setClearSelectedJobSector(Math.random());
        setClearSelectedTeams(Math.random());
    };

    const onCreated = () => {
        onPoolEditor(false);
        clearData();
    };

    // HELPER FNS
    const getOwnerName = (id) => {
        if (!id) {
            return '';
        }
        return poolOwners.find((item) => item.id === id)?.name || '';
    };

    // USE EFFECTS
    useEffect(() => {
        setClearSelectedTeams(Math.random());
        setClearSelectedPoolOwner(Math.random());
        setClearSelectedJobSector(Math.random());
        setClearSelectedTeams(Math.random());
    }, []);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Pool has been added`) {
            setIsUpdateButtonDisabled(false);
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        setIsUpdateButtonDisabled(true);
        setValue('requirements', requirements, { shouldValidate: true });
        const payload = newEntry;
        payload.ownerId = selectedPoolOwner;
        dispatch(createPool(payload));
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <div className={classes.poolDialogWindow}>
                <Dialog
                    sx={{ alignSelf: 'centre', width: '450px', maxWidth: '450px' }}
                    open={isOpen}
                    onClose={handleClickOutside}
                    aria-labelledby="form-dialog-title">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle id="form-dialog-title">Add Pool</DialogTitle>
                        <DialogContent data-testid="dialog-content">
                            <DDLOptionPicker
                                label={'Pool Owner'}
                                id={'poolOwnerId'}
                                key={clearSelectedPoolOwner}
                                disabled={true}
                                menuItems={poolOwners}
                                chosenName={getOwnerName(selectedPoolOwner)}
                                chosenId={selectedPoolOwner}
                            />
                            <DDLOptionPicker
                                label={'Job Sector'}
                                id={'jobSectorId'}
                                key={clearSelectedJobSector}
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={jobSectorDetails}
                                error={errors.jobSectorId}
                                {...register('jobSectorId')}
                                onChange={(e) => {
                                    if (e !== null && e !== undefined) {
                                        handleJobSectorChange(e);
                                    }
                                }}
                            />
                            <DDLMultiOptionPicker
                                label="Teams"
                                id="teamIds"
                                key={clearSelectedTeams}
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                placeholder="Select Teams"
                                limit={1}
                                menuItems={teamDetails || []}
                                chosenIds={teamDetails || []}
                                error={errors.teamIds}
                                {...register('teamIds')}
                                onChange={(chosenIds) => handleTeamChange(chosenIds)}
                            />
                            <LabelledTextField
                                label={'Pool Requirements'}
                                id={'requirements'}
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                multiline
                                rows={5}
                                value={requirements}
                                placeholder={'Enter pool requirements'}
                                counter={'true'}
                                helperText={`${requirements.length}` + '/' + CHARACTER_LIMIT}
                                inputProps={{
                                    maxLength: CHARACTER_LIMIT
                                }}
                                error={errors.requirements}
                                {...register('requirements')}
                                onChange={handleRequirementsChange}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) ||
                                    isUpdateButtonDisabled
                                }>
                                Update
                            </Button>
                            <Button onClick={handleCancel} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    jobSectorId: Yup.string().required('Please select a job sector.'),
    teamIds: Yup.array().min(1, 'Please select at least one team.').nullable(),
    requirements: Yup.string().trim().min(15, 'Must be at least fifteen characters.')
});

PoolEditor.propTypes = {
    onPoolEditor: PropTypes.func,
    open: PropTypes.bool.isRequired,
    jobSectorDetails: PropTypes.array.isRequired,
    selectedPoolOwner: PropTypes.string.isRequired,
    poolOwners: PropTypes.array.isRequired,
    teamDetails: PropTypes.array.isRequired
};

export default PoolEditor;
