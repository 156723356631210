import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { hideStickyMenus, showParticipantStickyMenu } from '../../store/formsState';
import { loadParticipantSectionHeadings } from '../../store/participantService';
import FormHeader from '../layout/FormHeader';
import ParticipantStickyMenu from '../navigation/participant/ParticipantStickyMenu';
import Education from '../participant/education/Education';
import Submissions from '../participant/submissions/Submissions';
import WorkHistory from '../participant/workHistory/WorkHistory';
import WorkRequirements from '../participant/workRequirements/WorkRequirements';
import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';

import AllActiveCourseAndProvisionManagement from './allActiveCourseAndProvision/AllActiveCourseAndProvisionManagement';

import classes from '../../app.module.css';

const CourseInformation = () => {
    //  LOCAL STATE
    const dispatch = useDispatch();

    const initialState = {
        courses: false,
        workRequirements: false,
        workHistory: false,
        education: false,
        submissions: false
    };
    const [isSection, setIsSection] = useState(initialState);

    const { currentParticipant, participantSectionHeadings } = useSelector(
        (state) => state.entities.participantService
    );

    //  USE EFFECTS
    useEffect(() => {
        const { firstName, lastName, contractId } = currentParticipant;
        dispatch(loadParticipantSectionHeadings(contractId, `${firstName} ${lastName}`));
        dispatch(showParticipantStickyMenu());

        return () => {
            dispatch(hideStickyMenus());
        };
    }, [currentParticipant]);

    /**
     * participantSectionHeadings has changed so will have to clear all accordion entries and
     * if the new participantSectionHeadings exists then repopulate said accordions.
     */
    useEffect(() => {
        let sections;
        if (Object.keys(participantSectionHeadings).length !== 0) {
            sections = (({ courses, workRequirements, workHistory, education, submissions }) => ({
                courses,
                workRequirements,
                workHistory,
                education,
                submissions
            }))(participantSectionHeadings);
        } else {
            sections = initialState;
        }
        setIsSection(sections);
    }, [participantSectionHeadings]);

    return (
        <Card data-testid="form_start">
            <CardContent>
                <div className={classes.withSideBar}>
                    <div style={{ flex: '1 1 auto' }}>
                        <div>
                            <FormHeader text={'Course, Work & Education'}></FormHeader>
                            <div hidden={!isSection.courses}>
                                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                    <AccordionSummary
                                        expandIcon={<AccordionIcon />}
                                        aria-controls="courses-content"
                                        id="courses-header">
                                        <AccordionHeader>Courses</AccordionHeader>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AllActiveCourseAndProvisionManagement />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div hidden={!isSection.workRequirements}>
                                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                    <AccordionSummary
                                        expandIcon={<AccordionIcon />}
                                        aria-controls="workRequirements-content"
                                        id="workRequirements-header">
                                        <AccordionHeader>Work Requirements</AccordionHeader>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <WorkRequirements />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div hidden={!isSection.workHistory}>
                                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                    <AccordionSummary
                                        expandIcon={<AccordionIcon />}
                                        aria-controls="workHistory-content"
                                        id="workHistory-header">
                                        <AccordionHeader>Work History</AccordionHeader>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <WorkHistory />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div hidden={!isSection.education}>
                                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                    <AccordionSummary
                                        expandIcon={<AccordionIcon />}
                                        aria-controls="education-content"
                                        id="education-header">
                                        <AccordionHeader>Education</AccordionHeader>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Education />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div hidden={!isSection.submissions}>
                                <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                    <AccordionSummary
                                        expandIcon={<AccordionIcon />}
                                        aria-controls="submissions-content"
                                        id="submissions-header">
                                        <AccordionHeader>Submissions</AccordionHeader>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Submissions />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className={classes.sideBar}>
                        <ParticipantStickyMenu />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default CourseInformation;
