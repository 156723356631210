import React, { useEffect } from 'react';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Card, CardContent } from '@mui/material';

import OnDeleteIcon from '../../icons/OnDeleteIcon';
import { loadAppointmentTypeDetails, loadAttendanceDetails } from '../../store/directusService';
import { selectUserRoles } from '../../store/userSelectors';
import { formatJustTime, formatTime } from '../../utils/dateFunctions';
import { getNameFromId } from '../../utils/directusFunctions';
import { removeHtmlTags } from '../../utils/removeHtmlTags';
import { hasRole, QUALITY, SUPERUSER } from '../../utils/userRoles';

import TimelineAppointmentEventHistoryManagement from './timelineAppointmentEventHistory/TimelineAppointmentEventHistoryManagement';
import TimelineEventHistoryManagement from './timelineEventHistory/TimelineEventHistoryManagement';
import TimelineRecruitmentEventHistoryManagement from './timelineRecruitmentEventHistory/TimelineRecruitmentEventHistoryManagement';

import timelineClasses from './timeLineStyles/timeLine.module.css';

const TimeLineView = ({ row, timelineTypeDetails, onDelete }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedDeleteRoles = [QUALITY, SUPERUSER];
    const {
        businessName,
        details,
        emailAddress,
        eventDate,
        notes,
        eventHistory,
        eventStatusId,
        eventTime,
        eventType,
        history,
        itemTypeId,
        location,
        loggedDate,
        loggedTime,
        recipient,
        subject,
        userId,
        vacancyName,
        vacancyStage
    } = row;
    const date = parse(eventDate, 'yyyy-MM-dd', new Date());
    let dateLogged;
    loggedDate ? (dateLogged = parse(loggedDate, 'yyyy-MM-dd', new Date())) : (dateLogged = '');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const users = useSelector((state) => state.entities.userService.users);
    const { attendanceDetails, appointmentTypeDetails } = useSelector(
        (state) => state.entities.directusService
    );

    //  USE EFFECTS
    useEffect(() => {
        appointmentTypeDetails?.length < 1 && dispatch(loadAppointmentTypeDetails());
        attendanceDetails?.length < 1 && dispatch(loadAttendanceDetails());
    }, []);

    return (
        <Card>
            <CardContent sx={{ padding: 1, backgroundColor: '#fff' }}>
                <div className={timelineClasses.timeLineItem}>
                    <span className={timelineClasses.time}>{format(date, 'dd MMM yy')}</span>

                    <div className={timelineClasses.details}>
                        <details>
                            <summary>
                                <div className={timelineClasses.summaryTop}>
                                    {getNameFromId(timelineTypeDetails, itemTypeId) ===
                                    'Appointment' ? (
                                        <span>
                                            {`${getNameFromId(
                                                timelineTypeDetails,
                                                itemTypeId
                                            )} - ${eventType}`}
                                        </span>
                                    ) : history &&
                                      eventType !== 'PARTICIPANT' &&
                                      eventType !== 'Communication' &&
                                      eventType !== 'Outbound Communication' &&
                                      getNameFromId(timelineTypeDetails, itemTypeId) !==
                                          'Course Module' ? (
                                        <span>{`${eventType} <${vacancyName} - ${businessName}>`}</span>
                                    ) : eventType === 'Outbound Communication' ? (
                                        <span>
                                            {`${eventType} - ${getNameFromId(
                                                timelineTypeDetails,
                                                itemTypeId
                                            )}`}
                                        </span>
                                    ) : eventHistory && eventType === 'PARTICIPANT' ? (
                                        <span>{eventType}</span>
                                    ) : getNameFromId(timelineTypeDetails, itemTypeId) ===
                                      'Course Module' ? (
                                        <span>{`${getNameFromId(
                                            timelineTypeDetails,
                                            itemTypeId
                                        )} - ${eventType}`}</span>
                                    ) : (
                                        <span>
                                            {`Manual - ${eventType} - ${getNameFromId(
                                                timelineTypeDetails,
                                                itemTypeId
                                            )}`}
                                        </span>
                                    )}
                                    <span>
                                        <sup>
                                            {eventType === 'PARTICIPANT' ||
                                            eventType === 'Communication'
                                                ? formatJustTime(eventTime)
                                                : history &&
                                                    eventType !== 'PARTICIPANT' &&
                                                    getNameFromId(
                                                        timelineTypeDetails,
                                                        itemTypeId
                                                    ) !== 'Appointment' &&
                                                    eventType !== 'Outbound Communication' &&
                                                    getNameFromId(
                                                        timelineTypeDetails,
                                                        itemTypeId
                                                    ) !== 'Course Module'
                                                  ? formatTime(eventTime)
                                                  : eventTime.length === 3
                                                    ? `0${eventTime.substring(
                                                          0,
                                                          1
                                                      )}:${eventTime.substring(1)}`
                                                    : `${eventTime.substring(
                                                          0,
                                                          2
                                                      )}:${eventTime.substring(2)}`}
                                        </sup>
                                        {getNameFromId(timelineTypeDetails, itemTypeId) ===
                                        'Appointment' ? (
                                            getNameFromId(
                                                attendanceDetails,
                                                history[0]?.newValue
                                            ) === 'Attended' ? (
                                                <CheckCircleIcon
                                                    className={timelineClasses.green}
                                                />
                                            ) : getNameFromId(
                                                  attendanceDetails,
                                                  history[0]?.newValue
                                              ) === 'Not known' ? (
                                                <ErrorIcon className={timelineClasses.blue} />
                                            ) : (
                                                <CancelIcon className={timelineClasses.red} />
                                            )
                                        ) : eventType !== 'PARTICIPANT' &&
                                          eventType !== 'Communication' &&
                                          eventType !== 'Outbound Communication' &&
                                          getNameFromId(timelineTypeDetails, itemTypeId) !==
                                              'Course Module' ? (
                                            vacancyStage === 'Accepted' ? (
                                                <CheckCircleIcon
                                                    className={timelineClasses.green}
                                                />
                                            ) : vacancyStage === 'Rejected' ||
                                              vacancyStage === 'Withdrawn' ? (
                                                <CancelIcon className={timelineClasses.red} />
                                            ) : (
                                                <ErrorIcon className={timelineClasses.blue} />
                                            )
                                        ) : getNameFromId(timelineTypeDetails, itemTypeId) ===
                                          'Course Module' ? (
                                            getNameFromId(attendanceDetails, eventStatusId) ===
                                            'Attended' ? (
                                                <CheckCircleIcon
                                                    className={timelineClasses.green}
                                                />
                                            ) : getNameFromId(attendanceDetails, eventStatusId) ===
                                              'Not known' ? (
                                                <ErrorIcon className={timelineClasses.blue} />
                                            ) : (
                                                <CancelIcon className={timelineClasses.red} />
                                            )
                                        ) : (
                                            <CheckCircleIcon className={timelineClasses.green} />
                                        )}
                                    </span>
                                </div>
                                <div className={timelineClasses.summaryBottom}>
                                    <sup className={timelineClasses.adviserName}>
                                        {emailAddress
                                            ? emailAddress
                                            : users.find((el) => el.id === userId)?.emailAddress ||
                                              ''}
                                    </sup>
                                    <sup className={timelineClasses.adviserName}>
                                        {getNameFromId(timelineTypeDetails, itemTypeId) ===
                                        'Email Sent'
                                            ? `Recipient: ${recipient}`
                                            : getNameFromId(timelineTypeDetails, itemTypeId) ===
                                                    'Course Module' ||
                                                getNameFromId(timelineTypeDetails, itemTypeId) ===
                                                    'Appointment'
                                              ? `Location: ${location ? location : ''}`
                                              : ''}
                                    </sup>

                                    {(history && eventType === 'PARTICIPANT') ||
                                    history ||
                                    getNameFromId(timelineTypeDetails, itemTypeId) ===
                                        'Appointment' ? (
                                        ''
                                    ) : (
                                        <sup>
                                            Logged: {format(dateLogged, 'dd MMM yy')}{' '}
                                            {loggedTime.length === 3
                                                ? `0${loggedTime.substring(
                                                      0,
                                                      1
                                                  )}:${loggedTime.substring(1)}`
                                                : `${loggedTime.substring(
                                                      0,
                                                      2
                                                  )}:${loggedTime.substring(2)}`}
                                        </sup>
                                    )}

                                    <span className={timelineClasses.manualEntry}>
                                        {row?.manuallyCreated && (
                                            <OnDeleteIcon
                                                onDelete={() => onDelete(row.id)}
                                                roles={roles}
                                                acceptedRoles={acceptedDeleteRoles}
                                                active={hasRole(acceptedDeleteRoles, roles)}
                                            />
                                        )}
                                    </span>
                                </div>
                                <div></div>
                            </summary>
                            <div className={timelineClasses.dropdown}>
                                {appointmentTypeDetails.some((el) => el.name === eventType) ? (
                                    <>
                                        <sup className={timelineClasses.sup}>Notes</sup>
                                        <p className={timelineClasses.appointment}>{notes}</p>
                                        <br />
                                        <sup>{`Attendance Status`}</sup>
                                        <span>
                                            <TimelineAppointmentEventHistoryManagement
                                                eventHistory={eventHistory}
                                            />
                                        </span>
                                    </>
                                ) : eventHistory &&
                                  eventType === 'PARTICIPANT' &&
                                  itemTypeId.startsWith('00000000') ? (
                                    <>
                                        <sup className={timelineClasses.sup}>{eventType}</sup>
                                        <span>
                                            <TimelineEventHistoryManagement
                                                eventHistory={eventHistory}
                                            />
                                        </span>
                                    </>
                                ) : history && eventType === 'Recruitment Activity' ? (
                                    <>
                                        <>
                                            <sup className={timelineClasses.sup}>Notes</sup>
                                            <p className={timelineClasses.rejection}>{notes}</p>
                                        </>
                                        <sup className={timelineClasses.sup}>
                                            {history[0].field}
                                        </sup>
                                        <span>
                                            <TimelineRecruitmentEventHistoryManagement
                                                eventHistory={history}
                                            />
                                        </span>
                                    </>
                                ) : eventType === 'Outbound Communication' ? (
                                    <>
                                        <sup className={timelineClasses.sup}>
                                            {getNameFromId(timelineTypeDetails, itemTypeId) ===
                                            'SMS Sent'
                                                ? subject === '-'
                                                    ? 'Subject: Ad Hoc SMS'
                                                    : `Subject: ${subject}`
                                                : ''}
                                        </sup>
                                        <br />
                                        <sup className={timelineClasses.sup}>Notes</sup>
                                        <p className={timelineClasses.appointment}>
                                            {removeHtmlTags(details)}
                                        </p>
                                    </>
                                ) : getNameFromId(timelineTypeDetails, itemTypeId) ===
                                  'Course Module' ? (
                                    <>
                                        <sup className={timelineClasses.sup}>
                                            Module Description
                                        </sup>
                                        <p className={timelineClasses.appointment}>{notes}</p>
                                        <br />
                                        <sup className={timelineClasses.sup}>
                                            {`Course Status: ${getNameFromId(
                                                attendanceDetails,
                                                eventStatusId
                                            )}`}
                                        </sup>
                                    </>
                                ) : (
                                    <>
                                        <sup className={timelineClasses.sup}>Message</sup>
                                        <p className={timelineClasses.appointment}>{notes}</p>
                                    </>
                                )}
                            </div>
                        </details>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

TimeLineView.propTypes = {
    row: PropTypes.object.isRequired,
    timelineTypeDetails: PropTypes.array.isRequired,
    onDelete: PropTypes.func
};

export default TimeLineView;
