import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROW_OPTIONS, DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';

import FullCourseAttendanceRow from './FullCourseAttendanceRow';

import classes from '../../../app.module.css';

/**
 * Table for managing course instances. See PPL-615 for details.
 * @param props.rows {Array} Array of course instance objects.
 * @param props.roles {Array} Array of roles for the current user.
 * Each row has
 * - id {string}
 * - ptCode {string}
 * - firstName {string}
 * - lastName {string}
 * - notes {icon}
 * Clicking on the ptCode icon for a row calls calls the Edit Partcicipant Page (once link has been clicked to show sensitive information)
 * @returns {JSX.Element}
 */

const headCells = [
    { id: 'id', numeric: false, label: 'Participant ID', align: 'left' },
    { id: 'firstName', numeric: false, label: 'First name', align: 'left' },
    { id: 'lastName', numeric: false, label: 'Last name', align: 'left' },
    { id: 'status', numeric: false, label: 'Status', align: 'center' }
];
const timeLimit = 30;
const FullCourseAttendanceTable = ({ rows, module, onUpdateParticipantDetail }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('courseName');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);
    const [hideInfo, setHideInfo] = useState(false);

    // USE EFFECTS

    useEffect(() => {
        const timer = setTimeout(() => {
            setHideInfo(false);
        }, timeLimit * 1000); // Convert timeLimit to milliseconds

        return () => {
            clearTimeout(timer);
        };
    }, [timeLimit, hideInfo]);

    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onRevealInfo = () => {
        setHideInfo(!hideInfo);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <br />
                    {!hideInfo && (
                        <div className={classes.center}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item xs={1}>
                                    <VisibilityOff sx={{ color: 'red' }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ color: 'red' }}
                                        xs={8}>
                                        The hidden information is of a sensitive nature. You must
                                        ensure that nobody else has the ability to see your screen
                                        before revealing it.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Link sx={{ cursor: 'pointer' }} onClick={onRevealInfo}>
                                Click here to reveal
                            </Link>
                        </div>
                    )}
                    <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sx={{ textAlign: `${headCell.align}` }}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => onSort(headCell.id)}>
                                            <Typography variant={'subtitle3'} color={'primary'}>
                                                {headCell.label}
                                            </Typography>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc'
                                                        ? 'sorted descending'
                                                        : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(rows, orderBy, order)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <FullCourseAttendanceRow
                                            key={row.id}
                                            row={row}
                                            module={module}
                                            hideInfo={hideInfo}
                                            onUpdateParticipantDetail={onUpdateParticipantDetail}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

FullCourseAttendanceTable.propTypes = {
    rows: PropTypes.array.isRequired,
    module: PropTypes.object,
    onUpdateParticipantDetail: PropTypes.func
};

export default FullCourseAttendanceTable;
