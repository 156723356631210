import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import form from '../../../../commonStyles/formStyles.module.css';
import expand from '../../../../commonStyles/rowExpand.module.css';

const BloodTestRowDetails = ({ row, onClose }) => {
    return (
        <div className={`${expand.rowDropdown} ${expand.whiteBg}`}>
            <div className={expand.content2}>
                <div className={form.fullWidth}>
                    <div className={form.fullWidthSection}>
                        <div className={form.staticField}>
                            <div className={form.formLabel}>Event</div>
                            <div className={`${form.staticText} ${form.textBox}`}>{row.event}</div>
                        </div>
                    </div>

                    <div className={form.fullWidthSection}>
                        <div className={form.staticField}>
                            <div className={form.formLabel}>Source</div>
                            <div className={`${form.staticText} ${form.textBox}`}>{row.source}</div>
                        </div>
                    </div>

                    <div className={form.fullWidthSection}>
                        <div className={form.staticField}>
                            <div className={form.formLabel}>Linked Module</div>
                            <div className={`${form.staticText} ${form.textBox}`}>
                                {row.linkedModule === 'z' ? '' : row.linkedModule}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={expand.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default BloodTestRowDetails;

BloodTestRowDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
