import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { loadCommunicationTemplates } from '../../../store/communicationService';
import { loadFormStatusDetails, loadFormTypes } from '../../../store/directusService';
import {
    selectCurrentParticipant,
    selectFormContracts,
    selectForms,
    selectFormsParticipant
} from '../../../store/participantSelectors';
import {
    loadFormContractByContract,
    loadForms,
    loadFormsParticipantByParticipantId
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { loadUser, loadUsersByServiceIds } from '../../../store/userService';
import { getNameFromId } from '../../../utils/directusFunctions';
import { hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import ConductForm from './ConductForm';
import EditForm from './EditForm';
import FormTable from './FormTable';

import classes from './formStyles/form.module.css';

const Form = () => {
    // HOOKS
    const surveyRef = useRef(null);
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [editForm, setEditForm] = useState(false);

    const [formId, setFormId] = useState('');
    const [formMenuItems, setFormMenuItems] = useState([]);
    const acceptedRoles = [MANAGER, QUALITY, SUPERUSER];

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const forms = useSelector(selectForms);
    const formsParticipant = useSelector(selectFormsParticipant);
    const formContracts = useSelector(selectFormContracts);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const formStatusDetails = useSelector(
        (state) => state.entities.directusService.formStatusDetails
    );

    // EVENT HANDLERS
    const handleFormIdChange = (formId) => {
        setFormId(formId);
    };

    const onStartForm = () => {
        setShowForm(true);
    };

    const onEditForm = (row) => {
        setSelectedRow(row);
        setEditForm(true);

        surveyRef.current.scrollIntoView();
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadCommunicationTemplates());
        dispatch(loadFormTypes());
        dispatch(loadFormStatusDetails());
        dispatch(loadForms());
        dispatch(loadFormContractByContract(currentParticipant.contractId));
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser)?.length < 1) return;
        dispatch(loadUser(loggedInUser.id));
        loggedInUser.serviceIds.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (showForm) return;
        setRows([]);
        if (currentParticipant?.id) {
            dispatch(loadFormsParticipantByParticipantId(currentParticipant.id));
        }
    }, [showForm, currentParticipant]);

    useEffect(() => {
        if (Object.keys(formsParticipant).length > 0 && formStatusDetails?.length > 0) {
            if (Object.keys(forms).length > 0) {
                setRows(
                    formsParticipant.map((el) => ({
                        ...el,
                        statusName: getNameFromId(formStatusDetails, el.statusId)
                    }))
                );
            }
        }

        if (Object.keys(formContracts).length < 1 || Object.keys(forms).length < 1) return;

        const formsForContracts = formContracts.filter((el) =>
            el.contractIds.some((entry) => entry.includes(currentParticipant.contractId))
        );

        setFormMenuItems(
            forms
                .filter((el) => !el.inactive)
                .filter((el) => formsForContracts.find((entry) => entry.name === el.name))
                .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        );
    }, [forms, formContracts, formsParticipant, formStatusDetails]);

    useEffect(() => {
        if (successMessage === 'Form results have been saved successfully') {
            dispatch(loadFormsParticipantByParticipantId(currentParticipant.id));
        }
    }, [successMessage]);

    // RENDER
    let content = '';
    if (formStatusDetails?.length < 1) content = 'No form status details';
    if (formStatusDetails?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <div className={classes.forms}>
            <div className={classes.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No forms found" />
                ) : (
                    <FormTable
                        rows={rows}
                        roles={loggedInUser.roles}
                        showForm={showForm}
                        editForm={editForm}
                        onEdit={onEditForm}
                    />
                )}
            </div>
            <div>
                <DDLOptionPicker
                    id="formId"
                    label={'Form'}
                    parentMessage={
                        (hasRole(acceptedRoles, loggedInUser.roles) &&
                            !formId &&
                            'Please select a form from the list above to start it') ||
                        ''
                    }
                    mandatory={true}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    data-testid="form_ddl_option_picker"
                    menuItems={formMenuItems}
                    chosenId={formId}
                    chosenName={forms.find((el) => el.id === formId)?.title}
                    onChange={handleFormIdChange}></DDLOptionPicker>
                <Button
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || !formId || showForm}
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onStartForm}>
                    Start Form
                </Button>
                <div ref={surveyRef}>
                    {showForm && (
                        <ConductForm
                            formId={formId}
                            onClose={() => setShowForm(false)}
                            roles={loggedInUser.roles}
                        />
                    )}
                    {editForm && (
                        <EditForm
                            row={selectedRow}
                            onClose={() => setEditForm(false)}
                            roles={loggedInUser.roles}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Form;
