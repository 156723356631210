import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnCreateIcon from '../../../../icons/OnCreateIcon';
import { setCurrentVacancy } from '../../../../store/recruitmentService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    ADVISER,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import JobOffersDetails from './JobOffersDetails';

import classes from '../../../../commonStyles/tables.module.css';

const JobOffersTableRow = ({ row, roles, toggleDropdown, openId, onPlacementEditor }) => {
    // HOOKS
    const dispatch = useDispatch();
    // LOCAL STATE
    const [open, setOpen] = useState(false);
    const [updatedRow, setUpdatedRow] = useState({});

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { jobSectorDetails } = useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    const onAddPlacement = (row) => {
        dispatch(setCurrentVacancy(row));
        onPlacementEditor(true);
    };

    // CALLBACKS
    const onToggleDropdown = (e) => {
        if (e.target.nodeName !== 'TD') return;
        toggleDropdown(row.id, e);
        setOpen(open === row.id ? null : row.id);
    };

    // USE EFFECTS
    useEffect(() => {
        setUpdatedRow(row);
    }, [row]);

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    useEffect(() => {
        if (successMessage === `Job placement has been added: ${row.id}`) {
            setUpdatedRow((prev) => ({ ...prev, placementFound: true }));
        }
    }, [successMessage]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.adviser}</TableCell>
                <TableCell>{getNameFromId(jobSectorDetails, row.sectorId)}</TableCell>
                <TableCell>{`£${row.payRateFrom} - £${row.payRateTo}`}</TableCell>
                <TableCell>{row.remote ? 'Remote' : row.postcode}</TableCell>
                <TableCell align="left">
                    <OnCreateIcon
                        onCreate={() => onAddPlacement(row)}
                        titleString={'Placement'}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={!updatedRow.placementFound}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={7}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <JobOffersDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default JobOffersTableRow;

JobOffersTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string,
    onPlacementEditor: PropTypes.func
};
