import { isDateFuture } from '../../../../utils/dateFunctions';

const MAX_MINUTES = 10060;
const BAD_DATE_ERROR = 'Date must be in the past or present';
const NO_DATE_ERROR = 'Please enter a date';
const NO_VALUE_ERROR = 'Please enter a value';
const MINUTES_ERROR = 'Value must be a whole, positive number';
const MAX_MINUTES_ERROR = `Value cannot be greater than ${MAX_MINUTES}`;

export const initialErrorState = {
    vigorousActivityMinutes: { error: false, message: '' },
    moderateActivityMinutes: { error: false, message: '' },
    walkingMinutes: { error: false, message: '' },
    eventDate: { error: false, message: '' }
};

export const checkDate = (eventDate, errors) => {
    if (!eventDate) {
        return {
            ...errors,
            eventDate: { error: true, message: NO_DATE_ERROR }
        };
    }
    return isDateFuture(eventDate)
        ? {
              ...errors,
              eventDate: { error: true, message: BAD_DATE_ERROR }
          }
        : {
              ...errors,
              eventDate: initialErrorState.eventDate
          };
};

export const checkValue = (key, value, errors) => {
    if (value === '') {
        return {
            ...errors,
            [key]: { error: true, message: NO_VALUE_ERROR }
        };
    } else if (value < 0 || !Number.isInteger(+value)) {
        return {
            ...errors,
            [key]: { error: true, message: MINUTES_ERROR }
        };
    } else if (value > MAX_MINUTES) {
        return {
            ...errors,
            [key]: { error: true, message: MAX_MINUTES_ERROR }
        };
    } else return { ...errors, [key]: initialErrorState[key] };
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;
    newErrors = checkValue('vigorousActivityMinutes', newEntry.vigorousActivityMinutes, newErrors);
    if (newErrors.vigorousActivityMinutes.error) isValid = false;

    newErrors = checkValue('moderateActivityMinutes', newEntry.moderateActivityMinutes, newErrors);
    if (newErrors.moderateActivityMinutes.error) isValid = false;

    newErrors = checkValue('walkingMinutes', newEntry.walkingMinutes, newErrors);
    if (newErrors.walkingMinutes.error) isValid = false;

    newErrors = checkDate(newEntry.eventDate, newErrors);
    if (newErrors.eventDate.error) isValid = false;

    return { isValid, newErrors };
};
