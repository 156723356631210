import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Autocomplete, TextField } from '@mui/material';

import { loadParticipantEmployability } from '../../../store/participantService';

const AppointmentParticipantSelect = ({
    participants,
    participant,
    onParticipantChange,
    disabled
}) => {
    const dispatch = useDispatch();
    const onChange = (_, val) => {
        if (!val) return;
        dispatch(loadParticipantEmployability(val.id));
        onParticipantChange({
            participantId: val.id,
            participant: `${val.firstName} ${val.lastName} - ${val.ptCode}`
        });
    };

    return (
        <div className="input-set">
            <label htmlFor="participant">Participant</label>

            <div className="selector-input input">
                <Autocomplete
                    disablePortal
                    id="participant"
                    key={Math.random().toString(36).slice(0, 5)}
                    disabled={disabled}
                    options={participants || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) =>
                        `${option?.firstName} ${option?.lastName} - ${option?.ptCode}`
                    }
                    renderInput={(params) => <TextField {...params} placeholder={participant} />}
                    classes={{ listbox: 'menu-list' }}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default AppointmentParticipantSelect;

AppointmentParticipantSelect.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    participant: PropTypes.string,
    onParticipantChange: PropTypes.func,
    disabled: PropTypes.bool
};
