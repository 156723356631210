import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PortraitIcon from '@mui/icons-material/Portrait';
import RadarIcon from '@mui/icons-material/Radar';
import TodayIcon from '@mui/icons-material/Today';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Card, CardContent, Checkbox, Typography } from '@mui/material';

import { loadLocation } from '../../../../store/directusService';
import { loadUser } from '../../../../store/userService';
import { POLARIS_GREY, SCREEN_SETTINGS } from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';

import attendanceClasses from '../cardStyles/cardAttendanceView.module.css';
import classes from '../cardStyles/cardView.module.css';

/**
 * Viewer for participant attendance register card
 *
 * @param module  a module within a course.
 *
 * @returns {JSX.Element}o
 *
 */

const ParticipantAttendanceRegisterView = ({ module }) => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const {
        title,
        description,
        locationId,
        date,
        startTime,
        endTime,
        userId,
        capacity,
        smartTarget,
        participants
    } = module;

    const [courseLocation, setCourseLocation] = useState('');
    const [adviser, setAdviser] = useState('');

    const users = useSelector((state) => state.entities.userService.users);
    const locations = useSelector((state) => state.entities.directusService.locationDetails);
    const addAdviser = (adviser) => {
        const { firstName, lastName } = adviser;
        setAdviser(`${firstName} ${lastName}`);
    };

    useEffect(() => {
        if (userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === userId);
                user ? addAdviser(user) : dispatch(loadUser(userId));
            } else dispatch(loadUser(userId));
        }
        if (locationId) {
            if (locations && locations.length) {
                const location = locations.find((el) => el.id === locationId);
                location ? setCourseLocation(location.name) : dispatch(loadLocation(locationId));
            } else dispatch(loadLocation(locationId));
        }
    }, []);

    useEffect(() => {
        if (users.length) {
            const user = users.find((el) => el.id === userId);
            user && addAdviser(user);
        }
    }, [users]);

    useEffect(() => {
        if (locations.length) {
            const location = locations.find((el) => el.id === locationId);
            location && setCourseLocation(location.name);
        }
    }, [locations]);

    // RENDER
    return (
        <div className={classes.cardView}>
            <Card
                elevation={3}
                sx={{
                    backgroundColor: POLARIS_GREY,
                    width: 250,
                    height: 'calc(100% - 16px)',
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <div className={`${title?.length > 60 ? classes.bannerDoubleRow : classes.banner}`}>
                    <Typography variant={'h6'}>{title || 'Participant Attendance'}</Typography>
                </div>
                <CardContent sx={{ padding: 1 }}>
                    <div>
                        <p>
                            <span className={classes.label}>Module Description: </span>
                            <span className={classes.description}>
                                {description && description.length > 100
                                    ? description.substring(0, 97) + '...'
                                    : description}
                            </span>
                        </p>
                        <section>
                            <LocationOnIcon />
                            {` ${courseLocation ? courseLocation : ' - '}`}
                        </section>
                        <section>
                            <TodayIcon />
                            {!date && !startTime && !endTime
                                ? 'Date and time here'
                                : ` ${date ? reverseFormatDate(date) : ''} ${
                                      startTime ? startTime : ''
                                  } ${endTime ? endTime : ''}`}
                        </section>
                        <section>
                            <PortraitIcon />
                            {adviser || ' - '}
                        </section>
                        <section>
                            <RadarIcon />
                            <span className={classes.smartTargetName}>
                                {smartTarget?.name || ' - '}
                            </span>
                        </section>
                        <label
                            hidden={
                                reverseFormatDate(date) + 'T' + startTime >=
                                format(new Date(), "yyyy/MM/dd'T'HH:mm")
                            }>
                            <Checkbox disabled={true} defaultChecked />
                            Module selected to attend
                        </label>
                        <br />
                        <div className={attendanceClasses.cardAttendanceView}>
                            <span className={attendanceClasses.spaces}>
                                <WorkspacesIcon />
                                {participants &&
                                    ` ${participants.length} of ${
                                        capacity ? capacity : ''
                                    } spaces filled`}
                                {!participants && ` 0 spaces filled`}
                            </span>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

ParticipantAttendanceRegisterView.propTypes = {
    module: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default ParticipantAttendanceRegisterView;
