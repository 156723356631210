(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("survey-core"), require("survey-creator-core"), require("survey-react-ui"));
	else if(typeof define === 'function' && define.amd)
		define("survey-creator-react", ["react", "react-dom", "survey-core", "survey-creator-core", "survey-react-ui"], factory);
	else if(typeof exports === 'object')
		exports["survey-creator-react"] = factory(require("react"), require("react-dom"), require("survey-core"), require("survey-creator-core"), require("survey-react-ui"));
	else
		root["SurveyCreator"] = factory(root["React"], root["ReactDOM"], root["Survey"], root["SurveyCreatorCore"], root["SurveyReact"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_survey_core__, __WEBPACK_EXTERNAL_MODULE_survey_creator_core__, __WEBPACK_EXTERNAL_MODULE_survey_react_ui__) => {
return 