import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../../store/directusService';
import {
    loadRecentParticipants,
    saveCurrentParticipantId,
    setCurrentlySelectedParticipant
} from '../../../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../../../commonStyles/tables.module.css';

const RemoveParticipantRow = ({ row, roles, type, onDeSelectRow, deSelectedRows }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setIsSelected(deSelectedRows.some((el) => el.id === row.id));
    }, [deSelectedRows]);

    const selectRow = () => {
        if (!hasRole(acceptedRoles, roles)) return;
        onDeSelectRow(row, isSelected);
        setIsSelected(!isSelected);
    };

    const onNav = () => {
        dispatch(setCurrentlySelectedParticipant(row));
        dispatch(loadContractDetailsForContractId(row.contractId));
        dispatch(loadServiceDetailsForContractId(row.contractId));
        navigate('/edit_participant');
        saveCurrentParticipantId(row.id);
        dispatch(loadRecentParticipants());
    };

    return (
        <TableRow
            hover
            className={`${classes.basicRowStripes} ${type === 'SMS' ? classes.rowHeight : ''}`}>
            <TableCell align="center" className={classes.rowLink} onClick={onNav}>
                {row.ptCode}
            </TableCell>
            <TableCell align="center">{row.firstName}</TableCell>
            <TableCell align="center">{row.lastName}</TableCell>
            <TableCell align="center">
                {type === 'SMS' ? (
                    <>{row.selectedContact.contactNumber}</>
                ) : (
                    <>{row.emailAddress === 'z' ? ' - ' : row.emailAddress}</>
                )}
            </TableCell>
            <TableCell align="center">
                {row.currentStatus === 'zz' ? ' - ' : row.currentStatus}
            </TableCell>
            <TableCell align="center">
                <div
                    className={`${form.crossBox} ${
                        !hasRole(acceptedRoles, roles) ? form.crossBoxDisabled : ''
                    } `}
                    onClick={selectRow}>
                    {isSelected ? 'X' : ''}
                </div>
            </TableCell>
        </TableRow>
    );
};

export default RemoveParticipantRow;

RemoveParticipantRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    onDeSelectRow: PropTypes.func,
    deSelectedRows: PropTypes.arrayOf(PropTypes.object)
};
