import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardContent } from '@mui/material';

import { POLARIS_WHITE } from '../../../themes/theme';
import LoadingSpinner from '../../ui/LoadingSpinner';

import TimelineRecruitmentEventHistoryTable from './TimelineRecruitmentEventHistoryTable';

const TimelineRecruitmentEventHistoryManagement = ({ eventHistory = [] }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);

    // USE EFFECTS
    useEffect(() => {
        if (!eventHistory?.length > 0) return;
        setRows(eventHistory);
    }, []);

    // RENDER
    return rows.length < 1 ? (
        <LoadingSpinner content="No timeline event history found" />
    ) : (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    {rows.length < 1 ? (
                        <LoadingSpinner content="No timeline event history found" />
                    ) : (
                        <TimelineRecruitmentEventHistoryTable rows={rows} />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

TimelineRecruitmentEventHistoryManagement.propTypes = {
    eventHistory: PropTypes.array
};

export default TimelineRecruitmentEventHistoryManagement;
