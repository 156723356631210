export const addFullNameToArray = (array) => {
    if (array.length < 1) return array;
    return array
        .filter((el) => el)
        .map((el) => ({
            ...el,
            name: `${el.firstName} ${el.lastName}`
        }));
};

export const addEmailAddressAsNameToArray = (array) => {
    if (array.length < 1) return array;
    return array.map((el) => ({
        ...el,
        name: el.emailAddress
    }));
};
