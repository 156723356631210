import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import {
    loadMaximumTravelDistanceDetails,
    loadParticipantStatusDetails,
    loadServiceDetailsByService
} from '../../store/directusService';
import { loadParticipantsAdvancedSearch } from '../../store/participantService';
import { selectLoggedInUser } from '../../store/userSelectors';
import { searchTeams, searchUsersByLoggedInUserServiceIds } from '../../store/userService';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import SingleSelect from '../formElements/SingleSelect';
import IconError from '../IconError';
import LabelledTextField from '../ui/editors/LabelledTextField';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';

import {
    checkForValues,
    initialErrorState,
    validate
} from './participantValidations/participantSearchValidations';

import classes from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const ParticipantAdvancedSearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE

    const initialState = {
        serviceId: '',
        teamId: '',
        adviserId: '',
        statusId: '',
        blankPONumber: null,
        requestsAwaitingAuthorisation: null,
        postcode: '',
        maximumPostcodeDistanceId: '',
        notKnownAppointments: null,
        formsAwaitingAuthorisation: null
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [filteredAdvisers, setFilteredAdvisers] = useState([]);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(true);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const services = useSelector((state) => state.entities.directusService.serviceDetails);
    const teamDetails = useSelector((state) => state.entities.userService.teams);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const statuses = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const distances = useSelector(
        (state) => state.entities.directusService.maximumTravelDistanceDetails
    );

    // HELPER FNS

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false, message: '' } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (!('serviceIds' in loggedInUser)) return;
        if (loggedInUser.serviceIds.length > 0) {
            dispatch(loadServiceDetailsByService(loggedInUser.serviceIds));
        }
        loggedInUser?.teamIds.length > 0 && dispatch(searchTeams({ ids: loggedInUser.teamIds }));
        dispatch(loadParticipantStatusDetails());
        dispatch(loadMaximumTravelDistanceDetails());
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        const updatedFilteredAdvisers = users.filter(
            (el) =>
                el.primaryService === newEntry.serviceId ||
                el.otherServices?.find((id) => id === newEntry.serviceId) ||
                el.primaryTeam?.id === newEntry.teamId ||
                el.otherTeams?.find((entry) => entry.id === newEntry.teamId)
        );
        if (!updatedFilteredAdvisers.length && users.length < totalUsers) {
            onLoadMoreAdvisers();
            return;
        }
        setFilteredAdvisers([
            { id: 'isBlank', name: 'is blank' },
            ...addFullNameToArray(updatedFilteredAdvisers)
        ]);
    }, [users, newEntry.serviceId, newEntry.teamId]);

    useEffect(() => {
        if (teamDetails?.length < 1) return;
        const sortedTeams = [...teamDetails];
        sortedTeams.sort((a, b) => a.name.localeCompare(b.name));
        setTeams(sortedTeams);
        setFilteredTeams(sortedTeams);
    }, [teamDetails]);

    useEffect(() => {
        setIsDisabled(checkForValues(newEntry));
    }, [newEntry.serviceId, newEntry.postcode, newEntry.maximumPostcodeDistanceId]);

    // EVENT HANDLERS

    const onServiceChange = (chosenId) => {
        clearError('serviceId');
        setNewEntry((prev) => ({
            ...prev,
            serviceId: chosenId,
            teamId: '',
            adviserId: ''
        }));

        setFilteredTeams(!chosenId ? teams : teams.filter((el) => el.serviceId === chosenId));
    };

    const onTeamChange = (chosenId) => {
        setNewEntry((prev) => ({
            ...prev,
            teamId: chosenId,
            adviserId: ''
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        setIsDisabled(!isValid);
        if (!isValid) return;
        const {
            blankPONumber,
            requestsAwaitingAuthorisation,
            notKnownAppointments,
            formsAwaitingAuthorisation,
            adviserId,
            ...rest
        } = newEntry;
        const blankAdviser = adviserId === 'isBlank';
        const payload = {
            ...rest,
            blankPONumber: blankPONumber || null,
            requestsAwaitingAuthorisation: requestsAwaitingAuthorisation || null,
            notKnownAppointments: notKnownAppointments || null,
            formsAwaitingAuthorisation: formsAwaitingAuthorisation || null,
            blankAdviser,
            adviserId: blankAdviser ? '' : adviserId
        };
        dispatch(loadParticipantsAdvancedSearch(payload));
        navigate('/participant_search_results');
    };

    const onLoadMoreAdvisers = () => {
        const pageNumber = !users.length ? 0 : lastPageOfUsersLoaded + 1;
        dispatch(searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds, pageNumber));
    };

    // RENDER

    return (
        <div className={classes.container}>
            <div className={classes.mainHeading}>Participant Advanced Search</div>
            <div className={classes.whiteContainer}>
                <form className={form.formWrapper} onSubmit={onSubmit}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <DDLOptionPicker
                                label={'Service'}
                                id={'serviceId'}
                                mandatory={true}
                                menuItems={services || []}
                                chosenId={newEntry.serviceId}
                                onChange={(chosenId) =>
                                    onServiceChange(chosenId)
                                }></DDLOptionPicker>
                            {errors.serviceId.error && (
                                <div className={form.textInputError}>
                                    <IconError text={errors.serviceId} />
                                </div>
                            )}

                            <DDLOptionPicker
                                label={'Team'}
                                id={'teamId'}
                                menuItems={filteredTeams || []}
                                chosenId={newEntry.teamId}
                                onChange={(chosenId) => onTeamChange(chosenId)}></DDLOptionPicker>

                            <SingleSelect
                                id={'adviserId'}
                                label={'Adviser'}
                                placeholder="Select adviser..."
                                disabled={!newEntry.serviceId}
                                menuItems={filteredAdvisers || []}
                                selectedId={newEntry.adviserId || ''}
                                selected={
                                    filteredAdvisers.find((el) => el.id === newEntry.adviserId) ||
                                    {}
                                }
                                onChange={(chosenId) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        adviserId: chosenId
                                    }))
                                }
                                onLoadMoreItems={onLoadMoreAdvisers}
                                moreItemsToLoad={!totalUsers || users.length < totalUsers}
                            />

                            <DDLOptionPicker
                                label={'Participant Status'}
                                id={'statusId'}
                                menuItems={statuses || []}
                                chosenId={newEntry.statusId}
                                onChange={(chosenId) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        statusId: chosenId
                                    }))
                                }></DDLOptionPicker>
                            <DDLOptionPicker
                                label={'Max Postcode Distance (Miles)'}
                                id={'maximumPostcodeDistanceId'}
                                menuItems={distances || []}
                                chosenId={newEntry.maximumPostcodeDistanceId}
                                onChange={(chosenId) => {
                                    clearError('maximumPostcodeDistanceId');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        maximumPostcodeDistanceId: chosenId
                                    }));
                                }}></DDLOptionPicker>
                            {errors.maximumPostcodeDistanceId.error && (
                                <div className={form.textInputError}>
                                    <IconError text={errors.maximumPostcodeDistanceId} />
                                </div>
                            )}

                            <LabelledTextField
                                label={'Of Postcode'}
                                id={'postcode'}
                                value={newEntry.postcode}
                                placeholder={'Enter postcode'}
                                onChange={(e) => {
                                    clearError('postcode');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        postcode: e.target.value.trim()
                                    }));
                                }}
                            />
                            {errors.postcode.error && (
                                <div className={form.textInputError}>
                                    <IconError text={errors.postcode} />
                                </div>
                            )}
                        </div>

                        <div className={form.formColumn}>
                            <div className={`${form.checkboxGroup} ${form.inputSet}`}>
                                <label
                                    htmlFor="requestsAwaitingAuthorisation"
                                    className={form.formLabel}>
                                    Requests Awaiting Authorisation
                                </label>
                                <input
                                    id="requestsAwaitingAuthorisation"
                                    type="checkbox"
                                    checked={newEntry.requestsAwaitingAuthorisation || false}
                                    onChange={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            requestsAwaitingAuthorisation: e.target.checked
                                        }))
                                    }
                                />
                            </div>
                            <div className={`${form.checkboxGroup} ${form.inputSet}`}>
                                <label
                                    htmlFor="formsAwaitingAuthorisation"
                                    className={form.formLabel}>
                                    Forms Awaiting Authorisation
                                </label>
                                <input
                                    id="formsAwaitingAuthorisation"
                                    type="checkbox"
                                    checked={newEntry.formsAwaitingAuthorisation || false}
                                    onChange={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            formsAwaitingAuthorisation: e.target.checked
                                        }))
                                    }
                                />
                            </div>
                            <div className={`${form.checkboxGroup} ${form.inputSet}`}>
                                <label htmlFor="blankPONumber" className={form.formLabel}>
                                    PO Number is Blank
                                </label>
                                <input
                                    id="blankPONumber"
                                    type="checkbox"
                                    checked={newEntry.blankPONumber || false}
                                    onChange={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            blankPONumber: e.target.checked
                                        }))
                                    }
                                />
                            </div>
                            <div className={`${form.checkboxGroup} ${form.inputSet}`}>
                                <label htmlFor="notKnownAppointments" className={form.formLabel}>
                                    Previous Appointment Not Known
                                </label>
                                <input
                                    id="notKnownAppointments"
                                    type="checkbox"
                                    checked={newEntry.notKnownAppointments || false}
                                    onChange={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            notKnownAppointments: e.target.checked
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isDisabled}
                        onClick={onSubmit}>
                        Search
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ParticipantAdvancedSearch;
