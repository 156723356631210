import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, Link, Typography } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import { loadParticipant } from '../../store/participantService';
import { POLARIS_BLUE, POLARIS_WHITE, SCREEN_SETTINGS } from '../../themes/theme';

const RecordedIdentifier = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [contractName, setContractName] = useState('');
    const [serviceName, setServiceName] = useState('');

    // STORE STATE
    const { currentParticipant, currentParticipantId } = useSelector(
        (state) => state.entities.participantService
    );
    const { contractDetailsForContractId: contractDetails, serviceDetailsForContractId: services } =
        useSelector((state) => state.entities.directusService);
    // FNs
    const renderTitles = `${currentParticipant.firstName} ${currentParticipant.lastName} : ${
        currentParticipant.ptCode
    } : ${contractName} : ${serviceName}`;

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(currentParticipant).length === 0 && currentParticipantId) {
            dispatch(loadParticipant(currentParticipantId));
        }
    }, [currentParticipantId]);

    useEffect(() => {
        if (!('contractId' in currentParticipant)) {
            setContractName('');
            setServiceName('');
        } else if (contractDetails?.id !== currentParticipant.contractId) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (
            !currentParticipant ||
            Object.keys(currentParticipant).length < 1 ||
            services?.length < 1 ||
            Object.keys(contractDetails)?.length < 1
        )
            return;
        'name' in contractDetails && setContractName(contractDetails.name);
        const serviceName =
            services.find((el) => el.id === currentParticipant.serviceId)?.name || '';
        setServiceName(serviceName);
    }, [contractDetails, services]);

    // RENDER

    return (
        <Card
            sx={{
                backgroundColor: POLARIS_WHITE,
                paddingBottom: SCREEN_SETTINGS.padding.small,
                marginLeft: SCREEN_SETTINGS.margin.large
            }}
            elevation={0}>
            <Link onClick={() => navigate('/edit_participant')} style={{ cursor: 'pointer' }}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: POLARIS_BLUE }}>
                    {Object.keys(currentParticipant)?.length < 1 ? (
                        <div>No Participant selected</div>
                    ) : (
                        <div>{renderTitles}</div>
                    )}
                </Typography>
            </Link>
        </Card>
    );
};
export default RecordedIdentifier;
