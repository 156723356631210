import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import Button from '../../formElements/Button';

import modal from '../../../commonStyles/modal.module.css';

const PromptModal = ({ promptType, content, isOpen, onClose }) => {
    return (
        <Dialog onClose={onClose} open={isOpen}>
            <div className={modal.modalHeader}>
                <DialogTitle>{promptType}</DialogTitle>
            </div>
            <DialogContent>
                <>
                    <div className={modal.modalPromptContent}>{content}</div>
                    <div className={modal.actionButtons}>
                        <div className={modal.cancelButton}>
                            <Button id="promptModalButton" onClick={onClose} content="Proceed" />
                        </div>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    );
};

export default PromptModal;

PromptModal.propTypes = {
    promptType: PropTypes.string,
    content: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};
