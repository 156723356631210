import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import {
    deletePhysicalActivity,
    loadPhysicalActivities,
    onDeletePhysicalActivity
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { formatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CreatePhysicalActivity from './CreatePhysicalActivity';
import PhysicalActivityTable from './PhysicalActivityTable';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const PhysicalActivity = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Physical activity deleted`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const physicalActivities = useSelector(
        (state) => state.entities.participantService.physicalActivityEntries
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        currentParticipant?.id && dispatch(loadPhysicalActivities(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        const rows = physicalActivities.map((el) => ({
            ...el,
            displayDate: Date.parse(el.eventDate),
            eventDate: formatDate(el.eventDate)
        }));
        setRows(rows);
    }, [physicalActivities]);

    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeletePhysicalActivity(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deletePhysicalActivity(row.id, deleteMsg));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Physical Activity</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No physical activities found" />
                ) : (
                    <PhysicalActivityTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Physical Activity
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreatePhysicalActivity
                            onClose={() => setShowForm(false)}
                            roles={roles}
                            formType={formType}
                            row={selectedRow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PhysicalActivity;
