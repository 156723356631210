import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Collapse, TableCell, TableRow } from '@mui/material';

import OnCreateIcon from '../../../icons/OnCreateIcon';
import OnEditIcon from '../../../icons/OnEditIcon';
import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { hasRole } from '../../../utils/userRoles';

import ValidationRowDetails from './ValidationRowDetails';

import classes from '../../../commonStyles/tables.module.css';
import claimsClasses from '../claimStyles/validations.module.css';

const ValidationTableRow = ({
    row,
    acceptedRoles,
    toggleDropdown,
    openId,
    onStatusChangeEditor,
    onStatusRow,
    onEditAction,
    onCreateAction
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [open, setOpen] = useState(null);
    const [loaded, setLoaded] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    // USE EFFECTS
    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && loaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    // EVENT HANDLERS
    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    const onEditParticipant = () => {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            setLoaded(true);
        }
    };

    const onChangeStatus = () => {
        if (row?.statusId) {
            onStatusRow(row);
            onStatusChangeEditor(true);
        }
    };

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}>
                <TableCell
                    className={claimsClasses.claimsCodeLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row?.poNumber}</TableCell>
                <TableCell onClick={onToggleDropdown}>{row.eventName}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.eventDate)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.lastStatusUpdated)}
                </TableCell>
                <TableCell
                    className={claimsClasses.claimsCodeLink}
                    onClick={onChangeStatus}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row.statusName}
                </TableCell>
                <TableCell align="center">
                    {row.actionStatus === 'zz' ? ' - ' : row.actionStatus}
                </TableCell>
                <TableCell align="center">
                    {row.actionStatus === 'zz' ? (
                        <OnCreateIcon
                            onCreate={() => onCreateAction(row)}
                            titleString={'Action'}
                            roles={loggedInUser.roles}
                            acceptedRoles={acceptedRoles}
                        />
                    ) : (
                        <OnEditIcon
                            onEdit={() => onEditAction(row)}
                            roles={loggedInUser.roles}
                            acceptedRoles={acceptedRoles}
                        />
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={8}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <ValidationRowDetails row={row} onClose={() => setOpen(null)} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ValidationTableRow;

ValidationTableRow.propTypes = {
    row: PropTypes.object,
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string,
    onStatusChangeEditor: PropTypes.func,
    onStatusRow: PropTypes.func,
    onCreateAction: PropTypes.func,
    onEditAction: PropTypes.func
};
