import { charVal } from './validationPatterns';

export const nhsNumberChecksum = (value) => {
    let sum = 0;
    for (let i = 0; i < value.length - 1; i++) sum += value[i] * (charVal.NHS_LENGTH - i);

    let total = charVal.NHS_MOD - (sum % charVal.NHS_MOD);
    if (total === charVal.NHS_MOD) total = 0;

    const checkDigit = total < charVal.NHS_LENGTH ? parseInt(value[charVal.NHS_LENGTH - 1]) : 0;

    if (total === charVal.NHS_LENGTH || total !== checkDigit) {
        return { error: true, message: checksumMessage };
    }
    return false;
};

export const checksumMessage = 'NHS number invalid, please check and try again.';
