import React from 'react';

import { Typography } from '@mui/material';

import { POLARIS_BLUE, POLARIS_WHITE } from '../../themes/theme';

import logo from './../../images/logo.png';

import classes from '../../app.module.css';

const Footer = () => {
    return (
        <div className={classes.footer} style={{ backgroundColor: POLARIS_BLUE }}>
            <div className={classes.footerLogo}>
                <img src={logo} alt="Reed Logo" />
            </div>

            <Typography variant="body2" sx={{ color: POLARIS_WHITE }}>
                <span className={classes.footerCopy}>
                    Reed in Partnership Limited. Registered Office: Academy Court, 94 Chancery Lane,
                    London WC2A 1DT
                </span>
                <span className={classes.footerCopy}>
                    Company Number: 00851645. Registered in the UK. © {new Date().getFullYear()}
                    &nbsp;Reed in Partnership Ltd
                </span>
            </Typography>
        </div>
    );
};

export default Footer;
