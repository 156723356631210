import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const TrackVisibility = ({ children, onVisible, options }) => {
    const trackRef = useRef(null);
    const hasCalledOnVisibleRef = useRef(false);

    useEffect(() => {
        const fn = (entries) => {
            const [entry] = entries;
            if (!entry.isIntersecting || hasCalledOnVisibleRef.current) return;
            onVisible();
            hasCalledOnVisibleRef.current = true;
            observer.unobserve(entry.target);
        };

        const observer = new IntersectionObserver(fn, options);
        trackRef.current && observer.observe(trackRef.current);
    }, [onVisible, options]);

    return <div ref={trackRef}>{children}</div>;
};

export default TrackVisibility;

TrackVisibility.propTypes = {
    children: PropTypes.any,
    onVisible: PropTypes.func,
    options: PropTypes.object
};

// options example:
// const trackOptions = {
//     root: document.querySelector('.divScrolledTo'), // use null for browser window
//     rootMargin: '20px',
//     threshold: [0.2]
// }

// wrap around the component div(s) that require an action when scrolled into view:
// <TrackVisibility onVisible={loadNewPage} options={trackOptions}>
//      <div className="divScrolledTo">
//           text
//      </div>
// </TrackVisibility>
