import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { selectBarrierDetails } from '../../../store/dataSelectors';
import { loadBarrierNameDetails } from '../../../store/directusService';
import { selectCurrentParticipantBarriers } from '../../../store/participantSelectors';
import { loadParticipantBarriers } from '../../../store/participantService';
import { POLARIS_WHITE } from '../../../themes/theme';
import FormHeader from '../../layout/FormHeader';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CurrentCourseTable from './CurrentCourseTable';

const CurrentCourseManagement = ({ activeCourses }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [filteredActiveCourses, setFilteredActiveCourses] = useState([]);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const barrierNameDetails = useSelector(selectBarrierDetails);

    // USE EFFECTS
    useEffect(() => {
        if (
            !currentParticipantBarriers?.length ||
            currentParticipantBarriers?.participantId !== currentParticipant.id
        )
            dispatch(loadParticipantBarriers(currentParticipant.id));

        if (barrierNameDetails?.length < 1) dispatch(loadBarrierNameDetails());
    }, []);

    useEffect(() => {
        if (activeCourses.length < 1) return;
        const filteredActiveCourses = activeCourses.filter((el) =>
            el.modules.some((entry) =>
                entry.participants.some((pt) => pt.participantId === currentParticipant.id)
            )
        );
        setFilteredActiveCourses(filteredActiveCourses);
    }, [activeCourses]);

    // RENDER
    return (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Current Courses'}></FormHeader>

                    {filteredActiveCourses.length < 1 ? (
                        <LoadingSpinner content="No current courses found" />
                    ) : (
                        <CurrentCourseTable rows={filteredActiveCourses} roles={roles} />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

CurrentCourseManagement.propTypes = {
    activeCourses: PropTypes.array
};

export default CurrentCourseManagement;
