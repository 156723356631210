import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { setCurrentlySelectedParticipant } from '../../../store/participantService';

import '../styles/startAppointmentButtonStyles.css';
import '../../../app.module.css';

const StartSlotButtons = ({ participants, appointment, disabled, onPanelClose, statuses }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(participants);
    }, [participants]);

    // EVENT HANDLERS
    const onAction = (participant) => {
        dispatch(setCurrentlySelectedParticipant(participant));
        onPanelClose();
    };

    const onStartAppointment = async (participant) => {
        await onAction(participant);
        navigate('/appointments', { state: { appointment, returnPath: window.location.pathname } });
    };

    const onNav = async (participant) => {
        if (disabled) return;
        await onAction(participant);
        navigate('/edit_participant');
    };

    // RENDER
    return rows.length < 1 ? null : (
        <div className="startAppointmentButtons fadein">
            {participants.map((el) => (
                <div className="appointmentButtonRow fadein" key={el.id}>
                    <span
                        className={`rowName ${!disabled ? 'rowNameLink' : ''}`}
                        onClick={() => onNav(el)}>{`${el.firstName} ${el.lastName}`}</span>
                    <span className="rowButton">
                        <Button
                            type="text"
                            color="primary"
                            variant="contained"
                            disabled={
                                disabled || (statuses[el.id] && statuses[el.id] !== 'Not known')
                            }
                            onClick={() => onStartAppointment(el)}>
                            Start Appointment
                        </Button>
                    </span>
                    <span className="rowStatus">{statuses[el.id] || 'Not known'}</span>
                </div>
            ))}
        </div>
    );
};

export default StartSlotButtons;

StartSlotButtons.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    appointment: PropTypes.object,
    disabled: PropTypes.bool,
    onPanelClose: PropTypes.func,
    statuses: PropTypes.object
};
