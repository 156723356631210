import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import { selectIsSecondNatureReferralAttemptSuccessful } from '../../../../store/participantSelectors';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';

import BloodTestRowDetails from './BloodTestRowDetails';

import classes from '../../../../commonStyles/tables.module.css';

const BloodTestsRow = ({ row, roles, onEdit, onDelete, toggleDropdown, openId }) => {
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const acceptedDeleteRoles = [MANAGER, QUALITY, SUPERUSER];

    const [open, setOpen] = useState(null);

    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    const onToggleDropdown = (e) => {
        if (e.target.nodeName !== 'TD') return;
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    return (
        <>
            <TableRow
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.displayDate}</TableCell>
                <TableCell align="center">{row.testType}</TableCell>
                <TableCell align="center">{row.testValue}</TableCell>
                <TableCell align="center">{row.testValueType}</TableCell>
                <TableCell align="center">{row.testResult}</TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEdit(row)}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={!isReferralHistoryAttemptSuccessful}
                    />
                </TableCell>
                <TableCell align="right">
                    <OnDeleteIcon
                        onDelete={() => onDelete(row)}
                        roles={roles}
                        acceptedRoles={acceptedDeleteRoles}
                        active={!isReferralHistoryAttemptSuccessful}
                    />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={7}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <BloodTestRowDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default BloodTestsRow;

BloodTestsRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string
};
