import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import OnContactIcon from '../../../../icons/OnContactIcon';
import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import { onSetCurrentGroupCommunication } from '../../../../store/communicationService';
import {
    ADVISER,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';

const CommunicationGroupsRow = ({ row, roles, onDelete }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

    const onContact = () => {
        dispatch(onSetCurrentGroupCommunication(row));
        row.type === 'SMS'
            ? navigate('/group_sms', { state: { group: row } })
            : navigate('/group_email', { state: { group: row } });
    };

    const onEdit = () => {
        dispatch(onSetCurrentGroupCommunication(row));
        navigate('/edit_communication_groups');
    };

    return (
        <TableRow hover className={`${classes.basicRowStripes}`}>
            <TableCell align="center">{row.code}</TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="center">{row.groupSize}</TableCell>
            <TableCell align="center">{row.type}</TableCell>
            <TableCell align="center" id="contactIcon">
                <OnContactIcon onClick={onContact} roles={roles} acceptedRoles={acceptedRoles} />
            </TableCell>
            <TableCell align="right" id="editIcon">
                <OnEditIcon onEdit={onEdit} roles={roles} acceptedRoles={acceptedRoles} />
            </TableCell>
            <TableCell align="right">
                <OnDeleteIcon
                    onDelete={() => onDelete(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                />
            </TableCell>
        </TableRow>
    );
};

export default CommunicationGroupsRow;

CommunicationGroupsRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onDelete: PropTypes.func
};
