import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import { Button } from '@mui/material';

import {
    clearWorkHistory,
    loadEmploymentTypeDetails,
    loadKeySoftSkillsDetails,
    loadReasonForLeavingDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    loadParticipantWorkHistory,
    updateParticipantWorkHistory
} from '../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import FormHeader from '../../layout/FormHeader';
import CardHandler from '../../ui/cards/CardHandler';
import { WORK_HISTORY_VIEWER } from '../../ui/cards/ViewerTypes';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../../ui/pickers/DDLMultiOptionPicker';

const WorkHistory = () => {
    const { handleSubmit } = useForm({});
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        keySkillsIds: [],
        employment: []
    };
    const [workHistory, setWorkHistory] = useState([]);
    const [selectedKeySkills, setSelectedKeySkills] = useState([]);
    const [isClearSelectedKeySkills, setIsClearSelectedKeySkills] = useState('1');

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { employmentTypeDetails, keySoftSkillsDetails, reasonForLeavingDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    let dbWorkHistory = useSelector(
        (state) => state.entities.participantService.currentParticipantWorkHistory
    );

    // EVENT HANDLERS
    const handleKeySkillsChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedKeySkills(chosenIds);
    };

    /**
     * Add blank workHistory to workHistory
     */
    const addEmployment = () => {
        // If there is already a blank past education then don't add another
        if (workHistory?.some((c) => !c.companyName || !c.employmentTypeId)) {
            dispatch(setErrorMessage(`Cannot add another blank. Please complete existing card.`));

            return;
        }
        const newEmployment = {
            id: uuid(),
            companyName: null,
            employmentTypeId: null,
            jobTitle: null,
            startDate: null,
            endDate: null,
            reasonForLeavingId: null,
            description: ''
        };
        if (!workHistory) {
            setWorkHistory([newEmployment]);
        } else {
            setWorkHistory((prev) => [...prev, newEmployment]);
        }
    };

    /**
     * Delete past workHistory from workHistory
     */
    const deleteEmployment = (employment) => {
        if (workHistory.length < 2) return;
        setWorkHistory(workHistory.filter((el) => el.id !== employment.id));
    };

    /**
     * Update past workHistory in workHistory
     *
     * @param {object} employment - past workHistory to update
     *
     * This is a callback from the CardViewer component.
     */
    const updateEmployment = (employment) => {
        setWorkHistory(workHistory.map((el) => (el.id === employment.id ? employment : el)));
    };

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant) {
            dispatch(clearWorkHistory());
            setIsClearSelectedKeySkills(Math.random());
            dispatch(loadParticipantWorkHistory(currentParticipant.id));
            dispatch(loadKeySoftSkillsDetails());
            dispatch(loadEmploymentTypeDetails());
            dispatch(loadReasonForLeavingDetails());
        }
    }, [currentParticipant.id]);

    useEffect(() => {
        setWorkHistory(dbWorkHistory.employment);
        setSelectedKeySkills(dbWorkHistory.keySoftSkillIds);
    }, [dbWorkHistory, currentParticipant?.id]);

    /**
     * Check if there are any incomplete past educations
     * @returns {boolean} - true if there are incomplete cards
     */
    const hasIncompleteCard = () => {
        return workHistory?.some((c) => !c.companyName || !c.employmentTypeId);
    };

    // FORM SUBMIT
    const onSubmit = () => {
        if (hasIncompleteCard()) {
            dispatch(setErrorMessage(`Please complete all past educations cards.`));
        } else if (
            (!selectedKeySkills && !workHistory) ||
            (selectedKeySkills?.length < 1 && !workHistory) ||
            (selectedKeySkills?.length < 1 && workHistory?.length < 1)
        ) {
            dispatch(setErrorMessage(`There is nothing to submit.`));
        } else {
            const payload = {
                id: initialState.id,
                keySoftSkillIds: selectedKeySkills,
                employment:
                    !workHistory || workHistory.length < 1
                        ? []
                        : workHistory.map((el) => ({
                              ...el,
                              id: null
                          }))
            };
            dispatch(updateParticipantWorkHistory(payload, currentParticipant.id));
        }
    };

    let content;
    if (employmentTypeDetails?.length < 1) content = 'No workHistory type details';
    if (keySoftSkillsDetails?.length < 1) content = 'No key skill details';
    if (reasonForLeavingDetails?.length < 1) content = 'No reason for leaving details';
    if (currentParticipant?.length < 1) content = 'No current participant';

    // RENDER
    if (
        employmentTypeDetails?.length < 1 ||
        keySoftSkillsDetails?.length < 1 ||
        reasonForLeavingDetails?.length < 1 ||
        currentParticipant?.length < 1
    ) {
        return <LoadingSpinner content={content} />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <FormHeader text={'Past Employment'}></FormHeader>
            <DDLMultiOptionPicker
                heading={'Key Skills'}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                id="keySkills"
                key={isClearSelectedKeySkills}
                label="Key Skills"
                placeholder="Key Skill"
                menuItems={keySoftSkillsDetails}
                preSelectedIds={dbWorkHistory.keySoftSkillIds || []}
                chosenIds={dbWorkHistory.keySoftSkillIds || []}
                onChange={(chosenIds) => handleKeySkillsChange(chosenIds)}
            />
            <br />
            <br />
            <CardHandler
                viewerType={WORK_HISTORY_VIEWER}
                cards={workHistory || []}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                itemName={'WorkHistory'}
                label={'Add Past Employment'}
                data={employmentTypeDetails}
                moreData={reasonForLeavingDetails}
                sendAdd={addEmployment}
                sendDelete={deleteEmployment}
                sendUpdate={updateEmployment}></CardHandler>
            <p>{}</p>
            <br />
            <br />
            <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                data-testid="testIdHISubmitButton">
                Update Past Employment
            </Button>
        </form>
    );
};

export default WorkHistory;
