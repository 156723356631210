import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { FormControl, TextField } from '@mui/material';

import IconError from '../../IconError';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * A label plus an email text field
 *
 * @param label Label text Default =''
 * @param id Used to create id and data-testid
 * @param error  This has error to display in IconError
 * @param disabled Disables control if true. Default = false
 * @param mandatory Shows Mandatory component if true. Default = false;
 * @parma rows   Number of rows. Default =1
 *
 */

// eslint-disable-next-line react/display-name
const LabelledEmailText = forwardRef(
    (
        { label = '', id = '', error, disabled = false, mandatory = false, rows = 1, ...props },
        ref
    ) => {
        return (
            <div className={form.textFieldInputWrapper}>
                <label htmlFor={id} className={form.formLabel}>
                    {label} {mandatory && <sup>*</sup>}
                </label>
                <FormControl fullWidth>
                    <TextField
                        multiline
                        rows={rows}
                        disabled={disabled}
                        {...props}
                        size="small"
                        id={id}
                        data-testid={'textField' + id}
                        ref={ref}
                        variant="outlined"
                    />
                </FormControl>
                <IconError text={error || null} data-testid={id + 'Error'} />
            </div>
        );
    }
);

LabelledEmailText.propTypes = {
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.any,
    rows: PropTypes.number
};

export default LabelledEmailText;
