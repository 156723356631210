import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { DEFAULT_TABLE_ROW_OPTIONS, DEFAULT_TABLE_ROWS_PER_PAGE } from '../../utils/uiConstants';
import SensitivityNotice from '../ui/notices/sensitivity/SensitivityNotice';

import classes from '../../commonStyles/tables.module.css';

const ResultsTable = ({
    defaultOrderBy,
    sortOrder = 'asc',
    loadPageSize = 10,
    defaultRowsPerPage = DEFAULT_TABLE_ROWS_PER_PAGE,
    searchData,
    apiParams,
    headCells,
    loadingValues,
    loadResults,
    passRowMetaDataUp,
    onRevealSensitiveData,
    showSensitiveCells,
    tableRows,
    totalResults,
    metaData
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [tableRowsByPage, setTableRowsByPage] = useState([]);
    const [visibleRows, setVisibleRows] = useState([]);
    const [order, setOrder] = useState(sortOrder);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [totalRows, setTotalRows] = useState(totalResults);

    useEffect(() => {
        if (loadingValues) return;
        if (!metaData.last && tableRows.length < rowsPerPage) loadMoreResults();
        else configPageRows(rowsPerPage);
    }, [tableRows, loadingValues]);

    useEffect(() => {
        setVisibleRows(tableRowsByPage[page]);
    }, [tableRowsByPage, page]);

    useEffect(() => {
        setTotalRows(metaData.last ? tableRows.length : totalResults);
    }, [metaData.last, totalResults, tableRows.length]);

    useEffect(() => {
        passRowMetaDataUp && passRowMetaDataUp({ order, orderBy, page, rowsPerPage });
    }, [order, orderBy, page, rowsPerPage, searchData]);

    useEffect(() => {
        setOrder(sortOrder);
        setOrderBy(defaultOrderBy);
        setPage(0);
    }, [searchData]);

    // HELPER FNS

    const configPageRows = (newRowsPerPage) => {
        let tableRowsByPageArr = [];
        let nextPage = 0;
        while (nextPage * newRowsPerPage < tableRows.length) {
            tableRowsByPageArr = [
                ...tableRowsByPageArr,
                tableRows.slice(
                    nextPage * newRowsPerPage,
                    nextPage * newRowsPerPage + newRowsPerPage
                )
            ];
            nextPage++;
        }
        setTableRowsByPage(tableRowsByPageArr);
    };

    const getDisplayedRowsData = () =>
        `${page * rowsPerPage + 1} - ${page * rowsPerPage + visibleRows?.length}`;

    const loadMoreResults = () => {
        dispatch(loadResults(...getLoadResultsArgs(metaData.number + 1)));
    };

    const getLoadResultsArgs = (pageNumber) => [
        ...(apiParams ? [apiParams] : []),
        pageNumber,
        loadPageSize,
        orderBy,
        order
    ];

    // EVENT HANDLERS
    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        setPage(newPage);
        if (!metaData.last) loadMoreResults();
    };

    const onChangeRowsPerPage = (e) => {
        const newRowsPerPage = +e.target.value;
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        configPageRows(newRowsPerPage);
        if (tableRows.length < newRowsPerPage) {
            dispatch(loadResults(...getLoadResultsArgs(0)));
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <div className={classes.tableWrapper}>
                    <TableContainer>
                        <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                            <TableHead>
                                <TableRow>
                                    {headCells.map(({ id, label, sortable }) => (
                                        <TableCell
                                            key={id}
                                            sortDirection={orderBy === id ? order : false}>
                                            {sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === id}
                                                    direction={orderBy === id ? order : sortOrder}
                                                    onClick={() => onSort(id)}>
                                                    <Typography
                                                        variant={'subtitle3'}
                                                        color={'primary'}>
                                                        {label}
                                                    </Typography>
                                                    {orderBy === id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc'
                                                                ? 'sorted descending'
                                                                : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            ) : (
                                                <Typography variant={'subtitle3'} color={'primary'}>
                                                    {label}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>{visibleRows}</TableBody>
                        </Table>
                    </TableContainer>
                    {showSensitiveCells === false && (
                        <SensitivityNotice
                            onClick={onRevealSensitiveData}
                            numRows={visibleRows?.length}
                        />
                    )}
                </div>
                {totalRows && (
                    <TablePagination
                        data-testid="tablePagination"
                        rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        labelDisplayedRows={getDisplayedRowsData}
                    />
                )}
            </Paper>
        </Box>
    );
};

ResultsTable.propTypes = {
    defaultOrderBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string,
    loadPageSize: PropTypes.number,
    defaultRowsPerPage: PropTypes.number,
    headCells: PropTypes.array,
    loadingValues: PropTypes.bool,
    loadResults: PropTypes.func,
    passRowMetaDataUp: PropTypes.func,
    onRevealSensitiveData: PropTypes.func,
    searchData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    apiParams: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showSensitiveCells: PropTypes.bool,
    tableRows: PropTypes.arrayOf(PropTypes.object),
    totalResults: PropTypes.number,
    metaData: PropTypes.object
};

export default ResultsTable;
