import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Card, CardContent, Divider, Link, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { checkExists, getCourseURL } from '../../../api/commonHTTP';
import { createCourseTemplate } from '../../../store/courseService';
import {
    clearCourseTemplateHealth,
    loadBarrierNameDetailsByContract,
    loadContractDetails,
    loadCourseTemplateHealthDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { hasRole, LOCAL_ADMIN, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import RadioButtons from '../../formElements/RadioButtons';
import FormHeader from '../../layout/FormHeader';
import BusyIndicator from '../../ui/BusyIndicator';
import CardHandler from '../../ui/cards/CardHandler';
import { MODULE_TEMPLATE_VIEWER } from '../../ui/cards/ViewerTypes';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLMultiOptionPicker from '../../ui/pickers/DDLMultiOptionPicker';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

const CHARACTER_LIMIT = 250;
const ACTION_DESCRIPTION_LIMIT = 750;

const CreateCourseTemplate = () => {
    const {
        clearErrors,
        handleSubmit,
        register,
        // reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    // State for create service newEntry
    // This matches the payload sent to the backend
    const acceptedRoles = [QUALITY, SUPERUSER, LOCAL_ADMIN];
    const initialState = {
        name: null,
        description: '',
        active: true,
        contractIds: null,
        healthInformationCaptured: false,
        healthInformationIds: null,
        modularCourse: false,
        modulesInSetOrder: false,
        moduleTemplates: [],
        smartTargetFlag: false
    };
    const [newEntry, setNewEntry] = useState(initialState);

    const [selectedContracts, setSelectedContracts] = useState([]);
    const [selectedHealth, setSelectedHealth] = useState([]);

    const [arrayContracts, setArrayContracts] = useState([]);
    const [arrayHealth, setArrayHealth] = useState([]);
    const [moduleName, setModuleName] = useState('');
    const [moduleDescription, setModuleDescription] = useState('');
    const [modularCourse, setModularCourse] = useState(false);

    const [smartTarget, setSmartTarget] = useState(false);
    const [smartTargetName, setSmartTargetName] = useState('');
    const [smartTargetAction, setSmartTargetAction] = useState('');
    const [smartTargetBarrierName, setSmartTargetBarrierName] = useState([]);

    // const [isClearSelectedContract, setIsClearSelectedContract] = useState('0');
    const [isClearSelectedHealth, setIsClearSelectedHealth] = useState('1');
    const [isClearSelectedSmartTargetBarrierName, setIsClearSelectedSmartTargetBarrierName] =
        useState('2');

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { contractDetails, courseTemplateHealthDetails, barrierNameDetails } = useSelector(
        (state) => state.entities.directusService
    );

    // EVENT HANDLERS
    const handleNameChange = (e) => setNewEntry((prev) => ({ ...prev, name: e.target.value }));

    const handleDescriptionChange = (e) =>
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));

    const handleActiveChange = (option) => setNewEntry((prev) => ({ ...prev, active: option }));

    const handleContractChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedContracts(chosenIds);
        if (chosenIds.length !== 0) {
            clearErrors('selectedContracts');
            setValue('selectedContracts', chosenIds, {
                shouldValidate: true
            });
            dispatch(loadBarrierNameDetailsByContract(chosenIds));
        }
        setSmartTargetBarrierName('');
        setIsClearSelectedSmartTargetBarrierName(Math.random());
    };

    const handleHealthInformationCaptured = (option) => {
        if (option) setSelectedHealth([]);
        clearErrors('selectedHealth');
        setNewEntry((prev) => ({ ...prev, healthInformationCaptured: option }));
    };

    const handleHealthChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedHealth(chosenIds);
        if (chosenIds.length !== 0) clearErrors('selectedHealth');
        if (newEntry.healthInformationCaptured) {
            setValue('selectedHealth', chosenIds, {
                shouldValidate: true
            });
        }
    };

    const handleMultipleChange = (option) => {
        setModularCourse(option);
        setNewEntry((prev) => ({ ...prev, modularCourse: option }));
        setValue('singleModules', option, {
            shouldValidate: true
        });
        if (option) {
            clearErrors('moduleName');
            clearErrors('moduleDescription');
        }
    };

    const handleModuleNameChange = (e) => setModuleName(e.target.value);

    const handleModuleDescriptionChange = (e) => setModuleDescription(e.target.value);

    const handleModulesOrderChange = (option) => {
        setNewEntry((prev) => ({ ...prev, modulesInSetOrder: option }));
    };

    const handleSmartTargetFlagChange = (option) => {
        setSmartTarget(option);
        setValue('smartTarget', option, {
            shouldValidate: true
        });

        setNewEntry((prev) => ({ ...prev, smartTargetFlag: option }));
        if (!option) {
            clearErrors('action');
            clearErrors('barrierNameId');
            clearErrors('smartTargetAction');
        }
    };

    const handleSmartTargetNameChange = (e) => setSmartTargetName(e.target.value);

    const handleSmartTargetBarrierNameChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setSmartTargetBarrierName(chosenId);
    };

    const handleSmartTargetActionChange = (e) => setSmartTargetAction(e.target.value);

    /**
     * Add blank module template card to module templates
     */
    const addModuleTemplate = () => {
        // If there is already a blank module template then don't add another
        // Check for 'blank' name and description
        if (newEntry.moduleTemplates.some((c) => !c.name || !c.description)) {
            // Show warning that there is already an incomplete card.
            dispatch(setErrorMessage(`Cannot add another blank. Please complete existing card.`));

            return;
        }

        // Add blank module template card to newEntry
        setNewEntry((prev) => ({
            // Add blank goal card
            ...prev,
            moduleTemplates: [
                ...newEntry.moduleTemplates,
                {
                    id: uuid(),
                    name: null,
                    description: null,
                    smartTarget: {
                        name: '',
                        barrierNameId: '',
                        action: ''
                    },
                    smartTargetFlag: false
                }
            ]
        }));
    };

    /**
     * Delete module template card from newEntry.moduleTemplates
     * @param {object} moduleTemplate - module template card to delete
     *
     * newEntry.moduleTemplates = State with an array of objects with module template cards
     */
    // const deleteModuleTemplate = (moduleTemplate) => {
    //     // Get index of newEntry.goals goal card to delete
    //     const index = newEntry.moduleTemplates.findIndex((item) => item.id === moduleTemplate.id);
    //
    //     // Delete module template card from newEntry.moduleTemplates
    //     setNewEntry((draft) => {
    //         draft.moduleTemplates.splice(index, 1);
    //     });
    // };

    /**
     * Update module template card in newEntry.
     *
     * @param {object} moduleTemplate - module template card to update
     *
     * This is a callback from the CardViewer component.
     */
    const updateModuleTemplate = (moduleTemplate) => {
        setNewEntry((prev) => ({
            ...prev,
            moduleTemplates: newEntry.moduleTemplates.map((el) =>
                el.id === moduleTemplate.id ? moduleTemplate : el
            )
        }));
    };

    // USEEFFECTS

    useEffect(() => {
        dispatch(loadContractDetails());
        dispatch(loadCourseTemplateHealthDetails());
        setValue('singleModules', false, {
            shouldValidate: true
        });
        setValue('health', false, {
            shouldValidate: true
        });
        setValue('smartTarget', false, {
            shouldValidate: true
        });
    }, []);

    useEffect(() => {
        if (contractDetails?.length && arrayContracts?.length === 0) {
            setArrayContracts(contractDetails);
        }
    }, [contractDetails]);

    useEffect(() => {
        if (courseTemplateHealthDetails?.length && arrayHealth?.length === 0) {
            setArrayHealth(courseTemplateHealthDetails);
        }
    }, [courseTemplateHealthDetails]);

    useEffect(() => {
        if (successMessage === `Course template details for ${newEntry.name} have been added`) {
            onNavigate();
        }
    }, [successMessage]);

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
        dispatch(clearCourseTemplateHealth());
        setSelectedHealth([]);
        setIsClearSelectedHealth(Math.random());
    };

    const hasIncompleteCard = () => {
        return newEntry.moduleTemplates.some((c) => c.name === null || c.description === null);
    };

    const duplicateModuleNames = () => {
        const moduleTemplateNames = [
            ...new Set(newEntry.moduleTemplates.map((item) => item.name.toLowerCase()))
        ];
        return moduleTemplateNames.length < newEntry.moduleTemplates.length;
    };

    const createSingleModule = () => {
        let temp;
        if (newEntry.smartTargetFlag) {
            temp = {
                ...newEntry,
                moduleTemplates: [
                    {
                        name: moduleName,
                        description: moduleDescription,
                        smartTarget: {
                            name: smartTargetName,
                            barrierNameId: smartTargetBarrierName,
                            action: smartTargetAction
                        }
                    }
                ]
            };
        } else {
            temp = {
                ...newEntry,
                moduleTemplates: [
                    {
                        name: moduleName,
                        description: moduleDescription,
                        smartTarget: null
                    }
                ]
            };
        }
        // eslint-disable-next-line
        const { smartTargetFlag, ...rest } = temp;
        const payload = {
            ...rest,
            contractIds: selectedContracts,
            healthInformationIds: selectedHealth
        };

        dispatch(createCourseTemplate(newEntry.name, payload));
    };

    const createMultipleModules = () => {
        const entries = newEntry.moduleTemplates
            .filter((el) => el.id)
            .map((el) => ({
                ...el,
                id: null,
                smartTarget: el.smartTargetFlag ? el.smartTarget : null
            }))
            .map((el) => {
                // eslint-disable-next-line
                const { smartTargetFlag, ...rest } = el;
                return { ...rest };
            });
        //eslint-disable-next-line
        const { smartTargetFlag, ...rest } = newEntry;
        const payload = {
            ...rest,
            moduleTemplates: entries,
            contractIds: selectedContracts,
            healthInformationIds: selectedHealth
        };
        dispatch(createCourseTemplate(newEntry.name, payload));
    };

    // FORM SUBMIT
    const onSubmit = async () => {
        if (modularCourse && hasIncompleteCard()) {
            dispatch(setErrorMessage(`Cannot save. Please complete all module template cards.`));
        } else if (modularCourse && duplicateModuleNames()) {
            dispatch(
                setErrorMessage(
                    `Cannot save. Module names must be unique within a course template.`
                )
            );
        } else if (
            await checkExists(getCourseURL() + 'rest/course-template/exists/' + newEntry.name)
        ) {
            dispatch(setErrorMessage(`Course ${newEntry.name} already exists.`));
        } else if (selectedContracts.length === 0) {
            // Force Yup validation on required multiselect contract field.
            setValue('selectedContracts', selectedContracts, { shouldValidate: true });
        } else if (newEntry.healthInformationCaptured && selectedHealth.length === 0) {
            // Force Yup validation on required multiselect health field.
            setValue('health', newEntry.healthInformationCaptured, {
                shouldValidate: true
            });
            setValue('selectedHealth', selectedHealth, { shouldValidate: true });
        } else if (modularCourse && newEntry.moduleTemplates.length < 2) {
            dispatch(setErrorMessage(`Modular courses must contain at least 2 modules.`));
        } else {
            !modularCourse ? createSingleModule() : createMultipleModules();
        }
    };

    const onNavigate = () => {
        clearData();
        navigate('/course_management', { state: { accordionPanel: 'templates' } });
    };

    const onCancel = () => onNavigate();

    if (contractDetails.length < 1 || courseTemplateHealthDetails.length < 1) {
        return <BusyIndicator />;
    }

    return (
        <Card data-testid="form_start">
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start">
                    <FormHeader text={'Create Course Template'}></FormHeader>
                    {/* This is needed for single module validations */}
                    <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                        <input
                            id="singleModules"
                            name="singleModules"
                            value={newEntry.modularCourse || false}
                            {...register('singleModules')}></input>
                    </div>
                    {/* This is needed for smart target validations */}
                    <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                        <input
                            id="smartTarget"
                            name="smartTarget"
                            value={newEntry.smartTargetFlag || false}
                            {...register('smartTarget')}></input>
                    </div>
                    {/* This is needed for health data validation */}
                    <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                        <input
                            id="health"
                            name="health"
                            value={newEntry.healthInformationCaptured || false}
                            {...register('health')}></input>
                    </div>
                    <LabelledTextField
                        label={'Course Name'}
                        id={'name'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={true}
                        value={newEntry.name || ''}
                        placeholder={'Enter course name'}
                        error={errors.name}
                        {...register('name')}
                        onChange={handleNameChange}
                    />
                    <LabelledTextField
                        label={'Course Description'}
                        id={'description'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={true}
                        multiline
                        rows={5}
                        value={newEntry.description || ''}
                        placeholder={'Enter course description'}
                        counter={'true'}
                        helperText={`${newEntry.description.length}` + '/' + CHARACTER_LIMIT}
                        inputProps={{
                            maxLength: CHARACTER_LIMIT
                        }}
                        error={errors.description}
                        {...register('description')}
                        onChange={handleDescriptionChange}
                    />
                    <RadioButtons
                        id={'activeTemplateRadio'}
                        label={'Is Current Template Active?'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        value={newEntry.active}
                        onChange={handleActiveChange}
                    />
                    <DDLMultiOptionPicker
                        heading={'Available to which Contracts'}
                        // key={isClearSelectedContract}
                        disabled={!hasRole(acceptedRoles, roles)}
                        id="selectedContracts"
                        label="Contracts"
                        placeholder="Contract"
                        mandatory={true}
                        error={errors.selectedContracts}
                        menuItems={contractDetails || []}
                        chosenIds={selectedContracts}
                        {...register('selectedContracts')}
                        onChange={(chosenIds) => handleContractChange(chosenIds)}
                    />
                    <RadioButtons
                        id={'healthInformationRadio'}
                        label={'Is Health Information Captured?'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        value={newEntry.healthInformationCaptured}
                        onChange={handleHealthInformationCaptured}
                    />
                    {/* Only show multi select ddl health condition if health information is to be captured */}
                    <div hidden={!newEntry.healthInformationCaptured}>
                        <DDLMultiOptionPicker
                            heading={'Health Details'}
                            key={isClearSelectedHealth}
                            disabled={!hasRole(acceptedRoles, roles)}
                            id="selectedHealth"
                            label="Health"
                            placeholder="Health"
                            mandatory={newEntry.healthInformationCaptured}
                            error={errors.selectedHealth}
                            menuItems={courseTemplateHealthDetails || []}
                            chosenIds={selectedHealth}
                            {...register('selectedHealth')}
                            onChange={(chosenIds) => handleHealthChange(chosenIds)}
                        />
                    </div>
                    <br />
                    <Typography variant="h5">Module Details:</Typography>
                    <br />
                    <RadioButtons
                        id={'multipleModulesRadio'}
                        label={'Does this Course have Multiple Modules?'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        value={newEntry.modularCourse}
                        onChange={handleMultipleChange}
                    />
                    <div hidden={modularCourse}>
                        <LabelledTextField
                            label={'Module Name'}
                            id={'moduleName'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={!modularCourse && true}
                            value={moduleName || ''}
                            placeholder={'Enter module name'}
                            error={errors.moduleName}
                            {...register('moduleName')}
                            onChange={handleModuleNameChange}
                        />
                        <LabelledTextField
                            label={'Module Description'}
                            id={'moduleDescription'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            multiline
                            rows={5}
                            value={moduleDescription}
                            placeholder={'Enter module description'}
                            counter={'true'}
                            helperText={`${moduleDescription.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            error={errors.moduleDescription}
                            {...register('moduleDescription')}
                            onChange={handleModuleDescriptionChange}
                        />
                        <RadioButtons
                            id={'smartTargetRadio'}
                            label={'Add Smart Target?'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.smartTargetFlag}
                            onChange={handleSmartTargetFlagChange}
                        />
                        <div hidden={!smartTarget}>
                            <Typography variant="h5">Smart Target Details:</Typography>
                            <br />
                            <LabelledTextField
                                label={'What shall we call this action?'}
                                id={'action'}
                                mandatory={smartTarget && true}
                                value={smartTargetName}
                                placeholder={'Enter the name of the action'}
                                error={errors.action}
                                {...register('action')}
                                onChange={handleSmartTargetNameChange}
                            />
                            <DDLOptionPicker
                                label={'Which barrier name aligns to this smart target?'}
                                id={'barrierNameId'}
                                key={isClearSelectedSmartTargetBarrierName}
                                disabled={selectedContracts.length === 0}
                                mandatory={smartTarget && true}
                                menuItems={barrierNameDetails || []}
                                error={errors.barrierNameId}
                                parentMessage={
                                    (selectedContracts.length === 0 &&
                                        'Please select at least 1 Contract') ||
                                    ''
                                }
                                {...register('barrierNameId')}
                                onChange={(chosenId) =>
                                    handleSmartTargetBarrierNameChange(chosenId)
                                }
                            />
                            <div>Write an action below using SMART</div>
                            <br />
                            <div>
                                <strong>S: </strong>What is the action?
                            </div>
                            <br />
                            <div>
                                <strong>M: </strong>What is the measure of success?
                            </div>
                            <br />
                            <div>
                                <strong>A: </strong>Is the action achievable in complexity and time
                                given?
                            </div>
                            <br />
                            <div>
                                <strong>R: </strong>Is the action relevant to the barrier it aligns
                                to?
                            </div>
                            <br />
                            <div>
                                <strong>T: </strong>When is the action due?
                            </div>
                            <br />
                            <LabelledTextField
                                label={'Action'}
                                id={'smartTargetAction'}
                                mandatory={smartTarget && true}
                                multiline
                                rows={5}
                                value={smartTargetAction}
                                placeholder={'Enter action description'}
                                counter={'true'}
                                helperText={
                                    `${smartTargetAction.length}` + '/' + ACTION_DESCRIPTION_LIMIT
                                }
                                inputProps={{ maxLength: ACTION_DESCRIPTION_LIMIT }}
                                error={errors.smartTargetAction}
                                {...register('smartTargetAction')}
                                onChange={handleSmartTargetActionChange}
                            />
                        </div>
                    </div>
                    <div hidden={!newEntry.modularCourse}>
                        <NoYesRadioPicker
                            disabled={!hasRole(acceptedRoles, roles)}
                            text={'Do the Modules Need to be in a Set Order?'}
                            radioButtonPick={newEntry.modulesInSetOrder || false}
                            onChange={handleModulesOrderChange}
                        />
                        <Typography variant="h5">Module Templates:</Typography>
                        <CardHandler
                            viewerType={MODULE_TEMPLATE_VIEWER}
                            cards={newEntry.moduleTemplates || []}
                            disabled={!hasRole(acceptedRoles, roles)}
                            itemName={'moduleTemplate'}
                            label={'Add a Module'}
                            data={barrierNameDetails || []}
                            sendAdd={addModuleTemplate}
                            // sendDelete={deleteModuleTemplate}
                            sendUpdate={updateModuleTemplate}></CardHandler>
                        <p>{}</p>
                    </div>
                    <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                    <Stack direction="row" spacing={4}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="testIdSubmitButton1"
                            endIcon={<KeyboardArrowRightIcon />}>
                            {'Submit Template'}
                        </Button>
                        <Link
                            label="Cancel X"
                            underline="always"
                            variant="body1"
                            color="primary"
                            onClick={() => onCancel()}
                            sx={{
                                paddingTop: SCREEN_SETTINGS.margin.large,
                                cursor: 'pointer'
                            }}>
                            Cancel X
                        </Link>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    );
};

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(5, 'Course name must be at least five characters.')
        .max(100, 'Course name must be 100 characters or less.'),
    description: Yup.string()
        .trim()
        .min(50, 'Course description must be at least fifty characters.'),
    selectedContracts: Yup.array().min(1, 'You must select at least one contract.').nullable(),
    health: Yup.boolean(),
    selectedHealth: Yup.array()
        .nullable()
        .when('health', {
            is: (health) => health === true,
            then: () => Yup.array().min(1, 'You must select at least one health condition.')
        }),
    singleModules: Yup.boolean(),
    moduleName: Yup.string().when('singleModules', {
        is: (singleModules) => singleModules === false,
        then: () =>
            Yup.string()
                .trim()
                .min(15, 'Module name must be at least fifteen characters.')
                .max(100, 'Module name must be 100 characters or less.')
    }),
    moduleDescription: Yup.string().when('singleModules', {
        is: (singleModules) => singleModules === false,
        then: () =>
            Yup.string().trim().min(50, 'Module description must be at least fifty characters.')
    }),
    smartTarget: Yup.boolean(),
    action: Yup.string().when('smartTarget', {
        is: (smartTarget) => smartTarget === true,
        then: () =>
            Yup.string()
                .trim()
                .min(15, 'Action must be at least fifteen characters.')
                .max(100, 'Action must be 100 characters or less.')
    }),
    barrierNameId: Yup.string().when('smartTarget', {
        is: (smartTarget) => smartTarget === true,
        then: () => Yup.string().required('Please select a barrier name.')
    }),
    smartTargetAction: Yup.string().when('smartTarget', {
        is: (smartTarget) => smartTarget === true,
        then: () =>
            Yup.string().trim().min(50, 'Action description must be at least fifty characters.')
    })
});

export default CreateCourseTemplate;
