import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadServiceDetailsForContractId, loadUploaderTypes } from '../../../store/directusService';
import { deletePostcodeCsvData } from '../../../store/documentService';
import {
    loadContractConstants,
    searchEligibilityGroups,
    updateContractConstants
} from '../../../store/participantService';
import { loadFormsByContract } from '../../../store/participantService';
import { selectUserRoles } from '../../../store/userSelectors';
import { loadTeamsForService } from '../../../store/userService';
import { updatePreSelects, updateSelectedItems } from '../../../utils/directusFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import TextInputField from '../../formElements/TextInputField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ConfirmPrompt from '../../ui/notices/confirmPrompt/ConfirmPrompt';
import PostcodeUploader from '../../uploaders/PostcodeUploader';

import { initialErrorState, validate } from './validateFormsDefaultsConfigAdmin';

import form from '../../../commonStyles/formStyles.module.css';
import local from './formsDefaultsConfigAdmin.module.css';

const FormsDefaultsConfigAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: '',
        contractId: '',
        doubtFormIds: [],
        complianceActivityFormQuestionnaireId: '',
        defaultTeamId: '',
        endFormTemplateId: '',
        defaultEligibilityGroupId: '',
        weekendAsWorkingDay: false,
        bankHolidayAsWorkingDay: false,
        uploaders: [],
        exitInterviewBookingPeriodDays: '',
        exitInterviewBookingDays: '',
        exitFormTemplateId: ''
    };

    const collectionName = 'uploader types';
    const urlName = 'uploader_type';
    const acceptedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [eligibilityGroups, setEligibilityGroups] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedUploaders, setSelectedUploaders] = useState([]);
    const [preSelectedUploaders, setPreSelectedUploaders] = useState([]);
    const [preSelectedUploaderIds, setPreSelectedUploaderIds] = useState([]);
    const [arrayUploaders, setArrayUploaders] = useState([]);
    const [batchUploaderId, setBatchUploaderId] = useState('');
    const [showPostcodeUploaderModal, setShowPostcodeUploaderModal] = useState(false);
    const [showPurgeConfirmPrompt, setShowPurgeConfirmPrompt] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [keys, setKeys] = useState({
        doubtFormIds: '0',
        complianceActivityFormQuestionnaireId: '1',
        defaultTeamId: '2',
        exitFormTemplateId: '3',
        endFormTemplateId: '4',
        defaultEligibilityGroupId: '5',
        uploaders: '6'
    });

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );
    const services = useSelector(
        (state) => state.entities.directusService.serviceDetailsForContractId
    );
    const teamDetails = useSelector((state) => state.entities.userService.teamsForService);
    const eligibilityGroupDetails = useSelector(
        (state) => state.entities.participantService.eligibilityGroupSearch
    );
    const forms = useSelector((state) => state.entities.participantService.formsByContract);
    const uploaders = useSelector((state) => state.entities.directusService.uploaderTypes);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (uploaders?.length && arrayUploaders?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(urlName, uploaders, contractId);
            setPreSelectedUploaders(preSelects);
            setPreSelectedUploaderIds(preSelectIds);
            setArrayUploaders(uploaders);
            setBatchUploaderId(
                uploaders.find((el) => el.name.toLowerCase() === 'batch uploader')?.id
            );
        }
    }, [uploaders]);

    useEffect(() => {
        if (services.length < 1 || teams.length > 0) return;
        const serviceIds = services.map((el) => el.id);
        dispatch(loadTeamsForService({ serviceIds }));
    }, [services]);

    useEffect(() => {
        if (eligibilityGroupDetails?.length < 1) return;
        setEligibilityGroups(eligibilityGroupDetails);
    }, [eligibilityGroupDetails]);

    useEffect(() => {
        if (teamDetails.length < 1) return;
        setTeams(teamDetails);
    }, [teamDetails]);

    useEffect(() => {
        if (
            Object.keys(contractConstants).length < 1 ||
            contractConstants.contractId !== contractId
        )
            return;
        setNewEntry({
            ...contractConstants,
            exitInterviewBookingPeriodDays: contractConstants.exitInterviewBookingPeriodDays || '',
            exitInterviewBookingDays: contractConstants.exitInterviewBookingDays || ''
        });
    }, [contractConstants]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${collectionName} collection`)) {
            contractSetup();
        }
    }, [successMessage]);

    // HELPER FNS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const clearValues = () => {
        setKeys(clearKeys(keys));
        setNewEntry(initialState);
        setEligibilityGroups([]);
        setTeams([]);
        setSelectedUploaders([]);
        setPreSelectedUploaders([]);
        setPreSelectedUploaderIds([]);
        setArrayUploaders([]);
        setErrors({});
    };

    const contractSetup = () => {
        setIsDisabled(false);
        clearValues();
        dispatch(loadContractConstants(contractId));
        dispatch(loadUploaderTypes());
        dispatch(loadServiceDetailsForContractId(contractId));
        dispatch(searchEligibilityGroups({ contractId }));
        dispatch(loadFormsByContract(contractId));
    };

    const confirmPostcodePurge = (_, doPurge) => {
        if (doPurge) onPostcodesPurge();
        setShowPurgeConfirmPrompt(false);
    };

    // EVENT HANDLERS

    const onUpdate = (key, value) => {
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onContractDoubtFormsChange = (chosenIds) => {
        setNewEntry((prev) => ({
            ...prev,
            doubtFormIds: chosenIds || []
        }));
    };

    const onComplianceActivityFormQuestionnaireChange = (chosenId) => {
        setNewEntry((prev) => ({
            ...prev,
            complianceActivityFormQuestionnaireId: chosenId || ''
        }));
    };

    const onDefaultTeamChange = (chosenId) => {
        clearError('defaultTeamId');
        const defaultTeam = teams.find((el) => el.id === chosenId);
        setNewEntry((prev) => ({
            ...prev,
            defaultTeamId: chosenId || '',
            defaultTeam: defaultTeam || {}
        }));
    };

    const onExitFormTemplateChange = (chosenId) => {
        clearError('exitFormTemplateId');
        const exitFormTemplate = forms.find((el) => el.id === chosenId);
        setNewEntry((prev) => ({
            ...prev,
            exitFormTemplateId: chosenId || '',
            exitFormTemplate: exitFormTemplate || {}
        }));
    };

    const onExitInterviewBookingPeriodDaysChange = (e) => {
        clearError('exitInterviewBookingPeriodDays');
        clearError('exitInterviewBookingDays');
        setNewEntry((prev) => ({
            ...prev,
            exitInterviewBookingPeriodDays: e.target.value.replace(/[^\d]/g, '').slice(0, 3)
        }));
    };

    const onExitInterviewBookingDaysChange = (e) => {
        clearError('exitInterviewBookingDays');
        clearError('exitInterviewBookingPeriodDays');
        setNewEntry((prev) => ({
            ...prev,
            exitInterviewBookingDays: e.target.value.replace(/[^\d]/g, '').slice(0, 3)
        }));
    };

    const onEndFormTemplateChange = (chosenId) => {
        clearError('endFormTemplateId');
        setNewEntry((prev) => ({
            ...prev,
            endFormTemplateId: chosenId || ''
        }));
    };

    const onDefaultEligibilityGroupChange = (chosenId) => {
        clearError('defaultEligibilityGroupId');
        setNewEntry((prev) => ({
            ...prev,
            defaultEligibilityGroupId: chosenId || ''
        }));
    };

    const onPostcodeUploaderOpen = () => {
        setShowPostcodeUploaderModal(true);
    };

    const onPostcodeUploaderModalClose = (e, reason) => {
        if (reason && reason === 'backdropClick') return;
        setShowPostcodeUploaderModal(false);
    };

    const onCancelPostcodeUploaderModal = (e, reason) => {
        if (reason && reason === 'backdropClick') return;
        setShowPostcodeUploaderModal(false);
    };

    const onPostcodesPurge = () => {
        const message = `Postcode data for contractId: ${contractId} has been deleted`;
        dispatch(deletePostcodeCsvData(contractId, message));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const {
            /* eslint-disable no-unused-vars */
            defaultTeam,
            defaultEligibilityGroup,
            contractDoubtForm,
            complianceActivityFormQuestionnaire,
            endFormTemplate,
            exitFormTemplate,
            exitInterviewBookingPeriodDays,
            exitInterviewBookingDays,
            ...rest
        } = newEntry;
        const values = {
            ...rest,
            exitInterviewBookingPeriodDays:
                exitInterviewBookingPeriodDays === '' ? null : exitInterviewBookingPeriodDays,
            exitInterviewBookingDays:
                exitInterviewBookingDays === '' ? null : exitInterviewBookingDays
        };
        const { newErrors } = validate(values, contractConstants);
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const payload = { ...values, contractId };
        dispatch(updateContractConstants(payload));
        updateSelectedItems(
            urlName,
            uploaders,
            selectedUploaders,
            contractId,
            collectionName,
            dispatch
        );
        setIsDisabled(true);
    };

    // AWAITING CONTENT
    let content = '';
    if (!contractId) content = 'No Contract Id';
    if (!forms) content = 'No forms';
    // RENDER
    if (!contractId || !forms) return <LoadingSpinner content={content} />;

    return (
        <>
            <form className={form.formWrapper} onSubmit={onSubmit} data-testid="form_start">
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="doubtFormIds"
                            key={keys.doubtFormIds}
                            label="Contract Doubt Forms"
                            placeholder="Select forms"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={forms || []}
                            preSelectedIds={newEntry.doubtFormIds || []}
                            preSelects={
                                forms.filter((el) => newEntry.doubtFormIds.includes(el.id)) || []
                            }
                            onChange={(chosenIds) => onContractDoubtFormsChange(chosenIds)}
                        />

                        <SingleSelect
                            id="complianceActivityFormQuestionnaireId"
                            key={keys.complianceActivityFormQuestionnaireId}
                            label="Compliance Activity Form Questionnaire"
                            placeholder="Select a form"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={forms || []}
                            selectedId={newEntry.complianceActivityFormQuestionnaireId || ''}
                            selected={
                                forms.find(
                                    (el) => el.id === newEntry.complianceActivityFormQuestionnaireId
                                ) || {}
                            }
                            onChange={(chosenId) =>
                                onComplianceActivityFormQuestionnaireChange(chosenId)
                            }
                        />

                        <SingleSelect
                            id="defaultTeamId"
                            key={keys.defaultTeamId}
                            label="Default Team"
                            placeholder="Select a default team"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={teams || []}
                            selectedId={newEntry.defaultTeamId || ''}
                            selected={teams.find((el) => el.id === newEntry.defaultTeamId) || {}}
                            error={errors.defaultTeamId}
                            onChange={(chosenId) => onDefaultTeamChange(chosenId)}
                        />

                        <SingleSelect
                            id="exitFormTemplateId"
                            key={keys.exitFormTemplateId}
                            label="Exit Form Template"
                            placeholder="Select a form"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={forms || []}
                            selectedId={newEntry.exitFormTemplateId || ''}
                            selected={
                                forms.find((el) => el.id === newEntry.exitFormTemplateId) || {}
                            }
                            onChange={(chosenId) => onExitFormTemplateChange(chosenId)}
                        />

                        <TextInputField
                            id={'exitInterviewBookingPeriodDays'}
                            label={'Exit Interview Booking Period Days'}
                            placeholder={'Enter number of days'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={String(newEntry.exitInterviewBookingPeriodDays) || ''}
                            error={errors.exitInterviewBookingPeriodDays}
                            onChange={(e) => onExitInterviewBookingPeriodDaysChange(e)}
                        />

                        <TextInputField
                            id={'exitInterviewBookingDays'}
                            label={'Exit Interview Booking Days'}
                            placeholder={'Enter number of days'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={String(newEntry.exitInterviewBookingDays) || ''}
                            error={errors.exitInterviewBookingDays}
                            onChange={(e) => onExitInterviewBookingDaysChange(e)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id="endFormTemplateId"
                            key={keys.endFormTemplateId}
                            label="End Form Template"
                            placeholder="Select a form"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={forms || []}
                            selectedId={newEntry.endFormTemplateId || ''}
                            selected={
                                forms.find((el) => el.id === newEntry.endFormTemplateId) || {}
                            }
                            onChange={(chosenId) => onEndFormTemplateChange(chosenId)}
                        />

                        <SingleSelect
                            id="defaultEligibilityGroupId"
                            key={keys.defaultEligibilityGroupId}
                            label="Default Eligibility Group"
                            placeholder="Select a default eligibility group"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={eligibilityGroups || []}
                            selectedId={newEntry.defaultEligibilityGroupId || ''}
                            selected={
                                eligibilityGroups.find(
                                    (el) => el.id === newEntry.defaultEligibilityGroupId
                                ) || {}
                            }
                            error={errors.defaultEligibilityGroupId}
                            onChange={(chosenId) => onDefaultEligibilityGroupChange(chosenId)}
                        />

                        <MultiSelect
                            id="uploaders"
                            key={keys.uploaders}
                            label="Uploaders"
                            placeholder="Select uploaders"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={uploaders || []}
                            preSelectedIds={preSelectedUploaderIds}
                            preSelects={preSelectedUploaders}
                            chosenIds={selectedUploaders}
                            onChange={(chosenIds) => setSelectedUploaders(chosenIds)}
                        />

                        {selectedUploaders.includes(batchUploaderId) && (
                            <div className={local.twinButtonWrapper}>
                                <Button
                                    id="postcodeUploaderButton"
                                    type="button"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    content="Attach Postcode Uploader"
                                    icon={true}
                                    onClick={onPostcodeUploaderOpen}
                                />

                                <Button
                                    id="purgePostcodesButton"
                                    type="button"
                                    content="Purge Postcodes"
                                    onClick={() => setShowPurgeConfirmPrompt(true)}
                                />
                            </div>
                        )}

                        <RadioButtons
                            id="weekendAsWorkingDay"
                            label="Are Weekends Working Days?"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.weekendAsWorkingDay || false}
                            onChange={(option) => onUpdate('weekendAsWorkingDay', option)}
                        />

                        <RadioButtons
                            id="bankHolidayAsWorkingDay"
                            label="Are Bank Holidays Working Days?"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.bankHolidayAsWorkingDay || false}
                            onChange={(option) => onUpdate('bankHolidayAsWorkingDay', option)}
                        />
                    </div>
                </div>

                <Button
                    id="formsDefaultsConfigAdminButton"
                    content="UPDATE FORMS, DEFAULTS & CONFIGURATION"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
            {showPostcodeUploaderModal && (
                <PostcodeUploader
                    contractId={contractId}
                    onClose={onPostcodeUploaderModalClose}
                    onCancel={onCancelPostcodeUploaderModal}
                    isOpen={showPostcodeUploaderModal}
                />
            )}
            {showPurgeConfirmPrompt && (
                <ConfirmPrompt
                    isOpen={showPurgeConfirmPrompt}
                    onCancel={(e) => confirmPostcodePurge(e, false)}
                    onConfirm={(e) => confirmPostcodePurge(e, true)}
                    content="By clicking 'Yes', all postcodes for this contract will be purged. This action cannot be undone. Are you sure you want to continue?"
                    customClass="formatContent"
                />
            )}
        </>
    );
};

FormsDefaultsConfigAdmin.propTypes = {
    contractId: PropTypes.string
};

export default FormsDefaultsConfigAdmin;
