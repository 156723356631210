import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    loadInterventionTypes,
    loadSmokingCoResults,
    loadSmokingSelfReportedQuits
} from '../../../../store/directusService';
import {
    deleteSmokingQuitAttemptReview,
    onDeleteSmokingQuitAttemptReview
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CreateQuitReview from './CreateQuitReview';
import QuitReviewTable from './QuitReviewTable';

import app from '../../../../app.module.css';
import local from '../wellbeing.module.css';

const QuitReviewManagement = ({ quitDate, smokingQuitAttemptId }) => {
    const dispatch = useDispatch();
    const formRef = useRef(null);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Smoking quit attempt review deleted`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const quitReviews = useSelector(
        (state) => state.entities.participantService.smokingQuitAttemptReviews[smokingQuitAttemptId]
    );
    const interventionTypes = useSelector(
        (state) => state.entities.directusService.interventionTypes
    );
    const selfReportedQuits = useSelector(
        (state) => state.entities.directusService.smokingSelfReportedQuits
    );
    const coResults = useSelector((state) => state.entities.directusService.smokingCoResults);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS

    useEffect(() => {
        interventionTypes?.length < 1 && dispatch(loadInterventionTypes());
        selfReportedQuits?.length < 1 && dispatch(loadSmokingSelfReportedQuits());
        coResults?.length < 1 && dispatch(loadSmokingCoResults());
    }, []);

    useEffect(() => {
        if (!quitReviews) return;
        const rows = quitReviews
            .filter((el) => !el.inactive)
            .map((el) => ({
                ...el,
                eventDate: reverseFormatDate(el.eventDate),
                intervention:
                    interventionTypes.find((entry) => entry.id === el.interventionTypeId)?.name ||
                    '',
                selfReportedQuit:
                    selfReportedQuits.find((entry) => entry.id === el.selfReportedQuitId)?.name ||
                    '',
                coResult: coResults.find((entry) => entry.id === el.coResultId)?.name || ''
            }));
        setRows(rows);
    }, [quitReviews, interventionTypes, selfReportedQuits, coResults]);

    useEffect(() => {
        if (successMessage === deleteMsg)
            dispatch(onDeleteSmokingQuitAttemptReview(smokingQuitAttemptId, selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteSmokingQuitAttemptReview(row.id, deleteMsg));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Quit Reviews</div>
            <div>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No quit reviews found" />
                ) : (
                    <QuitReviewTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
            </div>
            <div className={local.actions} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={!hasRole(acceptedRoles, roles)}
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Quit Review
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreateQuitReview
                            onClose={() => setShowForm(false)}
                            roles={roles}
                            smokingQuitAttemptId={smokingQuitAttemptId}
                            quitDate={quitDate}
                            type={formType}
                            quitAttemptReview={selectedRow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuitReviewManagement;

QuitReviewManagement.propTypes = {
    quitDate: PropTypes.string,
    smokingQuitAttemptId: PropTypes.string
};
