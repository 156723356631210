import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadServiceDetails } from '../../store/directusService';
import { createTeam } from '../../store/userService';
import { clearKeys } from '../../utils/objectUtils';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import Button from '../formElements/Button';
import SingleSelect from '../formElements/SingleSelect';
import TextInputField from '../formElements/TextInputField';
import NotFound from '../notFound/NotFound';
import LoadingSpinner from '../ui/LoadingSpinner';

import { checkTeamName, initialErrorState, validate } from './validateCreateTeam';

import app from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const CreateTeam = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        name: '',
        serviceId: '',
        costCode: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [keys, setKeys] = useState({ serviceId: '0' });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const serviceDetails = useSelector((state) => state.entities.directusService.serviceDetails);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadServiceDetails());
    }, []);

    useEffect(() => {
        if (successMessage.includes(`Created team`)) {
            dispatch(loadServiceDetails());
            clearForm();
        }
    }, [successMessage]);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
        setIsDisabled(false);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onNameChange = (e) => {
        clearError('name');
        isDisabled && setIsDisabled(false);
        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
    };

    const onServiceChange = (chosenId) => {
        clearError('serviceId');
        setNewEntry((prev) => ({ ...prev, serviceId: chosenId }));
    };

    const onCostCodeChange = (e) => {
        clearError('costCode');
        setNewEntry((prev) => ({ ...prev, costCode: e.target.value }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { isValid, errors } = validate(newEntry);
        setErrors(errors);
        if (!isValid) return;
        setIsDisabled(true);
        const isExistingTeamName = await checkTeamName(newEntry.name);
        if (isExistingTeamName) {
            setErrors({
                ...errors,
                name: { error: true, message: `Team ${newEntry.name} already exists.` }
            });
            return;
        }
        dispatch(createTeam(newEntry.name, newEntry));
    };

    // RENDER
    if (!hasRole([SUPERUSER], roles)) return <NotFound />;
    if (serviceDetails?.length < 1) return <LoadingSpinner content="No services" />;

    return (
        <div className={form.formWrapper} data-testid="form_start">
            <form className={form.form} onSubmit={onSubmit}>
                <h2 className={app.mainHeading}>Create Team</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'name'}
                            label={'Name'}
                            placeholder={'Enter team name'}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={onNameChange}
                        />
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            key={keys.serviceId}
                            id={'serviceId'}
                            label={'Service'}
                            placeholder="Search services..."
                            mandatory={true}
                            menuItems={serviceDetails || []}
                            selectedId={newEntry.serviceId || ''}
                            selected={
                                serviceDetails.find((el) => el.id === newEntry.serviceId) || {}
                            }
                            error={errors.serviceId}
                            onChange={(chosenId) => onServiceChange(chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'costCode'}
                            label={'Cost Code'}
                            placeholder={'Enter cost code'}
                            value={newEntry.costCode || ''}
                            error={errors.costCode}
                            onChange={onCostCodeChange}
                        />
                    </div>
                </div>
                <Button id="createTeam" content="Create" disabled={isDisabled} />
            </form>
        </div>
    );
};

export default CreateTeam;
