import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import InfoIcon from '@mui/icons-material/Info';
import { Checkbox, TableCell, TableRow, Tooltip } from '@mui/material';

import { selectLoggedInUser, selectUserName } from '../../../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../../../themes/theme';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';

import classes from '../../../../../commonStyles/tables.module.css';
import poolClasses from '../../submissionStyles/pools.module.css';

const SelectPoolsTableRow = ({ row, onRowChange }) => {
    // HOOKS
    const onSelectUserName = useMemo(selectUserName, []);

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const adviser = useSelector((state) => onSelectUserName(state, row.ownerId));

    // EVENT HANDLERS
    const onCheck = (e) => {
        const { checked } = e.target;
        const updatedRow = {
            ...row,
            update: checked
        };
        onRowChange(updatedRow);
    };

    // RENDER
    return (
        <>
            <TableRow key={row.id} hover className={classes.basicRowStripes}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{adviser}</TableCell>
                <TableCell align="center">
                    <Tooltip title={row.requirements}>
                        <InfoIcon sx={{ color: POLARIS_ROYAL_BLUE }} width={20} height={20} />
                    </Tooltip>
                </TableCell>
                <TableCell disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                    <div className={poolClasses.check}>
                        <Checkbox
                            key={row.id}
                            type="checkbox"
                            sx={{ color: POLARIS_ROYAL_BLUE }}
                            checked={row.update}
                            onClick={onCheck}
                        />
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SelectPoolsTableRow;

SelectPoolsTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onRowChange: PropTypes.func
};
