import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';

import { setErrorMessage } from '../../../../store/formsState';
import {
    POLARIS_DARKER_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LabelledTextField from '../../editors/LabelledTextField';
import DDLOptionPicker from '../../pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../pickers/NoYesRadioPicker';

import classes from '../../cards/cardStyles/pastEducation.module.css';

/**
 * A dialog to edit Past Education details. See PPL-342 for details
 *
 * @param cardData - Data for this editor
 * This has values which match the past education table in the participant service
 * @param disabled Set true to disable editing (default = false)
 * @param sendChange Callback function to tell parent that data has changed.
 * @param qualificationTypeDetails  Qualification type information.
 *
 * @returns {JSX.Element}
 */
const PastEducationEditor = ({
    cardData,
    disabled = false,
    sendChange,
    qualificationTypeDetails
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    // State for past education
    const initialState = {
        id: null,
        name: null,
        qualificationTypeId: null,
        subjects: [
            {
                grade: null,
                subject: null
            }
        ],
        startDate: '',
        onGoing: false,
        endDate: ''
    };
    const MAX_SUBJECT_LENGTH = 100;
    const MAX_GRADE_LENGTH = 20;
    const [newEntry, setNewEntry] = useState(initialState);
    const [open, setOpen] = React.useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [formValues, setFormValues] = useState([{ subject: '', grade: '' }]);
    const [isClearSelectedQualificationType, setIsClearSelectedQualificationType] = useState('1');
    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;

    const dispatch = useDispatch();

    // First time on card and blank so must be new.
    // Open dialog to force user to enter data
    useEffect(() => {
        if (!cardData.name) {
            if (disabled === false) {
                setOpen(true);
            }
        }
    }, []);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);

    // EVENT HANDLERS
    const handleNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
    };
    const handleQualificationTypeIdChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, qualificationTypeId: chosenId }));
        setValue('qualificationTypeId', chosenId, { shouldValidate: true });
    };

    const handleSubjectChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setNewEntry((prev) => ({ ...prev, subjects: newFormValues }));
    };

    const handleStartDateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, startDate: e.target.value }));
    };

    const handleOngoingChange = (option) => {
        if (option) {
            setIsDisabled(true);
            setNewEntry((prev) => ({ ...prev, onGoing: true }));
            setNewEntry((prev) => ({ ...prev, endDate: null }));
        } else {
            setIsDisabled(false);
            setNewEntry((prev) => ({ ...prev, onGoing: false }));
            reset({
                endDate: ''
            });
        }
    };

    const handleEndDateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, endDate: e.target.value }));
    };

    const handleClickOpen = () => {
        if (disabled === false) {
            setOpen(true);
        }
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        // Reset info to cardData
        setCardData();
        reset();
        clearErrors();
        setIsClearSelectedQualificationType(Math.random());

        setOpen(false);
    };

    const handleUpdate = () => {
        clearErrors();
        setNewEntry((prev) => ({ ...prev, subjects: formValues }));
        sendChange(newEntry);
        setOpen(false);
    };

    const setCardData = () => {
        setNewEntry((prev) => ({
            ...prev,
            id: cardData.id,
            name: cardData.name,
            qualificationTypeId: cardData.qualificationTypeId,
            subjects: cardData.subjects,
            startDate: cardData.startDate?.substring(0, 10),
            onGoing: cardData.onGoing || false,
            endDate: cardData.endDate?.substring(0, 10) || null
        }));
        // populateSubjects();
    };

    // HELPER FNS
    const addFormFields = () => {
        setFormValues([...formValues, { subject: '', grade: '' }]);
    };

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const getName = (id) => {
        if (!id) {
            return '';
        }
        return qualificationTypeDetails.find((item) => item.id === id)?.name || '';
    };

    function populateSubjects() {
        let newFormValues = [];
        cardData.subjects.map((el, idx) => {
            if (idx === 0) {
                newFormValues = [{ subject: el.subject, grade: el.grade }];
            } else {
                newFormValues.push({
                    subject: el.subject,
                    grade: el.grade
                });
            }
            setFormValues(newFormValues);
        });
    }

    function checkIfDuplicateSubjectExists(arr) {
        const uniqueValues = [...new Set(arr.map((v) => v.subject))];
        return uniqueValues.length !== arr.length;
    }

    // Set info from cardData
    React.useEffect(() => {
        populateSubjects();

        if (!cardData.onGoing) {
            handleOngoingChange(false);
        } else {
            handleOngoingChange(cardData.onGoing);
        }

        setCardData();
    }, []);

    const onSubmit = () => {
        if (newEntry.subjects.length < 1 || newEntry.subjects[0].subject === '') {
            dispatch(setErrorMessage(`At least one subject must be entered.`));
        } else if (newEntry?.endDate < newEntry.startDate) {
            dispatch(setErrorMessage('End date must not be earlier than start date'));
        } else if (
            formValues.some(
                (c) => c.subject.length > MAX_SUBJECT_LENGTH || c.grade.length > MAX_GRADE_LENGTH
            )
        ) {
            dispatch(
                setErrorMessage(
                    `Subject can be a maximum of ${MAX_SUBJECT_LENGTH} characters and grades a maximum of ${MAX_GRADE_LENGTH} characters.`
                )
            );
        } else if (checkIfDuplicateSubjectExists(newEntry.subjects)) {
            dispatch(setErrorMessage('Subjects must be unique'));
        } else {
            handleUpdate();
        }
    };

    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <EditIcon
                onClick={handleClickOpen}
                sx={{ color: iconColor }}
                data-testid="editor-icon"></EditIcon>

            <Dialog open={open} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Past Education</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <LabelledTextField
                            id="name"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            mandatory={true}
                            label="Institution Name"
                            value={newEntry.name || ''}
                            placeholder="Enter name..."
                            error={errors.name}
                            {...register('name')}
                            onChange={handleNameChange}
                        />
                        <DDLOptionPicker
                            label={'Qualification Type'}
                            id="qualificationTypeId"
                            key={isClearSelectedQualificationType}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            mandatory={true}
                            menuItems={qualificationTypeDetails}
                            chosenName={getName(newEntry.qualificationTypeId)}
                            chosenId={newEntry.qualificationTypeId}
                            error={errors.qualificationTypeId}
                            {...register('qualificationTypeId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleQualificationTypeIdChange(e);
                                }
                            }}
                        />
                        {formValues.map((el, i, arr) => (
                            <div key={i}>
                                <TextField
                                    id="subject"
                                    name="subject"
                                    sx={{
                                        width: '55%',
                                        margin: '5px 10px 5px 0',
                                        borderBottom: `${
                                            el.subject.length > MAX_SUBJECT_LENGTH ||
                                            arr.find(
                                                (entry, idx) =>
                                                    entry.subject === el.subject && idx < i
                                            )
                                                ? '1px solid red'
                                                : ''
                                        }`,
                                        borderRadius: '5px'
                                    }}
                                    disabled={
                                        !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)
                                    }
                                    size="small"
                                    label="Subject *"
                                    value={el.subject || ''}
                                    placeholder="Enter subject..."
                                    onChange={(e) => handleSubjectChange(i, e)}
                                />
                                <TextField
                                    id="grade"
                                    name="grade"
                                    sx={{
                                        width: '35%',
                                        margin: '5px 3px 5px 0',
                                        borderBottom: `${
                                            el.grade.length > MAX_GRADE_LENGTH
                                                ? '1px solid red'
                                                : ''
                                        }`,
                                        borderRadius: '5px'
                                    }}
                                    disabled={
                                        !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)
                                    }
                                    size="small"
                                    label="Grade"
                                    value={el.grade || ''}
                                    placeholder="Enter grade..."
                                    onChange={(e) => handleSubjectChange(i, e)}
                                />
                                {i ? (
                                    <DeleteIcon
                                        onClick={() => removeFormFields(i)}
                                        sx={{
                                            color: POLARIS_ROYAL_BLUE,
                                            marginTop: '10px'
                                        }}></DeleteIcon>
                                ) : (
                                    <DeleteIcon
                                        disabled={true}
                                        sx={{
                                            color: POLARIS_DARKER_GREY,
                                            marginTop: '10px'
                                        }}></DeleteIcon>
                                )}
                            </div>
                        ))}
                        <Button
                            className={classes.addGradeButton}
                            disabled={
                                !hasRole([SUPERUSER, ADVISER, MANAGER, QUALITY], roles) ||
                                formValues[formValues.length - 1].subject === '' ||
                                formValues.length === 10
                            }
                            color="primary"
                            variant="contained"
                            size={'small'}
                            onClick={() => addFormFields()}>
                            Add Subject
                            <Box width={SCREEN_SETTINGS.gap.medium} />
                            <AddCircleOutlineIcon />
                        </Button>
                        <p></p>
                        <br />
                        <TextField
                            id="startDate"
                            label="Start Date"
                            type="date"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            value={newEntry.startDate || ''}
                            sx={{ width: 220, marginBottom: '20px' }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleStartDateChange}
                        />
                        <br />
                        <NoYesRadioPicker
                            id="ongoing"
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            radioButtonPick={newEntry.onGoing || false}
                            text={'Education Ongoing?'}
                            onChange={handleOngoingChange}></NoYesRadioPicker>
                        <br />
                        <TextField
                            id="endDate"
                            label="End Date"
                            type="date"
                            disabled={
                                isDisabled ||
                                !hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)
                            }
                            value={newEntry.endDate || ''}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleEndDateChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Update
                        </Button>
                        <Button
                            onClick={handleCancel}
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(1, 'Institution name name must be at least one character')
        .max(200, 'Institution name must be 200 characters or less'),
    qualificationTypeId: Yup.string().required('Please select a qualification type.')
});

PastEducationEditor.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendChange: PropTypes.func.isRequired,
    qualificationTypeDetails: PropTypes.array.isRequired
};
export default PastEducationEditor;
