import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadAppointmentTypeDetails,
    loadAttendanceDetails
} from '../../../../../store/directusService';
import { loadPoolsByJobSector } from '../../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../../store/userSelectors';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import LoadingSpinner from '../../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';

import SelectPoolsTable from './SelectPoolsTable';

import classes from '../../submissionStyles/pools.module.css';

const SelectPoolsTableManagement = ({ onRowsUpdated, poolApplications }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];
    const [updatedRows, setUpdatedRows] = useState([]);
    const [selectedJobSector, setSelectedJobSector] = useState('');
    const [clearSelectedJobSector, setClearSelectedJobSector] = useState('0');

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const loggedInUser = useSelector(selectLoggedInUser);
    const { jobSectorDetails, poolStatusDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const selectedPools = useSelector((state) => state.entities.recruitmentService.pools);

    // EVENT HANDLERS

    // HELPER FNS
    const handleJobSectorChange = (chosenId) => {
        setSelectedJobSector(chosenId);
    };

    const onRowChange = (row) => {
        const rows = updatedRows.map((el) => (el.id === row.id ? row : el));
        setUpdatedRows(rows);
        onRowsUpdated(rows);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAttendanceDetails);
        dispatch(loadAppointmentTypeDetails());
        setUpdatedRows([]);
        setClearSelectedJobSector(Math.random());
    }, []);

    useEffect(() => {
        if (!selectedJobSector) {
            setUpdatedRows([]);
        } else {
            dispatch(loadPoolsByJobSector(selectedJobSector));
        }
    }, [selectedJobSector]);

    useEffect(() => {
        if (selectedPools?.length < 1 || !selectedJobSector) {
            setUpdatedRows([]);
            return;
            // }
        }
        const poolsForJobSectorWithStatusId = selectedPools.map((el) => ({
            ...el,
            statusId:
                poolApplications?.find((entry) => el.id === entry.participantPoolId)?.statusId || ''
        }));
        setUpdatedRows(
            poolsForJobSectorWithStatusId
                .filter(
                    (el) =>
                        (el.teamIds.some((entry) => entry === currentParticipant.teamId) &&
                            el.statusId === '') ||
                        getNameFromId(poolStatusDetails, el.statusId).toLowerCase() === 'rejected'
                )
                .map((el) => ({
                    ...el,
                    update: false
                }))
        );
    }, [selectedPools]);

    // RENDER
    return (
        <>
            <div className={classes.poolsToolBar}>
                <DDLOptionPicker
                    label={'Job Sector'}
                    id={'jobSectorId'}
                    key={clearSelectedJobSector}
                    mandatory={true}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    menuItems={jobSectorDetails}
                    onChange={(e) => {
                        if (e !== null && e !== undefined) {
                            handleJobSectorChange(e);
                        }
                    }}
                />
            </div>

            {updatedRows.length < 1 ? (
                <LoadingSpinner content="No pools found" />
            ) : (
                <SelectPoolsTable
                    rows={updatedRows}
                    roles={loggedInUser.roles}
                    onRowChange={onRowChange}
                />
            )}
        </>
    );
};

SelectPoolsTableManagement.propTypes = {
    onRowsUpdated: PropTypes.func,
    poolApplications: PropTypes.array
};

export default SelectPoolsTableManagement;
