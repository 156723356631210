import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import OnEditProvisionIcon from '../../../icons/OnEditProvisionIcon';
import { loadCourseProvisions, setCurrentProvision } from '../../../store/courseService';
import { loadProvisionStatusDetails } from '../../../store/directusService';
import { searchParticipants } from '../../../store/participantService';
import { capitalize } from '../../../utils/capitalize';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

import FullProvisionAttendanceDialog from './FullProvisionAttendanceDialog';

import classes from '../../../commonStyles/tables.module.css';
import attendanceClasses from '../../ui/cards/cardStyles/cardAttendanceView.module.css';

const ProvisionTableRow = ({ row, roles }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const acceptedRoles = [MANAGER, QUALITY, SUPERUSER];
    const [openFullProvisionDialog, setOpenFullProvisionDialog] = useState(false);
    const [rows, setRows] = useState(false);
    const [ptIds, setPtIds] = useState([]);

    // STORE STATE
    const { participantsSearch, participantsSearchMetaData } = useSelector(
        (state) => state.entities.participantService
    );

    const onAttendance = (row) => {
        if (!('participants' in row)) return;
        const ids = row.participants.map((el) => el.participantId);
        if (ids.length > 0) {
            dispatch(searchParticipants({ ids }));
            dispatch(loadProvisionStatusDetails());
            setPtIds(ids);
        }
        setOpenFullProvisionDialog(!openFullProvisionDialog);
    };

    useEffect(() => {
        if (participantsSearch.length < 1 || row.participants.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(searchParticipants({ ids: ptIds }, participantsSearchMetaData.number + 1));
        } else {
            setRows(
                row.participants.map((el) => {
                    const ptEntry = participantsSearch.find(
                        (entry) => entry.id === el.participantId
                    );
                    return {
                        id: el.participantId,
                        contractId: ptEntry ? ptEntry.contractId : '',
                        ptCode: ptEntry ? ptEntry.ptCode : '',
                        firstName: ptEntry ? ptEntry.firstName : '',
                        lastName: ptEntry ? ptEntry.lastName : '',
                        dateSubmitted: el.dateSubmitted,
                        status: el.statusId,
                        payload: row
                    };
                })
            );
        }
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (!openFullProvisionDialog) {
            dispatch(loadCourseProvisions());
        }
    }, [openFullProvisionDialog]);

    const onEditRow = () => {
        dispatch(setCurrentProvision(row));
        navigate('/edit_provision', { state: { id: row.id, accordionPanel: 'provisions' } });
    };

    return (
        <TableRow key={row.id} hover className={classes.basicRowStripes}>
            <TableCell>{capitalize(row.title)}</TableCell>
            <TableCell>{row.providerName}</TableCell>
            <TableCell>{row.providerContactName}</TableCell>
            <TableCell>£{row.costPerParticipant}</TableCell>
            <TableCell>{reverseFormatDate(row.reviewDate)}</TableCell>
            <TableCell>{reverseFormatDate(row.dateCreated)}</TableCell>
            <TableCell onClick={onAttendance}>
                {' '}
                <a className={attendanceClasses.attlink} href="#" onClick={() => onAttendance(row)}>
                    Submission list
                </a>
                {openFullProvisionDialog && (
                    <FullProvisionAttendanceDialog
                        rows={rows}
                        openFullProvisionDialog={openFullProvisionDialog}
                        setOpenFullProvisionDialog={setOpenFullProvisionDialog}
                    />
                )}
            </TableCell>
            <TableCell align="right">
                <OnEditProvisionIcon
                    onEdit={onEditRow}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                />
            </TableCell>
        </TableRow>
    );
};

export default ProvisionTableRow;

ProvisionTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string)
};
