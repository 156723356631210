import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    selectFinancialPaymentMethodType,
    selectFinancialRequestStatus,
    selectFinancialRequestType
} from '../../../../store/dataSelectors';
import {
    loadFinancialPaymentMethodDetailsByPaymentMethodType,
    loadFinancialPaymentMethodTypeDetails,
    loadFinancialRequestReasonDetailsByRequestType,
    loadFinancialRequestStatusDetails,
    loadFinancialRequestTypeDetails
} from '../../../../store/directusService';
import { setErrorMessage } from '../../../../store/formsState';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import {
    updateFinancialIssueRequest,
    updateFinancialRequest
} from '../../../../store/participantService';
import { selectLoggedInUser, selectUsersById } from '../../../../store/userSelectors';
import { loadUserById, searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { getConfiguredItems, getNameFromId } from '../../../../utils/directusFunctions';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import * as validate from '../../../../validation/validation';
import SingleSelect from '../../../formElements/SingleSelect';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../ui/pickers/NoYesRadioPicker';

import form from '../../../../commonStyles/formStyles.module.css';
import actions from '../../../ui/formActions/formActions.module.css';

const REASON_DETAILS_LIMIT = 500;
const AUTHORISER_NOTES_LIMIT = 500;
const EditFinancialRequest = ({ onClose, roles, row }) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        code: null,
        adviserId: null,
        amount: null,
        approverId: null,
        requestTypeId: null,
        paymentMethodId: null,
        paymentMethodTypeId: null,
        paymentReasonId: null,
        dateIssued: null,
        dateRequested: null,
        statusChangeDate: '',
        taxNiIssued: false,
        reasonDetail: '',
        approverNote: '',
        statusId: null,
        participantId: null
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const advisorRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER, SUPERUSER];
    const [advisers, setAdvisers] = useState([]);
    const [arrayFinancialRequestType, setArrayFinancialRequestType] = useState([]);
    const [arrayPaymentMethodType, setArrayPaymentMethodType] = useState([]);
    const [requestReasonRequired, setRequestReasonRequired] = useState(false);
    const [paymentMethodRequired, setPaymentMethodRequired] = useState(false);

    const [isClearSelectedAdviser, setIsClearSelectedAdviser] = useState('0');
    const [isClearSelectedRequestType, setIsClearSelectedRequestType] = useState('1');
    const [isClearSelectedRequestReason, setIsClearSelectedRequestReason] = useState('2');
    const [isClearSelectedPaymentMethodType, setIsClearSelectedPaymentMethodType] = useState('3');
    const [isClearSelectedPaymentMethod, setIsClearSelectedPaymentMethod] = useState('4');

    const [approveDisable, setApproveDisable] = useState(false);
    const [declineDisable, setDeclineDisable] = useState(false);
    const [withdrawDisable, setWithdrawDisable] = useState(false);

    let payload;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const financialPaymentMethodTypeDetails = useSelector(selectFinancialPaymentMethodType);
    const financialRequestStatusDetails = useSelector(selectFinancialRequestStatus);
    const financialRequestTypeDetails = useSelector(selectFinancialRequestType);
    const { financialRequestReasonDetails, financialPaymentMethodDetails } = useSelector(
        (state) => state.entities.directusService
    );

    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );

    const usersById = useSelector(selectUsersById);

    // EVENT HANDLERS
    const handleAdviserChange = (chosenId) => {
        if (chosenId && chosenId !== row.adviserId) disableButtonsOnEdit();
        setNewEntry((prev) => ({ ...prev, adviserId: chosenId }));
    };

    const handleAmount = (e) => {
        disableButtonsOnEdit();
        setNewEntry((prev) => ({ ...prev, amount: e.target.value }));
    };

    const handleRequestTypeChange = (chosenId) => {
        if (chosenId && chosenId !== row.requestTypeId) disableButtonsOnEdit();
        setNewEntry((prev) => ({ ...prev, requestTypeId: chosenId }));
        setNewEntry((prev) => ({ ...prev, paymentReasonId: '' }));
        setIsClearSelectedRequestReason(Math.random());

        clearErrors('requestTypeId,');
        clearErrors('financialRequestReasonId');

        if (chosenId) {
            dispatch(loadFinancialRequestReasonDetailsByRequestType(chosenId));
        }
    };

    const handleRequestReasonChange = (chosenId) => {
        if (chosenId && chosenId !== row.paymentReasonId) disableButtonsOnEdit();
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, paymentReasonId: chosenId }));
            clearErrors('financialRequestReasonId');
        }
    };

    const handlePaymentMethodTypeChange = (chosenId) => {
        if (chosenId && chosenId !== row.paymentMethodTypeId) disableButtonsOnEdit();
        setNewEntry((prev) => ({ ...prev, paymentMethodTypeId: chosenId }));
        setNewEntry((prev) => ({ ...prev, paymentMethodId: '' }));
        setIsClearSelectedPaymentMethod(Math.random());

        clearErrors('paymentMethodTypeId');
        clearErrors('financialPaymentMethodId');

        if (chosenId) {
            dispatch(loadFinancialPaymentMethodDetailsByPaymentMethodType(chosenId));
        }
    };

    const handlePaymentMethodChange = (chosenId) => {
        if (chosenId && chosenId !== row.paymentMethodId) disableButtonsOnEdit();
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, paymentMethodId: chosenId }));
            clearErrors('financialPaymentMethodId');
        }
    };

    const handleTaxChange = (option) => {
        if (option && option !== row.taxNiIssued) disableButtonsOnEdit();
        setNewEntry((prev) => ({ ...prev, taxNiIssued: option }));
    };

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setIsClearSelectedAdviser(Math.random());
        setIsClearSelectedRequestType(Math.random());
        setIsClearSelectedRequestReason(Math.random());
        setIsClearSelectedPaymentMethodType(Math.random());
        setIsClearSelectedPaymentMethod(Math.random());
    };

    function disableButtonsOnEdit() {
        setApproveDisable(true);
        setDeclineDisable(true);
        setWithdrawDisable(true);
    }

    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addEmailAddressAsNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    const getStatusName = (id) => {
        let status = financialRequestStatusDetails.find((item) => item.id === id);
        return status.name.toLowerCase();
    };

    const getStatusId = (name) => {
        let status = financialRequestStatusDetails.find((item) => item.name.toLowerCase() === name);
        return status.id;
    };

    // USE EFFECTS
    useEffect(() => {
        if (!currentParticipant?.id) return;

        if (!financialRequestStatusDetails?.length) dispatch(loadFinancialRequestStatusDetails());
        if (!financialRequestTypeDetails?.length) dispatch(loadFinancialRequestTypeDetails());
        if (!financialPaymentMethodTypeDetails?.length)
            dispatch(loadFinancialPaymentMethodTypeDetails());

        dispatch(loadFinancialRequestReasonDetailsByRequestType(row.requestTypeId));
        dispatch(loadFinancialPaymentMethodDetailsByPaymentMethodType(row.paymentMethodTypeId));

        if (row.approverNote === null) row.approverNote = '';
        reset();
        setNewEntry(row);

        // Force Yup validation on pre-populated fields
        setValue('amount', row.amount, {
            shouldValidate: true
        });
        setValue('adviserId', row.adviserId, {
            shouldValidate: true
        });
        setValue('requestTypeId', row.requestTypeId, {
            shouldValidate: true
        });
        setValue('financialRequestReasonId', row.paymentReasonId, {
            shouldValidate: true
        });
        setValue('paymentMethodTypeId', row.paymentMethodTypeId, {
            shouldValidate: true
        });
        setValue('financialPaymentMethodId', row.paymentMethodId, {
            shouldValidate: true
        });
        setValue('reasonDetail', row.reasonDetail, {
            shouldValidate: true
        });
        if (row.approverNote)
            setValue('approverNote', row.approverNote, {
                shouldValidate: true
            });

        if (row.adviserId) {
            const initialAdviser =
                usersById[row.adviserId] || users.find((el) => el.id === row.adviserId);
            if (!initialAdviser) {
                dispatch(loadUserById(row.adviserId));
            } else {
                setAdvisersArray([initialAdviser]);
            }
        }
    }, [currentParticipant, row]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter((el) =>
            el.userTypes?.find((entry) => advisorRoles.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < totalUsers) {
            onLoadMoreAdvisers();
            return;
        }
        if (newEntry.adviserId && !updatedAdvisers.some((el) => el.id === newEntry.adviserId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === newEntry.adviserId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (newEntry.adviserId && usersById[newEntry.adviserId]) {
            setAdvisersArray([usersById[newEntry.adviserId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (financialRequestStatusDetails?.length) {
            let status = financialRequestStatusDetails.find(
                (item) => item.name.toLowerCase() === 'requested'
            );
            let id = status.id;
            setNewEntry((prev) => ({ ...prev, statusId: id }));
        }
    }, [financialRequestStatusDetails]);

    useEffect(() => {
        if (financialRequestTypeDetails?.length && arrayFinancialRequestType?.length === 0) {
            setArrayFinancialRequestType(
                getConfiguredItems(financialRequestTypeDetails, currentParticipant?.contractId)
            );
        }
    }, [financialRequestTypeDetails]);

    useEffect(() => {
        if (financialRequestReasonDetails?.length) {
            setRequestReasonRequired(true);
            setValue('requestReasonRequired', true, {
                shouldValidate: true
            });
        } else {
            setRequestReasonRequired(false);
            setValue('requestReasonRequired', false, {
                shouldValidate: true
            });
        }
    }, [financialRequestReasonDetails]);

    useEffect(() => {
        if (financialPaymentMethodTypeDetails?.length && arrayPaymentMethodType?.length === 0) {
            setArrayPaymentMethodType(
                getConfiguredItems(
                    financialPaymentMethodTypeDetails,
                    currentParticipant?.contractId
                )
            );
        }
    }, [financialPaymentMethodTypeDetails]);

    useEffect(() => {
        if (financialPaymentMethodDetails?.length) {
            setPaymentMethodRequired(true);
            setValue('paymentMethodRequired', true, {
                shouldValidate: true
            });
        } else {
            setPaymentMethodRequired(false);
            setValue('paymentMethodRequired', false, {
                shouldValidate: true
            });
        }
    }, [financialPaymentMethodDetails]);

    useEffect(() => {
        if (successMessage === 'Participant financial request has been updated') clearForm();
    }, [successMessage]);

    // EVENT HANDLERS
    const onLoadMoreAdvisers = () => {
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                !users.length ? 0 : lastPageOfUsersLoaded + 1
            )
        );
    };

    const onFormExit = () => {
        setApproveDisable(false);
        setDeclineDisable(false);
        setWithdrawDisable(false);
        payload = '';

        clearForm();
        clearErrors();
        onClose();
    };

    const onSubmit = () => {
        if (payload) {
            dispatch(updateFinancialRequest(payload));
            onFormExit();
        } else {
            if (
                newEntry.amount === '0' ||
                newEntry.amount === '0.0' ||
                newEntry.amount === '0.00'
            ) {
                dispatch(setErrorMessage('Amount cannot be zero'));
            } else {
                dispatch(updateFinancialRequest(newEntry));
                onFormExit();
            }
        }
    };

    function onApprove() {
        payload = { ...newEntry, statusId: getStatusId('approved'), approverId: loggedInUser.id };
        onSubmit();
    }

    function onDecline() {
        payload = { ...newEntry, statusId: getStatusId('declined'), approverId: loggedInUser.id };
        onSubmit();
    }

    function onWithdraw() {
        payload = { ...newEntry, statusId: getStatusId('withdrawn') };
        onSubmit();
    }

    function onIssue() {
        payload = {
            ...newEntry,
            dateIssued: new Date().toISOString().slice(0, 10),
            statusId: getStatusId('approved')
        };
        dispatch(updateFinancialIssueRequest(payload));
        onFormExit();
    }

    let content;
    if (advisers?.length < 1) content = 'No advisor found';
    if (Object.keys(currentParticipant)?.length < 1) content = 'No current participant';

    // RENDER
    if (advisers?.length < 1 || Object.keys(currentParticipant)?.length < 1) {
        return <LoadingSpinner content={content} />;
    }

    return (
        <div className={form.formWrapper}>
            <h3>Edit Financial Request - ID {row.code}</h3>
            <form className={form.form} onSubmit={handleSubmit(onSubmit)}>
                {/* This is needed for request reason validations */}
                <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                    <input
                        id="requestReasonRequired"
                        name="requestReasonRequired"
                        value={requestReasonRequired}
                        {...register('requestReasonRequired')}></input>
                </div>
                {/* This is needed for payment method validations */}
                <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                    <input
                        id="paymentMethodRequired"
                        name="paymentMethodRequired"
                        value={paymentMethodRequired}
                        {...register('paymentMethodRequired')}></input>
                </div>
                <br />
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'adviserId'}
                            key={isClearSelectedAdviser}
                            label={'Adviser'}
                            placeholder="Select adviser..."
                            disabled={
                                !hasRole([MANAGER, QUALITY, SUPERUSER], roles) ||
                                getStatusName(row.statusId) !== 'requested'
                            }
                            mandatory={true}
                            menuItems={advisers || []}
                            selectedId={row.adviserId || ''}
                            selected={advisers?.find((el) => el.id === row.adviserId) || {}}
                            error={errors.adviserId}
                            onChange={(chosenId) => handleAdviserChange(chosenId)}
                            onLoadMoreItems={onLoadMoreAdvisers}
                            moreItemsToLoad={!totalUsers || users.length < totalUsers}
                        />
                        <LabelledTextField
                            label={'Amount'}
                            id={'amount'}
                            disabled={
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) || getStatusName(row.statusId) !== 'requested'
                            }
                            mandatory={true}
                            value={newEntry.amount || ''}
                            placeholder={'Enter amount'}
                            error={errors.amount}
                            {...register('amount')}
                            onChange={handleAmount}
                        />
                        <DDLOptionPicker
                            label={'Request Type'}
                            id={'requestTypeId'}
                            key={isClearSelectedRequestType}
                            disabled={
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) || getStatusName(row.statusId) !== 'requested'
                            }
                            mandatory={true}
                            menuItems={arrayFinancialRequestType}
                            chosenName={
                                newEntry.requestTypeId
                                    ? getNameFromId(
                                          financialRequestTypeDetails,
                                          newEntry.requestTypeId
                                      )
                                    : ''
                            }
                            chosenId={newEntry.requestTypeId || ''}
                            error={errors.requestTypeId}
                            {...register('requestTypeId')}
                            onChange={(chosenId) => handleRequestTypeChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Reason'}
                            id={'financialRequestReasonId'}
                            key={isClearSelectedRequestReason}
                            disabled={
                                !newEntry.requestTypeId ||
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) ||
                                getStatusName(row.statusId) !== 'requested'
                            }
                            mandatory={requestReasonRequired}
                            menuItems={financialRequestReasonDetails || []}
                            chosenName={
                                newEntry.paymentReasonId
                                    ? getNameFromId(
                                          financialRequestReasonDetails,
                                          newEntry.paymentReasonId
                                      )
                                    : ''
                            }
                            chosenId={newEntry.paymentReasonId || ''}
                            error={errors.financialRequestReasonId}
                            {...register('financialRequestReasonId')}
                            onChange={(chosenId) => handleRequestReasonChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Payment Method Type'}
                            id={'paymentMethodTypeId'}
                            key={isClearSelectedPaymentMethodType}
                            disabled={
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) || getStatusName(row.statusId) !== 'requested'
                            }
                            mandatory={true}
                            menuItems={arrayPaymentMethodType}
                            chosenName={
                                newEntry.paymentMethodTypeId
                                    ? getNameFromId(
                                          financialPaymentMethodTypeDetails,
                                          newEntry.paymentMethodTypeId
                                      )
                                    : ''
                            }
                            chosenId={newEntry.paymentMethodTypeId || ''}
                            error={errors.paymentMethodTypeId}
                            {...register('paymentMethodTypeId')}
                            onChange={(chosenId) => handlePaymentMethodTypeChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Payment Method'}
                            id={'financialPaymentMethodId'}
                            key={isClearSelectedPaymentMethod}
                            disabled={
                                !newEntry.paymentMethodTypeId ||
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) ||
                                getStatusName(row.statusId) !== 'requested'
                            }
                            mandatory={paymentMethodRequired}
                            menuItems={financialPaymentMethodDetails || []}
                            chosenName={
                                newEntry.paymentMethodId
                                    ? getNameFromId(
                                          financialPaymentMethodDetails,
                                          newEntry.paymentMethodId
                                      )
                                    : ''
                            }
                            chosenId={newEntry.paymentMethodId || ''}
                            error={errors.financialPaymentMethodId}
                            {...register('financialPaymentMethodId')}
                            onChange={(chosenId) => handlePaymentMethodChange(chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Reason Details'}
                            id={'reasonDetail'}
                            mandatory={true}
                            disabled={
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) || getStatusName(row.statusId) !== 'requested'
                            }
                            multiline
                            rows={5}
                            value={newEntry.reasonDetail}
                            placeholder={'Enter reason details'}
                            counter={'true'}
                            helperText={
                                `${newEntry.reasonDetail.length}` + '/' + REASON_DETAILS_LIMIT
                            }
                            inputProps={{ maxLength: REASON_DETAILS_LIMIT }}
                            error={errors.reasonDetail}
                            {...register('reasonDetail')}
                            onChange={(e) => {
                                disableButtonsOnEdit();
                                clearErrors('reasonDetail');
                                setNewEntry((prev) => ({ ...prev, reasonDetail: e.target.value }));
                            }}
                        />
                        <LabelledTextField
                            label={'Authoriser Notes'}
                            id={'approverNote'}
                            mandatory={true}
                            disabled={
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) || getStatusName(row.statusId) !== 'requested'
                            }
                            multiline
                            rows={5}
                            value={newEntry.approverNote}
                            placeholder={'Enter authoriser notes'}
                            counter={'true'}
                            helperText={
                                `${newEntry.approverNote.length}` + '/' + AUTHORISER_NOTES_LIMIT
                            }
                            inputProps={{ maxLength: AUTHORISER_NOTES_LIMIT }}
                            error={errors.approverNote}
                            {...register('approverNote')}
                            onChange={(e) => {
                                clearErrors('approverNote');
                                setNewEntry((prev) => ({ ...prev, approverNote: e.target.value }));
                            }}
                        />
                        <LabelledTextField
                            label={'Date Requested'}
                            id={'dateRequested'}
                            disabled={true}
                            mandatory={true}
                            value={reverseFormatDate(newEntry.dateRequested)}
                        />
                        <NoYesRadioPicker
                            id="tax"
                            disabled={
                                !hasRole(
                                    [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    roles
                                ) || getStatusName(row.statusId) !== 'requested'
                            }
                            radioButtonPick={newEntry.taxNiIssued || false}
                            text={'Tax and NI issued?'}
                            onChange={handleTaxChange}></NoYesRadioPicker>
                    </div>
                </div>
                <div className={actions.formActions}>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={onApprove}
                        disabled={
                            !hasRole([MANAGER, QUALITY, SUPERUSER], roles) ||
                            getStatusName(row.statusId) !== 'requested' ||
                            approveDisable ||
                            newEntry.approverNote.length < 7
                        }>
                        {' '}
                        Approve
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={onDecline}
                        disabled={
                            !hasRole([MANAGER, QUALITY, SUPERUSER], roles) ||
                            getStatusName(row.statusId) !== 'requested' ||
                            declineDisable ||
                            newEntry.approverNote.length < 7
                        }>
                        {' '}
                        Decline
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={onWithdraw}
                        disabled={
                            !hasRole(
                                [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                roles
                            ) ||
                            (getStatusName(row.statusId) !== 'requested' &&
                                getStatusName(row.statusId) !== 'approved') ||
                            (getStatusName(row.statusId) === 'approved' && newEntry.dateIssued) ||
                            withdrawDisable ||
                            newEntry.approverNote.length < 7
                        }>
                        {' '}
                        Withdraw
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={onIssue}
                        disabled={
                            !hasRole(
                                [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                roles
                            ) ||
                            getStatusName(row.statusId) !== 'approved' ||
                            (getStatusName(row.statusId) === 'approved' && newEntry.dateIssued)
                        }>
                        {' '}
                        Issue
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={
                            !hasRole(
                                [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                roles
                            ) || getStatusName(row.statusId) !== 'requested'
                        }>
                        {' '}
                        Update
                    </Button>
                    <div className={actions.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    adviserId: Yup.string().required('Please select an adviser'),
    amount: Yup.string()
        .nullable()
        .min(0.1, 'Amount cannot be negative, blank or zero')
        .max(9999.99, 'Amount must be 9999.99 or less')
        .matches(validate.PRICE_REGEXP, 'Invalid Amount'),
    requestTypeId: Yup.string().required('Please select a request type'),
    requestReasonRequired: Yup.boolean(),
    financialRequestReasonId: Yup.string().when('requestReasonRequired', {
        is: (requestReasonRequired) => requestReasonRequired === true,
        then: () => Yup.string().required('Please select a request reason')
    }),
    paymentMethodTypeId: Yup.string().required('Please select a payment method type'),
    paymentMethodRequired: Yup.boolean(),
    financialPaymentMethodId: Yup.string().when('paymentMethodRequired', {
        is: (paymentMethodRequired) => paymentMethodRequired === true,
        then: () => Yup.string().required('Please select a payment method')
    }),
    reasonDetail: Yup.string().min(7, 'Reason details must be at least seven characters'),
    approverNote: Yup.string().min(7, 'Authoriser note must be at least seven characters')
});

export default EditFinancialRequest;

EditFinancialRequest.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string
};
