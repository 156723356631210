import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';

import {
    loadBusinessSectorDetails,
    loadJobSectorDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import {
    loadBusinessRecords,
    loadVacanciesByOwners,
    loadVacancySubmissionsByParticipantId
} from '../../../../store/recruitmentService';
import { selectLoggedInUser, selectUsersForVacancies } from '../../../../store/userSelectors';
import { loadUsersForVacancies } from '../../../../store/userService';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CurrentVacanciesTableManagement from './currentVacancies/CurrentVacanciesTableManagement';
import RejectedVacanciesTableManagement from './rejectedVacancies/RejectedVacanciesTableManagement';
import VacancySearchTableManagement from './vacancySearch/VacancySearchTableManagement';

const BUS_LOAD_SIZE = 50;
const Vacancies = () => {
    // HOOKS
    const dispatch = useDispatch();

    const [businessesForVacancies, setBusinessesForVacancies] = useState([]);

    // STORE STATE
    const usersForVacancies = useSelector(selectUsersForVacancies);
    const loggedInUser = useSelector(selectLoggedInUser);
    const { businessSectorDetails, jobSectorDetails, vacancyStageDetails, vacancySubmissions } =
        useSelector((state) => state.entities.directusService);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const { businesses, businessesMetaData, vacanciesForOwners } = useSelector(
        (state) => state.entities.recruitmentService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadVacancySubmissionsByParticipantId(currentParticipant.id));
        businesses?.length < 1
            ? dispatch(loadBusinessRecords(0, BUS_LOAD_SIZE))
            : setBusinessesForVacancies(businesses);
        businessSectorDetails?.length < 1 && dispatch(loadBusinessSectorDetails());
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        usersForVacancies?.length < 1 &&
            loggedInUser?.serviceIds?.length > 0 &&
            dispatch(loadUsersForVacancies(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (businessesForVacancies?.length < 1) return;
        else if (!businessesMetaData.last) {
            dispatch(loadBusinessRecords(businessesMetaData.number + 1, BUS_LOAD_SIZE));
        } else setBusinessesForVacancies(businesses);
    }, [businesses, businessesMetaData]);

    // RENDER
    let content = '';
    if (businesses?.length < 1) content = 'No businesses found';
    if (businessSectorDetails?.length < 1) content = 'No business sector details';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (vacanciesForOwners?.length < 1) content = 'No vacancy fow owner details';
    if (vacancyStageDetails?.length < 1) content = 'No vacancy stage details';
    if (vacancySubmissions?.length < 1) content = 'No vacancy submissions';
    if (
        businesses?.length < 1 ||
        businessSectorDetails?.length < 1 ||
        jobSectorDetails?.length < 1 ||
        vacanciesForOwners?.length < 1 ||
        vacancyStageDetails?.length < 1 ||
        vacancySubmissions?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <div>
            <br />
            <Typography variant="h5">Vacancy Search</Typography>
            <VacancySearchTableManagement />
            <br />
            <br />
            <Typography variant="h5">Current Vacancies</Typography>
            <CurrentVacanciesTableManagement />
            <br />
            <br />
            <Typography variant="h5">Rejected Vacancies</Typography>
            <RejectedVacanciesTableManagement />
        </div>
    );
};

export default Vacancies;
