import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createHealthIncident, updateHealthIncident } from '../../../../store/participantService';
import { formatDateForDateInput } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';

import { checkDate, initialErrorState, validate } from './validateHealthIncident';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateHealthIncident = ({ onClose, roles, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        eventDate: new Date().toISOString().slice(0, 10),
        dateReportedToNhse: '',
        programmeDeIntensifiedDate: '',
        programmeReIntensifiedDate: '',
        fibreSupplementDate: '',
        participantId: '',
        note: '',
        otherDetail: '',
        eventTypeId: '',
        eventType: '',
        seriousTypeEvent: false
    };
    const NOTE_CHAR_LIMIT = 500;
    const OTHER_CHAR_LIMIT = 50;
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [eventTypes, setEventTypes] = useState([]);
    const [isClearEventType, setIsClearEventType] = useState('0');
    const msg = `Participant health incident has been ${
        formType === 'create' ? 'added' : 'updated'
    }`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const seriousEventTypes = useSelector(
        (state) => state.entities.directusService.seriousEventTypes
    );
    const nonSeriousEventTypes = useSelector(
        (state) => state.entities.directusService.nonSeriousEventTypes
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setIsClearEventType(Math.random());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length > 0) return;
        nonSeriousEventTypes?.length > 0 && setEventTypes(nonSeriousEventTypes);
    }, [nonSeriousEventTypes]);

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const arr = row.seriousTypeEvent ? seriousEventTypes : nonSeriousEventTypes;
        row.seriousTypeEvent
            ? setEventTypes(seriousEventTypes)
            : setEventTypes(nonSeriousEventTypes);

        const formattedRow = {
            ...row,
            eventDate: formatDateForDateInput(row.eventDate),
            eventType: arr.find((el) => el.id === row.eventTypeId)?.name || '',
            otherDetail: row.otherDetail === null ? '' : row.otherDetail,
            dateReportedToNhse:
                row.dateReportedToNhse === 'z'
                    ? ''
                    : formatDateForDateInput(row.dateReportedToNhse),
            programmeDeIntensifiedDate:
                row.programmeDeIntensifiedDate === 'z'
                    ? ''
                    : formatDateForDateInput(row.programmeDeIntensifiedDate),
            programmeReIntensifiedDate:
                row.programmeReIntensifiedDate === 'z'
                    ? ''
                    : formatDateForDateInput(row.programmeReIntensifiedDate),
            fibreSupplementDate: row.fibreSupplementDate
                ? formatDateForDateInput(row.fibreSupplementDate)
                : ''
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        setEventTypes(newEntry.seriousTypeEvent ? seriousEventTypes : nonSeriousEventTypes);
    }, [newEntry.eventTypeId]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onIsSeriousSwitch = (e) => {
        const { checked } = e.target;
        setNewEntry((prev) => ({
            ...prev,
            seriousTypeEvent: checked,
            priorityId: ``,
            eventType: ``
        }));
        newEntry.eventTypeId === `` &&
            setEventTypes(checked ? seriousEventTypes : nonSeriousEventTypes);
    };

    const onDateChange = (key, date) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: date }));
        const newErrors = checkDate(key, date, errors, newEntry);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const { otherDetail, ...rest } = newEntry;
        const payload = {
            ...rest,
            participantId: currentParticipant?.id,
            otherDetail:
                newEntry.eventType === 'Other'
                    ? otherDetail.trim() === ''
                        ? null
                        : otherDetail.trim()
                    : null
        };
        formType === 'create'
            ? dispatch(createHealthIncident(payload, msg))
            : dispatch(updateHealthIncident(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>{formType === 'create' ? 'Add a New Health Incident' : 'Edit Health Incident'}</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.dateSelector}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange('eventDate', res)}
                            />
                        </div>

                        <div className={form.switchWrapper}>
                            <PolarisSwitchSet
                                id="serious"
                                disabled={false}
                                label=""
                                leftLabel="Non-Serious"
                                rightLabel="Serious"
                                checked={newEntry.seriousTypeEvent}
                                onSwitch={(e) => onIsSeriousSwitch(e)}
                            />
                        </div>

                        <DDLOptionPicker
                            label={'Event Type'}
                            id={'eventType'}
                            key={isClearEventType}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={eventTypes || []}
                            chosenName={newEntry.eventType || ``}
                            chosenId={newEntry.eventTypeId || ``}
                            onChange={(chosenId) => {
                                clearError('eventTypeId');
                                const eventType =
                                    eventTypes.find((el) => el.id === chosenId)?.name || '';
                                setNewEntry((prev) => ({
                                    ...prev,
                                    eventTypeId: chosenId,
                                    eventType
                                }));
                            }}></DDLOptionPicker>
                        {errors.eventTypeId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.eventTypeId} />
                            </div>
                        )}

                        {newEntry.eventType === 'Other' && (
                            <div className={form.textAreaSet}>
                                <label htmlFor="otherDetail">Other Details *</label>

                                <textarea
                                    id="otherDetail"
                                    rows={3}
                                    maxLength={OTHER_CHAR_LIMIT}
                                    value={newEntry.otherDetail || ''}
                                    placeholder={'Other details'}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    className={form.textAreaText}
                                    onChange={(e) => {
                                        clearError('otherDetail');
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            otherDetail: e.target.value
                                        }));
                                    }}
                                />

                                <div>
                                    {newEntry.otherDetail?.length}/{OTHER_CHAR_LIMIT}
                                </div>
                                {errors.otherDetail.error && (
                                    <div>
                                        <IconError text={errors.otherDetail} />
                                    </div>
                                )}
                            </div>
                        )}

                        <div className={form.dateSelector}>
                            <DateSelect
                                value={newEntry.dateReportedToNhse}
                                label="Date Reported to NHSE"
                                disabled={false}
                                isDefault={false}
                                error={errors.dateReportedToNhse}
                                onDateChange={(res) => onDateChange('dateReportedToNhse', res)}
                            />
                        </div>

                        <div className={form.textAreaSet}>
                            <label htmlFor="note">Notes</label>
                            <textarea
                                className={form.textAreaText}
                                id="note"
                                rows={8}
                                maxLength={NOTE_CHAR_LIMIT}
                                value={newEntry.note || ''}
                                placeholder={'Notes'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onChange={(e) =>
                                    setNewEntry((prev) => ({ ...prev, note: e.target.value }))
                                }
                            />
                            <div>
                                {newEntry.note?.length}/{NOTE_CHAR_LIMIT}
                            </div>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.dateSelector}>
                            <DateSelect
                                value={newEntry.programmeDeIntensifiedDate}
                                label="Programme de-intensified Date"
                                disabled={false}
                                isDefault={false}
                                error={errors.programmeDeIntensifiedDate}
                                onDateChange={(res) =>
                                    onDateChange('programmeDeIntensifiedDate', res)
                                }
                            />
                        </div>
                        <div className={form.dateSelector}>
                            <DateSelect
                                value={newEntry.programmeReIntensifiedDate}
                                label="Programme re-intensified Date"
                                disabled={false}
                                isDefault={false}
                                error={errors.programmeReIntensifiedDate}
                                onDateChange={(res) =>
                                    onDateChange('programmeReIntensifiedDate', res)
                                }
                            />
                        </div>
                        <div className={form.dateSelector}>
                            <DateSelect
                                value={newEntry.fibreSupplementDate}
                                label="Fibre Supplement Date"
                                disabled={false}
                                isDefault={false}
                                error={errors.fibreSupplementDate}
                                onDateChange={(res) => onDateChange('fibreSupplementDate', res)}
                            />
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={formType === 'create' ? 'Save' : 'Update'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateHealthIncident;

CreateHealthIncident.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};
