export const downloadCsv = (fields, values, fileName) => {
    const csvHeader = `${fields}\n`;
    const csvBody = values.map((el) => el?.join(','))?.join('\n');

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(`${csvHeader}${csvBody}`);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
};
