import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@mui/material';

import './confirmPrompt.css';

const ConfirmPrompt = ({ isOpen, onCancel, onConfirm, content, customClass = '' }) => {
    return (
        <Dialog onClose={onCancel} open={isOpen}>
            <div className="confirm-modal">
                <div className={`modal-content ${customClass}`}>{content}</div>
                <div className="modal-btns">
                    <div className="cancel-btn" onClick={onCancel}>
                        No
                    </div>
                    <div className="verify-btn" onClick={onConfirm}>
                        Yes
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmPrompt;

ConfirmPrompt.propTypes = {
    onCancel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    content: PropTypes.string,
    customClass: PropTypes.string
};
