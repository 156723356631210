import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadBloodPressureMeasurementTypes } from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import {
    deleteBloodPressure,
    loadBloodPressure,
    onDeleteBloodPressure
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import BloodPressureTable from './BloodPressureTable';
import CreateBloodPressure from './CreateBloodPressure';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const BloodPressure = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Blood glucose measurement deleted`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const ranges = {
        MIN_SYSTOLIC: 89,
        MAX_SYSTOLIC: 160,
        MIN_DIASTOLIC: 59,
        MAX_DIASTOLIC: 100
    };
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const bloodPressureMeasurementTypes = useSelector(
        (state) => state.entities.directusService.bloodPressureMeasurementTypes
    );
    const bloodPressure = useSelector((state) => state.entities.participantService.bloodPressure);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (bloodPressureMeasurementTypes?.length < 1)
            dispatch(loadBloodPressureMeasurementTypes());
    }, []);

    useEffect(() => {
        currentParticipant?.id && dispatch(loadBloodPressure(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (bloodPressureMeasurementTypes?.length < 1) return;
        const rows = bloodPressure.map((el) => ({
            ...el,
            displayDate: reverseFormatDate(el.eventDate),
            measurement:
                bloodPressureMeasurementTypes.find((entry) => entry.id === el.measurementId)
                    ?.name || ''
        }));
        setRows(rows);
    }, [bloodPressure, bloodPressureMeasurementTypes]);

    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeleteBloodPressure(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setSelectedRow({});
        setShowForm(true);
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteBloodPressure(row.id, deleteMsg));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Blood Pressure</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No blood pressure measurements found" />
                ) : (
                    <BloodPressureTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        ranges={ranges}
                    />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Blood Pressure
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreateBloodPressure
                            onClose={() => setShowForm(false)}
                            roles={roles}
                            formType={formType}
                            row={selectedRow}
                            ranges={ranges}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BloodPressure;
