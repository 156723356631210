import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';

import dropdownClasses from '../claimStyles/rowDropDown.module.css';

const ReversionDetails = ({ row, onClose }) => {
    return !row ? (
        <LoadingSpinner content="No reversion information found" />
    ) : (
        <div className={dropdownClasses.rowDropdown}>
            <Typography variant="h6">Reversion Feedback</Typography>
            <LabelledTextField id={'notes'} disabled={true} value={row?.notes || ''} rows={4} />
            <div className={dropdownClasses.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default ReversionDetails;

ReversionDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
