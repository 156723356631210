import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, Button, TextField } from '@mui/material';

import {
    setCurrentModule,
    setOpenModuleSchedulerPanel,
    updateCourseModule
} from '../../../store/courseService';
import { selectLocationsForServices } from '../../../store/dataSelectors';
import { loadLocationsForServices } from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import { selectUserRoles } from '../../../store/userSelectors';
import { isTimePast } from '../../../utils/dateFunctions';
import { UPDATE } from '../../../utils/uiConstants';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import NotFound from '../../notFound/NotFound';
import { getServiceIds } from '../../ui/calendar/calendarUtils';
import Panel from '../../ui/panel/Panel';

import './moduleScheduler.css';

const ModuleScheduler = ({ currentModule, currentLocation, newEvent, onPending, adviser }) => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const DATE_RANGE_INVALID = 'Module date must be later than now';
    const TIME_RANGE_INVALID = 'Module end time must be later than module start time';
    const NO_LOCATION = 'Please select a location';
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];

    const initialState = {
        id: '',
        title: '',
        locationId: '',
        capacity: 1,
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0])
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [adviserName, setAdviserName] = useState('');
    const [capacityLowerLimit, setCapacityLowerLimit] = useState(1);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const locationsForServices = useSelector(selectLocationsForServices);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const openModuleScheduler = useSelector(
        (state) => state.entities.courseService.openModuleScheduler
    );

    const clearForm = () => setNewEntry(initialState);

    // USEEFFECTS

    useEffect(() => {
        const { firstName, lastName } = adviser || {};
        const serviceIds = getServiceIds(adviser);
        dispatch(loadLocationsForServices(serviceIds));
        if (firstName && lastName) setAdviserName(`${firstName} ${lastName}`);

        if (Object.keys(newEvent).length) {
            setNewEntry({
                ...newEvent,
                capacity: newEvent?.capacity || '1',
                startTime: newEvent?.startTime,
                endTime: newEvent?.endTime,
                date: newEvent?.date,
                userId: adviser?.id
            });
        } else if (Object.keys(currentModule).length) {
            setNewEntry({
                ...currentModule,
                capacity: currentModule?.capacity || '1',
                startTime: currentModule?.startTime?.slice(0, 5),
                endTime: currentModule?.endTime?.slice(0, 5),
                date: currentModule?.date?.split('T')[0] || '',
                location: currentLocation ? currentLocation : '',
                userId: adviser.id
            });
            if (currentModule?.capacity > 1) setCapacityLowerLimit(currentModule.capacity);
        }
    }, []);

    useEffect(() => {
        if (successMessage.includes(`Module details for ${newEntry.title} has been updated`)) {
            clearForm();
        }
    }, [successMessage]);

    // HELPER FNS
    const onToggle = (open) => {
        dispatch(setOpenModuleSchedulerPanel(open));
        if (!open) {
            if (!currentModule.date) onPending(newEvent);
            dispatch(setCurrentModule({}));
        }
    };

    const onValidate = () => {
        let errorMessage;
        if (isTimePast(`${newEntry.date}T${newEntry.startTime}`)) {
            errorMessage = DATE_RANGE_INVALID;
        }
        if (newEntry.endTime <= newEntry.startTime) errorMessage = TIME_RANGE_INVALID;
        if (!newEntry.locationId) errorMessage = NO_LOCATION;
        if (errorMessage) {
            dispatch(setErrorMessage(errorMessage));
            return false;
        }
        return true;
    };

    // TODO change capacityLowerLimit to no. of participants already on course when data available
    const onCapacityChange = (e) => {
        const { value } = e.target;
        if (value > capacityLowerLimit) setNewEntry((prev) => ({ ...prev, capacity: value }));
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const valid = onValidate();
        if (!valid) return;
        // eslint-disable-next-line
        const { participants, location, start, end, ...rest } = newEntry;
        const payload = {
            ...rest
        };

        dispatch(
            updateCourseModule(payload, `Module details for ${payload.title} have been updated`)
        );
        onPending({});
        onToggle(false);
    };

    // RENDER

    if (!hasRole(acceptedRoles, roles)) return <NotFound />;

    return (
        <Panel width="500px" open={openModuleScheduler} onToggle={onToggle}>
            <div className="module-scheduler">
                <div className="module-title">{newEntry.title}</div>
                <form onSubmit={onSubmit}>
                    <div className="static-copy">
                        <span>Adviser: </span>
                        <span> {adviserName} </span>
                    </div>
                    <div className="input location-input">
                        <label htmlFor="location"> Location * </label>
                        <div className="search-input">
                            <Autocomplete
                                disablePortal
                                id="locations"
                                options={locationsForServices || []}
                                getOptionLabel={(option) => option?.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={
                                            newEntry.location ||
                                            currentLocation ||
                                            'search locations...'
                                        }
                                    />
                                )}
                                classes={{ listbox: 'menu-list' }}
                                onChange={(_, val) =>
                                    val && setNewEntry({ ...newEntry, locationId: val.id })
                                }
                            />
                        </div>
                    </div>
                    <div className="input capacity-input">
                        <label htmlFor="capacity"> Capacity </label>
                        <TextField
                            type="number"
                            InputProps={{ inputProps: { min: capacityLowerLimit, max: 99 } }}
                            id={'capacity'}
                            value={newEntry.capacity || currentModule?.capacity || 1}
                            onChange={onCapacityChange}
                        />
                    </div>
                    <div className="time-inputs">
                        <div className="input-set start-input">
                            <label htmlFor="startTime"> Start Time</label>
                            <input
                                type="time"
                                id="startTime"
                                data-testid="startTime"
                                value={newEntry.startTime || '08:00'}
                                min={'08:00'}
                                max={'17:55'}
                                step={'00:05'}
                                onChange={(e) =>
                                    setNewEntry({ ...newEntry, startTime: e.target.value })
                                }
                            />
                        </div>
                        <div className="input-set end-input">
                            <label htmlFor="endTime"> End Time</label>
                            <input
                                type="time"
                                id="endTime"
                                data-testid="endTime"
                                value={newEntry.endTime || '09:00'}
                                min={'08:05'}
                                max={'18:00'}
                                step={'00:05'}
                                onChange={(e) =>
                                    setNewEntry({ ...newEntry, endTime: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="input date-input">
                        <label htmlFor="date">Date</label>
                        <TextField
                            type="date"
                            id="date"
                            name="date"
                            value={
                                newEntry.date ||
                                currentModule?.date ||
                                newEvent?.date ||
                                String(new Date().toISOString().split('T')[0])
                            }
                            onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                        />
                    </div>
                    <div className="btn">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="testIdSubmitButton">
                            {UPDATE}
                        </Button>
                    </div>
                </form>
            </div>
        </Panel>
    );
};

export default ModuleScheduler;

ModuleScheduler.propTypes = {
    currentModule: PropTypes.object,
    newEvent: PropTypes.object,
    currentLocation: PropTypes.string,
    onPending: PropTypes.func,
    adviser: PropTypes.object
};
