import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import { Button } from '@mui/material';

import { loadQualificationTypeDetails } from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    loadParticipantPastEducation,
    updateParticipantPastEducation
} from '../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import FormHeader from '../../layout/FormHeader';
import CardHandler from '../../ui/cards/CardHandler';
import { PAST_EDUCATION_VIEWER } from '../../ui/cards/ViewerTypes';
import LoadingSpinner from '../../ui/LoadingSpinner';

const Education = () => {
    const { handleSubmit } = useForm({});
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        institutions: []
    };
    const [institutions, setInstitutions] = useState([]);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { qualificationTypeDetails } = useSelector((state) => state.entities.directusService);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    let pastEducation = useSelector(
        (state) => state.entities.participantService.currentParticipantPastEducation
    );

    /**
     * Add blank institution to institutions
     */
    const addInstitution = () => {
        // If there is already a blank past education then don't add another
        if (institutions?.some((c) => !c.name || !c.qualificationTypeId)) {
            // Show warning that there is already an incomplete card.
            dispatch(setErrorMessage(`Cannot add another blank. Please complete existing card.`));

            return;
        }
        const newInstitution = {
            id: uuid(),
            endDate: null,
            name: null,
            onGoing: null,
            qualificationTypeId: null,
            startDate: null,
            subjects: []
        };
        if (!institutions) {
            setInstitutions([newInstitution]);
        } else {
            setInstitutions((prev) => [...prev, newInstitution]);
        }
    };

    /**
     * Delete past institution from institutions
     * @param {object} institution - institution to delete
     *
     * institutions = State with an array of objects with institutions
     */
    const deleteInstitution = (institution) => {
        if (institutions.length < 2) return;
        setInstitutions(institutions.filter((el) => el.id !== institution.id));
    };

    /**
     * Update past education in education
     *
     * @param {object} institution - past institution to update
     *
     * This is a callback from the CardViewer component.
     */
    const updateInstitution = (institution) => {
        setInstitutions(institutions.map((el) => (el.id === institution.id ? institution : el)));
    };

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant) {
            dispatch(loadParticipantPastEducation(currentParticipant.id));
            dispatch(loadQualificationTypeDetails());
        }
    }, [currentParticipant.id]);

    useEffect(() => {
        setInstitutions(pastEducation.institutions);
    }, [pastEducation, currentParticipant?.id]);

    /**
     * Check if there are any incomplete past educations
     * @returns {boolean} - true if there are incomplete cards
     */
    const hasIncompleteCard = () => {
        return institutions.some((c) => !c.name || !c.qualificationTypeId);
    };

    // FORM SUBMIT
    const onSubmit = () => {
        if (hasIncompleteCard()) {
            dispatch(setErrorMessage(`Please complete all past education cards.`));
        } else if (pastEducation.length < 1 || institutions?.length < 1) {
            dispatch(setErrorMessage(`There is nothing to submit.`));
        } else {
            const payload = {
                id: initialState.id,
                institutions: institutions.map((el) => ({
                    ...el,
                    id: null
                }))
            };
            dispatch(updateParticipantPastEducation(payload, currentParticipant.id));
        }
    };

    let content;
    if (qualificationTypeDetails?.length < 1) content = 'No qualification type details';
    if (Object.keys(currentParticipant)?.length < 1) content = 'No current participant';

    // RENDER
    if (qualificationTypeDetails?.length < 1 || Object.keys(currentParticipant)?.length < 1) {
        return <LoadingSpinner content={content} />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <FormHeader text={'Past Education'}></FormHeader>
            <CardHandler
                viewerType={PAST_EDUCATION_VIEWER}
                cards={institutions || []}
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                itemName={'Education'}
                label={'Add Past Education'}
                data={qualificationTypeDetails}
                sendAdd={addInstitution}
                sendDelete={deleteInstitution}
                sendUpdate={updateInstitution}></CardHandler>
            <p>{}</p>
            <br />
            <br />
            <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                data-testid="testIdHISubmitButton">
                Update Past Education
            </Button>
        </form>
    );
};

export default Education;
