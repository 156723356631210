import React from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';

import { POLARIS_ROYAL_BLUE } from '../themes/theme';

const OnWarningIcon = ({ tooltipContent, color = POLARIS_ROYAL_BLUE }) => {
    return (
        <Tooltip title={tooltipContent} placement="top">
            <WarningIcon
                sx={{ color }}
                aria-label="warning-icon"
                role="img"
                data-testid="warning-icon"
            />
        </Tooltip>
    );
};

export default OnWarningIcon;

OnWarningIcon.propTypes = {
    tooltipContent: PropTypes.string,
    color: PropTypes.string
};
