import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { selectSmokingCoResults } from '../../../../store/dataSelectors';
import {
    loadSmokingCoResults,
    loadSmokingUsingECigarettes
} from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful,
    selectSmokingQuitAttempts
} from '../../../../store/participantSelectors';
import { loadSmokingQuitAttempts } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { formatDateForDateInput, reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import AccordionHeader from '../../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../../ui/accordion/AccordionIcon';

import AddQuitAttempt from './AddQuitAttempt';
import QuitAttempt from './QuitAttempt';

import app from '../../../../app.module.css';
import local from '../wellbeing.module.css';

const Smoking = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [type, setType] = useState('');
    const [expanded, setExpanded] = useState('');
    const [rows, setRows] = useState([]);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const smokingQuitAttempts = useSelector(selectSmokingQuitAttempts);
    const baselineCoMeasurements = useSelector(selectSmokingCoResults);
    const usingECigarettes = useSelector(
        (state) => state.entities.directusService.smokingUsingECigarettes
    );

    // USE EFFECTS
    useEffect(() => {
        currentParticipant?.id && dispatch(loadSmokingQuitAttempts(currentParticipant.id));
        baselineCoMeasurements?.length < 1 && dispatch(loadSmokingCoResults());
        usingECigarettes?.length < 1 && dispatch(loadSmokingUsingECigarettes());
    }, [currentParticipant]);

    useEffect(() => {
        if (
            !smokingQuitAttempts &&
            baselineCoMeasurements?.length < 1 &&
            usingECigarettes?.length < 1
        )
            return;
        const rows = smokingQuitAttempts.map((el) => ({
            ...el,
            dateSet: formatDateForDateInput(el.dateSet),
            quitDate: formatDateForDateInput(el.quitDate),
            baselineCoMeasurementName:
                baselineCoMeasurements.find((entry) => entry.id === el.baselineCoMeasurementId)
                    ?.name || '',
            usingECigaretteName:
                usingECigarettes.find((entry) => entry.id === el.usingECigaretteId)?.name || ''
        }));
        setRows(rows);
    }, [smokingQuitAttempts, baselineCoMeasurements, usingECigarettes]);

    // EVENT HANDLERS
    const onAdd = () => setType('new');

    const onAccordionChange = (panel) => setExpanded(expanded === panel ? '' : panel);

    const onQuitAttemptClose = () => setType('');

    // RENDER

    return (
        <div className={local.sectionWrapper}>
            <div className={app.sectionHeading}>Smoking</div>
            <Button
                disabled={
                    !hasRole(acceptedRoles, roles) ||
                    type === 'new' ||
                    isReferralHistoryAttemptSuccessful
                }
                color="primary"
                variant="contained"
                size={'small'}
                onClick={onAdd}>
                Add New Quit Attempt
                <Box width={SCREEN_SETTINGS.gap.medium} />
                <AddCircleOutlineIcon />
            </Button>
            {type === 'new' && <AddQuitAttempt roles={roles} onClose={onQuitAttemptClose} />}
            <div className={local.accordionContent}>
                {rows.map((el) => (
                    <div key={el.id}>
                        <Accordion
                            expanded={expanded === el.id}
                            onChange={() => onAccordionChange(el.id)}
                            TransitionProps={{ mountOnEnter: true }}>
                            <AccordionSummary
                                expandIcon={<AccordionIcon />}
                                aria-controls={`panel1a-content`}
                                sx={{ borderTop: '1px solid #000' }}
                                id={`panel1a-header`}>
                                <AccordionHeader>
                                    Quit Attempt {reverseFormatDate(el.quitDate)}
                                </AccordionHeader>
                            </AccordionSummary>
                            <AccordionDetails>
                                <QuitAttempt
                                    quitAttempt={el}
                                    roles={roles}
                                    onClose={onAccordionChange}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Smoking;
