import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import EditUserDetails from './EditUserDetails';

import classes from '../../../commonStyles/tables.module.css';

const UsersTableRow = ({ row, toggleDropdown, openId }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    return (
        <>
            <TableRow
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.name}</TableCell>
                <TableCell> {row.emailAddress || ' - '} </TableCell>
                <TableCell> {row.primaryService || ' - '} </TableCell>
                <TableCell>{row.primaryTeam || ' - '} </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={5}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <EditUserDetails
                                row={row}
                                onClose={() => setOpen(null)}
                                onCancel={() => setOpen(null)}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default UsersTableRow;

UsersTableRow.propTypes = {
    row: PropTypes.object,
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
