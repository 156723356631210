import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { stableSort } from '../../../../utils/sortFunctions';
import {
    DEFAULT_TABLE_ROW_OPTIONS,
    DEFAULT_TABLE_ROWS_PER_PAGE
} from '../../../../utils/uiConstants';

import JobOffersTableRow from './JobOffersTableRow';

const headCells = [
    { id: 'code', numeric: false, label: 'ID' },
    { id: 'vacancyName', numeric: false, label: 'Title' },
    { id: 'owner', numeric: false, label: 'Owner' },
    { id: 'sector', numeric: false, label: 'Job Sector' },
    { id: 'payRate', numeric: false, label: 'Pay Rate' },
    { id: 'postcode', numeric: false, label: 'Location' },
    { id: 'createPlacement', numeric: false, label: 'Create Placement' }
];

const JobOffersTable = ({ rows, roles, searchTerm, onPlacementEditor }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);
    const [openId, setOpenId] = useState('');

    // First time ensure data is sorted by id
    useEffect(() => {
        setOrder('asc');
        setOrderBy('id');
    }, []);

    useEffect(() => {
        setPage(0);
    }, [searchTerm]);

    // HELPER FNS
    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const onToggleDropdown = (id) => setOpenId(id);

    // RENDER
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => onSort(headCell.id)}>
                                            <Typography variant={'subtitle3'} color={'primary'}>
                                                {headCell.label}
                                            </Typography>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc'
                                                        ? 'sorted descending'
                                                        : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort(rows, orderBy, order)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <JobOffersTableRow
                                        key={row.id}
                                        row={row}
                                        roles={roles}
                                        toggleDropdown={onToggleDropdown}
                                        openId={openId}
                                        onPlacementEditor={onPlacementEditor}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

JobOffersTable.propTypes = {
    rows: PropTypes.array.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    searchTerm: PropTypes.string,
    onPlacementEditor: PropTypes.func
};

export default JobOffersTable;
