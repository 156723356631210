import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MedicalIcon from '../../icons/MedicalIcon';
import { loadCourseTemplateHealthDetails } from '../../store/directusService';
import { getNameFromId } from '../../utils/directusFunctions';

import classes from './healthInfo.module.css';

const HealthInfo = ({ healthInformationCaptured, healthInformationIds }) => {
    const dispatch = useDispatch();

    const [names, setNames] = useState([]);
    const { courseTemplateHealthDetails } = useSelector((state) => state.entities.directusService);

    useEffect(() => {
        dispatch(loadCourseTemplateHealthDetails());
    }, []);

    useEffect(() => {
        if (courseTemplateHealthDetails.length === 0) return;
        const names = healthInformationIds
            .map((el) => getNameFromId(courseTemplateHealthDetails, el))
            .filter((el) => el);
        setNames(names);
    }, [courseTemplateHealthDetails]);

    return (
        <div className={classes.healthInfo}>
            <MedicalIcon />
            <div>
                <span> Health Information Required </span>
                {healthInformationCaptured && (
                    <span>
                        <span className={classes.spacer}> - </span>
                        {names.map((el) => (
                            <span key={el} className={classes.healthItem}>
                                {el}
                            </span>
                        ))}
                    </span>
                )}
            </div>
        </div>
    );
};

export default HealthInfo;

HealthInfo.propTypes = {
    healthInformationCaptured: PropTypes.bool.isRequired,
    healthInformationIds: PropTypes.arrayOf(PropTypes.string)
};
