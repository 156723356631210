import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { saveAccessToken, setIdToken } from '../store/userService';

import { b2cPolicies } from './authConfig';

const Masl = () => {
    /**
     * Compare the token issuing policy with a specific policy name
     * @param {object} idTokenClaims - Object containing the claims from the parsed token
     * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
     * @returns {boolean}
     */
    function compareIssuingPolicy(idTokenClaims, policyToCompare) {
        let tfpMatches =
            // eslint-disable-next-line no-prototype-builtins
            idTokenClaims.hasOwnProperty('tfp') &&
            idTokenClaims['tfp'].toLowerCase() === policyToCompare.toLowerCase();
        let acrMatches =
            // eslint-disable-next-line no-prototype-builtins
            idTokenClaims.hasOwnProperty('acr') &&
            idTokenClaims['acr'].toLowerCase() === policyToCompare.toLowerCase();
        return tfpMatches || acrMatches;
    }

    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();
    const dispatch = useDispatch();
    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                // set idToken in store
                dispatch(setIdToken(event.payload.idToken));
                // save idToken in localStorage for refresh
                saveAccessToken(event.payload.idToken);

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (
                    compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)
                ) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                compareIssuingPolicy(
                                    account.idTokenClaims,
                                    b2cPolicies.names.signUpSignIn
                                )
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: []
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);
};

export default Masl;
