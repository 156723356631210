import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadForms, setCurrentForm } from '../../store/participantService';
import { selectLoggedInUser } from '../../store/userSelectors';
import { loadUser } from '../../store/userService';
import { SCREEN_SETTINGS } from '../../themes/theme';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import FormHeader from '../layout/FormHeader';
import DynamicSearch from '../search/DynamicSearch';
import LoadingSpinner from '../ui/LoadingSpinner';

import FormTable from './FormTable';

import classes from './formStyles/formToolbar.module.css';

const FormManagement = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState(rows);
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const forms = useSelector((state) => state.entities.participantService.forms);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadForms());
        dispatch(loadUser(loggedInUser.id));
    }, []);

    useEffect(() => {
        setRows(forms);
        setFilteredRows(forms);
    }, [forms]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredRows(filteredRows);
    }, [searchTerm]);

    useEffect(() => {
        if (successMessage === `Form has been saved successfully`) dispatch(loadForms());
    }, [successMessage]);

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onCreateForm = () => {
        dispatch(setCurrentForm({}));
        navigate('/build_form');
    };

    return (
        <div>
            <br />
            <FormHeader text={'Manage Form'}></FormHeader>
            <div className={classes.formToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter search term"
                />
            </div>
            {filteredRows.length < 1 ? (
                <LoadingSpinner content="No forms found" />
            ) : (
                <FormTable rows={filteredRows} roles={roles} searchTerm={searchTerm} />
            )}
            <Button
                disabled={!hasRole([SUPERUSER], roles)}
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton"
                onClick={onCreateForm}>
                {'Create Form'}
                <Box width={SCREEN_SETTINGS.gap.medium} />
                <AddCircleOutlineIcon />
            </Button>
        </div>
    );
};

export default FormManagement;
