export const NO_DOCUMENTS = 'No document types selected';
export const initialErrorState = {
    documentTypes: { error: false, message: '' },
    appointments: []
};

export const validate = (essentials, selected, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (selected?.length < 1) {
        newErrors = {
            ...newErrors,
            documentTypes: { error: true, message: NO_DOCUMENTS }
        };
        isValid = false;
    } else {
        essentials.forEach((el) => {
            if (!selected.includes(el.id)) {
                newErrors = {
                    ...newErrors,
                    appointments: [
                        ...newErrors.appointments,
                        {
                            id: el.id,
                            error: true,
                            message: `${el.name} is an Appointment Type and must have a corresponding Document Type.`
                        }
                    ]
                };
            }
        });
        if (newErrors.appointments.length > 0) isValid = false;
    }
    return { isValid, newErrors };
};
