import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import {
    selectFormContracts,
    selectForms,
    selectQuestionnaireContracts,
    selectQuestionnaires
} from '../../store/dataSelectors';
import {
    createQuestionnaireContract,
    loadFormContractByContract,
    loadForms,
    loadQuestionnaireContractByContract,
    loadQuestionnaires,
    updateFormContract
} from '../../store/participantService';
import { selectUserRoles } from '../../store/userSelectors';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';

const ParticipantQuestionnairesAndFormsAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [selectedForms, setSelectedForms] = useState([]);
    const [preSelectedForms, setPreSelectedForms] = useState([]);
    const [arrayForms, setArrayForms] = useState([]);
    const [isClearSelectedForms, setIsClearSelectedForms] = useState('0');

    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);
    const [preSelectedQuestionnaires, setPreSelectedQuestionnaires] = useState([]);
    const [arrayQuestionnaires, setArrayQuestionnaires] = useState([]);
    const [isClearSelectedQuestionnaires, setIsClearSelectedQuestionnaires] = useState('1');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const forms = useSelector(selectForms);
    const formContracts = useSelector(selectFormContracts);
    const questionnaires = useSelector(selectQuestionnaires);
    const questionnaireContracts = useSelector(selectQuestionnaireContracts);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    //HELPER FNS
    const contractSetup = () => {
        setSelectedForms([]);
        setPreSelectedForms([]);
        setArrayForms([]);
        setIsClearSelectedForms(Math.random());

        setSelectedQuestionnaires([]);
        setPreSelectedQuestionnaires([]);
        setArrayQuestionnaires([]);
        setIsClearSelectedQuestionnaires(Math.random());

        dispatch(loadForms());
        dispatch(loadQuestionnaires());
        if (contractId) {
            dispatch(loadFormContractByContract(contractId));
            dispatch(loadQuestionnaireContractByContract(contractId));
        }
    };

    // USE EFFECTS
    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (forms?.length && arrayForms?.length === 0) {
            setArrayForms(forms.filter((el) => !el.inactive));
        }
    }, [forms]);

    useEffect(() => {
        if (Object.keys(formContracts)?.length > 0) {
            setPreSelectedForms(
                formContracts
                    .filter((el) => el.contractIds.some((entry) => entry.includes(contractId)))
                    .map((el) => el.id)
            );
        }
    }, [formContracts]);

    useEffect(() => {
        if (questionnaires?.length && arrayQuestionnaires?.length === 0) {
            //rebuild questionnaires and rename title to name for DDLPicker
            const modifiedQuestionnaires = questionnaires.map((q) => {
                return { ...q, name: q.title };
            });

            setArrayQuestionnaires(modifiedQuestionnaires);
        }
    }, [questionnaires]);

    useEffect(() => {
        if (
            Object.keys(questionnaireContracts)?.length > 0 &&
            questionnaireContracts.questionnaires &&
            questionnaireContracts.questionnaires.length > 0
        ) {
            //map the list of questionnaireContracts titles an array of matching ids from questionnaireContracts to work with getPreSelectedOptions in DDLOptionPicker
            const questionnaireContractIds = questionnaireContracts.questionnaires.map((qc) => {
                return questionnaireContracts.questionnaires.find((q) => q.title === qc.title).id;
            });
            setPreSelectedQuestionnaires(questionnaireContractIds);
        }
    }, [questionnaireContracts]);

    useEffect(() => {
        if (
            successMessage.startsWith(`Form Contracts have been updated`) ||
            successMessage.startsWith(`Questionnaire Contracts have been updated`)
        ) {
            contractSetup();
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onQuestionnaireChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedQuestionnaires(chosenIds);
    };

    const onFormChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedForms(chosenIds);
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const questionnaireContracts = {
            questionnaireIds: selectedQuestionnaires,
            contractId: contractId
        };
        // this will merge all questionnaires so will add/remove
        dispatch(createQuestionnaireContract(questionnaireContracts));

        const formContracts = {
            formIds: selectedForms,
            contractId: contractId
        };
        // this will merge all forms so will add/remove
        dispatch(updateFormContract(formContracts));
    };

    // RENDER
    let content;
    if (!contractId) content = 'No Contract Id';
    if (questionnaires?.length < 1) content = 'No questionnaires';

    return !contractId || questionnaires?.length < 1 ? (
        <LoadingSpinner content={content} />
    ) : (
        <form onSubmit={onSubmit}>
            <br />
            <DDLMultiOptionPicker
                heading={'Configure Questionnaire Types'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="Questionnaires"
                id="questionnaires"
                key={isClearSelectedQuestionnaires}
                label="Questionnaire Types"
                placeholder="Questionnaire Types"
                menuItems={arrayQuestionnaires || []}
                preSelectedIds={preSelectedQuestionnaires}
                chosenIds={selectedQuestionnaires}
                onChange={(chosenIds) => onQuestionnaireChange(chosenIds)}
            />
            <DDLMultiOptionPicker
                heading={'Configure Forms'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="Forms"
                id="forms"
                key={isClearSelectedForms}
                label="Forms"
                placeholder="Forms"
                menuItems={arrayForms || []}
                preSelectedIds={preSelectedForms}
                chosenIds={selectedForms}
                onChange={(chosenIds) => onFormChange(chosenIds)}
            />

            <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton">
                {'UPDATE QUESTIONNAIRES AND FORMS'}
            </Button>
        </form>
    );
};

ParticipantQuestionnairesAndFormsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ParticipantQuestionnairesAndFormsAdmin;
