import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    loadSignPostingOrganisations,
    loadSignPostingTypes
} from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import {
    deleteSignPosting,
    loadSignPostings,
    onDeleteSignPosting
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { formatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CreateSignPosting from './CreateSignPosting';
import SignPostingTable from './SignPostingTable';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const SignPosting = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Signposting deleted`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const signPostingOrganisations = useSelector(
        (state) => state.entities.directusService.signPostingOrganisations
    );
    const signPostings = useSelector((state) => state.entities.participantService.signPostings);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        currentParticipant?.id && dispatch(loadSignPostings(currentParticipant.id));
        dispatch(loadSignPostingTypes());
        dispatch(loadSignPostingOrganisations());
    }, [currentParticipant]);

    useEffect(() => {
        if (signPostingOrganisations?.length < 1) return;
        const rows = signPostings.map((el) => {
            const org = signPostingOrganisations.find((entry) => entry.id === el.organisationId);
            return {
                ...el,
                displayDate: Date.parse(el.date),
                date: formatDate(el.date),
                organisation: org?.name || '',
                type:
                    org.signposting_type.find((entry) => entry.signposting_type_id.id === el.typeId)
                        ?.signposting_type_id.name || ''
            };
        });
        setRows(rows);
    }, [signPostings, signPostingOrganisations]);

    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeleteSignPosting(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteSignPosting(row.id, deleteMsg));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Signposting</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No signpostings found" />
                ) : (
                    <SignPostingTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Signposting
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreateSignPosting
                            onClose={() => setShowForm(false)}
                            roles={roles}
                            formType={formType}
                            row={selectedRow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SignPosting;
