import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Typography } from '@mui/material';

import { loadParticipantAppointments } from '../../../../store/calendarService';
import {
    loadDocumentTypes,
    loadJobSectorDetails,
    loadPoolStatusDetails
} from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import {
    loadAppointmentAttendanceDetailsBySearch,
    loadParticipantEmployability
} from '../../../../store/participantService';
import {
    loadPoolApplicationsByParticipant,
    loadPoolsForApplications
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { loadUser } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import DynamicSearch from '../../../search/DynamicSearch';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import ApplyToPoolEditor from './selectPools/ApplyToPoolEditor';
import PoolsTable from './PoolsTable';

import classes from '../submissionStyles/pools.module.css';

const PoolsTableManagement = () => {
    //HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const participantClosedStatusRoles = [MANAGER, QUALITY, SUPERUSER];
    const [participantDocumentsAddName, setParticipantDocumentsAddName] = useState([]);
    const [participantPools, setParticipantPools] = useState([]);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [poolApplicationsLoaded, setPoolApplicationsLoaded] = useState(false);
    const [storedPools, setStoredPools] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [poolEditor, setPoolEditor] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const { poolApplications, pools } = useSelector((state) => state.entities.recruitmentService);
    const { documentTypes, jobSectorDetails, participantStatusDetails, poolStatusDetails } =
        useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    const setPool = () => {
        onPoolEditor(true);
    };

    const onPoolEditor = (setOpen) => {
        setPoolEditor(setOpen);
    };

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // HELPER FNS
    const getDocumentName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return participantDocuments.find((item) => item.id === id)?.filename || '';
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadJobSectorDetails());
        dispatch(loadPoolStatusDetails());
        dispatch(loadDocumentTypes());
        dispatch(loadParticipantAppointments(currentParticipant.id));
        dispatch(
            loadAppointmentAttendanceDetailsBySearch({ participantIds: [currentParticipant.id] })
        );
        dispatch(loadParticipantDocumentDetails(currentParticipant.id));
        dispatch(loadParticipantEmployability(currentParticipant.id));
        dispatch(loadPoolApplicationsByParticipant(currentParticipant.id));
    }, []);

    useEffect(() => {
        if (poolApplications.length < 1) return;
        setStoredPools([]);
        const poolIds = poolApplications.map((el) => el.participantPoolId);
        dispatch(loadPoolsForApplications(poolIds));
    }, [poolApplications]);

    useEffect(() => {
        if (poolApplications.length < 1 || pools.length < 1 || storedPools.length > 0) return;
        setStoredPools(pools);
    }, [pools]);

    useEffect(() => {
        if (storedPools.length < 1) return;
        let updatedPoolApplications = poolApplications;

        storedPools.forEach((el) => {
            dispatch(loadUser(el.ownerId));
            updatedPoolApplications = updatedPoolApplications.map((entry) => {
                if (entry.participantPoolId === el.id) {
                    return {
                        ...entry,
                        code: el.code,
                        ownerId: el.ownerId,
                        ownerName: el.ownerId,
                        sectorId: el.jobSectorId
                    };
                } else {
                    return entry;
                }
            });
        });
        setParticipantPools(updatedPoolApplications);
        setRows(updatedPoolApplications);
    }, [storedPools]);

    useEffect(() => {
        if (participantDocuments?.length === 0) {
            setParticipantDocumentsAddName([]);
            return;
        }

        if (
            !participantDocuments?.length ||
            documentTypes?.length < 1 ||
            (participantPools?.length < 1 && !poolApplicationsLoaded)
        )
            return;

        const goldStandardCVId = documentTypes.find(
            (entry) => entry.name.toLowerCase() === 'gold standard cv'
        )?.id;
        const updated = participantDocuments
            .filter((el) => el.typeId === goldStandardCVId)
            .map((el) => ({ ...el, name: el.filename }));
        setParticipantDocumentsAddName(updated);

        const updatedPoolApplications = participantPools.map((el) => ({
            ...el,
            sectorName: getNameFromId(jobSectorDetails, el.sectorId),
            filename: getDocumentName(el.goldStandardCVId),
            statusName: getNameFromId(poolStatusDetails, el.statusId)
        }));
        setRows(updatedPoolApplications);
        setFilteredRows(updatedPoolApplications);
    }, [participantDocuments, documentTypes, poolApplicationsLoaded, participantPools]);

    useEffect(() => {
        if (!rows.length) return;
        const filtered = !searchTerm
            ? rows
            : rows.filter((el) => el.sectorName.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredRows(filtered);
    }, [searchTerm]);

    useEffect(() => {
        if (successMessage === `Pool applications have been loaded`) {
            setPoolApplicationsLoaded(true);
        }
    }, [successMessage]);

    // RENDER
    let content = '';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (poolStatusDetails?.length < 1) content = 'No pool status details';
    if (jobSectorDetails?.length < 1 || poolStatusDetails?.length < 1)
        return <LoadingSpinner content={content} />;

    return (
        <>
            <Typography className={classes.poolTitle} variant={'h5'}>
                Participant Pooling
            </Typography>
            <div className={classes.poolToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                />
                <div>
                    <Button
                        className={classes.poolToolBarButton}
                        disabled={
                            !hasRole(acceptedRoles, loggedInUser.roles) ||
                            (getNameFromId(
                                participantStatusDetails,
                                currentParticipant.statusId
                            ) === 'Closed' &&
                                !hasRole(participantClosedStatusRoles, loggedInUser.roles))
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={setPool}>
                        Apply to new Pool
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                    <ApplyToPoolEditor
                        open={poolEditor}
                        onPoolEditor={onPoolEditor}
                        participantDocuments={participantDocumentsAddName}
                        poolApplications={poolApplications}
                    />
                </div>
            </div>

            {filteredRows.length < 1 ? (
                <LoadingSpinner content="No participant pools found" />
            ) : (
                <PoolsTable
                    rows={filteredRows}
                    roles={loggedInUser.roles}
                    searchTerm={searchTerm}
                />
            )}
        </>
    );
};

export default PoolsTableManagement;
