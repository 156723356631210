import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { getNoUpdateMessage } from '../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {
    button: { error: false, message: '' }
};

export const validate = (newEntry, initialEntry) => {
    let newErrors = {};

    const isSame = compareUnorderedArrays(Object.values(initialEntry), Object.values(newEntry));
    if (isSame) {
        newErrors = {
            ...newErrors,
            button: {
                error: true,
                message: getNoUpdateMessage()
            }
        };
    }

    return { newErrors };
};
