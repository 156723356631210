import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Divider,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from '@mui/material';

import { selectBarrierDetails } from '../../../store/dataSelectors';
import { setErrorMessage, setSuccessMessage } from '../../../store/formsState';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { addParticipantBarrier, updateParticipantBarrier } from '../../../store/participantService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { getNameFromId } from '../../../utils/directusFunctions';
import IconError from '../../IconError';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * Edit a barrier
 * @param barrier - the barrier to edit
 * @param onComplete - callback to when editor has finished
 * @returns {JSX.Element}
 */
const BarrierEditor = ({ barrier, onComplete }) => {
    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({});

    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        participantId: '',
        id: '',
        barrierId: null,
        startDate: null,
        dueDate: null,
        priority: null
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [isClearBarrierName, setIsClearBarrierName] = useState('0');

    // STORE STATE
    const barrierNameDetails = useSelector(selectBarrierDetails);
    const currentParticipant = useSelector(selectCurrentParticipant);

    // USEEFFECTS
    useEffect(() => {
        if (currentParticipant && !newEntry.participantId) {
            setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
        }
    }, [currentParticipant]);

    useEffect(() => {
        setNewEntry(barrier);
        clearErrors('startDate');
        clearErrors('dueDate');
    }, [barrier]);

    // EVENT HANDLERS

    const handleBarrierNameChange = (chosenId) => {
        clearErrors('barrierName');
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, barrierId: chosenId }));
    };

    const handleStartDateChange = (e) => {
        clearErrors('startDate');
        setNewEntry((prev) => ({
            ...prev,
            startDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleDueDateChange = (e) => {
        clearErrors('dueDate');
        setNewEntry((prev) => ({
            ...prev,
            dueDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handlePriorityChange = (e, priority) => {
        setNewEntry((prev) => ({
            ...prev,
            priority
        }));
    };

    // FORM SUBMIT FNS
    const editingComplete = () => {
        setNewEntry(initialState);
        setIsClearBarrierName(Math.random());
        onComplete();
    };

    const barrierDataValid = (barrier) => {
        let valid = true;

        if (!barrier.participantId) {
            dispatch(setErrorMessage(`No participant id`));
            valid = false;
        }

        if (!barrier.barrierId) {
            setError('barrierName', { type: 'manual', message: 'Select a barrier name' });
            valid = false;
        }

        if (!barrier.startDate) {
            setError('startDate', { type: 'manual', message: 'Select a start date' });
            valid = false;
        }

        if (!barrier.dueDate) {
            setError('dueDate', { type: 'manual', message: 'Select a due date' });
            valid = false;
        }

        if (!barrier.priority) {
            setError('priority', { type: 'manual', message: 'Select a priority' });
            valid = false;
        }

        // Check start date is a valid date
        if (isNaN(new Date(barrier.startDate))) {
            setError('startDate', { type: 'manual', message: 'Start date is not valid' });
            valid = false;
        }

        const startDate = new Date(barrier.startDate);

        // Check due date is a valid date
        if (isNaN(new Date(barrier.dueDate))) {
            setError('dueDate', { type: 'manual', message: 'Due date is not valid' });

            valid = false;
        }

        // Check due date is not before start date
        const dueDate = new Date(barrier.dueDate);
        if (dueDate < startDate) {
            setError('dueDate', {
                type: 'manual',
                message: 'Due date must be on or after start date'
            });
            valid = false;
        }
        return valid;
    };

    // FORM SUBMIT
    const onSubmit = () => {
        clearErrors();

        if (barrierDataValid(newEntry)) {
            const newBarrier = {
                ...newEntry,
                startDate: new Date(newEntry.startDate).toISOString(),
                dueDate: new Date(newEntry.dueDate).toISOString()
            };

            if (newBarrier.id) {
                dispatch(updateParticipantBarrier(newBarrier));
                dispatch(setSuccessMessage(`Updated existing barrier`));
            } else {
                dispatch(addParticipantBarrier(newBarrier));
                dispatch(setSuccessMessage(`Added new barrier`));
            }
            editingComplete();
        }
    };

    // RENDER
    return (
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start_edit_barrier">
            <div className={form.pageSelectorWrapper}>
                <DDLOptionPicker
                    key={isClearBarrierName}
                    label={'Barrier Name'}
                    mandatory={true}
                    data-testid="barrier_name_ddl_option_picker"
                    menuItems={barrierNameDetails || []}
                    chosenName={getNameFromId(barrierNameDetails, newEntry?.barrierId)}
                    chosenId={newEntry?.barrierId}
                    showError={false}
                    onChange={(chosenId) => handleBarrierNameChange(chosenId)}></DDLOptionPicker>
                <div className={form.pageErrorWrapper}>
                    <IconError text={errors.barrierName || null} data-testid={'nameError'} />
                </div>
            </div>

            <div className={form.radioGroup}>
                <label htmlFor="paymentMethod" className={form.formLabel}>
                    What is the barrier priority? <sup>*</sup>
                </label>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={newEntry.priority || ''}
                    onChange={handlePriorityChange}
                    name="radio-buttons-group">
                    <Stack direction="row" spacing={1}>
                        <FormControlLabel
                            {...register('priority')}
                            value="Low"
                            control={<Radio />}
                            label="Low"
                        />
                        <FormControlLabel
                            {...register('priority')}
                            value="Medium"
                            control={<Radio />}
                            label="Medium"
                        />
                        <FormControlLabel
                            {...register('priority')}
                            value="High"
                            control={<Radio />}
                            label="High"
                        />
                    </Stack>
                </RadioGroup>
                <IconError text={errors.priority || null} data-testid={'priorityError'} />
            </div>
            <div className={form.formColumnSplit}>
                <div className={form.dateSelector}>
                    <TextField
                        id="startDate"
                        data-testid="startDate"
                        label="Start Date"
                        type="date"
                        required={true}
                        value={newEntry.startDate || ''}
                        InputLabelProps={{
                            shrink: true
                        }}
                        {...register('startDate')}
                        onChange={handleStartDateChange}
                    />
                    <IconError text={errors.startDate || null} />
                </div>
                <div className={form.dateSelector}>
                    <TextField
                        id="dueDate"
                        data-testid="dueDate"
                        label="Due Date"
                        type="date"
                        required={true}
                        value={newEntry.dueDate || ''}
                        InputLabelProps={{
                            shrink: true
                        }}
                        {...register('dueDate')}
                        onChange={handleDueDateChange}
                    />
                    <IconError text={errors.dueDate || null} />
                </div>
            </div>
            <Divider
                sx={{
                    mt: SCREEN_SETTINGS.margin.large,
                    mb: SCREEN_SETTINGS.margin.large
                }}
            />

            <Stack direction="row" spacing={4}>
                <Button variant={'contained'} type={'submit'} data-testid="testIdAddBarrierButton">
                    {' '}
                    Save this Barrier &gt;
                </Button>
                <Link
                    label="CancelX"
                    onClick={() => editingComplete()}
                    underline="always"
                    variant="body1"
                    color="primary"
                    sx={{
                        paddingTop: SCREEN_SETTINGS.margin.large
                    }}>
                    Cancel X
                </Link>
            </Stack>
        </form>
    );
};
BarrierEditor.propTypes = {
    barrier: PropTypes.object,
    onComplete: PropTypes.func
};

export default BarrierEditor;
