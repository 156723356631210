import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnFileDownloadIcon from '../../../../icons/OnFileDownloadIcon';
import { loadPoolStatusDetails } from '../../../../store/directusService';
import { downloadParticipantDocument } from '../../../../store/documentService';
import { loadParticipantByPtCode } from '../../../../store/participantService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import RejectionDetails from './RejectionDetails';

import classes from '../../../../commonStyles/tables.module.css';
import poolClasses from '../../recruitmentStyles/pool.module.css';

const PoolTableRow = ({
    row,
    roles,
    toggleDropdown,
    openId,
    onUpdateParticipantStatus,
    onPoolApplicationRejectEditor,
    onPoolRejectedRow,
    rowCancelled
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [open, setOpen] = useState(null);
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [poolStatus, setPoolStatus] = useState({});
    const [poolStatusDetailsTrimmed, setPoolStatusDetailsTrimmed] = useState([]);

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    const poolApplicationReject = () => {
        onPoolApplicationRejectEditor(true);
    };

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { poolStatusDetails } = useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    function onEditParticipant() {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            navigate('/edit_participant');
        }
    }

    const onDownload = () => dispatch(downloadParticipantDocument(row.participantId, row.filename));

    // HELPER FNS
    const onStatusIdChange = (id) => {
        const selected = poolStatusDetails.find((el) => el.id === id);

        if (poolStatus?.id && poolStatus.id !== id) {
            onUpdateParticipantStatus(id, row.participantId, row.participantPoolApplicationId);
            if (
                selected.name.toLowerCase() === 'rejected' &&
                row.statusName.toLowerCase() !== 'rejected'
            ) {
                onPoolRejectedRow(row);
                poolApplicationReject();
            }
        }
        selected && setPoolStatus(selected);
    };

    // USE EFFECTS
    useEffect(() => {
        setPoolStatus({});
        if (poolStatusDetails?.length < 1) dispatch(loadPoolStatusDetails());
    }, []);

    useEffect(() => {
        if (!poolStatusDetails?.length) return;
        const poolStatus = poolStatusDetails.find((el) => el.id === row.statusId);
        setPoolStatus(poolStatus);

        if (poolStatus?.name.toLowerCase() === 'requested') {
            setPoolStatusDetailsTrimmed(poolStatusDetails);
        } else if (poolStatus?.name.toLowerCase() === 'accepted') {
            setPoolStatusDetailsTrimmed(
                poolStatusDetails.filter(
                    (el) => el.name.toLowerCase() !== 'Requested'.toLowerCase()
                )
            );
        } else if (poolStatus?.name.toLowerCase() === 'rejected') {
            setPoolStatusDetailsTrimmed(
                poolStatusDetails.filter(
                    (el) =>
                        el.name.toLowerCase() !== 'Requested'.toLowerCase() &&
                        el.name.toLowerCase() !== 'Accepted'.toLowerCase()
                )
            );
        }
    }, [poolStatusDetails]);

    useEffect(() => {
        rowCancelled && setPoolStatus(poolStatusDetails.find((el) => el.id === row.statusId));
    }, [rowCancelled]);

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell
                    className={poolClasses.poolCodeLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell>{row.ptName ? row.ptName : ''}</TableCell>
                <TableCell>{row?.filename || ''}</TableCell>
                <TableCell align="center">
                    <OnFileDownloadIcon
                        roles={roles}
                        acceptedRoles={[MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER]}
                        active={
                            hasRole(acceptedRoles, loggedInUser.roles) &&
                            row?.filename !== '' &&
                            row?.filename !== undefined
                        }
                        onDownload={onDownload}
                        participantId={row?.participantId}
                        filename={row?.filename}
                    />
                </TableCell>
                <TableCell align="center">
                    {row?.startDate !== 'z-' ? reverseFormatDate(row?.startDate) : '-'}
                </TableCell>
                <TableCell>{row?.jobSectorName || ''}</TableCell>
                <TableCell sx={{ padding: '0', paddingRight: '5px' }}>
                    <div className={poolClasses.rowOptionPicker}>
                        <DDLOptionPicker
                            key={0}
                            id={'poolStatus'}
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                row?.statusId === '' ||
                                row?.statusId === undefined ||
                                poolStatus?.name?.toLowerCase() === 'rejected'
                            }
                            menuItems={poolStatusDetailsTrimmed || []}
                            chosenName={poolStatus?.name || ''}
                            chosenId={poolStatus?.id || ''}
                            onChange={(chosenId) => onStatusIdChange(chosenId)}
                        />
                    </div>
                </TableCell>
            </TableRow>
            {poolStatus?.name?.toLowerCase() !== 'rejected' ? (
                ''
            ) : (
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderTop: 'none',
                            borderBottom: 'none'
                        }}
                        colSpan={7}>
                        <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                            <Box>
                                <RejectionDetails row={row} onClose={() => setOpen(null)} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default PoolTableRow;

PoolTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    onUpdateParticipantStatus: PropTypes.func,
    onPoolApplicationRejectEditor: PropTypes.func,
    onPoolRejectedRow: PropTypes.func,
    rowCancelled: PropTypes.bool
};
