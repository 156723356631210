import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../../icons/OnEditIcon';
import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../../store/participantService';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import {
    ADVISER,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import ValidationRowDetails from '../../claims/validation/ValidationRowDetails';

import classes from '../../../commonStyles/tables.module.css';
import local from './claims.module.css';

const ClaimTableRow = ({ row, roles, toggleDropdown, openId, onEditAction }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [open, setOpen] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && loaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    // EVENT HANDLERS

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };
    function onEditParticipant() {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            setLoaded(true);
        }
    }

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}>
                <TableCell
                    className={local.claimsCodeLink}
                    onClick={onEditParticipant}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row?.firstName === 'zz' ? '-' : row.firstName}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row?.lastName === 'zz' ? '-' : row.lastName}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row?.eventName === 'zz' ? '-' : row.eventName}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row?.statusName === 'zz' ? '-' : row.statusName}
                </TableCell>
                <TableCell align="center" onClick={onToggleDropdown}>
                    {row?.actionStatus === 'zz' ? '-' : row.actionStatus}
                </TableCell>
                <TableCell className={local.editCell}>
                    {row?.actionStatus === 'Corrective Action Required' ? (
                        <OnEditIcon
                            onEdit={() => onEditAction(row)}
                            roles={roles}
                            acceptedRoles={acceptedRoles}
                        />
                    ) : null}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={7} sx={{ padding: '0', border: 'none' }}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <ValidationRowDetails row={row} onClose={() => setOpen(null)} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ClaimTableRow;

ClaimTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string,
    onEditAction: PropTypes.func
};
