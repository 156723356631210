import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../formElements/Button';

import actions from './formActions.module.css';

const FormActions = ({
    onClose,
    onCancel,
    btnText,
    disabled = false,
    customClass = '',
    id = ''
}) => {
    return (
        <div className={`${actions.formActions} ${actions[customClass]}`}>
            <Button id={id} onClick={onClose} disabled={disabled} content={btnText} />

            <div className={actions.cancelLink} onClick={onCancel}>
                Cancel X
            </div>
        </div>
    );
};

FormActions.propTypes = {
    id: PropTypes.string,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    btnText: PropTypes.string,
    disabled: PropTypes.bool,
    customClass: PropTypes.string
};

export default FormActions;
