import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../../store/directusService';
import {
    loadRecentParticipants,
    saveCurrentParticipantId,
    setCurrentlySelectedParticipant
} from '../../../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../../../commonStyles/tables.module.css';

const AddParticipantRow = ({ row, roles, type, onSelectRow, selectedRows }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [newRowData, setNewRowData] = useState({
        contactMode: 'PRIMARY_PHONE_NUMBER',
        contactNumber: row.primaryPhoneNumber
    });
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (type !== 'SMS') return;
        const numbers = [
            {
                id: 'PRIMARY_PHONE_NUMBER',
                name: row.primaryPhoneNumber
            },
            {
                id: 'SECONDARY_PHONE_NUMBER',
                name: row.secondaryPhoneNumber
            }
        ].filter((el) => el);
        setPhoneNumbers(numbers);
    }, [row]);

    useEffect(() => {
        setIsSelected(selectedRows.some((el) => el.id === row.id));
    }, [selectedRows]);

    const selectRow = () => {
        const updatedRow = {
            ...row,
            selectedContact: type === 'SMS' ? newRowData : { contactMode: 'EMAIL' }
        };
        onSelectRow(updatedRow, isSelected);
    };

    const onNav = () => {
        dispatch(setCurrentlySelectedParticipant(row));
        dispatch(loadContractDetailsForContractId(row.contractId));
        dispatch(loadServiceDetailsForContractId(row.contractId));
        navigate('/edit_participant');
        saveCurrentParticipantId(row.id);
        dispatch(loadRecentParticipants());
    };

    return (
        <TableRow
            hover
            className={`${classes.basicRowStripes} ${type === 'SMS' ? classes.rowHeight : ''}`}>
            <TableCell align="center" className={classes.rowLink} onClick={onNav}>
                {row.ptCode}
            </TableCell>
            <TableCell align="center">{row.firstName}</TableCell>
            <TableCell align="center">{row.lastName}</TableCell>
            <TableCell align="center">
                {type === 'SMS' ? (
                    <div className={classes.rowOptionPicker}>
                        <DDLOptionPicker
                            label={''}
                            id={'contactMode'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={phoneNumbers || []}
                            chosenName={newRowData.contactNumber}
                            chosenId={newRowData.contactMode}
                            onChange={(chosenId) => {
                                const contactNumber = phoneNumbers.find((el) => el.id === chosenId);
                                setNewRowData((prev) => ({
                                    ...prev,
                                    contactMode: chosenId || '',
                                    contactNumber: contactNumber?.name || ''
                                }));
                            }}></DDLOptionPicker>
                    </div>
                ) : (
                    <>{row.emailAddress === 'z' ? ' - ' : row.emailAddress}</>
                )}
            </TableCell>
            <TableCell align="center">
                {row.currentStatus === 'zz' ? ' - ' : row.currentStatus}
            </TableCell>
            <TableCell align="center">
                <div className={form.crossBox} onClick={selectRow}>
                    {isSelected ? 'X' : ''}
                </div>
            </TableCell>
        </TableRow>
    );
};

export default AddParticipantRow;

AddParticipantRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    onSelectRow: PropTypes.func,
    selectedRows: PropTypes.arrayOf(PropTypes.object)
};
