import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUsers } from '../../../../store/userSelectors';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const VacancyTable = ({ onIsLoaded, currentParticipant }) => {
    const vacancyRef = useRef(null);
    const [rows, setRows] = useState([]);
    const jobSectors = useSelector((state) => state.entities.directusService.jobSectorDetails);
    const users = useSelector(selectUsers);
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const { vacanciesForOwners, vacancySubmissions } = useSelector(
        (state) => state.entities.recruitmentService
    );

    useEffect(() => {
        if (
            vacanciesForOwners?.length < 1 ||
            users?.length < 1 ||
            jobSectors?.length < 1 ||
            vacancySubmissions?.length < 1 ||
            vacancyStageDetails?.length < 1
        ) {
            setRows([]);
            return;
        }

        const participantSubmissions = vacancySubmissions.filter(
            (el) => el.participantId === currentParticipant.id
        );
        const excludedStatuses = ['rejected', 'job offered'];
        const data = vacanciesForOwners
            .filter((el) => participantSubmissions.some((entry) => entry.vacancyId === el.id))
            .map((el) => {
                const owner = users.find((entry) => entry.id === el.ownerId);
                const { firstName, lastName } = owner
                    ? owner
                    : firstName && lastName
                      ? '${firstName} ${lastName}'
                      : ' - ';
                const statusId = participantSubmissions.find(
                    (entry) => entry?.vacancyId === el.id
                )?.statusId;

                return {
                    code: el.code,
                    title: el?.title,
                    // eslint-disable-next-line no-constant-binary-expression
                    owner: `${firstName} ${lastName}` || ' - ',
                    jobSector: getNameFromId(jobSectors, el?.sectorId) || ' - ',
                    payRate: `${el?.payRateFrom || ' - '} - ${el?.payRateTo || ' - '}`,
                    location: el?.remote ? 'Remote' : el?.postcode,
                    status: getNameFromId(vacancyStageDetails, statusId)
                };
            })
            .filter((el) => !excludedStatuses.includes(el.status.toLowerCase()));
        setRows(data);
    }, [vacanciesForOwners, vacancyStageDetails, vacancySubmissions, users, jobSectors]);

    useEffect(() => {
        vacancyRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('vacancy');
    }, [vacancyRef.current]);

    return (
        <div>
            <h3>Current Vacancy</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>ID</span>
                    <span>Title</span>
                    <span>Owner</span>
                    <span>Job Sector</span>
                    <span>Pay Rate</span>
                    <span>Location</span>
                    <span>Status</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (vacancyRef.current = el)}
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.title}</span>
                                <span>{el.owner}</span>
                                <span>{el.jobSector}</span>
                                <span>{el.payRate}</span>
                                <span>{el.location}</span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VacancyTable;

VacancyTable.propTypes = {
    onIsLoaded: PropTypes.func,
    currentParticipant: PropTypes.object
};
