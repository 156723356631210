import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Autocomplete,
    Chip,
    Container,
    createFilterOptions,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';

import {
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_VICTORIA_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../themes/theme';

import {
    RADIO_BUTTON_NO,
    RADIO_BUTTON_PREFER_NOT_TO_SAY,
    RADIO_BUTTON_YES
} from './SelectorOptions';

import form from '../../../commonStyles/formStyles.module.css';
/**
 * Get a set of options using a fuzzy selection picker.
 * @param id ID
 * @param disabled. If true component is disabled. Default = false.
 * @param label label
 * @param inputLabel Label for the input
 * @param enterPrompt. Shown by fuzzy search. E.g. 'disability' Default =''
 *                     This is shown as either
 *                     'Confirm <enterPrompt> ?'
 *                         or
 *                     'Cancel <enter prompt> ?'
 *@param allOptions Array of objects with the data needed here - id and name.
 * @param preSelectedIds  Array of ids of pre-selected options. Default = []
 * These are shown as pills at start.
 *
 * @param showPreferNotToSay. If false 'Prefer not to say' button is hidden. Default = true,
 * @param radioButtonPick  This decides which button is initially shown.
 * It's a string value which should be in SelectorOptions.js
 * 'yes' | 'no' | 'prefer' not to say'
 *
 * @param onChange  The onChange handler which must be supplied by parent.
 * This returns the current radio button selection and a list of selected ids.
 * See PPL-600
 *
 * The user types in text and matching options are shown in a list
 * zero or more of these can be chosen.
 *
 * Each chosen option is displayed in a set of Pills. (Chips)
 *
 * Any pills can be deleted by clicking on them.
 *
 */

const FuzzySearchOptionPicker = ({
    id = '',
    disabled = false,
    label = '',
    inputLabel = '',
    enterPrompt = '',
    allOptions = [],
    preSelectedIds = [],
    showPreferNotToSay = true,
    radioButtonPick = RADIO_BUTTON_NO,
    onChange
}) => {
    // LOCAL STATE

    const OPTIONS_LIMIT = 1024;
    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_VICTORIA_BLUE;
    const [selectedNames, setSelectedNames] = useState([]);
    const [sortedOptions, setSortedOptions] = useState([]);
    const [radioSelection, setRadioSelection] = useState({ radioButtonPick });
    const [currentText, setCurrentText] = useState('');

    // USEEFFECTS

    useEffect(() => {
        setSelectedNames([]);
        setSortedOptions([]);
        if (allOptions.length < 1) return;
        const names = preSelectedIds
            .map((id) => allOptions.find((el) => el.id === id)?.name)
            .filter((el) => el);
        setSelectedNames(names);
        setSortedOptions(allOptions.map((el) => el.name));
    }, []);

    useEffect(() => {
        // If 'yes' radio button not chosen then no selected ids.
        if (!disabled) {
            if (radioSelection === RADIO_BUTTON_YES) {
                const ids = selectedNames.map(
                    (el) =>
                        allOptions.find((entry) => entry.name.toLowerCase() === el.toLowerCase())
                            ?.id
                );
                onChange(ids, radioSelection);
            } else {
                onChange([], radioSelection);
            }
        }
    }, [selectedNames, radioSelection]);

    useEffect(() => {
        setRadioSelection(radioButtonPick);
    }, [radioButtonPick]);

    // EVENT HANDLERS
    const handleRadioClick = (e) => {
        if (!disabled && e.target.value) setRadioSelection(e.target.value);
    };

    const handleItemSelection = (e) => setCurrentText(e.target.value);

    const handleDelete = (itemToDelete) => () => {
        if (!disabled) {
            setSelectedNames(selectedNames.filter((item) => item !== itemToDelete));
        }
    };

    const handleAddItem = () => {
        if (
            !selectedNames.some(
                (el) => el.trim().toLowerCase() === currentText.trim().toLowerCase()
            )
        ) {
            setSelectedNames([...selectedNames, currentText]);
        }
        setCurrentText('');
    };

    // Show up to OPTIONS_LIMIT options where match is anywhere in text
    const optionLimit = (options, state) => {
        const limitedOptions = createFilterOptions();
        return limitedOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    // This is sortedOptions minus any selected items
    const getPossibleOptions = () => {
        return sortedOptions.filter((value) => !selectedNames.includes(value));
    };

    // Should only be shown if text entered matches one of selectedItems in list.
    const showConfirm = () => {
        if (currentText.length === 0) return false;
        return getPossibleOptions().some((el) => el.toLowerCase() === currentText.toLowerCase());
    };

    const showPills = () => radioSelection === 'yes';

    return (
        <div className={form.fuzzySearch}>
            <label htmlFor={id} className={form.formLabel}>
                {label}
            </label>

            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                data-testid={'radio_button_group_id'}
                name="radio-buttons-group"
                value={radioSelection}
                disabled={disabled}
                onClick={handleRadioClick}>
                <FormControlLabel
                    data-testid={'radio_button_no_id'}
                    value={RADIO_BUTTON_NO}
                    control={<Radio disabled={disabled} />}
                    label="No"
                />
                <FormControlLabel
                    data-testid={'radio_button_yes_id'}
                    value={RADIO_BUTTON_YES}
                    control={<Radio disabled={disabled} />}
                    label="Yes"
                />
                <FormControlLabel
                    data-testid={'radio_button_prefer_not_to_say_id'}
                    sx={{
                        display: showPreferNotToSay === true ? 'inline' : 'none'
                    }}
                    value={RADIO_BUTTON_PREFER_NOT_TO_SAY}
                    control={<Radio disabled={disabled} />}
                    label="Prefer not to say"
                />
            </RadioGroup>

            <Container
                sx={{
                    display: showPills() ? 'inline' : 'none'
                }}>
                <div className={form.optionalInput}>
                    {inputLabel && <label className={form.formLabel}>{inputLabel}</label>}
                    <div className={form.inputConfirmSet}>
                        <Autocomplete
                            value={currentText}
                            disabled={disabled}
                            freeSolo={true}
                            data-testid="auto_complete_id"
                            filterOptions={optionLimit}
                            options={getPossibleOptions() || []}
                            renderInput={(params) => (
                                <TextField disabled={disabled} sx={{ width: '100%' }} {...params} />
                            )}
                            size={'small'}
                            sx={{ width: '50%' }}
                            onSelect={handleItemSelection}
                            // Set width of dropdown box
                            componentsProps={{
                                paper: {
                                    sx: {
                                        width: '100%'
                                    }
                                }
                            }}
                            // Only show up to ~11 items in scrollable box
                            ListboxProps={{
                                style: {
                                    maxHeight: '380px'
                                }
                            }}
                        />
                        <Grid
                            sx={{
                                display: showConfirm() ? 'flex' : 'none'
                            }}>
                            <Typography
                                variant="body1"
                                onClick={handleAddItem}
                                sx={{
                                    marginLeft: SCREEN_SETTINGS.margin.medium,
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: '8px'
                                }}>
                                <CheckCircleIcon
                                    sx={{
                                        color: POLARIS_ROYAL_BLUE
                                    }}
                                />
                                Confirm {enterPrompt}
                            </Typography>
                        </Grid>
                    </div>
                </div>
                <Grid container>
                    {/* Show chips */}

                    {selectedNames.map((item, index) => (
                        <Chip
                            data-testid={'pill' + index}
                            key={index}
                            label={item}
                            sx={{
                                marginRight: SCREEN_SETTINGS.margin.small,
                                marginTop: SCREEN_SETTINGS.margin.small,
                                color: POLARIS_WHITE,
                                '& .MuiChip-deleteIcon': {
                                    color: POLARIS_WHITE
                                },
                                backgroundColor: { backgroundColor }
                            }}
                            onDelete={handleDelete(item)}
                            deleteIcon={<CancelOutlinedIcon />}></Chip>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default FuzzySearchOptionPicker;
FuzzySearchOptionPicker.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    inputLabel: PropTypes.string,
    onChange: PropTypes.func,
    enterPrompt: PropTypes.string,
    showPreferNotToSay: PropTypes.bool,
    preSelectedIds: PropTypes.arrayOf(PropTypes.string),
    allOptions: PropTypes.array,
    radioButtonPick: PropTypes.string
};
