import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnCreateIcon from '../../../icons/OnCreateIcon';
import { setCurrentTemplate } from '../../../store/courseService';
import { selectUserName } from '../../../store/userSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

import AllActiveCourseTemplateDetails from './AllActiveCourseTemplateDetails';

import classes from '../../../commonStyles/tables.module.css';

const AllTemplateTableRow = ({ row, roles, toggleDropdown, openId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const onSelectUserName = useMemo(selectUserName, []);

    const [open, setOpen] = useState(null);

    const userName = useSelector((state) => onSelectUserName(state, row.userId));

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    const onCreateCourse = (row) => {
        dispatch(setCurrentTemplate(row));
        navigate('/create_course', {
            state: { id: row.id, accordionPanel: 'allTemplates' }
        });
    };

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    return (
        <>
            <TableRow
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{userName}</TableCell>
                <TableCell>{reverseFormatDate(row.dateCreated)}</TableCell>
                <TableCell>{reverseFormatDate(row.reviewDate)}</TableCell>
                <TableCell align="right">
                    <OnCreateIcon
                        onCreate={() => onCreateCourse(row)}
                        titleString={'Course'}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={5}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <AllActiveCourseTemplateDetails
                                row={row}
                                onClose={() => setOpen(null)}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AllTemplateTableRow;

AllTemplateTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
