import { React } from 'react';

import { Card, CardContent, Typography } from '@mui/material';

/**
 * Shown whenever the correct form is not available.
 *
 * The form shows 'TBC:' and the name of the form which will be shown.
 *
 * Eventually this will disappear.
 */

const NotFound = () => {
    return (
        <Card
            data-testid="form_start"
            sx={{
                height: '800px'
            }}>
            <CardContent>
                <div>
                    <Typography variant="h3">{'Cannot find that page'}</Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default NotFound;
