import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectAllAppointments, selectUsersAppointments } from '../../store/calendarSelectors';
import { loadUserAppointments, loadUserAppointmentSlots } from '../../store/calendarService';
import {
    selectAllCourseIds,
    selectCourseModules,
    selectCourses,
    selectCurrentCourse,
    selectCurrentModule
} from '../../store/courseSelectors';
import {
    loadCourseModulesByUserId,
    searchCoursesByCourseIds,
    setCurrentModule,
    setOpenModuleSchedulerPanel,
    updateCourseModule
} from '../../store/courseService';
import { selectAppointmentTypes, selectLocationIdsAndNames } from '../../store/dataSelectors';
import { searchParticipantsByLoggedInUserServiceIds } from '../../store/participantService';
import { selectLoggedInUser, selectUsersById } from '../../store/userSelectors';
import { loadUserById, searchUsersByLoggedInUserServiceIds } from '../../store/userService';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import SingleSelect from '../formElements/SingleSelect';
import FormHeader from '../layout/FormHeader';
import Calendar from '../ui/calendar/Calendar';
import {
    configCourseEventContent,
    configParticipantEventContent,
    fetchLoggedInUserData,
    formatFullCalendarTimes,
    getBusinessHours,
    mapColors,
    mapCommonParams
} from '../ui/calendar/calendarUtils';

import ModuleScheduler from './moduleScheduler/ModuleScheduler';

import classes from '../../app.module.css';

const PT_LOAD_SIZE = 50;

const CourseCalendar = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const onSelectUsersAppointments = useMemo(selectUsersAppointments, []);

    // LOCAL STATE
    const [owner, setOwner] = useState({});
    const [users, setUsers] = useState([]);
    const [adviser, setAdviser] = useState({});
    const [advisers, setAdvisers] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [allModules, setAllModules] = useState([]);
    const [userEvents, setUserEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [pending, setPending] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [changedModule, setChangedModule] = useState(false);
    const [isSlotSelectable, setIsSlotSelectable] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const paginatedUsers = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIds
    );
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const usersById = useSelector(selectUsersById);
    const allAppointments = useSelector(selectAllAppointments);
    const usersAppointments = useSelector((state) => onSelectUsersAppointments(state, adviser?.id));
    const courses = useSelector(selectCourses);
    const courseIds = useSelector(selectAllCourseIds);
    const courseModules = useSelector(selectCourseModules);
    const currentCourse = useSelector(selectCurrentCourse);
    const currentModule = useSelector(selectCurrentModule);
    const locations = useSelector(selectLocationIdsAndNames);
    const appointmentTypes = useSelector(selectAppointmentTypes);
    const {
        participantsByLoggedInUserServiceIds,
        participantsByLoggedInUserServiceIdsMetaData: ptMetaData
    } = useSelector((state) => state.entities.participantService);
    const openModuleScheduler = useSelector(
        (state) => state.entities.courseService.openModuleScheduler
    );

    // CONFIG FNS
    const configEvents = () => {
        const businessHours = getBusinessHours();
        let modules = courseModules;
        if (currentModule?.id) {
            const found = courseModules?.find((el) => el.id === currentModule?.id);
            if (!found) modules = [...courseModules, currentModule];
        }
        setAllModules(modules);
        let entries;
        if (!modules?.length) entries = [{}];
        else
            entries = modules?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, courses, location);
                const { color, textColor } = mapColors(el.id, currentModule?.id);
                const editable = el.id === currentModule?.id;
                return {
                    id: el.id,
                    eventType: 'courseModules',
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    content,
                    location,
                    date,
                    start,
                    end,
                    color,
                    textColor,
                    editable,
                    constraint: businessHours
                };
            });
        setEvents(entries);
        const cur = entries?.find((el) => el.id === currentModule?.id);
        if (cur) setCurrentEvent(cur);
    };

    const configBackgroundEvents = () => {
        let events;
        if (usersAppointments?.length < 1) events = [];
        else
            events = usersAppointments?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, participant, slots, filledSlots } =
                    configParticipantEventContent(el, appointmentTypes, participants);
                return {
                    eventType: 'user',
                    className: 'non-interactive-events',
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    slots,
                    filledSlots,
                    location,
                    participant,
                    appointmentType,
                    editable: false,
                    date,
                    start,
                    end
                };
            });
        setUserEvents(events);
    };

    const fetchAdviserData = () => {
        if (adviser?.id) {
            if (!allAppointments[adviser.id]) {
                dispatch(loadUserAppointments(adviser.id));
                dispatch(loadUserAppointmentSlots(adviser.id));
            }
            dispatch(loadCourseModulesByUserId(adviser.id));
        }
    };

    // HELPER FNS
    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS
    useEffect(() => {
        if (!loggedInUser?.id || !currentModule?.id) return;
        let owner;
        if (currentModule?.userId) {
            owner =
                usersById[currentModule.userId] ||
                users.find((el) => el.id === currentModule.userId);
            if (!owner) {
                dispatch(loadUserById(currentModule.userId));
                return;
            }
        } else {
            owner = loggedInUser;
        }
        setOwner(owner);
    }, [loggedInUser.id, currentModule.id]);

    useEffect(() => {
        if (currentModule?.userId && usersById[currentModule.userId]) {
            const owner = usersById[currentModule.userId];
            setOwner(owner);
        }
    }, [usersById]);

    useEffect(() => {
        if (participantsByLoggedInUserServiceIds?.length < 1) return;
        else if (!ptMetaData.last) {
            dispatch(
                searchParticipantsByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    ptMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsByLoggedInUserServiceIds);
    }, [participantsByLoggedInUserServiceIds, ptMetaData]);

    useEffect(() => {
        setUsers(paginatedUsers.flat());
    }, [paginatedUsers]);

    useEffect(() => {
        if (!owner.id) return;
        if (!adviser.id) {
            setAdviser(owner);
            setAdvisersArray([owner]);
        }
        fetchAdviserData(owner.id, dispatch);
    }, [owner]);

    useEffect(() => {
        if (!loggedInUser.id) return;
        fetchLoggedInUserData(loggedInUser, dispatch);
        dispatch(
            searchParticipantsByLoggedInUserServiceIds(loggedInUser.serviceIds, 0, PT_LOAD_SIZE)
        );
        setNewEvent({});
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!courseModules) return;
        courseIds.length > 0 && dispatch(searchCoursesByCourseIds(courseIds));
    }, [courseModules, courseIds]);

    useEffect(() => {
        if (!currentModule?.id) setCurrentEvent({});
        if (currentModule?.id && !currentModule?.date) setIsSlotSelectable(true);
        configEvents();
    }, [currentModule, adviser, courseModules, courses, locations]);

    useEffect(() => {
        if (appointmentTypes.length > 0) configBackgroundEvents();
    }, [usersAppointments, locations, participants, appointmentTypes]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users;
        if (adviser.id && !updatedAdvisers.some((el) => el.id === adviser.id)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === adviser.id);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (changedModule) {
            onOpenModuleSchedulerPanel();
            setChangedModule(false);
        }
    }, [changedModule]);

    useEffect(() => {
        fetchAdviserData();
    }, [adviser.id]);

    // EVENT HANDLERS
    const onEditTimes = (time, update) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            const updatedModule = {
                ...currentModule,
                date,
                startTime,
                endTime,
                userId: adviser.id
            };
            dispatch(
                updateCourseModule(
                    updatedModule,
                    `Module details for ${currentModule.title} have been updated`
                )
            );
        }
        if (!update) {
            dispatch(setCurrentModule({}));
            configEvents();
        }
    };

    let count = 0;
    const onOpenModuleSchedulerPanel = () => {
        if (isSlotSelectable) {
            dispatch(setOpenModuleSchedulerPanel(true));
        }
        if (!isSlotSelectable) {
            count++;
            setTimeout(() => (count = 0), 300);
            if (count > 1) {
                dispatch(setOpenModuleSchedulerPanel(true));
                setNewEvent({});
                setPending({});
            }
        }
    };

    const onEventSelect = (id) => {
        setIsSlotSelectable(false);
        if (id !== currentModule?.id) {
            const selectedModule = allModules.find((el) => el.id === id);
            selectedModule && dispatch(setCurrentModule(selectedModule));
            setChangedModule(true);
        }
        if (id === currentEvent.id) onOpenModuleSchedulerPanel();
    };

    const onSlotSelect = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        if (Object.keys(pending).length) setNewEvent({ ...pending, date, startTime, endTime });
        else setNewEvent({ ...currentModule, date, startTime, endTime });
        onOpenModuleSchedulerPanel();
    };

    const onUpdateAdviser = (chosenId) => {
        if (!chosenId) {
            if (adviser?.id !== owner?.id) {
                const adviser = owner?.id ? owner : loggedInUser;
                setAdviser(adviser);
            }
        } else if (chosenId && chosenId !== adviser.id) {
            const chosenAdviser = advisers.find((el) => el.id === chosenId);
            setAdviser(chosenAdviser);
        }
    };

    const onReturn = () => {
        const { prevPage } = location?.state || '';
        if (prevPage === 'editCourse')
            navigate('/edit_course', { state: { row: currentCourse, mode: 'edit' } });
        else navigate('/course_management');
    };

    const onPending = (unScheduledModule) => setPending(unScheduledModule);

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    // RENDER

    return (
        <div className={classes.container}>
            <FormHeader
                text={
                    'Select your Module Date - click into any available slot and double-click or drag to edit'
                }></FormHeader>
            <div className="calendar-wrapper">
                <div className="toolbar-search">
                    <SingleSelect
                        id={'adviserId'}
                        label={'Adviser/Coach'}
                        placeholder="Select Adviser/Coach..."
                        menuItems={advisers}
                        selectedId={adviser.id || ''}
                        selected={
                            adviser.id ? advisers?.find((el) => el.id === adviser.id) || {} : {}
                        }
                        onChange={onUpdateAdviser}
                        onLoadMoreItems={onLoadMoreAdvisers}
                        moreItemsToLoad={!totalUsers || users.length < totalUsers}
                    />
                </div>

                <Calendar
                    /* eslint-disable-next-line no-constant-binary-expression */
                    events={[...events, ...userEvents] || []}
                    currentEvent={currentEvent}
                    onEditTimes={onEditTimes}
                    selectSlot={isSlotSelectable ? onSlotSelect : undefined}
                    selectEvent={onEventSelect}
                    allowSlotSelect={true}
                    onReturn={onReturn}
                    returnButton={true}
                    initialView="timeGridWeek"
                />
                {openModuleScheduler && (
                    <ModuleScheduler
                        newEvent={newEvent}
                        currentModule={currentModule}
                        currentLocation={currentEvent.location}
                        onPending={onPending}
                        adviser={adviser}
                    />
                )}
            </div>
        </div>
    );
};

export default CourseCalendar;
