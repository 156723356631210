import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '@mui/icons-material/Error';
import { FormLabel } from '@mui/material';

import { capitalize } from '../utils/capitalize';

import form from '../commonStyles/formStyles.module.css';
/**
 * A component for showing an icon and an error message.
 * Currently, the icon is fixed to the icon specified in Figma for error text.
 */
const IconError = (props) => {
    return (
        <p className={form.inputError}>
            {!props.text ? null : (
                <FormLabel error={true}>
                    <ErrorIcon fontSize="medium" /> <sup>{capitalize(props.text?.message)}</sup>
                </FormLabel>
            )}
        </p>
    );
};

export default IconError;

IconError.propTypes = {
    text: PropTypes.object
};
