import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createSignPosting, updateSignPosting } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import { checkDate, initialErrorState, validate } from './validateSignPosting';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateSignPosting = ({ onClose, roles, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        date: new Date().toISOString().slice(0, 10),
        organisationId: '',
        typeId: '',
        notes: '',
        participantId: '',
        organisation: '',
        type: ''
    };
    const CHARACTER_LIMIT = 1000;
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [arrayTypes, setArrayTypes] = useState([]);
    const [isClearOrganisation, setIsClearOrganisation] = useState('0');
    const [isClearType, setIsClearType] = useState('1');
    const msg = `Participant signposting has been ${formType === 'create' ? 'added' : 'updated'}`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const signPostingOrganisations = useSelector(
        (state) => state.entities.directusService.signPostingOrganisations
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setArrayTypes([]);
        setErrors(initialErrorState);
        setIsClearOrganisation(Math.random());
        setIsClearType(Math.random());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const configTypeArray = (organisationId) => {
        if (!organisationId) return;
        setArrayTypes(
            signPostingOrganisations
                .find((el) => el.id === organisationId)
                ?.signposting_type.map((el) => el.signposting_type_id)
        );
    };

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length < 1 || formType === 'create') return;
        row?.organisationId && configTypeArray(row.organisationId);
        const formattedRow = {
            ...row,
            date: row.date.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        if (arrayTypes?.length === 1)
            setNewEntry((prev) => ({
                ...prev,
                typeId: arrayTypes[0].id,
                type: arrayTypes[0].name
            }));
    }, [arrayTypes]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onDateChange = (date) => {
        clearError('date');
        setNewEntry((prev) => ({ ...prev, date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        // eslint-disable-next-line
        const { type, organisation, ...rest } = newEntry;
        const payload = { ...rest, participantId: currentParticipant?.id };
        formType === 'create'
            ? dispatch(createSignPosting(payload, msg))
            : dispatch(updateSignPosting(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>{formType === 'create' ? 'Add New Signposting' : 'Edit Signposting'}</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.date}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.date}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Signposted Organisation'}
                            id={'organisation'}
                            key={isClearOrganisation}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={signPostingOrganisations || []}
                            chosenName={newEntry.organisation}
                            chosenId={newEntry.organisationId}
                            onChange={(chosenId) => {
                                clearError('organisationId');
                                configTypeArray(chosenId);
                                !chosenId && setArrayTypes([]);
                                setNewEntry((prev) => ({
                                    ...prev,
                                    organisationId: chosenId,
                                    type: !chosenId ? '' : prev.type,
                                    typeId: !chosenId ? '' : prev.typeId
                                }));
                            }}></DDLOptionPicker>
                        {errors.organisationId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.organisationId} />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Signposting Type'}
                            id={'type'}
                            key={isClearType}
                            disabled={!hasRole(acceptedRoles, roles) || !newEntry.organisationId}
                            mandatory={true}
                            menuItems={arrayTypes || []}
                            chosenName={newEntry.type}
                            chosenId={newEntry.typeId}
                            onChange={(chosenId) => {
                                clearError('typeId');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    typeId: chosenId
                                }));
                            }}></DDLOptionPicker>
                        {errors.typeId.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.typeId} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className="notesFieldSet">
                            <label htmlFor="notesCurrent">Notes</label>
                            <textarea
                                className="notesField"
                                id="notesCurrent"
                                disabled={!hasRole(acceptedRoles, roles)}
                                maxLength={CHARACTER_LIMIT}
                                rows={5}
                                placeholder={'Notes'}
                                value={newEntry.notes || ''}
                                onChange={(e) =>
                                    setNewEntry((prev) => ({ ...prev, notes: e.target.value }))
                                }
                            />
                            <div>
                                {newEntry.notes?.length}/{CHARACTER_LIMIT}
                            </div>
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={formType === 'create' ? 'Save' : 'Update'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateSignPosting;

CreateSignPosting.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};
