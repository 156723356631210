import { createAction } from '@reduxjs/toolkit';

export const apiCallBegan = createAction('api/callBegan');
export const apiCallDirectusBegan = createAction('api/callDirectusBegan');
export const apiCallSuccess = createAction('api/callSuccess');
export const apiCallFailed = createAction('api/callFailed');
export const apiUpload = createAction('api/upload');
export const apiDownload = createAction('api/download');
export const apiSeqDelete = createAction('api/seqDelete');
export const apiSeqAdd = createAction('api/seqAdd');
