import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectActionPlanStatus } from '../../../../store/dataSelectors';
import { selectParticipantActionPlans } from '../../../../store/participantSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const ActionPlanTable = ({ onIsLoaded }) => {
    const actionPlanRef = useRef(null);
    const [rows, setRows] = useState([]);
    const barriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const actionPlans = useSelector(selectParticipantActionPlans);
    const actionPlanStatuses = useSelector(selectActionPlanStatus);

    useEffect(() => {
        if (barrierNameDetails?.length < 1 || actionPlanStatuses?.length < 1) return;
        if (actionPlans?.length < 1) {
            onIsLoaded('actionPlan');
            return;
        }

        const data = actionPlans.map((el) => ({
            id: el.id,
            actionTitle: el?.actionTitle,
            barrierName:
                getNameFromId(
                    barrierNameDetails,
                    barriers.find((entry) => entry.id === el.barrierId)?.barrierId
                ) || ' - ',
            startDate: el?.actionStartDate ? reverseFormatDate(el.actionStartDate) : ' - ',
            dueDate: el?.actionDueDate ? reverseFormatDate(el.actionDueDate) : ' - ',
            outcomeDate: el?.actionOutcomeDate ? reverseFormatDate(el.actionOutcomeDate) : ' - ',
            outcome: getNameFromId(actionPlanStatuses, el.outcomeId) || ' - ',
            action: el?.action,
            comments: el?.comments
        }));
        setRows(data);
    }, [actionPlans, barrierNameDetails, actionPlanStatuses]);

    useEffect(() => {
        actionPlanRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('actionPlan');
    }, [actionPlanRef.current]);

    return (
        <div>
            <h3>Action Plan</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>Action Name</span>
                    <span>Barrier</span>
                    <span>Start Date</span>
                    <span>Due Date</span>
                    <span>Outcome Date</span>
                    <span>Outcome</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (actionPlanRef.current = el)}
                                key={el.id}
                                id={`${el.id}--${i}`}
                                className={`${i % 2 === 0 ? classes.rowPlain : classes.rowFilled} ${
                                    classes.borderedSet
                                }`}>
                                <div className={classes.pdfTableRow}>
                                    <span>{el.actionTitle}</span>
                                    <span>{el.barrierName}</span>
                                    <span>{el.startDate}</span>
                                    <span
                                        className={`${
                                            el.dueDate === ' - ' ? classes.alignCenter : ''
                                        }`}>
                                        {el.dueDate}
                                    </span>
                                    <span
                                        className={`${
                                            el.outcomeDate === ' - ' ? classes.alignCenter : ''
                                        }`}>
                                        {el.outcomeDate}
                                    </span>
                                    <span
                                        className={`${
                                            el.outcome === ' - ' ? classes.alignCenter : ''
                                        }`}>
                                        {el.outcome}
                                    </span>
                                </div>
                                <div>
                                    <div className={classes.textBoxSet}>
                                        <div className={classes.textBoxHeader}>Action</div>
                                        <div className={classes.textBox}>{el?.action}</div>
                                    </div>
                                    <div className={classes.textBoxSet}>
                                        <div className={classes.textBoxHeader}>Comments</div>
                                        {el.comments ? (
                                            <div className={classes.textBox}>{el?.comments}</div>
                                        ) : (
                                            ' n/a '
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActionPlanTable;

ActionPlanTable.propTypes = {
    onIsLoaded: PropTypes.func
};
