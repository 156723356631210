import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { SCREEN_SETTINGS } from '../../../themes/theme';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * No / Yes Radio button picker
 *
 * Default is radioButtonPick
 * @param id selector id
 * @param label selector label
 * @param onChange  Callback function to tell parent of change. This is returned as
 * a boolean.  true = 'Yes' false = 'No'
 * @param text
 * @param disabled Default = false
 * @param radioButtonPick boolean Sets initial radio selection.
 *     false (default) means 'No' is selected.
 *     true = 'Yes' selected
 *
 */

const NoYesRadioPicker = ({
    id,
    onChange,
    text,
    label,
    disabled = false,
    radioButtonPick = false
}) => {
    const [radioSelection, setRadioSelection] = useState(radioButtonPick);

    const handleRadioClick = (e) => {
        if (!disabled && e.target.value !== undefined) {
            const value = e.target.value === 'true';
            setRadioSelection(value);
            onChange(value);
        }
    };

    // Set radio selection from options on first entry
    useEffect(() => {
        setRadioSelection(radioButtonPick);
    }, [radioButtonPick]);

    return (
        <div className={form.yesNoRadioButtons}>
            <Typography variant="h6" data-testid={'label_text'}>
                {text || ''}{' '}
            </Typography>
            <label id={id} htmlFor={id} className={form.formLabel}>
                {label}
            </label>

            <RadioGroup
                row
                aria-labelledby={id}
                name="no-yes-radio-buttons-group"
                value={radioSelection}
                onClick={handleRadioClick}>
                <FormControlLabel
                    data-testid={'radio_button_no'}
                    value={false}
                    control={<Radio disabled={disabled} />}
                    label="No"
                />
                <FormControlLabel
                    data-testid={'radio_button_yes'}
                    value={true}
                    control={<Radio disabled={disabled} />}
                    label="Yes"
                />
            </RadioGroup>
            <Box sx={{ height: SCREEN_SETTINGS.gap.large }} />
        </div>
    );
};

NoYesRadioPicker.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    radioButtonPick: PropTypes.bool
};

export default NoYesRadioPicker;
