import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadNonSeriousEventTypes, loadSeriousEventTypes } from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import {
    deleteHealthIncident,
    loadHealthIncidents,
    onDeleteHealthIncident
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CreateHealthIncident from './CreateHealthIncident';
import HealthIncidentTable from './HealthIncidentTable';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const HealthIncident = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Health Incident deleted`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const seriousEventTypes = useSelector(
        (state) => state.entities.directusService.seriousEventTypes
    );
    const nonSeriousEventTypes = useSelector(
        (state) => state.entities.directusService.nonSeriousEventTypes
    );
    //const healthIncidents = useSelector((state) => state.entities.directusService.healthIncidents);
    const healthIncidents = useSelector(
        (state) => state.entities.participantService.healthIncidents
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (seriousEventTypes?.length < 1) dispatch(loadSeriousEventTypes());
        if (nonSeriousEventTypes?.length < 1) dispatch(loadNonSeriousEventTypes());
    }, []);

    useEffect(() => {
        currentParticipant?.id && dispatch(loadHealthIncidents(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (
            healthIncidents?.length < 1 ||
            seriousEventTypes?.length < 1 ||
            nonSeriousEventTypes?.length < 1
        )
            return;
        const rows = healthIncidents
            .filter((el) => !el.inactive)
            .map((el) => {
                const arr = el.seriousTypeEvent ? seriousEventTypes : nonSeriousEventTypes;
                return {
                    ...el,
                    eventType: arr.find((entry) => entry.id === el.eventTypeId)?.name || '',
                    dateReportedToNhse: el.dateReportedToNhse ?? 'z',
                    programmeDeIntensifiedDate: el.programmeDeIntensifiedDate ?? 'z',
                    programmeReIntensifiedDate: el.programmeReIntensifiedDate ?? 'z'
                };
            });
        setRows(rows);
    }, [healthIncidents, seriousEventTypes, nonSeriousEventTypes]);

    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeleteHealthIncident(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setShowForm(true);
        setSelectedRow({});
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteHealthIncident(row.id, deleteMsg));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Health Incident</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No health incidents found" />
                ) : (
                    <HealthIncidentTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Health Incident
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreateHealthIncident
                            onClose={() => setShowForm(false)}
                            roles={roles}
                            formType={formType}
                            row={selectedRow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HealthIncident;
