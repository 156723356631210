import { deepEqual } from './objectUtils';

// returns true if same
export const compareUnorderedArrays = (a, b) => {
    if (a.length !== b.length) return false;
    else {
        const bCopy = [...b];
        for (const item of a) {
            const index = bCopy.findIndex((bItem) => deepEqual(item, bItem));
            if (index > -1) bCopy.splice(index, 1);
            else break;
        }
        return bCopy.length === 0;
    }
};

// returns true if same
export const compareOrderedArraysOfObjects = (a, b) => {
    if (a.length !== b.length) return false;
    else {
        a.forEach((el, i) => {
            if (!deepEqual(el, b[i])) {
                return false;
            }
        });
        return true;
    }
};

// returns new array where items in A and B are not the same
export const getUniqueElements = (a, b) => {
    return a.filter((x) => !b.includes(x)).concat(b.filter((x) => !a.includes(x)));
};

// returns new array where items in A are not in B
export const arrayDif = (a, b) => {
    return a.filter((x) => !b.includes(x));
};
