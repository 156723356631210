import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../icons/OnEditIcon';
import { SUPERUSER } from '../../utils/userRoles';

import classes from '../../commonStyles/tables.module.css';

const EligibilityGroupTableRow = ({ row, roles, onEdit }) => {
    const acceptedRoles = [SUPERUSER];

    return (
        <TableRow hover className={`${classes.basicRowStripes}`}>
            <TableCell>{row.name}</TableCell>
            <TableCell> {row.contractName} </TableCell>
            <TableCell align="right">
                <OnEditIcon
                    onEdit={() => onEdit(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                />
            </TableCell>
        </TableRow>
    );
};

export default EligibilityGroupTableRow;

EligibilityGroupTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func
};
