import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';

import { POLARIS_DARKER_GREY, POLARIS_WHITE } from '../../../../themes/theme';
import LabelledTextField from '../../editors/LabelledTextField';
import DDLOptionPicker from '../../pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../pickers/NoYesRadioPicker';

const CHARACTER_LIMIT = 250;
const ACTION_DESCRIPTION_LIMIT = 750;

/**
 * A dialog to edit module template details
 * @param cardData - Data for this editor
 * This has values which match the additional_contact_card table in the participant service
 * @param disabled
 * @param sendChange Callback function to tell parent that data has changed.
 * @param barrierNameDetails This has an array of barriers that are passed in from the viewer to avoid
 * having to fetch again.
 *
 * @returns {JSX.Element}
 */
const ModuleTemplateEditor = ({ cardData, disabled = false, sendChange, barrierNameDetails }) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const [open, setOpen] = useState(false);

    // First time on card and blank so must be new.
    // Open dialog to force user to enter data
    useEffect(() => {
        if (!cardData.name && !cardData.description) {
            if (disabled === false) {
                setOpen(true);
            }
        }
    }, []);

    const handleClickOpen = () => {
        if (disabled === false) {
            // Select relationship control ?
            setOpen(true);
        }
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit be clicking outside dialog.
    };

    /**
     *  Handle the cancel button
     */
    const handleCancel = () => {
        // Reset info to cardData
        setCardData();
        clearErrors();
        reset('moduleName');
        reset('moduleDescription');
        reset('action');
        reset('smartTargetAction');
        reset({
            barrierNameId: setIsClearSelectedSmartTargetBarrierName(Math.random())
        });
        setOpen(false);
    };

    // Local state
    const [newEntry, setNewEntry] = useState({
        id: null,
        name: null,
        description: null,
        smartTarget: {
            name: null,
            barrierNameId: null,
            action: null
        },
        smartTargetFlag: false
    });

    let [isClearSelectedSmartTargetBarrierName, setIsClearSelectedSmartTargetBarrierName] =
        useState('0');

    const setCardData = () => {
        setNewEntry((prev) => ({
            ...prev,
            id: cardData.id,
            name: cardData.name,
            description: cardData.description
        }));
        if (cardData.name) {
            setModuleName(cardData.name);
            setModuleDescription(cardData.description);
            setValue('moduleName', cardData.name, {
                shouldValidate: true
            });
            setValue('moduleDescription', cardData.description, {
                shouldValidate: true
            });
        } else if (!cardData.name) {
            setModuleName('');
            setModuleDescription('');
            setSmartTarget(false);
            setNewEntry((prev) => ({ ...prev, smartTargetFlag: false }));
        }
        if (cardData.smartTarget) {
            if (cardData.smartTarget.name) {
                setSmartTarget(true);
                setSmartTargetName(cardData.smartTarget.name);
                setSmartTargetAction(cardData.smartTarget.action);
                setNewEntry((prev) => ({
                    ...prev,
                    smartTargetFlag: true,
                    smartTarget: {
                        ...newEntry.smartTarget,
                        name: cardData.smartTarget.name,
                        barrierNameId: cardData.smartTarget.barrierNameId,
                        action: cardData.smartTarget.action
                    }
                }));
                setValue('action', cardData.smartTarget.name, {
                    shouldValidate: true
                });
                setValue('barrierNameId', cardData.smartTarget.barrierNameId, {
                    shouldValidate: true
                });
                setValue('smartTargetAction', cardData.smartTarget.action, {
                    shouldValidate: true
                });
            }
        } else {
            setSmartTarget(false);
        }
    };

    const handleModuleNameChange = (e) => {
        setModuleName(e.target.value);
        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
    };

    const handleModuleDescriptionChange = (e) => {
        setModuleDescription(e.target.value);
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };

    const handleSmartTargetFlagChange = (option) => {
        setSmartTarget(option);
        setValue('smartTarget', option, {
            shouldValidate: true
        });

        setNewEntry((prev) => ({ ...prev, smartTargetFlag: option }));
        if (!option) {
            clearErrors('action');
            clearErrors('barrierNameId');
            clearErrors('smartTargetAction');
            setNewEntry((prev) => ({
                ...prev,
                smartTarget: {
                    ...newEntry.smartTarget,
                    name: null,
                    barrierNameId: null,
                    action: null
                }
            }));
            setSmartTargetName('');
            setSmartTargetAction('');
        }
    };

    const handleSmartTargetNameChange = (e) => {
        setSmartTargetName(e.target.value);
        setNewEntry((prev) => ({
            ...prev,
            smartTarget: {
                ...newEntry.smartTarget,
                name: e.target.value
            }
        }));
    };

    const handleSmartTargetBarrierNameChange = (chosenId) => {
        if (chosenId !== '') {
            setNewEntry((prev) => ({
                ...prev,
                smartTarget: {
                    ...newEntry.smartTarget,
                    barrierNameId: chosenId
                }
            }));
        }
    };

    const handleSmartTargetActionChange = (e) => {
        setSmartTargetAction(e.target.value);
        setNewEntry((prev) => ({
            ...prev,
            smartTarget: {
                ...newEntry.smartTarget,
                action: e.target.value
            }
        }));
    };

    const getName = (id) => {
        if (!id) return '';
        const match = barrierNameDetails.find((item) => item.id === id);
        return match?.name || '';
    };

    // MODULE
    const [moduleName, setModuleName] = useState('');
    const [moduleDescription, setModuleDescription] = useState('');

    // SMART TARGET
    const [smartTarget, setSmartTarget] = useState(false);
    const [smartTargetName, setSmartTargetName] = useState('');
    const [smartTargetAction, setSmartTargetAction] = useState('');
    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;

    // Set info from cardData
    useEffect(() => {
        setCardData();
    }, []);

    const onSubmit = () => {
        clearErrors();
        sendChange(newEntry);
        setOpen(false);
    };

    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <EditIcon
                onClick={handleClickOpen}
                sx={{ color: iconColor }}
                data-testid="editor-icon"></EditIcon>

            <Dialog open={open} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Module Template</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        {/* This is needed for smart target validations */}
                        <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                            <input
                                id="smartTarget"
                                name="smartTarget"
                                value={newEntry.smartTargetFlag || false}
                                {...register('smartTarget')}></input>
                        </div>
                        <LabelledTextField
                            label={'Module Name'}
                            id={'moduleName'}
                            mandatory={true}
                            value={moduleName || ''}
                            placeholder={'Enter module name'}
                            error={errors.moduleName}
                            {...register('moduleName')}
                            onChange={handleModuleNameChange}
                        />
                        <LabelledTextField
                            label={'Module Description'}
                            id={'moduleDescription'}
                            mandatory={true}
                            multiline
                            rows={5}
                            value={moduleDescription}
                            placeholder={'Enter module description'}
                            counter={'true'}
                            helperText={`${moduleDescription.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            error={errors.moduleDescription}
                            {...register('moduleDescription')}
                            onChange={handleModuleDescriptionChange}
                        />
                        <NoYesRadioPicker
                            text={'Add Smart Target?'}
                            radioButtonPick={newEntry.smartTargetFlag || false}
                            onChange={handleSmartTargetFlagChange}
                        />
                        <div hidden={!smartTarget}>
                            <Typography variant="h5">Smart Target Details:</Typography>
                            <br />
                            <LabelledTextField
                                label={'What shall we call this action?'}
                                id={'action'}
                                mandatory={smartTarget && true}
                                value={smartTargetName}
                                placeholder={'Enter the name of the action'}
                                error={errors.action}
                                {...register('action')}
                                onChange={handleSmartTargetNameChange}
                            />
                            <DDLOptionPicker
                                label={'Which barrier name aligns to this smart target?'}
                                id={'barrierNameId'}
                                key={isClearSelectedSmartTargetBarrierName}
                                mandatory={smartTarget && true}
                                menuItems={barrierNameDetails}
                                chosenName={getName(newEntry.smartTarget.barrierNameId)}
                                chosenId={newEntry.smartTarget.barrierNameId}
                                error={errors.barrierNameId}
                                {...register('barrierNameId')}
                                onChange={(e) => {
                                    if (e !== null && e !== undefined) {
                                        handleSmartTargetBarrierNameChange(e);
                                    }
                                }}
                            />
                            <div>Write an action below using SMART</div>
                            <br />
                            <div>
                                <strong>S: </strong>What is the action?
                            </div>
                            <br />
                            <div>
                                <strong>M: </strong>What is the measure of success?
                            </div>
                            <br />
                            <div>
                                <strong>A: </strong>Is the action achievable in complexity and time
                                given?
                            </div>
                            <br />
                            <div>
                                <strong>R: </strong>Is the action relevant to the barrier it aligns
                                to?
                            </div>
                            <br />
                            <div>
                                <strong>T: </strong>When is the action due?
                            </div>
                            <br />
                            <LabelledTextField
                                label={'Action'}
                                id={'smartTargetAction'}
                                mandatory={smartTarget && true}
                                multiline
                                rows={5}
                                value={smartTargetAction}
                                placeholder={'Enter action description'}
                                counter={'true'}
                                helperText={
                                    `${smartTargetAction.length}` + '/' + ACTION_DESCRIPTION_LIMIT
                                }
                                inputProps={{
                                    maxLength: ACTION_DESCRIPTION_LIMIT
                                }}
                                error={errors.smartTargetAction}
                                {...register('smartTargetAction')}
                                onChange={handleSmartTargetActionChange}
                            />
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Update
                        </Button>
                        <Button
                            onClick={handleCancel}
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    moduleName: Yup.string()
        .trim()
        .min(15, 'Module name must be at least fifteen characters.')
        .max(100, 'Module name must be 100 characters or less.'),
    moduleDescription: Yup.string()
        .trim()
        .min(50, 'Module description must be at least fifty characters.'),
    smartTarget: Yup.boolean(),
    action: Yup.string().when('smartTarget', {
        is: (smartTarget) => smartTarget === true,
        then: () =>
            Yup.string()
                .trim()
                .min(15, 'Action must be at least fifteen characters.')
                .max(100, 'Action must be 100 characters or less.')
    }),
    barrierNameId: Yup.string().when('smartTarget', {
        is: (smartTarget) => smartTarget === true,
        then: () => Yup.string().required('Please select a barrier name.')
    }),
    smartTargetAction: Yup.string().when('smartTarget', {
        is: (smartTarget) => smartTarget === true,
        then: () =>
            Yup.string().trim().min(50, 'Action description must be at least fifty characters.')
    })
});

ModuleTemplateEditor.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendChange: PropTypes.func.isRequired,
    barrierNameDetails: PropTypes.array.isRequired
};
export default ModuleTemplateEditor;
