import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import IconError from '../IconError';

import local from './formElementStyles/button.module.css';

const Button = ({
    id = '',
    type = 'submit',
    testId = 'testIdSubmitButton',
    disabled = false,
    customClass = '',
    onClick,
    content,
    error = {},
    clearError,
    icon = false
}) => {
    useEffect(() => {
        if (error?.error) {
            setTimeout(() => clearError(), 3000);
        }
    }, [error?.error]);

    return (
        <>
            <button
                id={id}
                type={type}
                data-testid={testId}
                role="button"
                disabled={disabled}
                className={`${local.button} ${local[customClass]}`}
                onClick={onClick}>
                <span className={`${icon ? `${local.withIcon}` : ''}`}>
                    {content}
                    {icon && <AddCircleOutlineIcon />}
                </span>
            </button>
            {error?.error && (
                <div className={local.buttonError}>
                    <IconError text={error} />
                </div>
            )}
        </>
    );
};

export default Button;

Button.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    testId: PropTypes.string,
    disabled: PropTypes.bool,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    error: PropTypes.object,
    clearError: PropTypes.any,
    icon: PropTypes.bool
};
