import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';

import IconError from '../../IconError';

import './pickerStyles/multiSelect.css';
// eslint-disable-next-line react/display-name
const MultiSelect = forwardRef(
    (
        {
            id,
            disabled = false,
            removeable = true,
            mandatory = false,
            error,
            label,
            placeholder,
            heading,
            menuItems,
            preSelectedIds,
            preSelects,
            onChange,
            compareUpdatedItems = false,
            selectAllDisabled = false
        },
        ref
    ) => {
        const toggleRef = useRef(null);
        const listRef = useRef(null);
        const pillListRef = useRef(null);
        const [listItems, setListItems] = useState([]);
        const [rows, setRows] = useState([]);
        const [selected, setSelected] = useState([]);
        const [selectedIds, setSelectedIds] = useState([]);
        const [isListOpen, setIsListOpen] = useState(false);
        const [isCountOpen, setIsCountOpen] = useState(false);
        const [areAllSelected, setAreAllSelected] = useState(false);
        const [searchTerm, setSearchTerm] = useState('');

        // HELPER FNS

        const isSelected = (id) => selected.find((el) => el.id === id);

        const onCheckAll = () => {
            if (menuItems.length > 0 && preSelects.length === menuItems.length)
                setAreAllSelected(true);
            else setAreAllSelected(false);
        };

        // USE EFFECTS

        useEffect(() => {
            setSelectedIds(preSelectedIds);
            setSelected(preSelects);
            onCheckAll();
        }, [preSelectedIds, preSelects]);

        useEffect(() => {
            const items = menuItems
                .slice()
                .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
            setListItems(items);
            setRows(items);
            onCheckAll();
            if (compareUpdatedItems && menuItems.length < listItems.length) {
                const menuItemIds = menuItems.map((el) => el.id);
                const filterSelected = selected.filter((el) => menuItemIds.includes(el.id));
                setSelected(filterSelected);
                setSelectedIds(filterSelected.map((el) => el.id));
            }
        }, [menuItems]);

        useEffect(() => {
            onChange(selectedIds);
        }, [selectedIds]);

        useEffect(() => {
            if (listItems.length < 1) return;
            const filtered = searchTerm
                ? listItems.filter((el) => el.name.toLowerCase().includes(searchTerm.toLowerCase()))
                : listItems;
            setRows(filtered);
            onOpenList();
        }, [searchTerm]);

        useEffect(() => {
            if (isListOpen) {
                document.addEventListener('click', onCloseList);
            }
            return () => document.removeEventListener('click', onCloseList);
        }, [isListOpen]);

        // EVENT HANDLERS

        const onSelect = (_, item) => {
            if (selected.find((el) => el.id === item.id)) {
                setSelected(selected.filter((el) => el.id !== item.id));
                setSelectedIds(selectedIds.filter((el) => el !== item.id));
                areAllSelected && setAreAllSelected(false);
            } else {
                if (selected.length === listItems.length - 1) setAreAllSelected(true);
                setSelected((prev) => [...prev, item]);
                setSelectedIds((prev) => [...prev, item.id]);
                setSearchTerm('');
            }
        };

        const onRemovePill = (item) => {
            if (disabled || !removeable) return;
            setSelected(selected.filter((el) => el.id !== item.id));
            setSelectedIds(selectedIds.filter((el) => el !== item.id));
            areAllSelected && setAreAllSelected(false);
        };

        const onSelectAll = () => {
            if (selected.length === menuItems.length) return;
            setSelected(rows);
            setSelectedIds(rows.map((el) => el.id));
            setAreAllSelected(true);
        };

        const onDeselectAll = () => {
            if (!removeable) return;
            setSelected([]);
            setSelectedIds([]);
            setAreAllSelected(false);
        };

        const onOpenList = () => {
            if (disabled) return;
            if (toggleRef.current && listRef.current) {
                if (!toggleRef.current.classList.contains('toggleOpen')) {
                    toggleRef.current.classList.add('toggleOpen');
                    setIsListOpen(true);
                }
            }
        };

        const onCloseList = (e) => {
            const selectorEl = document.getElementById(id);
            const listEl = document.querySelector('.showList');
            if (
                e &&
                typeof e.composedPath === 'function' &&
                !e.composedPath().includes(selectorEl) &&
                !e.composedPath().includes(listEl)
            ) {
                if (toggleRef.current && listRef.current) {
                    if (toggleRef.current.classList.contains('toggleOpen')) {
                        toggleRef.current.classList.remove('toggleOpen');
                        setIsListOpen(false);
                    }
                }
            }
        };

        const onToggle = () => {
            if (disabled) return;
            if (toggleRef.current && listRef.current) {
                if (toggleRef.current.classList.contains('toggleOpen')) {
                    toggleRef.current.classList.remove('toggleOpen');
                    setIsListOpen(false);
                } else {
                    toggleRef.current.classList.add('toggleOpen');
                    setIsListOpen(true);
                }
            }
        };

        return (
            <div className="ddlSelector">
                <h3>
                    {heading} {mandatory && <span className="mandatory">*</span>}
                </h3>
                <label id={`label-${id}`} htmlFor={id} className="selectorLabel">
                    {label} {mandatory && <sup>*</sup>}
                </label>
                <div
                    className={`selector ${disabled ? 'selectorDisabled' : 'selectorActive'} ${
                        selectAllDisabled ? 'noAllSelectorInput' : ''
                    }`}
                    id={id}
                    aria-labelledby={`label-${id}`}>
                    <span
                        className={`pillList scroller ${isCountOpen ? 'pillListExpand' : ''}`}
                        ref={pillListRef}>
                        {selected.map((el) => (
                            <span
                                className={`selectedPill ${disabled ? 'pillDisabled' : ''}`}
                                key={el.id}>
                                <span
                                    className={'selectedPillText'}
                                    data-testid={`selected-option-${id}-${el.id}`}>
                                    {el.name.toLowerCase()}
                                </span>
                                {removeable && (
                                    <span
                                        className={`clearIcon ${
                                            disabled ? 'clearIconDisabled' : ''
                                        }`}
                                        onClick={() => onRemovePill(el)}>
                                        <ClearIcon />
                                    </span>
                                )}
                            </span>
                        ))}
                    </span>

                    <span className="count" onClick={() => setIsCountOpen(!isCountOpen)}>
                        {selected.length > 0 ? `x${selected.length}` : ''}
                    </span>
                    <span className="searchInput">
                        <input
                            type="search"
                            value={searchTerm}
                            placeholder={placeholder}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            disabled={disabled}
                            ref={ref}
                        />
                    </span>

                    <span
                        className={`toggle ${disabled ? 'toggleDisabled' : 'toggleActive'}`}
                        onClick={onToggle}
                        ref={toggleRef}>
                        <ArrowDropDownIcon />
                    </span>
                </div>

                <>
                    <div
                        className={`selectList scroller ${
                            isListOpen
                                ? `showList ${selectAllDisabled ? 'showListNoAllSelector' : ''}`
                                : 'hideList'
                        } ${selectAllDisabled ? 'noAllSelector' : ''}`}
                        ref={listRef}>
                        <ul>
                            {rows.map((el) =>
                                isSelected(el.id) ? (
                                    <li
                                        key={el.id}
                                        onClick={(e) => (removeable ? onSelect(e, el) : null)}
                                        className="selected">
                                        <CheckBoxIcon />
                                        {el.name.toLowerCase()}
                                    </li>
                                ) : (
                                    <li key={el.id} onClick={(e) => onSelect(e, el)}>
                                        <CheckBoxOutlineBlankIcon />
                                        {el.name.toLowerCase()}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    {menuItems.length > 0 && !selectAllDisabled ? (
                        <ul className="selectList selectAll">
                            {menuItems.length === selected.length ? (
                                <li onClick={onDeselectAll} className="selected">
                                    <CheckBoxIcon />
                                    Deselect All
                                </li>
                            ) : (
                                <li onClick={onSelectAll} className="">
                                    <CheckBoxOutlineBlankIcon />
                                    Select All
                                </li>
                            )}
                        </ul>
                    ) : (
                        !selectAllDisabled && (
                            <ul className="selectList selectAll noSelections">
                                <li> No items </li>
                            </ul>
                        )
                    )}
                </>
                {mandatory && <IconError text={error || null} />}
            </div>
        );
    }
);

export default MultiSelect;

MultiSelect.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    removeable: PropTypes.bool,
    mandatory: PropTypes.bool,
    error: PropTypes.any,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    preSelectedIds: PropTypes.arrayOf(PropTypes.string),
    preSelects: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    heading: PropTypes.string,
    onChange: PropTypes.func,
    compareUpdatedItems: PropTypes.bool,
    selectAllDisabled: PropTypes.bool
};
