import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { loadClaimQueueActionStatusDetails } from '../../../store/directusService';
import { selectUsers } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { POLARIS_WHITE, SCREEN_SETTINGS } from '../../../themes/theme';
import { formatDateTime } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';

import statusHistoryClasses from '../claimStyles/statusHistory.module.css';

const ActionHistory = ({ history }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const users = useSelector(selectUsers);
    const claimQueueActionStatusDetails = useSelector(
        (state) => state.entities.directusService.claimQueueActionStatusDetails
    );

    useEffect(() => {
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (history.userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === history.userId);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(history.userId));
            } else dispatch(loadUser(history.userId));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === history.userId);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    // RENDER
    return (
        <div className={statusHistoryClasses.cardView}>
            <Card
                elevation={3}
                sx={{
                    backgroundColor: POLARIS_WHITE,
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <div className={statusHistoryClasses.banner}>
                    <span>Email: {emailAddress} </span>
                    <span>
                        Date: {history.date ? formatDateTime(history.date).substring(0, 10) : 'N/A'}
                    </span>
                    <span>
                        Time:{' '}
                        {history.date ? formatDateTime(history.date).substring(19, 12) : 'N/A'}
                    </span>
                    <span>
                        New Action:{' '}
                        {getNameFromId(claimQueueActionStatusDetails, history.actionStatusId)}
                    </span>
                </div>
                <CardContent sx={{ padding: 1 }}>
                    <div>
                        <p>
                            <span className={statusHistoryClasses.label}>
                                {' '}
                                Notes: {` ${history?.actionNotes ? history.actionNotes : ' - '}`}
                            </span>
                        </p>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ActionHistory;

ActionHistory.propTypes = {
    history: PropTypes.object
};
