import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadParticipantsAdvancedSearch,
    loadParticipantsSimpleSearch,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../../store/participantService';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ParticipantTableRow from './ParticipantTableRow';

import classes from '../../../app.module.css';
import search from './searchResults.module.css';

const headCells = [
    { id: 'ptCode', label: 'Participant ID', sortable: true },
    { id: 'firstName', label: 'First Name', sortable: true },
    { id: 'lastName', label: 'Last Name', sortable: true },
    { id: 'postcode', label: 'Post Code', sortable: true },
    { id: 'uniqueIdentifier', label: 'Unique ID', sortable: true },
    { id: 'primaryPhoneNumber', label: 'Primary Phone Number', sortable: true },
    { id: 'emailAddress', label: 'Email Address', sortable: true }
];

const ParticipantSearchResults = () => {
    // HOOKS
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const tableRef = useRef(null);
    const [loadedParticipant, setLoadedParticipant] = useState(false);
    const [showSensitiveCells, setShowSensitiveCells] = useState(false);

    // STORE STATE
    const { currentParticipant, participantsSearch, participantsSearchMetaData } = useSelector(
        (state) => state.entities.participantService
    );
    const { data: searchData, type: searchType } = useSelector(
        (state) => state.entities.participantService.participantSearchData
    );
    const isAdvancedSearch = searchType === 'advanced';

    // USE EFFECTS
    useEffect(() => {
        if (loadedParticipant && currentParticipant.id) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoadedParticipant(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant.id]);

    // HELPER FNS
    const getRowsInPages = () =>
        participantsSearch.map((el) => (
            <ParticipantTableRow key={el.ptCode} row={el} showSensitiveCells={showSensitiveCells} />
        ));

    // EVENT HANDLERS
    const onRevealSensitiveData = () => {
        setShowSensitiveCells(!showSensitiveCells);
        setTimeout(
            () => setShowSensitiveCells((showSensitiveCells) => !showSensitiveCells),
            30 * 1000
        );
    };

    return (
        <div className={classes.container}>
            <FormHeader text={'Participant Search Results'} />
            <div ref={tableRef} className={search.wrapper}>
                {participantsSearch.length < 1 ? (
                    <LoadingSpinner content="No participants found" />
                ) : (
                    <ResultsTable
                        defaultOrderBy={'firstName'}
                        headCells={headCells}
                        loadResults={
                            isAdvancedSearch
                                ? loadParticipantsAdvancedSearch
                                : loadParticipantsSimpleSearch
                        }
                        onRevealSensitiveData={onRevealSensitiveData}
                        tableRows={getRowsInPages()}
                        searchData={searchData}
                        apiParams={searchData}
                        showSensitiveCells={showSensitiveCells}
                        totalResults={participantsSearchMetaData.totalElements}
                        metaData={participantsSearchMetaData}
                    />
                )}
            </div>
        </div>
    );
};

export default ParticipantSearchResults;
