import { isDateFuture } from '../../../../utils/dateFunctions';

const MIN_CM_HEIGHT = 100;
const MAX_CM_HEIGHT = 250;
const MAX_INS_HEIGHT = 11;
const MIN_KG_WEIGHT = 35;
const MAX_KG_WEIGHT = 300;
const MAX_LBS_WEIGHT = 13;

const ERROR_BAD_DATE = 'Date must be in the past or present';
const NO_VALUE = 'Please enter a value';

const MIN_HEIGHT_ERROR = 'Value must be greater than zero';
const MAX_IN_HEIGHT_ERROR = `Value cannot be higher than ${MAX_INS_HEIGHT} ins`;

const MIN_WEIGHT_ERROR = 'Value must be greater than zero';
const MAX_LBS_WEIGHT_ERROR = `Value cannot be higher than ${MAX_LBS_WEIGHT} lbs`;

const MIN_HEIGHT_WARNING = 'Height is lower than minimum value. Please confirm';
const MAX_HEIGHT_WARNING = 'Height is higher than maximum value. Please confirm';
const MIN_WEIGHT_WARNING = 'Weight is lighter than minimum value. Please confirm';
const MAX_WEIGHT_WARNING = 'Weight is heavier than maximum value. Please confirm';

export const initialHeightErrorState = {
    height: { error: false, message: '' },
    heightFt: { error: false, message: '' },
    heightIn: { error: false, message: '' }
};

export const initialWeightErrorState = {
    weight: { error: false, message: '' },
    weightSt: { error: false, message: '' },
    weightLb: { error: false, message: '' }
};
export const initialErrorState = {
    ...initialHeightErrorState,
    ...initialWeightErrorState,
    eventDate: { error: false, message: '' }
};

export const initialWarningState = {
    height: { warning: false, message: '' },
    weight: { warning: false, message: '' },
    heightFt: { warning: false, message: '' },
    heightIns: { warning: false, message: '' },
    weightSt: { warning: false, message: '' },
    weightLbs: { warning: false, message: '' }
};

export const checkDate = (date, errors) => {
    let error = false;
    let message = '';
    if (isDateFuture(date)) {
        error = true;
        message = ERROR_BAD_DATE;
    } else if (!date) error = true;

    return { ...errors, eventDate: { error, message } };
};

export const checkHeightErrors = (value, errors) => {
    let error = false;
    let message = '';
    if (!value) {
        error = true;
        message = NO_VALUE;
    } else if (value <= 0) {
        error = true;
        message = MIN_HEIGHT_ERROR;
    }
    return {
        ...errors,
        heightFt: { error: false, message: '' },
        heightIn: { error: false, message: '' },
        height: { error, message }
    };
};

export const checkHeightFt = (value, errors) => {
    let error = false;
    let message = '';
    if (value <= 0) {
        error = true;
        message = MIN_HEIGHT_ERROR;
    }
    return {
        ...errors,
        height: { error: false, message: '' },
        heightFt: { error, message }
    };
};

export const checkHeightIns = (value, errors) => {
    let error = false;
    let message = '';
    if (value > MAX_INS_HEIGHT) {
        error = true;
        message = MAX_IN_HEIGHT_ERROR;
    }
    return { ...errors, heightIn: { error, message } };
};

export const checkHeightWarnings = (value, warnings) => {
    let warning = false;
    let message = '';
    if (value > 0 && value < MIN_CM_HEIGHT) {
        warning = true;
        message = MIN_HEIGHT_WARNING;
    } else if (value > MAX_CM_HEIGHT) {
        warning = true;
        message = MAX_HEIGHT_WARNING;
    }
    return { ...warnings, height: { warning, message } };
};

export const checkWeightErrors = (value, errors) => {
    let error = false;
    let message = '';
    if (!value) {
        error = true;
        message = NO_VALUE;
    } else if (value <= 0) {
        error = true;
        message = MIN_WEIGHT_ERROR;
    }
    return {
        ...errors,
        weightSt: { error: false, message: '' },
        weightLb: { error: false, message: '' },
        weight: { error, message }
    };
};

export const checkWeightSt = (value, errors) => {
    let error = false;
    let message = '';
    if (value <= 0) {
        error = true;
        message = MIN_WEIGHT_ERROR;
    }
    return {
        ...errors,
        weight: { error: false, message: '' },
        weightSt: { error, message }
    };
};

export const checkWeightLbs = (value, errors) => {
    let error = false;
    let message = '';
    if (value > MAX_LBS_WEIGHT) {
        error = true;
        message = MAX_LBS_WEIGHT_ERROR;
    }
    return { ...errors, weightLb: { error, message } };
};

export const checkWeightWarnings = (value, warnings) => {
    let warning = false;
    let message = '';
    if (value > 0 && value < MIN_KG_WEIGHT) {
        warning = true;
        message = MIN_WEIGHT_WARNING;
    } else if (value > MAX_KG_WEIGHT) {
        warning = true;
        message = MAX_WEIGHT_WARNING;
    }
    return { ...warnings, weight: { warning, message } };
};

export const validate = (newEntry, errors, warnings, heightChecked, weightChecked) => {
    let isValid = true;
    let newErrors = errors;
    let newWarnings = warnings;

    newErrors = checkHeightErrors(newEntry.height, newErrors);
    if (newErrors.height.error) isValid = false;

    newErrors = checkWeightErrors(newEntry.weight, newErrors);
    if (newErrors.weight.error) isValid = false;

    newErrors = checkDate(newEntry.eventDate, newErrors);
    if (newErrors.eventDate.error) isValid = false;

    if (!isValid) return { isValid, newErrors, newWarnings };

    newWarnings = checkHeightWarnings(newEntry.height, newWarnings);
    if (newWarnings.height.warning && !heightChecked) isValid = false;

    newWarnings = checkWeightWarnings(newEntry.weight, newWarnings);
    if (newWarnings.weight.warning && !weightChecked) isValid = false;

    return { isValid, newErrors, newWarnings };
};
