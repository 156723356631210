import React from 'react';
import PropTypes from 'prop-types';

import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Tooltip } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

const OnEditIcon = ({ onClick, roles, acceptedRoles, active = true }) => {
    acceptedRoles = acceptedRoles?.length > 0 ? acceptedRoles : [];
    if (hasRole(roles, acceptedRoles) && active) {
        return (
            <Tooltip title="Contact" placement="top">
                <PeopleAltOutlinedIcon
                    sx={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}
                    aria-label="contact-icon"
                    data-testid="contact-icon"
                    onClick={onClick}></PeopleAltOutlinedIcon>
            </Tooltip>
        );
    }
    return (
        <PeopleAltOutlinedIcon
            sx={{ color: POLARIS_DARKER_GREY }}
            data-testid="contact-icon"
            aria-label="contact-icon"></PeopleAltOutlinedIcon>
    );
};

export default OnEditIcon;

OnEditIcon.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};
