import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingSpinner from '../../../../ui/LoadingSpinner';

import SummaryDataTable from './SummaryDataTable';

import app from '../../../../../app.module.css';
import local from '../../wellbeing.module.css';

const SummaryData = () => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);

    // STORE STATE
    const referralSummary = useSelector(
        (state) => state.entities.participantService.referralSummary
    );

    // USE EFFECTS..
    useEffect(() => {
        setRows(referralSummary);
    }, [referralSummary]);

    // EVENT HANDLERS

    return (
        <div className={local.tableWrapper}>
            <div className={app.sectionHeading}>Second Nature Summary Data</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No summary data entries found" />
                ) : (
                    <SummaryDataTable rows={rows} />
                )}
            </div>
        </div>
    );
};

export default SummaryData;
