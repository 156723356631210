import { commonFieldValidations } from '../../../../utils/formValidation/commonFieldValidations';
import { names } from '../../../../utils/formValidation/validationPatterns';
import { regExps } from '../../../../utils/formValidation/validationPatterns';

export const businessValidationFields = {
    addressLine1: { required: 'text', ...commonFieldValidations.address1 },
    addressLine2: commonFieldValidations.address2,
    addressLine3: commonFieldValidations.address3,
    city: { required: 'text', ...commonFieldValidations.city },
    name: { required: 'text', ...commonFieldValidations.businessName },
    ownerId: { required: 'select', ...commonFieldValidations.businessOwnerId },
    postcode: { required: 'text', ...commonFieldValidations.postcode },
    sectorId: { required: 'select', ...commonFieldValidations.businessSectorId },
    size: { required: 'text', ...commonFieldValidations.businessSize },
    website: {
        subject: names.WEBSITE_URL,
        format: regExps.WEBSITE_R
    }
};
