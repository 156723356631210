import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../icons/OnEditIcon';
import { reverseFormatDate } from '../../utils/dateFunctions';
import { LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';

import classes from '../../commonStyles/tables.module.css';

const CommunicationTemplateTableRow = ({ row, roles, onEdit }) => {
    const acceptedRoles = [SUPERUSER, LOCAL_ADMIN];

    return (
        <TableRow
            hover
            className={`${classes.basicRowStripes} ${
                row.inactive ? classes.inactive : classes.active
            }`}>
            <TableCell>{row.code}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.type}</TableCell>
            <TableCell>
                {' '}
                {row.dateCreated === 'z' ? '-' : reverseFormatDate(row.dateCreated)}{' '}
            </TableCell>
            <TableCell>
                {' '}
                {row.dateUpdated === 'z' ? '-' : reverseFormatDate(row.dateUpdated)}{' '}
            </TableCell>
            <TableCell>{row.inactive ? 'Inactive' : 'Active'} </TableCell>
            <TableCell align="right">
                <OnEditIcon
                    onEdit={() => onEdit(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                />
            </TableCell>
        </TableRow>
    );
};

export default CommunicationTemplateTableRow;

CommunicationTemplateTableRow.propTypes = {
    row: PropTypes.object,
    onEdit: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};
