import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { checkExists, getCommunicationURL } from '../../../../api/commonHTTP';
import { updateGroupCommunication } from '../../../../store/communicationService';
import {
    selectCommunicationOptOutTypes,
    selectEmailCommunicationOptOutTypeId,
    selectSmsCommunicationOptOutTypeId
} from '../../../../store/dataSelectors';
import { loadCommunicationOptOutTypes } from '../../../../store/directusService';
import { searchParticipants } from '../../../../store/participantService';
import { selectLoggedInUser, selectUserRoles } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { addFullNameToArray } from '../../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import SingleSelect from '../../../formElements/SingleSelect';
import IconError from '../../../IconError';
import DynamicSearch from '../../../search/DynamicSearch';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';

import AddParticipantTable from './AddParticipantTable';
import RemoveParticipantTable from './RemoveParticipantTable';
import { ERROR_EXISTS, initialErrorState, validateName } from './validateGroupCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../../courses/courseStyles/courseToolbar.module.css';
import local from '../communicationStyles.module.css';

const EditCommunicationGroups = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        type: 'Email',
        name: '',
        originalName: '',
        participants: [],
        adviser: {}
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const adviserRoles = [ADVISER, MANAGER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [typeSwitch, setTypeSwitch] = useState(false);
    const [errors, setErrors] = useState(initialErrorState);
    let msg;

    const [searchTerm, setSearchTerm] = useState('');
    const [participants, setParticipants] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [addRows, setAddRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [newRows, setNewRows] = useState([]);
    const [deSelectedRows, setDeSelectedRows] = useState([]);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const { participantsSearch, participantsSearchMetaData } = useSelector(
        (state) => state.entities.participantService
    );
    const communicationOptOutTypes = useSelector(selectCommunicationOptOutTypes);
    const smsCommunicationOptOutTypeId = useSelector(selectSmsCommunicationOptOutTypeId);
    const emailCommunicationOptOutTypeId = useSelector(selectEmailCommunicationOptOutTypeId);
    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const group = useSelector((state) => state.entities.communicationService.groupCommunication);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setAddRows([]);
        setNewRows([]);
        setParticipants([]);
    };

    // USE EFFECTS
    useEffect(() => {
        if (!communicationOptOutTypes.length) dispatch(loadCommunicationOptOutTypes());
    }, []);

    useEffect(() => {
        if (!loggedInUser?.firstName) return;
        if (!hasRole(adviserRoles, roles)) return;
        setNewEntry((prev) => ({ ...prev, adviser: loggedInUser }));
        setAdvisersArray([loggedInUser]);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter((el) =>
            el.userTypes?.find((entry) => adviserRoles.includes(entry.role))
        );
        if (newEntry.adviser?.id && !updatedAdvisers.some((el) => el.id === newEntry.adviser.id)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdviser = advisers.find((el) => el.id === newEntry.adviser.id);
            updatedAdvisers = [selectedAdviser, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (
            !group ||
            Object.keys(group).length < 1 ||
            advisers.length < 1 ||
            participantStatusDetails?.length < 1
        )
            return;
        setNewEntry((prev) => ({ ...prev, ...group, originalName: group.name }));
        setTypeSwitch(group.type === 'SMS');
    }, [group, advisers]);

    useEffect(() => {
        newEntry.adviser?.id && dispatch(searchParticipants({ userId: newEntry.adviser.id }));
    }, [newEntry.adviser?.id]);

    useEffect(() => {
        if (participantsSearch?.length < 1 || communicationOptOutTypes?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { userId: newEntry.adviser.id },
                    participantsSearchMetaData.number + 1
                )
            );
        } else {
            const activeParticipants = participantsSearch.map((el) => ({
                ...el,
                emailAddress: el.emailAddress === '' ? 'z' : el.emailAddress,
                currentStatus: getNameFromId(participantStatusDetails, el.statusId) || 'zz'
            }));
            const newActiveParticipants =
                group.type === 'SMS'
                    ? activeParticipants.filter(
                          (el) =>
                              !el.communicationTypeOptOutIds.includes(
                                  smsCommunicationOptOutTypeId
                              ) &&
                              el.primaryPhoneNumber &&
                              !newEntry.participants.find((entry) => entry.participantId === el.id)
                      )
                    : activeParticipants.filter(
                          (el) =>
                              !el.communicationTypeOptOutIds.includes(
                                  emailCommunicationOptOutTypeId
                              ) &&
                              !newEntry.participants.find((entry) => entry.participantId === el.id)
                      );
            setParticipants(newActiveParticipants);
            setAddRows(newActiveParticipants);
            if (!group?.participants) return;
            const existingParticipants = group.participants
                .map((el) => {
                    const entry =
                        activeParticipants.find((entry) => entry.id === el.participantId) || null;
                    return entry
                        ? {
                              ...entry,
                              selectedContact: {
                                  contactMode: el.contactMode,
                                  contactNumber:
                                      el.contactMode === 'SECONDARY_PHONE_NUMBER'
                                          ? entry.secondaryPhoneNumber
                                          : entry.primaryPhoneNumber
                              }
                          }
                        : null;
                })
                .filter((el) => el);
            setNewRows(existingParticipants);
        }
    }, [
        participantsSearch,
        participantsSearchMetaData,
        participantStatusDetails,
        communicationOptOutTypes
    ]);

    useEffect(() => {
        const rows = !searchTerm
            ? participants
            : participants.filter(
                  (el) =>
                      el.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      el.lastName.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setAddRows(rows);
    }, [searchTerm]);

    useEffect(() => {
        if (successMessage === msg) onFormExit();
    }, [successMessage]);

    // EVENT HANDLERS

    const onSearch = (searchRes) => setSearchTerm(searchRes);
    const onSwitchType = (e) => {
        const { checked } = e.target;
        setTypeSwitch(checked);
        const type = checked ? 'SMS' : 'Email';
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onSelectRow = (row, isSelected) => {
        isSelected
            ? setSelectedRows(selectedRows.filter((el) => el.id !== row.id))
            : setSelectedRows([...selectedRows, row]);
    };

    const onDeSelectRow = (row, isSelected) => {
        isSelected
            ? setDeSelectedRows(deSelectedRows.filter((el) => el.id !== row.id))
            : setDeSelectedRows([...deSelectedRows, row]);
    };

    const onAdd = () => {
        if (selectedRows.length < 1) return;
        const availableRows = participants.filter(
            (el) => !selectedRows.find((entry) => entry.id === el.id)
        );
        setNewRows((prev) => [...prev, ...selectedRows]);
        setSelectedRows([]);
        setParticipants(availableRows);
        setAddRows(availableRows);
    };

    const onRemove = () => {
        if (deSelectedRows.length < 1) return;
        setParticipants([...participants, ...deSelectedRows]);
        setAddRows([...addRows, ...deSelectedRows]);
        setNewRows(newRows.filter((el) => !deSelectedRows.find((entry) => entry.id === el.id)));
        setDeSelectedRows([]);
    };

    const onAdviserChange = (chosenId) => {
        if (advisers.length < 1) return;
        setNewEntry((prev) => ({
            ...prev,
            adviser: advisers.find((el) => el.id === chosenId)
        }));
        setAddRows([]);
        setNewRows([]);
        setSelectedRows([]);
        setDeSelectedRows([]);
    };

    const onFormExit = () => {
        clearForm();
        navigate('/manage_groups');
    };

    const onLoadMoreAdvisers = () => {
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                !users.length ? 0 : lastPageOfUsersLoaded + 1
            )
        );
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validateName(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        if (newEntry.name !== newEntry.originalName) {
            if (
                await checkExists(
                    getCommunicationURL() + `rest/group-communication/exists/${newEntry.name}`
                )
            ) {
                setErrors((errors) => ({
                    ...errors,
                    name: { error: true, message: ERROR_EXISTS }
                }));
                return;
            }
        }
        msg = `${newEntry.name} has been updated`;
        const participants = newRows.map((el) => ({
            participantId: el.id,
            contactMode: el.selectedContact.contactMode
        }));
        const { id, name, type } = newEntry;
        const payload = { id, name, type, participants };

        dispatch(updateGroupCommunication(payload.id, payload, msg));
        onFormExit();
    };

    return (
        <div className={app.container}>
            <div className={app.mainHeading}>Edit Group</div>
            <div className={app.blueContainer}>
                <form className={form.formWrapper} onSubmit={onSubmit}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label="Group Name"
                                id="groupName"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.name}
                                placeholder="Enter value"
                                onChange={(e) =>
                                    setNewEntry((prev) => ({ ...prev, name: e.target.value }))
                                }
                            />
                            {errors.name.error && (
                                <div className={form.textInputError}>
                                    <IconError text={errors.name} />
                                </div>
                            )}

                            <div>
                                <PolarisSwitchSet
                                    id="groupContactType"
                                    label="Group Contact Type"
                                    leftLabel="Email"
                                    rightLabel="SMS"
                                    checked={typeSwitch}
                                    onSwitch={onSwitchType}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className={form.formColumn}></div>
                    </div>

                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <div className={form.formLabel}>Add Participants</div>
                            <div className={classes.coursesToolBar}>
                                <DynamicSearch
                                    search={onSearch}
                                    searchTerm={searchTerm}
                                    placeholder="Enter Search Term"
                                />
                            </div>
                        </div>
                        <div className={form.formColumn}>
                            <SingleSelect
                                id={'adviser'}
                                label={'Adviser'}
                                placeholder="Select adviser..."
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={advisers || []}
                                selectedId={newEntry.adviser?.id || ''}
                                selected={
                                    advisers?.find((el) => el.id === newEntry.adviser?.id) || {}
                                }
                                onChange={(chosenId) => onAdviserChange(chosenId)}
                                onLoadMoreItems={onLoadMoreAdvisers}
                                moreItemsToLoad={!totalUsers || users.length < totalUsers}
                            />
                        </div>
                    </div>

                    {addRows.length < 1 ? null : (
                        <>
                            <div>
                                <AddParticipantTable
                                    rows={addRows}
                                    roles={roles}
                                    type={newEntry.type}
                                    onSelectRow={onSelectRow}
                                    selectedRows={selectedRows}
                                />
                            </div>

                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                disabled={!hasRole(acceptedRoles, roles)}>
                                Add
                            </Button>
                        </>
                    )}

                    {newRows.length < 1 ? null : (
                        <div className={local.tableGroup}>
                            <RemoveParticipantTable
                                rows={newRows}
                                roles={roles}
                                type={newEntry.type}
                                onDeSelectRow={onDeSelectRow}
                                deSelectedRows={deSelectedRows}
                            />

                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={onRemove}
                                disabled={!hasRole(acceptedRoles, roles)}>
                                Remove
                            </Button>
                        </div>
                    )}

                    <FormActions
                        onClose={onSubmit}
                        onCancel={onFormExit}
                        btnText="Update"
                        disabled={!hasRole(acceptedRoles, roles)}
                    />
                </form>
            </div>
        </div>
    );
};

export default EditCommunicationGroups;
