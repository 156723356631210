import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import classes from '../../../../commonStyles/tables.module.css';

const OccProfileRow = ({ row }) => {
    return (
        <TableRow hover className={classes.basicRowStripes}>
            <TableCell>{row.eventDate}</TableCell>
            <TableCell>{row.time}</TableCell>
            <TableCell>{row.user === 'zz' ? ' - ' : row.user}</TableCell>
            <TableCell>{row.status === 'zz' ? ' - ' : row.status}</TableCell>
        </TableRow>
    );
};

export default OccProfileRow;

OccProfileRow.propTypes = {
    row: PropTypes.object
};
