import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { clearPlacements, clearVacancySubmissions } from '../../../store/recruitmentService';

import JobOffersTableManagement from './jobOffers/JobOffersTableManagement';
import PlacementManagement from './placements/PlacementManagement';
import PoolsTableManagement from './pools/PoolsTableManagement';
import Vacancies from './vacancies/Vacancies';

import local from './submissionStyles/submissions.module.css';

const Submissions = () => {
    const dispatch = useDispatch();

    const [tab, setTab] = useState(0);

    const onChange = (e, tab) => {
        setTab(tab);
    };

    useEffect(() => {
        dispatch(clearPlacements());
        dispatch(clearVacancySubmissions());
    }, []);

    return (
        <div className={local.submissions}>
            <div className={local.navBar}>
                <Tabs value={tab} onChange={onChange} aria-label="icon tabs example">
                    <Tab
                        icon={<Diversity3Icon />}
                        iconPosition="end"
                        label="Pools"
                        aria-label="group"
                        className={local.tab}
                    />
                    <Tab
                        icon={<ArticleOutlinedIcon />}
                        iconPosition="end"
                        label="Vacancies"
                        aria-label="CV"
                        className={local.tab}
                    />
                    <Tab
                        icon={<CheckBoxOutlinedIcon />}
                        iconPosition="end"
                        label="Job Offers"
                        aria-label="job offers"
                        className={local.tab}
                    />
                    <Tab label="Placement" aria-label="support" className={local.tab} />
                </Tabs>
            </div>
            <div className={local.submissionsContainer}>
                {tab === 0 && <PoolsTableManagement />}
                {tab === 1 && <Vacancies />}
                {tab === 2 && <JobOffersTableManagement />}
                {tab === 3 && <PlacementManagement />}
            </div>
        </div>
    );
};

export default Submissions;
