import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setErrorMessage } from '../../../../store/formsState';
import { addParticipantBarrier } from '../../../../store/participantService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import SelectBarriersTableManagement from './SelectBarriersTableManagement';

import classes from './barriers.module.css';

const SelectBarriersEditor = ({ open, onBarrierEditor, barrierNameDetails }) => {
    const { handleSubmit } = useForm({});

    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [rowsUpdated, setRowsUpdated] = useState([]);
    const [invalidDate, setInvalidDate] = React.useState(false);

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const onCreated = () => {
        setRowsUpdated([]);
        onBarrierEditor(false);
    };

    const onRowsUpdated = (rowsUpdated, invalidDate) => {
        invalidDate ? setInvalidDate(true) : setInvalidDate(false);
        setRowsUpdated(rowsUpdated);
    };

    // USE EFFECTS
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Participant barrier(s) added`) {
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        const participantBarriers = rowsUpdated.filter((el) => el.update === true);
        if (participantBarriers?.length < 1)
            dispatch(setErrorMessage(`There is nothing to update`));
        else {
            const invalidDueDateBarriers = participantBarriers.filter(
                (el) => el.dueDate < el.startDate
            );
            if (invalidDueDateBarriers?.length > 0)
                dispatch(setErrorMessage(`Due date must be same as or after start date`));
            else {
                participantBarriers.map((el) => {
                    const newBarrier = {
                        ...el,
                        id: null,
                        barrierId: el.id,
                        startDate: new Date(el.startDate).toISOString(),
                        dueDate: new Date(el.dueDate).toISOString()
                    };
                    dispatch(addParticipantBarrier(newBarrier));
                });
            }
        }
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <div className={classes.barriersDialogWindow}>
                <Dialog
                    className="muiDialogWrapper"
                    sx={{ alignSelf: 'centre' }}
                    open={isOpen}
                    onClose={handleClickOutside}
                    aria-labelledby="form-dialog-title">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle id="form-dialog-title">
                            Diagnostic Suggested Barriers
                        </DialogTitle>
                        <DialogContent data-testid="dialog-content">
                            <p>
                                Your answers to the Diagnostic Questionnaire have given the below
                                suggestion for Barriers.
                                <br /> Please select the Barriers which are applicable.
                            </p>
                            <SelectBarriersTableManagement
                                onRowsUpdated={onRowsUpdated}
                                barrierNameDetails={barrierNameDetails}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || invalidDate
                                }>
                                Update
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

SelectBarriersEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onBarrierEditor: PropTypes.func,
    barrierNameDetails: PropTypes.array.isRequired
};

export default SelectBarriersEditor;
