import { isDateFuture } from '../../../../utils/dateFunctions';

const ERROR_BAD_DATE = 'Date must be in the past or present';

export const initialErrorState = {
    unitConsumed: { error: false, message: 'Please enter a value' },
    eventDate: { error: false, message: 'Please enter a date' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (newEntry.unitConsumed === '') {
        newErrors = {
            ...newErrors,
            unitConsumed: { ...errors.unitConsumed, error: true }
        };
        isValid = false;
    }
    if (!newEntry.eventDate) {
        newErrors = {
            ...newErrors,
            eventDate: { ...errors.eventDate, error: true }
        };
        isValid = false;
    }
    if (isDateFuture(newEntry.eventDate)) {
        newErrors = {
            ...newErrors,
            eventDate: { error: true, message: ERROR_BAD_DATE }
        };
        isValid = false;
    }

    return { isValid, newErrors };
};

export const checkDate = (date, errors) => {
    return isDateFuture(date)
        ? {
              ...errors,
              eventDate: { error: true, message: ERROR_BAD_DATE }
          }
        : {
              ...errors,
              eventDate: initialErrorState.eventDate
          };
};
