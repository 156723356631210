import { createSlice } from '@reduxjs/toolkit';

import { getCalendarURL } from '../api/commonHTTP';

import { apiCallBegan } from './api';

const slice = createSlice({
    name: 'calendarService',
    initialState: {
        allAppointments: {},
        appointmentSearch: [],
        appointmentSlotSearch: [],
        newAppointment: {},
        participantAppointments: [],
        participantAppointmentSlots: [],
        usersCurrentAppointment: {},
        openAppointmentSchedulerPanel: false,
        loadingAppointments: false,
        loadingAppointmentSlots: false,
        loadingUserId: '',
        successMessage: '',
        errorMessage: ''
    },
    reducers: {
        clearAppointmentAndSlotSearch: (state) => {
            state.appointmentSearch = [];
            state.appointmentSlotSearch = [];
        },

        appointmentsLoading: (state, action) => {
            state.loadingAppointments = true;
            state.participantAppointments = [];
            state.loadingUserId = action.payload.userId;
        },

        appointmentsLoadingFailure: (state) => {
            state.loadingAppointments = false;
        },

        appointmentSlotsLoadingFailure: (state) => {
            state.loadingAppointmentSlots = false;
        },

        appointmentsLoaded: (state, action) => {
            state.loadingAppointments = false;
            const activeAppointments = action.payload.filter((el) => !el.inactive);
            state.allAppointments = {
                ...state.allAppointments,
                [state.loadingUserId]:
                    state.allAppointments[state.loadingUserId] &&
                    Array.isArray(state.allAppointments[state.loadingUserId])
                        ? [
                              ...state.allAppointments[state.loadingUserId].filter(
                                  (el) => !activeAppointments.find((entry) => entry.id === el.id)
                              ),
                              ...activeAppointments
                          ]
                        : activeAppointments
            };
            state.participantAppointments = activeAppointments;
        },

        appointmentSlotsLoading: (state, action) => {
            state.loadingAppointmentSlots = true;
            state.participantAppointmentSlots = [];
            state.loadingUserId = action.payload.userId;
        },

        appointmentSlotsLoaded: (state, action) => {
            state.loadingAppointmentSlots = false;
            const id = state.loadingUserId;
            const activeAppointmentSlots = action.payload.filter((el) => !el.inactive);
            state.allAppointments = {
                ...state.allAppointments,
                [id]:
                    state.allAppointments[id] && Array.isArray(state.allAppointments[id])
                        ? [
                              ...state.allAppointments[id].filter(
                                  (el) =>
                                      !activeAppointmentSlots.find((entry) => entry.id === el.id)
                              ),
                              ...activeAppointmentSlots
                          ]
                        : activeAppointmentSlots
            };
            state.participantAppointmentSlots = activeAppointmentSlots;
        },

        appointmentSearchLoaded: (state, action) => {
            state.appointmentSearch = action.payload;
        },

        appointmentSlotSearchLoaded: (state, action) => {
            state.appointmentSlotSearch = action.payload;
        },

        appointmentCreating: (state) => {
            state.newAppointment = {};
        },

        appointmentCreated: (state, action) => {
            state.newAppointment = action.payload;
            state.allAppointments = {
                ...state.allAppointments,
                [action.payload?.userId]:
                    state.allAppointments[action.payload.userId] &&
                    Array.isArray(state.allAppointments[action.payload.userId])
                        ? [...state.allAppointments[action.payload.userId], action.payload]
                        : [action.payload]
            };
            state.participantAppointments = [...state.participantAppointments, action.payload];
        },

        appointmentSlotCreated: (state, action) => {
            state.participantAppointmentSlots = [
                ...state.participantAppointmentSlots,
                action.payload
            ];
            state.allAppointments = {
                ...state.allAppointments,
                [action.payload.userId]:
                    state.allAppointments[action.payload.userId] &&
                    Array.isArray(state.allAppointments[action.payload.userId])
                        ? [...state.allAppointments[action.payload.userId], action.payload]
                        : [action.payload]
            };
        },

        appointmentUpdating: (state, action) => {
            const { userId, id } = action.payload;
            state.allAppointments = {
                ...state.allAppointments,
                [userId]: state.allAppointments[userId]?.filter((el) => el.id !== id)
            };
        },

        appointmentUpdated: (state, action) => {
            state.allAppointments = {
                ...state.allAppointments,
                [action.payload?.userId]:
                    state.allAppointments[action.payload.userId] &&
                    Array.isArray(state.allAppointments[action.payload.userId])
                        ? [...state.allAppointments[action.payload.userId], action.payload]
                        : [action.payload]
            };
            state.participantAppointments = state.participantAppointments.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        appointmentSlotUpdating: (state, action) => {
            const { userId, id } = action.payload;
            state.allAppointments = {
                ...state.allAppointments,
                [userId]: state.allAppointments[userId].filter((el) => el.id !== id)
            };
        },

        appointmentSlotUpdated: (state, action) => {
            state.allAppointments = {
                ...state.allAppointments,
                [action.payload.userId]:
                    state.allAppointments[action.payload.userId] &&
                    Array.isArray(state.allAppointments[action.payload.userId])
                        ? [...state.allAppointments[action.payload.userId], action.payload]
                        : [action.payload]
            };
            state.participantAppointmentSlots = state.participantAppointmentSlots.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        appointmentDeleting: (state, action) => {
            state.loadingUserId = action.payload.userId;
        },

        appointmentDeleted: (state, action) => {
            state.allAppointments = {
                ...state.allAppointments,
                [state.loadingUserId]: state.allAppointments[state.loadingUserId].filter(
                    (el) => el.id !== action.payload
                )
            };
            state.participantAppointments = state.participantAppointments.filter(
                (el) => el.id !== action.payload
            );
        },

        appointmentSlotDeleting: (state, action) => {
            state.loadingUserId = action.payload.userId;
        },

        appointmentSlotDeleted: (state, action) => {
            state.allAppointments = {
                ...state.allAppointments,
                [state.loadingUserId]: state.allAppointments[state.loadingUserId].filter(
                    (el) => el.id !== action.payload
                )
            };
            state.participantAppointmentSlots = state.participantAppointmentSlots.filter(
                (el) => el.id !== action.payload
            );
        },
        setCurrentAppointment: (state, action) => {
            state.usersCurrentAppointment = action.payload;
        },
        setOpenAppointmentSchedulerPanel: (state, action) => {
            state.openAppointmentSchedulerPanel = action.payload;
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload;
        }
    }
});

export const createAppointment = (appointmentType, data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment`,
        data,
        method: 'post',
        onStart: appointmentCreating.type,
        onSuccess: appointmentCreated.type,
        successMessage: `Appointment ${appointmentType} has been created`,
        onError: errorOccurred.type
    });

export const createAppointmentSlot = (slotType, data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot`,
        data,
        method: 'post',
        onSuccess: appointmentSlotCreated.type,
        successMessage: `Appointment slot ${slotType} has been created`,
        onError: errorOccurred.type
    });

export const loadUserAppointments = (userId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/user/${userId}`,
        data: { userId },
        onStart: appointmentsLoading.type,
        onSuccess: appointmentsLoaded.type,
        successMessage: `Appointments loaded`,
        onError: errorOccurred.type
    });

export const loadParticipantAppointments = (participantId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/search`,
        method: 'post',
        data: { participantIds: [participantId] },
        onStart: appointmentsLoading.type,
        onSuccess: appointmentsLoaded.type,
        onError: appointmentsLoadingFailure.type
    });

export const searchAppointments = (data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/search`,
        method: 'post',
        data,
        onSuccess: appointmentSearchLoaded.type,
        onError: appointmentsLoadingFailure.type
    });

export const loadUserAppointmentSlots = (userId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/user/${userId}`,
        data: { userId },
        onStart: appointmentSlotsLoading.type,
        onSuccess: appointmentSlotsLoaded.type,
        successMessage: `Appointment slots loaded`,
        onError: errorOccurred.type
    });

export const loadParticipantAppointmentSlots = (participantIds) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/search`,
        method: 'post',
        data: { participantIds: [participantIds] },
        onStart: appointmentSlotsLoading.type,
        onSuccess: appointmentSlotsLoaded.type,
        onError: appointmentSlotsLoadingFailure.type
    });

export const searchAppointmentSlots = (data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/search`,
        method: 'post',
        data,
        onSuccess: appointmentSlotSearchLoaded.type,
        onError: appointmentSlotsLoadingFailure.type
    });

export const updateAppointment = (userId, appointmentType, data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/${data.id}`,
        data,
        onStartData: { id: data.id, userId },
        method: 'put',
        onStart: appointmentUpdating.type,
        onSuccess: appointmentUpdated.type,
        successMessage: `Appointment ${appointmentType} has been updated`,
        onError: errorOccurred.type
    });

export const updateAppointmentSlot = (userId, slotType, data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/${data.id}`,
        data,
        method: 'put',
        onStartData: { id: data.id, userId },
        onStart: appointmentSlotUpdating.type,
        onSuccess: appointmentSlotUpdated.type,
        successMessage: `Appointment slot ${slotType} has been updated`,
        onError: errorOccurred.type
    });

export const deleteAppointment = (userId, name, id) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/${id}`,
        method: 'delete',
        data: { userId },
        onStart: appointmentDeleting.type,
        successMessage: `Appointment ${name} has been deleted`,
        onError: errorOccurred.type
    });

export const deleteAppointmentSlot = (userId, name, id) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/${id}`,
        method: 'delete',
        data: { userId },
        onStart: appointmentSlotDeleting.type,
        successMessage: `Appointment slot ${name} has been deleted`,
        onError: errorOccurred.type
    });

export const {
    clearAppointmentAndSlotSearch,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    appointmentDeleted,
    appointmentSlotDeleted
} = slice.actions;

const {
    errorOccurred,
    appointmentsLoading,
    appointmentSlotsLoading,
    appointmentsLoaded,
    appointmentSlotsLoaded,
    appointmentsLoadingFailure,
    appointmentSlotsLoadingFailure,
    appointmentSearchLoaded,
    appointmentSlotSearchLoaded,
    appointmentCreating,
    appointmentCreated,
    appointmentSlotCreated,
    appointmentUpdating,
    appointmentSlotUpdating,
    appointmentUpdated,
    appointmentSlotUpdated,
    appointmentDeleting,
    appointmentSlotDeleting
} = slice.actions;
export default slice.reducer;
