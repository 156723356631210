import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

import classes from './iconStyles.module.css';

const MedicalIcon = () => (
    <span className={classes.medical}>
        <MedicalInformationIcon aria-label="medical-icon" />
    </span>
);

export default MedicalIcon;
