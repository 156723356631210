import { createSelector } from '@reduxjs/toolkit';

const selectActionPlanStatusDetails = (state) =>
    state.entities.directusService.actionPlanStatusDetails;
const selectAppointmentTypeData = (state) => state.entities.directusService.appointmentTypes;

const selectAppointmentTypesForContractsData = (state) =>
    state.entities.directusService.appointmentTypeDetailsForContracts;

const selectAssetRequestStatusDetails = (state) =>
    state.entities.directusService.assetRequestStatusDetails;
const selectAssetRequestOrderTypeDetails = (state) =>
    state.entities.directusService.assetRequestOrderTypeDetails;

const selectAssetRequestAssetDetails = (state) =>
    state.entities.directusService.assetRequestAssetDetails;

const selectAssetRequestTypeDetails = (state) =>
    state.entities.directusService.assetRequestRequestTypeDetails;
const selectAttendanceDetails = (state) => state.entities.directusService.attendanceDetails;
const selectBarrierNameDetails = (state) => state.entities.directusService.barrierNameDetails;
const selectContractEntries = (state) => state.entities.directusService.contractDetails;

const selectDocumentTypeEntries = (state) => state.entities.directusService.documentTypes;

const selectFinancialPaymentMethodTypeDetails = (state) =>
    state.entities.directusService.financialPaymentMethodTypeDetails;

const selectFinancialRequestStatusDetails = (state) =>
    state.entities.directusService.financialRequestStatusDetails;
const selectFinancialRequestTypeDetails = (state) =>
    state.entities.directusService.financialRequestTypeDetails;
const selectFormsContracts = (state) => state.entities.participantService.formContracts;
const selectFormDetails = (state) => state.entities.participantService.forms;

const selectLocationIdsAndNamesData = (state) => state.entities.directusService.locationIdsAndNames;
const selectLocationForServicesData = (state) =>
    state.entities.directusService.locationsForServices;
const selectQuestionnairesDetails = (state) => state.entities.participantService.questionnaires;
const selectQuestionnairesContracts = (state) =>
    state.entities.participantService.questionnaireContracts;
const selectServiceEntries = (state) => state.entities.directusService.serviceDetails;
const selectSmokingCoResultEntries = (state) => state.entities.directusService.smokingCoResults;
const selectWellbeingEntries = (state) => state.entities.directusService.wellbeingDetails;

const selectCommunicationOptOutTypeData = (state) =>
    state.entities.directusService.communicationOptOutTypes;

const selectParticipantStatusDetails = (state) =>
    state.entities.directusService.participantStatusDetails;

export const selectActionPlanOutcomes = createSelector(
    selectActionPlanStatusDetails,
    (actionPlanStatusDetails) => actionPlanStatusDetails
);
export const selectActionPlanStatus = createSelector(
    selectActionPlanStatusDetails,
    (actionPlanStatusDetails) => actionPlanStatusDetails
);

export const selectAppointmentTypes = createSelector(
    selectAppointmentTypeData,
    (appointmentTypes) => appointmentTypes
);

export const selectAppointmentTypesForContracts = createSelector(
    selectAppointmentTypesForContractsData,
    (appointmentTypeDetailsForContracts) => appointmentTypeDetailsForContracts
);

export const selectAttendanceStatus = createSelector(
    selectAttendanceDetails,
    (attendanceDetails) => attendanceDetails
);

export const selectAttendedStatusId = createSelector(
    selectAttendanceStatus,
    (attendanceDetails) =>
        attendanceDetails.find((el) => el.name.toLowerCase() === 'attended')?.id || ''
);

export const selectNotKnownStatusId = createSelector(
    selectAttendanceStatus,
    (attendanceDetails) =>
        attendanceDetails.find((el) => el.name.toLowerCase() === 'not known')?.id || ''
);

export const selectBarrierDetails = createSelector(
    selectBarrierNameDetails,
    (barrierNameDetails) => {
        const newBarriers = [...barrierNameDetails];
        return newBarriers.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    }
);

export const selectContracts = createSelector(
    selectContractEntries,
    (contractDetails) => contractDetails
);

export const selectDocumentTypes = createSelector(selectDocumentTypeEntries, (documentTypes) => {
    const newDocumentTypes = documentTypes?.length > 0 ? [...documentTypes] : [];
    return newDocumentTypes.length > 0
        ? newDocumentTypes.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        : [];
});

export const selectFinancialPaymentMethodType = createSelector(
    selectFinancialPaymentMethodTypeDetails,
    (financialRequestPaymentMethodDetails) => financialRequestPaymentMethodDetails
);

export const selectFinancialRequestStatus = createSelector(
    selectFinancialRequestStatusDetails,
    (financialRequestStatusDetails) => financialRequestStatusDetails
);

export const selectFinancialRequestType = createSelector(
    selectFinancialRequestTypeDetails,
    (financialRequestTypeDetails) => financialRequestTypeDetails
);

export const selectLocationIdsAndNames = createSelector(
    selectLocationIdsAndNamesData,
    (locationIdsAndNames) => locationIdsAndNames
);

export const selectLocationsForServices = createSelector(
    selectLocationForServicesData,
    (locationsForServices) => locationsForServices
);
export const selectForms = createSelector(selectFormDetails, (formDetails) => formDetails);

export const selectFormContracts = createSelector(
    selectFormsContracts,
    (formContracts) => formContracts
);

export const selectQuestionnaires = createSelector(
    selectQuestionnairesDetails,
    (questionnaireDetails) => questionnaireDetails
);

export const selectQuestionnaireContracts = createSelector(
    selectQuestionnairesContracts,
    (questionnaireContracts) => questionnaireContracts
);

export const selectServices = createSelector(
    selectServiceEntries,
    (serviceDetails) => serviceDetails
);

export const selectSmokingCoResults = createSelector(
    selectSmokingCoResultEntries,
    (smokingCoResults) => {
        const sortedArr = [...smokingCoResults];
        return sortedArr.sort((a, b) => a.name - b.name);
    }
);

export const selectWellbeingTypes = createSelector(
    selectWellbeingEntries,
    (wellbeingDetails) => wellbeingDetails
);

export const selectWellbeingTypeNames = createSelector(selectWellbeingTypes, (wellbeingTypes) =>
    wellbeingTypes.map((el) => el.name.toLowerCase())
);

export const selectAssetRequestStatus = createSelector(
    selectAssetRequestStatusDetails,
    (assetRequestStatusDetails) => assetRequestStatusDetails
);

export const selectAssetRequestOrderType = createSelector(
    selectAssetRequestOrderTypeDetails,
    (assetRequestOrderTypeDetails) => assetRequestOrderTypeDetails
);
export const selectAssetRequestAsset = createSelector(
    selectAssetRequestAssetDetails,
    (assetRequestAssetDetails) => assetRequestAssetDetails
);

export const selectAssetRequestType = createSelector(
    selectAssetRequestTypeDetails,
    (assetRequestTypeDetails) => assetRequestTypeDetails
);

export const selectCommunicationOptOutTypes = createSelector(
    selectCommunicationOptOutTypeData,
    (communicationOptOutTypes) => communicationOptOutTypes
);

export const selectSmsCommunicationOptOutTypeId = createSelector(
    selectCommunicationOptOutTypes,
    (communicationOptOutTypes) => communicationOptOutTypes?.find((type) => type.name === 'SMS')?.id
);

export const selectEmailCommunicationOptOutTypeId = createSelector(
    selectCommunicationOptOutTypes,
    (communicationOptOutTypes) =>
        communicationOptOutTypes?.find((type) => type.name === 'Email')?.id
);

export const selectPhoneCallsCommunicationOptOutTypeId = createSelector(
    selectCommunicationOptOutTypes,
    (communicationOptOutTypes) =>
        communicationOptOutTypes?.find((type) => type.name === 'Phone Calls')?.id
);

export const selectParticipantStatuses = createSelector(
    selectParticipantStatusDetails,
    (participantStatusTypes) => participantStatusTypes
);

export const selectDoubtRaisedStatusId = createSelector(
    selectParticipantStatuses,
    (participantStatusTypes) =>
        participantStatusTypes?.find((el) => el.name.toLowerCase() === 'doubt raised')?.id || ''
);

export const selectSanctionedStatusId = createSelector(
    selectParticipantStatuses,
    (participantStatusTypes) =>
        participantStatusTypes?.find((el) => el.name.toLowerCase() === 'sanctioned')?.id || ''
);
