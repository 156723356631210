import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { loadCourseProvisions } from '../../../store/courseService';
import { loadAllExternalCourseProviderDetails } from '../../../store/directusService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import DynamicSearch from '../../search/DynamicSearch';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CourseProvisionManagementTable from './CourseProvisionManagementTable';

import classes from '../courseStyles/courseToolbar.module.css';

/**
 * Manage provisions
 * See PPL-473 for details.
 * As far as possible all UI code is in CourseProvisionManagementUI
 * @returns {JSX.Element}
 */

const CourseProvisionManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { courseProvisions } = useSelector((state) => state.entities.courseService);
    const { externalCourseProviderDetails } = useSelector(
        (state) => state.entities.directusService
    );

    // EVENT HANDLERS
    const onCreateProvision = () => navigate('/create_provision');

    // USEEFFECTS
    useEffect(() => {
        dispatch(loadCourseProvisions());
        dispatch(loadAllExternalCourseProviderDetails());
    }, []);

    useEffect(() => {
        if (!courseProvisions?.length || !externalCourseProviderDetails?.length) return;
        const getProviderName = (nameId) => {
            return externalCourseProviderDetails.find((el) => el.id === nameId)?.name || '';
        };

        const getProviderContactName = (nameId) => {
            const entry = externalCourseProviderDetails.find((el) => el.id === nameId);
            return entry
                ? `${entry.facilitator_contact_firstnames} ${entry.facilitator_contact_lastname}`
                : '';
        };

        if (courseProvisions?.length !== 0) {
            const rows = courseProvisions
                .filter((el) => el.active)
                .map((el) => ({
                    ...el,
                    providerName: getProviderName(el.nameId),
                    providerContactName: getProviderContactName(el.nameId)
                }));
            setRows(rows);
            setFilteredRows(rows);
        }
    }, [courseProvisions, externalCourseProviderDetails]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.title.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredRows(filteredRows);
    }, [searchTerm]);

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    return (
        <>
            <div className={classes.coursesToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter provision name"
                />

                <Button
                    disabled={!hasRole([SUPERUSER, ADVISER, MANAGER, QUALITY], roles)}
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onCreateProvision}>
                    Create Provision
                </Button>
            </div>

            {filteredRows.length < 1 ? (
                <LoadingSpinner content="No provisions found" />
            ) : (
                <CourseProvisionManagementTable
                    rows={filteredRows}
                    roles={roles || []}
                    searchTerm={searchTerm}
                />
            )}
        </>
    );
};

export default CourseProvisionManagement;
