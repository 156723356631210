import { isDateFuture } from '../../../../utils/dateFunctions';
import { getSelectValueMessage } from '../../../../utils/formValidation/errorMessageFunctions';

const MIN_VALUE = 0.1;
const MAX_VALUE = 99.9;

const ERROR_BAD_DATE = 'Date must be in the past or present';
const ERROR_MIN_VALUE = 'Value cannot be lower than minimum value';
const ERROR_MAX_VALUE = 'Value cannot be higher than maximum value';
const ERROR_NO_VALUE = 'Please enter a value';
const ERROR_NO_DATE = 'Please enter a date';

export const initialErrorState = {
    eventDate: { error: false, message: '' },
    measurementValue: { error: false, message: '' },
    measurementId: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.measurementId) {
        newErrors = {
            ...newErrors,
            measurementId: { error: true, message: getSelectValueMessage() }
        };
        isValid = false;
    }

    newErrors = checkGlucoseValue(newEntry.measurementValue, newErrors);
    if (newErrors.measurementValue.error) isValid = false;

    newErrors = checkDate(newEntry.eventDate, newErrors);
    if (newErrors.eventDate.error) isValid = false;

    return { isValid, newErrors };
};

export const checkGlucoseValue = (value, errors) => {
    let error = false;
    let message = '';
    if (!value) {
        error = true;
        message = ERROR_NO_VALUE;
    } else if (value < MIN_VALUE) {
        error = true;
        message = ERROR_MIN_VALUE;
    } else if (value > MAX_VALUE) {
        error = true;
        message = ERROR_MAX_VALUE;
    }

    return { ...errors, measurementValue: { error, message } };
};

export const checkDate = (date, errors) => {
    let error = false;
    let message = '';
    if (isDateFuture(date)) {
        error = true;
        message = ERROR_BAD_DATE;
    } else if (!date) {
        error = true;
        message = ERROR_NO_DATE;
    }
    return { ...errors, eventDate: { error, message } };
};
