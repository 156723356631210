import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { Model } from 'survey-core';

import CloseIcon from '@mui/icons-material/Close';

import OnEmailIcon from '../../../icons/OnEmailIcon';
import OnPrintIcon from '../../../icons/OnPrintIcon';
import { loadDocumentTypes } from '../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../store/documentService';
import { selectCurrentForm, selectCurrentParticipant } from '../../../store/participantSelectors';
import { loadForm, loadParticipantEmployability } from '../../../store/participantService';
import { selectCurrentUser } from '../../../store/userSelectors';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import AddDocument from '../../documents/AddDocument';
import GenerateFormPDF from '../../pdfGenerator/GenerateFormPDF';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import FormStatusHistory from './FormStatusHistory';
import { themeJson } from './FormTheme';

import app from '../../../app.module.css';
import dropdownClasses from './formStyles/rowDropDown.module.css';

const FormRowDetails = ({
    row,
    roles,
    onClose,
    onPrint,
    onEmail,
    createPdf = false,
    onCreatePdf
}) => {
    //HOOKS
    const dispatch = useDispatch();

    //LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [surveyModel, setSurveyModel] = useState(new Model('{}'));
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [showDocumentDownload, setShowDocumentDownload] = useState(false);
    const [showParticipantDocuments, setShowParticipantDocuments] = useState(false);
    const [docType, setDocType] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentId, setDocumentId] = useState('');

    const [isClearSelectedDocuments, setIsClearSelectedDocuments] = useState('0');

    //STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentUser = useSelector(selectCurrentUser);
    const currentForm = useSelector(selectCurrentForm);
    const currentParticipantEmployability = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const documentTypes = useSelector((state) => state.entities.directusService.documentTypes);

    //EVENT HANDLERS
    const print = () => {
        onPrint(row);
    };

    const email = () => {
        setShowParticipantDocuments(true);
    };

    const onExit = () => {
        setIsClearSelectedDocuments(Math.random());
        setIsSucceeded(false);
        setShowDocumentDownload(false);
        onClose();
    };

    //USE EFFECTS
    useEffect(() => {
        setIsClearSelectedDocuments(Math.random());
        dispatch(loadParticipantEmployability(currentParticipant.id));
        dispatch(loadParticipantDocumentDetails(currentParticipant.id));
        documentTypes.length < 1 && dispatch(loadDocumentTypes());
        dispatch(loadForm(row.formId));
    }, []);

    useEffect(() => {
        if (!participantDocuments) return;
        const newDocuments = participantDocuments.map((el) => ({ ...el, name: el.filename }));
        setDocuments(newDocuments);
    }, [participantDocuments]);

    useEffect(() => {
        if (documentId === '') return;
        onEmail(documentId, currentForm);
    }, [documentId]);

    useEffect(() => {
        const model = new Model(currentForm.data);
        model.applyTheme(themeJson);
        setSurveyModel(model);
    }, [currentForm]);

    useEffect(() => {
        surveyModel.data = row.data;
    }, [surveyModel, row]);

    useEffect(() => {
        if (documentTypes.length < 1) return;
        setDocType(documentTypes.find((el) => el.name === 'Form'));
    }, [documentTypes]);

    // CALLBACKS
    const onPdfGenerated = (isPdfGenerated) => {
        setIsSucceeded(isPdfGenerated);
        setShowDocumentDownload(isPdfGenerated);
        onCreatePdf(false);
    };

    // RENDER
    let content = '';
    if (Object.keys(currentForm)?.length < 1) content = 'No form loaded';

    if (Object.keys(currentForm)?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <div className={dropdownClasses.rowDropdown}>
            <p>
                <strong>Print Form</strong>
                <sub>
                    {' '}
                    <OnPrintIcon
                        onPrint={print}
                        roles={roles}
                        active={row.statusName === 'Authorised'}
                        acceptedRoles={acceptedRoles}
                        tooltip={
                            row.statusName === 'Authorised'
                                ? 'Create a PDF for participant'
                                : 'Unable to print email for participant'
                        }
                    />
                </sub>{' '}
                (When printed a copy of the form will be automatically saved to the
                participant&quot;s documents)
            </p>
            <p>
                <strong>Email Form</strong>
                <sub>
                    {' '}
                    <OnEmailIcon
                        onEmail={email}
                        roles={roles}
                        active={
                            row.statusName === 'Authorised' &&
                            currentForm.typeId !== null &&
                            currentForm.emailTemplateId !== null
                        }
                        acceptedRoles={acceptedRoles}
                        tooltip={
                            row.statusName === 'Authorised' &&
                            currentForm.typeId &&
                            currentForm.emailTemplateId &&
                            currentParticipantEmployability.jobCentreId !== null
                                ? 'Create an email for participant'
                                : row.statusName === 'Authorised' &&
                                    !currentForm.typeId &&
                                    !currentForm.emailTemplateId
                                  ? 'Form type and Email template not set for participant'
                                  : row.statusName === 'Authorised' &&
                                      !currentForm.typeId &&
                                      currentForm.emailTemplateId
                                    ? 'Form type not set for participant'
                                    : row.statusName === 'Authorised' &&
                                        currentForm.typeId &&
                                        !currentForm.emailTemplateId
                                      ? 'Email template not set for participant'
                                      : currentParticipantEmployability.jobCentreId === null
                                        ? 'Job centre not set for participant'
                                        : 'Unable to create email for participant'
                        }
                    />
                </sub>{' '}
                (When emailed a copy of the form will be automatically saved to the
                participant&quot;s documents)
            </p>
            <div className={app.sectionHeading}>Form History</div>
            <span>
                {row.history.map((el) => (
                    <FormStatusHistory key={uuid()} history={el} />
                ))}
            </span>
            <div className={dropdownClasses.exit} onClick={onExit}>
                Close details
                <CloseIcon />
            </div>
            {isSucceeded && (
                <div className={currentForm.successMessage}>PDF {currentForm.name} downloaded</div>
            )}
            {createPdf && (
                <GenerateFormPDF
                    surveyModel={surveyModel}
                    form={currentForm}
                    row={row}
                    currentParticipant={currentParticipant}
                    currentUser={currentUser}
                    onPdfGenerated={onPdfGenerated}
                />
            )}
            {showDocumentDownload && (
                <div>
                    <AddDocument
                        onClose={onExit}
                        onCancel={onExit}
                        btnText="Close"
                        currentDocumentType={docType ? docType : null}
                        btnDisabled={true}
                    />
                </div>
            )}
            {showParticipantDocuments && (
                <div>
                    <DDLOptionPicker
                        id={'documents'}
                        label={'Please select a document to email'}
                        key={isClearSelectedDocuments}
                        disabled={!hasRole(acceptedRoles, roles)}
                        menuItems={documents || []}
                        mandatory={true}
                        onChange={(chosenId) => {
                            chosenId ? setDocumentId(chosenId) : setDocumentId('');
                        }}></DDLOptionPicker>
                </div>
            )}
        </div>
    );
};

export default FormRowDetails;

FormRowDetails.propTypes = {
    row: PropTypes.object.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func.isRequired,
    onPrint: PropTypes.func,
    onEmail: PropTypes.func,
    createPdf: PropTypes.bool,
    onCreatePdf: PropTypes.func
};
