import { regExps } from '../../../utils/formValidation/validationPatterns';

export const participantStatusAdminValidationFields = {
    daysWithoutInitialAppointmentUntilRecordClosure: {
        required: 'text',
        subject: 'Value',
        numMin: 1,
        numMax: 999
    },
    deleteParticipantPiOnContractClosureDays: {
        conditional: [
            {
                condition: (entry) => entry.deleteParticipantPiOnContractClosure,
                required: 'text',
                subject: 'Number of days',
                format: regExps.NUMBERS_R,
                numMin: 1,
                numMax: 999
            }
        ]
    },
    deleteParticipantPiOnParticipantClosureDays: {
        conditional: [
            {
                condition: (entry) => entry.deleteParticipantPiOnParticipantClosure,
                required: 'text',
                subject: 'Number of days',
                format: regExps.NUMBERS_R,
                numMin: 1,
                numMax: 999
            }
        ]
    },
    participantStatuses: {
        subject: 'Status',
        required: 'select',
        array: {
            key: 'participantStatusId',
            toParticipantStatuses: {
                subject: 'To Status',
                required: 'select',
                array: {
                    key: 'participantStatusId',
                    participantStatusReasonIds: {
                        subject: 'To Status Reason',
                        required: 'select'
                    }
                }
            }
        }
    },
    button: {
        global: true,
        checkDiff: {}
    }
};
