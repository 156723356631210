import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../../../icons/OnEditIcon';
import { selectLoggedInUser, selectUsers } from '../../../../store/userSelectors';
import { loadUsersByServiceIds } from '../../../../store/userService';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import {
    ADVISER,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';

const FinancialRequestTableRow = ({ row, roles, onEdit }) => {
    const dispatch = useDispatch();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const edit = () => {
        onEdit(row);
    };
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector(selectUsers);

    const getName = (id) => {
        if (!id || id === '') {
            return '';
        }
        const firstName = users.find((item) => item.id === id)?.firstName || '';
        const lastName = users.find((item) => item.id === id)?.lastName || '';
        return firstName + ' ' + lastName;
    };
    // USE EFFECTS
    useEffect(() => {
        loggedInUser?.serviceIds?.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{reverseFormatDate(row.statusChangeDate) || '-'}</TableCell>
                <TableCell>{reverseFormatDate(row.dateIssued) || '-'}</TableCell>
                <TableCell>{getName(row.approverId) || '-'}</TableCell>
                <TableCell align="right">
                    <OnEditIcon onEdit={edit} roles={roles} acceptedRoles={acceptedRoles} />
                </TableCell>
            </TableRow>
        </>
    );
};

export default FinancialRequestTableRow;

FinancialRequestTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    onEdit: PropTypes.func
};
