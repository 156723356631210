import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createAlcoholConsumed, updateAlcoholConsumed } from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';

import { checkDate, initialErrorState, validate } from './validateAlcoholConsumed';

import form from '../../../../commonStyles/formStyles.module.css';

const CreateAlcoholConsumed = ({ onClose, roles, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        participantId: '',
        unitConsumed: '',
        eventDate: new Date().toISOString().slice(0, 10)
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const msg = `Participant alcohol consumed entry has been ${
        formType === 'create' ? 'added' : 'updated'
    }`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            eventDate: row.eventDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onUnitChange = (value) => {
        const maxChar = value >= 100 ? 6 : 5;
        clearError('unitConsumed');
        setNewEntry((prev) => ({
            ...prev,
            unitConsumed: value.replace(/[^\d*.?]/g, '').slice(0, maxChar)
        }));
    };

    const onDateChange = (date) => {
        clearError('eventDate');
        setNewEntry((prev) => ({ ...prev, eventDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const payload = { ...newEntry, participantId: currentParticipant?.id };
        formType === 'create'
            ? dispatch(createAlcoholConsumed(payload, msg))
            : dispatch(updateAlcoholConsumed(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>
                {formType === 'create' ? 'Add a New Alcohol Consumed' : 'Edit Alcohol Consumed'}
            </h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Units consumed in last seven days'}
                            id={'alcoholConsumed'}
                            mandatory={true}
                            disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                            value={newEntry.unitConsumed}
                            placeholder={'Enter value'}
                            onChange={(e) => onUnitChange(e.target.value)}
                        />
                        {errors.unitConsumed.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.unitConsumed} />
                            </div>
                        )}
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.alignUp}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={formType === 'create' ? 'Save' : 'Update'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreateAlcoholConsumed;

CreateAlcoholConsumed.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};
