import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { SCREEN_SETTINGS } from '../../../themes/theme';

import { RADIO_BUTTON_NO, RADIO_BUTTON_YES } from './SelectorOptions';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * No / Yes / Other Radio button picker
 *
 * Default is radioButtonPick
 *
 * @param onChange  Callback function to tell part of change. This is returned as
 * a string  'no' or 'yes' or the text of the third radio button in lower case.
 *
 * @param text Text to show as label
 * @param label Label
 * @param disabled Default = false
 * @param thirdButton Text for third button
 *
 * @param radioButtonPick string Sets initial radio selection.
 *     'no' means 'No' is selected.
 *     'yes' = 'Yes' selected
 *     Anything else except null = 3rd button selected.
 *
 */

const NoYesOtherRadioPicker = ({
    id,
    onChange,
    label,
    text,
    disabled = false,
    thirdButtonText = 'Prefer Not To Say',
    radioButtonPick = 'no'
}) => {
    const getOtherValue = (text) => {
        return text.toLowerCase();
    };

    const [radioSelection, setRadioSelection] = useState(getOtherValue(radioButtonPick));

    const handleRadioClick = (event) => {
        if (!disabled) {
            const value = event.target.value;

            // Only check if value is not undefined
            if (value !== undefined) {
                if (value === 'no') {
                    setRadioSelection('no');
                } else if (value === 'yes') {
                    setRadioSelection('yes');
                } else {
                    setRadioSelection(getOtherValue(thirdButtonText));
                }
            }
        }
    };

    // If anything changes then let parent component know.
    useEffect(() => {
        if (!disabled) {
            // Callback to parent component which expects a string
            if (radioSelection === RADIO_BUTTON_NO) {
                onChange(radioSelection);
            } else if (radioSelection === RADIO_BUTTON_YES) {
                onChange(radioSelection);
            } else if (radioSelection !== null) {
                onChange(getOtherValue(thirdButtonText));
            }
        }
    });

    // Set radio selection from options on first entry
    useEffect(() => {
        setRadioSelection(radioButtonPick);
    }, [radioButtonPick]);

    return (
        <div className={form.yesNoRadioButtons}>
            <Typography variant="body1" data-testid={'label_text'}>
                {text || ''}
            </Typography>
            <label htmlFor={id} className={form.formLabel}>
                {label}
            </label>

            <RadioGroup
                row
                aria-labelledby="no-yes-other-radio-buttons-group-label"
                name="no-yes-other-radio-buttons-group"
                value={radioSelection}
                onClick={handleRadioClick}>
                <FormControlLabel
                    data-testid={'radio_button_no'}
                    value={RADIO_BUTTON_NO}
                    control={<Radio disabled={disabled} />}
                    label="No"
                />
                <FormControlLabel
                    data-testid={'radio_button_yes'}
                    value={RADIO_BUTTON_YES}
                    control={<Radio disabled={disabled} />}
                    label="Yes"
                />
                <FormControlLabel
                    data-testid={'radio_button_other'}
                    value={getOtherValue(thirdButtonText)}
                    control={<Radio disabled={disabled} />}
                    label={thirdButtonText}
                />
            </RadioGroup>
            <Box sx={{ height: SCREEN_SETTINGS.gap.large }} />
        </div>
    );
};

NoYesOtherRadioPicker.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    thirdButtonText: PropTypes.string,
    radioButtonPick: PropTypes.string
};

export default NoYesOtherRadioPicker;
