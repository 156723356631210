import React from 'react';
import PropTypes from 'prop-types';

import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

const NotesCurrent = ({
    onClose,
    roles,
    notesNext,
    notesCurrent,
    onChangeNotesCurrent,
    onChangeNotesNext
}) => {
    const CHARACTER_LIMIT = 4000;
    const NEXT_NOTES_CHARACTER_LIMIT = 1000;
    const resetFields = () => {
        onChangeNotesCurrent('');
        onChangeNotesNext('');
    };

    const onCancel = () => {
        resetFields();
        onClose('notes');
    };
    return (
        <>
            <div className="noteGroups">
                <div className="notesFieldSet">
                    <label htmlFor="notesCurrent">Notes from this Appointment</label>
                    <textarea
                        className="notesField"
                        id="notesCurrent"
                        rows={5}
                        maxLength={CHARACTER_LIMIT}
                        value={notesCurrent || ''}
                        placeholder={'Enter notes for this appointment'}
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        onChange={(e) => {
                            onChangeNotesCurrent(e.target.value);
                        }}
                    />
                    <div className="counter">
                        {notesCurrent?.length}/{CHARACTER_LIMIT}
                    </div>
                </div>

                <div className="notesFieldSet">
                    <label htmlFor="notesNext">Notes for next Appointment</label>
                    <textarea
                        className="notesField"
                        id="notesNext"
                        rows={5}
                        maxLength={NEXT_NOTES_CHARACTER_LIMIT}
                        value={notesNext || ''}
                        placeholder={'Enter notes for next appointment'}
                        disabled={!hasRole([ADVISER, MANAGER, QUALITY, SUPERUSER], roles)}
                        onChange={(e) => {
                            onChangeNotesNext(e.target.value);
                        }}
                    />
                    <div className="counter">
                        {notesNext?.length}/{NEXT_NOTES_CHARACTER_LIMIT}
                    </div>
                </div>
            </div>
            <div className="cancelButton" onClick={onCancel}>
                Cancel X
            </div>
        </>
    );
};

export default NotesCurrent;

NotesCurrent.propTypes = {
    notesNext: PropTypes.string,
    notesCurrent: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    onChangeNotesCurrent: PropTypes.func,
    onChangeNotesNext: PropTypes.func,
    onClose: PropTypes.func
};
