import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLoggedInUser, selectUsersForVacancies } from '../../../../../store/userSelectors';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import DynamicSearch from '../../../../search/DynamicSearch';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import RejectedVacanciesTable from './RejectedVacanciesTable';

import classes from '../../submissionStyles/vacancy.module.css';

const RejectedVacanciesTableManagement = () => {
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rejectedParticipantVacancies, setRejectedParticipantVacancies] = useState([]);
    const [rejectedVacancies, setRejectedVacancies] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const usersForVacancies = useSelector(selectUsersForVacancies);
    const loggedInUser = useSelector(selectLoggedInUser);
    const { businesses, vacanciesForOwners, vacancySubmissions } = useSelector(
        (state) => state.entities.recruitmentService
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );

    // EVENT HANDLERS
    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // USE EFFECTS
    useEffect(() => {
        if (vacanciesForOwners?.length < 1 || !usersForVacancies || usersForVacancies?.length < 1) {
            setRows([]);
            setRejectedParticipantVacancies([]);
            setRejectedVacancies([]);
            return;
        }

        const rejectedOwnerVacancies = vacanciesForOwners.map((el) => ({
            ...el,
            businessName: businesses.find((entry) => entry.id === el.businessRecordId)?.name,
            adviser:
                usersForVacancies.find((entry) => entry.id === el?.ownerId)?.firstName +
                ' ' +
                usersForVacancies.find((entry) => entry.id === el?.ownerId)?.lastName
        }));
        setRows(rejectedOwnerVacancies);
        setRejectedParticipantVacancies(rejectedOwnerVacancies);
    }, [vacanciesForOwners, usersForVacancies]);

    useEffect(() => {
        if (rejectedParticipantVacancies.length < 1 || vacancySubmissions.length < 1) {
            return;
        }
        const statusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'rejected')?.id || '';

        const rejectedVacancies = rejectedParticipantVacancies
            .map((el) => {
                const foundVacancySubmission = vacancySubmissions.find(
                    (entry) =>
                        entry.participantId === currentParticipant.id &&
                        entry.vacancyId === el.id &&
                        entry.statusId === statusId
                );
                if (foundVacancySubmission) {
                    return {
                        ...el,
                        feedback: foundVacancySubmission.rejection.feedback,
                        rejectionDate: foundVacancySubmission.rejection.rejectionDate,
                        rejectionReasonIds: foundVacancySubmission.rejection.rejectionReasonIds
                    };
                }
            })
            .filter((el) => el);

        if (rejectedVacancies.length > 0) {
            setRows(rejectedVacancies);
            setRejectedVacancies(rejectedVacancies);
        }
    }, [rejectedParticipantVacancies, vacancySubmissions]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.title.toLowerCase().includes(searchTerm.toLowerCase()));
        setRejectedVacancies(filteredRows);
    }, [searchTerm]);

    // RENDER
    return (
        <>
            <br />
            <div className={classes.vacanciesToolBar}>
                <DynamicSearch
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                />
            </div>

            {rejectedVacancies.length < 1 ? (
                <LoadingSpinner content="No rejected vacancies found" />
            ) : (
                <RejectedVacanciesTable rows={rejectedVacancies} searchTerm={searchTerm} />
            )}
        </>
    );
};

export default RejectedVacanciesTableManagement;
