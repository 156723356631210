import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadClaimEventStatuses,
    loadClaimEventTypes,
    loadContractDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    clearClaimQueues,
    searchClaimQueue,
    updateClaimQueueProgress
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { getNameFromId } from '../../../utils/directusFunctions';
import { getNoUpdateMessage } from '../../../utils/formValidation/errorMessageFunctions';
import { deepEqual, trimEntries } from '../../../utils/objectUtils';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SearchOnEnter from '../../search/SearchOnEnter';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DateRangePicker from '../../ui/pickers/DateRangePicker';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import ClaimTableRow from './ClaimTableRow';
import StatusRevertEditor from './StatusRevertEditor';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from '../claimStyles/validations.module.css';

const loadPageSize = 100;
const sortOrder = 'asc';
const headCells = [
    { id: 'ptCode', label: 'ID', sortable: true },
    { id: 'poNumber', label: 'PO Number', sortable: true },
    { id: 'eventType', label: 'Event Type' },
    { id: 'eventDate', label: 'Event Date', sortable: true },
    { id: 'lastStatusUpdated', label: 'Last Updated', sortable: true },
    { id: 'statusName', label: 'Status' },
    { id: 'proceed', label: 'Proceed / Revert / Cannot Claim / Skip' }
];

const ClaimTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];

    // const [allClaimQueues, setAllClaimQueues] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowMetaData, setRowMetaData] = useState({
        order: sortOrder,
        orderBy: 'lastStatusUpdated',
        page: 0,
        rowsPerPage: 100
    });
    const [filters, setFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [claimEventStatusesTrimmed, setClaimEventStatusesTrimmed] = useState([]);

    const [statusRevertEditor, setStatusRevertEditor] = useState(false);
    const [revertedRow, setRevertedRow] = useState({});
    const [openId, setOpenId] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [clearSelectedClaimEventStatus, setClearSelectedClaimEventStatus] = useState('0');
    const [clearSelectedClaimEventType, setClearSelectedClaimEventType] = useState('1');
    const [clearSelectedContract, setClearSelectedContract] = useState('2');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { claimEventStatuses, claimEventTypes, contractDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { claimQueues, claimQueuesMetaData, loadingClaimQueuesSearch } = useSelector(
        (state) => state.entities.participantService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearClaimQueues());
        setClearSelectedClaimEventStatus(Math.random().toString());
        setClearSelectedClaimEventType(Math.random().toString());
        setClearSelectedContract(Math.random().toString());
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        contractDetails?.length < 1 && dispatch(loadContractDetails());
    }, []);

    useEffect(() => {
        if (
            !deepEqual(filters, {
                statusIds: claimEventStatusesTrimmed.map((el) => el.id)
            })
        ) {
            Object.keys(filters).length !== 0 &&
                dispatch(
                    searchClaimQueue(
                        filters,
                        0,
                        loadPageSize,
                        rowMetaData.orderBy,
                        rowMetaData.order
                    )
                );
            setRows([]);
        } else dispatch(clearClaimQueues());
    }, [filters]);

    useEffect(() => {
        if (claimEventStatuses?.length < 1) return;
        const newClaimEventStatusesTrimmed = claimEventStatuses.filter(
            (el) => el.name === 'Verified' || el.name === 'Claimed' || el.name === 'Paid'
        );
        setFilters((prev) => ({
            ...prev,
            statusIds: newClaimEventStatusesTrimmed.map((el) => el.id)
        }));
        setClaimEventStatusesTrimmed(newClaimEventStatusesTrimmed);
    }, [claimEventStatuses]);

    useEffect(() => {
        setSubmitDisabled(false);
        if (successMessage === 'Claim queues have been updated') {
            Object.keys(filters).length !== 0 &&
                dispatch(
                    searchClaimQueue(
                        filters,
                        rowMetaData.page,
                        loadPageSize,
                        rowMetaData.orderBy,
                        rowMetaData.order
                    )
                );
        }
    }, [successMessage]);

    useEffect(() => {
        const newRows = claimQueues.map((el) => mapClaimQueue(el));
        //  setAllClaimQueues(newRows);
        setRows(newRows);
    }, [claimQueues]);

    // HELPER FUNCTIONS
    const mapClaimQueue = (el) => ({
        ...el,
        selection:
            getNameFromId(claimEventStatuses, el.statusId).toLowerCase() === 'paid'
                ? 'Skip'
                : 'Proceed',
        eventName: getNameFromId(claimEventTypes, el.eventTypeId),
        statusName: getNameFromId(claimEventStatuses, el.statusId)
    });

    // EVENT HANDLERS
    const onSubmit = (e) => {
        e.preventDefault();
        let proceedIds = [];
        let reverts = [];
        let unclaimableIds = [];
        rows.forEach((el) => {
            if (el.selection === 'Proceed') proceedIds = [...proceedIds, el.id];
            else if (el.selection === 'Revert')
                reverts = [
                    ...reverts,
                    {
                        id: el.id,
                        notes: el.notes
                    }
                ];
            else if (el.selection === 'CannotClaim') unclaimableIds = [...unclaimableIds, el.id];
        });
        if (proceedIds.length > 0 || reverts.length > 0 || unclaimableIds.length > 0) {
            setSubmitDisabled(true);
            dispatch(
                updateClaimQueueProgress({
                    proceedIds,
                    reverts,
                    unclaimableIds
                })
            );
        } else {
            dispatch(setErrorMessage(getNoUpdateMessage()));
        }
    };

    const onUpdateFilters = (chosenId, key) => {
        if (chosenId === filters[key]) return;
        if (!chosenId) {
            setFilters(trimEntries([key], filters));
        } else setFilters((prev) => ({ ...prev, [key]: chosenId }));
    };

    const onSearchDateRange = (searchRange) => {
        if (!searchRange) {
            if ('lastStatusUpdatedFrom' in filters && 'lastStatusUpdatedTo' in filters) {
                setFilters(trimEntries(['lastStatusUpdatedFrom', 'lastStatusUpdatedTo'], filters));
            }
        } else {
            const inDate = parse(searchRange.substring(0, 10), 'dd/MM/yyyy', new Date());
            const startDate = format(inDate, 'yyyy-MM-dd');
            const outDate = parse(searchRange.substring(13), 'dd/MM/yyyy', new Date());
            const endDate = format(outDate, 'yyyy-MM-dd');
            setFilters((prev) => ({
                ...prev,
                lastStatusUpdatedFrom: startDate,
                lastStatusUpdatedTo: endDate
            }));
        }
    };

    const onTermChange = (searchRes) => {
        if (searchRes === '' && filters.code) onUpdateFilters(searchRes, 'code');
        setSearchTerm(searchRes);
    };

    const onSearch = () => onUpdateFilters(searchTerm, 'code');

    // CALLBACKS
    const onRowChange = (row) => {
        setSubmitDisabled(false);
        if (row.selection === 'Revert') {
            setStatusRevertEditor(true);
            setRevertedRow(row);
        } else {
            setRows(rows.map((el) => (el.id === row.id ? row : el)));
        }
    };

    const onStatusRevertUpdated = (revertedRow) => {
        setRows(rows.map((el) => (el.id === revertedRow.id ? revertedRow : el)));
    };

    const onToggleDropdown = (id) => setOpenId(id);

    const onStatusRevertEditor = (setOpen) => {
        setStatusRevertEditor(setOpen);
    };

    const createRows = () => {
        return rows.map((el) => (
            <ClaimTableRow
                key={el.id}
                row={el}
                roles={acceptedRoles}
                openId={openId}
                toggleDropdown={onToggleDropdown}
                onRowChange={onRowChange}
            />
        ));
    };

    // RENDER
    let content = '';
    if (claimEventStatusesTrimmed?.length < 1) content = 'No claim event statuses';
    if (claimEventTypes?.length < 1) content = 'No claim event types';
    if (contractDetails?.length < 1) content = 'No contract details';
    if (
        claimEventStatusesTrimmed?.length < 1 ||
        claimEventTypes?.length < 1 ||
        contractDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <>
            <div className={app.pageContainer}>
                <div className={`${app.sectionHeading} ${local.headerBar}`}>Claim Queue</div>
                <form onSubmit={onSubmit} className={form.form}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <div>
                                <DDLOptionPicker
                                    label={'Event Type'}
                                    key={clearSelectedClaimEventType}
                                    id={'contractId'}
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    menuItems={claimEventTypes || []}
                                    onChange={(chosenId) =>
                                        onUpdateFilters(chosenId, 'eventTypeId')
                                    }
                                />
                                <div>
                                    <label htmlFor="lastStatusUpdated" className={form.formLabel}>
                                        Last Updated
                                    </label>
                                    <DateRangePicker onSearch={onSearchDateRange} />
                                </div>
                            </div>
                        </div>
                        <div className={form.formColumn}>
                            <div>
                                <DDLOptionPicker
                                    label={'Contract'}
                                    key={clearSelectedContract}
                                    id={'contractId'}
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    menuItems={contractDetails || []}
                                    onChange={(chosenId) => onUpdateFilters(chosenId, 'contractId')}
                                />
                                <DDLOptionPicker
                                    label={'Status'}
                                    key={clearSelectedClaimEventStatus}
                                    id={'statusIds'}
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    menuItems={claimEventStatusesTrimmed || []}
                                    onChange={(chosenId) => {
                                        if (!chosenId)
                                            onUpdateFilters(
                                                claimEventStatusesTrimmed.map((el) => el.id),
                                                'statusIds'
                                            );
                                        else onUpdateFilters([chosenId], 'statusIds');
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    <div className={local.claimsToolBar}>
                        <label className={form.formLabel}>Search PT ID & PO Number</label>
                        <SearchOnEnter
                            searchTerm={searchTerm}
                            placeholder="Search ID and PO..."
                            autocomplete="off"
                            search={onSearch}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            onChange={onTermChange}
                        />
                    </div>
                    <StatusRevertEditor
                        open={statusRevertEditor}
                        onStatusRevertEditor={onStatusRevertEditor}
                        revertedRow={revertedRow}
                        onStatusRevertUpdated={onStatusRevertUpdated}
                    />
                </div>
                {rows?.length < 1 ? (
                    <div>No claims found</div>
                ) : (
                    <div>
                        <ResultsTable
                            defaultOrderBy={rowMetaData.orderBy}
                            headCells={headCells}
                            loadResults={searchClaimQueue}
                            passRowMetaDataUp={setRowMetaData}
                            apiParams={filters}
                            tableRows={createRows()}
                            totalResults={claimQueuesMetaData.totalElements}
                            metaData={claimQueuesMetaData}
                            loadingValues={loadingClaimQueuesSearch}
                            loadPageSize={loadPageSize}
                        />
                        <Button
                            id="submitButtonClaimQueues"
                            data-testid="testIdSubmitButton"
                            content="Submit Checked Events"
                            disabled={submitDisabled}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ClaimTableManagement;
