import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import FormHeader from '../layout/FormHeader';
import AccordionHeader from '../ui/accordion/AccordionHeader';

import SubmissionManagement from './vacanciesAndPooling/SubmissionManagement';
import VacancyManagement from './vacanciesAndPooling/VacancyManagement';

const VacancySubmissionManagement = () => {
    // LOCAL STATE
    const { state } = useLocation();
    const [expanded, setExpanded] = useState(false);

    // STORE STATE
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );

    const handleChange = (panel) => (SyntheticEvent, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // USE EFFECTS
    useEffect(() => {
        if (!state?.accordionPanel) {
            setExpanded('vacancyDetails');
        } else {
            setExpanded(state.accordionPanel);
        }
    }, []);

    return (
        <Card data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Manage Vacancy'}></FormHeader>
                    <FormHeader
                        text={`${state.vacancy.title + ' ' + currentBusiness.code}`}></FormHeader>
                    <Accordion
                        TransitionProps={{ mountOnEnter: true }}
                        expanded={expanded === 'vacancyDetails'}
                        onChange={handleChange('vacancyDetails')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="vacancyDetails-content"
                            id="vacancyDetails-header">
                            <AccordionHeader>Vacancy Details</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <VacancyManagement
                                vacancy={state.vacancy}
                                accordionPanelFrom={
                                    !state?.accordionPanelFrom
                                        ? 'myBusinesses'
                                        : state.accordionPanelFrom
                                }
                                jobsOfferedForVacancies={state.jobsOfferedForVacancies}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        TransitionProps={{ mountOnEnter: true }}
                        expanded={expanded === 'submissions'}
                        onChange={handleChange('submissions')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="submissions-content"
                            id="submissions-header">
                            <AccordionHeader>Submissions</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SubmissionManagement
                                vacancy={state.vacancy}
                                accordionPanelFrom={
                                    !state?.accordionPanelFrom
                                        ? 'pooling'
                                        : state.accordionPanelFrom
                                }
                                jobsOfferedForVacancies={state.jobsOfferedForVacancies}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </CardContent>
        </Card>
    );
};

export default VacancySubmissionManagement;
