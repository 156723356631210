import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Accordion, Box, Button, Dialog, DialogTitle } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { selectParticipantAppointments } from '../../../store/calendarSelectors';
import { loadParticipantAppointments } from '../../../store/calendarService';
import { selectAppointmentTypes, selectAttendedStatusId } from '../../../store/dataSelectors';
import {
    loadAppointmentTypeDetails,
    loadAttendanceDetails,
    loadContractDetailsForContractId,
    loadDocumentTypes,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import {
    loadContractConstants,
    loadMostRecentAppointmentAttendanceDetails,
    loadParticipantGeneralInformation,
    loadParticipantSectionHeadings,
    updateAppointmentAttendanceDetail
} from '../../../store/participantService';
import { selectLoggedInUserFirstAndLastName, selectUserRoles } from '../../../store/userSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import SingleSelect from '../../formElements/SingleSelect';
import ParticipantStickyMenu from '../../navigation/participant/ParticipantStickyMenu';
import MandatoryActivitiesSwitch from '../../scheduling/components/MandatoryActivitiesSwitch';
import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';
import ConfirmPrompt from '../../ui/notices/confirmPrompt/ConfirmPrompt';
import ActionPlan from '../actionPlan/ActionPlan';
import Barriers from '../barriers/Barriers';
import JobGoals from '../jobGoals/JobGoals';
import Questionnaire from '../questionnaire/Questionnaire';
import Vacancies from '../submissions/vacancies/Vacancies';

import AppointmentPdfManagement from './AppointmentPdfManagement';
import Courses from './Courses';
import ESignature from './ESignature';
import General from './General';
import NotesCurrent from './NotesCurrent';
import NotesPast from './NotesPast';
import Requests from './Requests';

import './appointmentStyles.css';
import app from '../../../app.module.css';

const CONFIRMATION =
    'confirm that the information above is correct and that my Adviser has explained to me, and I understand, the service commitments and complaints procedure. I confirm that I have been issued a copy of the Privacy Notice/s and the contents of the Notice/s explained to me. I agree to maintain on-going contact with my Adviser and will let my Adviser know if I am to start work, or if I am ill and unable to participate on the programme. I confirm that I have been inducted to the programme and that during my induction, health and safety procedures were explained to me by my back to work programme Adviser.';
const Appointments = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER, MANAGER, QUALITY, ADVISER, RECRUITMENT_MANAGER];
    const [isHidden, setIsHidden] = useState({
        questionnaire: false,
        jobReadiness: false,
        barriers: false,
        actionPlan: false,
        courses: false,
        request: false,
        vacancy: false
    });

    const initialErrorState = {
        attendanceId: { error: false, message: 'Please select an attendance status' }
    };

    const initialCurrentAppointment = {
        id: '',
        attendanceId: '',
        participantId: '',
        attendanceName: '',
        lastAppointmentNotes: '',
        currentNotes: '',
        nextAppointmentNotes: '',
        calendarAppointmentId: '',
        endTime: '',
        startTime: '',
        mostRecentAppointmentId: ''
    };

    const initialNextAppointment = {
        date: ' - ',
        startTime: ' - ',
        appointmentType: ' - '
    };

    const [participantsActiveAccordions, setParticipantsActiveAccordions] = useState({});
    const [expanded, setExpanded] = useState('');
    const [completeDisabled, setCompleteDisabled] = useState(true);
    const [currentAppointment, setCurrentAppointment] = useState(initialCurrentAppointment);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [exitFormTemplateId, setExitFormTemplateId] = useState('');
    const [complianceActivityFormQuestionnaireId, setComplianceActivityFormQuestionnaireId] =
        useState('');
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
    const [errors, setErrors] = useState(initialErrorState);

    const [adviserSignature, setAdviserSignature] = useState('');
    const [participantSignature, setParticipantSignature] = useState('');
    const [signatureDateTime, setSignatureDateTime] = useState('');
    const [fileName, setFileName] = useState('');
    const [nextAppointment, setNextAppointment] = useState(initialNextAppointment);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const { participantSectionHeadings: sectionHeadings, currentParticipantGeneralInformation } =
        useSelector((state) => state.entities.participantService);

    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );
    const mostRecentAppointment = useSelector(
        (state) => state.entities.participantService.mostRecentAppointment
    );

    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );
    const attendedStatusId = useSelector(selectAttendedStatusId);

    const adviserName = useSelector(selectLoggedInUserFirstAndLastName);
    const participantAppointments = useSelector(selectParticipantAppointments);
    const appointmentTypes = useSelector(selectAppointmentTypes);

    // HELPER FNS

    const configNextAppointment = (baseDate, baseStartTime, appointmentList) => {
        const baseTimestamp = new Date(`${baseDate} ${baseStartTime}`).getTime();

        let closestFutureDate = null;
        let appointment = null;
        let appointmentType = null;

        for (let item of appointmentList) {
            const itemTimestamp = new Date(`${item.date} ${item.startTime}`).getTime();

            if (
                (itemTimestamp > baseTimestamp && closestFutureDate === null) ||
                (itemTimestamp > baseTimestamp && itemTimestamp < closestFutureDate)
            ) {
                closestFutureDate = itemTimestamp;
                appointment = item;
                appointmentType = appointmentTypes.find((el) => el.id === appointment.typeId)?.name;
            }
        }

        setNextAppointment({
            date: reverseFormatDate(appointment?.date)?.replace(/\//g, ' / ') || ' - ',
            startTime: appointment?.startTime?.replace(/:/g, ' : ') || ' - ',
            appointmentType: appointmentType || ' - '
        });
    };

    const confirmationText = `I, ${currentParticipant.firstName} ${currentParticipant.lastName}, ${CONFIRMATION}`;

    const initializeAttendance = () => {
        const entry = attendanceDetails.find((el) => el.name === 'Not known');
        if (entry) {
            setCurrentAppointment((prev) => ({
                ...prev,
                attendanceId: entry.id,
                attendanceName: entry.name,
                attendanceTime: format(new Date(), `dd / MM / yyyy , HH :mm`).replace(/:/g, ' : ')
            }));
        }
    };

    const configDocumentName = () => {
        const fileName = `${state?.appointment?.appointmentType.replaceAll(' ', '_')}_${format(
            new Date(),
            `dd_MMM_yyyy_HH_mm`
        )}`;
        setFileName(fileName);
    };

    const resetAppointment = () => {
        setCurrentAppointment(initialCurrentAppointment);
        setErrors(initialErrorState);
        initializeAttendance();
        setExpanded('');
    };

    const selectAttendance = (id) => {
        setCurrentAppointment((prev) => ({
            ...prev,
            attendanceId: id
        }));
        const attendanceTitle = attendanceDetails.find((el) => el.id === id)?.name || '';
        setCompleteDisabled(attendanceTitle === 'Not known');
        if (attendanceTitle === 'Failed to Attend' || attendanceTitle.includes('Cancelled')) {
            setIsHidden((prev) => ({
                ...prev,
                questionnaire: true,
                jobReadiness: true,
                barriers: true,
                actionPlan: true,
                courses: true,
                request: true,
                vacancy: true
            }));
        } else {
            setIsHidden((prev) => ({
                ...prev,
                questionnaire: !sectionHeadings.questionnaire,
                jobReadiness: !sectionHeadings.jobReadiness,
                barriers: !sectionHeadings.barriers,
                actionPlan: !sectionHeadings.actionPlan,
                courses: !sectionHeadings.courses,
                request: !sectionHeadings.request,
                vacancy: !sectionHeadings.vacancy
            }));
        }
    };

    // USE EFFECTS

    useEffect(() => {
        setErrors(initialErrorState);
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (!currentParticipant?.id) return;
        dispatch(loadParticipantSectionHeadings(currentParticipant.contractId));
        dispatch(loadContractConstants(currentParticipant.contractId));
        dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
        dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
        dispatch(loadDocumentTypes());
        dispatch(loadMostRecentAppointmentAttendanceDetails(currentParticipant.id));
        dispatch(loadParticipantAppointments(currentParticipant.id));
        dispatch(loadAppointmentTypeDetails());
        if (
            !currentParticipantGeneralInformation ||
            Object.keys(currentParticipantGeneralInformation).length < 1
        )
            dispatch(loadParticipantGeneralInformation(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (
            !isPDFModalOpen ||
            !state?.appointment ||
            participantAppointments?.length < 1 ||
            appointmentTypes?.length < 1
        )
            return;
        configNextAppointment(
            state?.appointment?.date,
            state?.appointment?.startTime,
            participantAppointments
        );
    }, [participantAppointments, appointmentTypes, isPDFModalOpen]);

    useEffect(() => {
        if (sectionHeadings?.contractId === currentParticipant?.contractId) {
            setIsHidden({
                questionnaire: !sectionHeadings.questionnaire,
                jobReadiness: !sectionHeadings.jobReadiness,
                barriers: !sectionHeadings.barriers,
                actionPlan: !sectionHeadings.actionPlan,
                courses: !sectionHeadings.courses,
                request: !sectionHeadings.request,
                vacancy: !sectionHeadings.vacancy
            });
            setParticipantsActiveAccordions({
                questionnaire: sectionHeadings.questionnaire,
                jobReadiness: sectionHeadings.jobReadiness,
                barriers: sectionHeadings.barriers,
                actionPlan: sectionHeadings.actionPlan,
                courses: sectionHeadings.courses,
                request: sectionHeadings.request,
                vacancy: sectionHeadings.vacancy
            });
        }
    }, [sectionHeadings]);

    useEffect(() => {
        selectAttendance(currentAppointment.attendanceId);
    }, [currentAppointment.attendanceId]);

    useEffect(() => {
        if (attendanceDetails?.length) {
            setCurrentAppointment((prev) => ({
                ...prev,
                participantId: currentParticipant?.id,
                calendarAppointmentId: state?.appointment?.id
            }));
            initializeAttendance();
        }
    }, [attendanceDetails]);

    useEffect(() => {
        if (!mostRecentAppointment?.id) return;

        setCurrentAppointment((prev) => ({
            ...prev,
            lastAppointmentNotes: mostRecentAppointment.lastAppointmentNotes || '', // from last app.
            mostRecentAppointmentId: mostRecentAppointment.id
        }));
    }, [mostRecentAppointment]);

    useEffect(() => {
        setCompleteDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    // EVENT HANDLERS
    const onValidateAttendance = () => {
        let isValid = true;
        if (!currentAppointment.attendanceId) {
            dispatch(setErrorMessage('No attendance selected'));
            isValid = false;
        }
        if (!currentAppointment.calendarAppointmentId) {
            dispatch(setErrorMessage('No appointment ID'));
            isValid = false;
        }
        return isValid;
    };

    const onAttendanceSelect = (id) => {
        setErrors(initialErrorState);
        if (
            state?.appointment?.appointmentType.toLowerCase() === 'initial' &&
            id === attendedStatusId &&
            contractConstants.requirePoNumber &&
            !currentParticipantGeneralInformation.poNumber
        ) {
            setErrors({
                attendanceId: {
                    error: true,
                    message:
                        'Participant Record missing PO number, Initial Appointment cannot be marked as Attended'
                }
            });
        }
        setCurrentAppointment((prev) => ({
            ...prev,
            attendanceId: id,
            attendanceName: attendanceDetails.find((el) => el.id === id)?.name
        }));
    };

    const onAccordionChange = (panel) => {
        setExpanded(expanded === panel ? '' : panel);
    };
    const onCancelAppointment = () => setIsCancelModalOpen(true);

    const onUpdateAppointmentEntry = () => {
        const payload = {
            attendanceId: currentAppointment.attendanceId,
            notes: currentAppointment.currentNotes,
            nextAppointmentNotes: currentAppointment.nextAppointmentNotes
        };
        dispatch(updateAppointmentAttendanceDetail(payload, state.participantAppointmentId));
    };

    const onProceed = () => {
        const isValid = onValidateAttendance();
        if (!isValid) return;
        contractConstants?.displayESignature ? setIsSignatureModalOpen(true) : onESigSkip();
        setExitFormTemplateId(contractConstants?.exitFormTemplateId || '');
        setComplianceActivityFormQuestionnaireId(
            contractConstants?.complianceActivityFormQuestionnaireId || ''
        );
    };

    const onSavePDF = () => {
        onPDFModalClose();
        resetAppointment();
    };

    const onCancelPDF = () => {
        onPDFModalClose();
    };

    const onPDFModalClose = () => {
        setIsPDFModalOpen(false);
    };

    const onESigSkip = () => {
        setIsSignatureModalOpen(false);
        setIsPDFModalOpen(true);
        onUpdateAppointmentEntry();
        setSignatureDateTime(format(new Date(), `dd MMM, yyyy HH :mm`));
        configDocumentName();
    };

    const onESigSaveAdviserSignature = (adviserSignature) => {
        setAdviserSignature(adviserSignature);
    };

    const onESigSaveParticipantSignature = (participantSignature) => {
        setParticipantSignature(participantSignature);
        setSignatureDateTime(format(new Date(), `dd  MMM,  yyyy   HH :mm`).replace(/:/g, ' : '));
        configDocumentName();
    };

    const onESigCancel = () => {
        setIsSignatureModalOpen(false);
    };

    const onESigClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsSignatureModalOpen(false);
        }
    };

    const onESigConfirm = () => {
        setIsSignatureModalOpen(false);
        setIsPDFModalOpen(true);
        onUpdateAppointmentEntry();
    };

    const onChangeNotesCurrent = (value) => {
        setCurrentAppointment((prev) => ({
            ...prev,
            currentNotes: value
        }));
    };

    const onChangeNotesNext = (value) => {
        setCurrentAppointment((prev) => ({
            ...prev,
            nextAppointmentNotes: value
        }));
    };

    const onCancelModalClose = (_, cancelChanges) => {
        if (cancelChanges) {
            resetAppointment();
            setIsCancelModalOpen(false);
            navigate(state?.returnPath);
        }
        if (!cancelChanges) setIsCancelModalOpen(false);
    };

    // RENDER
    return (
        <div className={`${app.container} appointmentsContainer`}>
            <div className={app.withSideBar}>
                <h1 className={app.mainHeading}>
                    {state?.appointment?.appointmentType || ''}{' '}
                    {reverseFormatDate(state?.appointment?.date)}
                </h1>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'general'}
                    onChange={() => onAccordionChange('general')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <AccordionHeader>General</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <General onClose={onAccordionChange} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Notes from last Appointment</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <NotesPast notes={currentAppointment.lastAppointmentNotes} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.questionnaire}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Questionnaire</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Questionnaire />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.jobReadiness}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Job Readiness & Goals</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <JobGoals
                            roles={roles}
                            acceptedRoles={acceptedRoles}
                            currentParticipant={currentParticipant}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.barriers}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Barriers</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Barriers />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.actionPlan}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Action Plan</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ActionPlan />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.courses}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Courses</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Courses />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.request}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole([...acceptedRoles, RECRUITMENT_MANAGER], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Requests</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Requests />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={isHidden.vacancy}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    disabled={!hasRole([...acceptedRoles, RECRUITMENT_MANAGER], roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Vacancy</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Vacancies />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'notes'}
                    onChange={() => onAccordionChange('notes')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Appointment Notes</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <NotesCurrent
                            notesCurrent={currentAppointment.currentNotes}
                            notesNext={currentAppointment.nextAppointmentNotes}
                            onChangeNotesCurrent={onChangeNotesCurrent}
                            onChangeNotesNext={onChangeNotesNext}
                            onClose={onAccordionChange}
                            roles={roles}
                        />
                    </AccordionDetails>
                </Accordion>

                <div className="appointmentCompleteSection">
                    <div className="appointmentField">
                        <SingleSelect
                            id={'selectedAttendanceStatus'}
                            key={0}
                            label="Attendance"
                            placeholder="Select an attendance status"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={attendanceDetails || []}
                            selectedId={currentAppointment.attendanceId}
                            selected={
                                attendanceDetails.find(
                                    (el) => el.id === currentAppointment.attendanceId
                                ) || {}
                            }
                            error={errors.attendanceId}
                            onChange={(chosenId) => onAttendanceSelect(chosenId)}
                        />
                    </div>
                    <div className="mandatoryField">
                        <MandatoryActivitiesSwitch
                            checked={state?.appointment?.mandatory}
                            disabled={true}
                        />
                    </div>

                    <div className="completeFormActions">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={onProceed}
                            disabled={completeDisabled}>
                            Proceed
                        </Button>
                        <div className="cancelButton" onClick={onCancelAppointment}>
                            Cancel X
                        </div>
                    </div>
                </div>

                {isSignatureModalOpen && (
                    <Dialog onClose={onESigClose} open={isSignatureModalOpen}>
                        <div className="eSigModal">
                            <div className="eSigModalContent">
                                <ESignature
                                    onESigSaveParticipantSignature={onESigSaveParticipantSignature}
                                    onESigSaveAdviserSignature={onESigSaveAdviserSignature}
                                    onSkip={onESigSkip}
                                    onConfirm={onESigConfirm}
                                    onCancel={onESigCancel}
                                    confirmationText={confirmationText}
                                />
                            </div>
                        </div>
                    </Dialog>
                )}
                {isPDFModalOpen && (
                    <Dialog fullWidth={true} maxWidth={'md'} open={isPDFModalOpen}>
                        <DialogTitle id="id">
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>Preview PDF</Box>
                            </Box>
                        </DialogTitle>
                        <div className="pdfModal">
                            <div className="pdfModalContent">
                                <AppointmentPdfManagement
                                    onSavePDF={onSavePDF}
                                    onCancelPDF={onCancelPDF}
                                    fileName={fileName}
                                    appointmentType={
                                        appointmentTypes.find(
                                            (el) => el.id === state?.appointment.typeId
                                        ) || {}
                                    }
                                    participantsActiveAccordions={participantsActiveAccordions}
                                    adviserName={adviserName || 'User not logged in'}
                                    currentParticipant={currentParticipant}
                                    confirmationText={confirmationText}
                                    participantSignature={participantSignature}
                                    adviserSignature={adviserSignature}
                                    currentAppointment={currentAppointment}
                                    appointment={state?.appointment}
                                    nextAppointment={nextAppointment}
                                    signatureDateTime={signatureDateTime}
                                    exitFormTemplateId={exitFormTemplateId}
                                    complianceActivityFormQuestionnaireId={
                                        complianceActivityFormQuestionnaireId
                                    }
                                />
                            </div>
                            <div className="pdfModalButtons"></div>
                        </div>
                    </Dialog>
                )}
                {isCancelModalOpen && (
                    <ConfirmPrompt
                        isOpen={isCancelModalOpen}
                        onCancel={(e) => onCancelModalClose(e, false)}
                        onConfirm={(e) => onCancelModalClose(e, true)}
                        content="Do you want to cancel these changes?"
                    />
                )}
                <div className={app.sideBar}>
                    <ParticipantStickyMenu />
                </div>
            </div>
        </div>
    );
};
export default Appointments;
