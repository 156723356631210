import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { DEFAULT_TABLE_ROW_OPTIONS, DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';

import CurrentProvisionTableRow from './CurrentProvisionTableRow';

const headCells = [
    { id: 'id', numeric: false, label: 'ID' },
    { id: 'provisionName', numeric: false, label: 'Provision Name' },
    { id: 'providerName', numeric: false, label: 'Provider Name' },
    { id: 'status', numeric: false, label: 'Status' }
];

/**
 * Table for managing all current provisions.
 * Each row has
 * - id
 * - provision name
 * - provider name
 * - status
 * @returns {JSX.Element}
 */
const CurrentProvisionTable = ({ rows }) => {
    // LOCAL STATE
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);
    const [openId, setOpenId] = useState('');

    // USE EFFECTS
    useEffect(() => {
        setOrder('asc');
        setOrderBy('id');
    }, []);

    // EVENT HANDLERS
    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const onToggleDropdown = (id) => setOpenId(id);

    // HELPER FNS
    const stableSort = () => {
        if (rows.length < 1) return;

        const onSort =
            order === 'desc'
                ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
                : (a, b) => -(b[orderBy] > a[orderBy] ? 1 : -1);

        const stabilizedThis = rows.map((el, i) => [el, i]);
        stabilizedThis.sort((a, b) => {
            const order = onSort(a[0], b[0]);
            return order !== 0 ? order : a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    // RENDER
    return rows.length < 1 ? null : (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => onSort(headCell.id)}>
                                            <Typography variant={'subtitle3'} color={'primary'}>
                                                {headCell.label}
                                            </Typography>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc'
                                                        ? 'sorted descending'
                                                        : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort()
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <CurrentProvisionTableRow
                                        key={row.id}
                                        row={row}
                                        toggleDropdown={onToggleDropdown}
                                        openId={openId}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default CurrentProvisionTable;

CurrentProvisionTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
