import { getCutoffDate } from '../dateFunctions';
import { deepEqual } from '../objectUtils';

import {
    getCharMaxMessage,
    getCharMinMessage,
    getDuplicatedMessage,
    getFormatMessage,
    getIsLengthMessage,
    getMatchMessage,
    getNoUpdateMessage,
    getNumMaxMessage,
    getNumMinMessage,
    getReqMessage,
    getSelectMessage,
    getYoungMessage
} from './errorMessageFunctions';

export const getAgeMin = (value, min, subject) => {
    if (!value) return false;
    const startDate = new Date(value);
    const cutoffDate = getCutoffDate(Date.now(), min);
    if (startDate >= cutoffDate) return { error: true, message: getYoungMessage(subject, min) };
    return false;
};

export const getCharMax = (value, max, subject) => {
    if (value && value.trim().length > max)
        return { error: true, message: getCharMaxMessage(subject, max) };
    return false;
};

export const getCharMin = (value, min, subject) => {
    if (value && value.trim().length < min)
        return { error: true, message: getCharMinMessage(subject, min) };
    return false;
};

export const getDiff = (entry, initial) => {
    if (deepEqual(entry, initial)) return { error: true, message: getNoUpdateMessage() };
    return false;
};

export const getDuplicated = (entry, array, subject) => {
    if (array.some((el) => el === entry))
        return { error: true, message: getDuplicatedMessage(subject) };
    return false;
};

export const getFormat = (value, regExp, subject) => {
    if (!regExp.test(value)) return { error: true, message: getFormatMessage(subject) };
    return false;
};

export const getIsLength = (value, length, subject) => {
    if (value?.trim().length !== length)
        return { error: true, message: getIsLengthMessage(subject, length) };
    return false;
};

export const getMatches = (value, match, subject) => {
    if (value !== match[0]) return { error: true, message: getMatchMessage(subject, match[1]) };
    return false;
};

export const getNumMax = (value, max, subject) => {
    if (value != null && parseFloat(value) > max)
        return { error: true, message: getNumMaxMessage(subject, max) };
    return false;
};

export const getNumMin = (value, min, subject) => {
    if (value != null && parseFloat(value) < min)
        return { error: true, message: getNumMinMessage(subject, min) };
    return false;
};

export const getRequired = (value, subject) => {
    if (!value) return { error: true, message: getReqMessage(subject) };
    return false;
};

export const getSelected = (value, subject, ia) => {
    if (!value || !value.length) return { error: true, message: getSelectMessage(subject, ia) };
    return false;
};
