import React from 'react';
import PropTypes from 'prop-types';

import { Switch } from '@mui/material';

import './pickerStyles/polarisSwitch.css';

const PolarisSwitchSet = ({
    id,
    label,
    leftLabel,
    rightLabel,
    checked,
    onSwitch,
    disabled = false
}) => {
    return (
        <div className="polarisSwitchSet">
            <label htmlFor={id}>{label}</label>
            <div className="polarisSwitch">
                <span>{leftLabel}</span>
                <span className={`switchButton ${disabled ? 'disabledSwitch' : ''}`}>
                    <Switch id={id} disabled={disabled} checked={checked} onChange={onSwitch} />
                </span>
                <span>{rightLabel}</span>
            </div>
        </div>
    );
};

export default PolarisSwitchSet;

PolarisSwitchSet.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    checked: PropTypes.bool,
    onSwitch: PropTypes.func,
    disabled: PropTypes.bool
};
