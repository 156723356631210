import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { selectSmokingCoResults } from '../../../../store/dataSelectors';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createSmokingQuitAttempt } from '../../../../store/participantService';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import AccordionHeader from '../../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../../ui/accordion/AccordionIcon';
import FormActions from '../../../ui/formActions/FormActions';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import NoYesOtherRadioPicker from '../../../ui/pickers/NoYesOtherRadioPicker';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';
import { RADIO_BUTTON_NO } from '../../../ui/pickers/SelectorOptions';

import { checkDateSet, checkQuitDate, initialErrorState, validate } from './validateSmoking';

import form from '../../../../commonStyles/formStyles.module.css';

const QuitAttempt = ({ roles, onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        baselineCoMeasurementId: '',
        dateSet: new Date().toISOString().slice(0, 10),
        entitledToFreePrescription: 'no',
        participantConfirmed: false,
        pregnantAtQuitDate: 'no',
        quitDate: new Date().toISOString().slice(0, 10),
        quitStatus: true,
        usingECigaretteId: '',
        smokingQuitAttemptReviews: []
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [pregnantAtQuitDate, setPregnantAtQuitDate] = useState('no');
    const [entitledToFreePrescription, setEntitledToFreePrescription] = useState('no');
    const [errors, setErrors] = useState(initialErrorState);
    const [arrayBaselineCoMeasurements, setArrayBaselineCoMeasurements] = useState([]);
    const [isClearBaselineCoMeasurementId, setIsClearBaselineCoMeasurementId] = useState('0');
    const [arrayUsingECigarettes, setArrayUsingECigarettes] = useState([]);
    const [isClearUsingECigarettes, setIsClearUsingECigarettes] = useState('1');
    const msg = `Participant quit attempt has been added`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const baselineCoMeasurements = useSelector(selectSmokingCoResults);
    const usingECigarettes = useSelector(
        (state) => state.entities.directusService.smokingUsingECigarettes
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setArrayBaselineCoMeasurements([]);
        setIsClearBaselineCoMeasurementId(Math.random());
        setArrayUsingECigarettes([]);
        setIsClearUsingECigarettes(Math.random());
    };

    const onFormExit = () => {
        onClose();
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        setArrayBaselineCoMeasurements(baselineCoMeasurements);
    }, [baselineCoMeasurements]);

    useEffect(() => {
        setArrayUsingECigarettes(usingECigarettes);
    }, [usingECigarettes]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onDateChange = (key, date) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: date }));
        const newErrors =
            key === 'dateSet'
                ? checkDateSet(date, newEntry.quitDate, errors)
                : checkQuitDate(date, newEntry.dateSet, errors);
        setErrors(newErrors);
    };

    const onCancelQuitAttempt = () => {
        clearForm();
        onFormExit();
    };

    const onSubmitQuitAttempt = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const {
            // eslint-disable-next-line
            parsedDate,
            ...rest
        } = newEntry;
        const payload = {
            ...rest,
            pregnantAtQuitDate,
            entitledToFreePrescription,
            participantId: currentParticipant?.id
        };
        dispatch(createSmokingQuitAttempt(payload));
        onFormExit();
    };

    // RENDER

    return (
        <Accordion defaultExpanded={true} slotProps={{ transition: { mountOnEnter: true } }}>
            <AccordionSummary
                expandIcon={<AccordionIcon />}
                aria-controls={`panel1a-content`}
                sx={{ borderTop: '1px solid #000' }}
                id={`panel1a-header`}>
                <AccordionHeader>
                    Quit Attempt {reverseFormatDate(newEntry.quitDate)}
                </AccordionHeader>
            </AccordionSummary>
            <AccordionDetails>
                <h3>Quit Attempt Details</h3>

                <form className={form.formWrapper} onSubmit={onSubmitQuitAttempt}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <div className={form.baseMargin}>
                                <DateSelect
                                    value={newEntry.dateSet}
                                    label="Date Set"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    isDefault={true}
                                    mandatory={true}
                                    error={errors.dateSet}
                                    onDateChange={(res) => onDateChange('dateSet', res)}
                                />
                            </div>

                            <div className={form.checkboxGroup}>
                                <label htmlFor="participantConfirmed" className={form.formLabel}>
                                    Participant Confirmed?
                                </label>
                                <input
                                    id="participantConfirmed"
                                    type="checkbox"
                                    checked={newEntry.participantConfirmed}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    onChange={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            participantConfirmed: e.target.checked
                                        }))
                                    }
                                />
                            </div>

                            <DDLOptionPicker
                                label={'Baseline CO Measurement'}
                                id={'baselineCoMeasurementId'}
                                key={isClearBaselineCoMeasurementId}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                menuItems={arrayBaselineCoMeasurements || []}
                                chosenName={newEntry.baselineCoMeasurementName}
                                chosenId={newEntry.baselineCoMeasurementId}
                                onChange={(chosenId) => {
                                    clearError('baselineCoMeasurementId');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        baselineCoMeasurementId: chosenId
                                    }));
                                }}></DDLOptionPicker>
                            {errors.baselineCoMeasurementId.error && (
                                <div className={form.selectInputError}>
                                    <IconError text={errors.baselineCoMeasurementId} />
                                </div>
                            )}

                            <div>
                                <NoYesOtherRadioPicker
                                    id="pregnantAtQuitDate"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    label={'Pregnant at Quit Date'}
                                    radioButtonPick={pregnantAtQuitDate || RADIO_BUTTON_NO}
                                    thirdButtonText={'Not Known'}
                                    onChange={(option) => setPregnantAtQuitDate(option)}
                                />
                            </div>
                        </div>

                        <div className={form.formColumn}>
                            <div className={form.baseMargin}>
                                <DateSelect
                                    value={newEntry.quitDate}
                                    label="Quit Date"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    isDefault={true}
                                    mandatory={true}
                                    error={errors.quitDate}
                                    onDateChange={(res) => onDateChange('quitDate', res)}
                                />
                            </div>

                            <div>
                                <NoYesOtherRadioPicker
                                    id="entitledToFreePrescription"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    label={'Entitled to Free Prescription'}
                                    radioButtonPick={entitledToFreePrescription || RADIO_BUTTON_NO}
                                    thirdButtonText={'Not Known'}
                                    onChange={(option) => setEntitledToFreePrescription(option)}
                                />
                            </div>

                            <DDLOptionPicker
                                label={'Using e-cigarette'}
                                id={'usingECigaretteId'}
                                key={isClearUsingECigarettes}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                menuItems={arrayUsingECigarettes || []}
                                chosenName={newEntry.usingECigaretteName}
                                chosenId={newEntry.usingECigaretteId}
                                onChange={(chosenId) => {
                                    clearError('usingECigaretteId');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        usingECigaretteId: chosenId
                                    }));
                                }}></DDLOptionPicker>
                            {errors.usingECigaretteId.error && (
                                <div className={form.selectInputError}>
                                    <IconError text={errors.usingECigaretteId} />
                                </div>
                            )}

                            <div>
                                <PolarisSwitchSet
                                    id="quitStatus"
                                    label="Quit Status"
                                    leftLabel="Inactive"
                                    rightLabel="Active"
                                    checked={newEntry.quitStatus}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    onSwitch={(e) => {
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            quitStatus: e.target.checked
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <FormActions
                    onClose={onSubmitQuitAttempt}
                    onCancel={onCancelQuitAttempt}
                    btnText={'Save'}
                    disabled={!hasRole(acceptedRoles, roles)}
                    customClass="noTopBorder"
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default QuitAttempt;

QuitAttempt.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func
};
