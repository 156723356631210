import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';

import { hasRole, LOCAL_ADMIN, QUALITY, SUPERUSER } from '../../utils/userRoles';
import PolarisLink from '../ui/PolarisLink';

const AdminMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const navigate = useNavigate();

    const handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                navigate('/create_contract');
                break;
            case 1:
                navigate('/create_service');
                break;
            case 2:
                navigate('/create_team');
                break;
            case 3:
                break;
            case 4:
                navigate('/create_user');
                break;
            case 5:
                navigate('/configure_contract');
                break;
            case 6:
                navigate('/manage_communication');
                break;
            case 7:
                navigate('/manage_eligibility_groups');
                break;
            case 8:
                navigate('/manage_form');
                break;
            case 9:
                navigate('/manage_questionnaire');
                break;
            case 10:
                navigate('/manage_users');
                break;
            case 11:
                window.open(
                    'https://as-app-directus-polaris-trn-uksouth.azurewebsites.net/',
                    '_blank',
                    'noopener,noreferrer'
                );
                break;
            default:
                break;
        }
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!hasRole([SUPERUSER, QUALITY], roles)) <div></div>;
    return (
        <div>
            <PolarisLink label={'Admin'} onClick={handleClickListItem} />

            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}>
                <MenuItem
                    onClick={() => handleMenuItemClick(0)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Create Contract
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuItemClick(1)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Create Service
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(2)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Create Team
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(4)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Create User
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(5)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Configure Contract
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(6)}
                    disabled={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}>
                    Manage Communication
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(7)}
                    disabled={!hasRole([SUPERUSER], roles)}>
                    Manage Eligibility Groups
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(8)}
                    disabled={!hasRole([SUPERUSER], roles)}>
                    Manage Forms
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(9)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Manage Questionnaires
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(10)}
                    disabled={!hasRole([SUPERUSER, QUALITY], roles)}>
                    Manage Users
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuItemClick(11)}
                    disabled={!hasRole([SUPERUSER], roles)}>
                    Administer Common DDLs
                </MenuItem>
            </Menu>
        </div>
    );
};
export default AdminMenu;
