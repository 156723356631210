import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';

import FullProvisionAttendanceTable from './FullProvisionAttendanceTable';

import classes from '../../../app.module.css';

/**
 * Full Provision Attendance Dialog
 * See PPL-838 for details.
 *
 * This is shown when clicking on a provision see attendance link to show attendance of that provision see PPL-827
 *
 *
 * @returns {JSX.Element}
 */
const FullProvisionAttendanceDialog = (rows) => {
    const handleClose = () => {
        rows.setOpenFullProvisionDialog(false);
    };

    return (
        <div>
            <Dialog
                open={rows.openFullProvisionDialog}
                maxWidth="md"
                onClose={(_, reason) =>
                    reason === 'backdropClick' && rows.setOpenFullProvisionDialog(false)
                }
                fullWidth>
                <DialogTitle
                    id="alert-dialog-title"
                    className={classes.center}>{`Provision Booking Confirmation`}</DialogTitle>
                <DialogContent style={{ height: '725px' }}>
                    <Typography variant="h6" className={classes.center} component="div">
                        The following participants are currently booked on this course
                    </Typography>
                    <FullProvisionAttendanceTable rows={rows.rows || []} />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FullProvisionAttendanceDialog;
