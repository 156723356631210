import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import { selectIsSecondNatureReferralAttemptSuccessful } from '../../../../store/participantSelectors';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';

const StepsRow = ({ row, roles, onEdit, onDelete }) => {
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const acceptedDeleteRoles = [MANAGER, QUALITY, SUPERUSER];

    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );

    return (
        <TableRow hover className={classes.basicRowStripes}>
            <TableCell>{row.stepsDate}</TableCell>
            <TableCell align="center">{row.stepsValue}</TableCell>
            <TableCell align="right">
                <OnEditIcon
                    onEdit={() => onEdit(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                    active={!isReferralHistoryAttemptSuccessful}
                />
            </TableCell>
            <TableCell align="right">
                <OnDeleteIcon
                    onDelete={() => onDelete(row)}
                    roles={roles}
                    acceptedRoles={acceptedDeleteRoles}
                    active={!isReferralHistoryAttemptSuccessful}
                />
            </TableCell>
        </TableRow>
    );
};

export default StepsRow;

StepsRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};
