import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { Card, CardContent } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import FormHeader from '../layout/FormHeader';
import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';

import CourseInstanceManagement from './courseInstances/CourseInstanceManagement';
import CourseTemplateManagement from './courseTemplates/CourseTemplateManagement';
import CourseProvisionManagement from './provisions/CourseProvisionManagement';

const CourseManagement = () => {
    // LOCAL STATE
    const { state } = useLocation();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (SyntheticEvent, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // USEEFFECTS
    useEffect(() => {
        if (!state?.accordionPanel) {
            setExpanded('courses');
        } else {
            setExpanded(state.accordionPanel);
        }
    }, []);

    return (
        <Card data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Course Management'}></FormHeader>
                    <Accordion expanded={expanded === 'courses'} onChange={handleChange('courses')}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="courses-content"
                            id="courses-header">
                            <AccordionHeader>My Courses</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CourseInstanceManagement />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        slotProps={{ transition: { mountOnEnter: true } }}
                        expanded={expanded === 'templates'}
                        onChange={handleChange('templates')}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="templates-content"
                            id="templates-header">
                            <AccordionHeader>My Course Templates</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CourseTemplateManagement />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        slotProps={{ transition: { mountOnEnter: true } }}
                        expanded={expanded === 'provisions'}
                        onChange={handleChange('provisions')}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="provisions-content"
                            id="provisions-header">
                            <AccordionHeader>Provisions</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CourseProvisionManagement />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </CardContent>
        </Card>
    );
};

CourseManagement.propTypes = {
    accordionPanel: PropTypes.string
};

export default CourseManagement;
