import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';

import classes from '../../../../commonStyles/pdf.module.css';

const BarrierTable = ({ onIsLoaded }) => {
    const barrierRef = useRef(null);
    const [rows, setRows] = useState([]);

    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const barriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );

    useEffect(() => {
        if (barrierNameDetails?.length < 1) return;
        if (barriers?.length < 1) {
            onIsLoaded('barriers');
            return;
        }
        const data = barriers.map((el) => ({
            id: el.id,
            name: getNameFromId(barrierNameDetails, el.barrierId),
            priority: el?.priority || ' - ',
            startDate: el?.startDate ? reverseFormatDate(el.startDate) : ' - ',
            dueDate: el?.dueDate ? reverseFormatDate(el.dueDate) : ' - ',
            status: el?.completeDate ? 'Completed' : 'Current'
        }));
        setRows(data);
    }, [barriers, barrierNameDetails]);

    useEffect(() => {
        barrierRef.current?.id.includes(`--${rows.length - 1}`) && onIsLoaded('barriers');
    }, [barrierRef.current]);

    return (
        <div>
            <h3>Barriers</h3>
            <div className={classes.pdfTable}>
                <div className={classes.pdfTableHeaders}>
                    <span>Barrier Name</span>
                    <span>Priority</span>
                    <span>Start Date</span>
                    <span>Due Date</span>
                    <span>Status</span>
                </div>
                {rows.length > 0 && (
                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                ref={(el) => (barrierRef.current = el)}
                                key={el.id}
                                id={`${el.id}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.name}</span>
                                <span>{el.priority}</span>
                                <span>{el.startDate}</span>
                                <span>{el.dueDate}</span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BarrierTable;

BarrierTable.propTypes = {
    onIsLoaded: PropTypes.func
};
