import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { updatePoolApplicationRejected } from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import DDLMultiOptionPicker from '../../../ui/pickers/DDLMultiOptionPicker';

const CHARACTER_LIMIT = 500;

const PoolApplicationRejectEditor = ({
    open,
    onPoolApplicationRejectEditor,
    onPoolApplicationRejectCancelled,
    updatedPoolApplications,
    rejectedRow,
    onPoolRejectedUpdate
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    // State for create pool newEntry
    // This matches the payload sent to the backend
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        participantPoolApplicationId: null,
        reasonIds: null,
        notes: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [isOpen, setIsOpen] = React.useState(false);
    const [notes, setNotes] = useState('');
    const [rejectionReasonsDetails, setRejectionReasonsDetails] = useState([]);

    const [clearSelectedRejectReasons, setClearSelectedRejectReasons] = useState('1');

    const poolApplicationCancelled = () => {
        onPoolApplicationRejectCancelled(true, rejectedRow);
    };

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const loggedInUser = useSelector(selectLoggedInUser);
    const { poolAcceptedRejectionReasonsDetails, poolRequestedRejectionReasonsDetails } =
        useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    const handleRejectReasonsChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('rejectedReasonIds', chosenIds, { shouldValidate: true });
        setNewEntry((prev) => ({ ...prev, reasonIds: chosenIds }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, notes: e.target.value }));
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        onPoolApplicationRejectEditor(false);
        poolApplicationCancelled(rejectedRow);
        clearData();
        setNotes('');
    };

    const clearData = () => {
        setNewEntry(initialState);
        setNotes('');
        setClearSelectedRejectReasons(Math.random());
    };

    const onCreated = () => {
        onPoolApplicationRejectEditor(false);
        clearData();
    };

    // USE EFFECTS
    useEffect(() => {
        setClearSelectedRejectReasons(Math.random());
    }, []);

    useEffect(() => {
        const statusName =
            updatedPoolApplications.find((el) => el.id === rejectedRow.id)?.statusName || '';

        statusName.toLowerCase() === 'accepted'
            ? setRejectionReasonsDetails(poolAcceptedRejectionReasonsDetails)
            : setRejectionReasonsDetails(poolRequestedRejectionReasonsDetails);
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Pool application rejected has been updated`) {
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        onPoolRejectedUpdate(false);
        dispatch(
            updatePoolApplicationRejected({
                ...newEntry,
                participantPoolApplicationId: rejectedRow.participantPoolApplicationId
            })
        );
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <Dialog open={isOpen} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Pool Application Rejection</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <DDLMultiOptionPicker
                            label="Rejection Reason"
                            id="rejectedReasonIds"
                            key={clearSelectedRejectReasons}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            placeholder="Select Rejection Reasons"
                            menuItems={rejectionReasonsDetails}
                            error={errors.rejectedReasonIds}
                            {...register('rejectedReasonIds')}
                            onChange={(chosenIds) => handleRejectReasonsChange(chosenIds)}
                        />
                        <LabelledTextField
                            label={'Rejection Feedback'}
                            id={'notes'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            multiline
                            rows={5}
                            value={notes}
                            placeholder={'Enter rejection feedback'}
                            counter={'true'}
                            helperText={`${notes.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            onChange={handleNotesChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                            Update
                        </Button>
                        <Button onClick={handleCancel} color="primary" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    rejectedReasonIds: Yup.array().min(1, 'Please select at least one rejection reason.').nullable()
});

PoolApplicationRejectEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onPoolApplicationRejectEditor: PropTypes.func,
    onPoolApplicationRejectCancelled: PropTypes.func,
    updatedPoolApplications: PropTypes.array.isRequired,
    rejectedRow: PropTypes.object,
    onPoolRejectedUpdate: PropTypes.func
};

export default PoolApplicationRejectEditor;
