import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { loadParticipantModulesAttendedCount } from '../../../../store/courseService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import { submitReferralHistory } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { loadUsersByUserIds } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';

import ReferralHistory from './referralHistory/ReferralHistory';

import app from '../../../../app.module.css';
import local from '../wellbeing.module.css';

const SecondNature = () => {
    const dispatch = useDispatch();

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [createDisabled, setCreateDisabled] = useState(false);
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const referralHistory = useSelector(
        (state) => state.entities.participantService.referralHistory
    );
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const participantAttendedCount = useSelector(
        (state) => state.entities.courseService.participantModulesAttendedCount
    );
    const bloodTests = useSelector((state) => state.entities.participantService.bloodTests);
    const bmiEntries = useSelector((state) => state.entities.participantService.bmiEntries);

    useEffect(() => {
        dispatch(loadParticipantModulesAttendedCount(currentParticipant.id));
    }, []);

    useEffect(() => {
        if (!currentParticipant.id) return;
        if (
            !hasRole(acceptedRoles, roles) ||
            isReferralHistoryAttemptSuccessful ||
            currentParticipant.uniqueIdentifierType !== 'NHS_NUMBER' ||
            participantAttendedCount > 1 ||
            bmiEntries.length < 1 ||
            bloodTests?.length < 1 ||
            bloodTests.filter((el) => el.testType === 'HbA1c').length < 1
        ) {
            setCreateDisabled(true);
        }
        if (
            hasRole(acceptedRoles, roles) &&
            !isReferralHistoryAttemptSuccessful &&
            currentParticipant.uniqueIdentifierType === 'NHS_NUMBER' &&
            participantAttendedCount < 1 &&
            bmiEntries.length > 0 &&
            bloodTests?.length > 0 &&
            bloodTests.filter((el) => el.testType === 'HbA1c').length > 0
        )
            setCreateDisabled(false);
    }, [
        bloodTests,
        bmiEntries,
        participantAttendedCount,
        currentParticipant,
        isReferralHistoryAttemptSuccessful
    ]);

    useEffect(() => {
        if (!referralHistory || referralHistory.length < 1) return;
        const userIds = referralHistory.map((el) => el.userId);
        userIds.length > 0 && dispatch(loadUsersByUserIds(userIds));
    }, [referralHistory]);

    const onCreate = () => {
        dispatch(submitReferralHistory(currentParticipant.id));
    };

    return (
        <div className={local.secondNature}>
            <div className={app.sectionHeading}>Second Nature</div>
            <div className={local.contentContainer}>
                <div className={local.subSection}>
                    <Button
                        disabled={createDisabled}
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Create Second Nature Referral
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                </div>

                <ReferralHistory />
            </div>
        </div>
    );
};

export default SecondNature;
