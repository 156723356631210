import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from '@mui/material';

import '../../search/searchStyles/autoCompleteNameSearch.css';

const SchedulerSelector = ({
    id,
    heading,
    listItems,
    currentName = '',
    currentId = '',
    onUpdate,
    disabled
}) => {
    const onChange = (_, val) => {
        if (!val || val.id === currentId) return;
        onUpdate(val);
    };

    return (
        <div className="input-set auto-search-input">
            <label htmlFor={id}>{heading}</label>
            <div className="selector-input input">
                <Autocomplete
                    disablePortal
                    id={id}
                    key={Math.random().toString(36).slice(0, 5)}
                    disabled={disabled}
                    options={listItems || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => <TextField {...params} placeholder={currentName} />}
                    classes={{ listbox: 'menu-list' }}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default SchedulerSelector;

SchedulerSelector.propTypes = {
    id: PropTypes.string,
    heading: PropTypes.string,
    listItems: PropTypes.arrayOf(PropTypes.object),
    currentName: PropTypes.string,
    currentId: PropTypes.string,
    onUpdate: PropTypes.func,
    disabled: PropTypes.bool
};
