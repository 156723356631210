import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import {
    createPhysicalActivity,
    updatePhysicalActivity
} from '../../../../store/participantService';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import IconError from '../../../IconError';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';

import { checkDate, checkValue, initialErrorState, validate } from './validatePhysicalActivity';

import form from '../../../../commonStyles/formStyles.module.css';

const CreatePhysicalActivity = ({ onClose, roles, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        eventDate: new Date().toISOString().slice(0, 10),
        vigorousActivityMinutes: '',
        moderateActivityMinutes: '',
        walkingMinutes: '',
        participantId: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const msg = `Participant physical activity has been ${
        formType === 'create' ? 'added' : 'updated'
    }`;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        formType === 'create' && setNewEntry(initialState);
    }, []);

    useEffect(() => {
        if (Object.keys(row).length < 1) return;
        const formattedRow = {
            ...row,
            eventDate: row.eventDate.split('/').reverse().join('-')
        };
        setNewEntry(formattedRow);
    }, [row]);

    useEffect(() => {
        if (successMessage === msg) clearForm();
    }, [successMessage]);

    // EVENT HANDLERS

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onDateChange = (date) => {
        clearError('eventDate');
        setNewEntry((prev) => ({ ...prev, eventDate: date }));
        const newErrors = checkDate(date, errors);
        setErrors(newErrors);
    };

    const onActivityChange = (key, value) => {
        clearError(key);
        const mins = value.replace(/[^\d]/g, '').slice(0, 5);
        setNewEntry((prev) => ({ ...prev, [key]: mins }));
        const newErrors = checkValue(key, mins, errors);
        setErrors(newErrors);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const payload = { ...newEntry, participantId: currentParticipant?.id };
        formType === 'create'
            ? dispatch(createPhysicalActivity(payload, msg))
            : dispatch(updatePhysicalActivity(payload.id, payload));
        onFormExit();
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>
                {formType === 'create' ? 'Add a New Physical Activity' : 'Edit Physical Activity'}
            </h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={`${form.alignUp} ${form.optionInputWrapper}`}>
                            <DateSelect
                                value={newEntry.eventDate}
                                label="Event Date"
                                disabled={false}
                                isDefault={true}
                                mandatory={true}
                                error={errors.eventDate}
                                onDateChange={(res) => onDateChange(res)}
                            />
                        </div>

                        <div className={form.fullWidth}>
                            <div className={form.fullWidthSection}>
                                <LabelledTextField
                                    label={'Vigorous activity in past seven days (minutes)'}
                                    id={'vigorousActivityMinutes'}
                                    mandatory={true}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    value={newEntry.vigorousActivityMinutes}
                                    placeholder={'Enter value'}
                                    customClass="verticalAlign"
                                    labelClass="alignLabel"
                                    onChange={(e) => onActivityChange(e.target.id, e.target.value)}
                                />
                                {errors.vigorousActivityMinutes.error && (
                                    <div className={form.textInputError}>
                                        <IconError text={errors.vigorousActivityMinutes} />
                                    </div>
                                )}
                            </div>

                            <div className={form.fullWidthSection}>
                                <LabelledTextField
                                    label={'Moderate activity in past seven days (minutes)'}
                                    id={'moderateActivityMinutes'}
                                    mandatory={true}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    value={newEntry.moderateActivityMinutes}
                                    placeholder={'Enter value'}
                                    customClass="verticalAlign"
                                    labelClass="alignLabel"
                                    onChange={(e) => onActivityChange(e.target.id, e.target.value)}
                                />
                                {errors.moderateActivityMinutes.error && (
                                    <div className={form.textInputError}>
                                        <IconError text={errors.moderateActivityMinutes} />
                                    </div>
                                )}
                            </div>

                            <div className={form.fullWidthSection}>
                                <LabelledTextField
                                    label={'Walking in past seven days (minutes)'}
                                    id={'walkingMinutes'}
                                    mandatory={true}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    value={newEntry.walkingMinutes}
                                    placeholder={'Enter value'}
                                    customClass="verticalAlign"
                                    labelClass="alignLabel"
                                    onChange={(e) => onActivityChange(e.target.id, e.target.value)}
                                />
                                {errors.walkingMinutes.error && (
                                    <div className={form.textInputError}>
                                        <IconError text={errors.walkingMinutes} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText={formType === 'create' ? 'Save' : 'Update'}
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default CreatePhysicalActivity;

CreatePhysicalActivity.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};
