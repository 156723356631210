import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { loadForm, searchFormParticipant } from '../../store/participantService';
import { reverseFormatDate } from '../../utils/dateFunctions';

import classes from '../../commonStyles/tables.module.css';

const FormTableRow = ({ row }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentForm = useSelector((state) => state.entities.participantService.currentForm);

    const [formSelected, setFormSelected] = useState(false);

    useEffect(() => {
        if (formSelected) {
            navigate('/build_form');
        }
    }, [currentForm]);

    const onEditRow = (row) => {
        dispatch(loadForm(row.id));
        setFormSelected(true);
        const payload = {
            formId: row.id
        };
        dispatch(searchFormParticipant(payload));
    };

    return (
        <TableRow
            hover
            className={`${classes.tableRowStripes} ${classes.canOpen}`}
            onClick={() => onEditRow(row)}>
            <TableCell>{row.code}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{reverseFormatDate(row.dateCreated)}</TableCell>
            <TableCell>{row.inactive ? 'Inactive' : 'Active'}</TableCell>
        </TableRow>
    );
};

export default FormTableRow;

FormTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
