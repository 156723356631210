import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import {
    selectCurrentParticipant,
    selectQuestionnaireAnswers,
    selectQuestionnaireContracts,
    selectQuestionnaires
} from '../../../store/participantSelectors';
import {
    loadQuestionnaireAnswersByParticipant,
    loadQuestionnaireContractByContract,
    loadQuestionnaires
} from '../../../store/participantService';
import { selectUserRoles } from '../../../store/userSelectors';
import { formatTime } from '../../../utils/dateFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import QuestionnaireAnswer from './QuestionnaireAnswer';
import QuestionnaireTable from './QuestionnaireTable';

import classes from '../questionnaire/questionnaire.module.css';

const Questionnaire = () => {
    // REFERENCE
    const surveyRef = useRef(null);

    //DISPATCH
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [questionnaireId, setQuestionnaireId] = useState('');
    const [questionnaireMenuItems, setQuestionnaireMenuItems] = useState([]);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const questionnaires = useSelector(selectQuestionnaires);
    const questionnaireAnswers = useSelector(selectQuestionnaireAnswers);
    const questionnaireContracts = useSelector(selectQuestionnaireContracts);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadQuestionnaires());
        dispatch(loadQuestionnaireContractByContract(currentParticipant.contractId));
    }, []);

    useEffect(() => {
        if (showForm) return;
        setRows([]);
        if (currentParticipant?.id) {
            dispatch(loadQuestionnaireAnswersByParticipant(currentParticipant.id));
        }
    }, [showForm, currentParticipant]);

    useEffect(() => {
        if (questionnaireAnswers && questionnaireAnswers?.length > 0) {
            const rows = questionnaireAnswers.map((el) => ({
                ...el,
                title: questionnaires.find((entry) => entry.id === el.questionnaireId)?.title || '',
                timeCompleted: formatTime(el?.dateCompleted) || ''
            }));
            setRows(rows);
        }

        setQuestionnaireMenuItems(
            questionnaires
                .filter((el) => !el.inactive)
                .filter((el) =>
                    questionnaireContracts.questionnaires?.some((contract) => contract.id === el.id)
                )
                .filter((el) => {
                    const incompleteQuestionnaire =
                        Array.isArray(questionnaireAnswers) &&
                        questionnaireAnswers.find(
                            (entry) => el.id === entry.questionnaireId && entry.draft
                        );
                    return !incompleteQuestionnaire;
                })
                .map((el) => ({
                    ...el,
                    name: el.title
                }))
                .sort((a, b) => a.name?.trim().localeCompare(b.name?.trim()))
        );
    }, [questionnaires, questionnaireContracts, questionnaireAnswers]);

    useEffect(() => {
        if (successMessage === 'Questionnaire results have been saved successfully') {
            dispatch(loadQuestionnaireAnswersByParticipant(currentParticipant.id));
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onStartQuestionnaire = () => {
        const row = {
            questionnaireId: questionnaireId
        };
        setSelectedRow(row);
        setShowForm(true);
        setQuestionnaireId('');
    };

    const onEditQuestionnaire = (row) => {
        setSelectedRow(row);
        setShowForm(true);

        surveyRef.current.scrollIntoView();
    };

    const handleQuestionnaireIdChange = (questionnaireId) => {
        setQuestionnaireId(questionnaireId);
    };

    return (
        <div className={classes.questionnaires}>
            <div className={classes.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No questionnaires found" />
                ) : (
                    <QuestionnaireTable
                        rows={rows}
                        roles={roles}
                        showForm={showForm}
                        onEdit={onEditQuestionnaire}
                    />
                )}
            </div>
            <div>
                <DDLOptionPicker
                    id="questionnaireId"
                    label={'Questionnaire (In-Progress questionnaires will be omitted)'}
                    parentMessage={
                        (!questionnaireId &&
                            'Please select a questionnaire from the list above to start it') ||
                        ''
                    }
                    mandatory={true}
                    data-testid="questionnaire_ddl_option_picker"
                    menuItems={questionnaireMenuItems}
                    chosenId={questionnaireId}
                    chosenName={
                        questionnaires.find((questionnaire) => questionnaire.id === questionnaireId)
                            ?.title
                    }
                    onChange={handleQuestionnaireIdChange}></DDLOptionPicker>
                <Button
                    disabled={!hasRole(acceptedRoles, roles) || !questionnaireId || showForm}
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onStartQuestionnaire}>
                    Start Questionnaire
                </Button>
                <div ref={surveyRef}>
                    {showForm && (
                        <QuestionnaireAnswer
                            row={selectedRow}
                            onClose={() => setShowForm(false)}
                            roles={roles}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Questionnaire;
