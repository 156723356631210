import { isDateEarlier, isDateFuture } from '../../../../utils/dateFunctions';
import { getSelectValueMessage } from '../../../../utils/formValidation/errorMessageFunctions';

const FUTURE_DATE_ERROR = 'Date must be in the past or present';
const DATE_EARLIER_THAN_QUIT_DATE = 'Date cannot be earlier than quit attempt date';
const NO_VALUE_SELECTED = getSelectValueMessage();

export const initialErrorState = {
    eventDate: { error: false, message: '' },
    interventionTypeId: { error: false, message: '' },
    selfReportedQuitId: { error: false, message: '' },
    coResultId: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.interventionTypeId) {
        newErrors = {
            ...newErrors,
            interventionTypeId: { error: true, message: NO_VALUE_SELECTED }
        };
        isValid = false;
    }
    if (newEntry.arraySelfReportedQuitLength > 0 && !newEntry.selfReportedQuitId) {
        newErrors = {
            ...newErrors,
            selfReportedQuitId: { error: true, message: NO_VALUE_SELECTED }
        };
        isValid = false;
    }

    newErrors = checkDate(newEntry.eventDate, newEntry.attemptQuitDate, newErrors);
    if (newErrors.eventDate.error) isValid = false;

    return { isValid, newErrors };
};

export const checkDate = (date, quitDate, errors) => {
    let eventDate;
    if (isDateFuture(date)) eventDate = { error: true, message: FUTURE_DATE_ERROR };
    else if (isDateEarlier(quitDate, date))
        eventDate = { error: true, message: DATE_EARLIER_THAN_QUIT_DATE };
    else eventDate = initialErrorState.eventDate;
    return { ...errors, eventDate };
};
