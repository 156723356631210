export const initialErrorState = {
    chosenClaimStatusUploaderType: { error: true, message: '' },
    contractId: { error: true, message: '' },
    claimStatusId: { error: true, message: '' },
    claimTypeId: { error: true, message: '' }
};

export const validate = (chosenClaimStatusUploaderType, uploadData) => {
    let isValid = true;

    if (!chosenClaimStatusUploaderType?.id) {
        isValid = false;
    }

    if (!('contractId' in uploadData) || !uploadData.contractId?.value) {
        isValid = false;
    }

    if (chosenClaimStatusUploaderType?.name === 'Proceed') {
        if (!('claimStatusId' in uploadData) || !uploadData.claimStatusId?.value) {
            isValid = false;
        }
        if (!('claimTypeId' in uploadData) || !uploadData.claimTypeId?.value) {
            isValid = false;
        }
    }

    return isValid;
};
