import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import ConfigureContract from './components/administration/configureContract/ConfigureContract';
import Claims from './components/claims/Claims';
import ManageCommunication from './components/communication/ManageCommunication';
import CreateContract from './components/contract/CreateContract';
import AllActiveCourseTemplateManagement from './components/courses/allActiveCourseTemplates/AllActiveCourseTemplateManagement';
import CourseCalendar from './components/courses/CourseCalendar';
import CourseInformation from './components/courses/CourseInformation';
import CreateCourse from './components/courses/courseInstances/CreateCourse';
import EditCourse from './components/courses/courseInstances/EditCourse';
import CourseManagement from './components/courses/CourseManagement';
import CreateCourseTemplate from './components/courses/courseTemplates/CreateCourseTemplate';
import EditCourseTemplate from './components/courses/courseTemplates/EditCourseTemplate';
import CreateProvision from './components/courses/provisions/CreateProvision';
import EditProvision from './components/courses/provisions/EditProvision';
import Documents from './components/documents/Documents';
import EligibilityGroupManagement from './components/eligibilityGroup/EligibilityGroupManagenment';
import FormBuilder from './components/form/FormBuilder';
import FormManagement from './components/form/FormManagement';
import Home from './components/home/Home';
import NotFound from './components/notFound/NotFound';
import Appointments from './components/participant/appointments/Appointments';
import EditCommunicationGroups from './components/participant/communication/groupCommunication/EditCommunicationGroups';
import GroupEmail from './components/participant/communication/groupCommunication/GroupEmail';
import GroupSMS from './components/participant/communication/groupCommunication/GroupSMS';
import ManageCommunicationGroups from './components/participant/communication/groupCommunication/ManageCommunicationGroups';
import EmailCommunication from './components/participant/communication/singleParticipantCommunication/EmailCommunication';
import LetterCommunication from './components/participant/communication/singleParticipantCommunication/LetterCommunication';
import SMSCommunication from './components/participant/communication/singleParticipantCommunication/SMSCommunication';
import CreateParticipant from './components/participant/createParticipant/CreateParticipant';
import EditParticipant from './components/participant/EditParticipant';
import MandatoryForm from './components/participant/form/MandatoryForm';
import ParticipantAdvancedSearch from './components/participant/ParticipantAdvancedSearch';
import ParticipantCalendar from './components/participant/ParticipantCalendar';
import ParticipantInformation from './components/participant/participantInformation/ParticipantInformation';
import HealthInformation from './components/participant/personal/HealthInformation';
import CreateFinancialRequest from './components/participant/requests/financialRequests/CreateFinancialRequest';
import RequestInformation from './components/participant/requests/RequestInformation';
import ParticipantSearchResults from './components/participant/searchResults/ParticipantSearchResults';
import QuestionnaireBuilder from './components/questionnaire/QuestionnaireBuilder';
import QuestionnaireManagement from './components/questionnaire/QuestionnaireManagement';
import CreateBusiness from './components/recruitment/businesses/myBusinesses/CreateBusiness';
import EditBusiness from './components/recruitment/businesses/myBusinesses/EditBusiness';
import RecruitmentManagement from './components/recruitment/RecruitmentManagement';
import CreateVacancy from './components/recruitment/vacanciesAndPooling/vacancies/CreateVacancy';
import EditVacancy from './components/recruitment/vacanciesAndPooling/vacancies/EditVacancy';
import VacancySubmissionManagement from './components/recruitment/VacancySubmissionManagement';
import SchedulingAssistant from './components/scheduling/SchedulingAssistant';
import CreateService from './components/service/CreateService';
import TBC from './components/tbc/TBC';
import CreateTeam from './components/team/CreateTeam';
import TimeLine from './components/timeline/TimeLine';
import CreateUser from './components/user/CreateUser';
import UserCalendar from './components/user/UserCalendar';
import ManageUsers from './components/user/usersManagement/ManageUsers';
import { msalInstance } from './masl/authConfig';
import App from './App';
import { store } from './store';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App instance={msalInstance} />}>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="all_active_course_templates"
                            element={<AllActiveCourseTemplateManagement />}
                        />
                        <Route path="appointments" element={<Appointments />} />
                        <Route path="build_form" element={<FormBuilder />} />
                        <Route path="build_questionnaire" element={<QuestionnaireBuilder />} />
                        <Route path="claims" element={<Claims />} />
                        <Route path="configure_contract" element={<ConfigureContract />} />
                        <Route path="course_calendar" element={<CourseCalendar />} />
                        <Route path="course_information" element={<CourseInformation />} />
                        <Route path="course_management" element={<CourseManagement />} />
                        <Route path="create_business" element={<CreateBusiness />} />
                        <Route path="create_contract" element={<CreateContract />} />
                        <Route path="create_course" element={<CreateCourse />} />
                        <Route path="create_course_template" element={<CreateCourseTemplate />} />
                        <Route
                            path="manage_eligibility_groups"
                            element={<EligibilityGroupManagement />}
                        />
                        <Route
                            path="create_financial_request"
                            element={<CreateFinancialRequest />}
                        />
                        <Route path="create_participant" element={<CreateParticipant />} />
                        <Route path="create_provision" element={<CreateProvision />} />
                        <Route path="create_service" element={<CreateService />} />
                        <Route path="create_team" element={<CreateTeam />} />
                        <Route path="create_vacancy" element={<CreateVacancy />} />
                        <Route path="create_user" element={<CreateUser />} />
                        <Route path="documents" element={<Documents />} />
                        <Route path="edit_business" element={<EditBusiness />} />
                        <Route
                            path="edit_communication_groups"
                            element={<EditCommunicationGroups />}
                        />
                        <Route path="edit_course" element={<EditCourse />} />
                        <Route path="edit_course_template" element={<EditCourseTemplate />} />
                        <Route path="edit_participant" element={<EditParticipant />} />
                        <Route path="edit_provision" element={<EditProvision />} />
                        <Route path="edit_vacancy" element={<EditVacancy />} />
                        <Route path="email_communication" element={<EmailCommunication />} />
                        <Route path="financial_information" element={<RequestInformation />} />
                        <Route path="group_sms" element={<GroupSMS />} />
                        <Route path="group_email" element={<GroupEmail />} />
                        <Route path="health_information" element={<HealthInformation />} />
                        <Route path="home" element={<Home />} />
                        <Route path="letter_communication" element={<LetterCommunication />} />
                        <Route path="manage_communication" element={<ManageCommunication />} />
                        <Route path="manage_form" element={<FormManagement />} />
                        <Route path="manage_groups" element={<ManageCommunicationGroups />} />
                        <Route path="manage_questionnaire" element={<QuestionnaireManagement />} />
                        <Route path="manage_users" element={<ManageUsers />} />
                        <Route path="mandatory_form" element={<MandatoryForm />} />
                        <Route path="participant_calendar" element={<ParticipantCalendar />} />
                        <Route
                            path="participant_information"
                            element={<ParticipantInformation />}
                        />
                        <Route path="search_participant" element={<ParticipantAdvancedSearch />} />
                        <Route
                            path="participant_search_results"
                            element={<ParticipantSearchResults />}
                        />
                        <Route path="recruitment_management" element={<RecruitmentManagement />} />
                        <Route path="scheduling_assistant" element={<SchedulingAssistant />} />
                        <Route path="sms_communication" element={<SMSCommunication />} />
                        <Route path="tbc" element={<TBC />} />
                        <Route path="timeline" element={<TimeLine />} />
                        <Route path="user_calendar" element={<UserCalendar />} />
                        <Route
                            path="vacancy_pooling_management"
                            element={<VacancySubmissionManagement />}
                        />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </LocalizationProvider>
    </Provider>
);
