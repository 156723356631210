import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import {
    clearPoolApplicationsForOwners,
    clearPoolsForOwners,
    deleteVacancy,
    loadBusinessRecord,
    setCurrentVacancy
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';
import vacancyClasses from '../../recruitmentStyles/vacancy.module.css';

const VacancyTableRow = ({ row }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { jobSectorDetails } = useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    function onManageVacancy() {
        dispatch(clearPoolsForOwners());
        dispatch(clearPoolApplicationsForOwners());
        dispatch(loadBusinessRecord(row.businessRecordId));
        dispatch(setCurrentVacancy(row));

        navigate('/vacancy_pooling_management', {
            state: {
                accordionPanel: 'vacancyDetails',
                accordionPanelFrom: 'pooling',
                vacancy: row
            }
        });
    }

    const onDeleteVacancy = () => {
        dispatch(deleteVacancy(row.id));
    };

    const onEditRow = (row) => {
        dispatch(setCurrentVacancy(row));
        navigate('/edit_vacancy', {
            state: {
                id: row.id,
                accordionPanel: 'pooling'
            }
        });
    };

    // RENDER
    return (
        <>
            <TableRow key={row.id} hover className={classes.basicRowStripes}>
                <TableCell
                    className={vacancyClasses.vacancyCodeLink}
                    onClick={onManageVacancy}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    style={{
                        color: `${POLARIS_ROYAL_BLUE}`,
                        cursor: 'pointer'
                    }}>
                    {row.code}
                </TableCell>
                <TableCell>{row.businessName}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.adviser}</TableCell>
                <TableCell>{row.postcode ? row.postcode : 'Remote'}</TableCell>
                <TableCell>{`${row.numberOfPositions - row.jobsOffered} / ${
                    row.numberOfPositions
                }`}</TableCell>
                <TableCell>{getNameFromId(jobSectorDetails, row.sectorId)}</TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEditRow(row)}
                        roles={loggedInUser.roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
                <TableCell align="right">
                    <OnDeleteIcon
                        onDelete={onDeleteVacancy}
                        roles={loggedInUser.roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default VacancyTableRow;

VacancyTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string)
};
