import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    clearBenefitCode,
    clearJobCentre,
    clearJobReadiness,
    clearReadinessToChange,
    clearRightToWorkDocType,
    clearTimeUnemployed,
    loadBenefitCodeDetails,
    loadJobCentreDetails,
    loadJobReadinessDetails,
    loadReadinessToChangeDetails,
    loadRightToWorkDocTypes,
    loadTimeUnemployedDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    loadParticipantEmployability,
    loadParticipantJobGoals,
    updateParticipantEmployability
} from '../../../store/participantService';
import { getFutureDate } from '../../../utils/dateFunctions';
import { getConfiguredItems, getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import { EMAIL_REGEXP } from '../../../validation/validation';
import LabelledEmailText from '../../ui/editors/LabelledEmailText';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';
import JobGoals from '../jobGoals/JobGoals';

import OccManagement from './occ/OccManagement';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const JOB_CENTRE_PLUS_NAME_LENGTH = 100; // See PPL-362
const JOB_CENTRE_PLUS_EMAIL_LENGTH = 50; // See PPL-362

/**
 * Edit employment details for a participant.
 *
 * See PPL-362
 *
 * @returns {JSX.Element}
 */
const Employability = () => {
    // LOCAL STATE

    // State newEntry for employability
    // This is the data that will be sent to the API when saving employability details.
    // Changes to the data during editing are made to the newEntry object
    // using change handlers.
    const initialNewEntryState = {
        id: null,
        participantId: '',
        allowMandatoryActivities: false, // Default
        jobReadinessId: '',
        readinessToChangeId: '',
        jobCentreId: '',
        jobCentrePlusContactName: '',
        jobCentrePlusContactEmail: '',
        timeUnemployedId: '',
        benefitCodeId: '',
        jobGoals: [],
        rightToWorkDocumentTypeId: '',
        rightToWorkDocumentIdNumber: '',
        rightToWorkDocumentExpiryDate: ''
    };
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialNewEntryState);

    const [jobReadiness, setJobReadiness] = useState('');
    const [readinessToChange, setReadinessToChange] = useState('');
    const [jobCentre, setJobCentre] = useState('');
    const [timeUnemployed, setTimeUnemployed] = useState('');
    const [benefitCode, setBenefitCode] = useState('');
    const [rightToWorkDocumentType, setRightToWorkDocumentType] = useState('');

    const [jobReadinessId, setJobReadinessId] = useState('');
    const [readinessToChangeId, setReadinessToChangeId] = useState('');
    const [jobCentreId, setJobCentreId] = useState('');
    const [timeUnemployedId, setTimeUnemployedId] = useState('');
    const [benefitCodeId, setBenefitCodeId] = useState('');
    const [rightToWorkDocumentTypeId, setRightToWorkDocumentTypeId] = useState('');

    const [arrayJobReadiness, setArrayJobReadiness] = useState([]);
    const [arrayReadinessToChange, setArrayReadinessToChange] = useState([]);
    const [arrayJobCentres, setArrayJobCentres] = useState([]);
    const [arrayTimeUnemployed, setArrayTimeUnemployed] = useState([]);
    const [arrayBenefitCodes, setArrayBenefitCodes] = useState([]);
    const [arrayRightToWorkDocumentTypes, setArrayRightToWorkDocumentTypes] = useState([]);

    const [isClearJobReadiness, setIsClearJobReadiness] = useState('0');
    const [isClearReadinessToChange, setIsClearReadinessToChange] = useState('1');
    const [isClearJobCentre, setIsClearJobCentre] = useState('2');
    const [isClearTimeUnemployed, setIsClearTimeUnemployed] = useState('3');
    const [isClearBenefitCode, setIsClearBenefitCode] = useState('4');
    const [isClearRightToWorkDocumentType, setIsClearRightToWorkDocumentType] = useState('5');

    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(validationSchema(arrayRightToWorkDocumentTypes))
    });

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentParticipantJobGoals = useSelector(
        (state) => state.entities.participantService.currentParticipantJobGoals
    );
    // this has PT job goals in it - but only if it has a PT entry
    const dbEmployabilityInformation = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );
    const {
        jobReadinessDetails,
        readinessToChangeDetails,
        jobCentreDetails,
        timeUnemployedDetails,
        benefitCodeDetails,
        rightToWorkDocTypes
    } = useSelector((state) => state.entities.directusService);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    const dispatch = useDispatch();

    // EVENT HANDLERS

    const handleAllowMandatoryActivitiesChange = (value) => {
        setNewEntry((prev) => ({
            ...prev,
            allowMandatoryActivities: value
        }));
    };

    const handleEmployabilityMetricSelectionChange =
        (employabilityMetricId, setEmployabilityMetricId) => (chosenId) => {
            setEmployabilityMetricId(chosenId);
            if (chosenId !== '') {
                setNewEntry((prev) => ({
                    ...prev,
                    [employabilityMetricId]: chosenId
                }));
            } else {
                setNewEntry((prev) => ({
                    ...prev,
                    [employabilityMetricId]: null
                }));
            }
            setValue(employabilityMetricId, chosenId, { shouldValidate: true });
            if (employabilityMetricId === 'rightToWorkDocumentTypeId') {
                setRightToWorkDocumentType(
                    arrayRightToWorkDocumentTypes.find((el) => el.id === chosenId)
                );
            }
        };

    const handleEmployabilityMetricEditChange = (employabilityMetricId) => (e) => {
        setNewEntry((prev) => ({
            ...prev,
            [employabilityMetricId]: e.target.value
        }));
        setValue(employabilityMetricId, e.target.value, { shouldValidate: true });
    };

    /**
     * Add blank goal card to goals
     */
    const addGoal = () => {
        // If there is already a blank goal then don't add another
        // Check for 'blank' sectorId
        if (newEntry.jobGoals.some((c) => !c.sectorId)) {
            // Show warning that there is already an incomplete card.
            dispatch(setErrorMessage(`Cannot add another blank. Please complete existing card.`));
            return;
        }

        // Add blank goal card to newEntry
        setNewEntry((prev) => ({
            // Add blank goal card
            ...prev,
            jobGoals: [
                ...newEntry.jobGoals,
                {
                    participantId: currentParticipant?.id,
                    id: uuid()
                }
            ]
        }));
    };

    const deleteGoal = (goal) => {
        setNewEntry((prev) => ({
            ...prev,
            jobGoals: newEntry.jobGoals.filter((el) => el.id !== goal.id)
        }));
    };

    const updateGoal = (goal) => {
        setNewEntry((prev) => ({
            ...prev,
            jobGoals: newEntry.jobGoals.map((el) => (el.id === goal.id ? goal : el))
        }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (currentParticipant) {
            if (!newEntry.participantId) {
                setNewEntry((prev) => ({
                    ...prev,
                    participantId: currentParticipant.id
                }));
            }
            dispatch(loadParticipantEmployability(currentParticipant.id));
        }
    }, [currentParticipant.id]);

    // Set newEntry to default if new participant
    useEffect(() => {
        // If mandatory activities is null, set to false (default)
        if (dbEmployabilityInformation?.allowMandatoryActivities === null) {
            dbEmployabilityInformation.allowMandatoryActivities = false;
        }
        if (
            !Object.keys(dbEmployabilityInformation).length ||
            dbEmployabilityInformation?.jobGoals?.length < 1
        ) {
            dispatch(loadParticipantJobGoals(currentParticipant?.id));
        }

        if (Object.keys(dbEmployabilityInformation).length === 0) {
            // If just a blank object, set newEntry to default
            setNewEntry(initialNewEntryState);
        } else {
            setNewEntry(dbEmployabilityInformation);
        }
        dispatch(clearJobReadiness());
        dispatch(clearReadinessToChange());
        dispatch(clearJobCentre());
        dispatch(clearTimeUnemployed());
        dispatch(clearBenefitCode());
        dispatch(clearRightToWorkDocType());

        setJobReadiness('');
        setReadinessToChange('');
        setJobCentre('');
        setTimeUnemployed('');
        setBenefitCode('');
        setRightToWorkDocumentType('');

        setJobReadinessId(dbEmployabilityInformation.jobReadinessId);
        setReadinessToChangeId(dbEmployabilityInformation.readinessToChangeId);
        setJobCentreId(dbEmployabilityInformation.jobCentreId);
        setTimeUnemployedId(dbEmployabilityInformation.timeUnemployedId);
        setBenefitCodeId(dbEmployabilityInformation.benefitCodeId);
        setRightToWorkDocumentTypeId(dbEmployabilityInformation.rightToWorkDocumentTypeId);

        setArrayJobReadiness([]);
        setArrayReadinessToChange([]);
        setArrayJobCentres([]);
        setArrayTimeUnemployed([]);
        setArrayBenefitCodes([]);
        setArrayRightToWorkDocumentTypes([]);

        setIsClearJobReadiness(Math.random());
        setIsClearReadinessToChange(Math.random());
        setIsClearJobCentre(Math.random());
        setIsClearTimeUnemployed(Math.random());
        setIsClearBenefitCode(Math.random());
        setIsClearRightToWorkDocumentType(Math.random());

        dispatch(loadJobReadinessDetails());
        dispatch(loadReadinessToChangeDetails());
        dispatch(loadJobCentreDetails());
        dispatch(loadTimeUnemployedDetails());
        dispatch(loadBenefitCodeDetails());
        dispatch(loadRightToWorkDocTypes());
    }, [dbEmployabilityInformation, currentParticipant?.id]);

    useEffect(() => {
        if (currentParticipantJobGoals.length > 0)
            setNewEntry((prev) => ({ ...prev, jobGoals: currentParticipantJobGoals }));
    }, [currentParticipantJobGoals]);

    useEffect(() => {
        if (jobReadinessDetails?.length && arrayJobReadiness?.length < 1) {
            if (dbEmployabilityInformation?.jobReadinessId) {
                setJobReadiness(
                    getNameFromId(jobReadinessDetails, dbEmployabilityInformation.jobReadinessId)
                );
            }
            setArrayJobReadiness(
                getConfiguredItems(jobReadinessDetails, currentParticipant?.contractId, dispatch)
            );
        }
    }, [jobReadinessDetails]);

    /**
     * readinessToChangeDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (readinessToChangeDetails?.length && arrayReadinessToChange?.length < 1) {
            if (dbEmployabilityInformation?.readinessToChangeId) {
                setReadinessToChange(
                    getNameFromId(
                        readinessToChangeDetails,
                        dbEmployabilityInformation.readinessToChangeId
                    )
                );
            }
            setArrayReadinessToChange(
                getConfiguredItems(
                    readinessToChangeDetails,
                    currentParticipant.contractId,
                    dispatch
                )
            );
        }
    }, [readinessToChangeDetails]);

    /**
     * jobCentreDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (jobCentreDetails?.length && arrayJobCentres?.length < 1) {
            if (dbEmployabilityInformation?.jobCentreId) {
                setJobCentre(
                    getNameFromId(jobCentreDetails, dbEmployabilityInformation?.jobCentreId)
                );
            }
            setArrayJobCentres(
                getConfiguredItems(jobCentreDetails, currentParticipant?.contractId, dispatch)
            );
        }
    }, [jobCentreDetails]);

    /**
     * timeUnemployedDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        // Get relevant data from back end
        if (timeUnemployedDetails?.length && arrayTimeUnemployed?.length < 1) {
            if (dbEmployabilityInformation?.timeUnemployedId) {
                setTimeUnemployed(
                    getNameFromId(
                        timeUnemployedDetails,
                        dbEmployabilityInformation.timeUnemployedId
                    )
                );
            }
            setArrayTimeUnemployed(
                getConfiguredItems(timeUnemployedDetails, currentParticipant?.contractId, dispatch)
            );
        }
    }, [timeUnemployedDetails]);

    /**
     * benefitCodeDetails has changed so check to see if we can populate fields.
     */
    useEffect(() => {
        if (benefitCodeDetails?.length && arrayBenefitCodes?.length < 1) {
            if (dbEmployabilityInformation?.benefitCodeId) {
                setBenefitCode(
                    getNameFromId(benefitCodeDetails, dbEmployabilityInformation?.benefitCodeId)
                );
            }
            setArrayBenefitCodes(
                getConfiguredItems(benefitCodeDetails, currentParticipant?.contractId, dispatch)
            );
        }
    }, [benefitCodeDetails]);

    useEffect(() => {
        if (rightToWorkDocTypes?.length && arrayRightToWorkDocumentTypes?.length < 1) {
            if (dbEmployabilityInformation?.rightToWorkDocumentTypeId) {
                setRightToWorkDocumentType(
                    getNameFromId(
                        rightToWorkDocTypes,
                        dbEmployabilityInformation?.rightToWorkDocumentTypeId
                    )
                );
                setNewEntry((prev) => ({
                    ...prev,
                    rightToWorkDocumentTypeId: dbEmployabilityInformation?.rightToWorkDocumentTypeId
                }));
            }
            setArrayRightToWorkDocumentTypes(
                getConfiguredItems(rightToWorkDocTypes, currentParticipant?.contractId, dispatch)
            );
        }
    }, [rightToWorkDocTypes]);

    useEffect(() => {
        if (successMessage === 'Participant employability details have been updated') {
            setIsUpdateButtonDisabled(false);
        }
    }, [successMessage]);

    /**
     * Check if there are any incomplete goal cards
     * @returns {boolean} - true if there are incomplete cards
     */
    const hasIncompleteCard = () => {
        return newEntry.jobGoals.some((c) => !c.sectorId);
    };

    const updateDB = () => {
        // Ensure newEntry has currentParticipant.id
        setNewEntry((prev) => ({
            ...prev,
            participantId: currentParticipant.id
        }));
        const payload = { ...newEntry, participantId: currentParticipant?.id };
        dispatch(updateParticipantEmployability(payload));
    };

    // FORM SUBMIT

    const onSubmit = () => {
        if (hasIncompleteCard()) {
            dispatch(setErrorMessage(`Cannot save. Please complete all job goal cards.`));
        } else {
            setIsUpdateButtonDisabled(true);
            updateDB();
        }
    };

    let content;
    if (jobReadinessDetails?.length < 1) content = 'No job readiness details';
    if (readinessToChangeDetails?.length < 1) content = 'No readiness to change details';
    if (jobCentreDetails?.length < 1) content = 'No job centre details';
    if (timeUnemployedDetails?.length < 1) content = 'No time unemployed details';
    if (benefitCodeDetails?.length < 1) content = 'No benefit code details';
    if (rightToWorkDocTypes?.length < 1) content = 'No right to work document types';
    if (Object.keys(currentParticipant)?.length < 1) content = 'No current participant';
    if (arrayJobReadiness?.length < 1) content = 'No job readiness options';
    if (arrayReadinessToChange?.length < 1) content = 'No readiness to change options';
    if (arrayJobCentres?.length < 1) content = 'No job centre options';
    if (arrayTimeUnemployed?.length < 1) content = 'No time unemployed options';
    if (arrayBenefitCodes?.length < 1) content = 'No benefit code options';
    if (arrayRightToWorkDocumentTypes?.length < 1) content = 'No right to work document types';

    // RENDER

    if (
        jobReadinessDetails?.length < 1 ||
        readinessToChangeDetails?.length < 1 ||
        jobCentreDetails?.length < 1 ||
        timeUnemployedDetails?.length < 1 ||
        benefitCodeDetails?.length < 1 ||
        Object.keys(currentParticipant)?.length < 1 ||
        arrayJobReadiness?.length < 1 ||
        arrayReadinessToChange?.length < 1 ||
        arrayJobCentres?.length < 1 ||
        arrayTimeUnemployed?.length < 1 ||
        arrayBenefitCodes?.length < 1 ||
        arrayRightToWorkDocumentTypes?.length < 1
    ) {
        return <LoadingSpinner content={content} />;
    }

    return (
        <div className={form.formWrapper}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                data-testid="form_start_edit_barrier"
                className={form.form}>
                <h2 className={app.sectionHeading}>General Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Job Centre'}
                            id={'jobCentreId'}
                            key={isClearJobCentre}
                            disabled={!hasRole(acceptedRoles, roles)}
                            chosenName={jobCentre}
                            chosenId={jobCentreId}
                            menuItems={arrayJobCentres || []}
                            onChange={handleEmployabilityMetricSelectionChange(
                                'jobCentreId',
                                setJobCentreId
                            )}
                        />
                        <LabelledTextField
                            label={'Job Centre Plus Contact Name'}
                            id={'jobCentrePlusContactName'}
                            value={newEntry.jobCentrePlusContactName || ''}
                            placeholder={'Enter job centre plus contact name'}
                            error={errors.jobCentrePlusContactName}
                            {...register('jobCentrePlusContactName')}
                            onChange={handleEmployabilityMetricEditChange(
                                'jobCentrePlusContactName'
                            )}
                        />
                        <LabelledEmailText
                            label={'Job Centre Plus Contact Email'}
                            id={'jobCentrePlusContactEmail'}
                            value={newEntry.jobCentrePlusContactEmail || ''}
                            placeholder={'Enter job centre plus contact email'}
                            error={errors.jobCentrePlusContactEmail}
                            {...register('jobCentrePlusContactEmail')}
                            onChange={handleEmployabilityMetricEditChange(
                                'jobCentrePlusContactEmail'
                            )}
                        />
                        <NoYesRadioPicker
                            label={'Allow Mandatory Activities?'}
                            disabled={!hasRole([QUALITY, SUPERUSER], roles)}
                            radioButtonPick={newEntry.allowMandatoryActivities || false}
                            onChange={handleAllowMandatoryActivitiesChange}
                        />
                        <DDLOptionPicker
                            label={'Right To Work Document Type Id'}
                            id={'rightToWorkDocumentTypeId'}
                            key={isClearRightToWorkDocumentType}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={arrayRightToWorkDocumentTypes || []}
                            chosenName={rightToWorkDocumentType?.name}
                            chosenId={rightToWorkDocumentTypeId}
                            {...register('rightToWorkDocumentTypeId')}
                            error={errors.rightToWorkDocumentTypeId}
                            onChange={handleEmployabilityMetricSelectionChange(
                                'rightToWorkDocumentTypeId',
                                setRightToWorkDocumentTypeId
                            )}
                        />
                        {(rightToWorkDocumentType?.expiry_date_required || false) && (
                            <div>
                                <LabelledTextField
                                    label={'Expiry Date'}
                                    id={'rightToWorkDocumentExpiryDate'}
                                    type="date"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={true}
                                    value={newEntry.rightToWorkDocumentExpiryDate || ''}
                                    placeholder={'Enter the expiry date'}
                                    data-testid="rightToWorkDocumentExpiryDate"
                                    sx={{ width: 220, marginBottom: '50px', marginRight: '20px' }}
                                    error={errors.rightToWorkDocumentExpiryDate}
                                    {...register('rightToWorkDocumentExpiryDate')}
                                    onChange={handleEmployabilityMetricEditChange(
                                        'rightToWorkDocumentExpiryDate'
                                    )}
                                />
                            </div>
                        )}
                        <LabelledTextField
                            label={'Right to Work ID Number'}
                            id={'rightToWorkDocumentIdNumber'}
                            value={newEntry.rightToWorkDocumentIdNumber || ''}
                            placeholder={'Enter the right to work ID number'}
                            {...register('rightToWorkDocumentIdNumber')}
                            onChange={handleEmployabilityMetricEditChange(
                                'rightToWorkDocumentIdNumber'
                            )}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Time Unemployed'}
                            id={'timeUnemployedId'}
                            key={isClearTimeUnemployed}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayTimeUnemployed || []}
                            chosenName={timeUnemployed}
                            chosenId={timeUnemployedId}
                            onChange={handleEmployabilityMetricSelectionChange(
                                'timeUnemployedId',
                                setTimeUnemployedId
                            )}
                        />

                        <DDLOptionPicker
                            label={'Benefit Code'}
                            id={'benefitCodeId'}
                            key={isClearBenefitCode}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayBenefitCodes || []}
                            chosenName={benefitCode}
                            chosenId={benefitCodeId}
                            onChange={handleEmployabilityMetricSelectionChange(
                                'benefitCodeId',
                                setBenefitCodeId
                            )}
                        />
                        <DDLOptionPicker
                            label={'Readiness to Change'}
                            id={'readinessToChangeId'}
                            key={isClearReadinessToChange}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayReadinessToChange || []}
                            chosenName={readinessToChange}
                            chosenId={readinessToChangeId}
                            onChange={handleEmployabilityMetricSelectionChange(
                                'readinessToChangeId',
                                setReadinessToChangeId
                            )}
                        />
                        <DDLOptionPicker
                            label={'Job Readiness'}
                            id={'jobReadinessId'}
                            key={isClearJobReadiness}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayJobReadiness || []}
                            chosenName={jobReadiness}
                            chosenId={jobReadinessId}
                            onChange={handleEmployabilityMetricSelectionChange(
                                'jobReadinessId',
                                setJobReadinessId
                            )}
                        />
                    </div>
                </div>

                <JobGoals
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                    cards={newEntry.jobGoals || []}
                    addGoal={addGoal}
                    deleteGoal={deleteGoal}
                    updateGoal={updateGoal}
                    currentParticipant={currentParticipant}
                    externalSource={true}
                />

                <Button
                    variant={'contained'}
                    type={'submit'}
                    disabled={isUpdateButtonDisabled}
                    data-testid="testIdEmployabilityButton">
                    Update Employability
                </Button>
            </form>

            <OccManagement currentParticipant={currentParticipant} />
        </div>
    );
};

const RIGHT_TO_WORK_DOCUMENT_EXPIRY_DATE_INVALID = 'Please enter a valid future date';

const validationSchema = (arrayRightToWorkDocumentTypes) =>
    Yup.object().shape({
        jobCentrePlusContactName: Yup.string()
            .trim()
            .max(
                JOB_CENTRE_PLUS_NAME_LENGTH,
                `Job Centre Plus contact name must be ${JOB_CENTRE_PLUS_NAME_LENGTH} characters or less`
            ),
        jobCentrePlusContactEmail: Yup.string()
            .trim()
            .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Email is not valid' })
            .max(
                JOB_CENTRE_PLUS_EMAIL_LENGTH,
                `Job Centre Plus contact email must be ${JOB_CENTRE_PLUS_EMAIL_LENGTH} characters or less`
            ),
        rightToWorkDocumentTypeId: Yup.string().required(
            'No right to work document type selected.'
        ),
        rightToWorkDocumentExpiryDate: Yup.string().when('rightToWorkDocumentTypeId', {
            is: (id) =>
                arrayRightToWorkDocumentTypes.find((el) => el.id === id)?.expiry_date_required ??
                false,
            then: () =>
                Yup.string()
                    .required('Please select a right to work document expiry date')
                    .test(
                        'rightToWorkDocumentExpiryDate',
                        RIGHT_TO_WORK_DOCUMENT_EXPIRY_DATE_INVALID,
                        function (rightToWorkDocumentExpiryDate) {
                            return getFutureDate(rightToWorkDocumentExpiryDate) === true;
                        }
                    )
        })
    });

export default Employability;
