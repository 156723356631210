import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectServices } from '../../../store/dataSelectors';
import { loadServiceDetailsByService } from '../../../store/directusService';
import { selectAllUsersPrimaryServiceIds, selectUsers } from '../../../store/userSelectors';
import { loadUsersBySimpleSearch } from '../../../store/userService';
import DynamicSearch from '../../search/DynamicSearch';
import LoadingSpinner from '../../ui/LoadingSpinner';

import UsersTable from './UsersTable';

import classes from '../../../app.module.css';

const ManageUsers = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const users = useSelector(selectUsers);
    const userServiceIds = useSelector(selectAllUsersPrimaryServiceIds);
    const services = useSelector(selectServices);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    useEffect(() => {
        setRows([]);
    }, []);

    useEffect(() => {
        if (searchTerm === '') setRows([]);
        if (searchTerm.length > 2) dispatch(loadUsersBySimpleSearch(searchTerm));
    }, [searchTerm]);

    useEffect(() => {
        userServiceIds.length > 0 && dispatch(loadServiceDetailsByService(userServiceIds));
    }, [userServiceIds]);

    useEffect(() => {
        if (!searchTerm) return;
        const rows = users.map((el) => ({
            id: el.id,
            name: `${el.firstName} ${el.lastName}`,
            emailAddress: el.emailAddress || '',
            primaryService: services.find((entry) => el.primaryService === entry.id)?.name || '',
            primaryTeam: el.primaryTeam?.name || ''
        }));
        setRows(rows);
    }, [users, services]);

    const noContent =
        successMessage === 'Users loaded' ? <LoadingSpinner content="No users found" /> : null;

    // EVENT HANDLERS

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    return (
        <div className={classes.container}>
            <div className={classes.mainHeading}>Manage Users</div>
            <div className={classes.background}>
                <div className={classes.whiteContainer}>
                    <div className={classes.searchWrapper}>
                        <DynamicSearch
                            search={onSearch}
                            searchTerm={searchTerm}
                            placeholder="Enter search term"
                        />
                    </div>

                    {rows.length < 1 ? (
                        noContent
                    ) : (
                        <div>
                            <UsersTable rows={rows} searchTerm={searchTerm} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ManageUsers;
