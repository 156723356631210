import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import { selectBarrierDetails } from '../../store/dataSelectors';
import { clearBarrierName, loadBarrierNameDetails } from '../../store/directusService';
import { updatePreSelectedMultiItems, updateSelectedItems } from '../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import NotFound from '../notFound/NotFound';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';

// Note! This code uses 'console.log' for logging and 'alert' to tell the user about successes/errors.
// Replace these when logging and notifications are implemented.

/**
 * ParticipantBarriersAdmin component.
 *
 * This updates the participant barrier names DDL for barrier names
 *
 * When the form is submitted onSubmit is called. This validates that DDLs are not blank.
 *
 */

const ParticipantBarriersAdmin = ({ contractId }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    const submitMessage = 'participant barrier';
    const [selectedBarrierNames, setSelectedBarrierNames] = useState([]);
    const [preSelectedBarrierNames, setPreSelectedBarrierNames] = useState([]);
    const [arrayBarrierNames, setArrayBarrierNames] = useState([]);
    const [isClearSelectedBarrierNames, setIsClearSelectedBarrierNames] = useState('0');

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const barrierNameDetails = useSelector(selectBarrierDetails);
    const dispatch = useDispatch();

    // EVENT HANDLERS
    const handleBarrierNameChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setValue('barrierNames', chosenIds, { shouldValidate: true });
        setSelectedBarrierNames(chosenIds);
    };

    // USEEFFECTS
    const contractSetup = () => {
        setSelectedBarrierNames([]);
        setPreSelectedBarrierNames([]);
        setArrayBarrierNames([]);
        setIsClearSelectedBarrierNames(Math.random());
        dispatch(clearBarrierName());
        dispatch(loadBarrierNameDetails());
    };

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (barrierNameDetails?.length && arrayBarrierNames?.length === 0) {
            setPreSelectedBarrierNames(
                updatePreSelectedMultiItems('barrier_name', barrierNameDetails, contractId)
            );
            setArrayBarrierNames(barrierNameDetails);
        }
    }, [barrierNameDetails]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${submitMessage} collection`)) {
            contractSetup();
        }
    }, [successMessage]);

    // FORM SUBMIT
    const onSubmit = () => {
        updateSelectedItems(
            'barrier_name',
            barrierNameDetails,
            selectedBarrierNames,
            contractId,
            submitMessage,
            dispatch
        );
    };

    // RENDER
    let content = '';
    if (!contractId) content = 'No Contract Id';
    if (barrierNameDetails?.length < 1) content = 'No barrier name details';

    if (!hasRole([SUPERUSER, LOCAL_ADMIN], roles)) return <NotFound />;

    if (!contractId || barrierNameDetails?.length < 1) return <LoadingSpinner content={content} />;

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start_ParticipantBarriersAdmin">
            <DDLMultiOptionPicker
                heading={'Configure Barrier Name DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="barrierNames"
                id="barrierNames"
                mandatory={true}
                key={isClearSelectedBarrierNames}
                label="Barrier Names"
                placeholder="Barrier Name"
                menuItems={barrierNameDetails || []}
                preSelectedIds={preSelectedBarrierNames}
                chosenIds={selectedBarrierNames}
                error={errors.barrierNames}
                {...register('barrierNames')}
                onChange={(chosenIds) => handleBarrierNameChange(chosenIds)}
            />
            <p>Priority</p>
            <p>Start Date</p>
            <p>End Date</p>
            <p>Status</p>
            <p>&nbsp;</p>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton">
                {'UPDATE PARTICIPANT BARRIERS'}
            </Button>
        </form>
    );
};

const validationSchema = Yup.object().shape({
    barrierNames: Yup.array().min(1, 'No barrier names selected.').nullable()
});

ParticipantBarriersAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ParticipantBarriersAdmin;
