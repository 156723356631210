import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SellIcon from '@mui/icons-material/Sell';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { loadProvisionStatusDetails } from '../../../store/directusService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { POLARIS_BLUE, POLARIS_VICTORIA_BLUE } from '../../../themes/theme';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';

import dropdownClasses from '../courseStyles/rowDropDown.module.css';

const CurrentProvisionDetails = ({ row, onClose }) => {
    const { handleSubmit } = useForm({});

    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [canBeSubmittedForFinanceRequest, setCanBeSubmittedForFinanceRequest] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { currentProvision } = useSelector((state) => state.entities.courseService);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const { provisionStatusDetails } = useSelector((state) => state.entities.directusService);
    const { successMessage } = useSelector((state) => state.entities.formsState);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadProvisionStatusDetails);
    }, []);

    useEffect(() => {
        if (currentProvision && Object.keys(currentProvision).length !== 0) {
            setCanBeSubmittedForFinanceRequest(false);
            if (currentProvision.participants && !currentProvision.participants.length < 1) {
                const index = currentProvision.participants.findIndex(
                    (item) => item.participantId === currentParticipant.id
                );
                if (index >= 0) {
                    const selectedProvisionStatus = provisionStatusDetails.find(
                        (item) => item.name.toLowerCase() === 'booked'
                    );
                    if (
                        currentProvision.participants[index].statusId !== selectedProvisionStatus.id // booked
                    ) {
                        setCanBeSubmittedForFinanceRequest(false);
                    }
                }
            }
        }
    }, [currentProvision.id]);

    useEffect(() => {
        if (successMessage === 'Participant has been submitted for finance request') {
            setCanBeSubmittedForFinanceRequest(false);
        }
    }, [successMessage]);

    //  FORM SUBMIT
    const onSubmit = () => {
        navigate('/create_financial_request', {
            state: {
                roles: loggedInUser.roles,
                accordionPanelFrom: 'courses',
                provision: row
            }
        });
    };
    navigate('/course_information', { state: { accordionPanel: 'courses' } });

    return provisionStatusDetails.length < 1 ? (
        <LoadingSpinner content="Provision status not found" />
    ) : (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start_PrimaryDetailsAdmin">
                <div className={dropdownClasses.rowDropdown}>
                    <Typography variant="h5">
                        {row.title} ({row.code})
                    </Typography>
                    <Typography variant="body1">{row.description}</Typography>
                    <p>
                        <VerifiedIcon sx={{ color: POLARIS_VICTORIA_BLUE }} />
                        <sup>{row.ofqualNumber}</sup>
                    </p>
                    <p>
                        <SellIcon sx={{ color: POLARIS_BLUE }} />{' '}
                        <sup>
                            {'£' +
                                row.costPerParticipant +
                                ' per participant - Payment Method is ' +
                                row.paymentMethod}
                        </sup>
                    </p>
                    <Typography variant="h6">Delivery Information</Typography>
                    <LabelledTextField
                        sx={{ marginBottom: '-20px' }}
                        disabled={true}
                        rows={3}
                        value={row.deliveryInformation}
                    />
                    <Typography variant="h6">Qualification Required</Typography>
                    <LabelledTextField
                        sx={{ marginBottom: '-20px' }}
                        disabled={true}
                        rows={3}
                        value={row.qualificationRequired}
                    />
                    <Typography variant="h6">Registration Methods</Typography>
                    <LabelledTextField disabled={true} rows={3} value={row.registrationMethods} />
                    <Button
                        sx={{ marginTop: '-20px', marginBottom: '20px' }}
                        disabled={!!canBeSubmittedForFinanceRequest}
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="testIdSubmitButton1"
                        endIcon={<KeyboardArrowRightIcon />}>
                        {'Raise Finance Request'}
                    </Button>
                    <div className={dropdownClasses.exit} onClick={onClose}>
                        Close details
                        <CloseIcon />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CurrentProvisionDetails;

CurrentProvisionDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
