import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_WHITE } from '../../../../themes/theme';
import LabelledTextField from '../../editors/LabelledTextField';
import DDLOptionPicker from '../../pickers/DDLOptionPicker';

const TITLE_MAX_LENGTH = 100;

/**
 * A dialog to edit Job goals details. See PPL-351 for details
 *
 * @param cardData - Data for this editor
 * This has values which match the job goals table in the participant service
 * @param disabled Set true to disable editing (default = false)
 * @param sendChange Callback function to tell parent that data has changed.
 * @param arrayJobSectors  Job sector information.
 * * having to fetch it again.
 *
 * @returns {JSX.Element}
 */
const JobGoalsEditor = ({ cardData, disabled = false, sendChange, arrayJobSectors }) => {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // local state

    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;
    const [newEntry, setNewEntry] = useState({
        id: null,
        title: null,
        sectorId: null,
        participantId: null
    });
    const [open, setOpen] = useState(false);

    const setCardData = () => {
        setNewEntry((prev) => ({
            ...prev,
            id: cardData.id,
            title: cardData.title,
            sectorId: cardData.sectorId,
            participantId: cardData.participantId
        }));
    };

    const getName = (id) => {
        if (!id) return '';
        return arrayJobSectors.find((item) => item.id === id)?.name || '';
    };

    // First time on card and blank so must be new.
    // Open dialog to force user to enter data
    useEffect(() => {
        if (!cardData.title && !cardData.sectorId) {
            if (disabled === false) {
                setOpen(true);
            }
        }
        setCardData();
    }, []);

    const handleClickOpen = () => {
        if (disabled === false) setOpen(true);
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        // Reset info to cardData
        setCardData();
        reset();
        clearErrors();
        setOpen(false);
    };

    const handleUpdate = () => {
        clearErrors();
        sendChange(newEntry);
        setOpen(false);
    };

    const handleSectorIdChange = (value) => {
        if (value == null) return;
        setNewEntry((prev) => ({
            ...prev,
            sectorId: value
        }));
        clearErrors('sector');
        setValue('sectorId', value, { shouldValidate: true });
    };

    const handleTitleChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            title: e.target.value
        }));
        clearErrors('title');
    };

    const onSubmit = () => {
        if (newEntry.sectorId === '') {
            setError('sector', { type: 'manual', message: 'Select a sector' });
            return false;
        }
        handleUpdate();
    };

    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <EditIcon
                onClick={handleClickOpen}
                sx={{ color: iconColor }}
                data-testid="editor-icon"></EditIcon>

            <Dialog open={open} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Edit Job Goals</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <DDLOptionPicker
                            id="sectorId"
                            label={'Job Sector'}
                            mandatory={true}
                            data-testid="job_readiness_ddl_option_picker"
                            menuItems={arrayJobSectors}
                            chosenName={getName(newEntry.sectorId)}
                            chosenId={newEntry.sectorId}
                            error={errors.sectorId}
                            {...register('sectorId')}
                            onChange={handleSectorIdChange}></DDLOptionPicker>
                        <LabelledTextField
                            id="title"
                            disabled={disabled}
                            label="Job Title"
                            value={newEntry.title || ''}
                            placeholder="Title here..."
                            error={errors.title}
                            {...register('title')}
                            onChange={handleTitleChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Update
                        </Button>
                        <Button
                            onClick={handleCancel}
                            color="primary"
                            variant="contained"
                            disabled={disabled}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    sectorId: Yup.string().required('Please select a job sector'),
    title: Yup.string()
        .trim()
        .max(TITLE_MAX_LENGTH, `Title must be ${TITLE_MAX_LENGTH} characters or shorter`)
});

JobGoalsEditor.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendChange: PropTypes.func.isRequired,
    arrayJobSectors: PropTypes.array.isRequired
};
export default JobGoalsEditor;
