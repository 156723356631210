import { createSlice } from '@reduxjs/toolkit';

import { getRecruitmentURL } from '../api/commonHTTP';
import { getEmptyPaginationState } from '../utils/objectUtils';

import { apiCallBegan } from './api';

const slice = createSlice({
    name: 'recruitmentService',
    initialState: {
        businesses: [],
        businessesMetaData: {},
        businessesByCodeOrName: [],
        businessesByLoggedInUser: [],
        businessesByLoggedInUserMetaData: getEmptyPaginationState(),
        businessesByOtherUsers: [],
        businessesByOwner: [],
        businessesByOwnerMetaData: getEmptyPaginationState(),
        poolApplications: [],
        poolApplicationsForOwners: [],
        placements: [],
        pools: [],
        poolsForOwners: [],
        recruitmentParticipantTimelineEvents: [],
        recruitmentParticipantVacancyTimelineEvents: [],
        vacancies: [],
        vacanciesForDistance: [],
        vacanciesForOwners: [],
        vacancySubmissions: [],
        vacancySubmissionsForBusiness: [],
        vacancySubmissionsForOtherBusiness: [],
        vacancySubmissionsForVacancy: [],
        currentBusiness: {},
        configureContractPlacement: {},
        currentPlacement: {},
        currentPool: {},
        currentPoolApplication: {},
        currentVacancy: {},

        loadingBusiness: false,
        loadingBusinesses: false,
        loadingBusinessesByCodeOrName: false,
        loadingBusinessesByLoggedInUser: false,
        loadingBusinessesByOwner: false,
        loadingConfigureContractPlacement: false,
        loadingParticipantTimeline: false,
        loadingParticipantVacancyTimeline: false,
        loadingPoolApplication: false,
        loadingPoolApplications: false,
        loadingPoolApplicationsForOwners: false,
        loadingPlacement: false,
        loadingPlacements: false,
        loadingPool: false,
        loadingPools: false,
        loadingPoolsForOwners: false,
        loadingVacancies: false,
        loadingVacanciesForDistance: false,
        loadingVacanciesForOwners: false,
        loadingVacancy: false,
        loadingVacancySubmissions: false,
        loadingVacancySubmissionsForBusiness: false,
        loadingVacancySubmissionsForOtherBusiness: false,
        loadingVacancySubmissionsForVacancy: false,

        successMessage: '',
        errorMessage: ''
    },
    reducers: {
        clearBusinesses: (state) => {
            state.businesses = [];
            state.businessesMetaData = getEmptyPaginationState();
        },

        clearPlacements: (state) => {
            state.placements = [];
        },

        clearPoolApplicationsForOwners: (state) => {
            state.poolApplicationsForOwners = [];
        },

        clearPoolsForOwners: (state) => {
            state.poolsForOwners = [];
        },

        clearVacancies: (state) => {
            state.vacancies = [];
        },

        clearVacancySubmissions: (state) => {
            state.vacancySubmissions = [];
        },

        // BUSINESS
        businessCreatedForLoggedInUser: (state, action) => {
            state.currentBusiness = action.payload;
            state.businesses = [...state.businesses, action.payload];

            state.businessesByLoggedInUser = [...state.businessesByLoggedInUser, action.payload];
            state.businessesByLoggedInUserMetaData = {
                ...state.businessesByLoggedInUserMetaData,
                totalElements: state.businessesByLoggedInUserMetaData.totalElements + 1,
                numberOfElements: state.businessesByLoggedInUserMetaData.numberOfElements + 1
            };
        },

        businessCreatedForOtherUser: (state, action) => {
            state.currentBusiness = action.payload;
            state.businesses = [...state.businesses, action.payload];
        },

        businessUpdatedForLoggedInUser: (state, action) => {
            state.loadingBusiness = false;
            const businesses = state.businessesByLoggedInUser.filter(
                (el) => el.id !== action.payload.id
            );
            state.businessesByLoggedInUser = [...businesses, action.payload];
            state.currentBusiness = action.payload;
        },

        businessUpdatedForOtherUser: (state, action) => {
            state.loadingBusiness = false;
            state.businessesByLoggedInUser = state.businessesByLoggedInUser?.filter(
                (el) => el.id !== action.payload.id
            );
            state.currentBusiness = action.payload;
        },

        businessDeleted: (state, action) => {
            state.businessesByLoggedInUser = state.businessesByLoggedInUser.map((el) =>
                el.id === action.payload.id ? { ...el, inactive: true } : el
            );
        },

        businessesByCodeOrNameLoaded: (state, action) => {
            state.loadingBusinessesByCodeOrName = false;
            state.businessesByCodeOrName = action.payload.content;
        },

        businessesByCodeOrNameLoading: (state) => {
            state.loadingBusinessesByCodeOrName = true;
            state.businessesByCodeOrName = [];
        },

        businessesByCodeOrNameLoadingFailed: (state, action) => {
            state.loadingBusinessesByCodeOrName = false;
            state.errorMessage = action.payload;
        },

        businessesByLoggedInUserLoaded: (state, action) => {
            state.loadingBusinessesByLoggedInUser = false;
            const { content, ...rest } = action.payload;
            state.businessesByLoggedInUser = rest.first
                ? content
                : [...state.businessesByLoggedInUser, ...content];
            state.businessesByLoggedInUserMetaData = rest;
        },

        businessesByLoggedInUserLoading: (state) => {
            state.loadingBusinessesByLoggedInUser = true;
        },

        businessesByLoggedInUserLoadingFailed: (state, action) => {
            state.loadingBusinessesByLoggedInUser = false;
            state.errorMessage = action.payload;
        },

        businessesByOwnerLoaded: (state, action) => {
            state.loadingBusinessesByOwner = false;
            const { content, ...rest } = action.payload;
            state.businessesByOwner = rest.first
                ? content
                : [...state.businessesByOwner, ...content];
            state.businessesByOwnerMetaData = rest;
        },

        businessesByOwnerLoading: (state) => {
            state.loadingBusinessesByOwner = true;
        },

        businessesByOwnerLoadingFailed: (state, action) => {
            state.loadingBusinessesByOwner = false;
            state.errorMessage = action.payload;
        },

        businessesLoaded: (state, action) => {
            state.loadingBusinesses = false;
            const { content, ...rest } = action.payload;
            state.businesses = rest.first ? content : [...state.businesses, ...content];
            state.businessesMetaData = rest;
        },

        businessesLoading: (state) => {
            state.loadingBusinesses = true;
        },

        businessesLoadingFailed: (state, action) => {
            state.loadingBusinesses = false;
            state.errorMessage = action.payload;
        },

        businessLoaded: (state, action) => {
            state.loadingBusiness = false;
            state.currentBusiness = action.payload;
        },

        businessLoading: (state) => {
            state.loadingBusiness = true;
            state.currentBusiness = {};
        },

        businessLoadingFailed: (state, action) => {
            state.loadingBusiness = false;
            state.errorMessage = action.payload;
        },

        setCurrentBusiness: (state, action) => {
            state.currentBusiness = action.payload;
        },

        // PLACEMENT
        placementLoading: (state) => {
            state.loadingPlacement = true;
            state.currentPlacement = {};
        },

        placementLoadingFailed: (state, action) => {
            state.loadingPlacement = false;
            state.errorMessage = action.payload;
        },

        placementsLoaded: (state, action) => {
            state.loadingPlacements = false;
            state.placements = action.payload;
        },

        placementsLoading: (state) => {
            state.loadingPlacements = true;
            state.placements = [];
        },

        placementsLoadingFailed: (state, action) => {
            state.loadingPlacements = false;
            state.errorMessage = action.payload;
        },

        placementCreated: (state, action) => {
            state.currentPlacement = action.payload;
            state.placements = [...state.placements, action.payload];
        },

        placementUpdated: (state, action) => {
            state.loadingPlacement = false;
            const placements = state.placements.filter((el) => el.id !== action.payload.id);
            state.placements = [...placements, action.payload];
            state.currentPlacement = action.payload;
        },

        configureContractPlacementLoaded: (state, action) => {
            state.loadingConfigureContractPlacement = false;
            state.configureContractPlacement = action.payload === '' ? {} : action.payload;
        },

        configureContractPlacementLoading: (state) => {
            state.loadingConfigureContractPlacement = true;
            state.configureContractPlacement = {};
        },

        configureContractPlacementLoadingFailed: (state, action) => {
            state.loadingConfigureContractPlacement = false;
            state.errorMessage = action.payload;
        },

        configureContractPlacementUpdated: (state, action) => {
            state.loadingConfigureContractPlacement = false;
            state.configureContractPlacement = action.payload;
        },

        // POOLING
        // Can be multiple pools created
        poolApplicationCreated: (state, action) => {
            state.currentPoolApplication = action.payload[0];
            const poolApplications = state.poolApplications.filter(
                (el) =>
                    !action.payload.some((ppId) => ppId.participantPoolId === el.participantPoolId)
            );
            state.poolApplications = [...poolApplications, ...action.payload];
        },

        poolApplicationLoaded: (state, action) => {
            state.loadingPoolApplication = false;
            state.currentPoolApplication = action.payload;
        },

        poolApplicationLoading: (state) => {
            state.loadingPoolApplication = true;
            state.currentPoolApplication = {};
        },

        poolApplicationLoadingFailed: (state, action) => {
            state.loadingPoolApplication = false;
            state.errorMessage = action.payload;
        },

        poolApplicationsForOwnersLoaded: (state, action) => {
            state.loadingPoolApplicationsForOwners = false;
            state.poolApplicationsForOwners = action.payload;
        },

        poolApplicationsForOwnersLoading: (state) => {
            state.loadingPoolApplicationsForOwners = true;
            state.poolApplicationsForOwners = [];
        },

        poolApplicationsForOwnersLoadingFailed: (state, action) => {
            state.loadingPoolApplicationsForOwners = false;
            state.errorMessage = action.payload;
        },

        poolApplicationsLoaded: (state, action) => {
            state.loadingPoolApplications = false;
            state.poolApplications = action.payload;
        },

        poolApplicationsLoading: (state) => {
            state.loadingPoolApplications = true;
            state.poolApplications = [];
        },

        poolApplicationsLoadingFailed: (state, action) => {
            state.loadingPoolApplications = false;
            state.errorMessage = action.payload;
        },

        poolApplicationsUpdated: (state, action) => {
            state.loadingPoolApplications = false;
            if (Array.isArray(action.payload)) {
                state.poolApplications = state.poolApplications?.map(
                    (el) => action.payload.find((entry) => entry.id === el.id) || el
                );
            } else {
                state.poolApplications = state.poolApplications.map((el) =>
                    el.id === action.payload.id ? action.payload : el
                );
            }
        },

        poolLoading: (state) => {
            state.loadingPool = true;
            state.currentPool = {};
        },

        poolLoadingFailed: (state, action) => {
            state.loadingPool = false;
            state.errorMessage = action.payload;
        },

        poolsForOwnersLoaded: (state, action) => {
            state.loadingPoolsForOwners = false;
            state.poolsForOwners = action.payload;
        },

        poolsForOwnersLoading: (state) => {
            state.loadingPoolsForOwners = true;
            state.poolsForOwners = [];
        },

        poolsForOwnersLoadingFailed: (state, action) => {
            state.loadingPoolsForOwners = false;
            state.errorMessage = action.payload;
        },

        poolsLoaded: (state, action) => {
            state.loadingPools = false;
            state.pools = action.payload;
        },

        poolsLoading: (state) => {
            state.loadingPools = true;
            state.pools = [];
        },

        poolsLoadingFailed: (state, action) => {
            state.loadingPools = false;
            state.errorMessage = action.payload;
        },

        poolCreated: (state, action) => {
            state.currentPool = action.payload;
            state.pools = [...state.pools, action.payload];
        },

        setCurrentPool: (state, action) => {
            state.currentpool = action.payload;
        },

        // TIMELINE
        recruitmentParticipantTimelineEventsLoaded: (state, action) => {
            state.loadingParticipantTimeline = false;
            state.recruitmentParticipantTimelineEvents = action.payload;
        },

        recruitmentParticipantTimelineEventsLoading: (state) => {
            state.loadingParticipantTimeline = true;
            state.recruitmentParticipantTimelineEvents = [];
        },

        recruitmentParticipantTimelineEventsLoadingFailed: (state, action) => {
            state.loadingParticipantTimeline = false;
            state.errorMessage = action.payload;
        },
        recruitmentParticipantVacancyTimelineEventsLoaded: (state, action) => {
            state.loadingParticipantVacancyTimeline = false;
            state.recruitmentParticipantVacancyTimelineEvents = action.payload;
        },

        recruitmentParticipantVacancyTimelineEventsLoading: (state) => {
            state.loadingParticipantVacancyTimeline = true;
            state.recruitmentParticipantVacancyTimelineEvents = [];
        },

        recruitmentParticipantVacancyTimelineEventsLoadingFailed: (state, action) => {
            state.loadingParticipantVacancyTimeline = false;
            state.errorMessage = action.payload;
        },

        // VACANCY
        vacancyDeleted: (state, action) => {
            state.vacanciesForOwners = state.vacanciesForOwners.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        vacanciesForDistanceLoaded: (state, action) => {
            state.loadingVacanciesForDistance = false;
            state.vacanciesForDistance = action.payload;
        },

        vacanciesForDistanceLoading: (state) => {
            state.loadingVacanciesForDistance = true;
            state.vacanciesForDistance = [];
        },

        vacanciesForDistanceLoadingFailed: (state, action) => {
            state.loadingVacanciesForDistance = false;
            state.errorMessage = action.payload;
        },

        vacanciesForOwnersLoaded: (state, action) => {
            state.loadingVacanciesForOwners = false;
            state.vacanciesForOwners = action.payload;
        },

        vacanciesForOwnersLoading: (state) => {
            state.loadingVacanciesForOwners = true;
            state.vacanciesForOwners = [];
        },

        vacanciesForOwnersLoadingFailed: (state, action) => {
            state.loadingVacanciesForOwners = false;
            state.errorMessage = action.payload;
        },

        vacanciesLoading: (state) => {
            state.loadingVacancies = true;
        },

        vacanciesLoaded: (state, action) => {
            state.loadingVacancies = false;
            action.payload.forEach((el, i, arr) => {
                const id = el.businessRecordId;
                if (!id) return;
                state.vacancies = {
                    ...state.vacancies,
                    [id]:
                        state.vacancies[id] && Array.isArray(state.vacancies[id])
                            ? [
                                  ...state.vacancies[id].filter(
                                      (element) =>
                                          !arr.find(
                                              (entry, idx) => idx >= i && entry.id === element.id
                                          )
                                  ),
                                  el
                              ]
                            : [el]
                };
            });
        },

        vacanciesLoadingFailed: (state, action) => {
            state.loadingVacancies = false;
            state.errorMessage = action.payload;
        },

        vacancyLoaded: (state, action) => {
            state.loadingVacancy = false;
            const id = action.payload?.businessRecordId;
            if (!id) return;
            state.vacancies = {
                ...state.vacancies,
                [id]:
                    state.vacancies[id] && Array.isArray(state.vacancies[id])
                        ? [
                              ...state.vacancies[id].filter((el) => el.id !== action.payload.id),
                              action.payload
                          ]
                        : [action.payload]
            };
            state.currentVacancy = action.payload;
        },

        vacancyLoading: (state) => {
            state.loadingVacancy = true;
            state.currentVacancy = {};
        },

        vacancyLoadingFailed: (state, action) => {
            state.loadingVacancy = false;
            state.errorMessage = action.payload;
        },

        vacancyUpdated: (state, action) => {
            state.loadingVacanciesForOwners = false;
            const vacanciesForOwners = state.vacanciesForOwners.filter(
                (el) => el.id !== action.payload.id
            );
            state.vacanciesForOwners = [...vacanciesForOwners, action.payload];
            state.currentVacancy = action.payload;
        },

        setCurrentVacancy: (state, action) => {
            state.currentVacancy = action.payload;
        },

        // Can be multiple vacancy submissions created
        vacancySubmissionsCreated: (state, action) => {
            const vacancySubmissions = state.vacancySubmissions.filter(
                (el) => !action.payload.some((ptId) => ptId.participantId === el.participantId)
            );
            state.vacancySubmissions = [...vacancySubmissions, ...action.payload];
        },

        vacancySubmissionsForBusinessLoaded: (state, action) => {
            state.loadingvacancySubmissionsForBusiness = false;
            state.vacancySubmissionsForBusiness = action.payload;
        },

        vacancySubmissionsForBusinessLoading: (state) => {
            state.loadingvacancySubmissionsForBusiness = true;
            state.vacancySubmissionsForBusiness = [];
        },

        vacancySubmissionsForBusinessLoadingFailed: (state, action) => {
            state.loadingvacancySubmissionsForBusiness = false;
            state.errorMessage = action.payload;
        },

        vacancySubmissionsForOtherBusinessLoaded: (state, action) => {
            state.loadingvacancySubmissionsForOtherBusiness = false;
            state.vacancySubmissionsForOtherBusiness = action.payload;
        },

        vacancySubmissionsForOtherBusinessLoading: (state) => {
            state.loadingvacancySubmissionsForOtherBusiness = true;
            state.vacancySubmissionsForOtherBusiness = [];
        },

        vacancySubmissionsForOtherBusinessLoadingFailed: (state, action) => {
            state.loadingvacancySubmissionsForOtherBusiness = false;
            state.errorMessage = action.payload;
        },

        vacancySubmissionsForVacancyLoaded: (state, action) => {
            state.loadingvacancySubmissionsForVacancy = false;
            state.vacancySubmissionsForVacancy = action.payload;
        },

        vacancySubmissionsForVacancyLoading: (state) => {
            state.loadingvacancySubmissionsForVacancy = true;
            state.vacancySubmissionsForVacancy = [];
        },

        vacancySubmissionsForVacancyLoadingFailed: (state, action) => {
            state.loadingvacancySubmissionsForVacancy = false;
            state.errorMessage = action.payload;
        },

        vacancySubmissionsLoaded: (state, action) => {
            state.loadingvacancySubmissions = false;
            state.vacancySubmissions = action.payload;
        },

        vacancySubmissionsLoading: (state) => {
            state.loadingvacancySubmissions = true;
            state.vacancySubmissions = [];
        },

        vacancySubmissionsLoadingFailed: (state, action) => {
            state.loadingvacancySubmissions = false;
            state.errorMessage = action.payload;
        },

        vacancySubmissionsUpdated: (state, action) => {
            state.loadingvacancySubmissions = false;
            if (Array.isArray(action.payload)) {
                state.vacancySubmissions = state.vacancySubmissions?.map(
                    (el) => action.payload.find((entry) => entry.id === el.id) || el
                );
            } else {
                state.vacancySubmissions = state.vacancySubmissions.map((el) =>
                    el.id === action.payload.id ? action.payload : el
                );
            }
        },

        // MISCELLANEOUS
        errorMessageSet: (state, action) => {
            state.errorMessage = action.payload;
            state.successMessage = '';
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        }
    }
});

// ActionCreators. These are commands rather
// than events that have happened

// BUSINESS
export const createBusinessRecord = (data, isLoggedInUser) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/business-record',
        method: 'post',
        data,
        onSuccess: isLoggedInUser
            ? businessCreatedForLoggedInUser.type
            : businessCreatedForOtherUser.type,
        successMessage: `Business record has been added`,
        onError: businessLoadingFailed.type
    });

export const deleteBusinessRecord = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/business-record/${id}`,
        method: 'delete',
        onSuccess: businessDeleted.type,
        successMessage: `Business record has been deleted`,
        onError: businessesLoadingFailed.type
    });

export const loadBusinessRecord = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/business-record/${id}`,
        onStart: businessLoading.type,
        onSuccess: businessLoaded.type,
        onError: businessLoadingFailed.type
    });

// fetches ALL business records in the system apart from inactive
export const loadBusinessRecords = (page = 0, size = 50, sort = 'name', dir = 'asc') =>
    apiCallBegan({
        url:
            getRecruitmentURL() +
            `rest/business-record?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        onStart: businessesLoading.type,
        onSuccess: businessesLoaded.type,
        successMessage: `Business records have been loaded`,
        onError: businessesLoadingFailed.type
    });

export const updateBusinessRecord = (data, isLoggedInUser) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/business-record/${data.id}`,
        method: 'put',
        data,
        onStart: businessLoading.type,
        onSuccess: isLoggedInUser
            ? businessUpdatedForLoggedInUser.type
            : businessUpdatedForOtherUser.type,
        successMessage: `Business record has been updated`,
        onError: businessLoadingFailed.type
    });

export const searchBusinessesByCodeOrName = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/business-record/search',
        method: 'post',
        data,
        onStart: businessesByCodeOrNameLoading.type,
        onSuccess: businessesByCodeOrNameLoaded.type,
        successMessage: `Business search record has been loaded`,
        onError: businessesByCodeOrNameLoadingFailed.type
    });

export const searchBusinessesByLoggedInUser = (
    data,
    page = 0,
    size = 50,
    sort = 'name',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getRecruitmentURL() +
            `rest/business-record/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: businessesByLoggedInUserLoading.type,
        onSuccess: businessesByLoggedInUserLoaded.type,
        successMessage: `Business records by logged in user have been loaded`,
        onError: businessesByLoggedInUserLoadingFailed.type
    });

export const searchBusinessesByOwner = (data, page = 0, size = 50, sort = 'code', dir = 'asc') =>
    apiCallBegan({
        url:
            getRecruitmentURL() +
            `rest/business-record/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: businessesByOwnerLoading.type,
        onSuccess: businessesByOwnerLoaded.type,
        onError: businessesByOwnerLoadingFailed.type
    });

// Configure Placement
export const loadConfigureContractPlacement = (contractId) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/contract-configuration/${contractId}`,
        onStart: configureContractPlacementLoading.type,
        onSuccess: configureContractPlacementLoaded.type,
        onError: configureContractPlacementLoadingFailed.type
    });

export const updateConfigureContractPlacement = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/contract-configuration/${data.id}`,
        method: 'put',
        data,
        onStart: configureContractPlacementLoading.type,
        onSuccess: configureContractPlacementUpdated.type,
        successMessage: `Placement has been updated`,
        onError: configureContractPlacementLoadingFailed.type
    });

// Placement
export const createPlacement = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/placement',
        method: 'post',
        data,
        onStart: placementLoading.type,
        onSuccess: placementCreated.type,
        successMessage: `Job placement has been added: ${data.vacancyId || ''}`,
        onError: placementLoadingFailed.type
    });

export const loadPlacementsForParticipant = (participantId) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/placement/participant/${participantId}`,
        onStart: placementsLoading.type,
        onSuccess: placementsLoaded.type,
        successMessage: `Job placements have been loaded`,
        onError: placementsLoadingFailed.type
    });

export const updatePlacement = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/placement/${data.id}`,
        method: 'put',
        data,
        onStart: placementLoading.type,
        onSuccess: placementUpdated.type,
        successMessage: `Job placement has been updated`,
        onError: placementLoadingFailed.type
    });

// Pooling
export const createPool = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/participant-pool',
        method: 'post',
        data,
        onSuccess: poolCreated.type,
        successMessage: `Pool has been added`,
        onError: poolLoadingFailed.type
    });

export const createPoolApplication = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/participant-pool/application/request',
        method: 'post',
        data,
        onSuccess: poolApplicationCreated.type,
        successMessage: `Pool application(s) have been added`,
        onError: poolApplicationLoadingFailed.type
    });

export const updatePoolApplicationAccepted = (poolApplicationId) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/application/accept/${poolApplicationId}`,
        method: 'put',
        onSuccess: poolApplicationsUpdated.type,
        successMessage: `Pool applications accepted have been updated`,
        onError: poolApplicationsLoadingFailed.type
    });

export const updatePoolApplicationRejected = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/application/reject`,
        method: 'put',
        data,
        onSuccess: poolApplicationsUpdated.type,
        successMessage: `Pool application rejected has been updated`,
        onError: poolApplicationsLoadingFailed.type
    });

export const loadPoolApplicationsByParticipant = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/application/search`,
        method: 'post',
        data: { participantId: id },
        onStart: poolApplicationsLoading.type,
        onSuccess: poolApplicationsLoaded.type,
        successMessage: `Pool applications have been loaded`,
        onError: poolApplicationsLoadingFailed.type
    });

export const loadPoolApplicationsByPoolIds = (poolIds) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/application/search`,
        method: 'post',
        data: { participantPoolIds: poolIds },
        onStart: poolApplicationsLoading.type,
        onSuccess: poolApplicationsLoaded.type,
        successMessage: `Pool applications have been loaded`,
        onError: poolApplicationsLoadingFailed.type
    });

export const loadPoolApplicationsForOwnersByPoolIds = (poolIds) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/application/search`,
        method: 'post',
        data: { participantPoolIds: poolIds },
        onStart: poolApplicationsForOwnersLoading.type,
        onSuccess: poolApplicationsForOwnersLoaded.type,
        successMessage: `Pool applications for owners have been loaded`,
        onError: poolApplicationsForOwnersLoadingFailed.type
    });

export const updatePoolApplicationsAccepted = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/application/accept`,
        method: 'put',
        data,
        onSuccess: poolApplicationsUpdated.type,
        successMessage: `Pool applications accepted have been updated`,
        onError: poolApplicationsLoadingFailed.type
    });

export const loadPoolsByJobSector = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/search`,
        method: 'post',
        data: { jobSectorId: id },
        onStart: poolsLoading.type,
        onSuccess: poolsLoaded.type,
        onError: poolsLoadingFailed.type
    });

export const loadPoolsByUser = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/search`,
        method: 'post',
        data: { ownerId: id },
        onStart: poolsLoading.type,
        onSuccess: poolsLoaded.type,
        onError: poolsLoadingFailed.type
    });

export const loadPoolsForOwnersByUser = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/search`,
        method: 'post',
        data: { ownerId: id },
        onStart: poolsForOwnersLoading.type,
        onSuccess: poolsForOwnersLoaded.type,
        onError: poolsForOwnersLoadingFailed.type
    });

export const loadPoolsForApplications = (poolIds) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/participant-pool/search`,
        method: 'post',
        data: { ids: poolIds },
        onStart: poolsLoading.type,
        onSuccess: poolsLoaded.type,
        onError: poolsLoadingFailed.type
    });

// TIMELINE
export const loadRecruitmentParticipantTimelineEvent = (participantId) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/recruitment-timeline/participant/${participantId}`,
        onStart: recruitmentParticipantTimelineEventsLoading.type,
        onSuccess: recruitmentParticipantTimelineEventsLoaded.type,
        successMessage: `Recruitment Participant TimelineEvents have been loaded`,
        onError: recruitmentParticipantTimelineEventsLoadingFailed.type
    });

export const loadRecruitmentParticipantVacancyTimelineEvent = (participantId, vacancyId) =>
    apiCallBegan({
        url:
            getRecruitmentURL() +
            `rest/recruitment-timeline/participant/${participantId}/vacancy/${vacancyId}`,
        onStart: recruitmentParticipantVacancyTimelineEventsLoading.type,
        onSuccess: recruitmentParticipantVacancyTimelineEventsLoaded.type,
        successMessage: `Recruitment Participant Vacancy TimelineEvents have been loaded`,
        onError: recruitmentParticipantVacancyTimelineEventsLoadingFailed.type
    });

// VACANCY
export const createVacancy = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy',
        method: 'post',
        data,
        onStart: vacancyLoading.type,
        onSuccess: vacancyLoaded.type,
        successMessage: `Vacancy has been added`,
        onError: vacancyLoadingFailed.type
    });

export const deleteVacancy = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/vacancy/${id}`,
        method: 'delete',
        onSuccess: vacancyDeleted.type,
        successMessage: `Vacancy record has been deleted`,
        onError: vacanciesLoadingFailed.type
    });

export const loadVacancy = (id) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/vacancy/${id}`,
        onStart: vacancyLoading.type,
        onSuccess: vacancyLoaded.type,
        successMessage: `Vacancy record has been loaded`,
        onError: vacancyLoadingFailed.type
    });

export const loadVacanciesByOwners = () =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy',
        onStart: vacanciesForOwnersLoading.type,
        onSuccess: vacanciesForOwnersLoaded.type,
        successMessage: `All active vacancies have been loaded`,
        onError: vacanciesForOwnersLoadingFailed.type
    });

export const loadVacanciesByBusinessRecordId = (businessRecordIds) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy/search',
        method: 'post',
        data: { businessRecordIds },
        onStart: vacanciesLoading.type,
        onSuccess: vacanciesLoaded.type,
        successMessage: `Vacancies have been loaded for business records`,
        onError: vacanciesLoadingFailed.type
    });

export const loadVacanciesByDistance = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy/search/distance',
        method: 'post',
        data,
        onStart: vacanciesForDistanceLoading.type,
        onSuccess: vacanciesForDistanceLoaded.type,
        successMessage: `Vacancies have been loaded for distance`,
        onError: vacanciesForDistanceLoadingFailed.type
    });

export const updateVacancy = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/vacancy/${data.id}`,
        method: 'put',
        data,
        onStart: vacanciesForOwnersLoading.type,
        onSuccess: vacancyUpdated.type,
        successMessage: `Vacancy has been updated`,
        onError: vacanciesForOwnersLoadingFailed.type
    });

// VACANCY SUBMISSIONS
export const createVacancySubmissions = (data, vacancyId) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/vacancy-submission/${vacancyId}/submissions`,
        method: 'put',
        data,
        onSuccess: vacancySubmissionsCreated.type,
        successMessage: `Vacancy submissions have been added`,
        onError: vacancyLoadingFailed.type
    });

export const loadVacancySubmissionsByParticipantId = (participantId) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy-submission/search',
        method: 'post',
        data: { participantId },
        onStart: vacancySubmissionsLoading.type,
        onSuccess: vacancySubmissionsLoaded.type,
        successMessage: `Vacancy submissions have been loaded for participant id: ${participantId}`,
        onError: vacancySubmissionsLoadingFailed.type
    });

export const loadVacancySubmissionsByVacancyId = (vacancyId) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy-submission/search',
        method: 'post',
        data: { vacancyId },
        onStart: vacancySubmissionsLoading.type,
        onSuccess: vacancySubmissionsLoaded.type,
        successMessage: `Vacancy submissions have been loaded for vacancy id: ${vacancyId}`,
        onError: vacancySubmissionsLoadingFailed.type
    });

export const loadVacancySubmissionsByVacancyIdForBusiness = (vacancyId, businessId) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy-submission/search',
        method: 'post',
        data: { vacancyId },
        onStart: vacancySubmissionsForBusinessLoading.type,
        onSuccess: vacancySubmissionsForBusinessLoaded.type,
        successMessage: `Vacancy submissions have been loaded for vacancy id: ${vacancyId} and business id: ${businessId}`,
        onError: vacancySubmissionsForBusinessLoadingFailed.type
    });

export const loadVacancySubmissionsByVacancyIdForOtherBusiness = (vacancyId, businessId) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy-submission/search',
        method: 'post',
        data: { vacancyId },
        onStart: vacancySubmissionsForOtherBusinessLoading.type,
        onSuccess: vacancySubmissionsForOtherBusinessLoaded.type,
        successMessage: `Vacancy submissions have been loaded for vacancy id: ${vacancyId} and other business id: ${businessId}`,
        onError: vacancySubmissionsForOtherBusinessLoadingFailed.type
    });

export const loadVacancySubmissionsByVacancyIdForVacancy = (vacancyId) =>
    apiCallBegan({
        url: getRecruitmentURL() + 'rest/vacancy-submission/search',
        method: 'post',
        data: { vacancyId },
        onStart: vacancySubmissionsForVacancyLoading.type,
        onSuccess: vacancySubmissionsForVacancyLoaded.type,
        successMessage: `Vacancy submissions for vacancy have been loaded vacancy id: ${vacancyId}`,
        onError: vacancySubmissionsForVacancyLoadingFailed.type
    });

export const updateVacancySubmissionRejected = (data) =>
    apiCallBegan({
        url: getRecruitmentURL() + `rest/vacancy-submission/reject`,
        method: 'post',
        data,
        onSuccess: vacancySubmissionsUpdated.type,
        successMessage: `Vacancy submission has been rejected`,
        onError: vacancySubmissionsLoadingFailed.type
    });

// Put selectors for getting data from state here
// Selectors take state and return computed state

// Action reducers called directly without http
export const {
    clearBusinesses,
    clearPlacements,
    clearPoolApplicationsForOwners,
    clearPoolsForOwners,
    clearVacancies,
    clearVacancySubmissions,
    setCurrentBusiness,
    setCurrentVacancy
} = slice.actions;

// Used internally
const {
    businessCreatedForLoggedInUser,
    businessCreatedForOtherUser,
    businessDeleted,
    businessesByCodeOrNameLoading,
    businessesByCodeOrNameLoaded,
    businessesByCodeOrNameLoadingFailed,
    businessesByLoggedInUserLoading,
    businessesByLoggedInUserLoaded,
    businessesByLoggedInUserLoadingFailed,
    businessesByOwnerLoading,
    businessesByOwnerLoaded,
    businessesByOwnerLoadingFailed,
    businessesLoading,
    businessesLoaded,
    businessesLoadingFailed,
    businessLoading,
    businessLoaded,
    businessLoadingFailed,
    businessUpdatedForLoggedInUser,
    businessUpdatedForOtherUser,
    configureContractPlacementLoaded,
    configureContractPlacementLoading,
    configureContractPlacementLoadingFailed,
    configureContractPlacementUpdated,
    placementCreated,
    placementLoading,
    placementUpdated,
    placementLoadingFailed,
    placementsLoading,
    placementsLoaded,
    placementsLoadingFailed,
    poolApplicationCreated,
    poolApplicationsForOwnersLoading,
    poolApplicationsForOwnersLoaded,
    poolApplicationsForOwnersLoadingFailed,
    poolApplicationLoadingFailed,
    poolApplicationsLoaded,
    poolApplicationsLoading,
    poolApplicationsLoadingFailed,
    poolApplicationsUpdated,
    poolCreated,
    poolLoadingFailed,
    poolsForOwnersLoading,
    poolsForOwnersLoaded,
    poolsForOwnersLoadingFailed,
    poolsLoaded,
    poolsLoading,
    poolsLoadingFailed,
    recruitmentParticipantTimelineEventsLoaded,
    recruitmentParticipantTimelineEventsLoading,
    recruitmentParticipantTimelineEventsLoadingFailed,
    recruitmentParticipantVacancyTimelineEventsLoaded,
    recruitmentParticipantVacancyTimelineEventsLoading,
    recruitmentParticipantVacancyTimelineEventsLoadingFailed,
    vacanciesForDistanceLoading,
    vacanciesForDistanceLoaded,
    vacanciesForDistanceLoadingFailed,
    vacanciesForOwnersLoading,
    vacanciesForOwnersLoaded,
    vacanciesForOwnersLoadingFailed,
    vacanciesLoaded,
    vacanciesLoading,
    vacanciesLoadingFailed,
    vacancyDeleted,
    vacancyLoaded,
    vacancyLoading,
    vacancyLoadingFailed,
    vacancySubmissionsCreated,
    vacancySubmissionsForBusinessLoading,
    vacancySubmissionsForBusinessLoaded,
    vacancySubmissionsForBusinessLoadingFailed,
    vacancySubmissionsForOtherBusinessLoading,
    vacancySubmissionsForOtherBusinessLoaded,
    vacancySubmissionsForOtherBusinessLoadingFailed,
    vacancySubmissionsForVacancyLoading,
    vacancySubmissionsForVacancyLoaded,
    vacancySubmissionsForVacancyLoadingFailed,
    vacancySubmissionsLoading,
    vacancySubmissionsLoaded,
    vacancySubmissionsLoadingFailed,
    vacancySubmissionsUpdated,
    vacancyUpdated
} = slice.actions;

export default slice.reducer;
