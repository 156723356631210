import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { selectUsers } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { POLARIS_WHITE, SCREEN_SETTINGS } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';

import darHistoryClasses from '../homeStyles/darHistory.module.css';

const DarHistory = ({ history, darStatuses }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const users = useSelector(selectUsers);

    useEffect(() => {
        if (history.userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === history.userId);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(history.userId));
            } else dispatch(loadUser(history.userId));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === history.userId);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    // RENDER
    return (
        <div className={darHistoryClasses.cardView}>
            <Card
                elevation={3}
                sx={{
                    backgroundColor: POLARIS_WHITE,
                    borderRadius: SCREEN_SETTINGS.radius.small
                }}>
                <div className={darHistoryClasses.banner}>
                    <span>Email: {emailAddress} </span>
                    <span>Date: {reverseFormatDate(history.date)}</span>
                    <span>Time: {history.date.substring(19, 11)} </span>
                    <span>New Status: {getNameFromId(darStatuses, history.statusId)}</span>
                </div>
                <CardContent sx={{ padding: 1 }}>
                    <div>
                        <p>
                            <span className={darHistoryClasses.label}>
                                {' '}
                                Notes: {` ${history.notes}`}
                            </span>
                        </p>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default DarHistory;

DarHistory.propTypes = {
    history: PropTypes.object,
    darStatuses: PropTypes.array
};
