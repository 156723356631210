import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import OnWarningIcon from '../../../../icons/OnWarningIcon';
import { selectIsSecondNatureReferralAttemptSuccessful } from '../../../../store/participantSelectors';
import { POLARIS_ORANGE } from '../../../../themes/theme';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';

const BloodPressureRow = ({ row, roles, onEdit, onDelete, ranges }) => {
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const acceptedDeleteRoles = [MANAGER, QUALITY, SUPERUSER];
    const [showWarning, setShowWarning] = useState(false);

    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );

    useEffect(() => {
        if (
            row.systolic <= ranges.MIN_SYSTOLIC ||
            row.systolic >= ranges.MAX_SYSTOLIC ||
            row.diastolic <= ranges.MIN_DIASTOLIC ||
            row.diastolic >= ranges.MAX_DIASTOLIC
        )
            setShowWarning(true);
        else setShowWarning(false);
    }, [ranges]);

    return (
        <TableRow hover className={`${classes.basicRowStripes}`}>
            <TableCell>{row.displayDate}</TableCell>
            <TableCell align="center">{row.systolic}</TableCell>
            <TableCell align="center">{row.diastolic}</TableCell>
            <TableCell>{row.measurement}</TableCell>
            <TableCell align="center">
                {showWarning && <OnWarningIcon tooltipContent="" color={POLARIS_ORANGE} />}
            </TableCell>
            <TableCell align="right">
                <OnEditIcon
                    onEdit={() => onEdit(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                    active={!isReferralHistoryAttemptSuccessful}
                />
            </TableCell>
            <TableCell align="right">
                <OnDeleteIcon
                    onDelete={() => onDelete(row)}
                    roles={roles}
                    acceptedRoles={acceptedDeleteRoles}
                    active={!isReferralHistoryAttemptSuccessful}
                />
            </TableCell>
        </TableRow>
    );
};

export default BloodPressureRow;

BloodPressureRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    ranges: PropTypes.object
};
