import { configureStore } from '@reduxjs/toolkit';

import api from './middleware/api';
import reducer from './reducer';

export const store = configureStore({
    // This is the combined reducer for all slices.
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['api/upload']
            }
        }).concat(api)
});
