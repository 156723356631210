import { useState } from 'react';
import PropTypes from 'prop-types';

// import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    // Icon,
    Stack,
    Typography
} from '@mui/material';

import {
    // POLARIS_DARKER_GREY,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';

import ModuleTemplateEditor from './ModuleTemplateEditor';

import classes from '../cardStyles/cardView.module.css';

/**
 * Viewer for smart target card
 *
 * Uses ModuleTemplateEditor when edit icon is clicked
 *
 * Calls the sendDelete callback when delete icon is clicked and this causes
 * CardHandler to delete this item.
 *
 * @param cardData Data for this viewer. This has values which match the
 *     module-template table in the course service
 * @param sendDelete  Callback function to tell parent  to delete cardData.
 * @param sendUpdate  Callback function to tell parent to update cardData.
 * @param disabled  If true, disables control.
 * @param barrierNameDetails  Barrier information.
 * @returns {JSX.Element}
 *
 */

const ModuleTemplateViewer = ({
    cardData,
    // sendDelete,
    sendUpdate,
    disabled = false,
    barrierNameDetails
}) => {
    const [open, setOpen] = useState(false);

    const handleCancelDelete = () => {
        setOpen(false);
    };

    // const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;

    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_ROYAL_BLUE;

    // const handleDelete = () => {
    //     if (disabled) {
    //         return;
    //     }
    //     setOpen(false);
    //     // Call onDelete in CardHandler.
    //     sendDelete(cardData);
    // };

    /*
     * This is the callback when there is a change in the editor.
     */
    const handleChange = (data) => {
        // Update handler with new data.
        // This will cause a render of this component
        // which will show the new data.
        sendUpdate(data);
        // }
    };

    const getName = (id) => {
        if (!id) {
            return '';
        }
        const match = barrierNameDetails.find((item) => item.id === id);
        return match?.name || '';
    };

    /**
     * Get a summary of card information.
     *
     * IF the action is not set, then return 'Incomplete Details'
     *
     * ELSE return the following: (See PPL-440)
     * name
     * description
     * action
     * barrier name id
     * description
     * *
     * @returns {JSX.Element}
     */
    const getCardContent = () => {
        if (
            cardData.name === undefined ||
            cardData.name === null ||
            cardData.name.trim === '' ||
            cardData.description === '' ||
            cardData.description === null ||
            cardData.description.trim === ''
        ) {
            return (
                <Typography variant="body1" sx={{ color: POLARIS_DOUBLE_DECKER_RED }}>
                    Incomplete Module Template Details
                </Typography>
            );
        }

        return (
            <div style={{ width: '250 px' }}>
                <Typography variant="body1">
                    <strong>Module Description: </strong>
                    {cardData.description.length > 100
                        ? cardData.description.substring(0, 97) + '...'
                        : cardData.description}
                </Typography>
                <Typography variant="body1">
                    {!cardData.smartTarget || cardData.smartTarget.name === null ? (
                        ''
                    ) : (
                        <strong>Action Name: </strong>
                    )}
                    {!cardData.smartTarget || cardData.smartTarget.name === null
                        ? ''
                        : cardData.smartTarget.name}
                </Typography>
                <Typography variant="body1">
                    {!cardData.smartTarget || cardData.smartTarget.barrierNameId === null ? (
                        ''
                    ) : (
                        <strong>Action Barrier: </strong>
                    )}
                    {!cardData.smartTarget || cardData.smartTarget.barrierNameId === null
                        ? ''
                        : getName(cardData.smartTarget.barrierNameId)}
                </Typography>
                <Typography variant="body1">
                    {!cardData.smartTarget || cardData.smartTarget.action === null ? (
                        ''
                    ) : (
                        <strong>Action Description: </strong>
                    )}
                    {!cardData.smartTarget || cardData.smartTarget.action === null
                        ? ''
                        : cardData.smartTarget.action.length > 200
                          ? cardData.smartTarget.action.substring(0, 197) + '...'
                          : cardData.smartTarget.action}
                </Typography>
            </div>
        );
    };

    return (
        <>
            <div className={classes.cardView}>
                <Card
                    elevation={3}
                    sx={{
                        p: 0,
                        backgroundColor: POLARIS_WHITE,
                        width: 250,
                        height: 'calc(100% - 16px)',
                        borderRadius: SCREEN_SETTINGS.radius.small
                    }}>
                    <CardHeader
                        title={
                            <div
                                className={`${
                                    cardData.name?.length > 60
                                        ? classes.bannerDoubleRow
                                        : classes.banner
                                }`}>
                                <Typography variant="h6">
                                    {cardData.name || 'Module Template'}
                                </Typography>
                            </div>
                        }
                        sx={{
                            p: 0.8,
                            m: 0.8,
                            // background color is gray if disabled
                            backgroundColor: { backgroundColor },
                            color: POLARIS_WHITE
                        }}
                        action={
                            <Stack direction="row">
                                <ModuleTemplateEditor
                                    barrierNameDetails={barrierNameDetails}
                                    disabled={disabled}
                                    sendChange={handleChange}
                                    cardData={cardData}></ModuleTemplateEditor>{' '}
                            </Stack>
                        }></CardHeader>

                    <CardContent sx={{ padding: 1 }}>{getCardContent()}</CardContent>
                </Card>
            </div>

            <Dialog open={open} onClose={handleCancelDelete}>
                <DialogTitle id="alert-dialog-title">{`Delete Module Template Details?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete the module template details?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={handleCancelDelete}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

ModuleTemplateViewer.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    barrierNameDetails: PropTypes.array.isRequired,
    sendDelete: PropTypes.func.isRequired,
    sendUpdate: PropTypes.func.isRequired
};

export default ModuleTemplateViewer;
