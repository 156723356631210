import { isDate, parse } from 'date-fns';
import * as Yup from 'yup';

const NHS_NUMBER_LENGTH = 10;
const NHS_NUMBER_MOD = 11;

/**
 * Custom yup method to validate nhsNumber
 * If called with no message the predefined error messages are used.
 * If called with a message this is used for all error messages.
 */
Yup.addMethod(Yup.mixed, 'nhsNumber', function nhsNumber(message) {
    return this.test('nhsNumber', message, function (value) {
        const { path, createError } = this;

        if (value === undefined) return true;

        value = value.replace(/\s/g, '');

        // All digits?
        if (!allDigits(value)) {
            return createError({
                path,
                message: message ?? 'NHS Number can only contain digits and spaces'
            });
        }

        if (value.length !== NHS_NUMBER_LENGTH) {
            return createError({
                path,
                message: message ?? 'NHS Number must contain ' + NHS_NUMBER_LENGTH + ' digits'
            });
        }

        if (!checkNhsNumberChecksum(value)) {
            return createError({
                path,
                message: message ?? 'NHS Number invalid, please check and try again'
            });
        }

        return true;
    });
});

/**
 * Add a method to Yup to checks string fits in specified range length.
 *
 * This can be easily validated using Yup but this has more specific error messages.
 *
 * @param {number} minLength - Minimum length of string.
 * @param {number} maxLength - Maximum length of string.
 * @param {string} message - Message to show on error. If not supplied, Messages are shown according to
 * the specific error.
 * @return true if string passes validation or an error which can be displayed.
 */
Yup.addMethod(Yup.mixed, 'stringRange', function (minLength = 1, maxLength = 10, message) {
    return this.test('stringRange', message, function (value) {
        const { path, createError } = this;

        value = value.replace(/\s/g, '');

        // At least minLength?
        if (value.length < minLength) {
            return createError({
                path,
                message:
                    message ??
                    'Too short. Must be at least ' +
                        minLength +
                        (minLength === 1 ? ' character' : ' characters')
            });
        }

        // Up to maxLength?
        if (value.length > maxLength) {
            return createError({
                path,
                message:
                    message ??
                    'Too long. Must be up to ' +
                        maxLength +
                        (maxLength === 1 ? ' character' : ' characters')
            });
        }
        return true;
    });
});

/**
 * Add a method to Yup to checks string is specified length.
 *
 * This can be easily validated using Yup but this has more specific error messages.
 *
 * @param {number} expectedLength - Expected length of string.
 * @param {string} message - Message to show on error.
 * @return true if string is expected length or an error which can be displayed.
 */
Yup.addMethod(Yup.mixed, 'stringLength', function (expectedLength, msg) {
    return this.test({
        name: 'stringLength',
        message: msg,
        test: (value) => value && value.toString().length === expectedLength
    });
});

/**
 * Return true if value string is all digits.
 *
 * @param {string} value - string to check
 * @return true if value only contains digits
 */
export function allDigits(value) {
    return /^\d+$/.test(value);
}

/**
 * Returns true if NHS number OK.
 *
 * To be valid the NHS Number must
 * contain 10 digits. These can be separated by spaces.
 * Have a valid checksum. See https://www.datadictionary.nhs.uk/attributes/nhs_number.html
 *
 * @param {string} value - NHS number to check
 * @return true if NHS Number is valid
 */
export function checkNhsNumberChecksum(value) {
    // Remove spaces
    value = value.replace(/\s/g, '');

    // Quick check if all digits and correct length first.
    if (!allDigits(value)) {
        return false;
    }
    // Check checksum is OK.
    if (value.length !== NHS_NUMBER_LENGTH) {
        return false;
    }

    let sum = 0;
    let factor = NHS_NUMBER_LENGTH;
    for (let i = 0; i < value.length - 1; i++) {
        sum = sum + value.charAt(i) * factor;
        factor--;
    }

    let step3 = sum % NHS_NUMBER_MOD;
    let step4 = NHS_NUMBER_MOD - step3;
    let checkDigit = 0;

    if (step4 === NHS_NUMBER_MOD) {
        step4 = 0;
    }

    if (step4 < NHS_NUMBER_LENGTH) {
        checkDigit = parseInt(value.charAt(NHS_NUMBER_LENGTH - 1));
    }

    return !(step4 === NHS_NUMBER_LENGTH || step4 !== checkDigit);
}

// Check only valid first name letters. Includes spaces because there may be several first names
// This probably won't be needed.
// Use this in Yup Schema with
// Yup.string().matches(validate.FIRST_NAME_REGEXP, {message: 'First name must be letters, - or ''' '}),
export const FIRST_NAME_REGEXP = /^[A-Za-z\s]+$/i;

// Needed for testing FIRST_NAME_REGEXP
export function firstName(message) {
    return FIRST_NAME_REGEXP.test(message);
}

// Only valid last name letters. Includes space, '-'  and '''
// Use this in Yup Schema with
// Yup.string().matches(validate.LAST_NAME_REGEXP, {message: 'Last name must contain letters, - or ''' '}),
export const LAST_NAME_REGEXP = /^[A-Za-z-'\s]+$/i;

// TODO Remove the above first name regular expressions when its greyed out
export const NAME_REGEXP = /^[A-Za-z'\s]+$/i;

// Only letters or digits
// Use this in Yup Schema with
// Yup.string().matches(validate.ALPHANUMERIC_REGEXP, {message: 'Value can only contain letters or digits'}),
export const ALPHANUMERIC_REGEXP = /^[A-Za-z\d]+$/i;

// Only letters or digits or space
// Use this in Yup with
// Yup.string().matches(validate.ALPHANUMERIC_SPACE_REGEXP, {message: 'Value can only contain letters, digits and spaces'}),
export const ALPHANUMERIC_SPACE_REGEXP = /^[A-Za-z\d\s]+$/i;

export const SEARCH_REGEX = /[^0-9a-z\s-,.@']/gi;

export const HTML_REGEX = /<[^>]*>/gi;

// PHONE
// A phone number. E.g. '01471 500345'  or '01471 500 345' or '44 01471 500 345' etc.
// N.B. This can probably be a lot more specific
// Use this in Yup Schema with
export const PHONE_REGEXP = /^[\\+]?[(]?[0-9]{2,3}[)]?[-\s\\.]?[0-9]{3,4}[-\s\\.]?[0-9]{4,6}$/;

// EMAIL
// Yup.string().matches(validate.EMAIL_REGEXP, {message: 'Invalid Email'}),
export const EMAIL_REGEXP = /^[\w-'.]+@(\[?)[a-zA-Z\d\-.]+\.([a-zA-Z]{2,}|\d{1,3})(]?)$/;

// Regexp for a NINo
// See https://stackoverflow.com/questions/10204378/regular-expression-to-validate-uk-national-insurance-number
// Use this in Yup Schema with
// Yup.string().matches(validate.NINO_REGEXP, {message: 'Invalid National Insurance Number'}),
export const NINO_REGEXP =
    /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s*\d\s*){6}([A-D]|\s)$/i;

// Needed to test nino
export function nino(message) {
    return NINO_REGEXP.test(message);
}

// Pay Rate
// Use this in Yup Schema with
// Yup.string().matches(validate.PAY_REGEXP, {message: 'Invalid Amount'}),
export const PAY_REGEXP = /^\d{0,3}(\.\d{1,2})?$/i;

// Postcode
// Use this in Yup Schema with
// Yup.string().matches(validate.POSTCODE_REGEXP, {message: 'Invalid Postcode'}),
export const POSTCODE_REGEXP = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;

// Price
// Use this in Yup Schema with
// Yup.string().matches(validate.PRICE_REGEXP, {message: 'Invalid Amount'}),
export const PRICE_REGEXP = /^\d{0,4}(\.\d{1,2})?$/i;

// Price
// Use this in Yup Schema with
// Yup.string().matches(validate.URL_REGEXP, {message: 'Invalid Website'}),
// export const URL_REGEXP =
//   /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i;
export const URL_REGEXP =
    /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/i;

// Integer
// Use this in Yup Schema with
// Yup.string().matches(validate.INTEGER_REGEXP, {message: 'Invalid Integer'}),
export const INTEGER_REGEXP = /^\d+$/i;

export const TENS_UNITS_TWO_DECIMAL_PLACES_REGEXP = /^(\d{1,2}|\d{0,2}\.\d{0,2})$/;

// Set undefined field to empty string
// export const StringOrUndefinedSchema = Yup.lazy((value) => {
//     switch (typeof value) {
//         case 'undefined':
//             return Yup.string().default('');
//         case 'string':
//             return Yup.string();
//         default:
//             throw new Yup.ValidationError('Value must be a string or `undefined`');
//     }
// });

// export const ObjectWithFieldSchema = Yup.object({
//     myField: StringOrUndefinedSchema
// });

// Needed for testing PRICE_REGEXP
export function adviserAuthLimit(message) {
    return PRICE_REGEXP.test(message);
}

// Needed for testing date input
export function parseDateString(value, originalValue) {
    return isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
}
