import React from 'react';

import { Card, CardContent } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';

import CallsDueManagement from './callsDueToday/CallsDueManagement';
import ClaimManagement from './claims/ClaimManagement';
import DarTableManagement from './dar/DarTableManagement';

const Home = () => {
    return (
        <Card data-testid="form_start">
            <CardContent>
                <div>
                    <Accordion TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="calls-content"
                            id="calls-header">
                            <AccordionHeader>Calls Due Today</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CallsDueManagement />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div>
                    <Accordion TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="dar-content"
                            id="dar-header">
                            <AccordionHeader>Data Amendment Requests</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DarTableManagement />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div>
                    <Accordion TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="claims-content"
                            id="claims-header">
                            <AccordionHeader>Claims</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0' }}>
                            <ClaimManagement />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </CardContent>
        </Card>
    );
};

export default Home;
