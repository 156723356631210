import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearFinancialPaymentMethodType,
    clearFinancialRequestType,
    loadFinancialPaymentMethodTypeDetails,
    loadFinancialRequestTypeDetails
} from '../../../store/directusService';
import { loadContractConstants, updateContractConstants } from '../../../store/participantService';
import { updatePreSelects, updateSelectedItems } from '../../../utils/directusFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import TextInputField from '../../formElements/TextInputField';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validateFinancialRequestsAdmin';

import form from '../../../commonStyles/formStyles.module.css';

const FinancialRequestsAdmin = ({ contractId }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        contractId: '',
        adviserApprovalLimit: null
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const viewRoles = [LOCAL_ADMIN, SUPERUSER];
    const acceptedRoles = [SUPERUSER];
    const submitMessage1 = 'financial requests';
    const submitMessage2 = 'payment methods';
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [keys, setKeys] = useState({ requestType: '0', methodType: '1' });

    const [selectedFinancialRequestTypes, setSelectedFinancialRequestTypes] = useState([]);
    const [preSelectedFinancialRequestTypeIds, setPreSelectedFinancialRequestTypeIds] = useState(
        []
    );
    const [preSelectedFinancialRequestTypes, setPreSelectedFinancialRequestTypes] = useState([]);

    const [selectedPaymentMethodTypes, setSelectedPaymentMethodTypes] = useState([]);
    const [preSelectedPaymentMethodTypeIds, setPreSelectedPaymentMethodTypeIds] = useState([]);
    const [preSelectedPaymentMethodTypes, setPreSelectedPaymentMethodTypes] = useState([]);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { financialPaymentMethodTypeDetails, financialRequestTypeDetails } = useSelector(
        (state) => state.entities.directusService
    );
    let dbContractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // USE EFFECTS
    useEffect(() => {
        if (contractId) {
            setNewEntry((prev) => ({ ...prev, contractId }));
            contractSetup();
        }
    }, [contractId]);

    useEffect(() => {
        if (Object.keys(dbContractConstants)?.length) {
            setNewEntry(dbContractConstants);
        }
    }, [dbContractConstants]);

    useEffect(() => {
        if (financialRequestTypeDetails?.length) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'financial_request_type',
                financialRequestTypeDetails,
                contractId
            );
            setPreSelectedFinancialRequestTypes(preSelects);
            setPreSelectedFinancialRequestTypeIds(preSelectIds);
        }
    }, [financialRequestTypeDetails]);

    useEffect(() => {
        if (financialPaymentMethodTypeDetails?.length) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'financial_payment_method_type',
                financialPaymentMethodTypeDetails,
                contractId
            );
            setPreSelectedPaymentMethodTypes(preSelects);
            setPreSelectedPaymentMethodTypeIds(preSelectIds);
        }
    }, [financialPaymentMethodTypeDetails]);

    useEffect(() => {
        if (
            successMessage.startsWith('Contract constants has been') ||
            successMessage.startsWith('Updating financial requests') ||
            successMessage.startsWith('Updating payment methods')
        ) {
            contractSetup();
        }
    }, [successMessage]);

    // HELPER FNS
    const contractSetup = () => {
        setIsDisabled(false);
        dbContractConstants = {};
        setSelectedFinancialRequestTypes([]);
        setSelectedPaymentMethodTypes([]);

        setPreSelectedFinancialRequestTypes([]);
        setPreSelectedPaymentMethodTypes([]);

        setErrors(initialErrorState);
        setKeys(clearKeys(keys));

        dispatch(clearFinancialRequestType());
        dispatch(clearFinancialPaymentMethodType());

        dispatch(loadFinancialRequestTypeDetails());
        dispatch(loadFinancialPaymentMethodTypeDetails());
        dispatch(loadContractConstants(contractId));
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onFinancialRequestTypeChange = (chosenIds) => {
        setIsDisabled(false);
        clearError('financialRequestTypeDetails');
        if (!chosenIds) chosenIds = [];
        setSelectedFinancialRequestTypes(chosenIds);
    };

    const onPaymentMethodTypeChange = (chosenIds) => {
        setIsDisabled(false);
        clearError('paymentMethodTypeDetails');
        if (!chosenIds) chosenIds = [];
        setSelectedPaymentMethodTypes(chosenIds);
    };

    const onAdviserApprovalLimitChange = (e) => {
        setIsDisabled(false);
        clearError('adviserApprovalLimit');
        setNewEntry((prev) => ({
            ...prev,
            adviserApprovalLimit: e.target.value.replace(/[^\d*.?]/g, '').slice(0, 7) || ''
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(
            newEntry,
            selectedFinancialRequestTypes,
            selectedPaymentMethodTypes,
            errors
        );
        setErrors(newErrors);
        setIsDisabled(true);
        if (!isValid) return;

        updateSelectedItems(
            'financial_request_type',
            financialRequestTypeDetails,
            selectedFinancialRequestTypes,
            contractId,
            submitMessage1,
            dispatch
        );

        updateSelectedItems(
            'financial_payment_method_type',
            financialPaymentMethodTypeDetails,
            selectedPaymentMethodTypes,
            contractId,
            submitMessage2,
            dispatch
        );
        dispatch(updateContractConstants(newEntry));
    };

    // AWAITING CONTENT
    let content = '';
    if (!contractId) content = 'No contract';
    if (financialRequestTypeDetails?.length < 1) content = 'No financial request type details';
    if (financialPaymentMethodTypeDetails?.length < 1)
        content = 'No financial payment method type details';

    if (!hasRole(viewRoles, roles)) return <NotFound />;

    if (
        !contractId ||
        financialRequestTypeDetails?.length < 1 ||
        financialPaymentMethodTypeDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start">
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="financialRequestTypeDetails"
                            key={keys.requestType}
                            label="Financial Request Type"
                            placeholder="Request Type"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={financialRequestTypeDetails || []}
                            preSelectedIds={preSelectedFinancialRequestTypeIds}
                            preSelects={preSelectedFinancialRequestTypes}
                            error={errors.financialRequestTypeDetails}
                            onChange={(chosenIds) => onFinancialRequestTypeChange(chosenIds)}
                        />
                        <MultiSelect
                            id="paymentMethodTypeDetails"
                            key={keys.methodType}
                            label="Financial Payment Method Type"
                            placeholder="Payment Method Type"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={financialPaymentMethodTypeDetails || []}
                            preSelectedIds={preSelectedPaymentMethodTypeIds}
                            preSelects={preSelectedPaymentMethodTypes}
                            error={errors.paymentMethodTypeDetails}
                            onChange={(chosenIds) => onPaymentMethodTypeChange(chosenIds)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'adviserApprovalLimit'}
                            label={'Adviser Approval Limit £ (9999.99 MAX)'}
                            placeholder={'Enter amount'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.adviserApprovalLimit || ''}
                            error={errors.adviserApprovalLimit}
                            onChange={onAdviserApprovalLimitChange}
                        />
                    </div>
                </div>

                <Button
                    content="UPDATE FINANCIAL REQUEST"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                />
            </form>
        </div>
    );
};

FinancialRequestsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default FinancialRequestsAdmin;
