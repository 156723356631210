import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { hideStickyMenus, showParticipantStickyMenu } from '../../../store/formsState';
import { loadParticipantSectionHeadings } from '../../../store/participantService';
import FormHeader from '../../layout/FormHeader';
import ParticipantStickyMenu from '../../navigation/participant/ParticipantStickyMenu';
import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';

import AssetRequest from './assetRequests/AssetRequest';
import ClaimsForecasting from './claimsForecasting/ClaimsForecasting';
import FinancialRequest from './financialRequests/FinancialRequest';

import classes from '../../../app.module.css';

const RequestInformation = () => {
    //  LOCAL STATE
    const dispatch = useDispatch();

    const initialState = {
        financialRequests: false,
        assetRequests: false
    };
    const [isSection, setIsSection] = useState(initialState);

    const { currentParticipant, participantSectionHeadings } = useSelector(
        (state) => state.entities.participantService
    );

    //  USE EFFECTS
    useEffect(() => {
        const { firstName, lastName, contractId } = currentParticipant;
        dispatch(loadParticipantSectionHeadings(contractId, `${firstName} ${lastName}`));
        dispatch(showParticipantStickyMenu());

        return () => {
            dispatch(hideStickyMenus());
        };
    }, [currentParticipant]);

    /**
     * participantSectionHeadings has changed so will have to clear all accordion entries and
     * if the new participantSectionHeadings exists then repopulate said accordions.
     */
    useEffect(() => {
        let sections;
        if (Object.keys(participantSectionHeadings).length !== 0) {
            sections = (({ financialRequests, assetRequests }) => ({
                financialRequests,
                assetRequests
            }))(participantSectionHeadings);
        } else {
            sections = initialState;
        }
        setIsSection(sections);
    }, [participantSectionHeadings]);

    return (
        <Card data-testid="form_start">
            <CardContent>
                <div className={classes.withSideBar}>
                    <div style={{ flex: '1 1 auto' }}>
                        <div>
                            <FormHeader text={'Financial Requests & Assets'}></FormHeader>
                            <Accordion TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<AccordionIcon />}
                                    aria-controls="panel30a-content"
                                    id="panel30a-header">
                                    <AccordionHeader>Requests</AccordionHeader>
                                </AccordionSummary>
                                <div hidden={!isSection.financialRequests}>
                                    <Accordion TransitionProps={{ mountOnEnter: true }}>
                                        <AccordionSummary
                                            expandIcon={<AccordionIcon />}
                                            aria-controls="finacial-requests-content"
                                            id="financial-header">
                                            <AccordionHeader>Financial Requests</AccordionHeader>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <FinancialRequest />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div hidden={!isSection.assetRequests}>
                                    <Accordion TransitionProps={{ mountOnEnter: true }}>
                                        <AccordionSummary
                                            expandIcon={<AccordionIcon />}
                                            aria-controls="asset-requests-content"
                                            id="asset-header">
                                            <AccordionHeader>Asset Requests</AccordionHeader>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AssetRequest />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Accordion>
                            <Accordion TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<AccordionIcon />}
                                    aria-controls="panel30a-content"
                                    id="panel30a-header">
                                    <AccordionHeader>Claims</AccordionHeader>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ClaimsForecasting />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className={classes.sideBar}>
                        <ParticipantStickyMenu />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default RequestInformation;
