import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Link, TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../icons/OnEditIcon';
import {
    deleteQuestionnaireAnswer,
    onDeleteQuestionnaireAnswer
} from '../../../store/participantService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

import classes from '../../../commonStyles/tables.module.css';

const QuestionnaireTableRow = ({ row, roles, showForm, onEdit }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [selectedRow, setSelectedRow] = useState({});
    const deleteMsg = `Questionnaire deleted`;

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeleteQuestionnaireAnswer(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLER CALLBACKS
    const onEditQuestionnaire = () => {
        onEdit(row);
    };

    const onDeleteQuestionnaire = () => {
        setSelectedRow(row);
        dispatch(deleteQuestionnaireAnswer(row.id, deleteMsg));
    };

    // RENDER
    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                {row.questionnaireCompletedTime === '' ? (
                    <TableCell>{row.questionnaireCode}</TableCell>
                ) : (
                    <TableCell>
                        {!hasRole(['SUPERUSER'], roles) && !showForm ? (
                            <Link
                                label={row.code}
                                underline="always"
                                variant="body1"
                                aria-disabled="true"
                                color="primary"
                                onClick={() => onEditQuestionnaire()}
                                sx={{
                                    paddingTop: SCREEN_SETTINGS.margin.large,
                                    cursor: 'pointer'
                                }}>
                                {row.code}
                            </Link>
                        ) : (
                            row.code
                        )}
                    </TableCell>
                )}
                <TableCell>{row?.title || ' - '}</TableCell>
                <TableCell>{reverseFormatDate(row?.dateStarted) || ' - '}</TableCell>
                <TableCell>{reverseFormatDate(row?.dateCompleted) || ' - '}</TableCell>
                <TableCell>{row?.timeCompleted || ' - '}</TableCell>
                <TableCell>{row?.draft ? 'In-Progress' : 'Completed'}</TableCell>
                <TableCell align="right">
                    {(row.draft || hasRole([SUPERUSER], roles)) && (
                        <OnEditIcon
                            onEdit={onEditQuestionnaire}
                            roles={roles}
                            active={!showForm}
                            acceptedRoles={acceptedRoles}
                            tooltip={
                                row.draft
                                    ? 'Edit questionnaire'
                                    : 'SUPERUSER can edit completed questionnaires'
                            }
                        />
                    )}
                </TableCell>
                <TableCell align="right">
                    {((row.draft && hasRole([MANAGER, QUALITY, SUPERUSER], roles)) ||
                        hasRole([SUPERUSER], roles)) && (
                        <OnDeleteIcon
                            onDelete={onDeleteQuestionnaire}
                            roles={roles}
                            acceptedRoles={acceptedRoles}
                            tooltip={
                                row.draft
                                    ? 'Delete questionnaire'
                                    : 'SUPERUSER can delete completed questionnaires'
                            }
                        />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};

export default QuestionnaireTableRow;

QuestionnaireTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    showForm: PropTypes.bool,
    onEdit: PropTypes.func
};
