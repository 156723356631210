import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectAppointmentTypesForContracts,
    selectDocumentTypes
} from '../../../store/dataSelectors';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadDocumentTypes
} from '../../../store/directusService';
import { selectUserRoles } from '../../../store/userSelectors';
import {
    getPreSelects,
    updatePreSelects,
    updateSelectedItems
} from '../../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import IconError from '../../IconError';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validateDocumentsAdmin';

import form from '../../../commonStyles/formStyles.module.css';

const DocumentsAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const viewRoles = [LOCAL_ADMIN, SUPERUSER];
    const acceptedRoles = [SUPERUSER];
    const collectionName = 'document types';
    const urlName = 'document_type';
    const calendarUrlName = 'calendar_appointment_type';
    const submitMessage = 'Updating document types collection';
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
    const [preSelectedDocumentTypes, setPreSelectedDocumentTypes] = useState([]);
    const [preSelectedDocumentTypeIds, setPreSelectedDocumentTypeIds] = useState([]);
    const [isClearSelectedDocumentTypes, setIsClearSelectedDocumentTypes] = useState('1');
    const [essentialDocumentTypes, setEssentialDocumentTypes] = useState([]);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const documentTypes = useSelector(selectDocumentTypes);
    const appointmentTypeDetailsForContracts = useSelector(selectAppointmentTypesForContracts);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    const contractSetup = () => {
        setIsDisabled(false);
        setSelectedDocumentTypes([]);
        setPreSelectedDocumentTypes([]);
        setPreSelectedDocumentTypeIds([]);
        setIsClearSelectedDocumentTypes(Math.random());
        setEssentialDocumentTypes([]);
        setErrors(initialErrorState);
        dispatch(loadDocumentTypes());
        dispatch(loadAppointmentTypeDetailsByContractIds([contractId]));
    };

    // USE EFFECTs
    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (documentTypes?.length > 0) {
            setEssentialDocumentTypes([]);
            setErrors(initialErrorState);
            const { preSelects, preSelectIds } = updatePreSelects(
                urlName,
                documentTypes,
                contractId
            );
            setPreSelectedDocumentTypes(preSelects);
            setPreSelectedDocumentTypeIds(preSelectIds);
        }
    }, [documentTypes]);

    useEffect(() => {
        if (
            !appointmentTypeDetailsForContracts ||
            appointmentTypeDetailsForContracts.length < 1 ||
            documentTypes?.length < 1
        )
            return;

        const preSelects = getPreSelects(
            calendarUrlName,
            appointmentTypeDetailsForContracts,
            contractId
        );
        const documentTypeMatches = preSelects.map((el) =>
            documentTypes.find((entry) => entry.name.toLowerCase() === el.name.toLowerCase())
        );
        setEssentialDocumentTypes(documentTypeMatches);
    }, [appointmentTypeDetailsForContracts, documentTypes]);

    useEffect(() => {
        setIsDisabled(errors.appointments.length > 0 || errors.documentTypes.error);
    }, [errors]);

    useEffect(() => {
        successMessage.includes(submitMessage) && contractSetup();
    }, [successMessage]);

    // EVENT HANDLERS
    const onDocumentTypesChange = (chosenIds) => {
        setErrors(initialErrorState);
        setIsDisabled(false);
        if (!chosenIds) chosenIds = [];
        setSelectedDocumentTypes(chosenIds);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(
            essentialDocumentTypes,
            selectedDocumentTypes,
            errors
        );
        setErrors(newErrors);
        if (!isValid) return;
        updateSelectedItems(
            urlName,
            documentTypes,
            selectedDocumentTypes,
            contractId,
            collectionName,
            dispatch
        );

        setIsDisabled(true);
    };

    // AWAITING CONTENT
    let content = '';
    if (!contractId) content = 'No contract Id';
    if (documentTypes?.length < 1) content = 'No document types';

    if (!hasRole(viewRoles, roles)) return <NotFound />;

    if (!contractId || documentTypes?.length < 1) return <LoadingSpinner content={content} />;

    // RENDER
    return (
        <form onSubmit={onSubmit} className={form.formWrapper} data-testid="form_start">
            <MultiSelect
                id="documentTypes"
                key={isClearSelectedDocumentTypes}
                label={'Document Types'}
                placeholder="Document types"
                disabled={!hasRole(acceptedRoles, roles)}
                mandatory={true}
                menuItems={documentTypes || []}
                preSelectedIds={preSelectedDocumentTypeIds}
                preSelects={preSelectedDocumentTypes}
                error={errors.documentTypes}
                onChange={(chosenIds) => onDocumentTypesChange(chosenIds)}
            />
            {errors.appointments.length > 0 && (
                <div className={form.textInputErrors}>
                    {errors.appointments.map((el) => (
                        <div key={el.id}>
                            <IconError text={el} />
                        </div>
                    ))}
                </div>
            )}

            <Button
                id="documentsAdmin"
                disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                content="UPDATE DOCUMENTS"
            />
        </form>
    );
};

DocumentsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default DocumentsAdmin;
