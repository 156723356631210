import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import { reverseFormatDate } from '../../../utils/dateFunctions';

import AllActiveCourseDetails from './AllActiveCourseDetails';
import AllActiveProvisionDetails from './AllActiveProvisionDetails';

import classes from '../../../commonStyles/tables.module.css';
const AllCourseAndProvisionTableRow = ({ row, toggleDropdown, openId, rowClassName = '' }) => {
    const [open, setOpen] = useState(null);

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    return (
        <>
            <TableRow
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen} ${rowClassName}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{reverseFormatDate(row.startDate)}</TableCell>
                <TableCell>{reverseFormatDate(row.endDate)}</TableCell>
                <TableCell>{row.modules?.length || 'Ext'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={5}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            {row.code.toString().startsWith('CI') ? (
                                <AllActiveCourseDetails row={row} onClose={() => setOpen(null)} />
                            ) : (
                                <AllActiveProvisionDetails
                                    row={row}
                                    onClose={() => setOpen(null)}
                                />
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AllCourseAndProvisionTableRow;

AllCourseAndProvisionTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    rowClassName: PropTypes.string
};
