import { getSelectValueMessage } from '../../utils/formValidation/errorMessageFunctions';

const ERROR_NO_SELECTION = getSelectValueMessage();

export const initialErrorState = {
    dataSetId: { error: true, message: '' },
    attendanceStatuses: { error: true, message: '' },
    contractId: { error: true, message: '' }
};

export const validate = (
    selectedDataSet,
    selectedAttendanceStatuses,
    selectedContractId,
    errors
) => {
    let isValid = true;
    let newErrors = errors;

    if (!selectedDataSet || Object.keys(selectedDataSet).length < 1) {
        newErrors = {
            ...newErrors,
            dataSetId: { error: true, message: ERROR_NO_SELECTION }
        };
        isValid = false;
    }

    if (selectedDataSet && selectedDataSet.name?.toLowerCase() === 'referrals created') {
        newErrors = {
            ...newErrors,
            attendanceStatuses: { error: false, message: '' }
        };
    }

    if (
        selectedDataSet &&
        selectedDataSet.name?.toLowerCase() === 'attendance outcomes' &&
        selectedAttendanceStatuses.length < 1
    ) {
        newErrors = {
            ...newErrors,
            attendanceStatuses: { error: true, message: ERROR_NO_SELECTION }
        };
        isValid = false;
    }

    if (!selectedContractId) {
        newErrors = {
            ...newErrors,
            contractId: { error: true, message: ERROR_NO_SELECTION }
        };
        isValid = false;
    }

    if (Object.values(newErrors).some((el) => el.error)) isValid = false;
    return isValid;
};
