export const NO_ASSET_REQUEST_TYPE = 'No request type selected';
export const NO_ASSET_TYPE = 'No asset type selected';
export const NO_ORDER_TYPE = 'No order type selected';

export const BUTTON_ERROR = 'There is nothing to update';

export const initialErrorState = {};

export const validate = (selectedRequestTypes, selectedAssetTypes, selectedOrderTypes) => {
    let newErrors = {};

    if (selectedRequestTypes?.length < 1) {
        newErrors = {
            ...newErrors,
            assetRequestRequestTypeDetails: { error: true, message: NO_ASSET_REQUEST_TYPE }
        };
    }

    if (selectedAssetTypes?.length < 1) {
        newErrors = {
            ...newErrors,
            assetRequestAssetDetails: { error: true, message: NO_ASSET_TYPE }
        };
    }

    if (selectedOrderTypes?.length < 1) {
        newErrors = {
            ...newErrors,
            assetRequestOrderTypeDetails: { error: true, message: NO_ORDER_TYPE }
        };
    }

    return { newErrors };
};
