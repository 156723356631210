import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Box,
    Checkbox,
    ClickAwayListener,
    Divider,
    FormControl,
    Popper,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';

import {
    POLARIS_DARKER_GREY,
    POLARIS_GREY,
    POLARIS_VICTORIA_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../themes/theme';
import IconError from '../../IconError';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * Let user select from a list of menu items
 * The selected items are shown as pills / checkbox selects and any of these can be selected.
 *
 * @param heading shown as heading. E.g. 'Housing'
 * @param thisId Used to create id and data-testid
 * @param disabled if true nothing can be selected or changed. False by default
 * @param label  shown as heading on select box.
 * @param placeholder  shown as placeholder within select box.
 * @param mandatory Shows Mandatory component if true. Default = false;
 * @param error  This has error to display in IconError
 * @param menuItems array of objects containing name to show as selected. Empty array by default
 * @param preSelectedIds  Array of ids of pre-selected options. Default = []
 * These are shown as pills at start. * @param menuItems array of ids of the selected menu item. Empty array by default
 * @param onChange Called when selection changes. This is a passed in as a callback
 * and returns an array of the selected ids *
 *
 */

// eslint-disable-next-line react/display-name
const DDLMultiOptionPicker = forwardRef(
    (
        {
            id = '',
            heading = '',
            thisId = '',
            disabled = false,
            limitTags = 3,
            label = '',
            subLabel = '',
            placeholder = '',
            mandatory = false,
            error,
            menuItems = [],
            preSelectedIds = [],
            onChange,
            disableSelectAll = false
        },
        ref
    ) => {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        const [selected, setSelected] = useState([]);
        const [checkAll, setCheckAll] = useState(false);
        const [open, setOpen] = useState(false);

        const checkAllChange = (e) => {
            setCheckAll(e.target.checked);
            e.target.checked ? setSelected(menuItems) : setSelected([]);
            setOpen(false);
        };

        const getPreSelectedOptions = (ids) => {
            const preSelected = ids
                .map((el) => menuItems.find((entry) => entry.id === el) || '')
                .filter((el) => el);
            if (preSelected.length === menuItems.length) setCheckAll(true);
            return preSelected;
        };

        // Set values on first entry
        useEffect(() => {
            if (preSelectedIds.length !== 0) {
                setSelected(getPreSelectedOptions(preSelectedIds));
            }
        }, [preSelectedIds]);

        useEffect(() => {
            if (!disabled) {
                const ids = selected.map((el) => el.id);
                onChange(ids);
            }
        }, [selected]);

        const handleClickAway = () => setOpen(false);

        return (
            <Fragment>
                <Typography variant="h6">{heading}</Typography>
                <FormControl fullWidth>
                    {heading && (
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: SCREEN_SETTINGS.margin.medium }}>
                            Please select {heading}
                        </Typography>
                    )}
                    <label htmlFor={id} aria-label={id} className={form.formLabel}>
                        {label} {mandatory && <sup>*</sup>}
                    </label>

                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box>
                            <div className={form.multiPicker}>
                                <Autocomplete
                                    id={id}
                                    multiple
                                    disabled={disabled}
                                    disableCloseOnSelect
                                    limitTags={limitTags}
                                    ref={ref}
                                    options={menuItems}
                                    value={selected.filter((el) =>
                                        menuItems.find((entry) => entry.id === el.id)
                                    )}
                                    open={open}
                                    onChange={(e, newValue, reason) => {
                                        if (reason === 'selectOption') {
                                            setSelected(newValue);
                                            if (menuItems.length === newValue.length) {
                                                setCheckAll(true);
                                            }
                                        } else if (reason === 'removeOption') {
                                            setCheckAll(false);
                                            setSelected(newValue);
                                        } else if (reason === 'clear') {
                                            setSelected([]);
                                            setCheckAll(false);
                                        }
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    PopperComponent={(param) => (
                                        <Popper {...param}>
                                            <Box
                                                open={param.open}
                                                className={param.className}
                                                role={param.role}
                                                style={param.style}>
                                                {param.children}
                                            </Box>
                                            <Divider />
                                            <Box
                                                sx={{
                                                    backgroundColor: POLARIS_WHITE,
                                                    height: '45px',
                                                    marginBottom: '10px',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    '& .MuiCheckbox-root': {
                                                        color: POLARIS_DARKER_GREY,
                                                        backgroundColor: POLARIS_WHITE
                                                    },
                                                    '& .Mui-checked': {
                                                        color: POLARIS_VICTORIA_BLUE
                                                    }
                                                }}>
                                                <div
                                                    className={`${
                                                        disableSelectAll ? form.hideField : ''
                                                    }`}>
                                                    <Checkbox
                                                        checked={checkAll}
                                                        onChange={checkAllChange}
                                                        id="check-all"
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    />
                                                    Select All
                                                </div>
                                            </Box>
                                        </Popper>
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected || checkAll}
                                                sx={{
                                                    marginRight: SCREEN_SETTINGS.margin.medium,
                                                    color: POLARIS_GREY + ' !important'
                                                }}
                                            />
                                            <Tooltip
                                                title={window.innerWidth <= 530 ? option.name : ''}>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                    {option.name}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    )}
                                    sx={{
                                        // the box with the pills
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '100%',
                                        maxHeight: SCREEN_SETTINGS.maxHeight.medium,
                                        overflowY: 'auto',
                                        paddingTop: '10px'
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={subLabel}
                                            placeholder={placeholder}
                                            sx={{
                                                '& .MuiChip-root': {
                                                    // pills
                                                    color: POLARIS_WHITE,
                                                    backgroundColor: POLARIS_VICTORIA_BLUE,
                                                    maxWidth: '100%'
                                                },
                                                '& .MuiChip-deleteIcon': {
                                                    color: POLARIS_WHITE + ' !important',
                                                    backgroundColor: POLARIS_VICTORIA_BLUE
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Box>
                    </ClickAwayListener>

                    <Box height={SCREEN_SETTINGS.gap.tiny} />
                </FormControl>
                <div className={form.multiOptionPickerErrorWrapper}>
                    {mandatory && <IconError text={error || null} data-testid={thisId + 'Error'} />}
                </div>
            </Fragment>
        );
    }
);

export default DDLMultiOptionPicker;

DDLMultiOptionPicker.propTypes = {
    id: PropTypes.string,
    heading: PropTypes.string,
    disabled: PropTypes.bool,
    limitTags: PropTypes.number,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    placeholder: PropTypes.string,
    thisId: PropTypes.string,
    mandatory: PropTypes.bool,
    error: PropTypes.any,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    preSelectedIds: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    disableSelectAll: PropTypes.bool
};
