import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import OnFileDownloadIcon from '../../../icons/OnFileDownloadIcon';
import {
    loadClaimEventStatuses,
    loadClaimEventStatusReasons,
    loadClaimEventTypes,
    loadClaimQueueActionStatusDetails,
    loadContractDetails
} from '../../../store/directusService';
import { clearClaimQueues, searchClaimQueue } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import { downloadCsv } from '../../../utils/downloadCSV';
import { trimEntries } from '../../../utils/objectUtils';
import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import SearchOnEnter from '../../search/SearchOnEnter';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DateRangePicker from '../../ui/pickers/DateRangePicker';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

import ActionEditor from './ActionEditor';
import StatusChangeEditor from './StatusChangeEditor';
import ValidationTableRow from './ValidationTableRow';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import spinner from '../../../commonStyles/loadingSpinner.module.css';
import local from '../claimStyles/validations.module.css';

const loadPageSize = 50;
const sortOrder = 'asc';
const headCells = [
    { id: 'ptCode', numeric: false, label: 'ID', sortable: true },
    { id: 'poNumber', numeric: false, label: 'PO Number', sortable: true },
    { id: 'eventType', numeric: false, label: 'Event Type' },
    { id: 'eventDate', numeric: false, label: 'Event Date', sortable: true },
    { id: 'lastStatusUpdated', numeric: false, label: 'Last Updated', sortable: true },
    { id: 'statusName', numeric: false, label: 'Status' },
    { id: 'actionStatus', numeric: false, label: 'Action Status' }
];

const ValidationTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [catId, setCatId] = useState('');

    // const [allClaimQueues, setAllClaimQueues] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowMetaData, setRowMetaData] = useState({
        order: sortOrder,
        orderBy: 'lastStatusUpdated',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    const [filters, setFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [preparingCSVForDownload, setPreparingCSVForDownload] = useState(false);
    //const [isDownloadNeeded, setIsDownloadNeeded] = useState(false);
    const [csvReadyToDownload, setCSVReadyToDownload] = useState(false);

    const [statusChangeEditor, setStatusChangeEditor] = useState(false);
    const [statusRow, setStatusRow] = useState({});

    const [clearSelectedClaimEventStatus, setClearSelectedClaimEventStatus] = useState('0');
    const [clearSelectedClaimEventType, setClearSelectedClaimEventType] = useState('1');
    const [clearSelectedContract, setClearSelectedContract] = useState('2');

    const [isOpenActionModal, setIsOpenActionModal] = useState(false);
    const [formType, setFormType] = useState('create');
    const [selectedRow, setSelectedRow] = useState({});
    const [openId, setOpenId] = useState('');
    const csvHeaderFields =
        'PO Number,PT ID,Contract,Claim Status,Event Type,Event Date,Last Updated';

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const {
        claimEventStatuses,
        claimEventStatusReasons,
        claimEventTypes,
        contractDetails,
        claimQueueActionStatusDetails
    } = useSelector((state) => state.entities.directusService);
    const { claimQueues, claimQueuesMetaData, loadingClaimQueuesSearch } = useSelector(
        (state) => state.entities.participantService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearClaimQueues());
        setClearSelectedClaimEventStatus(Math.random().toString());
        setClearSelectedClaimEventType(Math.random().toString());
        setClearSelectedContract(Math.random().toString());
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        claimEventStatusReasons?.length < 1 && dispatch(loadClaimEventStatusReasons());
        contractDetails?.length < 1 && dispatch(loadContractDetails());
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (claimQueueActionStatusDetails?.length < 1) return;
        setCatId(
            claimQueueActionStatusDetails.find((el) => el.name === 'Corrective Action Taken')?.id
        );
    }, [claimQueueActionStatusDetails]);

    useEffect(() => {
        if (Object.keys(filters).length !== 0) {
            dispatch(
                searchClaimQueue(
                    filters,
                    0,
                    rowMetaData.rowsPerPage,
                    rowMetaData.orderBy,
                    rowMetaData.order
                )
            );
        } else dispatch(clearClaimQueues());
    }, [filters]);

    useEffect(() => {
        let updatedRows = [];
        if (claimQueues.length > 0) {
            updatedRows = claimQueues.map((el) => generateClaim(el));
        }

        // setAllClaimQueues(updatedRows);
        setRows(updatedRows);
    }, [claimQueues]);

    useEffect(() => {
        if (csvReadyToDownload && claimQueues.length > 0) {
            startDownload();
        }
    }, [claimQueues, csvReadyToDownload]);

    useEffect(() => {
        if (successMessage === `Claim queue has been updated`) {
            Object.keys(filters).length !== 0 &&
                dispatch(
                    searchClaimQueue(
                        filters,
                        0,
                        rowMetaData.rowsPerPage,
                        rowMetaData.orderBy,
                        rowMetaData.order
                    )
                );
        }
    }, [successMessage]);

    // HELPER FUNCTIONS
    const generateClaim = (claim) => ({
        ...claim,
        eventName: getNameFromId(claimEventTypes, claim.eventTypeId),
        statusName: getNameFromId(claimEventStatuses, claim.statusId),
        actionStatus: claim?.actionStatusId
            ? claimQueueActionStatusDetails?.find((entry) => entry.id === claim.actionStatusId)
                  ?.name
            : 'zz'
    });

    const startDownload = () => {
        setCSVReadyToDownload(false);
        const newRows = claimQueues.map((el) => generateClaim(el));
        const name = `Live_Queue_Report_${format(new Date(), `dd_MMM_yyyy_HH_mm`)}`;
        const values = newRows.map((el) => [
            el.poNumber || '',
            el.ptCode,
            getNameFromId(contractDetails, filters.contractId),
            el.statusName,
            el.eventName,
            reverseFormatDate(el.eventDate),
            reverseFormatDate(el.lastStatusUpdated)
        ]);
        downloadCsv(csvHeaderFields, values, name);
        setPreparingCSVForDownload(false);
    };

    // EVENT HANDLERS
    const onFetchMissingPagesForDownload = () => {
        setPreparingCSVForDownload(true);
        claimQueues.forEach((el, i) => {
            if (el.length === 0) {
                Object.keys(filters).length !== 0 &&
                    dispatch(
                        searchClaimQueue(
                            filters,
                            i,
                            claimQueuesMetaData.size,
                            rowMetaData.orderBy,
                            rowMetaData.order
                        )
                    );
            }
        });
        setCSVReadyToDownload(true);
    };

    const onUpdateFilters = (chosenId, key) => {
        if (chosenId === filters[key]) return;
        if (!chosenId) {
            setFilters(trimEntries([key], filters));
        } else setFilters((prev) => ({ ...prev, [key]: chosenId }));
    };

    const onSearchDateRange = (searchRange) => {
        if (!searchRange) {
            if ('lastStatusUpdatedFrom' in filters && 'lastStatusUpdatedTo' in filters) {
                setFilters(trimEntries(['lastStatusUpdatedFrom', 'lastStatusUpdatedTo'], filters));
            }
        } else {
            const inDate = parse(searchRange.substring(0, 10), 'dd/MM/yyyy', new Date());
            const startDate = format(inDate, 'yyyy-MM-dd');
            const outDate = parse(searchRange.substring(13), 'dd/MM/yyyy', new Date());
            const endDate = format(outDate, 'yyyy-MM-dd');
            setFilters((prev) => ({
                ...prev,
                lastStatusUpdatedFrom: startDate,
                lastStatusUpdatedTo: endDate
            }));
        }
    };

    const onCreateAction = (row) => {
        setSelectedRow(row);
        setIsOpenActionModal(true);
        setFormType('create');
    };

    const onEditAction = (row) => {
        setSelectedRow(row);
        setIsOpenActionModal(true);
        setFormType('edit');
    };

    const onActionModalCancel = () => {
        setIsOpenActionModal(false);
        setSelectedRow({});
    };

    const onUpdate = () => {
        setIsOpenActionModal(false);
        setSelectedRow({});
    };

    const onTermChange = (searchRes) => {
        if (searchRes === '' && filters.code) onUpdateFilters(searchRes, 'code');
        setSearchTerm(searchRes);
    };

    const onSearch = () => onUpdateFilters(searchTerm, 'code');

    const onToggleDropdown = (id) => setOpenId(id);

    // CALLBACKS
    const onStatusChangeEditor = (setOpen) => {
        setStatusChangeEditor(setOpen);
    };

    const onStatusRow = (row) => {
        setStatusRow(row);
    };

    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <ValidationTableRow
                key={el.id}
                row={el}
                acceptedRoles={acceptedRoles}
                toggleDropdown={onToggleDropdown}
                openId={openId}
                onStatusChangeEditor={onStatusChangeEditor}
                onStatusRow={onStatusRow}
                onCreateAction={onCreateAction}
                onEditAction={onEditAction}
            />
        ));
    };

    // RENDER
    let content = '';
    if (claimEventStatuses?.length < 1) content = 'No claim event statuses';
    if (claimEventTypes?.length < 1) content = 'No claim event types';
    if (contractDetails?.length < 1) content = 'No contract details';
    if (
        claimEventStatuses?.length < 1 ||
        claimEventTypes?.length < 1 ||
        contractDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <div className={app.pageContainer}>
            <div className={local.headerBar}>
                <div className={app.sectionHeading}>Validation Queue</div>
                <div>
                    <label className={local.label} htmlFor="note">
                        Export to CSV
                    </label>
                    {preparingCSVForDownload ? (
                        <div className={local.loadingSpinnerWrapper}>
                            <div className={spinner.ddlLoader}>
                                <div className={spinner.loader} />
                            </div>
                        </div>
                    ) : (
                        <OnFileDownloadIcon
                            roles={loggedInUser.roles}
                            acceptedRoles={acceptedRoles}
                            active={
                                hasRole(acceptedRoles, loggedInUser.roles) &&
                                'contractId' in filters &&
                                'lastStatusUpdatedFrom' in filters &&
                                'lastStatusUpdatedTo' in filters &&
                                claimQueues?.length > 0
                            }
                            onDownload={onFetchMissingPagesForDownload}
                            align="center"
                        />
                    )}
                </div>
            </div>
            <form className={form.form}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div>
                            <DDLOptionPicker
                                label={'Event Type'}
                                key={clearSelectedClaimEventType}
                                id={'contractId'}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={claimEventTypes || []}
                                onChange={(chosenId) => onUpdateFilters(chosenId, 'eventTypeId')}
                            />
                            <div>
                                <strong>
                                    <Typography className={local.claimsTitle} variant={'p'}>
                                        Last Updated
                                    </Typography>
                                </strong>
                                <DateRangePicker onSearch={onSearchDateRange} />
                            </div>
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <div>
                            <DDLOptionPicker
                                label={'Contract'}
                                key={clearSelectedContract}
                                id={'contractId'}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={contractDetails || []}
                                onChange={(chosenId) => onUpdateFilters(chosenId, 'contractId')}
                            />
                            <DDLOptionPicker
                                label={'Status'}
                                key={clearSelectedClaimEventStatus}
                                id={'statusId'}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={claimEventStatuses || []}
                                onChange={(chosenId) => {
                                    if (chosenId) onUpdateFilters([chosenId], 'statusIds');
                                    else onUpdateFilters(null, 'statusIds');
                                }}
                            />

                            <div className={form.optionInputWrapper}>
                                <NoYesRadioPicker
                                    id="correctiveActivityTakenOnly"
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    radioButtonPick={false}
                                    label="Corrective Activity Taken Only"
                                    onChange={(option) =>
                                        onUpdateFilters(option ? catId : '', 'actionStatusId')
                                    }></NoYesRadioPicker>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div>
                <div className={local.claimsToolBar}>
                    <label className={form.formLabel}>Search PT ID & PO Number</label>
                    <SearchOnEnter
                        searchTerm={searchTerm}
                        placeholder="Search ID and PO..."
                        autocomplete="off"
                        search={onSearch}
                        onChange={onTermChange}
                    />
                </div>
                <StatusChangeEditor
                    open={statusChangeEditor}
                    onStatusChangeEditor={onStatusChangeEditor}
                    statusRow={statusRow}
                    claimEventStatuses={claimEventStatuses}
                    claimEventStatusReasons={claimEventStatusReasons}
                />
            </div>

            {rows.length < 1 ? (
                <div>No claims found</div>
            ) : (
                <div>
                    <ResultsTable
                        defaultOrderBy={rowMetaData.orderBy}
                        headCells={headCells}
                        loadResults={searchClaimQueue}
                        passRowMetaDataUp={setRowMetaData}
                        apiParams={filters}
                        tableRows={createRows()}
                        totalResults={claimQueuesMetaData.totalElements}
                        metaData={claimQueuesMetaData}
                        loadingValues={loadingClaimQueuesSearch}
                        loadPageSize={loadPageSize}
                    />
                </div>
            )}

            {isOpenActionModal && (
                <ActionEditor
                    roles={loggedInUser.roles}
                    formType={formType}
                    isOpen={isOpenActionModal}
                    selectedRow={selectedRow}
                    onCancel={onActionModalCancel}
                    onUpdate={onUpdate}
                />
            )}
        </div>
    );
};

export default ValidationTableManagement;
