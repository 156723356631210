import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Divider, Link, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../../store/directusService';
import { setErrorMessage } from '../../../../store/formsState';
import { createVacancy } from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE, SCREEN_SETTINGS } from '../../../../themes/theme';
import { getFutureDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import * as validate from '../../../../validation/validation';
import { TENS_UNITS_TWO_DECIMAL_PLACES_REGEXP } from '../../../../validation/validation';
import IconError from '../../../IconError';
import FormHeader from '../../../layout/FormHeader';
import LabelledPostcodeText from '../../../ui/editors/LabelledPostcodeText';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../ui/pickers/NoYesRadioPicker';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../recruitmentStyles/vacancy.module.css';

const SHIFT_PATTERN_LENGTH = 250;
const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_REQUIREMENTS_LENGTH = 250;
const PAY_RATE_FROM_GREATER_THAN_PAY_RATE_TO =
    'Pay rate from must be less than or equal to pay rate to';
const VACANCY_CLOSING_DATE_INVALID = 'Vacancy closing date must be future date';
const APPLICANT_START_DATE_INVALID = 'Applicant start date must be future date';
const EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT = 'Expected weekly hours must be 48 or less';
const EMPTY_STAGING_NOT_ALLOWED = 'Empty staging not allowed';
const POSITIONS_GREATER_THAN_ZERO = 'Number of positions must be 1 or more';

const CreateVacancy = () => {
    const {
        clearErrors,
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        title: null,
        ownerId: null,
        sectorId: null,
        hiringManagerId: null,
        numberOfPositions: null,
        payRateFrom: null,
        payRateTo: null,
        shiftPatternWorkingHours: '',
        expectedWeeklyHours: null,
        vacancyClosingDate: null,
        anticipatedStartDate: null,
        remote: false,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        city: null,
        postcode: null,
        description: '',
        requirement: '',
        inactive: null,
        stageIds: []
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [businessOwners, setBusinessOwners] = useState([]);
    const [hiringManagers, setHiringManagers] = useState([]);
    const [shiftPattern, setShiftPattern] = useState('');
    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');
    const [formValues, setFormValues] = useState([{ stageId: '', name: '' }]);
    const [staging, setStaging] = useState([]);
    const [vacancyStageDetailsNew, setVacancyStageDetailsNew] = useState([]);

    const [isClearSelectedOwner, setIsClearSelectedOwner] = useState('0');
    const [isClearSelectedJobSector, setIsClearSelectedJobSector] = useState('1');
    const [isClearSelectedHiringManager, setIsClearSelectedHiringManager] = useState('2');
    const [isClearSelectedVacancyStage, setIsClearSelectedVacancyStage] = useState('3');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { jobSectorDetails, vacancyStageDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentBusiness } = useSelector((state) => state.entities.recruitmentService);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // EVENT HANDLERS
    const handleVacancyTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, title: e.target.value }));
    };

    const handleOwnerChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, ownerId: chosenId }));
    };

    const handleJobSectorChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, sectorId: chosenId }));
    };

    const handlePositionsChange = (e) => {
        setNewEntry((prev) => ({ ...prev, numberOfPositions: e.target.value }));
    };

    const handlePayRateFromChange = (e) => {
        setNewEntry((prev) => ({ ...prev, payRateFrom: e.target.value }));
    };

    const handlePayRateToChange = (e) => {
        setNewEntry((prev) => ({ ...prev, payRateTo: e.target.value }));
    };

    const handleExpectedWeeklyHoursChange = (e) => {
        setNewEntry((prev) => ({ ...prev, expectedWeeklyHours: e.target.value }));
    };

    const handleShiftPatternChange = (e) => {
        setNewEntry((prev) => ({ ...prev, shiftPatternWorkingHours: e.target.value }));
        setShiftPattern(e.target.value || '');
    };

    const handleHiringManagerChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerId: chosenId }));
    };

    const handleVacancyClosingDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            vacancyClosingDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleAnticipatedStartDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            anticipatedStartDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleRemoteChange = (option) => {
        setNewEntry((prev) => ({
            ...prev,
            remote: option
        }));
        setValue('remoteWorking', option, {
            shouldValidate: true
        });
        if (option) {
            clearErrors('addressLine1', 'city', 'postcode');
        }
    };

    const handleAddress1Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine1: e.target.value }));
    };

    const handleAddress2Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine2: e.target.value }));
    };

    const handleAddress3Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine3: e.target.value }));
    };

    const handleCityChange = (e) => {
        setNewEntry((prev) => ({ ...prev, city: e.target.value }));
    };

    const handlePostcodeChange = (e) => {
        setNewEntry((prev) => ({ ...prev, postcode: e.target.value }));
    };

    const handleDescriptionChange = (e) => {
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
        setDescription(e.target.value);
    };

    const handleRequirementsChange = (e) => {
        setNewEntry((prev) => ({ ...prev, requirement: e.target.value }));
        setRequirements(e.target.value);
    };

    const handleVacancyStageChange = (i, chosenId) => {
        let newFormValues = [...formValues];
        newFormValues[i]['stageId'] = chosenId;
        newFormValues[i]['name'] = getNameFromId(vacancyStageDetails, chosenId);
        setStaging(newFormValues);
        setNewEntry((prev) => ({ ...prev, stageIds: newFormValues }));
    };

    // HELPER FNS
    const addFormFields = () => {
        setFormValues([...formValues, { stageId: '' }]);
    };

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
        setStaging(newFormValues);
    };

    const getStageId = (name) => {
        if (!name) return '';
        return vacancyStageDetails.find((item) => item.name === name)?.id || '';
    };

    const getName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return businessOwners.find((item) => item.id === id)?.name || '';
    };

    const clearData = () => {
        setNewEntry(initialState);
        setIsClearSelectedOwner(Math.random());
        setIsClearSelectedJobSector(Math.random());
        setIsClearSelectedHiringManager(Math.random());
        setIsClearSelectedVacancyStage(Math.random());
    };

    const onNavigate = () => {
        clearData();
        navigate('/recruitment_management', {
            state: { accordionPanel: state?.accordionPanel || 'myBusinesses' }
        });
    };

    // USE EFFECTS
    useEffect(() => {
        if (jobSectorDetails?.length < 1) dispatch(loadJobSectorDetails());
        if (vacancyStageDetails?.length < 1) dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || !('serviceIds' in loggedInUser) || loggedInUser.serviceIds.length < 1)
            return;
        if (!users || users.length < 1)
            dispatch(searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds));
        handleOwnerChange(loggedInUser.id);
    }, [loggedInUser]);

    useEffect(() => {
        setBusinessOwners(
            users
                .filter((el) =>
                    el.userTypes?.find(
                        (entry) => entry.role === MANAGER || entry.role === RECRUITMENT_MANAGER
                    )
                )
                .map(({ id, emailAddress: name }) => ({
                    id,
                    name
                }))
        );
    }, [users]);

    useEffect(() => {
        setHiringManagers(
            currentBusiness?.contactCards?.map((el) => ({
                id: el.id,
                name: `${el.firstName} ${el.lastName} ( ${el.officeNumber} )`
            }))
        );
    }, [currentBusiness]);

    useEffect(() => {
        if (successMessage === `Vacancy has been added`) onNavigate();
    }, [successMessage]);

    useEffect(() => {
        if (vacancyStageDetails?.length)
            setVacancyStageDetailsNew(
                vacancyStageDetails.filter(
                    (el) =>
                        el.name !== 'Job offered' &&
                        el.name !== 'Rejected' &&
                        el.name !== 'Submitted' &&
                        el.name !== 'Withdrawn'
                )
            );
    }, [vacancyStageDetails]);

    const onSubmit = () => {
        if (!newEntry.ownerId) {
            dispatch(setErrorMessage(`You must select a business record owner.`));
        } else if (newEntry.numberOfPositions && newEntry.numberOfPositions === '0') {
            dispatch(setErrorMessage(POSITIONS_GREATER_THAN_ZERO));
        } else if (parseFloat(newEntry.payRateTo) < parseFloat(newEntry.payRateFrom)) {
            dispatch(setErrorMessage(PAY_RATE_FROM_GREATER_THAN_PAY_RATE_TO));
        } else if (
            newEntry.payRateFrom === '0' ||
            newEntry.payRateFrom === '0.0' ||
            newEntry.payRateFrom === '0.00'
        ) {
            dispatch(setErrorMessage('Pay rate from cannot be zero'));
        } else if (
            newEntry.payRateTo === '0' ||
            newEntry.payRateTo === '0.0' ||
            newEntry.payRateTo === '0.00'
        ) {
            dispatch(setErrorMessage('Pay rate to cannot be zero'));
        } else if (
            newEntry.expectedWeeklyHours > '' &&
            48 < parseInt(newEntry.expectedWeeklyHours)
        ) {
            dispatch(setErrorMessage(EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT));
        } else if (staging.some((el) => el.stageId === '')) {
            dispatch(setErrorMessage(EMPTY_STAGING_NOT_ALLOWED));
        } else {
            const startId = getStageId('Submitted');
            const endId = getStageId('Job offered');
            const arrValues = staging.map(({ stageId }) => stageId);
            const newArr = [startId, ...arrValues, endId];
            const payload = {
                ...newEntry,
                businessRecordId: currentBusiness.id,
                inactive: false,
                stageIds: newArr
            };
            dispatch(createVacancy(payload));
        }
    };

    const onCancel = () => onNavigate();

    if (jobSectorDetails?.length < 1) return <LoadingSpinner content="No job sector found" />;
    if (vacancyStageDetails?.length < 1) return <LoadingSpinner content="No vacancy stage found" />;

    return (
        <div className={form.formWrapper}>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start" className={form.form}>
                <FormHeader text={'Create Vacancy'}></FormHeader>
                {/* This is needed for remote working validations */}
                <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                    <input
                        id="remoteWorking"
                        name="remoteWorking"
                        value={newEntry.remote || false}
                        {...register('remoteWorking')}></input>
                </div>
                <h2 className={app.sectionHeading}>Vacancy Overview:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Vacancy Title'}
                            id={'title'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.title || ''}
                            placeholder={'Enter vacancy title'}
                            error={errors.title}
                            {...register('title')}
                            onChange={handleVacancyTitleChange}
                        />
                        <DDLOptionPicker
                            label={'Vacancy Owner'}
                            id={'ownerId'}
                            key={isClearSelectedOwner}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            menuItems={businessOwners || []}
                            chosenName={newEntry.ownerId ? getName(newEntry.ownerId) : ''}
                            chosenId={newEntry.ownerId || ''}
                            error={errors.ownerId}
                            {...register('ownerId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleOwnerChange(e);
                                }
                            }}
                        />
                        <LabelledTextField
                            label={'Business Record'}
                            id={'title'}
                            disabled={true}
                            value={currentBusiness.name}
                        />
                        <DDLOptionPicker
                            label={'Hiring Manager'}
                            id={'managerId'}
                            key={isClearSelectedHiringManager}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            menuItems={hiringManagers || []}
                            error={errors.managerId}
                            {...register('managerId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleHiringManagerChange(e);
                                }
                            }}
                        />
                        <DDLOptionPicker
                            label={'Job Sector'}
                            id={'sectorId'}
                            key={isClearSelectedJobSector}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            menuItems={jobSectorDetails}
                            error={errors.sectorId}
                            {...register('sectorId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleJobSectorChange(e);
                                }
                            }}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Number of Positions'}
                            id={'positions'}
                            type="number"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.numberOfPositions || ''}
                            placeholder={'Enter number of Positions'}
                            error={errors.positions}
                            {...register('positions')}
                            onChange={handlePositionsChange}
                        />
                        <LabelledTextField
                            label={'Description'}
                            id={'description'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            multiline
                            rows={5}
                            value={description}
                            placeholder={'Enter job description'}
                            counter={'true'}
                            helperText={`${description.length}` + '/' + MAX_DESCRIPTION_LENGTH}
                            inputProps={{
                                maxLength: MAX_DESCRIPTION_LENGTH
                            }}
                            onChange={handleDescriptionChange}
                        />
                        <LabelledTextField
                            label={'Requirements'}
                            id={'requirements'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            multiline
                            rows={5}
                            value={requirements}
                            placeholder={'Enter job requirements'}
                            counter={'true'}
                            helperText={`${requirements.length}` + '/' + MAX_REQUIREMENTS_LENGTH}
                            inputProps={{
                                maxLength: MAX_REQUIREMENTS_LENGTH
                            }}
                            onChange={handleRequirementsChange}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Vacancy Detail:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Hourly Pay Rate - From'}
                            id={'payRateFrom'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.payRateFrom || ''}
                            placeholder={'Enter hourly pay rate from'}
                            error={errors.payRateFrom}
                            {...register('payRateFrom')}
                            onChange={handlePayRateFromChange}
                        />
                        <LabelledTextField
                            label={'Hourly Pay Rate - To'}
                            id={'payRateTo'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.payRateTo || ''}
                            placeholder={'Enter hourly pay rate to'}
                            error={errors.payRateTo}
                            {...register('payRateTo')}
                            onChange={handlePayRateToChange}
                        />
                        <TextField
                            label="Vacancy Closing Date *"
                            id="vacancyClosingDate"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            type="date"
                            value={newEntry.vacancyClosingDate || ''}
                            sx={{ width: 220, marginBottom: '50px', marginRight: '20px' }}
                            InputLabelProps={{ shrink: true }}
                            {...register('vacancyClosingDate')}
                            onChange={handleVacancyClosingDateChange}
                        />
                        <TextField
                            label="Applicant Start Date"
                            id="anticipatedStartDate"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            type="date"
                            value={newEntry.anticipatedStartDate || ''}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                            {...register('anticipatedStartDate')}
                            onChange={handleAnticipatedStartDateChange}
                        />
                        <IconError
                            text={errors.vacancyClosingDate || errors.anticipatedStartDate || null}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Expected Weekly Hours'}
                            id={'expectedWeeklyHours'}
                            type="number"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            size="small"
                            value={newEntry.expectedWeeklyHours || ''}
                            placeholder={'Enter maximum'}
                            error={errors.expectedWeeklyHours}
                            {...register('expectedWeeklyHours')}
                            onChange={handleExpectedWeeklyHoursChange}
                        />
                        <LabelledTextField
                            label={'Shift Patterns/Working Hours'}
                            id={'shiftPattern'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={false}
                            multiline
                            rows={5}
                            value={shiftPattern}
                            placeholder={'Enter shift patterns/working hours description'}
                            counter={'true'}
                            helperText={`${shiftPattern.length}` + '/' + SHIFT_PATTERN_LENGTH}
                            inputProps={{
                                maxLength: SHIFT_PATTERN_LENGTH
                            }}
                            onChange={handleShiftPatternChange}
                        />
                    </div>
                </div>
                <br />
                <br />
                <h2 className={app.sectionHeading}>Address:</h2>
                <NoYesRadioPicker
                    disabled={
                        !hasRole(
                            [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                            loggedInUser.roles
                        )
                    }
                    text={'Remote?'}
                    radioButtonPick={newEntry.remote || false}
                    onChange={handleRemoteChange}
                />
                <div hidden={newEntry.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Address 1'}
                                id={'address1'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                mandatory={true}
                                value={newEntry.addressLine1 || ''}
                                placeholder={'Enter address 1'}
                                error={errors.address1}
                                {...register('address1')}
                                onChange={handleAddress1Change}
                            />
                            <LabelledTextField
                                label={'Address 2'}
                                id={'address2'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                value={newEntry.addressLine2 || ''}
                                placeholder={'Enter address 2'}
                                error={errors.address2}
                                {...register('address2')}
                                onChange={handleAddress2Change}
                            />
                            <LabelledTextField
                                label={'Address 3'}
                                id={'address3'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                value={newEntry.addressLine3 || ''}
                                placeholder={'Enter address 3'}
                                error={errors.address3}
                                {...register('address3')}
                                onChange={handleAddress3Change}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Town/City'}
                                id={'city'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                mandatory={true}
                                value={newEntry.city || ''}
                                placeholder={'Enter town/city'}
                                error={errors.city}
                                {...register('city')}
                                onChange={handleCityChange}
                            />
                            <LabelledPostcodeText
                                label={'Postcode'}
                                id={'postcode'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                mandatory={true}
                                placeholder={'Enter Postcode'}
                                value={newEntry.postcode || ''}
                                name="postcode"
                                error={errors.postcode}
                                {...register('postcode')}
                                onChange={handlePostcodeChange}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process:</h2>
                <p>Select all the items involved in the application process</p>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <Typography variant="body1Bold">Stage 1</Typography>
                        <p>Submitted</p>
                        {formValues.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <DDLOptionPicker
                                            label={'Stage ' + `${i + 2}`}
                                            id={'stageId'}
                                            name={'stageId'}
                                            key={isClearSelectedVacancyStage}
                                            disabled={
                                                !hasRole(
                                                    [
                                                        MANAGER,
                                                        QUALITY,
                                                        RECRUITMENT_MANAGER,
                                                        SUPERUSER
                                                    ],
                                                    loggedInUser.roles
                                                )
                                            }
                                            chosenName={el.name || ''}
                                            chosenId={el.stageId || ''}
                                            menuItems={vacancyStageDetailsNew}
                                            onChange={(chosenId) =>
                                                handleVacancyStageChange(i, chosenId)
                                            }
                                        />
                                    </div>
                                    <div className={form.inputSetAction}>
                                        {i ? (
                                            <DeleteIcon
                                                className={classes.deleteIcon}
                                                onClick={() => removeFormFields(i)}
                                                sx={{
                                                    color: POLARIS_ROYAL_BLUE,
                                                    marginTop: '10px'
                                                }}></DeleteIcon>
                                        ) : (
                                            <DeleteIcon
                                                disabled={true}
                                                sx={{
                                                    color: POLARIS_DARKER_GREY,
                                                    marginTop: '10px'
                                                }}></DeleteIcon>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Typography variant="body1Bold">Final Stage</Typography>
                        <p>Job offered</p>
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <Button
                    className={classes.addStageButton}
                    disabled={
                        !hasRole(
                            [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                            loggedInUser.roles
                        ) ||
                        formValues[formValues.length - 1].stageId === '' ||
                        formValues.length === 8
                    }
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={() => addFormFields()}>
                    Add Stage
                    <Box width={SCREEN_SETTINGS.gap.medium} />
                    <AddCircleOutlineIcon />
                </Button>
                <p></p>
                <br />
                <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                <Stack direction="row" spacing={4}>
                    <Button
                        disabled={
                            !hasRole(
                                [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                loggedInUser.roles
                            )
                        }
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="testIdSubmitButton1"
                        endIcon={<KeyboardArrowRightIcon />}>
                        {'Create Vacancy'}
                    </Button>
                    <Link
                        label="Cancel X"
                        underline="always"
                        variant="body1"
                        color="primary"
                        onClick={() => onCancel()}
                        sx={{
                            paddingTop: SCREEN_SETTINGS.margin.large,
                            cursor: 'pointer'
                        }}>
                        Cancel X
                    </Link>
                </Stack>
                <br />
                <p>{}</p>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Vacancy title must be at least one character')
        .max(100, 'Vacancy title must be 100 characters or less'),
    sectorId: Yup.string().required('Please select a job sector.'),
    managerId: Yup.string().required('Please select a hiring manager.'),
    positions: Yup.string()
        .min(1, 'Number of positions must be 1 or more')
        .max(3, 'Number of positions must be 999 or less')
        .matches(validate.INTEGER_REGEXP, {
            excludeEmptyString: true,
            message: 'Please enter a valid integer'
        }),
    payRateFrom: Yup.string()
        .required('Please enter a valid From pay rate')
        .nullable()
        .min(0, 'From pay rate limit cannot be negative')
        .max(999.99, 'From pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid From pay rate'),
    payRateTo: Yup.string()
        .required('Please enter a valid To pay rate')
        .nullable()
        .min(0, 'To pay rate limit cannot be negative')
        .max(999.99, 'To pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid To pay rate'),
    vacancyClosingDate: Yup.string()
        .required('Please select a vacancy closing date')
        .nullable()
        .test('vacancyClosingDate', VACANCY_CLOSING_DATE_INVALID, function (vacancyClosingDate) {
            return getFutureDate(vacancyClosingDate) === true;
        }),
    anticipatedStartDate: Yup.string()
        .nullable()
        .test(
            'anticipatedStartDate',
            APPLICANT_START_DATE_INVALID,
            function (anticipatedStartDate) {
                return getFutureDate(anticipatedStartDate) === true;
            }
        ),
    expectedWeeklyHours: Yup.number()
        .nullable()
        .positive()
        .test(
            'isDecimal',
            'The amount should be a decimal with maximum two digits after point',
            (val) => {
                if (val !== undefined) {
                    return TENS_UNITS_TWO_DECIMAL_PLACES_REGEXP.test(val);
                }
                return true;
            }
        )
        .min(0.01, 'Expected weekly hours cannot be negative, blank or zero')
        .max(48, 'Expected weekly hours must be 48 or less'),
    remoteWorking: Yup.boolean(),
    address1: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () =>
            Yup.string()
                .min(1, 'Address 1 must be at least one character')
                .max(100, 'Address 1 must be 100 characters or less')
    }),
    address2: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () => Yup.string().max(100, 'Address 2 must be 100 characters or less')
    }),
    address3: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () => Yup.string().max(100, 'Address 3 must be 100 characters or less')
    }),
    city: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () =>
            Yup.string()
                .min(1, 'City must be at least one character')
                .max(30, 'City must be 30 characters or less')
    }),
    postcode: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () =>
            Yup.string().matches(validate.POSTCODE_REGEXP, {
                excludeEmptyString: false,
                message: 'Please enter a valid postcode'
            })
    })
});

export default CreateVacancy;
