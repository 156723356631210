import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../../store/participantService';
import { capitalize } from '../../../utils/capitalize';

import classes from '../../../commonStyles/tables.module.css';

const ParticipantTableRow = ({ row, showSensitiveCells }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);

    const { currentParticipant } = useSelector((state) => state.entities.participantService);

    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && loaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    const onNav = () => {
        dispatch(loadParticipantByPtCode(row.ptCode));
        setLoaded(true);
    };

    return (
        <>
            <TableRow
                hover
                className={`${showSensitiveCells ? classes.basicRowStripes : classes.noStripes}`}>
                <TableCell className={classes.rowLink} onClick={onNav}>
                    {row.ptCode}
                </TableCell>
                <TableCell>{capitalize(row.firstName ?? '')}</TableCell>
                {showSensitiveCells && <TableCell>{capitalize(row.lastName ?? '')}</TableCell>}
                {showSensitiveCells && <TableCell>{row.postcode?.toUpperCase() ?? ''}</TableCell>}
                {showSensitiveCells && (
                    <TableCell>{row.uniqueIdentifier?.toUpperCase() ?? ''}</TableCell>
                )}
                {showSensitiveCells && <TableCell>{row.primaryPhoneNumber ?? ''}</TableCell>}
                {showSensitiveCells && <TableCell>{row.emailAddress ?? ''}</TableCell>}
            </TableRow>
        </>
    );
};

export default ParticipantTableRow;

ParticipantTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    showSensitiveCells: PropTypes.bool.isRequired
};
