import { getSelectValueMessage } from '../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {
    contractId: { error: true, message: '' }
};

export const validate = (chosenContract, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!chosenContract || Object.keys(chosenContract).length < 1) {
        newErrors = {
            ...newErrors,
            contractId: { error: true, message: getSelectValueMessage() }
        };
        isValid = false;
    }

    if (Object.values(newErrors).some((el) => el.error)) isValid = false;
    return isValid;
};
