import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { loadClaimEventStatuses } from '../../store/directusService';
import {
    createClaimEvent,
    searchClaimEvents,
    updateClaimEvent
} from '../../store/participantService';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import IconError from '../IconError';

import ClaimStatusField from './ClaimStatusField';

import form from '../../commonStyles/formStyles.module.css';

const Claim = ({ eligibilityGroupId, claim, claimEvent, roles, onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialErrorState = { isStartedSet: { error: false, message: '' } };
    const initialState = {
        id: '',
        eligibilityGroupId: '',
        claimTypeId: '',
        claimTypeStatuses: []
    };
    const acceptedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [msg, setMsg] = useState(`Claim event has been created`);
    const [existingClaim, setExistingClaim] = useState({});

    // STORE STATE
    const claimEventStatuses = useSelector(
        (state) => state.entities.directusService.claimEventStatuses
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());

        dispatch(searchClaimEvents({ eligibilityGroupId, claimTypeId: claim.id }));
        setNewEntry((prev) => ({ ...prev, eligibilityGroupId, claimTypeId: claim.id }));
    }, [claim.id]);

    useEffect(() => {
        if (
            claimEvent.claimTypeId !== claim.id ||
            claimEvent.eligibilityGroupId !== eligibilityGroupId ||
            claimEventStatuses?.length < 1
        )
            return;

        if (Object.keys(claimEvent?.claimTypeStatuses).length < 1) {
            const initialStatuses = claimEventStatuses.map((el) => ({
                statusId: el.id,
                startingStatus: false,
                claimTypeToStatusIds: []
            }));
            setNewEntry((prev) => ({
                ...prev,
                claimTypeStatuses: initialStatuses
            }));
            return;
        }

        setExistingClaim(claimEvent);
        setNewEntry(claimEvent);
        setMsg(`Claim event has been updated`);
    }, [claimEvent, claimEventStatuses]);

    useEffect(() => {
        if (successMessage === msg) onExit();
    }, [successMessage]);

    // EVENT HANDLERS
    const onUpdateFromStatus = (statusId, checked) => {
        checked
            ? setNewEntry((prev) => ({
                  ...prev,
                  claimTypeStatuses: [
                      ...newEntry.claimTypeStatuses,
                      { statusId, startingStatus: false, claimTypeToStatusIds: [] }
                  ]
              }))
            : setNewEntry((prev) => ({
                  ...prev,
                  claimTypeStatuses: newEntry.claimTypeStatuses
                      .filter((el) => el.statusId !== statusId)
                      .map((el) => ({
                          ...el,
                          claimTypeToStatusIds: el.claimTypeToStatusIds.filter(
                              (entry) => entry !== statusId
                          )
                      }))
              }));
    };

    const onUpdateStarting = (statusId, checked) => {
        const updated = newEntry.claimTypeStatuses.map((el) => ({
            ...el,
            startingStatus: el.statusId === statusId ? checked : el.startingStatus
        }));
        setNewEntry((prev) => ({ ...prev, claimTypeStatuses: updated }));
    };

    const onUpdateToStatuses = (statusId, ids) => {
        const claimTypeStatuses = newEntry.claimTypeStatuses.map((el) =>
            el.statusId === statusId ? { ...el, claimTypeToStatusIds: ids } : el
        );
        setNewEntry((prev) => ({ ...prev, claimTypeStatuses }));
    };

    const onExit = () => onClose();

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors(initialErrorState);
        if (!newEntry.claimTypeStatuses.some((el) => el.startingStatus)) {
            setErrors((prev) => ({
                ...prev,
                isStartedSet: {
                    error: true,
                    message: 'A starting status must be set'
                }
            }));
            return;
        }
        newEntry?.id
            ? dispatch(updateClaimEvent(newEntry, msg))
            : dispatch(createClaimEvent(newEntry, msg));
    };

    // RENDER

    return (
        <div>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                {claimEventStatuses.map((el) => (
                    <ClaimStatusField
                        key={el.id}
                        claimId={claim.id}
                        status={el}
                        roles={roles}
                        newEntry={newEntry}
                        existingClaim={existingClaim}
                        onUpdateToStatuses={onUpdateToStatuses}
                        onUpdateFromStatus={onUpdateFromStatus}
                        onUpdateStarting={onUpdateStarting}
                    />
                ))}

                {errors.isStartedSet.error && (
                    <div className={form.textInputError}>
                        <IconError text={errors.isStartedSet} />
                    </div>
                )}
                <Button
                    disabled={!hasRole(acceptedRoles, roles)}
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onSubmit}>
                    Update Claim Type
                </Button>
            </form>
        </div>
    );
};

export default Claim;

Claim.propTypes = {
    eligibilityGroupId: PropTypes.string,
    claim: PropTypes.object,
    claimEvent: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func
};
