import axios from 'axios';

export let BASE_URL_CALENDAR_SERVICE;
export let BASE_URL_COMMUNICATION_SERVICE;
export let BASE_URL_COURSE_SERVICE;

export let BASE_URL_DOCUMENT_SERVICE;
export let BASE_URL_DIRECTUS_SERVICE;
export let BASE_URL_PARTICIPANT_SERVICE;
export let BASE_URL_RECRUITMENT_SERVICE;
export let BASE_URL_USER_SERVICE;

export function setURLs(environment) {
    BASE_URL_CALENDAR_SERVICE = environment.calendarUrl;
    BASE_URL_COMMUNICATION_SERVICE = environment.communicationUrl;
    BASE_URL_COURSE_SERVICE = environment.courseUrl;
    BASE_URL_DOCUMENT_SERVICE = environment.documentUrl;
    BASE_URL_DIRECTUS_SERVICE = environment.directusUrl;
    BASE_URL_PARTICIPANT_SERVICE = environment.participantUrl;
    BASE_URL_RECRUITMENT_SERVICE = environment.recruitmentUrl;
    BASE_URL_USER_SERVICE = environment.userUrl;
}

export function getCalendarURL() {
    return BASE_URL_CALENDAR_SERVICE;
}

export function getCommunicationURL() {
    return BASE_URL_COMMUNICATION_SERVICE;
}

export function getCourseURL() {
    return BASE_URL_COURSE_SERVICE;
}

export function getDirectusURL() {
    return BASE_URL_DIRECTUS_SERVICE;
}

export function getDocumentURL() {
    return BASE_URL_DOCUMENT_SERVICE;
}
export function getParticipantURL() {
    return BASE_URL_PARTICIPANT_SERVICE;
}

export function getRecruitmentURL() {
    return BASE_URL_RECRUITMENT_SERVICE;
}

export function getUserURL() {
    return BASE_URL_USER_SERVICE;
}

export function getHeader() {
    return HEADERS;
}

export function setHeader(header) {
    HEADERS = header;
}

//initial HEADER has no auth, set in General when the user has logged in
export let HEADERS = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
};

/**
 * Check if item exists.
 *
 * Call this before trying to create items
 *
 * @param url URL to endpoint which checks if item exists.
 *            E.g.http://localhost:9000/user-service/rest/team/exists/Reading Office
 * @returns {Promise<boolean>} true if the item exists
 */
export const checkExists = async (url) => {
    let exists = false;
    await axios
        .get(url, {
            headers: HEADERS
        })
        .then(function (response) {
            if (response.data) {
                exists = true;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    return exists;
};

export const applyPagination = (url, term, page = 0, size = 10, dir = 'asc', sortBy) => {
    return `${url}${term ? term : ''}?page=${page}&size=${size}&dir=${dir}${sortBy ? `&sort=${sortBy}` : ''}`;
};
