import React from 'react';
import PropTypes from 'prop-types';

import IconError from '../IconError';

import common from './formElementStyles/commonFormElements.module.css';
import radio from './formElementStyles/radioButtons.module.css';

const RadioButtons = ({
    id,
    label = '',
    onChange,
    radioButtons = [
        { id: 'No', label: 'No', onChangeVal: false },
        { id: 'Yes', label: 'Yes', onChangeVal: true }
    ],
    disabled = false,
    value,
    error = {}
}) => {
    return (
        <div className={`${common.fieldWrapper} ${radio.radioButtonWrapper}`}>
            <fieldset id={id} data-testid={`fieldset_${id}`}>
                <legend className={common.formLabel}>{label}</legend>
                {radioButtons.map((el) => (
                    <div key={el.id} className={radio.buttonGroup}>
                        <input
                            id={el.id}
                            data-testid={`radio_button_${el.id}`}
                            type="radio"
                            name={id}
                            value={el.id}
                            checked={el.onChangeVal === value || el.id === value}
                            disabled={disabled}
                            onChange={() => onChange(el.onChangeVal)}
                        />
                        <label id={el.id} htmlFor={el.id}>
                            {el.label}
                        </label>
                    </div>
                ))}
            </fieldset>
            {error?.error && (
                <div>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

RadioButtons.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    radioButtons: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.object
};

export default RadioButtons;
