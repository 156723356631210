import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    selectFinancialRequestStatus,
    selectFinancialRequestType
} from '../../../../store/dataSelectors';
import {
    loadFinancialPaymentMethodTypeDetails,
    loadFinancialRequestStatusDetails,
    loadFinancialRequestTypeDetails
} from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectParticipantFinancialRequests
} from '../../../../store/participantSelectors';
import { loadFinancialRequests } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CreateFinancialRequest from './CreateFinancialRequest';
import EditFinancialRequest from './EditFinancialRequest';
import FinancialRequestTable from './FinancialRequestTable';

import classes from './financialRequest.module.css';

const FinancialRequest = () => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const participantFinancialRequests = useSelector(selectParticipantFinancialRequests);
    const financialRequestStatusDetails = useSelector(selectFinancialRequestStatus);
    const financialRequestTypeDetails = useSelector(selectFinancialRequestType);

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        if (!currentParticipant?.id) return;

        dispatch(loadFinancialRequests(currentParticipant.id));
        dispatch(loadFinancialRequestStatusDetails());
        dispatch(loadFinancialRequestTypeDetails());
        dispatch(loadFinancialPaymentMethodTypeDetails());
    }, [currentParticipant]);

    useEffect(() => {
        if (
            !participantFinancialRequests.length ||
            !financialRequestStatusDetails?.length ||
            !financialRequestTypeDetails?.length
        )
            return;
        const rows = participantFinancialRequests.map((el) => ({
            ...el,
            code: el.code,
            type:
                financialRequestTypeDetails.find((entry) => entry.id === el.requestTypeId)?.name ||
                '',
            amount: el.amount,
            status:
                financialRequestStatusDetails.find((entry) => entry.id === el.statusId)?.name || '',
            statusChangeDate: el.statusChangeDate,
            dateIssued: el.dateIssued
        }));

        setRows(rows);
    }, [financialRequestStatusDetails, financialRequestTypeDetails, participantFinancialRequests]);

    // EVENT HANDLERS
    const onCreateFinancialRequest = () => {
        setFormType('create');
        setShowForm(true);
    };

    const onEditFinancialRequest = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
    };

    return (
        <div className={classes.financialRequests}>
            <h3>Financial Request Details</h3>
            <div className={classes.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No financial requests found" />
                ) : (
                    <FinancialRequestTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEditFinancialRequest}
                    />
                )}
            </div>
            <div>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(
                                [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                roles
                            )
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreateFinancialRequest}>
                        Add Financial Request
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                {showForm && formType === 'create' && (
                    <CreateFinancialRequest onClose={() => setShowForm(false)} roles={roles} />
                )}
                {showForm && formType === 'edit' && (
                    <EditFinancialRequest
                        onClose={() => setShowForm(false)}
                        roles={roles}
                        row={selectedRow}
                    />
                )}
            </div>
        </div>
    );
};

export default FinancialRequest;
