import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadClaimEventStatuses, loadClaimEventTypes } from '../../../../store/directusService';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import {
    loadClaimQueueForecast,
    searchClaimEvents,
    searchClaimQueue
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { getNameFromId } from '../../../../utils/directusFunctions';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import ClaimsForecastingTable from './ClaimsForecastingTable';

const ClaimsForecasting = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [rowsWithClaimQueueStatusName, setRowsWithClaimQueueStatusName] = useState([]);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const { claimEvents, claimQueueForecast, claimQueues } = useSelector(
        (state) => state.entities.participantService
    );
    const { claimEventStatuses, claimEventTypes } = useSelector(
        (state) => state.entities.directusService
    );

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        if (!currentParticipant?.id) return;

        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
        dispatch(
            searchClaimEvents({
                eligibilityGroupId: currentParticipant.eligibilityGroupId
            })
        );
        dispatch(loadClaimQueueForecast(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (claimEvents?.length < 1) return;

        dispatch(searchClaimQueue({ ptCodes: [currentParticipant.ptCode] }));
    }, [claimEvents]);

    useEffect(() => {
        if (claimQueues?.length < 1) return;

        setRowsWithClaimQueueStatusName(
            claimEvents.map((el) => {
                const statusId = claimQueues.find(
                    (entry) => entry.eventTypeId === el.claimTypeId
                )?.statusId;

                return {
                    ...el,
                    claimTypeName: getNameFromId(claimEventTypes, el.claimTypeId),
                    claimQueueStatusName: statusId
                        ? getNameFromId(claimEventStatuses, statusId)
                        : ' - '
                };
            })
        );
    }, [claimQueues]);

    useEffect(() => {
        if (rowsWithClaimQueueStatusName?.length < 1 || claimQueueForecast?.length < 1) return;

        const rowsWithClaimQueueForecastDates = rowsWithClaimQueueStatusName.map((el) => {
            const row = claimQueueForecast.find((entry) => {
                return entry.eventTypeId === el.claimTypeId;
            });

            return {
                ...el,
                dueDate: row?.dueDate || '',
                eventDate: row?.eventDate || ''
            };
        });
        setRows(rowsWithClaimQueueForecastDates);
    }, [rowsWithClaimQueueStatusName, claimQueueForecast]);

    let content;
    if (claimEventStatuses?.length < 1) content = 'No claim event statuses found';
    if (claimEvents?.length < 1) content = 'No claim events found';
    if (claimQueues?.length < 1) content = 'No claim queues found';

    if (claimEventStatuses?.length < 1 || claimEvents?.length < 1 || claimQueues?.length < 1)
        return <LoadingSpinner content={content} />;

    // RENDER
    return (
        <div>
            {rows.length < 1 ? (
                <LoadingSpinner content="No claim forecasts found" />
            ) : (
                <ClaimsForecastingTable rows={rows} roles={roles} />
            )}
        </div>
    );
};

export default ClaimsForecasting;
