import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import { loadParticipantByPtCode } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { hasRole } from '../../../utils/userRoles';

import DarDetails from './DarDetails';

import classes from '../../../commonStyles/tables.module.css';
import darClasses from '../homeStyles/dar.module.css';

const DarTableRow = ({
    row,
    roles,
    toggleDropdown,
    openId,
    darStatuses,
    onDarChangeEditor,
    onDarRow
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [open, setOpen] = useState(null);

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    function onEditParticipant() {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            navigate('/edit_participant');
        }
    }

    function onDarStatus() {
        if (row?.statusId) {
            onDarRow(row);
            onDarChangeEditor(true);
        }
    }

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}>
                <TableCell
                    className={darClasses.darCodeLink}
                    onClick={onDarStatus}
                    disabled={!hasRole(roles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row.code}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row.serviceName}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.dateCreated)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.dateUpdated)}
                </TableCell>
                <TableCell
                    className={darClasses.darCodeLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(roles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row.priorityName}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {row.statusName.toLowerCase() === 'zzclosed'
                        ? row.statusName.substring(2, 8)
                        : row.statusName}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={7}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <DarDetails
                                row={row}
                                onClose={() => setOpen(null)}
                                darStatuses={darStatuses}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DarTableRow;

DarTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    toggleDropdown: PropTypes.func,
    openId: PropTypes.string,
    darStatuses: PropTypes.array,
    onDarChangeEditor: PropTypes.func,
    onDarRow: PropTypes.func
};
