import React from 'react';
import PropTypes from 'prop-types';

import IconError from '../IconError';

import common from './formElementStyles/commonFormElements.module.css';

const TextInputField = ({
    label = '',
    subLabel = '',
    id = '',
    type = 'text',
    disabled = false,
    maxLength = 300000,
    placeholder = '',
    autoComplete = 'off',
    value,
    mandatory = false,
    customClass = '',
    customLabelClass = '',
    error = {},
    onPaste,
    onChange,
    onEnter
}) => {
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onEnter();
        }
    };

    return (
        <div className={`${common.fieldWrapper} ${common[customClass]}`}>
            {subLabel && <div>{subLabel}</div>}
            <label htmlFor={id} className={`${common.formLabel} ${common[customLabelClass]}`}>
                {label} {mandatory && <sup>*</sup>}
            </label>
            <input
                id={id}
                type={type}
                role="textbox"
                data-testid={'textField' + id}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete={autoComplete}
                maxLength={maxLength}
                value={value}
                className={common.fieldInput}
                onChange={onChange}
                onPaste={onPaste}
                {...(onEnter && { onKeyDown })}
            />
            {error?.error && (
                <div>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

TextInputField.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    mandatory: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string,
    maxLength: PropTypes.number,
    customClass: PropTypes.string,
    customLabelClass: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.object,
    onPaste: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func
};

export default TextInputField;
