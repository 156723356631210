import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { setCurrentlySelectedParticipant } from '../../../store/participantService';

const StaticParticipant = ({ participant, onNav }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    const onParticipantNav = () => {
        if (participant.id !== currentParticipant?.id) {
            dispatch(setCurrentlySelectedParticipant(participant));
            dispatch(loadContractDetailsForContractId(participant.contractId));
            dispatch(loadServiceDetailsForContractId(participant.contractId));
        }
        onNav();
        navigate('/edit_participant');
    };

    return (
        <div className="input-set">
            <label htmlFor="participant">Participant</label>
            {participant && Object.keys(participant)?.length ? (
                <div onClick={onParticipantNav} className="static-input-link static">
                    <span>{participant?.firstName} </span>
                    <span> {participant?.lastName}</span>
                </div>
            ) : (
                <div className="static">No Participant was attached to this Appointment</div>
            )}
        </div>
    );
};

export default StaticParticipant;

StaticParticipant.propTypes = {
    participant: PropTypes.object,
    onNav: PropTypes.func
};
