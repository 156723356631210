import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import HealthInfo from '../../healthInfo/HealthInfo';
import ModuleTemplateView from '../../ui/cards/moduleTemplate/ModuleTemplateView';

import cardClasses from '../../../commonStyles/cardContainer.module.css';
import dropdownClasses from '../courseStyles/rowDropDown.module.css';

const AllActiveCourseTemplateDetails = ({ row, onClose }) => {
    return (
        <div className={dropdownClasses.rowDropdown}>
            <Typography variant="h5">{row.name}</Typography>
            <p className={dropdownClasses.description}>{row.description}</p>
            {row.healthInformationCaptured && row.healthInformationIds.length > 0 && (
                <HealthInfo
                    healthInformationCaptured={row.healthInformationCaptured}
                    healthInformationIds={row.healthInformationIds}
                />
            )}

            <div className={cardClasses.cardContainer}>
                <Typography variant="h5">Module selection</Typography>
                <div className={cardClasses.cards}>
                    {row.moduleTemplates.map((el) => (
                        <div key={el.id}>
                            <ModuleTemplateView module={el} />
                        </div>
                    ))}
                </div>
            </div>
            <div className={dropdownClasses.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default AllActiveCourseTemplateDetails;

AllActiveCourseTemplateDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
