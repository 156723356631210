import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';

import { setErrorMessage } from '../../../../../store/formsState';
import { updatePlacement } from '../../../../../store/recruitmentService';
import { selectLoggedInUser, selectUsers } from '../../../../../store/userSelectors';
import { getFutureDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import * as validate from '../../../../../validation/validation';
import IconError from '../../../../IconError';
import LabelledEmailText from '../../../../ui/editors/LabelledEmailText';
import LabelledPhoneText from '../../../../ui/editors/LabelledPhoneText';
import LabelledPostcodeText from '../../../../ui/editors/LabelledPostcodeText';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../../ui/pickers/NoYesRadioPicker';
import PolarisSwitchSet from '../../../../ui/pickers/PolarisSwitchSet';

import form from '../../../../../commonStyles/formStyles.module.css';
import classes from '../../submissionStyles/placement.module.css';

const EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT = 'Expected weekly hours must be 48 or less';
const START_DATE_INVALID = 'Start date must not be a future date';

const OwnPlacement = ({
    row,
    roles,
    placementEmploymentTypeDetails,
    placementStatusDetails,
    hoursPerWeekDetails,
    jobSectorDetails,
    ownPlacementDetails
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        businessName: '',
        city: null,
        claimableEvent: true,
        hiringManagerEmail: null,
        employmentTypeId: null,
        endDate: '',
        endStatusId: '',
        fixedFlexibleHour: 'fixed',
        fullServiceDeclined: false,
        hiringManagerId: null,
        hiringManagerName: null,
        hiringManagerOfficeNumber: null,
        hiringManagerMobileNumber: null,
        hourlyPay: null,
        hoursPerWeek: null,
        hoursPerWeekId: null,
        jobSectorId: null,
        jobStartEvidence: false,
        leadAdviserId: null,
        leadGeneratedById: null,
        participantId: null,
        postcode: null,
        selfEmploymentClaimCalculationDate: '',
        startDate: '',
        vacancyId: null,
        vacancyTitle: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const leadAndAdviserRoles = [MANAGER, QUALITY, SUPERUSER];
    const [notFixedHours, setNotFixedHours] = useState(false);
    const [endStatus, setEndStatus] = useState(false);
    const [leadAdviser, setLeadAdviser] = useState(false);
    const [selfEmploymentDate, setSelfEmploymentDate] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [advisers, setAdvisers] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const users = useSelector(selectUsers);

    // EVENT HANDLERS
    const handleSelfEmployedDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            selfEmploymentClaimCalculationDate: e.target.value === '' ? null : e.target.value
        }));
    };
    const handleStartDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            startDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleEndDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            endDate: e.target.value === '' ? null : e.target.value
        }));
        if (!e.target.value) {
            setEndStatus(false);
            setNewEntry((prev) => ({
                ...prev,
                endStatusId: ''
            }));
        } else {
            setEndStatus(true);
        }
    };

    const handleEndStatusChange = (chosenId) => {
        if (!chosenId) chosenId = '';

        setNewEntry((prev) => ({ ...prev, endStatusId: chosenId }));
    };

    const handleVacancyTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, vacancyTitle: e.target.value }));
    };

    const handlePayRateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hourlyPay: e.target.value }));
    };

    const handleHoursPerWeekChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hoursPerWeek: e.target.value }));
    };

    const handleHoursPerWeekChangeDDL = (chosenId) => {
        if (!chosenId) chosenId = '';

        setNewEntry((prev) => ({ ...prev, hoursPerWeekId: chosenId }));
    };

    const handleClaimableChange = (option) => {
        setNewEntry((prev) => ({ ...prev, claimableEvent: option }));
    };

    const handleJobStartChange = (option) => {
        setNewEntry((prev) => ({ ...prev, jobStartEvidence: option }));
    };

    const handleFullServiceChange = (option) => {
        setNewEntry((prev) => ({ ...prev, fullServiceDeclined: option }));
    };

    const handleJobSectorChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, jobSectorId: chosenId }));
            clearErrors('sectorId');
        }
    };

    const handleBusinessNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, businessName: e.target.value }));
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleOfficePhoneNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerOfficeNumber: e.target.value }));
    };

    const handleMobilePhoneNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerMobileNumber: e.target.value }));
    };

    const handleEmailAddressChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerEmail: e.target.value }));
    };

    const handleAddress1Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine1: e.target.value }));
    };

    const handleAddress2Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine2: e.target.value }));
    };

    const handleAddress3Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine3: e.target.value }));
    };

    const handleCityChange = (e) => {
        setNewEntry((prev) => ({ ...prev, city: e.target.value }));
    };

    const handlePostcodeChange = (e) => {
        setNewEntry((prev) => ({ ...prev, postcode: e.target.value }));
    };

    const handleLeadGeneratedByChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, leadGeneratedById: chosenId }));
            clearErrors('leadGeneratedById');
        }
        if (getNameFromId(ownPlacementDetails, chosenId) !== 'Adviser') {
            setLeadAdviser(false);
            setNewEntry((prev) => ({
                ...prev,
                leadAdviserId: ''
            }));
        } else {
            setLeadAdviser(true);
        }
    };

    const handleAdviserChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, leadAdviserId: chosenId }));
        } else setNewEntry((prev) => ({ ...prev, leadAdviserId: '' }));
    };

    // HELPER FNS
    const getName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return advisers.find((item) => item.id === id)?.name || '';
    };

    const onSwitchFixedOrFlexible = (e) => {
        const { checked } = e.target;
        setNotFixedHours(checked);

        if (checked) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeek: null }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'flexible' }));
        } else {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: '' }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'fixed' }));
        }
    };

    // USE EFFECTS
    useEffect(() => {
        if (!row) return;

        setNewEntry(row);

        if (
            getNameFromId(placementEmploymentTypeDetails, row.employmentTypeId) !== 'Self Employed'
        ) {
            setSelfEmploymentDate(false);
            setNewEntry((prev) => ({
                ...prev,
                selfEmploymentClaimCalculationDate: ''
            }));
        } else {
            setSelfEmploymentDate(true);
        }

        setNewEntry((prev) => ({
            ...prev,
            startDate: row.startDate.substring(0, 10)
        }));

        setNotFixedHours(row.fixedFlexibleHour !== 'fixed');

        if (row.fixedFlexibleHour === 'fixed') {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: '' }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'fixed' }));
        } else {
            setNewEntry((prev) => ({ ...prev, hoursPerWeek: null }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'flexible' }));
        }

        const names = row.hiringManagerName.split(' ');
        setFirstName(names[0]);
        setLastName(names[1]);

        // Force Yup validation on pre-populated fields
        setValue('startDate', row.startDate.substring(0, 10), {
            shouldValidate: true
        });
        if (row?.endDate) {
            setNewEntry((prev) => ({
                ...prev,
                endDate: row.endDate.substring(0, 10)
            }));
            setValue('statusId', row.statusId, {
                shouldValidate: true
            });
            setEndStatus(true);
        }
        if (row?.selfEmploymentClaimCalculationDate) {
            setNewEntry((prev) => ({
                ...prev,
                selfEmploymentClaimCalculationDate:
                    row.selfEmploymentClaimCalculationDate.substring(0, 10)
            }));
            setSelfEmploymentDate(true);
        }
        setValue('title', row.vacancyTitle, {
            shouldValidate: true
        });
        setValue('hourlyPay', row.hourlyPay, {
            shouldValidate: true
        });
        setValue('sectorId', row.jobSectorId, {
            shouldValidate: true
        });
        setValue('businessName', row.businessName, {
            shouldValidate: true
        });
        setValue('businessName', row.businessName, {
            shouldValidate: true
        });
        setValue('firstName', names[0], {
            shouldValidate: true
        });
        setValue('lastName', names[1], {
            shouldValidate: true
        });
        setValue('officeNumber', row.officeNumber, {
            shouldValidate: true
        });
        setValue('address1', row.address1, {
            shouldValidate: true
        });
        setValue('city', row.city, {
            shouldValidate: true
        });
        setValue('postcode', row.postcode, {
            shouldValidate: true
        });
        setValue('leadGeneratedById', row.leadGeneratedById, {
            shouldValidate: true
        });
        if (getNameFromId(ownPlacementDetails, row?.leadGeneratedById) === 'Adviser') {
            setNewEntry((prev) => ({
                ...prev,
                leadAdviserId: row.leadAdviserId
            }));
            setValue('leadAdviserId', row.leadAdviserId, {
                shouldValidate: true
            });
            setLeadAdviser(true);
        }
    }, []);

    useEffect(() => {
        setAdvisers(
            users
                .filter((el) =>
                    el.userTypes?.find(
                        (entry) =>
                            entry.role === ADVISER ||
                            entry.role === MANAGER ||
                            entry.role === QUALITY ||
                            entry.role === RECRUITMENT_MANAGER ||
                            entry.role === SUPERUSER
                    )
                )
                .map(({ id, emailAddress: name }) => ({
                    id,
                    name
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        );
    }, [users]);

    const onSubmit = () => {
        if (newEntry?.startDate > newEntry.endDate) {
            dispatch(setErrorMessage('End date must be same as or after start date'));
            return;
        } else if (newEntry?.selfEmploymentClaimCalculationDate > newEntry?.endDate) {
            dispatch(
                setErrorMessage(
                    'End date must be same as or after self employed claim calculation date'
                )
            );
            return;
        } else if (newEntry?.endDate && getFutureDate(newEntry?.endDate) === true) {
            dispatch(setErrorMessage('End date must be a date in the past or in the present'));
            return;
        } else if (newEntry?.endDate && !newEntry.endStatusId) {
            dispatch(setErrorMessage('End status must be entered when end date present'));
            return;
        } else if (selfEmploymentDate && !newEntry.selfEmploymentClaimCalculationDate) {
            dispatch(
                setErrorMessage(
                    'Self employed claim calculation date must be entered when placement employment type is self employed'
                )
            );
            return;
        } else if (
            selfEmploymentDate &&
            newEntry.selfEmploymentClaimCalculationDate < newEntry.startDate
        ) {
            dispatch(
                setErrorMessage(
                    'Self employed claim calculation date must not be before start date'
                )
            );
            return;
        } else if (leadAdviser && !newEntry.leadAdviserId) {
            dispatch(setErrorMessage('Adviser must be entered when lead generated by adviser'));
            return;
        } else if (
            newEntry.hourlyPay === null ||
            newEntry.hourlyPay === '0' ||
            newEntry.hourlyPay === '0.0' ||
            newEntry.hourlyPay === '0.00'
        ) {
            dispatch(setErrorMessage('Hourly pay cannot be zero or blank'));
            return;
        }
        if (newEntry.hoursPerWeek > '') {
            const hoursPerWeek = parseFloat(newEntry.hoursPerWeek);
            if (hoursPerWeek > 48) {
                dispatch(setErrorMessage(EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT));
            } else if (
                (!notFixedHours && newEntry.hoursPerWeek === null) ||
                (!notFixedHours && newEntry.hoursPerWeek === '0') ||
                (!notFixedHours && newEntry.hoursPerWeek === '0.0') ||
                (!notFixedHours && newEntry.hoursPerWeek === '0.00')
            ) {
                dispatch(setErrorMessage('Hours per week cannot be zero or blank'));
                return;
            }
        } else if (
            (notFixedHours && newEntry.hoursPerWeekId === '') ||
            (notFixedHours && newEntry.hoursPerWeekId === null)
        ) {
            dispatch(setErrorMessage('Please select hours per week'));
            return;
        }
        const payload = {
            ...newEntry,
            participantId: currentParticipant.id
        };
        dispatch(updatePlacement(payload));
    };

    // RENDER
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Employment Type'}
                                id={'type'}
                                disabled={true}
                                value={getNameFromId(
                                    placementEmploymentTypeDetails,
                                    row.employmentTypeId
                                )}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <TextField
                                className={classes.startDate}
                                label="Start Date *"
                                id="startDate"
                                disabled={!hasRole(acceptedRoles, roles)}
                                type="date"
                                value={newEntry.startDate || ''}
                                sx={{ width: '45%' }}
                                InputLabelProps={{ shrink: true }}
                                {...register('startDate')}
                                onChange={handleStartDateChange}
                            />
                            <TextField
                                label="End Date"
                                id="endDate"
                                disabled={!hasRole(acceptedRoles, roles)}
                                type="date"
                                value={newEntry.endDate || ''}
                                sx={{ width: '45%' }}
                                InputLabelProps={{ shrink: true }}
                                {...register('endDate')}
                                onChange={handleEndDateChange}
                            />
                            <IconError text={errors.startDate || null} />
                        </div>
                        <div hidden={!selfEmploymentDate}>
                            <div className={classes.inputField}>
                                <TextField
                                    label="Self Employed Claim Calculation Date *"
                                    id="selfEmploymentClaimCalculationDate"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    type="date"
                                    value={newEntry.selfEmploymentClaimCalculationDate || ''}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleSelfEmployedDateChange}
                                />
                            </div>
                        </div>
                        <DDLOptionPicker
                            label={'End Status'}
                            id={'endStatusId'}
                            disabled={!(!hasRole(acceptedRoles, roles) || newEntry?.endDate)}
                            mandatory={endStatus}
                            menuItems={placementStatusDetails}
                            chosenName={getNameFromId(placementStatusDetails, newEntry.endStatusId)}
                            chosenId={newEntry.endStatusId}
                            error={errors.endStatusId}
                            {...register('endStatusId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleEndStatusChange(e);
                                }
                            }}
                        />
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Vacancy Title'}
                                id={'title'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.vacancyTitle || ''}
                                placeholder={'Enter vacancy title'}
                                error={errors.title}
                                {...register('title')}
                                onChange={handleVacancyTitleChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Hourly pay'}
                                id={'hourlyPay'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.hourlyPay || ''}
                                placeholder={'Enter hourly pay'}
                                error={errors.hourlyPay}
                                {...register('hourlyPay')}
                                onChange={handlePayRateChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <PolarisSwitchSet
                                id="fixedOrFlexible"
                                disabled={!hasRole(acceptedRoles, roles)}
                                label="Fixed or Flexible"
                                leftLabel="Fixed"
                                rightLabel="Flexible"
                                checked={notFixedHours}
                                onSwitch={onSwitchFixedOrFlexible}
                            />
                        </div>
                        {!notFixedHours ? (
                            <div className={classes.inputField}>
                                <LabelledTextField
                                    label={'Hours per Week'}
                                    id={'hoursPerWeek'}
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={!notFixedHours && true}
                                    value={newEntry.hoursPerWeek || ''}
                                    placeholder={'Enter hours'}
                                    onChange={handleHoursPerWeekChange}
                                />
                            </div>
                        ) : (
                            <>
                                <DDLOptionPicker
                                    label={'Hours Per Week'}
                                    id="hoursPerWeekId"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={notFixedHours && true}
                                    menuItems={hoursPerWeekDetails}
                                    chosenName={getNameFromId(
                                        hoursPerWeekDetails,
                                        newEntry.hoursPerWeekId
                                    )}
                                    chosenId={newEntry.hoursPerWeekId}
                                    onChange={(e) => {
                                        if (e !== null && e !== undefined) {
                                            handleHoursPerWeekChangeDDL(e);
                                        }
                                    }}
                                />
                            </>
                        )}
                        <NoYesRadioPicker
                            id="claimable"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.claimableEvent}
                            text={'Claimable Event?'}
                            onChange={handleClaimableChange}></NoYesRadioPicker>
                        <NoYesRadioPicker
                            id="jobStart"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.jobStartEvidence}
                            text={'Job Start Evidence?'}
                            onChange={handleJobStartChange}></NoYesRadioPicker>
                        <NoYesRadioPicker
                            id="fullService"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtonPick={newEntry.fullServiceDeclined}
                            text={'Full Service Declined?'}
                            onChange={handleFullServiceChange}></NoYesRadioPicker>
                        <DDLOptionPicker
                            label={'Job Sector'}
                            id={'sectorId'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={jobSectorDetails}
                            chosenName={getNameFromId(jobSectorDetails, newEntry.jobSectorId)}
                            chosenId={newEntry.jobSectorId}
                            error={errors.sectorId}
                            {...register('sectorId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleJobSectorChange(e);
                                }
                            }}
                        />
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Business Name'}
                                id={'businessName'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.businessName || ''}
                                placeholder={'Enter business name'}
                                error={errors.businessName}
                                {...register('businessName')}
                                onChange={handleBusinessNameChange}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'First Name'}
                                id={'firstName'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={firstName || ''}
                                placeholder={'Enter hiring manager first name'}
                                error={errors.firstName}
                                {...register('firstName')}
                                onChange={handleFirstNameChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Last Name'}
                                id={'lastName'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={lastName || ''}
                                placeholder={'Enter hiring manager last name'}
                                error={errors.lastName}
                                {...register('lastName')}
                                onChange={handleLastNameChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledPhoneText
                                label="Office Number"
                                id="officeNumber"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                name="officeNumber"
                                placeholder={'Enter hiring manager office phone number'}
                                value={newEntry.hiringManagerOfficeNumber || ''}
                                error={errors.officeNumber}
                                {...register('officeNumber')}
                                onChange={handleOfficePhoneNumberChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledPhoneText
                                label="Mobile Number"
                                id="mobileNumber"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={false}
                                name="mobileNumber"
                                placeholder={'Enter hiring manager mobile phone number'}
                                value={newEntry.hiringManagerMobileNumber || ''}
                                error={errors.mobileNumber}
                                {...register('mobileNumber')}
                                onChange={handleMobilePhoneNumberChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledEmailText
                                label={'Email'}
                                id={'email'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={false}
                                value={newEntry.hiringManagerEmail || ''}
                                placeholder={'Enter hiring manager email address'}
                                error={errors.email}
                                name="email"
                                {...register('email')}
                                onChange={handleEmailAddressChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Address 1'}
                                id={'address1'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.addressLine1 || ''}
                                placeholder={'Enter address 1'}
                                error={errors.address1}
                                {...register('address1')}
                                onChange={handleAddress1Change}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Address 2'}
                                id={'address2'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.addressLine2 || ''}
                                placeholder={'Enter address 2'}
                                error={errors.address2}
                                {...register('address2')}
                                onChange={handleAddress2Change}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Address 3'}
                                id={'address3'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.addressLine3 || ''}
                                placeholder={'Enter address 3'}
                                error={errors.address3}
                                {...register('address3')}
                                onChange={handleAddress3Change}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledTextField
                                label={'Town/City'}
                                id={'city'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.city || ''}
                                placeholder={'Enter town/city'}
                                error={errors.city}
                                {...register('city')}
                                onChange={handleCityChange}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <LabelledPostcodeText
                                label={'Postcode'}
                                id={'postcode'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                placeholder={'Enter Postcode'}
                                value={newEntry.postcode || ''}
                                name="postcode"
                                error={errors.postcode}
                                {...register('postcode')}
                                onChange={handlePostcodeChange}
                            />
                        </div>
                        <DDLOptionPicker
                            label={'Lead Generated By'}
                            id={'leadGeneratedById'}
                            disabled={!hasRole(leadAndAdviserRoles, loggedInUser.roles)}
                            mandatory={true}
                            menuItems={ownPlacementDetails}
                            chosenName={
                                ownPlacementDetails
                                    ? getNameFromId(ownPlacementDetails, newEntry.leadGeneratedById)
                                    : ''
                            }
                            chosenId={newEntry.leadGeneratedById}
                            error={errors.leadGeneratedById}
                            {...register('leadGeneratedById')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleLeadGeneratedByChange(e);
                                }
                            }}
                        />
                        <div hidden={!leadAdviser}>
                            <DDLOptionPicker
                                label={'Adviser'}
                                id={'leadAdviserId'}
                                disabled={!hasRole(leadAndAdviserRoles, loggedInUser.roles)}
                                mandatory={leadAdviser}
                                menuItems={advisers}
                                chosenName={
                                    newEntry.leadAdviserId ? getName(newEntry.leadAdviserId) : ''
                                }
                                chosenId={newEntry.leadAdviserId}
                                error={errors.leadAdviserId}
                                {...register('leadAdviserId')}
                                onChange={(e) => {
                                    if (e !== null && e !== undefined) {
                                        handleAdviserChange(e);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                    Update
                </Button>
            </form>
        </div>
    );
};
const validationSchema = Yup.object().shape({
    startDate: Yup.string()
        .required('Please select a start date')
        .nullable()
        .test('startDate', START_DATE_INVALID, function (startDate) {
            return getFutureDate(startDate) === false;
        }),
    title: Yup.string()
        .min(1, 'Vacancy title must be at least one character')
        .max(100, 'Vacancy title must be 100 characters or less'),
    hourlyPay: Yup.string()
        .required('Please enter a valid hourly pay')
        .min(0, 'Hourly pay rate limit cannot be negative')
        .max(999.99, 'Hourly pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid hourly pay'),
    sectorId: Yup.string().nullable().required('Please select a job sector.'),
    leadGeneratedById: Yup.string().nullable().required('Please select a participant / adviser.'),
    businessName: Yup.string()
        .min(1, 'Business name must be at least one character')
        .max(100, 'Business name must be 50 characters or less'),
    firstName: Yup.string()
        .min(1, 'First name must be at least one character')
        .max(50, 'First name must be 50 characters or less'),
    lastName: Yup.string()
        .min(1, 'Last name must be at least one character')
        .max(50, 'last name must be 50 characters or less'),
    officeNumber: Yup.string()
        .matches(/.{11,}/, {
            excludeEmptyString: false,
            message: 'Office number must be 11 characters or more'
        })
        .min(11, 'Office number is mandatory')
        .max(15, 'Office number must be 15 characters or less')
        .matches(validate.PHONE_REGEXP, {
            excludeEmptyString: true,
            message: 'Invalid Office number'
        }),
    mobileNumber: Yup.string()
        .matches(/.{11,}/, {
            excludeEmptyString: true,
            message: 'Mobile number must be 11 characters or more'
        })
        .max(15, 'Mobile number must be 15 characters or less')
        .matches(validate.PHONE_REGEXP, {
            excludeEmptyString: true,
            message: 'Invalid Mobile number'
        }),
    email: Yup.string()
        .email('Please enter a valid email address')
        .max(50, 'Email Address must be 50 characters or less'),
    address1: Yup.string()
        .min(1, 'Address 1 must be at least one character')
        .max(100, 'Address 1 must be 100 characters or less'),
    address2: Yup.string().max(100, 'Address 2 must be 100 characters or less'),
    address3: Yup.string().max(100, 'Address 3 must be 100 characters or less'),
    city: Yup.string()
        .min(1, 'City must be at least one character')
        .max(30, 'City must be 30 characters or less'),
    postcode: Yup.string().matches(validate.POSTCODE_REGEXP, {
        excludeEmptyString: false,
        message: 'Please enter a valid postcode'
    })
});

OwnPlacement.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    placementEmploymentTypeDetails: PropTypes.array.isRequired,
    placementStatusDetails: PropTypes.array.isRequired,
    hoursPerWeekDetails: PropTypes.array.isRequired,
    jobSectorDetails: PropTypes.array.isRequired,
    ownPlacementDetails: PropTypes.array.isRequired
};

export default OwnPlacement;
