import React from 'react';
import { useSelector } from 'react-redux';

import app from '../../app.module.css';

const CommunicationIdentifier = () => {
    const currentCommunicationGroup = useSelector(
        (state) => state.entities.communicationService.groupCommunication
    );

    return <div className={app.groupIdentifierHeader}>{currentCommunicationGroup.name}</div>;
};

export default CommunicationIdentifier;
