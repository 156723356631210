const MIN_NAME_CHAR = 5;
const MIN_DESCRIPTION_CHAR = 50;

export const NAME_MIN = `Course name must be at least ${MIN_NAME_CHAR} characters`;
export const DESCRIPTION_MIN = `Course description must be at least ${MIN_DESCRIPTION_CHAR} characters`;
export const NO_SERVICE_IDS = 'Please select at least one service';
export const NO_LOCATION_ID = 'Please select a location';

export const initialErrorState = {
    name: { error: false, message: '' },
    description: { error: false, message: '' },
    serviceIds: { error: false, message: '' },
    locationId: { error: false, message: '' }
};

export const validate = (newEntry) => {
    let newErrors = {};

    if (newEntry.name.length < 5) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: NAME_MIN }
        };
    }

    if (newEntry.description.length < 50) {
        newErrors = {
            ...newErrors,
            description: { error: true, message: DESCRIPTION_MIN }
        };
    }

    if (newEntry.serviceIds.length < 1) {
        newErrors = {
            ...newErrors,
            serviceIds: { error: true, message: NO_SERVICE_IDS }
        };
    }

    if (!newEntry.locationId) {
        newErrors = {
            ...newErrors,
            locationId: { error: true, message: NO_LOCATION_ID }
        };
    }

    return newErrors;
};
