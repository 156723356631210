import { isDateEarlier, isDatePast, isTimePast } from '../../../utils/dateFunctions';

export const validate = (disabled, newEntry) => {
    let valid = true;
    let errors = [];

    if (!disabled.actionTitle && newEntry.actionTitle.trim().length < 5) {
        errors = [
            ...errors,
            {
                error: 'actionTitle',
                detail: {
                    type: 'manual',
                    message: 'The action title must be at least five characters'
                }
            }
        ];
        valid = false;
    }

    if (!disabled.actionTitle && newEntry.actionTitle.trim().length > 100) {
        errors = [
            ...errors,
            {
                error: 'actionTitle',
                detail: {
                    type: 'manual',
                    message: 'The action title must be 100 characters or less'
                }
            }
        ];
        valid = false;
    }

    if (!disabled.action && newEntry.action.trim().length < 50) {
        errors = [
            ...errors,
            {
                error: 'action',
                detail: {
                    type: 'manual',
                    message: 'Action description must be at least 50 characters.'
                }
            }
        ];
        valid = false;
    }

    if (!disabled.action && newEntry.action.trim().length > 750) {
        errors = [
            ...errors,
            {
                error: 'action',
                detail: {
                    type: 'manual',
                    message: 'Action description must be less than 750 characters.'
                }
            }
        ];
        valid = false;
    }

    if (!disabled.actionDueDate && newEntry.actionDueDate && isDatePast(newEntry.actionDueDate)) {
        errors = [
            ...errors,
            {
                error: 'actionDueDate',
                detail: {
                    type: 'manual',
                    message: "Date must be today's date or later"
                }
            }
        ];
        valid = false;
    }

    if (
        !disabled.actionDueDate &&
        newEntry.actionDueDate &&
        isDateEarlier(newEntry.actionStartDate, newEntry.actionDueDate)
    ) {
        errors = [
            ...errors,
            {
                error: 'actionDueDate',
                detail: {
                    type: 'manual',
                    message: 'Date must be the same or later than the start date'
                }
            }
        ];
        valid = false;
    }

    if (
        !disabled.actionOutcomeDate &&
        newEntry.actionOutcomeDate &&
        !isTimePast(newEntry.actionOutcomeDate)
    ) {
        errors = [
            ...errors,
            {
                error: 'actionOutcomeDate',
                detail: {
                    type: 'manual',
                    message: "Date must be today's date or earlier"
                }
            }
        ];
        valid = false;
    }

    if (
        !disabled.actionOutcomeDate &&
        newEntry.actionOutcomeDate &&
        isDateEarlier(newEntry.actionStartDate, newEntry.actionOutcomeDate)
    ) {
        errors = [
            ...errors,
            {
                error: 'actionOutcomeDate',
                detail: {
                    type: 'manual',
                    message: 'Date must be the same or later than the start date'
                }
            }
        ];
        valid = false;
    }

    if (!disabled.comments && newEntry?.comments?.trim().length > 500) {
        errors = [
            ...errors,
            {
                error: 'comments',
                detail: {
                    type: 'manual',
                    message: 'Comments must be 500 characters or less'
                }
            }
        ];
        valid = false;
    }

    return { valid, errors };
};
