import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import OnEditIcon from '../../../../icons/OnEditIcon';
import { hasRole } from '../../../../utils/userRoles';

import card from '../../../../commonStyles/card.module.css';

const BusinessContactCard = ({ roles, acceptedRoles, entry, onEdit, editable = false }) => {
    const { firstName, lastName, positions, officeNumber, mobileNumber, email, notes } = entry;
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        editable && setIsDisabled(!hasRole(acceptedRoles, roles));
    }, []);

    return (
        <div data-testid="form_start" className={card.card}>
            <div
                className={`${
                    firstName?.length > 30 || lastName?.length > 30
                        ? card.bannerDoubleRow
                        : card.banner
                } ${isDisabled ? card.disabledBanner : ''}`}>
                {editable && (
                    <span className={card.editIcon} data-testid="business_contact_card_edit_icon">
                        <OnEditIcon
                            onEdit={() => onEdit(entry)}
                            roles={roles}
                            acceptedRoles={acceptedRoles}
                            active={!isDisabled}
                        />
                    </span>
                )}
                <h6>{firstName && lastName ? `Contact ${firstName} ${lastName}` : 'Contact'}</h6>
            </div>
            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Contact Notes:</div>
                    <div className={card.description}>
                        {notes?.length > 100 ? `${notes.substring(0, 97)}...` : notes}
                    </div>
                </div>
                <div className={card.rows}>
                    <div className={card.row}>
                        <AccountCircleIcon />
                        {` ${positions ? positions : ' - '}`}
                    </div>
                    <div className={card.row}>
                        <PhoneIcon />
                        {` ${officeNumber ? officeNumber : ' - '}`}
                    </div>
                    <div className={card.row}>
                        <PhoneIphoneIcon />
                        {` ${mobileNumber ? mobileNumber : ' - '}`}
                    </div>
                    <div className={card.row}>
                        <EmailIcon />
                        {` ${email ? email : ' - '}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessContactCard;

BusinessContactCard.propTypes = {
    entry: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func,
    editable: PropTypes.bool
};
