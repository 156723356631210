import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import { selectParticipantAttendedModules } from '../../../../store/courseSelectors';
import { selectAttendedStatusId } from '../../../../store/dataSelectors';
import {
    loadAttendanceDetails,
    loadBloodTestEvents,
    loadBloodTestSources
} from '../../../../store/directusService';
import {
    selectCurrentParticipant,
    selectIsSecondNatureReferralAttemptSuccessful
} from '../../../../store/participantSelectors';
import {
    deleteBloodTest,
    loadBloodTests,
    onDeleteBloodTest
} from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import BloodTestsTable from './BloodTestsTable';
import CreateBloodTest from './CreateBloodTest';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../wellbeing.module.css';

const BloodTests = () => {
    const dispatch = useDispatch();

    const formRef = useRef(null);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [modules, setModules] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const deleteMsg = `Blood test deleted`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const isReferralHistoryAttemptSuccessful = useSelector(
        selectIsSecondNatureReferralAttemptSuccessful
    );
    const bloodTests = useSelector((state) => state.entities.participantService.bloodTests);
    const attendedStatusId = useSelector(selectAttendedStatusId);
    const attendedModules = useSelector(selectParticipantAttendedModules);
    const bloodTestEvents = useSelector((state) => state.entities.directusService.bloodTestEvents);
    const bloodTestSources = useSelector(
        (state) => state.entities.directusService.bloodTestSources
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        !attendedStatusId && dispatch(loadAttendanceDetails());
        bloodTestEvents?.length < 1 && dispatch(loadBloodTestEvents());
        bloodTestSources?.length < 1 && dispatch(loadBloodTestSources());
    }, []);

    useEffect(() => {
        currentParticipant?.id && dispatch(loadBloodTests(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (bloodTestEvents?.length < 1 || bloodTestSources?.length < 1) return;
        const rows = bloodTests.map((el) => ({
            ...el,
            displayDate: reverseFormatDate(el.eventDate),
            testValue: el.testType === 'HbA1c' ? el.testValueHb : el.testValueFpg,
            event: bloodTestEvents.find((entry) => entry.id === el.eventId)?.name || '',
            source: bloodTestSources.find((entry) => entry.id === el.sourceId)?.name || '',
            linkedModule: el.linkedModuleId
                ? attendedModules?.find((entry) => entry.id === el.linkedModuleId)?.title
                : 'z'
        }));
        setRows(rows);
        attendedModules?.length &&
            setModules(
                attendedModules.filter(
                    (el) => !bloodTests.find((entry) => entry.linkedModuleId === el.id)
                )
            );
    }, [bloodTests, attendedModules, bloodTestEvents, bloodTestSources]);

    useEffect(() => {
        if (successMessage === deleteMsg) dispatch(onDeleteBloodTest(selectedRow.id));
    }, [successMessage]);

    // EVENT HANDLERS
    const onCreate = () => {
        setFormType('create');
        setSelectedRow({});
        setShowForm(true);
    };

    const onEdit = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setShowForm(true);
        if (formRef?.current) {
            const currentTop = formRef?.current.getBoundingClientRect().top;
            window.scrollBy({
                top: currentTop / 2,
                behavior: 'smooth'
            });
        }
    };

    const onDelete = (row) => {
        setSelectedRow(row);
        dispatch(deleteBloodTest(row.id, deleteMsg));
    };

    return (
        <div>
            <div className={app.sectionHeading}>Blood Test</div>
            <div className={local.tableSection}>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No blood tests found" />
                ) : (
                    <BloodTestsTable
                        rows={rows}
                        roles={roles}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
            </div>
            <div className={form.formEnd} ref={formRef}>
                {!showForm && (
                    <Button
                        disabled={
                            !hasRole(acceptedRoles, roles) || isReferralHistoryAttemptSuccessful
                        }
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreate}>
                        Add New Blood Test
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                <div>
                    {showForm && (
                        <CreateBloodTest
                            onClose={() => setShowForm(false)}
                            roles={roles}
                            attendedModules={modules}
                            formType={formType}
                            row={selectedRow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BloodTests;
