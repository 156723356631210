import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { loadCallOutcomes, loadWorkConfidence } from '../../../../../store/directusService';
import { updateIWSRecord } from '../../../../../store/participantService';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';

import '../../submissionStyles/muiStyles.css';
import form from '../../../../../commonStyles/formStyles.module.css';

const NOTES_LIMIT = 750;

const AddCallEditor = ({ open, roles, onAddCallEditor, addCallRow, businessName }) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    const initialState = {
        id: null,
        participantId: null,
        frequencyId: null,
        placementId: null,
        iwsEventOwnerId: null,
        frequencyNote: '',
        dueDate: '',
        frequencyWeekNo: null,
        callDate: '',
        workConfidenceId: null,
        callOutcomeId: null,
        note: '',
        notesFromLastCall: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [notes, setNotes] = useState('');
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const dateFormat = 'dd/MM/yyyy';
    const today = new Date();
    const [callOutcomesWithoutDue, setCallOutcomesWithoutDue] = useState([]);

    const [isClearSelectedOutcome, setIsClearSelectedOutcome] = useState('0');
    const [isClearSelectedConfidence, setIsClearSelectedConfidence] = useState('1');

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { callOutcomes, workConfidence } = useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    const handleOutcomeChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, callOutcomeId: chosenId }));
    };

    const handleConfidenceChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, workConfidenceId: chosenId }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, note: e.target.value }));
    };

    // HELPER FNS
    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        clearData();
    };

    const clearData = () => {
        setNewEntry(initialState);
        setNotes('');
        setIsClearSelectedOutcome(Math.random());
        setIsClearSelectedConfidence(Math.random());
        onAddCallEditor(false);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadCallOutcomes());
        dispatch(loadWorkConfidence());
    }, []);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        setNewEntry(addCallRow);
    }, [addCallRow]);

    useEffect(() => {
        if (callOutcomes?.length < 1) return;

        setCallOutcomesWithoutDue(callOutcomes.filter((el) => el.name !== 'Due'));
    }, [callOutcomes]);

    useEffect(() => {
        if (successMessage === `IWS Record for ${businessName} has been updated`) clearData();
    }, [successMessage]);

    const onSubmit = () => {
        dispatch(updateIWSRecord(newEntry, businessName));
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <div>
                <Dialog
                    className="muiDialogWrapper"
                    open={isOpen}
                    onClose={handleClickOutside}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {`Complete IWS Call: Week ${addCallRow.frequencyWeekNo || ''}`}
                    </DialogTitle>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent data-testid="dialog-content">
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <DDLOptionPicker
                                        label={'Call Outcome'}
                                        id={'outcomeId'}
                                        key={isClearSelectedOutcome}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        mandatory={true}
                                        menuItems={callOutcomesWithoutDue || []}
                                        chosenName={getNameFromId(callOutcomes, newEntry.outcomeId)}
                                        chosenId={newEntry.outcomeId}
                                        error={errors.outcomeId}
                                        {...register('outcomeId')}
                                        onChange={(chosenId) => handleOutcomeChange(chosenId)}
                                    />
                                    <LabelledTextField
                                        label={'Date Of Call'}
                                        id={'dateRequested'}
                                        disabled={true}
                                        mandatory={true}
                                        value={format(today, dateFormat)}
                                    />
                                </div>
                                <div className={form.formColumn}>
                                    <DDLOptionPicker
                                        label={'Work Confidence'}
                                        id={'confidenceId'}
                                        key={isClearSelectedConfidence}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        mandatory={true}
                                        menuItems={workConfidence || []}
                                        chosenName={getNameFromId(
                                            callOutcomes,
                                            newEntry.confidenceId
                                        )}
                                        chosenId={newEntry.confidenceId}
                                        error={errors.confidenceId}
                                        {...register('confidenceId')}
                                        onChange={(chosenId) => handleConfidenceChange(chosenId)}
                                    />
                                </div>
                            </div>
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <LabelledTextField
                                        label={'Notes'}
                                        id={'note'}
                                        mandatory={true}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        multiline
                                        rows={5}
                                        value={notes || ''}
                                        placeholder={'Enter In Work Support Call notes'}
                                        counter={'true'}
                                        helperText={
                                            notes
                                                ? `${notes.length}/${NOTES_LIMIT}`
                                                : `0/${NOTES_LIMIT}`
                                        }
                                        inputProps={{ maxLength: NOTES_LIMIT }}
                                        error={errors.notes}
                                        {...register('notes')}
                                        onChange={(e) => handleNotesChange(e)}
                                    />
                                </div>
                            </div>
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <LabelledTextField
                                        label={'Notes From Last Call'}
                                        id={'notesFromLastCall'}
                                        disabled={true}
                                        multiline
                                        rows={5}
                                        value={addCallRow?.notesFromLastCall || ''}
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!hasRole(acceptedRoles, roles)}>
                                Update
                            </Button>
                            <Button onClick={handleCancel} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    outcomeId: Yup.string().required('Please select an outcome'),
    confidenceId: Yup.string().required('Please select a work confidence'),
    notes: Yup.string().min(10, 'Notes must be at least ten characters')
});

AddCallEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    onAddCallEditor: PropTypes.func,
    addCallRow: PropTypes.object,
    businessName: PropTypes.string
};

export default AddCallEditor;
