import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import { loadCourseTemplate } from '../../../store/courseService';
import HealthInfo from '../../healthInfo/HealthInfo';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ScheduleModuleAttendanceView from '../courseCards/ScheduleModuleAttendanceView';

import app from '../../../app.module.css';
import cardClasses from '../../../commonStyles/cardContainer.module.css';
import dropdownClasses from '../courseStyles/rowDropDown.module.css';

/**
 * Course instance details
 * See PPL-641 for details.
 * This is shown inside PPL-473
 * @returns {JSX.Element}
 */
const CourseInstanceDetails = ({ row, onClose }) => {
    const dispatch = useDispatch();
    // STORE STATE
    const { currentTemplate } = useSelector((state) => state.entities.courseService);

    // USE EFFECTS

    useEffect(() => {
        if (row && Object.keys(row)?.length !== 0) {
            dispatch(loadCourseTemplate(row.courseTemplateId));
        }
    }, [row]);

    return !currentTemplate || Object.keys(currentTemplate).length < 1 ? (
        <LoadingSpinner content="No course/module information found" time={3} />
    ) : (
        <div className={dropdownClasses.rowDropdown}>
            <form>
                <Typography variant={'h5'}>
                    {row.name} ({row.code})
                </Typography>
                <Typography variant="body1">{row.description}</Typography>
                <br />
                {currentTemplate.healthInformationCaptured && (
                    <HealthInfo
                        healthInformationCaptured={true}
                        healthInformationIds={currentTemplate.healthInformationIds}
                    />
                )}
                <div className={cardClasses.cardContainer}>
                    <div className={app.sectionHeading}>Module Selection</div>
                    <div className={cardClasses.cards}>
                        {row.modules.map((el) => (
                            <div key={el.id}>
                                <ScheduleModuleAttendanceView course={row} module={el} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={dropdownClasses.exit} onClick={onClose}>
                    Close details
                    <CloseIcon />
                </div>
            </form>
        </div>
    );
};

CourseInstanceDetails.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default CourseInstanceDetails;
