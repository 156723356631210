import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    createContract,
    loadContractDetails,
    loadContractTypes,
    loadUniqueIdentifierTypes
} from '../../store/directusService';
import { clearKeys } from '../../utils/objectUtils';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import Button from '../formElements/Button';
import DateSelect from '../formElements/DateSelect';
import RadioButtons from '../formElements/RadioButtons';
import SingleSelect from '../formElements/SingleSelect';
import TextInputField from '../formElements/TextInputField';
import LoadingSpinner from '../ui/LoadingSpinner';

import { initialErrorState, validate } from './validateCreateContract';

import app from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const CreateContract = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        contract: '',
        name: '',
        contract_type: '',
        unique_identifier_type: '',
        status: 'published',
        active: true,
        startDate: null,
        endDate: null
    };
    const acceptedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [keys, setKeys] = useState({ type: '0', uniqueIdType: '1' });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { contractDetails, contractTypes, uniqueIdentifierTypes } = useSelector(
        (state) => state.entities.directusService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadContractDetails());
        contractTypes?.length < 1 && dispatch(loadContractTypes());
        uniqueIdentifierTypes?.length < 1 && dispatch(loadUniqueIdentifierTypes());
    }, []);

    useEffect(() => {
        if (successMessage.includes(newEntry.name)) clearForm();
    }, [successMessage]);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setKeys(clearKeys(keys));
        setIsDisabled(false);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onNameChange = (e) => {
        clearError('name');
        setNewEntry((prev) => ({
            ...prev,
            name: e.target.value,
            contract: e.target.value
        }));
    };

    const onUpdate = (key, value) => {
        if (errors[key]) clearError(key);
        if (value == null) value = '';
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, contractDetails);
        setErrors(newErrors);
        if (!isValid) return;
        dispatch(createContract(newEntry));
        setIsDisabled(true);
    };

    // AWAITING CONTENT
    let content;
    if (contractTypes?.length < 1) content = 'No contract types found';
    if (uniqueIdentifierTypes?.length < 1) content = 'No unique identifiers found';

    if (contractTypes?.length < 1 || uniqueIdentifierTypes?.length < 1)
        return <LoadingSpinner content={content} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form className={form.form} onSubmit={onSubmit} data-testid="form_start">
                <h2 className={app.mainHeading}>Create Contract</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'name'}
                            label={'Name'}
                            placeholder={'Enter contract name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={onNameChange}
                        />
                        <SingleSelect
                            id={'type'}
                            key={keys.type}
                            label={'Type'}
                            placeholder="Search contract types..."
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={contractTypes || []}
                            selectedId={newEntry.contract_type || ''}
                            selected={
                                contractTypes.find((el) => el.id === newEntry.contract_type) || {}
                            }
                            error={errors.contract_type}
                            onChange={(chosenId) => onUpdate('contract_type', chosenId)}
                        />
                        <SingleSelect
                            id={'uniqueIdType'}
                            key={keys.uniqueIdType}
                            label={'Unique ID Type'}
                            placeholder="Search unique identifier types..."
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={uniqueIdentifierTypes || []}
                            selectedId={newEntry.unique_identifier_type || ''}
                            selected={
                                uniqueIdentifierTypes.find(
                                    (el) => el.id === newEntry.unique_identifier_type
                                ) || {}
                            }
                            error={errors.unique_identifier_type}
                            onChange={(chosenId) => onUpdate('unique_identifier_type', chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <RadioButtons
                            id="active"
                            label="Active"
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.active}
                            onChange={(option) => onUpdate('active', option)}
                        />
                        <div className={`${form.formColumnSplit}`}>
                            <DateSelect
                                label="Start Date"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.startDate}
                                onDateChange={(date) =>
                                    onUpdate('startDate', date === '' ? null : date)
                                }
                                error={errors.startDate}
                            />
                            <DateSelect
                                label="End Date"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.endDate}
                                onDateChange={(date) =>
                                    onUpdate('endDate', date === '' ? null : date)
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                </div>

                <Button
                    id="createContract"
                    content="Create"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                />
            </form>
        </div>
    );
};

export default CreateContract;
