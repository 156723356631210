import { getSelectValueMessage } from '../../utils/formValidation/errorMessageFunctions';
import { PRICE_REGEXP } from '../../validation/validation';

const GROUP_NAME_MIN_CHAR = 1;
const GROUP_NAME_MAX_CHAR = 100;
const PRAP_FILE_NAME_MAX_CHAR = 100;
const MIN_DIGIT = 1;
const MAX_DIGIT = 3;
const MIN_EARNINGS = 0.01;
const MAX_EARNINGS = 9999.99;

const ERROR_NO_GROUPNAME = 'Please enter a group name';
const ERROR_NO_VALUE = 'Please enter a value';
const ERROR_MIN_NAME = 'Please enter at least 1 character';
const ERROR_MAX_NAME = 'Max character length exceeded';
const ERROR_NO_SELECTION = getSelectValueMessage();
const ERROR_MIN_CHAR = 'Please enter at least 1 character';
const ERROR_MAX_CHAR = 'Max character length exceeded';
const ERROR_MIN_VALUE = `Value must be greater than ${MIN_EARNINGS}`;
const ERROR_MAX_VALUE = `Maximum value of ${MAX_EARNINGS} has been exceeded`;
const ERROR_ZERO = 'Value cannot be zero or negative';
const ERROR_BAD_FORMAT = 'Value must be in the correct format';

export const initialErrorState = {
    name: { error: false, message: '' },
    prapFileName: { error: false, message: '' },
    employedBeforeClaimableDays: { error: false, message: '' },
    contractId: { error: false, message: '' },
    serviceDays: { error: false, message: '' },
    claimWindowDays: { error: false, message: '' },
    jobOutcomeFromJobStartDays: { error: false, message: '' },
    higherOutcomeFromJobOutcomeDays: { error: false, message: '' },
    selfEmployedIncomeRequirementDays: { error: false, message: '' },
    earningsToJobOutcome: { error: false, message: '' },
    earningsToHigherOutcome: { error: false, message: '' },
    payeHigherOutcomeWindow: { error: false, message: '' },
    claimTypeIds: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.name) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_NO_GROUPNAME }
        };
        isValid = false;
    }

    if (newEntry.name.trim().length < GROUP_NAME_MIN_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_MIN_NAME }
        };
        isValid = false;
    }

    if (newEntry.name.trim() > GROUP_NAME_MAX_CHAR) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: ERROR_MAX_NAME }
        };
        isValid = false;
    }

    if (newEntry.prapFileName?.trim().length > PRAP_FILE_NAME_MAX_CHAR) {
        newErrors = {
            ...newErrors,
            prapFileName: { error: true, message: ERROR_MAX_NAME }
        };
        isValid = false;
    }

    if (!newEntry.employedBeforeClaimableDays) {
        newErrors = {
            ...newErrors,
            employedBeforeClaimableDays: {
                error: true,
                message: ERROR_NO_VALUE
            }
        };
        isValid = false;
    }

    if (newEntry.employedBeforeClaimableDays < 1) {
        newErrors = {
            ...newErrors,
            employedBeforeClaimableDays: {
                error: true,
                message: ERROR_ZERO
            }
        };
        isValid = false;
    }

    if (newEntry.employedBeforeClaimableDays.length < MIN_DIGIT) {
        newErrors = {
            ...newErrors,
            employedBeforeClaimableDays: { error: true, message: ERROR_MIN_CHAR }
        };
        isValid = false;
    }

    if (newEntry.employedBeforeClaimableDays.length > MAX_DIGIT) {
        newErrors = {
            ...newErrors,
            employedBeforeClaimableDays: { error: true, message: ERROR_MAX_CHAR }
        };
        isValid = false;
    }

    if (!newEntry.contractId) {
        newErrors = {
            ...newErrors,
            contractId: { error: true, message: ERROR_NO_SELECTION }
        };
        isValid = false;
    }

    if (!newEntry.serviceDays) {
        newErrors = {
            ...newErrors,
            serviceDays: {
                error: true,
                message: ERROR_NO_VALUE
            }
        };
        isValid = false;
    }

    if (newEntry.serviceDays < 1) {
        newErrors = {
            ...newErrors,
            serviceDays: {
                error: true,
                message: ERROR_ZERO
            }
        };
        isValid = false;
    }

    if (newEntry.serviceDays.length < MIN_DIGIT) {
        newErrors = {
            ...newErrors,
            serviceDays: { error: true, message: ERROR_MIN_CHAR }
        };
        isValid = false;
    }

    if (newEntry.serviceDays.length > MAX_DIGIT) {
        newErrors = {
            ...newErrors,
            serviceDays: { error: true, message: ERROR_MAX_CHAR }
        };
        isValid = false;
    }

    if (!newEntry.claimWindowDays) {
        newErrors = {
            ...newErrors,
            claimWindowDays: {
                error: true,
                message: ERROR_NO_VALUE
            }
        };
        isValid = false;
    }

    if (newEntry.claimWindowDays < 1) {
        newErrors = {
            ...newErrors,
            claimWindowDays: {
                error: true,
                message: ERROR_ZERO
            }
        };
        isValid = false;
    }

    if (newEntry.claimWindowDays.length < MIN_DIGIT) {
        newErrors = {
            ...newErrors,
            claimWindowDays: { error: true, message: ERROR_MIN_CHAR }
        };
        isValid = false;
    }

    if (newEntry.claimWindowDays.length > MAX_DIGIT) {
        newErrors = {
            ...newErrors,
            claimWindowDays: { error: true, message: ERROR_MAX_CHAR }
        };
        isValid = false;
    }

    if (!newEntry.jobOutcomeFromJobStartDays) {
        newErrors = {
            ...newErrors,
            jobOutcomeFromJobStartDays: {
                error: true,
                message: ERROR_NO_VALUE
            }
        };
        isValid = false;
    }

    if (newEntry.jobOutcomeFromJobStartDays < 1) {
        newErrors = {
            ...newErrors,
            jobOutcomeFromJobStartDays: {
                error: true,
                message: ERROR_ZERO
            }
        };
        isValid = false;
    }

    if (newEntry.jobOutcomeFromJobStartDays.length < MIN_DIGIT) {
        newErrors = {
            ...newErrors,
            jobOutcomeFromJobStartDays: { error: true, message: ERROR_MIN_CHAR }
        };
        isValid = false;
    }

    if (newEntry.jobOutcomeFromJobStartDays.length > MAX_DIGIT) {
        newErrors = {
            ...newErrors,
            jobOutcomeFromJobStartDays: { error: true, message: ERROR_MAX_CHAR }
        };
        isValid = false;
    }

    if (!newEntry.higherOutcomeFromJobOutcomeDays) {
        newErrors = {
            ...newErrors,
            higherOutcomeFromJobOutcomeDays: {
                error: true,
                message: ERROR_NO_VALUE
            }
        };
        isValid = false;
    }

    if (newEntry.higherOutcomeFromJobOutcomeDays < 1) {
        newErrors = {
            ...newErrors,
            higherOutcomeFromJobOutcomeDays: {
                error: true,
                message: ERROR_ZERO
            }
        };
        isValid = false;
    }

    if (newEntry.higherOutcomeFromJobOutcomeDays.length < MIN_DIGIT) {
        newErrors = {
            ...newErrors,
            higherOutcomeFromJobOutcomeDays: { error: true, message: ERROR_MIN_CHAR }
        };
        isValid = false;
    }

    if (newEntry.higherOutcomeFromJobOutcomeDays.length > MAX_DIGIT) {
        newErrors = {
            ...newErrors,
            higherOutcomeFromJobOutcomeDays: { error: true, message: ERROR_MAX_CHAR }
        };
        isValid = false;
    }

    if (!newEntry.selfEmployedIncomeRequirementDays) {
        newErrors = {
            ...newErrors,
            selfEmployedIncomeRequirementDays: {
                error: true,
                message: ERROR_NO_VALUE
            }
        };
        isValid = false;
    }

    if (+newEntry.selfEmployedIncomeRequirementDays < 1) {
        newErrors = {
            ...newErrors,
            selfEmployedIncomeRequirementDays: {
                error: true,
                message: ERROR_ZERO
            }
        };
        isValid = false;
    }

    if (newEntry.selfEmployedIncomeRequirementDays.length < MIN_DIGIT) {
        newErrors = {
            ...newErrors,
            selfEmployedIncomeRequirementDays: { error: true, message: ERROR_MIN_CHAR }
        };
        isValid = false;
    }

    if (newEntry.selfEmployedIncomeRequirementDays.length > MAX_DIGIT) {
        newErrors = {
            ...newErrors,
            selfEmployedIncomeRequirementDays: { error: true, message: ERROR_MAX_CHAR }
        };
        isValid = false;
    }

    if (!PRICE_REGEXP.test(newEntry.earningsToJobOutcome)) {
        newErrors = {
            ...newErrors,
            earningsToJobOutcome: { error: true, message: ERROR_BAD_FORMAT }
        };
        isValid = false;
    }

    if (newEntry.earningsToJobOutcome > MAX_EARNINGS) {
        newErrors = {
            ...newErrors,
            earningsToJobOutcome: { error: true, message: ERROR_MAX_VALUE }
        };
        isValid = false;
    }

    if (!PRICE_REGEXP.test(newEntry.earningsToHigherOutcome)) {
        newErrors = {
            ...newErrors,
            earningsToHigherOutcome: { error: true, message: ERROR_BAD_FORMAT }
        };
        isValid = false;
    }

    if (newEntry.earningsToHigherOutcome && newEntry.earningsToHigherOutcome < MIN_EARNINGS) {
        newErrors = {
            ...newErrors,
            earningsToHigherOutcome: { error: true, message: ERROR_MIN_VALUE }
        };
        isValid = false;
    }

    if (newEntry.earningsToHigherOutcome > MAX_EARNINGS) {
        newErrors = {
            ...newErrors,
            earningsToHigherOutcome: { error: true, message: ERROR_MAX_VALUE }
        };
        isValid = false;
    }

    if (
        newEntry.payeHigherOutcomeWindow &&
        typeof newEntry.payeHigherOutcomeWindow === 'string' &&
        newEntry.payeHigherOutcomeWindow.startsWith('0')
    ) {
        newErrors = {
            ...newErrors,
            payeHigherOutcomeWindow: { error: true, message: ERROR_ZERO }
        };
        isValid = false;
    }

    if (newEntry.claimTypeIds.length < 1) {
        newErrors = {
            ...newErrors,
            claimTypeIds: { error: true, message: ERROR_NO_SELECTION }
        };
        isValid = false;
    }

    const firstError = Object.keys(newErrors).find((el) => newErrors[el].error);
    firstError && document.getElementById(firstError).focus();

    return { isValid, newErrors };
};
