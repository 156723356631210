import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserRoles } from '../../../store/userSelectors';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import TextInputField from '../../formElements/TextInputField';
import LoadingSpinner from '../../ui/LoadingSpinner';

import form from '../../../commonStyles/formStyles.module.css';

const AutomatedMessagesAdmin = ({
    serviceId,
    onAutomatedMessagesChange,
    errors,
    clearError,
    onExistingAutomatedMessagesLoad
}) => {
    // LOCAL STATE
    const automatedMessageConfigurations = {
        EMAIL_APPOINTMENT_REMINDER: {
            type: 'EMAIL_APPOINTMENT_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email Appointment Reminders',
            medium: 'Email'
        },
        EMAIL_COURSE_MODULE_REMINDER: {
            type: 'EMAIL_COURSE_MODULE_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email Course Module Reminders',
            medium: 'Email'
        },
        EMAIL_FTA_APPOINTMENT_FOLLOW_UP: {
            type: 'EMAIL_FTA_APPOINTMENT_FOLLOW_UP',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email FTA Appointment Follow-up',
            medium: 'Email'
        },
        EMAIL_FTA_COURSE_MODULE_FOLLOW_UP: {
            type: 'EMAIL_FTA_COURSE_MODULE_FOLLOW_UP',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email FTA Course Module Follow-up',
            medium: 'Email'
        },
        EMAIL_MANDATORY_APPOINTMENT_REMINDER: {
            type: 'EMAIL_MANDATORY_APPOINTMENT_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email Mandatory Appointment Reminders',
            medium: 'Email'
        },
        EMAIL_MANDATORY_FTA_APPOINTMENT_FOLLOW_UP: {
            type: 'EMAIL_MANDATORY_FTA_APPOINTMENT_FOLLOW_UP',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email FTA Mandatory Appointment Follow-up',
            medium: 'Email'
        },
        EMAIL_REMOTE_APPOINTMENT_REMINDER: {
            type: 'EMAIL_REMOTE_APPOINTMENT_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated Email Remote Appointment Reminders',
            medium: 'Email'
        },
        SMS_APPOINTMENT_REMINDER: {
            type: 'SMS_APPOINTMENT_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS Appointment Reminders',
            medium: 'SMS'
        },
        SMS_COURSE_MODULE_REMINDER: {
            type: 'SMS_COURSE_MODULE_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS Course Module Reminders',
            medium: 'SMS'
        },
        SMS_FTA_APPOINTMENT_FOLLOW_UP: {
            type: 'SMS_FTA_APPOINTMENT_FOLLOW_UP',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS FTA Appointment Follow-up',
            medium: 'SMS'
        },
        SMS_FTA_COURSE_MODULE_FOLLOW_UP: {
            type: 'SMS_FTA_COURSE_MODULE_FOLLOW_UP',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS FTA Course Module Follow-up',
            medium: 'SMS'
        },

        SMS_MANDATORY_APPOINTMENT_REMINDER: {
            type: 'SMS_MANDATORY_APPOINTMENT_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS Mandatory Appointment Reminders',
            medium: 'SMS'
        },

        SMS_MANDATORY_FTA_APPOINTMENT_FOLLOW_UP: {
            type: 'SMS_MANDATORY_FTA_APPOINTMENT_FOLLOW_UP',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS FTA Mandatory Appointment Follow-up',
            medium: 'SMS'
        },

        SMS_REMOTE_APPOINTMENT_REMINDER: {
            type: 'SMS_REMOTE_APPOINTMENT_REMINDER',
            enabled: false,
            communicationTemplateId: '',
            numDays: '',
            serviceId: '',
            label: 'Automated SMS Remote Appointment Reminders',
            medium: 'SMS'
        }
    };

    const initialState = {
        serviceId: '',
        automatedMessageConfigurations
    };
    const acceptedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );
    const automatedMessages = useSelector(
        (state) => state.entities.communicationService.automatedMessages
    );
    const communicationTemplates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );

    // HELPER FNS

    const findItem = (arr, el) => {
        const templateId =
            newEntry?.automatedMessageConfigurations[el.type]['communicationTemplateId'];
        return arr.find((el) => el.id === templateId) || {}; //?.name || '';
    };

    // USEEFFECT FNS

    useEffect(() => {
        if (communicationTypes?.length < 1 || communicationTemplates?.length < 1) return;
        setSmsTemplates(
            communicationTemplates.filter(
                (el) => el.typeId === communicationTypes.find((entry) => entry.name === 'SMS')?.id
            )
        );
        setEmailTemplates(
            communicationTemplates.filter(
                (el) => el.typeId === communicationTypes.find((entry) => entry.name === 'Email')?.id
            )
        );
    }, [communicationTemplates, communicationTypes]);

    useEffect(() => {
        if (automatedMessages?.length < 1 || automatedMessages[0].serviceId !== serviceId) return;
        const messagesObj = automatedMessages.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.type]: {
                    ...cur,
                    label: automatedMessageConfigurations[cur.type]?.label || '',
                    medium: automatedMessageConfigurations[cur.type]?.medium || ''
                }
            }),
            {}
        );
        setNewEntry({ serviceId, automatedMessageConfigurations: messagesObj });
        onExistingAutomatedMessagesLoad(messagesObj);
    }, [automatedMessages]);

    // EVENT HANDLERS

    const onTemplateChange = (chosenId, type) => {
        clearError(`communicationTemplateId-${type}`);
        setNewEntry((prev) => ({
            ...prev,
            automatedMessageConfigurations: {
                ...newEntry.automatedMessageConfigurations,
                [type]: {
                    ...newEntry.automatedMessageConfigurations[type],
                    communicationTemplateId: chosenId
                }
            }
        }));
        onAutomatedMessagesChange({
            ...newEntry.automatedMessageConfigurations,
            [type]: {
                ...newEntry.automatedMessageConfigurations[type],
                communicationTemplateId: chosenId
            }
        });
    };

    const onChangeEnabled = (type, option) => {
        clearError(`communicationTemplateId-${type}`);
        clearError(`numDays-${type}`);
        setNewEntry((prev) => ({
            ...prev,
            automatedMessageConfigurations: {
                ...newEntry.automatedMessageConfigurations,
                [type]: {
                    ...newEntry.automatedMessageConfigurations[type],
                    enabled: option,
                    communicationTemplateId:
                        option === true
                            ? newEntry.automatedMessageConfigurations[type].communicationTemplateId
                            : '',
                    numDays:
                        option === true ? newEntry.automatedMessageConfigurations[type].numDays : ''
                }
            }
        }));
        onAutomatedMessagesChange({
            ...newEntry.automatedMessageConfigurations,
            [type]: {
                ...newEntry.automatedMessageConfigurations[type],
                enabled: option,
                communicationTemplateId:
                    option === true
                        ? newEntry.automatedMessageConfigurations[type].communicationTemplateId
                        : '',
                numDays:
                    option === true ? newEntry.automatedMessageConfigurations[type].numDays : ''
            }
        });
    };

    const onNumDaysChange = (e, el) => {
        clearError(`numDays-${el.type}`);
        setNewEntry((prev) => ({
            ...prev,
            automatedMessageConfigurations: {
                ...newEntry.automatedMessageConfigurations,
                [el.type]: {
                    ...newEntry.automatedMessageConfigurations[el.type],
                    numDays: e.target.value.replace(/[^\d]/g, '').slice(0, 3)
                }
            }
        }));

        onAutomatedMessagesChange({
            ...newEntry.automatedMessageConfigurations,
            [el.type]: {
                ...newEntry.automatedMessageConfigurations[el.type],
                numDays: e.target.value.replace(/[^\d]/g, '').slice(0, 3)
            }
        });
    };

    // RENDER

    const renderFormColumn = (el) => {
        return (
            <div data-testid={`automatedMessageAdmin_${el.type}`}>
                <RadioButtons
                    id={el.type}
                    label={el.label}
                    mandatory={true}
                    value={newEntry?.automatedMessageConfigurations[el.type]['enabled'] || false}
                    onChange={(option) => onChangeEnabled(el.type, option)}
                />
                <div className={`${form.hiddenWrapper} ${el.enabled ? form.showWrapper : ''}`}>
                    {el.enabled && (
                        <div className={`${form.formColumnSplit} ${form.alignUp}`}>
                            <div className={form.colSplitSection}>
                                <SingleSelect
                                    id={`communicationTemplateId-${el.type}`}
                                    label={`${
                                        el.medium === 'SMS' ? 'SMS Template' : 'Email Template'
                                    }`}
                                    placeholder="Select a template"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={true}
                                    menuItems={
                                        el.medium === 'SMS' ? smsTemplates : emailTemplates || []
                                    }
                                    selected={findItem(
                                        el.medium === 'SMS' ? smsTemplates : emailTemplates,
                                        el
                                    )}
                                    selectedId={
                                        newEntry.automatedMessageConfigurations[el.type]
                                            .communicationTemplateId || ''
                                    }
                                    error={errors[`communicationTemplateId-${el.type}`]}
                                    onChange={(chosenId) => onTemplateChange(chosenId, el.type)}
                                />
                            </div>
                            <div className={form.colSplitSection}>
                                <TextInputField
                                    id={`numDays-${el.type}`}
                                    label={`Days ${
                                        el.type.includes('REMINDER') ? 'prior' : 'after'
                                    } to send`}
                                    placeholder="Enter number"
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    mandatory={true}
                                    value={
                                        newEntry?.automatedMessageConfigurations[el.type][
                                            'numDays'
                                        ] || ''
                                    }
                                    customClass="numberInput"
                                    error={errors[`numDays-${el.type}`]}
                                    onChange={(e) => onNumDaysChange(e, el)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (!serviceId) {
        return <LoadingSpinner content="No service Id" />;
    }

    return (
        <>
            <h3>Automated Messages</h3>
            <div className={form.formWrapper}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {Object.values(newEntry.automatedMessageConfigurations).map(
                            (el, i) => i < 7 && <div key={el.type}> {renderFormColumn(el)} </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        {Object.values(newEntry.automatedMessageConfigurations).map(
                            (el, i) => i >= 7 && <div key={el.type}> {renderFormColumn(el)} </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

AutomatedMessagesAdmin.propTypes = {
    serviceId: PropTypes.string,
    onAutomatedMessagesChange: PropTypes.func,
    errors: PropTypes.object,
    clearError: PropTypes.func,
    onExistingAutomatedMessagesLoad: PropTypes.func,
    clear: PropTypes.bool
};

export default AutomatedMessagesAdmin;
