import { compareUnorderedArrays } from '../../../utils/arrayUtils';

export const MAX_DAYS = 999;

export const ERROR_NO_TEAM = 'Please select a default team';
export const ERROR_NO_ELIGIBILITY_GROUP = 'Please select a default eligibility group';
export const ERROR_MAX_DAYS = `Cannot be more than ${MAX_DAYS}`;
export const ERROR_NO_EXIT_INTERVIEW_BOOKING_DAYS = 'Please enter a value';
export const ERROR_NO_EXIT_INTERVIEW_BOOKING_PERIOD_DAYS = 'Please enter a value';
export const ERROR_NO_DIFF = 'There is nothing to update';

export const initialErrorState = {
    defaultTeamId: { error: false, message: '' },
    defaultEligibilityGroupId: { error: false, message: '' },
    exitInterviewBookingDays: { error: false, message: '' },
    exitInterviewBookingPeriodDays: { error: false, message: '' },
    button: { error: false, message: '' }
};

export const validate = (newEntry, initial) => {
    let newErrors = {};
    if (!newEntry.defaultTeamId) {
        newErrors = {
            ...newErrors,
            defaultTeamId: {
                error: true,
                message: ERROR_NO_TEAM
            }
        };
    }

    if (!newEntry.defaultEligibilityGroupId) {
        newErrors = {
            ...newErrors,
            defaultEligibilityGroupId: {
                error: true,
                message: ERROR_NO_ELIGIBILITY_GROUP
            }
        };
    }

    if (+newEntry.exitInterviewBookingDays > MAX_DAYS) {
        newErrors = {
            ...newErrors,
            exitInterviewBookingDays: {
                error: true,
                message: ERROR_MAX_DAYS
            }
        };
    }

    if (newEntry.exitInterviewBookingPeriodDays && !newEntry.exitInterviewBookingDays) {
        newErrors = {
            ...newErrors,
            exitInterviewBookingDays: {
                error: true,
                message: ERROR_NO_EXIT_INTERVIEW_BOOKING_DAYS
            }
        };
    }

    if (newEntry.exitInterviewBookingPeriodDays && !newEntry.exitInterviewBookingDays) {
        newErrors = {
            ...newErrors,
            exitInterviewBookingDays: {
                error: true,
                message: ERROR_NO_EXIT_INTERVIEW_BOOKING_DAYS
            }
        };
    }

    if (+newEntry.exitInterviewBookingPeriodDays > MAX_DAYS) {
        newErrors = {
            ...newErrors,
            exitInterviewBookingPeriodDays: {
                error: true,
                message: ERROR_MAX_DAYS
            }
        };
    }

    if (newEntry.exitInterviewBookingDays && !newEntry.exitInterviewBookingPeriodDays) {
        newErrors = {
            ...newErrors,
            exitInterviewBookingPeriodDays: {
                error: true,
                message: ERROR_NO_EXIT_INTERVIEW_BOOKING_PERIOD_DAYS
            }
        };
    }

    if (newEntry.exitInterviewBookingDays && !newEntry.exitInterviewBookingPeriodDays) {
        newErrors = {
            ...newErrors,
            exitInterviewBookingPeriodDays: {
                error: true,
                message: ERROR_NO_EXIT_INTERVIEW_BOOKING_PERIOD_DAYS
            }
        };
    }

    if (Object.keys(newErrors).length < 1) {
        const isSame = compareUnorderedArrays(Object.values(initial), Object.values(newEntry));
        if (isSame) {
            newErrors = {
                ...newErrors,
                button: {
                    error: true,
                    message: ERROR_NO_DIFF
                }
            };
        }
    }

    return { newErrors };
};
