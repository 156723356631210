import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Typography } from '@mui/material';

import {
    loadJobSectorDetails,
    loadPoolAcceptedRejectionReasonsDetails,
    loadPoolRequestedRejectionReasonsDetails,
    loadPoolStatusDetails
} from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { setErrorMessage } from '../../../../store/formsState';
import { loadParticipant } from '../../../../store/participantService';
import {
    loadPoolApplicationsByPoolIds,
    loadPoolsByUser,
    updatePoolApplicationsAccepted
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { UPDATE } from '../../../../utils/uiConstants';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import SingleSelect from '../../../formElements/SingleSelect';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import MultiSelect from '../../../ui/pickers/MultiSelect';

import PoolApplicationRejectEditor from './PoolApplicationRejectEditor';
import PoolEditor from './PoolEditor';
import PoolTable from './PoolTable';

import classes from '../../recruitmentStyles/pool.module.css';

const PoolTableManagement = () => {
    const { handleSubmit } = useForm({});

    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const poolOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [poolRows, setPoolRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rejectedRow, setRejectedRow] = useState({});
    const [rowCancelled, setRowCancelled] = useState(false);
    const [poolApplicationsLoaded, setPoolApplicationsLoaded] = useState(false);
    const [poolEditor, setPoolEditor] = useState(false);
    const [poolOwners, setPoolOwners] = useState([]);
    const [selectedPoolOwner, setSelectedPoolOwner] = useState('');
    const [updatedPoolApplications, setUpdatedPoolApplications] = useState([]);
    const [poolApplicationRejectEditor, setPoolApplicationRejectEditor] = useState(false);
    const [teamDetails, setTeamDetails] = useState([]);

    const [arrayJobSectors, setArrayJobSectors] = useState([]);
    const [jobSectorPools, setJobSectorPools] = useState([]);
    const [preSelectedJobSectors, setPreSelectedJobSectors] = useState([]);
    const [preSelectedJobSectorIds, setPreSelectedJobSectorIds] = useState([]);
    const [selectedJobSectorIds, setSelectedJobSectorIds] = useState([]);

    const [isClearSelectedPoolOwner, setIsClearSelectedPoolOwner] = useState('0');
    const [isClearSelectedJobSector, setIsClearSelectedJobSector] = useState('1');

    // STORE STATE
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { poolApplications, pools } = useSelector((state) => state.entities.recruitmentService);
    const {
        jobSectorDetails,
        poolAcceptedRejectionReasonsDetails,
        poolRequestedRejectionReasonsDetails,
        poolStatusDetails
    } = useSelector((state) => state.entities.directusService);

    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );

    // EVENT HANDLERS
    const handlePoolOwnerChange = (chosenId) => {
        if (!chosenId || Object.keys(chosenId).length === 0) {
            chosenId = '';
            setRows([]);
            setFilteredRows([]);
            setUpdatedPoolApplications([]);
            setJobSectorPools([]);
        }
        setSelectedPoolOwner(chosenId);
    };

    const handleJobSectorChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedJobSectorIds(chosenIds);
    };

    const setPool = () => {
        onPoolEditor(true);
    };

    // HELPER FNS
    const setPoolOwnersArray = (poolOwners) => {
        const updatedPoolOwners = addEmailAddressAsNameToArray(poolOwners);
        setPoolOwners(updatedPoolOwners);
    };

    const getTeams = (id) => {
        if (!id) return;
        const teamDisplay = users.find((entry) => entry.id === id);
        if (teamDisplay) {
            const { primaryTeam, otherTeams } = teamDisplay;
            if (primaryTeam) {
                setTeamDetails([primaryTeam, ...otherTeams]);
            } else {
                setTeamDetails([]);
            }
        }
    };

    // USE EFFECTS
    useEffect(() => {
        setIsClearSelectedPoolOwner(Math.random());
        setIsClearSelectedJobSector(Math.random());

        dispatch(loadJobSectorDetails());
        dispatch(loadPoolStatusDetails());
        dispatch(loadPoolAcceptedRejectionReasonsDetails());
        dispatch(loadPoolRequestedRejectionReasonsDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        if (loggedInUser.roles.some((el) => poolOwnerRoles.includes(el))) {
            setSelectedPoolOwner(loggedInUser.id);
            setPoolOwnersArray([loggedInUser]);
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedPoolOwners = users.filter((el) =>
            el.userTypes?.find((entry) => poolOwnerRoles.includes(entry.role))
        );
        if (!poolOwners.length && users.length < totalUsers) {
            onLoadMorePoolOwners();
            return;
        }
        if (selectedPoolOwner && !updatedPoolOwners.some((el) => el.id === selectedPoolOwner)) {
            // Put selected pool owner at the top of dropdown if it's not in the updated pool owners array
            const selectedAdvisor = poolOwners.find((el) => el.id === selectedPoolOwner);
            updatedPoolOwners = [selectedAdvisor, ...updatedPoolOwners];
        }
        setPoolOwnersArray(updatedPoolOwners);
    }, [users]);

    useEffect(() => {
        if (!selectedPoolOwner) return;
        dispatch(loadPoolsByUser(selectedPoolOwner));
        setJobSectorPools([]);
        getTeams(selectedPoolOwner);
    }, [selectedPoolOwner]);

    useEffect(() => {
        if (pools?.length < 1) {
            setPoolRows([]);
            setRows([]);
            setFilteredRows([]);
            setUpdatedPoolApplications([]);
            setPoolApplicationsLoaded(true);
            return;
        }

        setPoolApplicationsLoaded(false);
        const filteredPools = pools.filter((el) => el.ownerId === selectedPoolOwner);

        if (!filteredPools) {
            setPoolRows([]);
            setRows([]);
            setPoolApplicationsLoaded(true);
            setJobSectorPools([]);
            return;
        }

        const filteredPoolIds = filteredPools.map((el) => el.id);
        const filteredPoolsWithId = filteredPools.map((el) => ({
            ...el,
            id: uuid(),
            poolId: el.id
        }));

        const filteredRowsWithSectorName = filteredPoolsWithId.map((el) => ({
            ...el,
            jobSectorName: jobSectorDetails.find((entry) => entry.id === el.jobSectorId)?.name || ''
        }));

        const jobSectors = jobSectorDetails.filter((el) =>
            filteredRowsWithSectorName.find((entry) => el.id === entry.jobSectorId)
        );
        setJobSectorPools(jobSectorDetails.filter((el) => !jobSectors.includes(el)));
        dispatch(loadPoolApplicationsByPoolIds(filteredPoolIds));
        setPoolRows(filteredRowsWithSectorName);
        setRows(filteredRowsWithSectorName);
        setFilteredRows(filteredRowsWithSectorName);
        setUpdatedPoolApplications(filteredRowsWithSectorName);
    }, [pools]);

    useEffect(() => {
        if (
            poolApplications.length < 1 ||
            jobSectorDetails.length < 1 ||
            poolStatusDetails.length < 1 ||
            poolApplicationsLoaded
        )
            return;
        let updatedPoolsWithApplications = poolRows;
        setPoolApplicationsLoaded(true);
        poolApplications.forEach((el) => {
            let newPoolEntryArray = [];
            updatedPoolsWithApplications = updatedPoolsWithApplications.map((entry) => {
                if (entry.poolId === el.participantPoolId) {
                    if (entry.participantId) {
                        const newPoolEntry = {
                            id: uuid(),
                            ownerId: entry.ownerId,
                            jobSectorId: entry.jobSectorId,
                            jobSectorName: entry.jobSectorName,
                            teamIds: entry.teamIds,
                            poolId: entry.poolId,
                            requirements: entry.requirements,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantPoolApplicationId: el.id,
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || 'z-',
                            statusId: el.statusId,
                            statusName: getNameFromId(poolStatusDetails, el.statusId),
                            reasons: el.rejection?.rejectionIds.map((el) => el) || '',
                            notes: el.rejection?.notes || ''
                        };
                        // Check for duplicate
                        if (newPoolEntryArray.length > 0) {
                            if (
                                newPoolEntryArray.some(
                                    (npe) =>
                                        npe.jobSectorId === newPoolEntry.jobSectorId &&
                                        npe.participantPoolApplicationId ===
                                            newPoolEntry.participantPoolApplicationId
                                )
                            ) {
                                return entry;
                            }
                        }
                        newPoolEntryArray = [...newPoolEntryArray, newPoolEntry];
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantPoolApplicationId: el.id,
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || 'z-',
                            statusId: el.statusId,
                            statusName: getNameFromId(poolStatusDetails, el.statusId),
                            reasons: el.rejection?.rejectionIds?.map((el) => el) || '',
                            notes: el.rejection?.notes || ''
                        };
                    }
                } else {
                    if (entry.participantId) {
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantPoolApplicationId: null,
                            participantId: null,
                            goldStandardCVId: null,
                            startDate: 'z-',
                            statusId: '',
                            statusName: '',
                            reasons: [],
                            notes: ''
                        };
                    }
                }
            });
            if (newPoolEntryArray?.length > 0) {
                updatedPoolsWithApplications = [
                    ...updatedPoolsWithApplications,
                    ...newPoolEntryArray
                ];
            }
        });
        const participantIds = [
            ...new Set(updatedPoolsWithApplications.map((el) => el.participantId))
        ];
        participantIds.map((el) => {
            if (el) {
                dispatch(loadParticipant(el));
                dispatch(loadParticipantDocumentDetails(el));
            }
        });
        setRows(updatedPoolsWithApplications);
        setFilteredRows(updatedPoolsWithApplications);
        setUpdatedPoolApplications(updatedPoolsWithApplications);
    }, [poolApplications, jobSectorDetails, poolStatusDetails]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length > 0) {
            rows.map((el) => {
                if (el.participantId === currentParticipant.id) {
                    el.ptCode = currentParticipant.ptCode;
                    el.ptName = `${currentParticipant.firstName} ${currentParticipant.lastName}`;
                }
            });
            setFilteredRows(rows);
            setUpdatedPoolApplications(rows);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (participantDocuments?.length > 0) {
            rows.map((el) => {
                participantDocuments.map((entry) => {
                    if (
                        el.participantId === entry.participantId &&
                        el.goldStandardCVId === entry.id
                    )
                        el.filename = entry?.filename || '';
                });
            });
            setFilteredRows(rows);
            setUpdatedPoolApplications(rows);
        }
    }, [participantDocuments]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectors(jobSectorDetails);
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (rows.length < 1 || selectedJobSectorIds.length < 1) return;
        const filteredRows = rows.filter((el) =>
            selectedJobSectorIds.some((v) => v === el.jobSectorId)
        );
        setFilteredRows(filteredRows);
        setUpdatedPoolApplications(filteredRows);
    }, [selectedJobSectorIds]);

    useEffect(() => {
        if (successMessage === `Pool applications have been loaded`) {
            setPoolApplicationsLoaded(true);
        }
    }, [successMessage]);

    // CALLBACKS
    const onLoadMorePoolOwners = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    const onPoolEditor = (setOpen) => {
        setPoolEditor(setOpen);
    };

    const onUpdateParticipantStatus = (statusId, participantId, participantPoolApplicationId) => {
        const modified = updatedPoolApplications.map((el) =>
            el.participantId === participantId &&
            el.participantPoolApplicationId === participantPoolApplicationId
                ? { ...el, statusId }
                : el
        );
        setUpdatedPoolApplications(modified);
    };

    const onPoolApplicationRejectEditor = (setOpen) => {
        setPoolApplicationRejectEditor(setOpen);
    };

    const onPoolApplicationRejectCancelled = (cancelled, rejectedRow) => {
        if (cancelled) {
            const resetRows = rows.filter((el) => el.id !== rejectedRow.id);
            setRows([...resetRows, rejectedRow]);
            setFilteredRows([...resetRows, rejectedRow]);
            setUpdatedPoolApplications([...resetRows, rejectedRow]);
            setRowCancelled(true);
        } else {
            setRowCancelled(false);
        }
    };

    const onPoolRejectedRow = (row) => {
        setRejectedRow(row);
        setRowCancelled(false);
    };

    const onPoolRejectedUpdate = (update) => {
        setPoolApplicationsLoaded(update);
    };

    // FORM SUBMIT
    const onSubmit = () => {
        const poolStatusId = poolStatusDetails.find(
            (el) => el.name.toLowerCase() === 'accepted'
        )?.id;

        const acceptedPoolApplicationIds = updatedPoolApplications
            .filter((el) => el.statusId === poolStatusId && el.startDate === 'z-')
            .map((el) => el.participantPoolApplicationId);

        if (acceptedPoolApplicationIds && acceptedPoolApplicationIds.length > 0) {
            setPoolApplicationsLoaded(false);
            setRows([]);
            setFilteredRows([]);
            dispatch(updatePoolApplicationsAccepted({ ids: acceptedPoolApplicationIds }));
        } else {
            dispatch(setErrorMessage(`There is nothing to submit.`));
        }
    };

    // RENDER
    let content = '';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (poolStatusDetails?.length < 1) content = 'No pool status details';
    if (!poolAcceptedRejectionReasonsDetails)
        content = 'pool accepted rejection reasons not loaded';
    if (!poolRequestedRejectionReasonsDetails)
        content = 'pool requested rejection reasons not loaded';
    if (!poolApplicationsLoaded) content = 'pool applications not loaded';
    if (
        jobSectorDetails?.length < 1 ||
        poolStatusDetails?.length < 1 ||
        poolAcceptedRejectionReasonsDetails?.length < 1 ||
        poolRequestedRejectionReasonsDetails?.length < 1 ||
        !poolApplicationsLoaded
    )
        return <LoadingSpinner content={content} />;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <br />
                <Typography className={classes.poolTitle} variant={'h5'}>
                    Pooling
                </Typography>
                <div className={classes.poolToolBar}>
                    <SingleSelect
                        id={'poolOwnerId'}
                        key={isClearSelectedPoolOwner}
                        label={'Pool Owner'}
                        placeholder="Select Pool Owner..."
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        menuItems={poolOwners}
                        selectedId={selectedPoolOwner}
                        selected={poolOwners?.find((el) => el.id === selectedPoolOwner) || {}}
                        onChange={(e) => {
                            if (e !== null && e !== undefined) {
                                handlePoolOwnerChange(e);
                            }
                        }}
                        onLoadMoreItems={onLoadMorePoolOwners}
                        moreItemsToLoad={!totalUsers || users.length < totalUsers}
                    />
                    <div className={classes.multiSelectorWrapper}>
                        <MultiSelect
                            id="jobSectorId"
                            key={isClearSelectedJobSector}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            data-testid="jobSectors"
                            label="Job Sector"
                            placeholder="Job Sector"
                            menuItems={jobSectorDetails || []}
                            preSelectedIds={preSelectedJobSectorIds}
                            preSelects={preSelectedJobSectors}
                            onChange={(chosenIds) => handleJobSectorChange(chosenIds)}
                        />
                    </div>
                    <div>
                        <Button
                            className={classes.poolToolBarButton}
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) || !selectedPoolOwner
                            }
                            color="primary"
                            variant="contained"
                            size={'small'}
                            onClick={setPool}>
                            Create Pool
                            <Box width={SCREEN_SETTINGS.gap.medium} />
                            <AddCircleOutlineIcon />
                        </Button>
                    </div>
                </div>
                <PoolEditor
                    open={poolEditor}
                    onPoolEditor={onPoolEditor}
                    jobSectorDetails={jobSectorPools.length > 0 ? jobSectorPools : jobSectorDetails}
                    selectedPoolOwner={selectedPoolOwner}
                    poolOwners={poolOwners}
                    teamDetails={teamDetails}
                />
                <PoolApplicationRejectEditor
                    open={poolApplicationRejectEditor}
                    onPoolApplicationRejectEditor={onPoolApplicationRejectEditor}
                    onPoolApplicationRejectCancelled={onPoolApplicationRejectCancelled}
                    updatedPoolApplications={updatedPoolApplications}
                    rejectedRow={rejectedRow}
                    onPoolRejectedUpdate={onPoolRejectedUpdate}
                />

                {filteredRows.length < 1 ? (
                    <LoadingSpinner content="No pools found" />
                ) : (
                    <PoolTable
                        rows={filteredRows}
                        roles={loggedInUser.roles}
                        onUpdateParticipantStatus={onUpdateParticipantStatus}
                        onPoolApplicationRejectEditor={onPoolApplicationRejectEditor}
                        onPoolRejectedRow={onPoolRejectedRow}
                        rowCancelled={rowCancelled}
                    />
                )}

                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="testIdSubmitButton">
                    {UPDATE}
                </Button>
            </form>
        </>
    );
};

export default PoolTableManagement;
