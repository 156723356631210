import React from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

import '../../commonStyles/muiStyles.css';
import modal from '../../commonStyles/modal.module.css';
import local from './datasets.module.css';

const DataSetsModal = ({ resultRowsLength, onCancel, isOpen }) => {
    return (
        <Dialog open={isOpen} fullWidth={true} className="muiDialogWrapper">
            <div className={modal.modalHeader}>
                <DialogTitle>Data Set Modal</DialogTitle>
            </div>
            <DialogContent>
                <div className={`${modal.modalUploaderContent} ${local.modalResults}`}>
                    <div className={local.modalHeader}>Results</div>
                    <div className={local.modalContent}>
                        <span>Number of Entries: </span>
                        <span> {resultRowsLength} </span>
                    </div>
                </div>
                <div className={modal.actionButtons}>
                    <div className={modal.cancelButton}>
                        <Button variant={'contained'} onClick={onCancel}>
                            Close
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DataSetsModal;

DataSetsModal.propTypes = {
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    resultRowsLength: PropTypes.number
};
