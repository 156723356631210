import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import CloseIcon from '@mui/icons-material/Close';

import ActionHistory from './ActionHistory';
import StatusHistory from './StatusHistory';

import app from '../../../app.module.css';
import dropdownClasses from '../claimStyles/rowDropDown.module.css';

const ValidationRowDetails = ({ row, onClose }) => {
    // LOCAL STATE
    const currentStatus = {
        statusId: row.statusId,
        reasonId: row.reasonId,
        date: row.lastStatusUpdated,
        notes: row.notes,
        userId: row.userId
    };
    const currentAndHistoryStatus = [currentStatus, ...row.history];

    const currentActionStatus = {
        actionStatusId: row.actionStatusId,
        date: row.lastActionStatusUpdated,
        actionNotes: row.actionNotes,
        userId: row.userId
    };
    const currentAndHistoryActionStatus = [currentActionStatus, ...row.actionStatusHistory];

    return (
        <div className={dropdownClasses.rowDropdown}>
            <div className={app.sectionHeading}>Status History</div>
            {currentAndHistoryStatus.map((el) => (
                <StatusHistory key={uuid()} history={el} />
            ))}

            <div className={dropdownClasses.divider}>
                <div className={dropdownClasses.dividerLine}></div>
            </div>

            <div className={`${app.sectionHeading}`}>Action History</div>
            {currentAndHistoryActionStatus.map((el) => (
                <ActionHistory key={uuid()} history={el} />
            ))}

            <div className={dropdownClasses.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default ValidationRowDetails;

ValidationRowDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
