import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';

import './pillList.css';

const PillList = ({ id = '', pills = [], onRemoving }) => {
    return (
        <span data-testid={id} className={'pillList'}>
            {pills.map((name, index) => (
                <span role={'pill'} className={'pill'} key={`${id}-${index}`}>
                    <span className={'pillText'}>{name}</span>
                    <span role={'button'} className={'clearIcon'} onClick={() => onRemoving(name)}>
                        <ClearIcon data-testid={`clear_icon-${name.toLowerCase()}`} />
                    </span>
                </span>
            ))}
        </span>
    );
};

PillList.propTypes = {
    id: PropTypes.string,
    pills: PropTypes.arrayOf(PropTypes.string),
    onRemoving: PropTypes.func
};

export default PillList;
