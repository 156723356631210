import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    clearBenefitCode,
    clearJobCentre,
    clearJobReadiness,
    clearJobSector,
    clearReadinessToChange,
    clearRightToWorkDocType,
    clearTimeUnemployed,
    loadBenefitCodeDetails,
    loadJobCentreDetails,
    loadJobReadinessDetails,
    loadJobSectorDetails,
    loadReadinessToChangeDetails,
    loadRightToWorkDocTypes,
    loadTimeUnemployedDetails
} from '../../store/directusService';
import {
    updatePreSelectedMultiItems,
    updatePreSelects,
    updateSelectedItems
} from '../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import NotFound from '../notFound/NotFound';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';
import MultiSelect from '../ui/pickers/MultiSelect';

import '../../commonStyles/loading.css';
import form from '../../commonStyles/formStyles.module.css';

// Note! This code uses 'console.log' for logging and 'alert' to tell the user about successes/errors.
// Replace these when logging and notifications are implemented.

/**
 * EmployabilityAdmin component.
 *
 * This updates the primary details DDL for Job Readiness, Readiness to Change, Job Centre, Time Unemployed, Job Goals - Job Sector
 *
 * When the form is submitted onSubmit is called. This validates that DDLs are not blank. *
 */

const EmployabilityAdmin = ({ contractId }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    // group into a single object at some point

    const isChangedInitialState = {
        jobReadiness: false,
        jobCentres: false,
        timeUnemployed: false,
        benefitCodes: false,
        readinessToChange: false,
        jobSectors: false,
        rightToWorkDocTypes: false
    };
    const [isChanged, setIsChanged] = useState(isChangedInitialState);
    const [selectedJobReadiness, setSelectedJobReadiness] = useState([]);
    const [selectedJobCentreIds, setSelectedJobCentreIds] = useState([]);
    const [selectedJobSectors, setSelectedJobSectors] = useState([]);
    const [selectedTimeUnemployed, setSelectedTimeUnemployed] = useState([]);
    const [selectedBenefitCodes, setSelectedBenefitCodes] = useState([]);
    const [selectedReadinessToChange, setSelectedReadinessToChange] = useState([]);
    const [selectedRightToWorkDocTypes, setSelectedRightToWorkDocTypes] = useState([]);

    const [preSelectedJobReadiness, setPreSelectedJobReadiness] = useState([]);
    const [preSelectedJobCentres, setPreSelectedJobCentres] = useState([]);
    const [preSelectedJobCentreIds, setPreSelectedJobCentreIds] = useState([]);
    const [preSelectedJobSectors, setPreSelectedJobSectors] = useState([]);
    const [preSelectedTimeUnemployed, setPreSelectedTimeUnemployed] = useState([]);
    const [preSelectedBenefitCodes, setPreSelectedBenefitCodes] = useState([]);
    const [preSelectedReadinessToChange, setPreSelectedReadinessToChange] = useState([]);
    const [preSelectedRightToWorkDocTypes, setPreSelectedRightToWorkDocTypes] = useState([]);

    const [arrayJobReadiness, setArrayJobReadiness] = useState([]);
    const [arrayJobCentre, setArrayJobCentres] = useState([]);
    const [arrayJobSector, setArrayJobSectors] = useState([]);
    const [arrayTimeUnemployed, setArrayTimeUnemployed] = useState([]);
    const [arrayBenefitCodes, setArrayBenefitCodes] = useState([]);
    const [arrayReadinessToChange, setArrayReadinessToChange] = useState([]);
    const [arrayRightToWorkDocTypes, setArrayRightToWorkDocTypes] = useState([]);

    const [isClearSelectedJobReadiness, setIsClearSelectedJobReadiness] = useState('1');
    const [isClearSelectedJobCentres, setIsClearSelectedJobCentres] = useState('2');
    const [isClearSelectedTimeUnemployed, setIsClearSelectedTimeUnemployed] = useState('3');
    const [isClearSelectedBenefitCodes, setIsClearSelectedBenefitCodes] = useState('4');
    const [isClearSelectedReadinessToChange, setIsClearSelectedReadinessToChange] = useState('5');
    const [isClearSelectedJobSector, setIsClearSelectedJobSectors] = useState('6');
    const [isClearSelectedRightToWorkDocType, setIsClearSelectedRightToWorkDocTypes] =
        useState('7');

    const [showJobCentresLoading, setShowJobCentresLoading] = useState(false);
    const [showJobCentresPending, setShowJobCentresPending] = useState(false);

    const submitMessages = {
        jobReadiness: 'Job readiness updated',
        jobCentres: 'Job centres updated',
        timeUnemployed: 'Time unemployed updated',
        benefitCodes: 'Benefit codes updated',
        readinessToChange: 'Readiness to change updated',
        jobSectors: 'Job sectors updated',
        rightToWorkDocTypes: 'Right to work document types updated'
    };

    // STORE STATE

    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const {
        jobReadinessDetails,
        jobCentreDetails,
        jobCentreDetailsLoading,
        job_centreUpdatePending,
        timeUnemployedDetails,
        benefitCodeDetails,
        readinessToChangeDetails,
        jobSectorDetails,
        rightToWorkDocTypes
    } = useSelector((state) => state.entities.directusService);

    const dispatch = useDispatch();

    // EVENT HANDLER FACTORY
    const handleFieldChange =
        (field, selectedFieldSetter, preSelectedFieldValues) => (chosenIds) => {
            if (!chosenIds) chosenIds = [];
            setValue(field, chosenIds, { shouldValidate: true });
            selectedFieldSetter(chosenIds);
            if (!isChanged[field] && chosenIds.length !== preSelectedFieldValues.length)
                setIsChanged((prev) => ({ ...prev, [field]: true }));
        };

    // USEEFFECTS
    const contractSetup = () => {
        setSelectedJobReadiness([]);
        setSelectedJobCentreIds([]);
        setSelectedTimeUnemployed([]);
        setSelectedBenefitCodes([]);
        setSelectedReadinessToChange([]);
        setSelectedJobSectors([]);
        setSelectedRightToWorkDocTypes([]);

        setPreSelectedJobReadiness([]);
        setPreSelectedJobCentres([]);
        setPreSelectedJobCentreIds([]);
        setPreSelectedTimeUnemployed([]);
        setPreSelectedBenefitCodes([]);
        setPreSelectedReadinessToChange([]);
        setPreSelectedJobSectors([]);
        setPreSelectedRightToWorkDocTypes([]);

        setArrayJobReadiness([]);
        setArrayJobCentres([]);
        setArrayTimeUnemployed([]);
        setArrayBenefitCodes([]);
        setArrayReadinessToChange([]);
        setArrayJobSectors([]);
        setArrayRightToWorkDocTypes([]);

        setIsClearSelectedJobReadiness(Math.random());
        setIsClearSelectedJobCentres(Math.random());
        setIsClearSelectedTimeUnemployed(Math.random());
        setIsClearSelectedBenefitCodes(Math.random());
        setIsClearSelectedReadinessToChange(Math.random());
        setIsClearSelectedJobSectors(Math.random());
        setIsClearSelectedRightToWorkDocTypes(Math.random());

        dispatch(clearJobReadiness());
        dispatch(clearJobCentre());
        dispatch(clearTimeUnemployed());
        dispatch(clearBenefitCode());
        dispatch(clearReadinessToChange());
        dispatch(clearJobSector());
        dispatch(clearRightToWorkDocType());

        dispatch(loadJobReadinessDetails());
        dispatch(loadJobCentreDetails());
        dispatch(loadTimeUnemployedDetails());
        dispatch(loadBenefitCodeDetails());
        dispatch(loadReadinessToChangeDetails());
        dispatch(loadJobSectorDetails());
        dispatch(loadRightToWorkDocTypes());
    };

    useEffect(() => {
        if (contractId !== '') contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (jobReadinessDetails?.length && arrayJobReadiness?.length === 0) {
            setPreSelectedJobReadiness(
                updatePreSelectedMultiItems(
                    'job_readiness',
                    jobReadinessDetails,
                    contractId,
                    dispatch
                )
            );
            setArrayJobReadiness(jobReadinessDetails);
        }
    }, [jobReadinessDetails]);

    useEffect(() => {
        if (jobCentreDetails?.length && arrayJobCentre?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'job_centre',
                jobCentreDetails,
                contractId
            );
            setPreSelectedJobCentres(preSelects);
            setPreSelectedJobCentreIds(preSelectIds);
            setArrayJobCentres(jobCentreDetails);
        }
    }, [jobCentreDetails]);

    useEffect(() => {
        if (timeUnemployedDetails?.length && arrayTimeUnemployed?.length === 0) {
            setPreSelectedTimeUnemployed(
                updatePreSelectedMultiItems(
                    'time_unemployed',
                    timeUnemployedDetails,
                    contractId,
                    dispatch
                )
            );
            setArrayTimeUnemployed(timeUnemployedDetails);
        }
    }, [timeUnemployedDetails]);

    useEffect(() => {
        if (benefitCodeDetails?.length && arrayBenefitCodes?.length === 0) {
            setPreSelectedBenefitCodes(
                updatePreSelectedMultiItems(
                    'benefit_code',
                    benefitCodeDetails,
                    contractId,
                    dispatch
                )
            );
            setArrayBenefitCodes(benefitCodeDetails);
        }
    }, [benefitCodeDetails]);

    useEffect(() => {
        if (readinessToChangeDetails?.length && arrayReadinessToChange?.length === 0) {
            setPreSelectedReadinessToChange(
                updatePreSelectedMultiItems(
                    'readiness_to_change',
                    readinessToChangeDetails,
                    contractId,
                    dispatch
                )
            );
            setArrayReadinessToChange(readinessToChangeDetails);
        }
    }, [readinessToChangeDetails]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSector?.length === 0) {
            setPreSelectedJobSectors(
                updatePreSelectedMultiItems('job_sector', jobSectorDetails, contractId, dispatch)
            );
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        jobCentreDetailsLoading ? setShowJobCentresLoading(true) : setShowJobCentresLoading(false);
    }, [jobCentreDetailsLoading]);

    useEffect(() => {
        job_centreUpdatePending ? setShowJobCentresPending(true) : setShowJobCentresPending(false);
    }, [job_centreUpdatePending]);

    useEffect(() => {
        if (rightToWorkDocTypes?.length && arrayRightToWorkDocTypes?.length === 0) {
            setPreSelectedRightToWorkDocTypes(
                updatePreSelectedMultiItems(
                    'right_to_work_document_type',
                    rightToWorkDocTypes,
                    contractId,
                    dispatch
                )
            );
            setArrayRightToWorkDocTypes(rightToWorkDocTypes);
        }
    }, [rightToWorkDocTypes]);

    useEffect(() => {
        if (successMessage.includes(`Updating ${submitMessages.jobReadiness} collection`)) {
            setSelectedJobReadiness([]);
            setPreSelectedJobReadiness([]);
            setArrayJobReadiness([]);
            setIsClearSelectedJobReadiness(Math.random());
            dispatch(clearJobReadiness());
            dispatch(loadJobReadinessDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.jobCentres} collection`)) {
            setSelectedJobCentreIds([]);
            setPreSelectedJobCentres([]);
            setPreSelectedJobCentreIds([]);
            setArrayJobCentres([]);
            setIsClearSelectedJobCentres(Math.random());
            dispatch(clearJobCentre());
            dispatch(loadJobCentreDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.timeUnemployed} collection`)) {
            setSelectedTimeUnemployed([]);
            setPreSelectedTimeUnemployed([]);
            setArrayTimeUnemployed([]);
            setIsClearSelectedTimeUnemployed(Math.random());
            dispatch(clearTimeUnemployed());
            dispatch(loadTimeUnemployedDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.benefitCodes} collection`)) {
            setSelectedBenefitCodes([]);
            setPreSelectedBenefitCodes([]);
            setArrayBenefitCodes([]);
            setIsClearSelectedBenefitCodes(Math.random());
            dispatch(clearBenefitCode());
            dispatch(loadBenefitCodeDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.readinessToChange} collection`)) {
            setSelectedReadinessToChange([]);
            setPreSelectedReadinessToChange([]);
            setArrayReadinessToChange([]);
            setIsClearSelectedReadinessToChange(Math.random());
            dispatch(clearReadinessToChange());
            dispatch(loadReadinessToChangeDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.jobSectors} collection`)) {
            setSelectedJobSectors([]);
            setPreSelectedJobSectors([]);
            setArrayJobSectors([]);
            setIsClearSelectedJobSectors(Math.random());
            dispatch(clearJobSector());
            dispatch(loadJobSectorDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.rightToWorkDocTypes} collection`)) {
            setSelectedRightToWorkDocTypes([]);
            setPreSelectedRightToWorkDocTypes([]);
            setArrayRightToWorkDocTypes([]);
            setIsClearSelectedRightToWorkDocTypes(Math.random());
            dispatch(clearRightToWorkDocType());
            dispatch(loadRightToWorkDocTypes());
        }
        setIsChanged(isChangedInitialState);
    }, [successMessage]);

    // FORM SUBMIT
    const onSubmit = () => {
        isChanged.jobReadiness &&
            updateSelectedItems(
                'job_readiness',
                jobReadinessDetails,
                selectedJobReadiness,
                contractId,
                submitMessages.jobReadiness,
                dispatch
            );

        isChanged.jobCentres &&
            updateSelectedItems(
                'job_centre',
                jobCentreDetails,
                selectedJobCentreIds,
                contractId,
                submitMessages.jobCentres,
                dispatch
            );

        isChanged.timeUnemployed &&
            updateSelectedItems(
                'time_unemployed',
                timeUnemployedDetails,
                selectedTimeUnemployed,
                contractId,
                submitMessages.timeUnemployed,
                dispatch
            );

        isChanged.benefitCodes &&
            updateSelectedItems(
                'benefit_code',
                benefitCodeDetails,
                selectedBenefitCodes,
                contractId,
                submitMessages.benefitCodes,
                dispatch
            );

        isChanged.readinessToChange &&
            updateSelectedItems(
                'readiness_to_change',
                readinessToChangeDetails,
                selectedReadinessToChange,
                contractId,
                submitMessages.readinessToChange,
                dispatch
            );

        isChanged.jobSectors &&
            updateSelectedItems(
                'job_sector',
                jobSectorDetails,
                selectedJobSectors,
                contractId,
                submitMessages.jobSectors,
                dispatch
            );

        isChanged.rightToWorkDocTypes &&
            updateSelectedItems(
                'right_to_work_document_type',
                rightToWorkDocTypes,
                selectedRightToWorkDocTypes,
                contractId,
                submitMessages.rightToWorkDocTypes,
                dispatch
            );
    };

    // RENDER
    let content = '';

    if (!contractId) content = 'No Contract Id';
    if (jobReadinessDetails?.length < 1) content = 'No job readiness details';
    if (readinessToChangeDetails?.length < 1) content = 'No readiness to change details';
    if (jobCentreDetails?.length < 1) content = 'No job centre details';
    if (timeUnemployedDetails?.length < 1) content = 'No time unemployed details';
    if (benefitCodeDetails?.length < 1) content = 'No benefit code details';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (rightToWorkDocTypes?.length < 1) content = 'No right to work document types';

    if (!hasRole([SUPERUSER, LOCAL_ADMIN], roles)) return <NotFound />;
    if (
        !contractId ||
        jobReadinessDetails?.length < 1 ||
        readinessToChangeDetails?.length < 1 ||
        jobCentreDetails?.length < 1 ||
        timeUnemployedDetails?.length < 1 ||
        benefitCodeDetails?.length < 1 ||
        jobSectorDetails?.length < 1 ||
        rightToWorkDocTypes?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="form_start_PersonalInformationEmployabilityAdmin">
            <br />
            <p>Mandatory Activity</p>
            <DDLMultiOptionPicker
                heading={'Configure Job Readiness DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                id="jobReadiness"
                mandatory={true}
                key={isClearSelectedJobReadiness}
                label="Job Readiness"
                placeholder="Job Readiness"
                menuItems={jobReadinessDetails || []}
                preSelectedIds={preSelectedJobReadiness}
                chosenIds={selectedJobReadiness}
                error={errors.jobReadiness}
                {...register('jobReadiness')}
                onChange={handleFieldChange(
                    'jobReadiness',
                    setSelectedJobReadiness,
                    preSelectedJobReadiness
                )}
            />
            <DDLMultiOptionPicker
                heading={'Configure Readiness To Change DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                id="readinessToChange"
                mandatory={true}
                key={isClearSelectedReadinessToChange}
                label="Readiness To Change"
                placeholder="Readiness To Change"
                menuItems={readinessToChangeDetails || []}
                preSelectedIds={preSelectedReadinessToChange}
                chosenIds={selectedReadinessToChange}
                error={errors.readinessToChange}
                {...register('readinessToChange')}
                onChange={handleFieldChange(
                    'readinessToChange',
                    setSelectedReadinessToChange,
                    preSelectedReadinessToChange
                )}
            />
            <div className={form.pageMultiSelectorWrapper}>
                <MultiSelect
                    id="jobCentres"
                    label="Job Centres *"
                    placeholder="Job centres"
                    key={isClearSelectedJobCentres}
                    disabled={!hasRole([SUPERUSER], roles)}
                    menuItems={jobCentreDetails || []}
                    preSelectedIds={preSelectedJobCentreIds}
                    preSelects={preSelectedJobCentres}
                    error={errors.jobCentres}
                    onChange={handleFieldChange(
                        'jobCentres',
                        setSelectedJobCentreIds,
                        preSelectedJobCentreIds
                    )}
                />
                {showJobCentresLoading && (
                    <div className="ddlLoadingIcon ddlMargin">Loading...</div>
                )}
                {showJobCentresPending && (
                    <div className="ddlLoadingIcon ddlMargin">Pending...</div>
                )}
            </div>

            <p>Job Centre Plus Contact</p>
            <DDLMultiOptionPicker
                heading={'Configure Time Unemployed DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                id="timeUnemployed"
                mandatory={true}
                key={isClearSelectedTimeUnemployed}
                label="Time Unemployed"
                placeholder="Time Unemployed"
                menuItems={timeUnemployedDetails || []}
                preSelectedIds={preSelectedTimeUnemployed}
                chosenIds={selectedTimeUnemployed}
                error={errors.timeUnemployed}
                {...register('timeUnemployed')}
                onChange={handleFieldChange(
                    'timeUnemployed',
                    setSelectedTimeUnemployed,
                    preSelectedTimeUnemployed
                )}
            />
            <p>Right to work documents available?</p>
            <DDLMultiOptionPicker
                heading={'Configure Benefit Code DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                id="benefitCodes"
                mandatory={true}
                key={isClearSelectedBenefitCodes}
                label="Benefit Codes"
                placeholder="Benefit Codes"
                menuItems={benefitCodeDetails || []}
                preSelectedIds={preSelectedBenefitCodes}
                chosenIds={selectedBenefitCodes}
                error={errors.benefitCodes}
                {...register('benefitCodes')}
                onChange={handleFieldChange(
                    'benefitCodes',
                    setSelectedBenefitCodes,
                    preSelectedBenefitCodes
                )}
            />
            <DDLMultiOptionPicker
                heading={'Configure Job Sector DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                id="jobSectors"
                mandatory={true}
                key={isClearSelectedJobSector}
                label="Job Sectors"
                placeholder="Job Sectors"
                menuItems={jobSectorDetails || []}
                preSelectedIds={preSelectedJobSectors}
                chosenIds={selectedJobSectors}
                error={errors.jobSectors}
                {...register('jobSectors')}
                onChange={handleFieldChange(
                    'jobSectors',
                    setSelectedJobSectors,
                    preSelectedJobSectors
                )}
            />
            <DDLMultiOptionPicker
                heading={'Configure Right to Work Document Type DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                id="rightToWorkDocTypes"
                mandatory={true}
                key={isClearSelectedRightToWorkDocType}
                label="Right to Work Document Types"
                placeholder="Right to Work Document Types"
                menuItems={rightToWorkDocTypes || []}
                preSelectedIds={preSelectedRightToWorkDocTypes}
                chosenIds={selectedRightToWorkDocTypes}
                error={errors.rightToWorkDocTypes}
                {...register('rightToWorkDocTypes')}
                onChange={handleFieldChange(
                    'rightToWorkDocTypes',
                    setSelectedRightToWorkDocTypes,
                    preSelectedRightToWorkDocTypes
                )}
            />
            <p>&nbsp;</p>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton">
                {'UPDATE EMPLOYABILITY DETAILS'}
            </Button>
        </form>
    );
};

// Not a lot to validate until DDLs are in place.
// No other fields are mandatory.
const validationSchema = Yup.object().shape({
    jobReadiness: Yup.array().min(1, 'No job readiness selected.').nullable(),
    readinessToChange: Yup.array().min(1, 'No readiness to change selected.').nullable(),
    jobCentres: Yup.array().min(1, 'No job centres selected.').nullable(),
    timeUnemployed: Yup.array().min(1, 'No time unemployed selected.').nullable(),
    benefitCodes: Yup.array().min(1, 'No benefit codes selected.').nullable(),
    jobSectors: Yup.array().min(1, 'No job sectors selected.').nullable(),
    rightToWorkDocTypes: Yup.array().min(1, 'No right to work document types selected.').nullable()
});

EmployabilityAdmin.propTypes = {
    contractId: PropTypes.string
};

export default EmployabilityAdmin;
