import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import classes from '../../../../commonStyles/tables.module.css';

const OccSummaryDataRow = ({ row }) => {
    return (
        <TableRow hover className={classes.basicRowStripes}>
            <TableCell>{row.eventDate}</TableCell>
            <TableCell align="center">{row.visits === 'zz' ? ' - ' : row.visits}</TableCell>
            <TableCell>{row.group === 'zz' ? ' - ' : row.group}</TableCell>
            <TableCell>{row.type === 'zz' ? ' - ' : row.type}</TableCell>
        </TableRow>
    );
};

export default OccSummaryDataRow;

OccSummaryDataRow.propTypes = {
    row: PropTypes.object
};
