import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import LoadingSpinner from '../../ui/LoadingSpinner';

import DarHistory from './DarHistory';

import dropdownClasses from '../homeStyles/rowDropDown.module.css';

const DarDetails = ({ row, onClose, darStatuses }) => {
    // LOCAL STATE
    const current = {
        statusId: row.statusId,
        userId: row.userId,
        date: row.dateUpdated,
        notes: row.notes
    };
    const currentAndHistory = [current, ...row.history];

    return !row ? (
        <LoadingSpinner content="No DAR history found" />
    ) : (
        <div className={dropdownClasses.rowDropdown}>
            <Typography variant="h5">DAR History</Typography>
            <p></p>
            {currentAndHistory.map((el) => (
                <DarHistory key={uuid()} history={el} darStatuses={darStatuses} />
            ))}
            <div className={dropdownClasses.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default DarDetails;

DarDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    darStatuses: PropTypes.array
};
