import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Collapse,
    FormControlLabel,
    Radio,
    RadioGroup,
    TableCell,
    TableRow
} from '@mui/material';

import { loadParticipantByPtCode } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { hasRole } from '../../../utils/userRoles';

import ReversionDetails from './ReversionDetails';

import classes from '../../../commonStyles/tables.module.css';
import claimsClasses from '../claimStyles/validations.module.css';

const ClaimTableRow = ({ row, roles, toggleDropdown, openId, onRowChange }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [open, setOpen] = useState(null);

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    function onEditParticipant() {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            navigate('/edit_participant');
        }
    }

    function statusChange(selection) {
        const updatedRow = {
            ...row,
            selection
        };
        onRowChange(updatedRow);
    }

    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.oldLace} ${classes.canOpen}`}>
                <TableCell
                    className={claimsClasses.claimsCodeLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(roles, loggedInUser.roles)}
                    style={{
                        color: POLARIS_ROYAL_BLUE,
                        cursor: 'pointer'
                    }}>
                    {row?.ptCode}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row?.poNumber}</TableCell>
                <TableCell onClick={onToggleDropdown}>{row.eventName}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.eventDate)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.lastStatusUpdated)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row.statusName}</TableCell>
                <TableCell>
                    <RadioGroup
                        row
                        aria-labelledby="statusUpdate"
                        value={row.selection}
                        id="statusUpdate"
                        onChange={(e) => statusChange(e.target.value)}>
                        <FormControlLabel
                            id="proceed"
                            disabled={row.statusName === 'Paid'}
                            required={false}
                            value="Proceed"
                            control={
                                <Radio
                                    sx={{
                                        marginLeft: '25px',
                                        color: POLARIS_ROYAL_BLUE
                                    }}
                                />
                            }
                            size="small"
                        />
                        <FormControlLabel
                            id="revert"
                            className={claimsClasses.claimRadioGroup}
                            required={false}
                            value="Revert"
                            control={
                                <Radio
                                    sx={{
                                        marginLeft: '25px',
                                        color: POLARIS_ROYAL_BLUE
                                    }}
                                />
                            }
                            size="small"
                        />
                        <FormControlLabel
                            id="cannotClaim"
                            value="CannotClaim"
                            required={false}
                            control={
                                <Radio
                                    sx={{
                                        marginLeft: '25px',
                                        color: POLARIS_ROYAL_BLUE
                                    }}
                                />
                            }
                            size="small"
                        />
                        <FormControlLabel
                            id="skip"
                            value="Skip"
                            required={false}
                            control={
                                <Radio
                                    sx={{
                                        marginLeft: '25px',
                                        color: POLARIS_ROYAL_BLUE
                                    }}
                                />
                            }
                            size="small"
                        />
                    </RadioGroup>
                </TableCell>
            </TableRow>
            {row?.selection !== 'Revert' ? (
                ''
            ) : (
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderTop: 'none',
                            borderBottom: 'none'
                        }}
                        colSpan={7}>
                        <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                            <Box>
                                <ReversionDetails row={row} onClose={() => setOpen(null)} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default ClaimTableRow;

ClaimTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    onRowChange: PropTypes.func
};
