import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    clearComorbidity,
    clearGp,
    clearReligion,
    loadComorbidityDetails,
    loadGpDetails,
    loadReligionDetails
} from '../../store/directusService';
import { setErrorMessage } from '../../store/formsState';
import { updatePreSelects, updateSelectedItems } from '../../utils/directusFunctions';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import NotFound from '../notFound/NotFound';
import LoadingSpinner from '../ui/LoadingSpinner';
import MultiSelect from '../ui/pickers/MultiSelect';

import '../../commonStyles/loading.css';
import form from '../../commonStyles/formStyles.module.css';
// Note! This code uses 'console.log' for logging and 'alert' to tell the user about successes/errors.
// Replace these when logging and notifications are implemented.

/**
 * PersonalInformationAdmin component.
 *
 * This updates the participant information details DDL for comorbidity & mental health
 *
 * When the form is submitted onSubmit is called. This validates that DDLs are not blank.
 *
 */

const HealthInformationAdmin = ({ contractId }) => {
    const { handleSubmit } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // LOCAL STATE
    // const submitMessage = 'health information';
    const [selectedGps, setSelectedGps] = useState([]);
    const [preSelectedGpIds, setPreSelectedGpIds] = useState([]);
    const [preSelectedGps, setPreSelectedGps] = useState([]);
    const [arrayGps, setArrayGps] = useState([]);
    const [isClearSelectedGps, setIsClearSelectedGps] = useState('0');

    const [selectedComorbidities, setSelectedComorbidities] = useState([]);
    const [preSelectedComorbidities, setPreSelectedComorbidities] = useState([]);
    const [preSelectedComorbidityIds, setPreSelectedComorbidityIds] = useState([]);
    const [arrayComorbidities, setArrayComorbidities] = useState([]);
    const [isClearSelectedComorbidities, setIsClearSelectedComorbidities] = useState('1');

    const [selectedReligion, setSelectedReligion] = useState([]);
    const [preSelectedReligion, setPreSelectedReligion] = useState([]);
    const [preSelectedReligionIds, setPreSelectedReligionIds] = useState([]);
    const [arrayReligion, setArrayReligion] = useState([]);
    const [isClearSelectedReligion, setIsClearSelectedReligion] = useState('2');

    const isChangedInitialState = {
        gps: false,
        comorbidities: false,
        religion: false
    };
    const [isChanged, setIsChanged] = useState(isChangedInitialState);
    const [showGPLoading, setShowGPLoading] = useState(false);
    const [showGPPending, setShowGPPending] = useState(false);

    const submitMessages = {
        gps: 'GPs updated',
        comorbidities: 'Comorbidities updated',
        religion: 'Religion updated'
    };

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { gpDetails, gpDetailsLoading, gpUpdatePending, comorbidityDetails, religionDetails } =
        useSelector((state) => state.entities.directusService);
    const dispatch = useDispatch();

    // USEEFFECTS
    const contractSetup = () => {
        setSelectedGps([]);
        setSelectedComorbidities([]);
        setSelectedReligion([]);

        setPreSelectedGpIds([]);
        setPreSelectedGps([]);
        setPreSelectedComorbidityIds([]);
        setPreSelectedComorbidities([]);
        setPreSelectedReligionIds([]);
        setPreSelectedReligion([]);

        setArrayGps([]);
        setArrayComorbidities([]);
        setArrayReligion([]);

        setIsClearSelectedGps(Math.random());
        setIsClearSelectedComorbidities(Math.random());
        setIsClearSelectedReligion(Math.random());

        dispatch(clearGp());
        dispatch(clearComorbidity());
        dispatch(clearReligion());
        dispatch(loadGpDetails());
        dispatch(loadGpDetails(2));
        dispatch(loadGpDetails(3));
        dispatch(loadComorbidityDetails());
        dispatch(loadReligionDetails());
    };

    useEffect(() => {
        if (contractId) contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (gpDetails?.length > 2000 && arrayGps?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects('gp', gpDetails, contractId);
            setPreSelectedGps(preSelects);
            setPreSelectedGpIds(preSelectIds);
            setArrayGps(gpDetails);
        }
    }, [gpDetails]);

    useEffect(() => {
        if (comorbidityDetails?.length && arrayComorbidities?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'comorbidity',
                comorbidityDetails,
                contractId
            );
            setPreSelectedComorbidities(preSelects);
            setPreSelectedComorbidityIds(preSelectIds);
            setArrayComorbidities(comorbidityDetails);
        }
    }, [comorbidityDetails]);

    useEffect(() => {
        if (religionDetails?.length && arrayReligion?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                'religion',
                religionDetails,
                contractId
            );
            setPreSelectedReligion(preSelects);
            setPreSelectedReligionIds(preSelectIds);
            setArrayReligion(religionDetails);
        }
    }, [religionDetails]);

    useEffect(() => {
        gpDetailsLoading ? setShowGPLoading(true) : setShowGPLoading(false);
    }, [gpDetailsLoading]);

    useEffect(() => {
        gpUpdatePending ? setShowGPPending(true) : setShowGPPending(false);
    }, [gpUpdatePending]);

    useEffect(() => {
        if (successMessage.includes(submitMessages.gps)) {
            setSelectedGps([]);
            setPreSelectedGpIds([]);
            setPreSelectedGps([]);
            setArrayGps([]);
            setIsClearSelectedGps(Math.random());
            dispatch(clearGp());
            dispatch(loadGpDetails());
            dispatch(loadGpDetails(2));
            dispatch(loadGpDetails(3));
        }
        if (successMessage.includes(submitMessages.comorbidities)) {
            setSelectedComorbidities([]);
            setPreSelectedComorbidityIds([]);
            setPreSelectedComorbidities([]);
            setArrayComorbidities([]);
            setIsClearSelectedComorbidities(Math.random());
            dispatch(clearComorbidity());
            dispatch(loadComorbidityDetails());
        }
        if (successMessage.includes(submitMessages.religion)) {
            setSelectedReligion([]);
            setPreSelectedReligion([]);
            setArrayReligion([]);
            setIsClearSelectedReligion(Math.random());
            dispatch(clearReligion());
            dispatch(loadReligionDetails());
        }
        setIsChanged(isChangedInitialState);
    }, [successMessage]);

    // FORM SUBMIT
    const onSubmit = () => {
        if (!selectedGps.length) {
            dispatch(setErrorMessage('No gp selected'));
        } else if (!selectedComorbidities.length) {
            dispatch(setErrorMessage('No comorbidity selected'));
        } else if (!selectedReligion.length) {
            dispatch(setErrorMessage('No religion selected'));
        } else {
            isChanged.gps &&
                updateSelectedItems(
                    'gp',
                    gpDetails,
                    selectedGps,
                    contractId,
                    submitMessages.gps,
                    dispatch
                );

            isChanged.comorbidities &&
                updateSelectedItems(
                    'comorbidity',
                    comorbidityDetails,
                    selectedComorbidities,
                    contractId,
                    submitMessages.comorbidities,
                    dispatch
                );

            isChanged.religion &&
                updateSelectedItems(
                    'religion',
                    religionDetails,
                    selectedReligion,
                    contractId,
                    submitMessages.religion,
                    dispatch
                );
        }
    };

    // RENDER
    let content = '';
    if (gpDetails?.length < 1) content = 'No GP details';
    if (comorbidityDetails?.length < 1) content = 'No comorbidity details';
    if (religionDetails?.length < 1) content = 'No religion details';

    if (!hasRole([SUPERUSER, LOCAL_ADMIN], roles)) return <NotFound />;

    if (
        !contractId ||
        gpDetails?.length < 1 ||
        comorbidityDetails?.length < 1 ||
        religionDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start_HealthInformationAdmin">
            <p>Doctor’s Statement</p>

            <div className={form.pageMultiSelectorWrapper}>
                <MultiSelect
                    id="gps"
                    key={isClearSelectedGps}
                    disabled={!hasRole([SUPERUSER], roles)}
                    heading="Configure GP DDL"
                    label="GP"
                    placeholder="GP"
                    menuItems={gpDetails || []}
                    preSelectedIds={preSelectedGpIds}
                    preSelects={preSelectedGps}
                    onChange={(chosenIds) => {
                        setSelectedGps(chosenIds || []);
                        setIsChanged((prev) => ({ ...prev, gps: true }));
                    }}
                />
                {showGPLoading && <div className="ddlLoadingIcon">Loading...</div>}
                {showGPPending && <div className="ddlLoadingIcon">Pending...</div>}
            </div>

            <MultiSelect
                id="comorbidities"
                key={isClearSelectedComorbidities}
                disabled={!hasRole([SUPERUSER], roles)}
                heading={'Configure Co-morbidities DDL'}
                data-testid="comorbidities"
                label="Comorbidity"
                placeholder="Comorbidity"
                menuItems={comorbidityDetails || []}
                preSelectedIds={preSelectedComorbidityIds}
                preSelects={preSelectedComorbidities}
                onChange={(chosenIds) => {
                    setSelectedComorbidities(chosenIds || []);
                    setIsChanged((prev) => ({ ...prev, comorbidities: true }));
                }}
            />

            <p>Pregnant?</p>
            <p>{"Midwife's Name"}</p>
            <p>{"Midwife's Phone"}</p>
            <p>{"Midwife's Email"}</p>
            <MultiSelect
                id="religion"
                key={isClearSelectedReligion}
                heading={'Configure Religion DDL'}
                disabled={!hasRole([SUPERUSER], roles)}
                data-testid="religion"
                label="Religion"
                placeholder="Religion"
                menuItems={religionDetails || []}
                preSelectedIds={preSelectedReligionIds}
                preSelects={preSelectedReligion}
                onChange={(chosenIds) => {
                    setSelectedReligion(chosenIds || []);
                    setIsChanged((prev) => ({ ...prev, religion: true }));
                }}
            />
            <p>Smoker?</p>
            <p>Employed?</p>
            <p>&nbsp;</p>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid="testIdSubmitButton">
                {'UPDATE HEALTH INFORMATION'}
            </Button>
        </form>
    );
};

// Not a lot to validate until DDLs are in place.
// No other fields are mandatory.
const validationSchema = Yup.object().shape({});

HealthInformationAdmin.propTypes = {
    contractId: PropTypes.string
};

export default HealthInformationAdmin;
