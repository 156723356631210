import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Link } from '@mui/material';

import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../utils/userRoles';

/**
 * Settings button.
 * Shown at top right of Header
 *
 * This is only accessible if user has one of these roles
 * ADVISER, MANAGER, QUALITY, SUPERUSER
 *
 * See PPL-271
 */

const Settings = () => {
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    // Can only access settings if user has the right role
    const disabled = !hasRole(roles, [ADVISER, MANAGER, QUALITY, SUPERUSER]);

    const color = disabled ? 'disabled' : 'secondary';

    const navigate = useNavigate();

    const handleSettings = () => {
        // Do something when clicked. This is TBC
        if (hasRole(roles, [ADVISER, MANAGER, QUALITY, SUPERUSER])) {
            navigate('/tbc', {
                state: { text: 'Settings options are not available yet' }
            });
        }
    };

    return (
        <Link onClick={handleSettings}>
            <SettingsOutlinedIcon disabled={disabled} color={color} />
        </Link>
    );
};
export default Settings;
