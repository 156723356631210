import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import { reverseFormatDate } from '../../../../utils/dateFunctions';

import form from '../../../../commonStyles/formStyles.module.css';
import expand from '../../../../commonStyles/rowExpand.module.css';

const HealtIncidentRowDetails = ({ row, onClose }) => {
    return (
        <div className={expand.rowDropdown}>
            <div className={expand.content}>
                <div className={form.fullWidth}>
                    <div className={form.staticText}>
                        <div className={form.formLabel}>Fibre Supplement Date</div>
                        <div className={form.textBox}>
                            {row.fibreSupplementDate
                                ? reverseFormatDate(row.fibreSupplementDate)
                                : ' - '}
                        </div>
                    </div>

                    {row.otherDetail && (
                        <div className={form.staticText}>
                            <div className={form.formLabel}>Other Details</div>
                            <div className={form.textBox}>{row.otherDetail}</div>
                        </div>
                    )}
                </div>
                <div className={form.staticText}>
                    <div className={form.formLabel}>Notes</div>
                    <div className={`${form.textBox} ${form.alignLeft}`}>{row.note}</div>
                </div>
            </div>
            <div className={expand.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default HealtIncidentRowDetails;

HealtIncidentRowDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
