import { setErrorMessage } from '../../../store/formsState';
import { isDatePast } from '../../../utils/dateFunctions';

const DATE_RANGE_INVALID = 'Appointment date must be later than now';
const TIME_RANGE_INVALID = 'Appointment end time must be later than appointment start time';
const APPT_TYPE_INVALID = 'Please select an appointment type';
const SERVICE_INVALID = 'Please select a service';
const ADVISER_INVALID = 'Please select an adviser';
const PARTICIPANT_INVALID = 'Please select a participant';
const LOCATION_INVALID = 'Please select a location';

export const validateUpdates = (entry, dispatch) => {
    let errorMessage;
    if (!entry.appointmentType) errorMessage = APPT_TYPE_INVALID;
    if (!entry.service) errorMessage = SERVICE_INVALID;
    if (!entry.userId) errorMessage = ADVISER_INVALID;
    if (!entry.locationId) errorMessage = LOCATION_INVALID;
    if (!entry?.numberOfSlots && !entry.participantId) errorMessage = PARTICIPANT_INVALID;
    if (isDatePast(`${entry.date}T${entry.startTime}`)) errorMessage = DATE_RANGE_INVALID;
    if (entry.endTime <= entry.startTime) errorMessage = TIME_RANGE_INVALID;
    if (errorMessage) {
        dispatch(setErrorMessage(errorMessage));
        return false;
    }
    return true;
};

export const updateAdvisers = (users, serviceId, roles) => {
    return users
        .filter((el) => el.active)
        .filter((el) => el.userTypes.find((entry) => roles.includes(entry.role)))
        .filter((el) => [el.primaryService, ...el.otherServices].includes(serviceId));
};

export const updateLocations = (locations, serviceId) => {
    return locations
        .filter((el) =>
            el.service.some(
                (entry) => entry.service_id === serviceId || entry.service_id?.id === serviceId
            )
        )
        .filter((el, i, arr) => i === arr.findIndex((entry) => entry.name === el.name));
};

export const updateParticipants = (participants, serviceId) => {
    return participants.filter((el) => el.serviceId === serviceId);
};
