import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    clearFrequency,
    clearPlacementEmploymentType,
    loadFrequencyDetails,
    loadPlacementEmploymentTypeDetails
} from '../../store/directusService';
import { loadContractConstants, updateContractConstants } from '../../store/participantService';
import { updatePreSelectedMultiItems, updateSelectedItems } from '../../utils/directusFunctions';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import NotFound from '../notFound/NotFound';
import LabelledTextField from '../ui/editors/LabelledTextField';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';
import NoYesRadioPicker from '../ui/pickers/NoYesRadioPicker';

import form from '../../commonStyles/formStyles.module.css';

const PlacementsAdmin = ({ contractId }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER];
    const isChangedInitialState = {
        employmentTypeIds: false,
        frequencyIds: false
    };
    const initialState = {
        id: '',
        contractId: '',
        numDaysIWSRequired: '',
        canIWSBeDeclined: true,
        displayBarrierTableInIWS: true,
        displayActionTableInIWS: true
    };

    const [newEntry, setNewEntry] = useState(initialState);

    const [selectedPlacementEmploymentTypes, setSelectedPlacementEmploymentTypes] = useState([]);
    const [selectedFrequencies, setSelectedFrequencies] = useState([]);

    const [preSelectedPlacementEmploymentTypes, setPreSelectedPlacementEmploymentTypes] = useState(
        []
    );
    const [preSelectedFrequencies, setPreSelectedFrequencies] = useState([]);

    const [arrayPlacementEmploymentTypes, setArrayPlacementEmploymentTypes] = useState([]);
    const [arrayFrequencies, setArrayFrequencies] = useState([]);

    const [isClearSelectedPlacementEmploymentTypes, setIsClearSelectedPlacementEmploymentTypes] =
        useState('1');
    const [isClearSelectedFrequencies, setIsClearSelectedFrequencies] = useState('2');

    const [isChanged, setIsChanged] = useState(isChangedInitialState);

    const submitMessages = {
        placementEmploymentTypes: 'placement_employment_type updated',
        frequency: 'frequency updated'
    };

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const placementEmploymentTypeDetails = useSelector(
        (state) => state.entities.directusService.placementEmploymentTypeDetails
    );
    const frequencyDetails = useSelector(
        (state) => state.entities.directusService.frequencyDetails
    );
    let dbContractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // EVENT HANDLERS
    const handleFieldChange =
        (field, selectedFieldSetter, preSelectedFieldValues) => (chosenIds) => {
            if (!chosenIds) chosenIds = [];
            setValue(field, chosenIds, { shouldValidate: true });
            selectedFieldSetter(chosenIds);
            if (!isChanged[field] && chosenIds.length !== preSelectedFieldValues.length)
                setIsChanged((prev) => ({ ...prev, [field]: true }));
        };

    const handleNumDaysIWSRequiredChange = (e) => {
        setNewEntry((prev) => ({ ...prev, numDaysIWSRequired: e.target.value }));
        setValue('numDaysIWSRequired', e.target.value, { shouldValidate: true });
    };

    // USE EFFECT FNS
    const contractSetup = () => {
        dbContractConstants = {};
        setNewEntry(initialState);
        setNewEntry((prev) => ({ ...prev, contractId: contractId }));
        setSelectedPlacementEmploymentTypes([]);
        setSelectedFrequencies([]);

        setPreSelectedPlacementEmploymentTypes([]);
        setPreSelectedFrequencies([]);

        setArrayPlacementEmploymentTypes([]);
        setArrayFrequencies([]);

        setIsClearSelectedPlacementEmploymentTypes(Math.random());
        setIsClearSelectedFrequencies(Math.random());

        dispatch(clearPlacementEmploymentType());
        dispatch(clearFrequency());

        dispatch(loadContractConstants(contractId));
        dispatch(loadPlacementEmploymentTypeDetails());
        dispatch(loadFrequencyDetails());
    };

    useEffect(() => {
        if (contractId === '') return;
        setNewEntry((prev) => ({ ...prev, contractId: contractId }));
        contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (Object.keys(dbContractConstants)?.length) {
            setNewEntry(dbContractConstants);
            setValue('numDaysIWSRequired', dbContractConstants.numDaysIWSRequired, {
                shouldValidate: true
            });
        }
    }, [dbContractConstants]);

    useEffect(() => {
        if (placementEmploymentTypeDetails?.length && arrayPlacementEmploymentTypes?.length === 0) {
            setPreSelectedPlacementEmploymentTypes(
                updatePreSelectedMultiItems(
                    'placement_employment_type',
                    placementEmploymentTypeDetails,
                    contractId
                )
            );
            setArrayPlacementEmploymentTypes(placementEmploymentTypeDetails);
        }
    }, [placementEmploymentTypeDetails]);

    useEffect(() => {
        if (frequencyDetails?.length && arrayFrequencies.length === 0) {
            setPreSelectedFrequencies(
                updatePreSelectedMultiItems('frequency', frequencyDetails, contractId)
            );
            setArrayFrequencies(frequencyDetails);
        }
    }, [frequencyDetails]);

    useEffect(() => {
        if (
            successMessage.includes(
                `Updating ${submitMessages.placementEmploymentTypes} collection`
            )
        ) {
            setSelectedPlacementEmploymentTypes([]);
            setPreSelectedPlacementEmploymentTypes([]);
            setArrayPlacementEmploymentTypes([]);
            setIsClearSelectedPlacementEmploymentTypes(Math.random());
            dispatch(clearPlacementEmploymentType());
            dispatch(loadPlacementEmploymentTypeDetails());
        }
        if (successMessage.includes(`Updating ${submitMessages.frequency} collection`)) {
            setSelectedFrequencies([]);
            setPreSelectedFrequencies([]);
            setArrayFrequencies([]);
            setIsClearSelectedFrequencies(Math.random());
            dispatch(clearFrequency());
            dispatch(loadFrequencyDetails());
        }
    }, [successMessage]);

    // FORM SUBMIT
    const onSubmit = () => {
        isChanged.employmentTypeIds &&
            updateSelectedItems(
                'placement_employment_type',
                placementEmploymentTypeDetails,
                selectedPlacementEmploymentTypes,
                contractId,
                submitMessages.placementEmploymentTypes,
                dispatch
            );
        isChanged.frequencyIds &&
            updateSelectedItems(
                'frequency',
                frequencyDetails,
                selectedFrequencies,
                contractId,
                submitMessages.frequency,
                dispatch
            );
        const payload = { ...newEntry, contractId: contractId };
        dispatch(updateContractConstants(payload));
    };

    // RENDER
    let content = '';
    if (!contractId) content = 'No Contract Id';
    if (placementEmploymentTypeDetails?.length < 1)
        content = 'No placement employment type details';
    if (frequencyDetails?.length < 1) content = 'No frequency details';

    if (!hasRole(acceptedRoles, roles)) return <NotFound />;

    if (!contractId || placementEmploymentTypeDetails?.length < 1 || frequencyDetails?.length < 1)
        return <LoadingSpinner content={content} />;

    return (
        <div className={form.formWrapper}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                data-testid="form_start_placements"
                className={form.form}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.basePadding}>
                            <DDLMultiOptionPicker
                                id="employmentTypeIds"
                                key={isClearSelectedPlacementEmploymentTypes}
                                label="Placement Employment Type"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                data-testid="employmentTypeIds"
                                placeholder="Placement Employment Type"
                                menuItems={placementEmploymentTypeDetails || []}
                                preSelectedIds={preSelectedPlacementEmploymentTypes}
                                chosenIds={selectedPlacementEmploymentTypes}
                                error={errors.employmentTypeIds}
                                {...register('employmentTypeIds')}
                                onChange={handleFieldChange(
                                    'employmentTypeIds',
                                    setSelectedPlacementEmploymentTypes,
                                    preSelectedPlacementEmploymentTypes
                                )}
                            />
                        </div>
                        <div className={form.basePadding}>
                            <DDLMultiOptionPicker
                                id="frequencyIds"
                                key={isClearSelectedFrequencies}
                                label="Frequency"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                data-testid="frequencyIds"
                                placeholder="Frequency"
                                menuItems={frequencyDetails || []}
                                preSelectedIds={preSelectedFrequencies}
                                chosenIds={selectedFrequencies}
                                error={errors.frequencyIds}
                                {...register('frequencyIds')}
                                onChange={handleFieldChange(
                                    'frequencyIds',
                                    setSelectedFrequencies,
                                    preSelectedFrequencies
                                )}
                            />
                        </div>

                        <LabelledTextField
                            type="number"
                            id={'numDaysIWSRequired'}
                            mandatory={true}
                            label={'Number of Days IWS is Required For'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.numDaysIWSRequired}
                            placeholder={'Enter value'}
                            error={errors.numDaysIWSRequired}
                            {...register('numDaysIWSRequired')}
                            onChange={handleNumDaysIWSRequiredChange}
                        />
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.radioSetAlign}>
                            <NoYesRadioPicker
                                id="canIWSBeDeclined"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                label="Can IWS Be Declined"
                                radioButtonPick={newEntry.canIWSBeDeclined}
                                onChange={(option) =>
                                    setNewEntry((prev) => ({ ...prev, canIWSBeDeclined: option }))
                                }></NoYesRadioPicker>
                        </div>
                        <div className={form.radioSetAlign}>
                            <NoYesRadioPicker
                                id="displayBarrierTableInIWS"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                radioButtonPick={newEntry.displayBarrierTableInIWS}
                                label="Display Barrier Table in IWS"
                                onChange={(option) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        displayBarrierTableInIWS: option
                                    }))
                                }></NoYesRadioPicker>
                        </div>
                        <div className={form.radioSetAlign}>
                            <NoYesRadioPicker
                                id="displayActionTableInIWS"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                radioButtonPick={newEntry.displayActionTableInIWS}
                                label="Display Action plan Table in IWS"
                                onChange={(option) =>
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        displayActionTableInIWS: option
                                    }))
                                }></NoYesRadioPicker>
                        </div>
                    </div>
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="testIdSubmitButton">
                    {'UPDATE PLACEMENTS'}
                </Button>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    employmentTypeIds: Yup.array().min(1, 'No placement employment types selected.').nullable(),
    frequencyIds: Yup.array().min(1, 'No frequencies selected.').nullable(),
    numDaysIWSRequired: Yup.number()
        .nullable()
        .required('Please enter a number more than 0')
        .typeError('must be a whole number')
        .integer('must be a whole number')
        .min(1, 'cannot be negative, blank or zero')
        .max(999, 'must be 999 or less')
});

PlacementsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default PlacementsAdmin;
