import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import {
    clearCurrentTemplate,
    loadCommunicationTemplate,
    searchCommunicationTemplates,
    sendGroupSMSCommunication
} from '../../../../store/communicationService';
import { searchParticipants } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { POLARIS_DARKER_GREY, POLARIS_ORANGE } from '../../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import IconError from '../../../IconError';
import SensitivityNotice from '../../../ui/notices/sensitivity/SensitivityNotice';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import { initialErrorState, validateGroupSMS } from './validateGroupCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../communicationStyles.module.css';

const PT_LOAD_SIZE = 65;
const GroupSMS = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    // LOCAL STATE
    const TIME = 30;
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const SMS_CHARACTERS_PER_SMS = 140;
    const SMS_CHARACTER_LIMIT = 560;
    const initialState = {
        templateId: '',
        templateName: '',
        numbers: '',
        details: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [smsColor, setSmsColor] = useState(POLARIS_DARKER_GREY);
    const [errors, setErrors] = useState(initialErrorState);
    const [showData, setShowData] = useState(false);
    const msg = `SMS Communication has been sent to ${state.group.name}`;

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const participants = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const templates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const currentTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        dispatch(clearCurrentTemplate());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (state.group.participants.length < 1) return;
        const ids = state.group.participants.map((el) => el.participantId);
        ids.length && dispatch(searchParticipants({ ids }, 0, PT_LOAD_SIZE));
    }, [state.group]);

    useEffect(() => {
        if (!participants || participants.length < 1) return;
        const numbers = participants
            .map((el) =>
                state.group.participants.find((entry) => entry.participantId === el.id)
                    ?.contactMode === 'PRIMARY_PHONE_NUMBER'
                    ? el.primaryPhoneNumber
                    : el.secondaryPhoneNumber
            )
            .join('; ');
        setNewEntry((prev) => ({ ...prev, numbers }));
        const contractIds = participants.map((el) => el.contractId);
        dispatch(
            searchCommunicationTemplates({
                type: 'SMS',
                contractIds: [...new Set(contractIds)],
                inactive: false
            })
        );
    }, [participants]);

    useEffect(() => {
        if (newEntry.details.length > 140) setSmsColor(POLARIS_ORANGE);
        else {
            if (smsColor === POLARIS_ORANGE) setSmsColor(POLARIS_DARKER_GREY);
        }
    }, [newEntry.details]);

    useEffect(() => {
        if (!newEntry.templateId || Object.keys(currentTemplate).length < 1) return;
        setNewEntry((prev) => ({ ...prev, details: currentTemplate.details }));
    }, [newEntry.templateId, currentTemplate]);

    useEffect(() => {
        if (successMessage === msg) {
            clearForm();
            setTimeout(() => navigate('/manage_groups'), 2000);
        }
    }, [successMessage]);

    // EVENT HANDLERS

    const onRevealData = () => {
        setShowData(!showData);
        setTimeout(() => setShowData((showData) => !showData), TIME * 1000);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validateGroupSMS(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        const payload = {
            groupCommunicationId: state.group.id,
            communicationTemplateId: newEntry.templateId || null,
            body: newEntry.details
        };
        dispatch(sendGroupSMSCommunication(payload, state.group.id, msg));
    };

    return (
        <div className={app.container} id="smsScreen">
            <div className={app.mainHeading}>Communications</div>
            <form className={`${form.formWrapper} ${local.formContainer}`} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.alignDownMed}>
                            <div className={`${form.staticText} ${form.baseGap}`}>
                                <div className={form.formLabel}>To Mobile Numbers</div>
                                <div className={`${form.textBox} ${form.alignLeft}`}>
                                    {newEntry.numbers}
                                </div>
                            </div>
                        </div>
                        {!showData && (
                            <div className={`${form.inputCover} ${form.coverHeightSm}`}>
                                <SensitivityNotice
                                    onClick={onRevealData}
                                    numRows={1}
                                    customClass={form.inputNotice}
                                />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <div className={form.alignDownMed}>
                            <DDLOptionPicker
                                label={'Template'}
                                id={'templates'}
                                placeholder="Template"
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={templates || []}
                                chosenName={newEntry.templateName}
                                chosenId={newEntry.templateId}
                                onChange={(chosenId) => {
                                    chosenId && dispatch(loadCommunicationTemplate(chosenId));
                                    const chosenTemplate = templates?.find(
                                        (el) => el.id === chosenId
                                    );
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        templateId: chosenId || '',
                                        templateName: chosenTemplate?.name || ''
                                    }));
                                }}></DDLOptionPicker>
                        </div>
                    </div>
                </div>

                <div className={`${form.formSection} ${form.alignDown}`}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            <label htmlFor="sms">Message *</label>
                            <textarea
                                className={form.textAreaText}
                                id="details"
                                disabled={!hasRole(acceptedRoles, roles)}
                                maxLength={SMS_CHARACTER_LIMIT}
                                rows={10}
                                placeholder={'Enter template details'}
                                value={newEntry.details || ''}
                                onChange={(e) => {
                                    clearError('details');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        details: e.target.value
                                    }));
                                }}
                            />
                            <div>
                                <div>
                                    <span>Characters: </span>
                                    <span style={{ color: smsColor }}>
                                        {newEntry.details?.length}
                                    </span>
                                </div>
                                <div>
                                    <span>SMS Count: </span>
                                    <span>
                                        {Math.ceil(
                                            newEntry.details?.length / SMS_CHARACTERS_PER_SMS
                                        )}
                                    </span>
                                </div>
                            </div>

                            {errors.details.error && (
                                <div className={`${form.textInputError} ${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={form.buttonRight}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={!hasRole(acceptedRoles, roles) || newEntry.details.length < 1}>
                        Send Now
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default GroupSMS;
