import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROW_OPTIONS, MAX_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import SensitivityNotice from '../../ui/notices/sensitivity/SensitivityNotice';

import CallsDueTableRow from './CallsDueTableRow';

import table from '../../../commonStyles/tables.module.css';
import classes from '../../ui/notices/sensitivity/sensitivityNotice.module.css';

const headCells = [
    { id: 'ptCode', numeric: false, label: 'Participant ID' },
    { id: 'firstName', numeric: false, label: 'First Name' },
    { id: 'lastName', numeric: false, label: 'Last Name' },
    { id: 'adviser', numeric: false, label: 'Adviser' }
];

const CallsDueTable = ({ rows, roles }) => {
    const TIME = 30;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('adviser');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(MAX_TABLE_ROWS_PER_PAGE);
    const [showRows, setShowRows] = useState(false);

    useEffect(() => {
        setOrder('desc');
        setOrderBy('adviser');
    }, []);

    // HELPER FNS
    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const onRevealData = () => {
        setShowRows(!showRows);
        setTimeout(() => setShowRows((showRows) => !showRows), TIME * 1000);
    };

    // RENDER
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <div className={table.tableWrapper}>
                    <TableContainer>
                        <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            sortDirection={orderBy === headCell.id ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={() => onSort(headCell.id)}>
                                                <Typography variant={'subtitle3'} color={'primary'}>
                                                    {headCell.label}
                                                </Typography>
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc'
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {stableSort(rows, orderBy, order)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <CallsDueTableRow
                                            key={row.id}
                                            row={row}
                                            roles={roles}
                                            show={showRows}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!showRows && (
                        <SensitivityNotice
                            onClick={onRevealData}
                            numRows={rows.length}
                            customClass={`${
                                rows.length <= 2 ? classes.oneAndTwoRowCoverNameCells : ''
                            } 
                                ${classes.coverNameCells}`}
                        />
                    )}
                </div>

                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

CallsDueTable.propTypes = {
    rows: PropTypes.array.isRequired,
    roles: PropTypes.array
};

export default CallsDueTable;
