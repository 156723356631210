import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setErrorMessage } from '../../../store/formsState';
import {
    loadParticipantByPtCode,
    searchClaimEvents,
    updateClaimQueue
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { getNameFromId } from '../../../utils/directusFunctions';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

const CHARACTER_LIMIT = 750;

const StatusChangeEditor = ({
    open,
    onStatusChangeEditor,
    statusRow,
    claimEventStatuses,
    claimEventStatusReasons
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        statusId: null,
        reasonId: null,
        notes: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [notes, setNotes] = useState('');
    const [claimEventStatusesTrimmed, setClaimEventStatusesTrimmed] = useState([]);
    const [clearSelectedClaimEventStatus, setClearSelectedClaimEventStatus] = useState('0');
    const [clearSelectedClaimEventReason, setClearSelectedClaimEventReason] = useState('1');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { claimEvent, currentParticipant } = useSelector(
        (state) => state.entities.participantService
    );

    // EVENT HANDLERS
    const handleClaimEventStatusChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, statusId: chosenId, reasonId: '' }));
    };

    const handleClaimEventReasonChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setNewEntry((prev) => ({ ...prev, reasonId: chosenId }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, notes: e.target.value }));
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        clearData();
    };

    const clearData = () => {
        setNewEntry(initialState);
        setClearSelectedClaimEventStatus(Math.random());
        setClearSelectedClaimEventReason(Math.random());
        setNotes('');
        onStatusChangeEditor(false);
    };

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(statusRow)?.length > 0) dispatch(loadParticipantByPtCode(statusRow.ptCode));
        setClearSelectedClaimEventStatus(Math.random());
        setClearSelectedClaimEventReason(Math.random());
    }, [statusRow]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length > 0) {
            dispatch(
                searchClaimEvents({
                    eligibilityGroupId: currentParticipant.eligibilityGroupId,
                    claimTypeId: statusRow.eventTypeId
                })
            );
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (Object.keys(claimEvent)?.length > 0) {
            const eventStatuses = claimEvent.claimTypeStatuses.find(
                (entry) => entry.statusId === statusRow.statusId
            );
            if (eventStatuses) {
                const eventToStatusIds = eventStatuses.claimTypeToStatusIds;
                setClaimEventStatusesTrimmed(
                    claimEventStatuses.filter((el) => eventToStatusIds.some((v) => v === el.id))
                );
            }
        }
    }, [claimEvent]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const onSubmit = () => {
        setValue('statusId', newEntry.statusId, { shouldValidate: true });
        setValue('notes', notes, { shouldValidate: true });
        if (
            getNameFromId(claimEventStatuses, newEntry.statusId).toLowerCase() === 'fail' &&
            !newEntry.reasonId
        ) {
            dispatch(setErrorMessage(`Reason must be entered for Fail status.`));
        } else {
            const payload = newEntry;
            payload.id = statusRow.id;

            dispatch(updateClaimQueue(payload));
            clearData();
        }
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <Dialog open={isOpen} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Update Status</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <DDLOptionPicker
                            label={'Status'}
                            key={clearSelectedClaimEventStatus}
                            id={'statusId'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            menuItems={claimEventStatusesTrimmed || []}
                            error={errors.statusId}
                            {...register('statusId')}
                            onChange={(chosenId) => handleClaimEventStatusChange(chosenId)}
                        />
                        <DDLOptionPicker
                            label={'Reason'}
                            key={clearSelectedClaimEventReason}
                            id={'reasonId'}
                            mandatory={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                getNameFromId(
                                    claimEventStatuses,
                                    newEntry.statusId
                                ).toLowerCase() === 'fail'
                            }
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                getNameFromId(
                                    claimEventStatuses,
                                    newEntry.statusId
                                ).toLowerCase() !== 'fail'
                            }
                            menuItems={claimEventStatusReasons || []}
                            onChange={(chosenId) => handleClaimEventReasonChange(chosenId)}
                        />
                        <LabelledTextField
                            label={'Notes'}
                            id={'notes'}
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            multiline
                            rows={5}
                            value={notes}
                            placeholder={'Enter reversion notes'}
                            counter={'true'}
                            helperText={`${notes.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            error={errors.notes}
                            {...register('notes')}
                            onChange={handleNotesChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                            Update
                        </Button>
                        <Button onClick={handleCancel} color="primary" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    statusId: Yup.string().required('Please select a status.'),
    notes: Yup.string().trim().min(5, 'Notes must be at least five characters.')
});

StatusChangeEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onStatusChangeEditor: PropTypes.func,
    statusRow: PropTypes.object,
    claimEventStatuses: PropTypes.array,
    claimEventStatusReasons: PropTypes.array
};

export default StatusChangeEditor;
