import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import MessageIcon from '@mui/icons-material/Message';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Box, MenuItem, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';

import {
    selectCommunicationOptOutTypes,
    selectEmailCommunicationOptOutTypeId,
    selectPhoneCallsCommunicationOptOutTypeId,
    selectSmsCommunicationOptOutTypeId
} from '../../../store/dataSelectors';
import { loadCommunicationOptOutTypes } from '../../../store/directusService';
import { POLARIS_WHITE, SCREEN_SETTINGS } from '../../../themes/theme';

/**
 * This is the side menu shown by the participant sticky menu when the 'head and shoulders'  icon is clicked
 *
 * See PPL-515
 * <a href="tel:<c:out value='${sessionScope.member.memberPersonal.mobileTelephone }'/>">
 *    <img alt="Call" class="phoneImage" src="image/icon/phone.png"/>
 * </a>
 *
 */
const ParticipantCommsMenu = () => {
    // LOCAL STATE
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // STORE STATE
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const communicationOptOutTypes = useSelector(selectCommunicationOptOutTypes);
    const smsCommunicationOptOutTypeId = useSelector(selectSmsCommunicationOptOutTypeId);
    const emailCommunicationOptOutTypeId = useSelector(selectEmailCommunicationOptOutTypeId);
    const phoneCallsCommunicationOptOutTypeId = useSelector(
        selectPhoneCallsCommunicationOptOutTypeId
    );
    const optedOutOfSms = currentParticipant.communicationTypeOptOutIds?.includes(
        smsCommunicationOptOutTypeId
    );
    const optedOutOfEmails = currentParticipant.communicationTypeOptOutIds?.includes(
        emailCommunicationOptOutTypeId
    );
    const optedOutOfPhoneCalls = currentParticipant.communicationTypeOptOutIds?.includes(
        phoneCallsCommunicationOptOutTypeId
    );

    // HELPER FNS

    const validPhoneNumber = (phoneNumber) => {
        if (phoneNumber === undefined || phoneNumber === null) {
            return false;
        }

        return phoneNumber.trim().length >= 9;
    };

    const removeNonDigits = (text) => {
        return text.replace(/\D/g, '');
    };

    /**
     * Get a URL for calling the phone number
     *
     * @param number String with phone number. This can contain spaces and digits
     * @returns url This is 'tel:' + '+' if number has country prefix + number with non-digits removed.
     */
    const getPhoneURL = (number) => {
        // Remove all non-digits
        let tidiedNumber = removeNonDigits(number);

        // Does number have a country prefix?
        // There isn't an easy way to find this out
        // Currently just handling '44'
        if (tidiedNumber.startsWith('44')) {
            tidiedNumber = '+' + tidiedNumber;
        }

        return `tel:${tidiedNumber}`;
    };

    // USE EFFECTS
    useEffect(() => {
        if (!communicationOptOutTypes.length) dispatch(loadCommunicationOptOutTypes());
    }, []);

    // EVENT HANDLERS
    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuItemClick = (index) => {
        switch (index) {
            case 0:
                navigate('/letter_communication');
                break;
            case 1:
                navigate('/sms_communication');
                break;
            case 2:
                navigate('/email_communication');
                break;
            default:
                break;
        }
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // RENDER

    return (
        <div>
            <Tooltip title="Contact Participant" placement="left-start">
                <PersonOutlineIcon
                    onClick={handleMenuClick}
                    fontSize={'large'}
                    sx={{
                        padding: '0px',
                        marginTop: SCREEN_SETTINGS.margin.large,
                        marginBottom: 0,
                        color: POLARIS_WHITE
                    }}
                />
            </Tooltip>
            <Box sx={{ marginRight: '20px' }}>
                <Menu
                    id="participant-menu-side-menu"
                    aria-labelledby="participant-menu-menu-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    // See https://mui.com/material-ui/react-popover/#anchor-playground for these values
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}>
                    <MenuItem onClick={() => handleMenuItemClick(0)}>
                        <Tooltip title="Letter" placement="left-start">
                            <MailOutlineOutlinedIcon fontSize={'large'} />
                        </Tooltip>
                    </MenuItem>

                    <MenuItem disabled={optedOutOfSms} onClick={() => handleMenuItemClick(1)}>
                        <Tooltip title="Send SMS" placement="left-start">
                            <MessageIcon fontSize={'large'} />
                        </Tooltip>
                    </MenuItem>

                    <MenuItem disabled={optedOutOfEmails} onClick={() => handleMenuItemClick(2)}>
                        <Tooltip title="Email Participant" placement="left-start">
                            <AttachEmailIcon fontSize={'large'} />
                        </Tooltip>
                    </MenuItem>

                    <MenuItem
                        disabled={
                            optedOutOfPhoneCalls ||
                            !validPhoneNumber(currentParticipant.primaryPhoneNumber)
                        }
                        onClick={() =>
                            window.open(getPhoneURL(currentParticipant.primaryPhoneNumber))
                        }>
                        <Tooltip title="Call primary phone" placement="left-start">
                            <AddIcCallIcon fontSize={'large'} />
                        </Tooltip>
                    </MenuItem>

                    <MenuItem
                        disabled={
                            optedOutOfPhoneCalls ||
                            !validPhoneNumber(currentParticipant.secondaryPhoneNumber)
                        }
                        onClick={() =>
                            window.open(getPhoneURL(currentParticipant.secondaryPhoneNumber))
                        }>
                        <Tooltip title="Call secondary phone" placement="left-start">
                            <AddIcCallIcon fontSize={'large'} />
                        </Tooltip>
                    </MenuItem>
                </Menu>
            </Box>
        </div>
    );
};
export default ParticipantCommsMenu;
